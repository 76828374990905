import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getActorType } from '../../../../../services/utilService';
import { Modal } from '../../../../shared/components/Modal';
import { SubmitButton } from '../../../../shared/components/SubmitButton';
import { PropTypes } from 'prop-types';
import './GuestModal.scss';

export function GuestModal({ onAdd, open, selected, email }) {
    const {
        register,
        formState: { errors, isValid, isSubmitted },
        handleSubmit,
        reset,
        setValue,
        trigger,
    } = useForm({
        email: null,
        firstName: null,
        lastName: null,
    });

    const [actorType, setActorType] = useState(null);

    useEffect(() => {
        async function fetch() {
            setActorType(await getActorType());
        }

        fetch();
    }, []);

    useEffect(() => {
        if (email && open) {
            setValue('email', email);
            trigger('email');
        }
    }, [open, email]);

    function onSubmit(data) {
        onAdd({ ...data, isExternal: true, type: actorType?.user });

        reset({ email: null, firstName: null, lastName: null });
    }
    return (
        <Fragment>
            <Modal
                className='guest-modal'
                open={open}
                onClose={() => onAdd(null)}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='group-form'>
                        <label htmlFor='email'>Email</label>
                        <input
                            id='email'
                            name='email'
                            aria-required={true}
                            aria-invalid={errors?.email ? true : false}
                            aria-describedby='error'
                            type='text'
                            {...register('email', {
                                required: 'Required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'Invalid email address',
                                },
                                validate: (value) =>
                                    !selected?.filter((x) => x.email === value)
                                        ?.length || 'Email address used',
                            })}
                        />
                        {errors?.email?.message && (
                            <p id='error' role='alert'>
                                {errors?.email?.message}
                            </p>
                        )}
                    </div>

                    <div className='group-form'>
                        <label htmlFor='firstName'>First Name</label>
                        <input
                            id='firstName'
                            name='firstName'
                            aria-required={true}
                            aria-invalid={errors?.email ? true : false}
                            aria-describedby='error'
                            type='text'
                            {...register('firstName', {
                                required: 'Required',
                            })}
                        />
                        {errors?.firstName?.message && (
                            <p id='error' role='alert'>
                                {errors?.firstName?.message}
                            </p>
                        )}
                    </div>

                    <div className='group-form'>
                        <label htmlFor='lastName'>Last name</label>
                        <input
                            id='lastName'
                            name='lastName'
                            aria-required={true}
                            aria-invalid={errors?.email ? true : false}
                            aria-describedby='error'
                            type='text'
                            {...register('lastName', {
                                required: 'Required',
                            })}
                        />
                        {errors?.lastName?.message && (
                            <p role='alert'>{errors?.lastName?.message}</p>
                        )}
                    </div>

                    <SubmitButton disabled={!isValid && isSubmitted}>
                        Add
                    </SubmitButton>
                </form>
            </Modal>
        </Fragment>
    );
}

GuestModal.propTypes = {
    onAdd: PropTypes.func,
    open: PropTypes.bool,
    selected: PropTypes.array,
    email: PropTypes.string,
};
