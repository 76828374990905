import { Fragment, useEffect, useState } from 'react';
import { useRagIntervals } from '../lib/useRagIntervals';
import { PropTypes } from 'prop-types';

export function DisplayRagValue({ value, color, textColor }) {
    const { getColor } = useRagIntervals();

    const [ragColor, setRagColor] = useState(color ? color : getColor(value));

    useEffect(() => {
        setRagColor(color ? color : getColor(value));
    }, [value]);

    return (
        <Fragment>
            <svg viewBox='0 0 180 180'>
                <circle
                    className='display-rag-value'
                    stroke={ragColor}
                    strokeWidth={5}
                    fill={ragColor}
                    cx='90'
                    cy='90'
                    r={80}
                />

                <text
                    style={{ fontSize: '500%' }}
                    x='50%'
                    y='43%'
                    textAnchor='middle'
                    stroke={textColor ? textColor : 'white'}
                    fill={textColor ? textColor : 'white'}
                    strokeWidth={'2px'}
                    dy='.5em'
                >
                    {isNaN(value) || value === null ? '-' : value}
                </text>
            </svg>
        </Fragment>
    );
}

DisplayRagValue.propTypes = {
    value: PropTypes.any,
    color: PropTypes.string,
    percentage: PropTypes.bool,
    textColor: PropTypes.string,
};
