import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import './components.scss';

export function TeamOverview({ data, sortOption, textFilter }) {
    const navigate = useNavigate();

    const [teams, setTeams] = useState(null);

    function reorderOrganisation(text, sort) {
        let result = data;

        switch (sort) {
            case 0:
                result = result.sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                );
                break;
            case 1:
                result = result.sort((a, b) => (a.volume < b.volume ? 1 : -1));
                break;
            case 2:
                result = result.sort((a, b) =>
                    a.unansweredFeedback < b.unansweredFeedback ? 1 : -1
                );
                break;

            default:
                result = result.sort((a, b) =>
                    moment(a.lastFeedback).isAfter(moment(b.lastFeedback))
                        ? -1
                        : 1
                );
                break;
        }

        if (text.length > 0) {
            result = result.filter((f) =>
                f.name.toLowerCase().includes(text.toLowerCase())
            );
        }

        setTeams([...result]);
    }

    useEffect(() => {
        if (data && data.length) {
            reorderOrganisation(textFilter.trim(), sortOption);
        }
    }, [textFilter, sortOption, data]);

    return (
        <Fragment>
            <div className='team-overview'>
                {!!teams &&
                    !!teams.length &&
                    teams.map((t) => (
                        <TeamOverviewBox
                            team={t}
                            key={t.id}
                            onClick={(t) =>
                                navigate(`/my-team/feedback/organisation`, {
                                    state: { teamId: t.id },
                                })
                            }
                        />
                    ))}
            </div>
        </Fragment>
    );
}

TeamOverview.propTypes = {
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    sortOption: PropTypes.number,
    textFilter: PropTypes.string,
};

function TeamOverviewBox({ team, onClick }) {
    return (
        <div className='team-box p-shadow' onClick={() => onClick(team)}>
            <div className='left-side'>
                <h5>{team.name}</h5>

                <div className='w-group'>
                    <label>Team lead(s): </label>
                    {team.teamLeads &&
                        !!team.teamLeads.length &&
                        team.teamLeads.map((tl) => (
                            <img
                                alt={tl.name}
                                key={`tl-${tl.id}`}
                                title={tl.name}
                                src={tl.image}
                            />
                        ))}
                </div>
                <div className='w-group'>
                    <label>Last feedback:</label>
                    <p>
                        {team.lastFeedback
                            ? moment(team.lastFeedback).format('DD MMM HH:mm')
                            : '-'}
                    </p>
                </div>
                <div className='w-group'>
                    <div className='red-circle'>{team.unansweredFeedback}</div>
                    <label>Feedback unanswered</label>
                </div>
            </div>
            <div className='right-side'>
                <i className='icon icon-arrow-right'></i>
                <label>Total</label>
                <div className='total'>{team.volume}</div>
            </div>
        </div>
    );
}

TeamOverviewBox.propTypes = {
    team: PropTypes.object,
    onClick: PropTypes.func,
};
