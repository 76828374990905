import { forwardRef } from 'react';
import { ComponentLoading } from '../loadings/ComponentLoading';
import { PropTypes } from 'prop-types';

export const SubmitButton = forwardRef(
    ({ className, children, loading, success, disabled, hidden }, ref) => {
        return (
            <div
                className={`form-input submit-button ${hidden ? 'hidden' : ''}`}
            >
                {loading && <ComponentLoading />}
                {!loading && success && (
                    <i role='img' className='icon icon-tick'></i>
                )}
                <button
                    ref={ref}
                    hidden={hidden}
                    disabled={disabled || loading}
                    className={`btn highlighted ${disabled ? 'disabled' : ''} ${
                        className ?? ''
                    }`}
                    type='submit'
                    role='button'
                >
                    {loading ? '' : children}
                </button>
            </div>
        );
    }
);

SubmitButton.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
};
