import { useState } from 'react';
import {
    NoChart,
    ScoreChart,
    SentimentChart,
    YesChart,
} from '../../../charts/RadialChartIcon';
import { Trend } from './trend/Trend';
import { PropTypes } from 'prop-types';
import constants from '../../../constants';

export function SurveyChart({ className, current, before, question }) {
    const [color, setColor] = useState(null);

    return (
        <div className={`survey-chart ${className ?? ''}`}>
            {question?.questionType === constants.QuestionType.Suggestion && (
                <div>
                    <div className='align-center'>
                        <SentimentChart
                            sentiment={Number(
                                current?.insight?.sentiment?.toFixed(0) ?? 0
                            )}
                            showText={false}
                            onColor={setColor}
                        />
                        <h2 style={{ color: color }}>
                            {typeof current?.insight?.sentiment === 'number'
                                ? `${Number(
                                      current?.insight?.sentiment?.toFixed(0)
                                  )}%`
                                : ''}
                        </h2>

                        {typeof before?.insight?.sentiment === 'number' &&
                            before?.insight?.sentiment > 0 && (
                                <Trend
                                    trend={
                                        Math.round(
                                            ((current?.insight?.sentiment -
                                                before?.insight?.sentiment) /
                                                ((current?.insight?.sentiment +
                                                    before?.insight
                                                        ?.sentiment) /
                                                    2)) *
                                                100 *
                                                10
                                        ) / 10
                                    }
                                />
                            )}
                    </div>
                </div>
            )}

            {question?.questionType === constants.QuestionType.Score && (
                <div>
                    <div className='align-center'>
                        <ScoreChart
                            score={Number(
                                current?.insight?.average?.toFixed(1) ?? 0
                            )}
                            showText={false}
                            onColor={setColor}
                        />

                        <h2 style={{ color: color }}>
                            {typeof current?.insight?.average === 'number'
                                ? Number(current?.insight?.average?.toFixed(1))
                                : '-'}
                        </h2>
                        {typeof before?.insight?.sentiment === 'number' &&
                            before?.insight?.sentiment > 0 && (
                                <Trend
                                    trend={
                                        Math.round(
                                            ((current?.insight?.average -
                                                before?.insight?.average) /
                                                ((current?.insight?.average +
                                                    before?.insight?.average) /
                                                    2)) *
                                                100 *
                                                10
                                        ) / 10
                                    }
                                />
                            )}
                    </div>
                </div>
            )}
            {question?.questionType === constants.QuestionType.YesNo && (
                <div>
                    <div className='yes-no-chart'>
                        <div className='align-center'>
                            <YesChart
                                yes={
                                    typeof current?.insight?.average ===
                                    'number'
                                        ? Number(
                                              current?.insight?.average?.toFixed(
                                                  0
                                              ) ?? 0
                                          )
                                        : null
                                }
                                showText={false}
                            />

                            <h2 style={{ color: '#741f82' }}>
                                {typeof current?.insight?.average === 'number'
                                    ? `${current?.insight?.average?.toFixed(
                                          0
                                      )}% `
                                    : '-'}
                            </h2>

                            {typeof before?.insight?.average === 'number' && (
                                <Trend
                                    trend={
                                        Math.round(
                                            ((current?.insight?.average -
                                                before?.insight?.average) /
                                                ((current?.insight?.average +
                                                    before?.insight?.average) /
                                                    2)) *
                                                100 *
                                                10
                                        ) / 10
                                    }
                                />
                            )}
                        </div>
                        <div className='align-center'>
                            <NoChart
                                no={
                                    typeof current?.insight?.average ===
                                    'number'
                                        ? 100 -
                                          Number(
                                              current?.insight?.average?.toFixed(
                                                  0
                                              ) ?? 0
                                          )
                                        : null
                                }
                                showText={false}
                            />
                            <h2 style={{ color: '#4b637d' }}>
                                {typeof current?.insight?.average === 'number'
                                    ? `${
                                          100 -
                                          Number(
                                              current?.insight?.average?.toFixed(
                                                  0
                                              )
                                          )
                                      }% `
                                    : '-'}
                            </h2>

                            {typeof before?.insight?.average === 'number' && (
                                <Trend
                                    trend={
                                        Math.round(
                                            ((100 -
                                                current?.insight?.average -
                                                (100 -
                                                    before?.insight?.average)) /
                                                ((100 -
                                                    current?.insight?.average +
                                                    (100 -
                                                        before?.insight
                                                            ?.average)) /
                                                    2)) *
                                                100 *
                                                10
                                        ) / 10
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

SurveyChart.propTypes = {
    className: PropTypes.string,
    current: PropTypes.object,
    before: PropTypes.object,
    question: PropTypes.object,
};
