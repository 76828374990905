import moment from 'moment';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import constants from '../../../../../../shared/constants';
import { Textarea } from '../../../../../../shared/inputs/Textarea';
import { useUser } from '../../../../../../shared/lib/useUser';
import { UserPhoto } from './UserPhoto';
import { PropTypes } from 'prop-types';
import './Comments.scss';

export function Comments({
    reactions,
    onComment,
    limited,
    isAssess,
    isAnonymous,
}) {
    const [active, setActive] = useState(false);

    const user = useUser();

    const messageRef = useRef(null);

    const ref = useRef();
    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollTop = messageRef.current.scrollHeight;
        }
    }, [messageRef, reactions, user]);

    async function sendComment(text) {
        await onComment(text);
    }

    const handleComment = useCallback(
        (event) => {
            if (event.key === 'Enter' || event.target.id === 'icon-send') {
                if (!event.shiftKey) {
                    event.preventDefault();
                    if (ref.current?.value) {
                        ref.current.disabled = true;
                        sendComment(ref.current.value);
                        ref.current.value = '';
                        setActive(false);
                    }
                }
            }
        },
        [ref.current?.value]
    );

    return !user ? (
        <Fragment></Fragment>
    ) : (
        <div className='pulse-comments'>
            <div ref={messageRef} className='messages'>
                {reactions?.map((reaction, index) => (
                    <div
                        key={`message-${index}`}
                        className={`message ${
                            reaction.actor.id === user.id ? 'user' : ''
                        } `}
                    >
                        <div className='content'>
                            <UserPhoto
                                giver={reaction.actor}
                                anonymous={
                                    reaction.actor.id === user.id &&
                                    reaction.actor.isAnonymous &&
                                    !isAssess
                                }
                            />

                            <div className='text-date'>
                                <p>{reaction.commentText}</p>
                                <span>
                                    {moment(reaction.date).format(
                                        'DD/MM HH:mm'
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className='control-input'>
                <UserPhoto giver={user} anonymous={isAnonymous && !isAssess} />
                <Textarea
                    disabled={
                        limited &&
                        user.id === reactions[reactions.length - 1].actor.id
                    }
                    ref={ref}
                    onKeyPress={handleComment}
                    maxLength={constants.maxLength}
                    rows={1}
                    placeholder='Write a comment...'
                    onChange={(e) => setActive(e.target.value.length > 0)}
                    icon={
                        <i
                            id='icon-send'
                            className={
                                'icon icon-send' +
                                (active ? ' active ' : '')
                            }
                            onClick={handleComment}
                        ></i>
                    }
                />
            </div>
        </div>
    );
}

Comments.propTypes = {
    reactions: PropTypes.array,
    onComment: PropTypes.func,
    limited: PropTypes.bool,
    isAssess: PropTypes.bool,
    isAnonymous: PropTypes.bool,
};
