import { Fragment } from 'react';
import { ErrorPage } from '../../../shared/error/ErrorPage';


export function InviteError() {
    return (
        <ErrorPage
            message={
                <Fragment>
                    <h3>
                        We{"'"}re sorry, we{"'"}ve experienced a problem.
                    </h3>
                    <br />
                    <p>
                        Please, contact your administrator or contact our
                        support team{' '}
                        <a href='mailto:support@pulse360.io'>here</a>.
                    </p>
                </Fragment>
            }
        />
    );
}
