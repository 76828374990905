import { Fragment, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { SurveyCompletionRateWidget } from '../../../shared/surveys/SurveyCompletionRateWidget';
import './Pulse.scss';

export function PulseCompletionRate() {
    const [redirect, setRedirect] = useState(null);

    const [redirectTeam, setRedirectTeam] = useState(null);

    return (
        <Fragment>
            {redirect && <Navigate to='/company/feedback/pulse' />}
            {redirectTeam && (
                <Navigate
                    to={'/my-team/feedback/pulse/completion-rate/' +
                            redirectTeam}
                    state={redirectTeam}
                />
            )}
            <div className='pulse-completion-rate'>
                <Page title='Surveys'>
                    <MainContent
                        title={`Survey insights`}
                        
                        line={true}
                        rightBox={
                            <i
                                className='icon-progress-active'
                                onClick={() => setRedirect(true)}></i>
                        }>
                        <SurveyCompletionRateWidget
                            filterable={true}
                            onRedirectTeam={(item) => setRedirectTeam(item)}
                        />
                    </MainContent>
                </Page>
            </div>
        </Fragment>
    );
}
