import { Fragment, useEffect, useState } from 'react';
import { react, getFeedback } from '../../../../../services/feedbackService';
import { getUser } from '../../../../../services/userService';
import { Textarea } from '../../../../shared/inputs/Textarea';
import { PropTypes } from 'prop-types';
import constants from '../../../../shared/constants';
import moment from 'moment';
import './components.scss';

export function FeedbackBox({ chatUser, feedback, onComment }) {
    const [send, setSend] = useState(false);

    const [reactions, setReactions] = useState(feedback.reactions);

    const [user, setUser] = useState(null);

    const [commentText, setCommentText] = useState('');

    useEffect(() => {
        async function sendComment() {
            try {
                react(
                    { Comment: commentText, ItemId: feedback.id },
                    chatUser.id
                ).then(async () => {
                    let feed = await getFeedback(feedback.id);

                    onComment(feed);
                    setReactions([...feed.reactions]);
                    setSend(false);
                });
            } catch (error) {
                //ignore
            }
        }

        if (send && commentText.trim().length > 0) {
            sendComment();
        }
    }, [send]);

    useEffect(() => {
        async function fetch() {
            let u = await getUser(feedback.feedbackGiver.id);
            setUser(u);
        }
        if (feedback && feedback.reactions) {
            setUser(null);
            setCommentText('');
            setReactions(feedback.reactions);
            fetch();
        }
    }, [feedback]);

    const canComment =
        ((chatUser &&
            reactions &&
            reactions.length &&
            reactions[reactions.length - 1].actor.id !== chatUser.id) ||
            (reactions && reactions.length === 0)) &&
        reactions &&
        reactions.length < 4;

    return (
        <Fragment>
            <div className='feedback-box'>
                <div className='feed'>
                    <div className='user-description'>
                        {feedback.feedbackGiver.isAnonymous ? (
                            <div className='icon-box'>
                                <i
                                    role='img'
                                    aria-label='Anonymous'
                                    className='icon-eye-anonymous'
                                ></i>
                            </div>
                        ) : (
                            <img
                                className='miniature'
                                src={feedback.feedbackGiver.image}
                            />
                        )}
                        <div className='w-group'>
                            <div>
                                <label>{feedback.feedbackGiver.name}</label>
                                <p className='secondary-text'>
                                    {moment(feedback.created).format(
                                        'DD MMM YY [at] HH:mm'
                                    )}
                                </p>
                            </div>
                            {!feedback.feedbackGiver.isAnonymous && (
                                <p className='secondary-text'>
                                    {user &&
                                        user.teams &&
                                        !!user.teams.length &&
                                        user.teams
                                            .map((x) => x.name)
                                            .join(', ')}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className='f-content'>
                        <p className='text'>
                            {feedback.givenFeedback?.feedback}
                        </p>
                        <div className='topic-bubble'>
                            <i className='icon icon-topic'></i>
                            {feedback.topic.title}
                        </div>
                    </div>
                </div>
                <hr />
                <div className='chat'>
                    <div className='comments'>
                        {reactions && !!reactions.length && (
                            <Fragment>
                                {reactions.map((comment, i) => (
                                    <ReactionMiniature
                                        comment={comment}
                                        key={`reaction-${i}-${comment.actor.id}`}
                                        chatUser={chatUser}
                                    />
                                ))}
                            </Fragment>
                        )}
                    </div>
                    <hr />
                    <div className='sender-box'>
                        <img
                            alt='Sender photo'
                            className='miniature'
                            src={chatUser.image}
                        />
                        {canComment ? (
                            <Fragment>
                                <Textarea
                                    maxLength={constants.maxLength}
                                    rows={1}
                                    placeholder='Write a comment...'
                                    onChange={(event) =>
                                        setCommentText(event.target.value)
                                    }
                                    focusOnRender={true}
                                    value={commentText}
                                    onKeyDown={(e) => {
                                        if (
                                            e.keyCode == 13 &&
                                            e.shiftKey == false
                                        ) {
                                            setSend(true);
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                {send ? (
                                    <div className='loader06'></div>
                                ) : (
                                    <i
                                        className={`icon icon-send ${
                                            commentText.trim().length > 0
                                                ? ''
                                                : 'disabled'
                                        }`}
                                        onClick={() => setSend(true)}
                                    ></i>
                                )}
                            </Fragment>
                        ) : (
                            <p className='secondary-text'>
                                {reactions && reactions.length === 4
                                    ? 'Maximum responses reached'
                                    : 'Wait for a response'}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

FeedbackBox.propTypes = {
    chatUser: PropTypes.object,
    feedback: PropTypes.object,
    onComment: PropTypes.func,
};

function ReactionMiniature({ comment, chatUser }) {
    return (
        <div
            className={`comment ${
                chatUser.id === comment.actor.id ? 'chat-user' : ''
            }`}
        >
            {comment.actor.isAnonymous ? (
                <div className='icon-box' style={{ marginTop: '1.3rem' }}>
                    <i
                        role='img'
                        aria-label='Anonymous'
                        className='icon-eye-anonymous'
                    ></i>
                </div>
            ) : (
                <img
                    alt='Photo'
                    className='miniature'
                    src={comment.actor.image}
                />
            )}

            <div className='text-box'>
                <div>
                    <label>{comment.actor.name}</label>
                    <p className='secondary-text'>
                        {moment(comment.date).format('DD MMM [at] HH:mm')}
                    </p>
                </div>

                <p className='text'>{comment.commentText}</p>
            </div>
        </div>
    );
}

ReactionMiniature.propTypes = {
    comment: PropTypes.object,
    chatUser: PropTypes.object,
};
