import { Fragment, useState } from 'react';
import constants from '../../../../../shared/constants';
import { Textarea } from '../../../../../shared/inputs/Textarea';
import { humanizeDiffToday } from '../../../../../shared/lib/dateTimeHelper';
import { useUser } from '../../../../../shared/lib/useUser';
import { UserPhoto } from './Components/UserPhoto';
import { CommentBox } from './FeedbackItem';
import { PropTypes } from 'prop-types';
import '../HubItems.scss';

export function GFeedbackItem({ item, submitComment, read, ...props }) {
    const user = useUser();

    const [commented, setCommented] = useState(null);

    const [commentText, setCommentText] = useState(null);

    async function onCommentEnterPress(e, actorId) {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();

            if (commentText) {
                await submitComment(item, actorId, commentText);

                setCommented(true);
                setCommentText(null);
            }
        }
    }

    return (
        <div
            className={`me-items-box guest-feedback-item ${
                read ? 'grey-border' : 'blue-border'
            }`}
        >
            <div className='main-section'>
                <div className='user-logo-top'>
                    <img src={item?.feedbackReceiver?.image} />
                    <i className='icon-arrow-right'> </i>

                    <i className='icon icon-person'></i>
                </div>
                {item.hasScore && (
                    <div className='score-container'>
                        <span className='score'>{item.score}</span>
                        <span className='score-separator'>{'/'}</span>
                        <span>10</span>
                    </div>
                )}

                {item.hasYesNo && (
                    <div className='score-container'>
                        <span className={`${item.yesNo ? 'score' : ''}`}>
                            Y
                        </span>
                        <span className='score-separator'>{'/'}</span>
                        <span className={`${item.yesNo ? '' : 'score'}`}>
                            N
                        </span>
                    </div>
                )}

                <div className='end-div'>
                    <h5> Guest Feedback</h5>

                    <p className='small light'>
                        {humanizeDiffToday(item?.created)}
                    </p>
                </div>
            </div>
            <div className='row row-box'>
                <div className='from-topic-col'>To:</div>
                <div className='user-title'>{item?.feedbackGiver?.name}</div>
            </div>
            <div className='row row-box'>
                <div className='topic-bubble'>
                    <i className='icon icon-topic'></i>
                    {item.topic}
                </div>
            </div>

            <div
                className='row row-box text-row'
                style={{ marginBottom: '1rem' }}
            >
                <p className='item-text'>{item.question}</p>
            </div>

            {item?.answered && (
                <div className='answer-text-box'>
                    <i className='icon icon-person'></i>
                    <div>
                        <div className='space-between'>
                            <h4>{item?.feedbackGiver?.name}</h4>
                            <p className='small light'>
                                {humanizeDiffToday(item?.answerDate)}
                            </p>
                        </div>
                        <p className='item-text'>{item.text}</p>
                    </div>
                </div>
            )}

            <Fragment>
                {!!item.reactions.length &&
                    item.reactions?.map((reaction, i) => (
                        <CommentBox
                            key={`reaction-${reaction?.actor?.id}-${i}`}
                            reaction={{
                                ...reaction,
                                actor: {
                                    ...reaction?.actor,
                                    icon: !reaction?.actor?.image
                                        ? 'icon-person'
                                        : null,
                                },
                            }}
                            props={props}
                        />
                    ))}

                {!commented &&
                    ((!item?.reactions?.length && item?.answered) ||
                        (item.reactions.length < 4 &&
                            item.reactions.length > 0 &&
                            item.reactions[item.reactions.length - 1].actor
                                .id !== user?.id)) && (
                        <div className='comment-box'>
                            <div className='row'>
                                <div className='col-md-1 comment-image'>
                                    <UserPhoto giver={user} />
                                </div>
                                <div className='col-md-11'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <div className='user-title'>
                                                {user?.name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xs-12'>
                                            <Textarea
                                                placeholder='Write a comment...'
                                                className='commentText'
                                                onKeyDown={(e) =>
                                                    onCommentEnterPress(
                                                        e,
                                                        item.feedbackGiver.id
                                                    )
                                                }
                                                onChange={({
                                                    target: { value },
                                                }) => setCommentText(value)}
                                                maxLength={constants.maxLength}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </Fragment>
        </div>
    );
}

GFeedbackItem.propTypes = {
    item: PropTypes.object,
    submitComment: PropTypes.func,
    read: PropTypes.bool,
};
