import { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppActionTypes, AppContext } from '../contexts/AppContext';
import { getRagInterval } from '../services/ragService';
import { getUser } from '../services/userService';
import { Breadcrumb } from './navigation/bread-crumb/Breadcrumb';
import { SideBar } from './navigation/side-bar/SideBar';
import { AuthResolver } from './pages/authentication/AuthResolver';
import { useSession } from './shared/lib/useSession';
import { PropTypes } from 'prop-types';
import { SnackbarContext } from '../contexts/SnackbarContext';
import { Snackbar } from './shared/components/Snackbar';
import { getClient } from '../services/clientService';
import moment from 'moment';
import 'rc-slider/assets/index.css';

export function Layout({ routes }) {
    const { state, dispatch } = useContext(AppContext);

    const snackbar = useContext(SnackbarContext);

    const session = useSession();

    useEffect(() => {
        async function fetch() {
            let [user, client, r] = await Promise.all([
                getUser(session.userId),
                getClient(),
                getRagInterval(),
            ]);

            dispatch({
                type: AppActionTypes.ADD,
                payload: {
                    user,
                    client,
                    ragIntervals: r,
                    cache: {
                        date: moment(),
                    },
                },
            });
        }

        if (!state?.user) {
            fetch();
        }
    }, [state?.user]);

    return (
        <div className='layout' id={'layout'}>
            <nav>
                <div className='side-bar-container'>
                    <SideBar />
                </div>
            </nav>

            {state?.user && (
                <main className='layout-body'>
                    <Breadcrumb routes={routes} />
                    <Routes>
                        {routes?.map((route, i) => (
                            <Route
                                key={i}
                                path={route.path}
                                element={<AuthResolver>{route}</AuthResolver>}
                            />
                        ))}
                    </Routes>
                </main>
            )}
            {snackbar.state?.isDisplayed && <Snackbar />}
        </div>
    );
}

Layout.propTypes = {
    routes: PropTypes.array,
};
