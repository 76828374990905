import { getSurveyTitleFileName } from './helper';
import { downloadFile } from './axios';
import { useSnackbar } from './useSnackbar';

export function usePDF() {
    const { showSnackbarWithLoading, hideSnackar, showSnackbarWithTimer } =
        useSnackbar();

    async function downloadSurveyPDF(
        title,
        surveyId,
        iteration,
        questionIds,
        includeComments,
        anonymous,
        accessible,
        teamId
    ) {
        try {
            showSnackbarWithLoading('Downloading PDF report...');
            await downloadFile(
                `pdf/surveys/${surveyId}`,
                getSurveyTitleFileName(title, true),
                {
                    iteration,
                    questionIds,
                    includeComments,
                    anonymous,
                    accessible,
                    teamId,
                }
            );
            hideSnackar();
        } catch {
            showSnackbarWithTimer(
                'Error when downloading PDF report. Please contact support@pulse360.io if the error persists.',
                3000,
                false
            );
        }
    }

    return {
        downloadSurveyPDF,
    };
}
