import { getSurveyTitleFileName } from './helper';
import { downloadFile } from './axios';
import { useSnackbar } from './useSnackbar';

export function useCSV() {
    const { showSnackbarWithLoading, showSnackbarWithTimer, hideSnackar } =
        useSnackbar();

    async function exportSurveyComments(surveyId, title) {
        try {
            showSnackbarWithLoading('Downloading CSV report...');
            await downloadFile(
                `csv/surveys/${surveyId}/comments`,
                getSurveyTitleFileName(title)
            );
            hideSnackar();
        } catch {
            showSnackbarWithTimer(
                'Error when downloading CSV report',
                3000,
                false
            );
        }
    }

    async function exportTeamSurveyComments(surveyId, teamId, title) {
        try {
            showSnackbarWithLoading('Downloading CSV report...');
            await downloadFile(
                `csv/teams/${teamId}/surveys/${surveyId}/comments`,
                getSurveyTitleFileName(title)
            );
            hideSnackar();
        } catch {
            showSnackbarWithTimer(
                'Error when downloading CSV report',
                3000,
                false
            );
        }
    }

    return { exportSurveyComments, exportTeamSurveyComments };
}
