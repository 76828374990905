import { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { InformationTooltip } from '../components/InformationTooltip';
import './layout.scss';

export function MainContent({
    className,
    title,
    titleIcon,
    rightBox,
    addButton,
    line,
    content,
    buttons,
    children,
    clickable,
    tooltip,
    br,
    style,
    testId,
}) {
    return (
        <div
            className={`main-content-container clearfix ${
                clickable ? 'clickable' : ''
            } ${className ? className : ''}`}
            style={style}
            data-testid={testId}
        >
            <div className={`title-button${tooltip ? ' tooltip' : ''}`}>
                {title && (
                    <Fragment>
                        <h3 role='none' className='main-content-title'>
                            {title}
                        </h3>
                    </Fragment>
                )}
                {tooltip && (
                    <div style={{ marginLeft: '1rem' }}>
                        <InformationTooltip text={tooltip} />
                    </div>
                )}

                {titleIcon && titleIcon}

                {rightBox && rightBox}

                {addButton ? (
                    <i
                        role='button'
                        aria-label='Add'
                        tabIndex={0}
                        onClick={(event) => {
                            addButton(event);
                        }}
                        onKeyPress={(event) => {
                            event.key === 'Enter' && addButton(event);
                        }}
                        className='add-button icon-add '
                    ></i>
                ) : (
                    <Fragment> </Fragment>
                )}
            </div>
            {line && <hr />}
            {br && <br />}
            {!!content && content}
            {!!children && children}
            {!!buttons && <div className='main-content-buttons'>{buttons}</div>}
        </div>
    );
}

MainContent.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleIcon: PropTypes.object,
    rightBox: PropTypes.object,
    addButton: PropTypes.func,
    line: PropTypes.bool,
    content: PropTypes.object,
    buttons: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    clickable: PropTypes.bool,
    tooltip: PropTypes.string,
    br: PropTypes.bool,
    style: PropTypes.object,
    testId: PropTypes.string,
};
