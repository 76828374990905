import moment from 'moment';
import { useContext, useEffect } from 'react';
import { AppActionTypes, AppContext } from '../../../contexts/AppContext';
import { getTeams, getUserTeamLeadTeam } from '../../../services/teamService';
import { getUsers } from '../../../services/userService';

export function useCache() {
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        async function fetch() {
            let [u, t, tl] = await Promise.all([
                getUsers(),
                getTeams(),
                getUserTeamLeadTeam(),
            ]);

            if (tl?.length) {
                dispatch({
                    type: AppActionTypes.UPDATE,
                    prop: 'selectedTeam',
                    payload: t?.find((x) => x?.id === tl[0]?.value),
                });
            }

            dispatch({
                type: AppActionTypes.UPDATE,
                prop: 'cache',
                payload: {
                    date: moment(),
                    teams: t,
                    users: [
                        ...u.map((x) => ({
                            ...x,
                            teams: t?.filter((t) =>
                                t?.teamMembers?.some((z) => z.id === x.id)
                            ),
                        })),
                    ],
                    teamLeadTeams: [...tl],
                },
            });
        }

        if (
            (state?.cache?.date &&
                moment(state?.cache?.date).diff(moment(), 'minutes') > 15) ||
            !state?.cache?.teams ||
            !state?.cache?.users ||
            !state?.cache?.teamLeadTeams
        ) {
            fetch();
        }
    }, []);

    return {
        users: state?.cache?.users,
        teams: state?.cache?.teams,
        teamLeadTeams: state?.cache?.teamLeadTeams,
    };
}
