import { Fragment, useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getTeam } from '../../../../../services/teamService';
import { useAsyncError } from '../../../../shared/lib/helper';
import { useMediaQueries } from '../../../../shared/lib/useMediaQuery';
import { PropTypes } from 'prop-types';
import './components.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

SwiperCore.use([Navigation, Pagination]);

export function AlternativeUsersWidget({ teamId, userId }) {
    const [team, setTeam] = useState(null);

    const SliderRef = useRef(null);

    const throwError = useAsyncError();

    const [state] = useReducer(reducer, {
        scrollLeft: 0,
    });

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchTeam() {
            try {
                let t = await getTeam(teamId);

                setTeam(t);
            } catch (error) {
                throwError(error);
            }
        }

        if (teamId) {
            fetchTeam();
        }
    }, []);

    useEffect(() => {
        if (SliderRef?.current) {
            SliderRef.current.scrollLeft = state.scrollLeft;
        }
    }, [state]);

    const { mediumDown } = useMediaQueries();
    return (
        <div className='alternative-users-widget p-shadow'>
            <h3>Other team members</h3>
            <hr role='none'></hr>

            <Fragment>
                <Swiper
                    aria-label='Swiper'
                    navigation={true}
                    slidesPerView={mediumDown ? 2 : 3}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    className='mySwiper'
                >
                    {team?.teamMembers
                        ?.filter((x) => x.id !== userId)
                        ?.map((user) => (
                            <SwiperSlide
                                style={{ padding: '2rem 2rem' }}
                                key={`swiper-user-box-${user.id}`}
                            >
                                <UserBox
                                    user={user}
                                    isTeamLead={
                                        !!team?.teamLeads?.find(
                                            (x) => x.id === user.id
                                        )
                                    }
                                    onClick={(e, selectedUser) =>
                                        navigate(
                                            `/my-team/feedback/overview/${teamId}/user/${selectedUser}`
                                        )
                                    }
                                />
                            </SwiperSlide>
                        ))}
                </Swiper>
            </Fragment>
        </div>
    );
}

AlternativeUsersWidget.propTypes = {
    teamId: PropTypes.string,
    userId: PropTypes.string,
};

function UserBox({ user, onClick }) {
    return (
        <div
            tabIndex={0}
            className='user-box p-shadow interactive'
            onClick={(e) => onClick(e, user.id)}
            onKeyPress={(e) => e.key === 'Enter' && onClick(e, user.id)}
        >
            <img
                aria-label={user.name + ' image'}
                alt='Team member miniature'
                src={user.image}
            />
            <p>{user.name}</p>
        </div>
    );
}

UserBox.propTypes = {
    user: PropTypes.object,
    onClick: PropTypes.func,
};

function reducer(state, action) {
    switch (action.type) {
        case 'NEXT':
            return {
                ...state,

                scrollLeft: state.scrollLeft + 190,
            };
        case 'PREV':
            return {
                ...state,
                scrollLeft: state.scrollLeft - 190,
            };
        default:
            return state;
    }
}
