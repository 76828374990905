import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { getPulseIndex } from '../../../../../services/pulseIndexService';
import { useFormattedMessage } from '../../../../shared/lib/helper';
import { useRagIntervals } from '../../../../shared/lib/useRagIntervals';
import { PulseIndexChart } from '../../../../shared/widgets/PulseIndexWidget';
import { WLayout } from './WLayout';
import { PropTypes } from 'prop-types';
import './components.scss';

export function WPulseIndex({ teamId, dates, isTeam }) {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState(null);

    const [colors, setColors] = useState(null);

    const { sortedDescendent } = useRagIntervals();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            var d = await getPulseIndex(dates.from, dates.to, teamId);

            setColors(sortedDescendent());

            setData(d);

            setLoading(false);
        }

        if (dates && ((isTeam && teamId) || !isTeam)) {
            fetchData();
        }
    }, [dates, teamId]);

    function renderDurationFormatted(category) {
        let momentFormatted = '-';
        if (category && category.average) {
            let duration = moment.duration(category.average);

            momentFormatted =
                duration.days() > 0
                    ? `${duration.days()}d ${duration.hours()}h`
                    : `${duration.hours()}h ${duration.minutes()}m`;
        }

        return momentFormatted;
    }
    return (
        <WLayout
            info={
                <Fragment>
                    To make the most of peer feedback, employees should have a
                    balance of:
                    <br />
                    <ul>
                        <li> Regularly asking for feedback</li>
                        <li> Proactively giving feedback</li>
                        <li>Responding in a timely manner</li>
                    </ul>
                </Fragment>
            }
            to={{
                pathname: `/${
                    teamId ? 'my-team' : 'company'
                }/feedback/peer-to-peer`,
            }}
            state={teamId}
            title={'Pulse Index'}
            loading={loading}
            className='w-pulse-index'
        >
            <Fragment>
                <div className='legend'>
                    <div>
                        <div
                            style={{
                                backgroundColor: colors?.length
                                    ? colors[2]?.hexColor
                                    : '#F2F4F8',
                            }}
                        ></div>
                        <p>
                            {useFormattedMessage(
                                'pulseIndexWidget.body.beginner'
                            )}
                        </p>
                    </div>
                    <div>
                        <div
                            style={{
                                backgroundColor: colors?.length
                                    ? colors[1]?.hexColor
                                    : '#F2F4F8',
                            }}
                        ></div>
                        <p>
                            {useFormattedMessage(
                                'pulseIndexWidget.body.intermediate'
                            )}
                        </p>
                    </div>
                    <div>
                        <div
                            style={{
                                backgroundColor: colors?.length
                                    ? colors[0]?.hexColor
                                    : '#F2F4F8',
                            }}
                        ></div>
                        <p>
                            {useFormattedMessage(
                                'pulseIndexWidget.body.expert'
                            )}
                        </p>
                    </div>
                </div>
                <div className='charts'>
                    <div className='chart-content'>
                        <PulseIndexChart
                            category={data ? data.feedbackAsked : null}
                        />
                        <div className='w-c-group'>
                            <h3>
                                {data &&
                                data.feedbackAsked &&
                                typeof data.feedbackAsked.average === 'number'
                                    ? data.feedbackAsked.average
                                    : '-'}
                            </h3>
                            <label>Average amount of feedback asked</label>
                        </div>
                    </div>
                    <div className='chart-content'>
                        <PulseIndexChart
                            category={data ? data.feedbackGiven : null}
                        />
                        <div className='w-c-group'>
                            <h3>
                                {data &&
                                data.feedbackGiven &&
                                typeof data.feedbackGiven.average === 'number'
                                    ? data.feedbackGiven.average
                                    : '-'}
                            </h3>
                            <label>Average amount of feedback given</label>
                        </div>
                    </div>
                    <div className='chart-content'>
                        <PulseIndexChart
                            category={data ? data.responseTime : null}
                        />
                        <div className='w-c-group'>
                            <h3>
                                {renderDurationFormatted(
                                    data && data.responseTime
                                        ? data.responseTime
                                        : null
                                )}
                            </h3>
                            <label>Response time</label>
                        </div>
                    </div>
                </div>
            </Fragment>
        </WLayout>
    );
}

WPulseIndex.propTypes = {
    teamId: PropTypes.string,
    dates: PropTypes.object,
    isTeam: PropTypes.bool,
};
