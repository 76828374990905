import { get } from '../components/shared/lib/axios';

var controllerRoute = 'ratings';

export async function getRating(ratingId) {
    return get(`controllerRoute/${ratingId}`);
}

export async function getRatings() {
    return get(controllerRoute);
}
