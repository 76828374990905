import { Fragment, useEffect, useState } from 'react';
import {
    getWidgetCompanyTopTopics,
    getWidgetTeamTopTopics,
} from '../../../services/topTopicsService';
import { ScoreChart, SentimentChart } from '../charts/RadialChartIcon';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { WidgetTable } from './components/WidgetTable';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function TopTopicsWidget({ from, to, teamId }) {
    const limit = 5;

    const [topTopics, setTopTopics] = useState([]);

    const [received, setReceived] = useState(true);

    const [topicsNotUsed, setTopicsNotUsed] = useState(0);

    const [totalTopics, setTotalTopics] = useState(0);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            let data = await (teamId
                ? getWidgetTeamTopTopics(teamId, from, to, limit, received)
                : getWidgetCompanyTopTopics(from, to, limit));

            setTopTopics(fillArray(data.topics));
            setTopicsNotUsed(data.topicsNotUsedCount);
            setTotalTopics(data.totalTopics);
            setLoading(false);
        }

        if (from && to) {
            fetchData();
        }
    }, [from, to, teamId, received]);

    function fillArray(array) {
        let result = [...array];
        while (result.length < limit) {
            result.push({
                entityName: '',
                frequency: null,
                sentiment: null,
                score: null,
            });
        }

        return result;
    }

    return (
        <div className='top-topics-widget'>
            <WidgetLayout
                information={
                    <Fragment>
                        Topics showcases the most frequently used peer feedback
                        topics <br />
                        alongside the associated average scores and sentiment
                        scores.
                    </Fragment>
                }
            >
                <WidgetHeader title={'Topics'} route={''} />
                <hr />

                {!!teamId && (
                    <div className='received-given-selector'>
                        <button
                            className={`btn received ${
                                received ? 'highlighted' : ''
                            }`}
                            onClick={() => setReceived(true)}
                            aria-pressed={received}
                        >
                            Received
                        </button>

                        <button
                            className={`btn given ${
                                !received ? 'highlighted' : ''
                            }`}
                            onClick={() => setReceived(false)}
                            aria-pressed={!received}
                        >
                            Given
                        </button>
                    </div>
                )}

                <div
                    className='tables'
                    style={{ marginTop: teamId ? '0' : '2rem' }}
                >
                    <WidgetTable
                        loading={loading}
                        data={topTopics}
                        columns={[
                            {
                                Header: '',
                                accessor: (d) => d,
                                id: 'numeration',
                                Cell: (row) => (
                                    <p className='numeration' role='none'>
                                        {row.row.index + 1}.
                                    </p>
                                ),
                            },
                            {
                                Header: `Topic`,
                                accessor: 'entityName',
                                Cell: (row) => (
                                    <p
                                        className='text-ellipsis'
                                        title={row.value}
                                        role='cell'
                                        aria-label={row.value}
                                        style={{ maxWidth: 130 }}
                                    >
                                        {row.value}{' '}
                                    </p>
                                ),
                            },
                            {
                                Header: 'Freq.',
                                accessor: 'frequency',
                                headerClassName: 'text-center',
                                className: 'text-center numeration',
                                Cell: (row) => (
                                    <p role='cell' aria-label={row.value}>
                                        {!isNaN(row.value) && row.value !== null
                                            ? row.value
                                            : '-'}
                                    </p>
                                ),
                            },
                            {
                                Header: 'Avg. Score',
                                accessor: 'score',
                                headerClassName: 'text-center',
                                className: 'text-center',
                                Cell: (row) => (
                                    <div
                                        role='cell'
                                        aria-label={row.value + ' out of 10'}
                                    >
                                        <ScoreChart
                                            className='radial-chart-in-table'
                                            score={row.value}
                                            showText={true}
                                        />
                                    </div>
                                ),
                            },
                            {
                                Header: 'Avg. Sentiment',
                                accessor: 'sentiment',
                                headerClassName: 'text-center',
                                className: 'text-center',
                                Cell: (row) => (
                                    <div
                                        role='cell'
                                        aria-label={row.value + '%'}
                                    >
                                        <SentimentChart
                                            className='radial-chart-in-table'
                                            sentiment={row.value}
                                            showText={true}
                                        />
                                    </div>
                                ),
                            },
                        ]}
                        size={limit}
                    />
                </div>

                {!teamId && (
                    <Fragment>
                        <hr />
                        <div className='topics-not-used'>
                            <label>Topics not used: </label>
                            <div>
                                {topicsNotUsed}/{totalTopics}
                            </div>
                        </div>
                    </Fragment>
                )}
            </WidgetLayout>
        </div>
    );
}

TopTopicsWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    teamId: PropTypes.string,
};
