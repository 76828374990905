import { LineTrendChart } from './LineTrendChart';
import { PropTypes } from 'prop-types';

export function SentimentTrendChart({ iterations }) {
    return (
        <LineTrendChart
            tickFormatter={(tick) => `${tick}%`}
            data={[
                ...iterations.map((x) => ({
                    Sentiment: Number(x?.insight?.sentiment?.toFixed(1) ?? 0),
                    name: x?.iteration + 1,
                })),
            ]}
            dataKey={'Sentiment'}
            domain={[0, 100]}
        />
    );
}

SentimentTrendChart.propTypes = {
    iterations: PropTypes.array,
};
