import pagHelper from '../components/shared/lib/paginationHelper';
import { get, post, put, remove } from '../components/shared/lib/axios';
var controllerRoute = 'topics';

export async function getTopics(category) {
    return get(controllerRoute, { category });
}

export async function getTopicsPaginated(offset, limit, filter, orderBy) {
    let pagination = pagHelper.createTopicPagination(
        offset,
        limit,
        filter,
        orderBy
    );

    return get(`${controllerRoute}/paginated`, pagination);
}

export async function deleteTopic(teamId) {
    return remove(`${controllerRoute}/${teamId}`);
}

export async function createTopic(topic) {
    return post(`/api/${controllerRoute}/`, topic);
}

export async function getTopic(topicId) {
    return get(`/api/${controllerRoute}/${topicId}`);
}

export async function updateTopic(topic) {
    return put(`/api/${controllerRoute}/`, topic);
}
