import moment from 'moment';
import { Fragment, useCallback, useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createFilter } from 'react-select';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCSV } from '../../../shared/lib/useCSV';
import {
    getScheduledSurveys,
    getSurveyTypes,
} from '../../../../services/surveyService';
import { Aside } from '../../../shared/components/Aside';
import { TeamSelector } from '../../../shared/components/TeamSelector';
import { PulseSelect } from '../../../shared/inputs/PulseSelect';
import { SearchInput } from '../../../shared/inputs/SearchInput';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useCache } from '../../../shared/lib/useCache';
import { useClient } from '../../../shared/lib/useClient';
import { useMediaQueries } from '../../../shared/lib/useMediaQuery';
import { useSelectedTeam } from '../../../shared/lib/useSelectedTeam';
import { GradientLoading } from '../../../shared/loadings/GradientLoading';
import { Loading } from '../../../shared/loadings/Loading';
import { ExportPdfModal } from '../../../shared/modal/ExportPdfModal';
import {
    SurveyActor,
    SurveyBox,
} from '../../../shared/surveys/components/SurveyBox';
import { SurveyCompletionRateWidget } from '../../../shared/surveys/SurveyCompletionRateWidget';
import { DateSelector } from '../../../shared/utils/DateSelector';
import { PropTypes } from 'prop-types';
import './Pulse.scss';

SwiperCore.use([Navigation, Pagination]);

export function Pulse({ isTeam }) {
    const { mediumDown } = useMediaQueries();

    const navigate = useNavigate();

    const [dates, setDates] = useState(null);

    const [loading, setLoading] = useState(true);

    const [surveys, setSurveys] = useState(null);

    const [filteredSurveys, setFilteredSurveys] = useState(null);

    const [filterActive, setFilterActive] = useState(false);

    const { teams } = useCache();

    const client = useClient();

    const [sentToFilter, setSentToFilter] = useState(null);

    const [search, setSearch] = useState(null);

    const [status, setStatus] = useState(null);

    const [surveyType, setSurveyType] = useState(null);

    const [iteration, setIteration] = useState(null);

    const [orderBy, setOrderBy] = useState('releaseDate.desc');

    const { team: selectedTeam } = useSelectedTeam();

    const { exportTeamSurveyComments, exportSurveyComments } = useCSV();

    const statusOptions = [
        { label: 'Active', color: '#93c01f', value: 1 },
        // { label: 'Planned', color: '#aec6cf', value: 0 },
        { label: 'Closed', color: '#ff7171', value: 2 },
    ];

    const clientSelect = {
        label: client?.companyName,
        value: client.id,
        image: client?.logo,
    };

    const [surveyTypes, setSurveyTypes] = useState(null);

    //EXPORT MODAL

    const [survey, setSurvey] = useState(null);

    const [exportPdfModal, setExportPdfModal] = useState(false);

    const [exportOption, setExportOption] = useState(null);

    ///END EXPORT MODAL

    const exportOptions = {
        pdf: 0,
        csv: 1,
    };

    useEffect(() => {
        async function exportFunction() {
            switch (exportOption) {
                case exportOptions.pdf:
                    setExportPdfModal(true);

                    break;
                case exportOptions.csv:
                    isTeam
                        ? await exportTeamSurveyComments(
                              survey?.id,
                              selectedTeam?.id,
                              survey?.title
                          )
                        : await exportSurveyComments(survey?.id, survey?.title);
                    break;

                default:
                    break;
            }

            setExportOption(null);
        }

        if (survey && typeof exportOption === 'number') {
            exportFunction();
        }
    }, [survey, exportOption]);

    useEffect(() => {
        async function fetch() {
            setSurveyTypes(await getSurveyTypes());
        }

        fetch();
    }, []);

    useEffect(() => {
        if (surveys?.length) {
            let filterSurveys = surveys;

            if (sentToFilter) {
                if (sentToFilter === client?.id) {
                    filterSurveys = filterSurveys.filter(
                        (x) => x.sentTo === null
                    );
                } else {
                    filterSurveys = filterSurveys.filter((x) =>
                        x.sentTo?.some((y) => y?.id === sentToFilter)
                    );
                }
            }

            if (typeof status === 'number') {
                filterSurveys = filterSurveys.filter(
                    (x) => x.status === status
                );
            }

            if (typeof surveyType === 'number') {
                filterSurveys = filterSurveys.filter(
                    (x) => x.surveyType === surveyType
                );
            }

            if (search) {
                filterSurveys = filterSurveys.filter(
                    (x) =>
                        x?.title
                            ?.toLowerCase()
                            .includes(search?.toLowerCase()) ||
                        x?.questions
                            ?.map((x) => x.questionText)
                            .join(' ')
                            .toLowerCase()
                            .includes(search?.toLowerCase()) ||
                        x?.questions
                            ?.map((x) => x.topic?.title)
                            .join(' ')
                            .toLowerCase()
                            .includes(search?.toLowerCase())
                );
            }

            if (orderBy) {
                filterSurveys = filterSurveys?.sort((a, b) => {
                    const dateA = moment(a.releaseDate);
                    const dateB = moment(b.releaseDate);
                    return orderBy === 'releaseDate.desc'
                        ? dateB - dateA
                        : dateA - dateB;
                });
            }

            setFilteredSurveys([...filterSurveys]);
        }
    }, [sentToFilter, search, status, surveyType, surveys, orderBy]);

    useEffect(() => {
        async function fetchSurveys() {
            if (isFetching) {
                let s = await getScheduledSurveys(
                    dates?.from,
                    dates?.to,
                    orderBy,
                    null,
                    isTeam ? selectedTeam?.id : null
                );
                setSurveys([...s]);
            }

            setLoading(false);
        }

        if (
            dates?.from &&
            dates?.to &&
            moment(dates?.from).isValid() &&
            moment(dates?.to).isValid() &&
            orderBy
        ) {
            var isFetching = true;
            fetchSurveys();
        }

        return () => {
            isFetching = false;
        };
    }, [dates, selectedTeam?.id]);

    return (
        <Fragment>
            {isTeam && <TeamSelector />}
            <Page className='pulse-page'>
                <div className='main-filter'>
                    <DateSelector
                        onChange={(from, to, months) =>
                            setDates({ from: from, to: to, months: months })
                        }
                    />
                    {teams && (
                        <button
                            className={`btn ${
                                filterActive ? 'highlighted' : ''
                            }`}
                            onClick={() =>
                                setFilterActive((prev) => {
                                    if (prev) {
                                        setSentToFilter(null);
                                        setSearch(null);
                                        setStatus(null);
                                        setSurveyType(null);
                                        setOrderBy('releaseDate.desc');
                                    }
                                    return !prev;
                                })
                            }
                        >
                            <i className='icon icon-filter'></i> Filter
                        </button>
                    )}
                </div>
                {filterActive && (
                    <div className='secondary-filter'>
                        <SearchInput
                            onSearch={(text) => setSearch(text)}
                            placeholder={'Questions, topics or survey title...'}
                        />
                        {!isTeam && (
                            <PulseSelect
                                filterOption={createFilter({
                                    ignoreCase: true,
                                    ignoreAccents: true,
                                    matchFrom: 'any',
                                    stringify: (option) => option?.data?.label,
                                    trim: true,
                                })}
                                isClearable={true}
                                placeholder='Sent to'
                                className={'react-select sent-to'}
                                onChange={({ value }) => setSentToFilter(value)}
                                options={[
                                    clientSelect,
                                    ...teams?.map((x) => ({
                                        value: x.id,
                                        label: x?.title,
                                        image: x?.image,
                                    })),
                                ]}
                                getOptionLabel={(option) => (
                                    <Fragment>
                                        <img src={option?.image} />
                                        <label>{option?.label}</label>
                                    </Fragment>
                                )}
                            />
                        )}

                        <PulseSelect
                            isSearchable={false}
                            className={'react-select order-by'}
                            onChange={({ value }) => setStatus(value)}
                            placeholder='Status'
                            options={statusOptions}
                            isClearable={true}
                            getOptionLabel={(option) => (
                                <Fragment>
                                    <div className='status-option'>
                                        <div
                                            className='coloured-circle'
                                            style={{
                                                backgroundColor: option?.color,
                                            }}
                                        ></div>
                                        <label aria-label={option?.label}>
                                            {option?.label}
                                        </label>
                                    </div>
                                </Fragment>
                            )}
                        />

                        <PulseSelect
                            isSearchable={false}
                            className={'react-select one-off'}
                            onChange={(prop) =>
                                setSurveyType(
                                    prop?.value ? Number(prop?.value) : null
                                )
                            }
                            isClearable={true}
                            placeholder='Type'
                            options={surveyTypes}
                            getOptionLabel={(option) => (
                                <Fragment>
                                    <div
                                        className='status-option'
                                        role='option'
                                        aria-label={option?.label}
                                    >
                                        <i
                                            className={`icon icon-${
                                                option?.isRecurring
                                                    ? 'recurring'
                                                    : 'one-off'
                                            }`}
                                        ></i>
                                        <label>{option?.label}</label>
                                    </div>
                                </Fragment>
                            )}
                        />

                        <PulseSelect
                            isSearchable={false}
                            className={'react-select order-by'}
                            onChange={({ value }) => setOrderBy(value)}
                            placeholder='Order by'
                            options={[
                                {
                                    label: 'Newest',
                                    value: 'releaseDate.desc',
                                    asc: false,
                                },
                                {
                                    label: 'Oldest',
                                    value: 'releaseDate.asc',
                                    asc: true,
                                },
                            ]}
                            defaultValue={{
                                label: 'Newest',
                                value: 'releaseDate.desc',
                            }}
                            getOptionLabel={(option) => (
                                <Fragment>
                                    <div className='status-option'>
                                        <i
                                            className={`icon icon-${
                                                option?.asc
                                                    ? 'ascending'
                                                    : 'descending'
                                            }-sort`}
                                        ></i>
                                        <label>{option?.label}</label>
                                    </div>
                                </Fragment>
                            )}
                        />
                    </div>
                )}

                <div className='new-surveys'>
                    {loading ? (
                        <Fragment>
                            <GradientLoading className='loading-container'>
                                <div className='element1'></div>
                                <div className='element2'></div>
                                <div className='element3'></div>
                                <div className='element4'></div>
                            </GradientLoading>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {filteredSurveys?.map((s) => (
                                <SurveyBox
                                    clickable
                                    teamId={isTeam ? selectedTeam?.id : null}
                                    survey={s}
                                    insights={
                                        (selectedTeam?.id && isTeam
                                            ? Number(
                                                  s?.teamInsights
                                                      ?.find(
                                                          (x) =>
                                                              x?.team?.id ===
                                                              selectedTeam?.id
                                                      )
                                                      ?.completionRate?.toFixed(
                                                          0
                                                      )
                                              )
                                            : Number(
                                                  s?.insight?.completionRate?.toFixed(
                                                      0
                                                  )
                                              )) || 0
                                    }
                                    key={`filtered-surveys-${s.id}-${s?.iteration}`}
                                    onExportPdf={(sur) => {
                                        setSurvey(sur);
                                        setIteration(s?.iteration);
                                        setExportOption(exportOptions.pdf);
                                    }}
                                    onExportCsv={(sur) => {
                                        setSurvey(sur);
                                        setExportOption(exportOptions.csv);
                                    }}
                                />
                            ))}
                            {!surveys?.length && <h4>No surveys found</h4>}
                        </Fragment>
                    )}
                </div>
            </Page>

            {!mediumDown && (
                <Aside className='pulse-page'>
                    <div className='widgets'>
                        <MainContent
                            title={`Survey insights`}
                            line={true}
                            rightBox={
                                <i
                                    className='icon-progress-unactive'
                                    onClick={() =>
                                        navigate(
                                            '/company/feedback/pulse/completion-rate'
                                        )
                                    }
                                ></i>
                            }
                        >
                            <SurveyCompletionRateWidget
                                take={6}
                                filter={false}
                                from={dates?.from}
                                to={dates?.to}
                                onRedirect={() =>
                                    isTeam
                                        ? navigate(
                                              `/my-team/feedback/pulse/completion-rate/${selectedTeam?.id}`
                                          )
                                        : navigate(
                                              '/company/feedback/pulse/completion-rate'
                                          )
                                }
                                team={
                                    selectedTeam
                                        ? {
                                              label: selectedTeam?.title,
                                              value: selectedTeam?.id,
                                              ...selectedTeam,
                                          }
                                        : undefined
                                }
                                onRedirectTeam={(team) =>
                                    navigate(
                                        `/my-team/feedback/pulse/completion-rate/${team}`,
                                        { team: team }
                                    )
                                }
                            />
                        </MainContent>

                        <MainContent title={'Upcoming surveys'} line={true}>
                            <UpcomingSurveysWidget
                                dates={{
                                    from: moment.utc(),
                                    to: moment
                                        .utc()
                                        .add(dates?.months, 'months'),
                                }}
                                teamId={isTeam ? selectedTeam?.id : null}
                            />
                        </MainContent>
                    </div>
                </Aside>
            )}

            <Fragment>
                <ExportPdfModal
                    open={exportPdfModal}
                    questions={survey?.questions}
                    iteration={iteration ?? 0}
                    teamId={selectedTeam?.id}
                    survey={survey}
                    iterations={survey?.iterations ?? null}
                    onClose={() => {
                        setExportPdfModal(false);
                    }}
                />
            </Fragment>
        </Fragment>
    );
}

Pulse.propTypes = {
    isTeam: PropTypes.bool,
};

const UpcomingSurveysWidget = memo(({ dates, teamId }) => {
    const [surveys, setSurveys] = useState(null);
    const [loading, setLoading] = useState(true);

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return (
                '<span class="bullet-swiper ' +
                className +
                '">' +
                (index + 1) +
                '</span>'
            );
        },
    };

    const getUpcomingSurveys = useCallback(async () => {
        let s = await getScheduledSurveys(
            dates?.from,
            dates?.to,
            'releaseDate.asc',
            0,
            teamId
        );

        s = s?.length > 10 ? s.slice(0, 10) : s;
        setSurveys(s);
    }, [dates?.from, dates?.to, teamId]);

    useEffect(() => {
        async function fetch() {
            await getUpcomingSurveys();
            setLoading(false);
        }
        if (dates?.from && dates?.to) {
            fetch();
        }
    }, [dates?.from, dates?.to, teamId]);

    return (
        <Fragment>
            <Swiper
                pagination={pagination}
                className='mySwiper upcoming-surveys-widget'
            >
                {surveys?.length && !loading ? (
                    surveys?.map((s) => (
                        <SwiperSlide
                            key={`survey-widget-box-${s?.id}-${s?.iteration}`}
                        >
                            <SurveyWidgetBox survey={s} date={s?.releaseDate}>
                                <div className='options'>
                                    <div className='release-time'>
                                        <i className='icon-clock'></i>
                                        <label>
                                            {moment(s?.releaseDate).format(
                                                'HH:mm'
                                            )}
                                        </label>
                                    </div>
                                </div>
                            </SurveyWidgetBox>
                        </SwiperSlide>
                    ))
                ) : (
                    <SwiperSlide>
                        <div className='empty-loading'>
                            {loading ? (
                                <Loading />
                            ) : (
                                <h3>There are no upcoming surveys.</h3>
                            )}
                        </div>
                    </SwiperSlide>
                )}
            </Swiper>
        </Fragment>
    );
});

UpcomingSurveysWidget.propTypes = {
    dates: PropTypes.object,
    teamId: PropTypes.any,
};

function SurveyWidgetBox({ survey, date, children }) {
    const client = useClient();

    return (
        <div className='survey-widget-box'>
            <div className='title'>
                <h4>{survey?.title}</h4>
                <i
                    className={`icon icon-${
                        survey?.surveyType === 0 ? 'one-off' : 'recurring'
                    }`}
                    title={survey?.surveyType === 0 ? 'One Off' : 'Recurring'}
                ></i>
                {survey.isAnonymous && (
                    <i
                        role='img'
                        aria-label='Anonymous'
                        className='icon icon-eye-anonymous'
                        title='Anonymous'
                    />
                )}
                {typeof survey?.iteration === 'number' && (
                    <h4>#{survey?.iteration + 1}</h4>
                )}
            </div>
            <div className='sent-to'>
                <div>
                    {survey?.sentTo?.length ? (
                        <Fragment>
                            <SurveyActor
                                actor={survey?.sentTo[0]}
                                title={survey?.sentTo[0]?.name}
                            />
                            {survey?.sentTo?.length > 1 && (
                                <SurveyActor
                                    title={survey?.sentTo
                                        ?.slice(1, survey?.sentTo?.length)
                                        ?.map((x) => x?.name)
                                        .join(', ')}
                                    actor={{
                                        name: `+${survey?.sentTo?.length - 1}`,
                                    }}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <SurveyActor
                            actor={{
                                image: client?.logo,
                                name: client?.companyName,
                            }}
                            title={client?.companyName}
                        />
                    )}
                </div>

                <div className='date'>
                    <h6>{moment(date).format('DD/MM/YYYY')}</h6>
                </div>
            </div>
            {children}
        </div>
    );
}

SurveyWidgetBox.propTypes = {
    survey: PropTypes.object,
    date: PropTypes.any,
    children: PropTypes.object,
};
