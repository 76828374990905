import moment from 'moment';
import { Fragment, useContext, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { createFilter } from 'react-select';
import { AppActionTypes, AppContext } from '../../../../contexts/AppContext';
import {
    calculateIteration,
    getSurvey,
    getRecurrenceTypes,
    getDayOfWeekTypes,
    getSequenceTypes,
    getMonthTypes,
    createSurvey,
    updateSurvey,
    SURVEY_STATUS_TYPE,
} from '../../../../services/surveyService';
import { getTeams } from '../../../../services/teamService';
import { SubmitButton } from '../../../shared/components/SubmitButton';
import { AddButton } from '../../../shared/inputs/AddButton';
import { PulseSelect } from '../../../shared/inputs/PulseSelect';
import { useClient } from '../../../shared/lib/useClient';
import { Loading } from '../../../shared/loadings/Loading';
import { SurveyQuestion } from '../../../shared/surveys/components/SurveyBox';
import { QuestionSelectModal } from './components/QuestionSelectModal';
import { PropTypes } from 'prop-types';
import { MainContent } from '../../../shared/layout/MainContent';
import './Survey.scss';

export function Survey() {
    const { id, type } = useParams();

    const [survey, setSurvey] = useState(null);

    const [teams, setTeams] = useState(null);

    const client = useClient();

    const [loading, setLoading] = useState(true);

    const [questionModal, setQuestionModal] = useState(false);

    const [recurrenceOptions, setRecurrenceOptions] = useState([]);

    const [dayOfWeekOptions, setDayOfWeekOptions] = useState([]);

    const [sequenceOptions, setSequenceOptions] = useState([]);

    const [monthOptions, setMonthOptions] = useState([]);

    const navigate = useNavigate();

    const [questions, setQuestions] = useState([]);

    const [questionsError, setQuestionsError] = useState(null);

    const [recurrenceError, setRecurrenceError] = useState(null);

    const [recurrence, setRecurrence] = useState({
        type: 0,
        everyWeekOrMonth: 1,
        sequence: 0,
    });

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useForm();

    const { dispatch } = useContext(AppContext);

    const [form, setForm] = useState(null);

    const watchReleaseDate = useWatch({
        control,
        name: 'releaseDate', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        defaultValue: null, // default value before the render
    });

    const watchReleaseTime = watch('releaseTime', null);

    const isOneOff = Number(type) === 0 || survey?.surveyType === 0;

    const [clientSelect, setClientSelect] = useState(null);

    useEffect(() => {
        if (client) {
            setClientSelect({
                label: client?.companyName,
                value: client.id,
                image: client?.logo,
            });
            setRecurrence({
                type:
                    client?.surveyConfiguration?.surveyDurationDays > 7 ? 1 : 0,
                everyWeekOrMonth: 1,
                sequence: 0,
            });
        }
    }, [client]);

    useEffect(() => {
        async function fetch() {
            let s = await getSurvey(id);
            setSurvey(s);
        }

        if (!id && !type) {
            throw new Error('Issue loading surveys');
        }

        if (id) {
            fetch();
        }
    }, [id]);

    const isEditable =
        (survey?.status === SURVEY_STATUS_TYPE.Planned || survey === null) &&
        !survey?.iterations?.length;

    useEffect(() => {
        if (survey && !loading) {
            reset({
                title: survey?.title,

                sentTo: survey?.sentTo?.map((x) => ({
                    label: x.name,
                    value: x.id,
                    image: x.image,
                })) ?? [clientSelect],

                releaseDate: moment(survey?.releaseDate).toDate(),
                releaseTime: moment(survey?.releaseDate).toDate(),
                isAnonymous: survey?.isAnonymous.toString(),
            });

            setQuestions([...survey.questions]);

            if (survey?.surveyType === 1) {
                setRecurrence(
                    { ...survey?.recurrence } ?? {
                        type:
                            client?.surveyConfiguration?.surveyDurationDays > 7
                                ? 1
                                : 0,
                        everyWeekOrMonth: 1,
                        sequence: 0,
                    }
                );
            }
        }
    }, [survey, clientSelect, loading]);

    useEffect(() => {
        async function fetch() {
            let [t, rec, day, sequenceTypesToMap, monthTypesToMap] =
                await Promise.all([
                    getTeams(),
                    getRecurrenceTypes(),
                    getDayOfWeekTypes(),
                    getSequenceTypes(),
                    getMonthTypes(),
                ]);

            setTeams(t);

            if (client?.surveyConfiguration?.surveyDurationDays > 7) {
                rec = rec.filter((x) => x.label !== 'Weekly');
            }
            setRecurrenceOptions(rec);

            setSequenceOptions(
                sequenceTypesToMap?.map((x) => ({
                    label: x.key,
                    value: x.value,
                }))
            );

            day.push(day.shift());

            setDayOfWeekOptions(
                day?.map((x) => ({
                    label: x.key,
                    value: x.value,
                }))
            );

            setMonthOptions(
                monthTypesToMap?.map((x) => ({
                    label: x.key,
                    value: x.value,
                }))
            );

            setLoading(false);
        }

        fetch();
    }, []);

    useEffect(() => {
        if (
            moment(watchReleaseTime).diff(moment(), 'minutes') < 0 &&
            moment(watchReleaseDate).isSameOrBefore(moment(), 'day')
        ) {
            setValue(
                'releaseTime',
                (moment().hour(),
                moment().minute() > 30
                    ? moment().set('minute', 0).add(1, 'hour')
                    : moment().set('minute', 30)).toDate()
            );
        }
    }, [watchReleaseDate]);

    const transform = (callback) => async (data) => {
        let rD = moment(data?.releaseDate);
        let time = moment(data?.releaseTime);

        rD.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
            second: time.get('second'),
        });

        if (!isOneOff) {
            rD = moment(await calculateIteration(recurrence, rD, 0));
        }

        callback({
            ...data,
            sentTo:
                data?.sentTo?.length === 1 &&
                data?.sentTo[0]?.value === client.id
                    ? null
                    : data?.sentTo?.map((x) => x.value),
            releaseDate: rD.toISOString(),
            surveyType:
                typeof type === 'string' ? Number(type) : survey?.surveyType,
            id: survey?.id,
        });
    };

    async function onSubmit(request) {
        if (!request) {
            throw new Error('Request is null');
        }

        setQuestionsError(
            !questions?.length ? 'At least 1 question is required' : null
        );

        setRecurrenceError(
            !recurrence ? 'Recurrence is required for recurring survey' : null
        );

        if (
            request &&
            questions?.length &&
            (isOneOff || (!isOneOff && recurrence))
        ) {
            setForm({ loading: true });

            request.questions = [...questions.map((x) => x.id)];
            request.recurrence = recurrence;
            try {
                if (!request?.id) {
                    await createSurvey(request);
                } else {
                    await updateSurvey(request);
                }

                setForm({ loading: false, success: true });

                setTimeout(
                    () => dispatch({ type: AppActionTypes.REFRESH }),
                    500
                );
            } catch (e) {
                setForm({ loading: false, success: false });
            } finally {
                setTimeout(() => {
                    setForm(null);
                    reset({
                        title: null,
                        questions: null,
                        sentTo: [],
                        recurrence: null,
                        releaseDate: null,
                        releaseTime: null,
                    });
                    navigate('/admin/surveys-settings');
                }, 500);
            }
        }
    }

    useEffect(() => {
        if (
            watchReleaseDate &&
            moment(watchReleaseDate).isValid() &&
            recurrence
        ) {
            if (recurrence?.type === 0) {
                setRecurrence({
                    ...recurrence,
                    day: moment(watchReleaseDate).day(),
                });
            } else if (recurrence?.sequence === 0) {
                setRecurrence({
                    ...recurrence,
                    day: moment(watchReleaseDate).date(),
                });
            }
        }
    }, [watchReleaseDate, recurrence?.sequence]);

    function onError() {
        setQuestionsError(
            !questions?.length ? 'At least 1 question is required' : null
        );

        setRecurrenceError(
            !recurrence ? 'Recurrence is required for recurring survey' : null
        );
    }

    function moveElement(array, initialIndex, finalIndex) {
        array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);

        return array;
    }

    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <MainContent style={{ maxWidth: '80rem' }}>
                    <form
                        onSubmit={
                            !questionModal
                                ? handleSubmit(transform(onSubmit), onError)
                                : null
                        }
                        className='survey'
                    >
                        <div className='row'>
                            <div className='group-form'>
                                <label htmlFor='title'>Title</label>
                                <input
                                    id='title'
                                    disabled={!isEditable}
                                    type='text'
                                    {...register('title', {
                                        required: 'Title is required',
                                    })}
                                    autoFocus
                                />

                                {errors?.title?.message && (
                                    <div className='error'>
                                        {errors?.title?.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='group-form'>
                                <label>To</label>

                                <Controller
                                    name='sentTo'
                                    control={control}
                                    defaultValue={null}
                                    disabled={!isEditable}
                                    rules={{ required: 'Sent to is required' }}
                                    render={({ field }) => (
                                        <PulseSelect
                                            {...field}
                                            filterOption={createFilter({
                                                ignoreCase: true,
                                                ignoreAccents: true,
                                                matchFrom: 'any',
                                                stringify: (option) =>
                                                    option?.data?.label,
                                                trim: true,
                                            })}
                                            className={'react-select sent-to'}
                                            isMulti
                                            isDisabled={!isEditable}
                                            onChange={(props) =>
                                                // eslint-disable-next-line react/prop-types
                                                props.find(
                                                    (x) => x.value === client.id
                                                )
                                                    ? field.onChange([
                                                          clientSelect,
                                                      ])
                                                    : field.onChange(props)
                                            }
                                            options={[
                                                clientSelect,
                                                ...teams.map((x) => ({
                                                    value: x.id,
                                                    label: x?.title,
                                                    image: x?.image,
                                                })),
                                            ]}
                                            getOptionLabel={(option) => (
                                                <Fragment>
                                                    <img src={option?.image} />
                                                    <label>
                                                        {option?.label}
                                                    </label>
                                                </Fragment>
                                            )}
                                        />
                                    )}
                                />

                                {errors?.sentTo?.message && (
                                    <div className='error'>
                                        {errors?.sentTo?.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='group-form'>
                                <label>Questions</label>

                                <Fragment>
                                    <AddButton
                                        disabled={!isEditable}
                                        onClick={() => setQuestionModal(true)}
                                    >
                                        Add
                                    </AddButton>

                                    <QuestionSelectModal
                                        show={questionModal}
                                        onClose={() => setQuestionModal(false)}
                                        onChange={(questions) => {
                                            setQuestions([...questions]);
                                            setQuestionModal(false);
                                            setQuestionsError(
                                                !questions?.length
                                                    ? 'At least 1 question is required'
                                                    : null
                                            );
                                        }}
                                        questionIds={
                                            questions?.length
                                                ? [
                                                      ...questions.map(
                                                          (x) => x.id
                                                      ),
                                                  ]
                                                : []
                                        }
                                    />

                                    {!!questions?.length && (
                                        <div>
                                            {questions?.map((q, i) => (
                                                <SurveyQuestion
                                                    question={{
                                                        ...q,
                                                        questionText: `${
                                                            i + 1
                                                        }. ${q.questionText}`,
                                                    }}
                                                    key={`survey-question-${i}`}
                                                    survey
                                                >
                                                    <div className='arrows'>
                                                        {i !== 0 && (
                                                            <i
                                                                className='icon-triangle-up'
                                                                onClick={() =>
                                                                    setQuestions(
                                                                        [
                                                                            ...moveElement(
                                                                                questions,
                                                                                i,
                                                                                i -
                                                                                    1
                                                                            ),
                                                                        ]
                                                                    )
                                                                }
                                                            ></i>
                                                        )}
                                                        {i !==
                                                            questions?.length -
                                                                1 && (
                                                            <i
                                                                className='icon-triangle-down'
                                                                onClick={() =>
                                                                    setQuestions(
                                                                        [
                                                                            ...moveElement(
                                                                                questions,
                                                                                i,
                                                                                i +
                                                                                    1
                                                                            ),
                                                                        ]
                                                                    )
                                                                }
                                                            ></i>
                                                        )}
                                                    </div>
                                                </SurveyQuestion>
                                            ))}
                                        </div>
                                    )}
                                </Fragment>

                                {questionsError && (
                                    <div className='error'>
                                        {questionsError}
                                    </div>
                                )}
                            </div>
                        </div>

                        {!isOneOff && (
                            <div className='row recurrence'>
                                <Fragment>
                                    <div className='group-form'>
                                        <label>Recurrence</label>
                                        <PulseSelect
                                            isDisabled={!isEditable}
                                            className='recurring'
                                            value={recurrenceOptions?.find(
                                                (x) =>
                                                    Number(x.value) ===
                                                    recurrence?.type
                                            )}
                                            options={recurrenceOptions}
                                            defaultOptions
                                            onChange={(prop) => {
                                                setRecurrence({
                                                    ...recurrence,
                                                    type: Number(prop?.value),
                                                });
                                            }}
                                            isClearable={false}
                                            isSearchable={false}
                                        />
                                    </div>

                                    <div className='group-form'>
                                        <label>Every</label>
                                        {recurrence?.type !== 2 ? (
                                            <Fragment>
                                                <div className='every-input'>
                                                    <input
                                                        disabled={!isEditable}
                                                        type='text'
                                                        onChange={(ev) => {
                                                            setRecurrence({
                                                                ...recurrence,
                                                                everyWeekOrMonth:
                                                                    Number(
                                                                        ev
                                                                            .target
                                                                            .value
                                                                    ),
                                                            });
                                                        }}
                                                        value={
                                                            recurrence?.everyWeekOrMonth
                                                        }
                                                    />
                                                    <span>
                                                        {' '}
                                                        {recurrence?.type === 0
                                                            ? 'week(s)'
                                                            : 'month(s)'}
                                                    </span>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <PulseSelect
                                                isDisabled={!isEditable}
                                                className='recurring'
                                                value={monthOptions?.find(
                                                    (x) =>
                                                        Number(x.value) ===
                                                        recurrence?.everyWeekOrMonth
                                                )}
                                                options={monthOptions}
                                                defaultOptions
                                                onChange={(prop) =>
                                                    setRecurrence({
                                                        ...recurrence,
                                                        everyWeekOrMonth:
                                                            Number(prop?.value),
                                                    })
                                                }
                                                isClearable={false}
                                                isSearchable={false}
                                            />
                                        )}
                                    </div>

                                    {recurrence?.type !== 0 && (
                                        <Fragment>
                                            <div className='group-form'>
                                                <label>On</label>
                                                <PulseSelect
                                                    isDisabled={!isEditable}
                                                    className='recurring'
                                                    value={sequenceOptions?.find(
                                                        (x) =>
                                                            Number(x.value) ===
                                                            recurrence?.sequence
                                                    )}
                                                    options={sequenceOptions}
                                                    defaultOptions
                                                    onChange={(prop) =>
                                                        setRecurrence({
                                                            ...recurrence,
                                                            sequence: Number(
                                                                prop?.value
                                                            ),
                                                        })
                                                    }
                                                    isClearable={false}
                                                    isSearchable={false}
                                                />
                                            </div>
                                            {recurrence?.sequence !== 0 && (
                                                <div className='group-form margin'>
                                                    <PulseSelect
                                                        isDisabled={!isEditable}
                                                        className='recurring'
                                                        value={dayOfWeekOptions?.find(
                                                            (x) =>
                                                                Number(
                                                                    x.value
                                                                ) ===
                                                                recurrence?.day
                                                        )}
                                                        options={
                                                            dayOfWeekOptions
                                                        }
                                                        defaultOptions
                                                        onChange={(prop) =>
                                                            setRecurrence({
                                                                ...recurrence,
                                                                day: Number(
                                                                    prop?.value
                                                                ),
                                                            })
                                                        }
                                                        isClearable={false}
                                                        isSearchable={false}
                                                    />
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                </Fragment>
                            </div>
                        )}

                        {recurrenceError && (
                            <div className='error'>{recurrenceError}</div>
                        )}
                        <div className='row release-date'>
                            <div className='group-form'>
                                <label>Release Date</label>

                                <Controller
                                    name='releaseDate'
                                    control={control}
                                    defaultValue={null}
                                    disabled={!isEditable}
                                    rules={{
                                        required: 'Release date is required',
                                    }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Fragment>
                                            <ReactDatePicker
                                                disabled={!isEditable}
                                                onChange={(props) => {
                                                    onChange(props);
                                                }}
                                                disabledDayAriaLabelPrefix={
                                                    !isEditable
                                                }
                                                inline
                                                selected={value}
                                                minDate={moment().toDate()}
                                                maxDate={
                                                    !isEditable &&
                                                    moment(value).toDate()
                                                }
                                            />
                                        </Fragment>
                                    )}
                                />

                                {errors?.releaseDate?.message && (
                                    <div className='error'>
                                        {errors?.releaseDate?.message}
                                    </div>
                                )}
                            </div>

                            <div className='group-form'>
                                <label>Release Time</label>
                                <Controller
                                    name='releaseTime'
                                    control={control}
                                    defaultValue={null}
                                    rules={{
                                        required: 'Release time is required',
                                        validate: (value) => {
                                            if (
                                                moment().isSame(
                                                    moment(watchReleaseDate),
                                                    'day'
                                                )
                                            ) {
                                                return (
                                                    moment().diff(
                                                        moment(value),
                                                        'millisecond'
                                                    ) < 0 ||
                                                    'It should be greater than now'
                                                );
                                            }
                                        },
                                    }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Fragment>
                                            <ReactDatePicker
                                                disabled={
                                                    !isEditable ||
                                                    !watchReleaseDate
                                                }
                                                onChange={onChange}
                                                onChangeRaw={(e) =>
                                                    e.preventDefault()
                                                }
                                                minTime={
                                                    moment(
                                                        watchReleaseDate
                                                    ).isSameOrBefore(
                                                        moment(),
                                                        'day'
                                                    )
                                                        ? moment().toDate()
                                                        : moment()
                                                              .startOf('day')
                                                              .toDate()
                                                }
                                                maxTime={moment()
                                                    .endOf('day')
                                                    .toDate()}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                selected={value}
                                                timeIntervals={30}
                                                timeCaption='Time'
                                                timeFormat='HH:mm'
                                                dateFormat='HH:mm'
                                            />
                                        </Fragment>
                                    )}
                                />

                                {errors?.releaseTime?.message && (
                                    <div className='error'>
                                        {errors?.releaseTime?.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        {!isOneOff && (
                            <div className='row'>
                                <RecurringOptions
                                    control={control}
                                    recurrence={recurrence}
                                />
                            </div>
                        )}

                        <div style={{ marginTop: '1rem' }}>
                            <label>
                                I want responses and any subsequent comments to
                                be anonymous.
                            </label>
                            <div style={{ display: 'flex', marginTop: '1rem' }}>
                                <div className='pulse360-radio'>
                                    <input
                                        aria-label='Yes'
                                        aria-labelledby='radioButton-yes'
                                        aria-invalid={
                                            errors?.value &&
                                            errors?.value.message
                                                ? true
                                                : false
                                        }
                                        aria-describedby='error'
                                        type='radio'
                                        value={true}
                                        {...register('isAnonymous', {
                                            required:
                                                'Anonymity setting is required',
                                        })}
                                        disabled={!isEditable}
                                    />
                                    <label id='radioButton-yes'>Yes</label>
                                </div>
                                <div
                                    className='pulse360-radio'
                                    style={{ marginLeft: '3rem' }}
                                >
                                    <input
                                        aria-label='No'
                                        aria-labelledby='radioButton-no'
                                        type='radio'
                                        value={false}
                                        {...register('isAnonymous', {
                                            required:
                                                'Anonymity setting is required',
                                        })}
                                        disabled={!isEditable}
                                    />
                                    <label id='radioButton-no'>No</label>
                                </div>
                            </div>
                            {errors?.isAnonymous?.message && (
                                <div className='error'>
                                    {errors?.isAnonymous?.message}
                                </div>
                            )}
                        </div>
                        <div className='row'>
                            <SubmitButton
                                loading={form?.loading}
                                success={form?.success}
                                disabled={form?.disabled || !isEditable}
                            >
                                Save
                            </SubmitButton>
                        </div>
                    </form>
                </MainContent>
            )}
        </div>
    );
}

function RecurringOptions({ control, recurrence }) {
    const releaseDate = useWatch({
        control,
        name: 'releaseDate', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        defaultValue: null, // default value before the render
    });

    const releaseTime = useWatch({
        control,
        name: 'releaseTime', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        defaultValue: null, // default value before the render
    });

    const [firstDate, setFirstDate] = useState(null);

    return (
        <div className='recurring-option'>
            <RecurringBox
                startDate={releaseDate}
                releaseTime={releaseTime}
                recurrence={recurrence}
                iteration={0}
                onCalculate={(date) => setFirstDate(date)}
            />

            <RecurringBox
                startDate={firstDate}
                releaseTime={releaseTime}
                recurrence={recurrence}
                iteration={1}
            />

            <RecurringBox
                startDate={firstDate}
                releaseTime={releaseTime}
                recurrence={recurrence}
                iteration={2}
            />
        </div>
    );
}

RecurringOptions.propTypes = {
    control: PropTypes.object,
    recurrence: PropTypes.object,
};

function RecurringBox({
    startDate,
    releaseTime,
    iteration,
    recurrence,
    onCalculate,
}) {
    const [date, setDate] = useState(null);

    useEffect(() => {
        async function fetch() {
            let rD = moment(startDate);
            let time = moment(releaseTime);
            rD.set({
                hour: time.get('hour'),
                minute: time.get('minute'),
                second: time.get('second'),
            });
            let calculate = await calculateIteration(recurrence, rD, iteration);
            setDate(calculate);

            if (onCalculate) {
                onCalculate(calculate);
            }
        }
        if (recurrence && startDate && releaseTime) {
            if (typeof recurrence?.type === 'number' && startDate) {
                fetch();
            }
        }
    }, [startDate, releaseTime, iteration, recurrence]);

    return (
        <div className='recurring-box'>
            <label>
                {iteration + 1}
                {nth(iteration + 1)} iteration:
            </label>
            <svg x='0px' y='0px' viewBox='0 0 130 114'>
                <path
                    fill='#4CBABB'
                    d='M19,6h92c5.5,0,10,4.5,10,10v30l0,0H9l0,0V16C9,10.5,13.5,6,19,6z'
                />
                <path
                    fill='none'
                    stroke='#4CBABB'
                    d='M19,6.5h92c5.2,0,9.5,4.3,9.5,9.5v29c0,0.3-0.2,0.5-0.5,0.5H10c-0.3,0-0.5-0.2-0.5-0.5V16
	C9.5,10.8,13.8,6.5,19,6.5z'
                />
                <circle fill='#FFFFFF' cx='37' cy='18' r='7' />
                <circle fill='none' stroke='#B4F8C8' cx='37' cy='18' r='6.5' />
                <circle fill='#FFFFFF' cx='93' cy='18' r='7' />
                <circle fill='none' stroke='#B4F8C8' cx='93' cy='18' r='6.5' />
                <path
                    fill='#424953'
                    d='M37,1L37,1c2.8,0,5,2.2,5,5v12c0,2.8-2.2,5-5,5l0,0c-2.8,0-5-2.2-5-5V6C32,3.2,34.2,1,37,1z'
                />
                <path
                    fill='none'
                    stroke='#424953'
                    d='M37,1.5L37,1.5c2.5,0,4.5,2,4.5,4.5v12c0,2.5-2,4.5-4.5,4.5l0,0c-2.5,0-4.5-2-4.5-4.5V6
	C32.5,3.5,34.5,1.5,37,1.5z'
                />
                <path
                    fill='#424953'
                    d='M93,1L93,1c2.8,0,5,2.2,5,5v12c0,2.8-2.2,5-5,5l0,0c-2.8,0-5-2.2-5-5V6C88,3.2,90.2,1,93,1z'
                />
                <path
                    fill='none'
                    stroke='#424953'
                    d='M93,1.5L93,1.5c2.5,0,4.5,2,4.5,4.5v12c0,2.5-2,4.5-4.5,4.5l0,0c-2.5,0-4.5-2-4.5-4.5V6
	C88.5,3.5,90.5,1.5,93,1.5z'
                />
                <path
                    fill='#FFFFFF'
                    d='M9,45h112l0,0v47c0,5.5-4.5,10-10,10H19c-5.5,0-10-4.5-10-10V45L9,45z'
                />
                <path
                    fill='none'
                    stroke='#D3D7DF'
                    d='M10,45.5h110c0.3,0,0.5,0.2,0.5,0.5v46c0,5.2-4.3,9.5-9.5,9.5l0,0H19c-5.2,0-9.5-4.3-9.5-9.5
	l0,0V46C9.5,45.7,9.7,45.5,10,45.5z'
                />
                <text
                    x='50%'
                    y='32%'
                    dominantBaseline='middle'
                    textAnchor='middle'
                    fill='#424953'
                    fontFamily="'OpenSans'"
                    fontSize='12px'
                >
                    {date && moment(date).format('MMM YY').toUpperCase()}
                </text>
                <text
                    x='50%'
                    y='50%'
                    dominantBaseline='middle'
                    textAnchor='middle'
                    fill='#424953'
                    fontFamily="'OpenSans'"
                    fontSize='12px'
                >
                    {date && moment(date).format('dddd').toUpperCase()}
                </text>
                <text
                    x='50%'
                    y='70%'
                    dominantBaseline='middle'
                    textAnchor='middle'
                    fill='#424953'
                    fontFamily="'OpenSans-Bold'"
                    fontSize='30px'
                >
                    {date && moment(date).format('DD').toUpperCase()}
                </text>
            </svg>
        </div>
    );

    function nth(n) {
        return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
    }
}

RecurringBox.propTypes = {
    startDate: PropTypes.date,
    releaseTime: PropTypes.date,
    iteration: PropTypes.number,
    recurrence: PropTypes.object,
    onCalculate: PropTypes.func,
};
