import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { PropTypes } from 'prop-types';
import { getDateFilterTypes } from '../../../../services/surveyService';
import 'react-datepicker/dist/react-datepicker.min.css';
import './DateSection.scss';

export function DateSection({ className, onChange, from, to }) {
    const [fromDate, setFrom] = useState(null);

    const [toDate, setTo] = useState(null);

    const [dateType, setDateType] = useState([]);

    useEffect(() => {
        if (from) {
            setFrom(moment.isMoment(from) ? from : moment(from));
        } else {
            setFrom(moment().add(-1, 'months'));
        }

        if (to) {
            setTo(moment.isMoment(to) ? to : moment(to));
        } else {
            setTo(moment());
        }
    }, [from, to]);

    useEffect(() => {
        async function fetch() {
            const types = await getDateFilterTypes();
            setDateType(types);
        }

        fetch();
    }, []);

    function handleDateButtons({ target: { value } }) {
        let t = moment();
        let f = moment().add(-value, 'months');

        setFrom(f);
        setTo(t);
        onChange(f, t);
    }

    function handleFrom(f) {
        setFrom(f);
        onChange(f, toDate);
    }

    function handleTo(t) {
        setTo(t);
        onChange(fromDate, t);
    }

    return (
        <Fragment>
            <div className={`date-section ${className ?? ''}`}>
                <div className='title'>Duration</div>
                <div>
                    <span>
                        From:
                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            selected={
                                fromDate ? moment(fromDate).toDate() : null
                            }
                            onChange={handleFrom}
                            isClearable={false}
                            maxDate={toDate ? moment(toDate).toDate() : null}
                        />
                    </span>
                </div>
                <div>
                    <span>
                        To:
                        <DatePicker
                            dateFormat='dd/MM/yyyy'
                            selected={toDate ? moment(toDate).toDate() : null}
                            onChange={handleTo}
                            isClearable={false}
                            maxDate={moment().toDate()}
                            minDate={
                                fromDate ? moment(fromDate).toDate() : null
                            }
                        />
                    </span>
                </div>
                <div className='buttons'>
                    {dateType.map((type, i) => (
                        <Fragment key={`rct-${i}`}>
                            <button
                                key={`btn-${i}`}
                                className={`btn column-${i % 2} ${
                                    Math.round(
                                        toDate.diff(fromDate, 'months', true)
                                    ) === parseInt(type.value)
                                        ? 'highlighted'
                                        : ''
                                }`}
                                value={type.value}
                                onClick={handleDateButtons}
                            >
                                {type.key}
                            </button>
                        </Fragment>
                    ))}
                </div>
            </div>
        </Fragment>
    );
}

DateSection.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
