import moment from 'moment';

export function humanizeDiffToday(date) {
    let duration = moment.duration(moment(date).diff(moment.utc()));
    let before = moment.utc().isBefore(moment(date));
    return (
        <span style={{whiteSpace: 'nowrap', fontSize: '1.3rem'}} title={moment(date).format('DD/MM/YYYY')}>
            {before && 'Expires '} {duration.humanize(true)}
        </span>
    );
}

export function humanizeRecurrence(recurrence) {
    function sequenceToString(sequence, day) {
        let str = '';

        switch (sequence) {
            case 1:
                str += 'the first ';
                break;
            case 2:
                str += 'the second ';
                break;
            case 3:
                str += 'the third ';
                break;
            case 4:
                str += 'the fourth ';
                break;
            case 5:
                str += 'the last ';
                break;
            default:
                break;
        }

        switch (day) {
            case 0:
                str += 'Sunday';
                break;
            case 1:
                str += 'Monday';
                break;
            case 2:
                str += 'Tuesday';
                break;
            case 3:
                str += 'Wednesday';
                break;
            case 4:
                str += 'Thursday';
                break;
            case 5:
                str += 'Friday';
                break;
            case 6:
                str += 'Saturday';
                break;
            default:
                break;
        }

        return str;
    }

    function everyMonthOfYearToString(everyWeekOrMonth) {
        let str = '';
        switch (everyWeekOrMonth) {
            case 1:
                str += 'January';
                break;
            case 2:
                str += 'February';
                break;
            case 3:
                str += 'March';
                break;
            case 4:
                str += 'April';
                break;
            case 5:
                str += 'May';
                break;
            case 6:
                str += 'June';
                break;
            case 7:
                str += 'July';
                break;
            case 8:
                str += 'August';
                break;
            case 9:
                str += 'September';
                break;
            case 10:
                str += 'October';
                break;
            case 11:
                str += 'November';
                break;
            case 12:
                str += 'December';
                break;
            default:
                break;
        }

        return str;
    }

    return `Every${
        recurrence?.type === 0 || recurrence?.type === 1
            ? ` ${recurrence?.everyWeekOrMonth} `
            : ''
    }${
        recurrence?.type === 0
            ? ' week(s) '
            : recurrence?.type === 1
            ? ' month(s) '
            : ` year on ${everyMonthOfYearToString(
                  recurrence?.everyWeekOrMonth
              )}`
    } on 
        ${
            recurrence?.sequence === 0
                ? `${moment()
                      .startOf('week')
                      .add(recurrence?.day, 'days')
                      .format('dddd')}`
                : sequenceToString(recurrence?.sequence, recurrence?.day)
        }`;
}
