import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useAsyncError } from '../lib/helper';
import { PropTypes } from 'prop-types';
import { Table } from '../components/Table';
import './utils.scss';

export function ResponsiveTable({
    className,
    columns,
    limit,
    onFetchData,
    noDataText,
    filter,
    customFilters,
    defaultSorted,
    refresh,
    onRefresh,
}) {
    const [loading, setLoading] = useState(true);

    const [hasMoreValues, setHasMoreValues] = useState(true);

    const [fetch, setFetch] = useState(true);

    const [data, setData] = useState([]);

    const [offset, setOffset] = useState(0);

    const bottomBoundaryRef = useRef(null);

    const [sorting, setSorting] = useState(defaultSorted);

    const [total, setTotal] = useState(null);

    const throwError = useAsyncError();

    useEffect(() => {
        if (refresh) {
            setTimeout(() => reloadTable(), 300);
            if (onRefresh) {
                onRefresh();
            }
        }
    }, [refresh]);

    async function fetchData() {
        try {
            setLoading(true);

            let newData = await onFetchData(
                offset,
                limit,
                filter,
                sorting ?? defaultSorted,
                customFilters
            );

            setTotal(newData.total);
            setData([...data, ...newData.data]);

            setHasMoreValues(newData.hasMoreItems);

            setOffset(offset + limit);

            setLoading(false);

            setFetch(false);
        } catch (e) {
            throwError(e);
        }
    }

    const scrollObserver = useCallback(
        (node) => {
            new IntersectionObserver((entries) => {
                entries.forEach((en) => {
                    if (en.intersectionRatio > 0) {
                        setFetch(true);
                    }
                });
            }).observe(node);
        },
        [data?.length]
    );
    useEffect(() => {
        if (bottomBoundaryRef?.current) {
            scrollObserver(bottomBoundaryRef.current);
        }
    }, [scrollObserver, bottomBoundaryRef]);

    function reloadTable() {
        setFetch(false);
        setHasMoreValues(true);
        setData([]);
        setOffset(0);
        setFetch(true);
    }

    useEffect(() => {
        reloadTable();
    }, [filter, sorting]);

    useEffect(() => {
        if (fetch && hasMoreValues) {
            fetchData();
        }
    }, [fetch, hasMoreValues]);

    return (
        <Fragment>
            <Table
                className={className ?? ''}
                data={data}
                loading={loading}
                noDataText={noDataText}
                onSortedChange={(newSorted) => setSorting(newSorted[0])}
                columns={[
                    ...columns.filter((x) => x.Header !== 'total-here'),
                    ...[
                        columns.find((x) => x.Header === 'total-here')
                            ? {
                                  ...columns?.find(
                                      (x) => x.Header === 'total-here'
                                  ),
                                  headerClassName: 'text-right',
                                  Header:
                                      typeof total === 'number'
                                          ? `Total: ${total}`
                                          : '',
                              }
                            : {},
                    ],
                ]}
            />
            <div ref={bottomBoundaryRef}></div>
        </Fragment>
    );
}

ResponsiveTable.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.array,
    limit: PropTypes.number,
    onFetchData: PropTypes.func,
    noDataText: PropTypes.string,
    filter: PropTypes.string,
    customFilters: PropTypes.object,
    innerScroll: PropTypes.bool,
    defaultSorted: PropTypes.object,
    refresh: PropTypes.bool,
    onRefresh: PropTypes.func,
};
