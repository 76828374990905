import { Fragment, useEffect, useState } from 'react';
import { Modal } from '../../../../shared/components/Modal';
import { SearchInput } from '../../../../shared/inputs/SearchInput';
import { useCache } from '../../../../shared/lib/useCache';
import { useClient } from '../../../../shared/lib/useClient';
import { useSession } from '../../../../shared/lib/useSession';
import { Loading } from '../../../../shared/loadings/Loading';
import { PropTypes } from 'prop-types';
import './ContactModal.scss';

export function ContactModal({ onAdd, open, isEmployee, selected, text }) {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState(null);

    const [lSelected, setSelected] = useState([]);

    const [filter, setFilter] = useState('');

    const client = useClient();

    const session = useSession();

    const { teams, users } = useCache();

    useEffect(() => {
        if ((users || teams) && client) {
            let aux = isEmployee
                ? [
                      {
                          ...client,
                          name: client?.companyName,
                          image: client?.logo,
                          description: 'Company',
                      },
                      ...teams.map((x) => ({
                          ...x,
                          name: x?.title,
                          description: 'Team',
                      })),
                  ]
                : users;

            aux = aux.filter((x) => !selected?.some((y) => y?.id === x?.id));

            aux = aux.filter((x) =>
                x?.name?.toLowerCase().includes(filter?.toLowerCase())
            );
            setData(
                aux
                    ?.filter((x) => x?.id !== session?.userId)
                    ?.sort(
                        (x, y) =>
                            (isEmployee &&
                                x?.description
                                    ?.toLowerCase()
                                    ?.localeCompare(
                                        y?.description?.toLowerCase()
                                    )) ||
                            x?.name
                                ?.toLowerCase()
                                ?.localeCompare(y?.name?.toLowerCase())
                    )
            );

            setLoading(false);
        }
    }, [users, client, teams, isEmployee, selected, filter]);

    useEffect(() => {
        setSelected([]);
    }, [isEmployee]);

    return (
        <Fragment>
            <Modal
                className='contact-modal'
                open={open}
                onClose={() => {
                    onAdd([]);
                    setSelected([]);
                }}
            >
                {loading ? (
                    <Loading />
                ) : (
                    <Fragment>
                        <div className='search'>
                            <SearchInput
                                onSearch={(text) => setFilter(text)}
                                placeholder={'User'}
                            />
                        </div>

                        <div className='wrapper'>
                            {data?.map((d) => {
                                let isActive = !!lSelected?.find(
                                    (x) => x?.id === d?.id
                                );
                                return (
                                    <Entity
                                        key={`entity-${d?.id}`}
                                        name={d?.name}
                                        active={isActive}
                                        image={d?.image}
                                        description={
                                            isEmployee
                                                ? d?.description
                                                : d?.teams
                                                      ?.map((x) => x.title)
                                                      .join(', ') ??
                                                  'Unassigned'
                                        }
                                        onClick={() =>
                                            setSelected(
                                                isActive
                                                    ? [
                                                          ...lSelected.filter(
                                                              (s) =>
                                                                  s?.id !==
                                                                  d?.id
                                                          ),
                                                      ]
                                                    : [...lSelected, d]
                                            )
                                        }
                                    />
                                );
                            })}
                        </div>
                        <button
                            className='btn highlighted'
                            disabled={!lSelected?.length}
                            onClick={() => {
                                onAdd([...lSelected]);
                                setSelected([]);
                            }}
                        >
                            {text ? text : 'Add'}
                        </button>
                    </Fragment>
                )}
            </Modal>
        </Fragment>
    );
}

ContactModal.propTypes = {
    onAdd: PropTypes.func,
    open: PropTypes.bool,
    isEmployee: PropTypes.bool,
    selected: PropTypes.array,
    text: PropTypes.string,
};

function Entity({ image, name, description, onClick, active }) {
    const [allow, setAllow] = useState(false);

    return (
        <a
            role='button'
            aria-pressed={active}
            tabIndex={0}
            className={`entity ${active ? 'active' : ''} ${
                active && allow ? 'allow' : ''
            }`}
            onClick={onClick}
            onKeyPress={onClick}
            onMouseLeave={() => setAllow(active)}
        >
            <img
                aria-hidden={true}
                src={image}
                className='rounded'
                alt='team image'
            />
            <div>
                <h4 role='none'>{name}</h4>
                <h5 role='none'>{description}</h5>
            </div>
        </a>
    );
}

Entity.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
};
