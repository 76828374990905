import { useEffect, useState } from 'react';
import { ReactComponent as ScoreIcon } from '../../../assets/fonts/icon/svg/feedback-score.svg';
import { ReactComponent as SentimentIcon } from '../../../assets/fonts/icon/svg/feedback-sentiment-heart.svg';
import { ReactComponent as TotalIcon } from '../../../assets/fonts/icon/svg/feedback-total.svg';
import { ReactComponent as NoIcon } from '../../../assets/fonts/icon/svg/no.svg';
import { ReactComponent as PulseIcon } from '../../../assets/fonts/icon/svg/pulse.svg';
import { ReactComponent as YesIcon } from '../../../assets/fonts/icon/svg/yes.svg';
import { useRagIntervals } from '../lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import constants from '../constants';
import './charts.scss';

export function RadialChartIcon({
    className,
    percentile,
    primaryColor,
    icon,
    animated,
    text,
}) {
    const [initiated, setInitiated] = useState(
        typeof animated === 'boolean' && !animated
    );

    useEffect(() => {
        setTimeout(() => setInitiated(true), 10);
    }, []);
    const radius = 80;

    const strokeWidth = 6;

    const circleRadius = Math.min(radius, 85);

    const circumference = 2 * 3.14 * circleRadius;

    const strokeLength = initiated
        ? (circumference / 100) * percentile
        : (circumference / 100) * 5;

    return (
        <div className={`radial-chart-icon ${className ? className : ''}`}>
            <svg viewBox='0 0 180 180' fontFamily='ubunturegular'>
                <circle
                    className='radial-chart-icon-percentile'
                    stroke={primaryColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${strokeLength},${circumference}`}
                    strokeLinecap={'round'}
                    fill={primaryColor}
                    fillOpacity={'15%'}
                    cx='90'
                    cy='90'
                    r={circleRadius}
                    aria-hidden={true}
                />

                {!!icon && icon}

                {text !== undefined && text !== null && (
                    <text
                        id='placeholder'
                        x='50%'
                        y='54%'
                        textAnchor='middle'
                        letterSpacing='-6px'
                        alignmentBaseline='middle'
                        fontSize={'5em'}
                        stroke={primaryColor}
                        fill={primaryColor}
                    >
                        {text}
                    </text>
                )}
            </svg>
        </div>
    );
}

RadialChartIcon.propTypes = {
    className: PropTypes.string,
    percentile: PropTypes.number,
    primaryColor: PropTypes.string,
    icon: PropTypes.object,
    animated: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export function SentimentChart({ sentiment, showText, className, onColor }) {
    const { getColor } = useRagIntervals();

    const color = getColor(sentiment);

    useEffect(() => {
        if (color && onColor) {
            onColor(color);
        }
    }, [color]);

    return (
        <RadialChartIcon
            className={`sentiment-radial-chart ${className ? className : ''}`}
            percentile={typeof sentiment === 'number' ? sentiment : 5}
            text={
                showText
                    ? typeof sentiment === 'number'
                        ? `${sentiment}%`
                        : ''
                    : null
            }
            primaryColor={color}
            icon={
                <SentimentIcon
                    stroke={color}
                    fill={color}
                    viewBox={null}
                    width={null}
                    height={null}
                    opacity={showText ? 0.1 : 1}
                />
            }
        />
    );
}

SentimentChart.propTypes = {
    sentiment: PropTypes.number,
    showText: PropTypes.bool,
    className: PropTypes.string,
    onColor: PropTypes.func,
};

export function ScoreChart({ score, showText, className, onColor }) {
    const { getColor } = useRagIntervals();

    const color = getColor(score, constants.QuestionType.Score);

    useEffect(() => {
        if (color && onColor) {
            onColor(color);
        }
    }, [color]);

    return (
        <RadialChartIcon
            className={`sentiment-radial-chart ${className ? className : ''}`}
            percentile={typeof score === 'number' ? score * 10 : 5}
            text={showText ? (typeof score === 'number' ? score : '') : null}
            primaryColor={color}
            icon={
                <ScoreIcon
                    stroke={color}
                    fill={color}
                    viewBox={null}
                    width={null}
                    height={null}
                    opacity={showText ? 0.1 : 1}
                />
            }
        />
    );
}

ScoreChart.propTypes = {
    score: PropTypes.number,
    showText: PropTypes.bool,
    className: PropTypes.string,
    onColor: PropTypes.func,
};

export function PulseChart({ rate, showText, className, onColor }) {
    const { getColor } = useRagIntervals();
    const color = getColor(rate);

    useEffect(() => {
        if (color && onColor) {
            onColor(color);
        }
    }, [color]);

    return (
        <RadialChartIcon
            className={`sentiment-radial-chart ${className ? className : ''}`}
            percentile={typeof rate === 'number' ? rate : 5}
            text={
                showText ? (typeof rate === 'number' ? `${rate}%` : '') : null
            }
            primaryColor={color}
            icon={
                <PulseIcon
                    stroke={color}
                    fill={color}
                    viewBox={null}
                    width={null}
                    height={null}
                    opacity={showText ? 0.1 : 1}
                />
            }
        />
    );
}

PulseChart.propTypes = {
    rate: PropTypes.number,
    showText: PropTypes.bool,
    className: PropTypes.string,
    onColor: PropTypes.func,
};

export function YesChart({ yes, showText, className, onColor }) {
    const color = '#741f82';

    useEffect(() => {
        if (color && onColor) {
            onColor(color);
        }
    }, [color]);

    return (
        <RadialChartIcon
            className={`sentiment-radial-chart ${className ? className : ''}`}
            percentile={typeof yes === 'number' ? yes : 5}
            text={showText ? (typeof yes === 'number' ? `${yes}%` : '') : null}
            primaryColor={color}
            icon={
                <YesIcon
                    stroke={color}
                    fill={color}
                    x='17%'
                    y='17%'
                    textAnchor='middle'
                    alignmentBaseline='middle'
                    viewBox={null}
                    height={null}
                    opacity={showText ? 0.1 : 1}
                />
            }
        />
    );
}

YesChart.propTypes = {
    yes: PropTypes.number,
    showText: PropTypes.bool,
    className: PropTypes.string,
    onColor: PropTypes.func,
};

export function NoChart({ no, showText, className, onColor }) {
    const color = '#4b637d';

    useEffect(() => {
        if (color && onColor) {
            onColor(color);
        }
    }, [color]);

    return (
        <RadialChartIcon
            className={`sentiment-radial-chart ${className ? className : ''}`}
            percentile={typeof no === 'number' ? no : 5}
            text={showText ? (typeof no === 'number' ? `${no}%` : '') : null}
            primaryColor={color}
            icon={
                <NoIcon
                    stroke={color}
                    fill={color}
                    x='17%'
                    y='17%'
                    textAnchor='middle'
                    alignmentBaseline='middle'
                    viewBox={null}
                    width={null}
                    height={null}
                    opacity={showText ? 0.1 : 1}
                />
            }
        />
    );
}

NoChart.propTypes = {
    no: PropTypes.number,
    showText: PropTypes.bool,
    className: PropTypes.string,
    onColor: PropTypes.func,
};

export function TotalChart({ total, color, showText, className }) {
    const primaryColor = color
        ? color
        : typeof total === 'number'
        ? '#424953'
        : '#8f9498';

    return (
        <RadialChartIcon
            className={`sentiment-radial-chart ${className ? className : ''}`}
            percentile={100}
            text={showText ? (typeof total === 'number' ? total : '') : null}
            primaryColor={primaryColor}
            animated={false}
            icon={
                <TotalIcon
                    stroke={primaryColor}
                    fill={primaryColor}
                    viewBox={null}
                    width={null}
                    height={null}
                    opacity={showText ? 0.1 : 1}
                />
            }
        />
    );
}

TotalChart.propTypes = {
    total: PropTypes.number,
    color: PropTypes.string,
    showText: PropTypes.bool,
    className: PropTypes.string,
};
