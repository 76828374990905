import { PropTypes } from 'prop-types';

export function ErrorPageTemplate({ className, header, children }) {
    return (
        <div className={`error-page ${!!className && className}`}>
            <div className='floating-box'>
                {!!header && header}
                {!!children && children}
            </div>
        </div>
    );
}

ErrorPageTemplate.propTypes = {
    className: PropTypes.string,
    header: PropTypes.object,
    children: PropTypes.object,
};
