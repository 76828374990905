import { Fragment, useEffect, useRef, useState } from 'react';
import {
    getTopic,
    updateTopic,
    createTopic,
} from '../../../../services/topicService';
import { Textarea } from '../../../shared/inputs/Textarea';
import { PropTypes } from 'prop-types';
import constants from '../../../shared/constants';
import './Topics.scss';

export function Topic({ topicId, onSubmit }) {
    const [topic, setTopic] = useState({});
    const [message, setMessage] = useState(null);

    const employee = useRef(null);

    const peer = useRef(null);

    useEffect(() => {
        async function fetchTopic() {
            try {
                let topic = await getTopic(topicId);

                setTopic(topic);
            } catch (e) {
                //ignore
            }
        }

        if (topicId) {
            fetchTopic();
        }
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();
        let success = false;
        if (topic.title && topic.description) {
            if (topic.category != null) {
                setMessage(null);
                topicId ? await updateTopic(topic) : await createTopic(topic);
                success = true;

                onSubmit(success);
            } else {
                setMessage('Please check one of both options');
            }
        }
    }

    return (
        <Fragment>
            <form className='topic-page' onSubmit={handleSubmit}>
                <div className='group-form'>
                    <label htmlFor='Title'>Topic name</label>
                    <input
                        id='Title'
                        type='text'
                        placeholder=''
                        onChange={(ev) =>
                            setTopic({
                                ...topic,
                                title: ev.target.value,
                            })
                        }
                        value={topic.title || ''}
                        required
                        autoFocus
                    />
                </div>

                <div className='group-form'>
                    <label htmlFor='Description'>Description</label>
                    <Textarea
                        className='form-control custom-textarea'
                        rows='5'
                        onChange={(ev) =>
                            setTopic({
                                ...topic,
                                description: ev.target.value,
                            })
                        }
                        maxLength={constants.maxLength}
                        value={topic.description || ''}
                        required={true}
                        placeholder={'Add a topic description...'}
                    />
                </div>

                <div className='group-form'>
                    <div className='topic-checkbox-inline'>
                        <label className='pulse360-checkbox tick'>
                            <input
                                ref={peer}
                                type='checkbox'
                                name='peer'
                                checked={
                                    topic?.category == 0 || topic?.category == 2
                                }
                                onChange={(ev) =>
                                    setTopic({
                                        ...topic,
                                        category:
                                            employee?.current?.checked &&
                                            ev.target.checked
                                                ? 2
                                                : ev.target.checked
                                                ? 0
                                                : employee?.current?.checked
                                                ? 1
                                                : null,
                                    })
                                }
                            />
                            <span></span>
                            Peer
                        </label>
                    </div>
                    <div className='topic-checkbox-inline'>
                        <label className='pulse360-checkbox tick'>
                            <input
                                ref={employee}
                                type='checkbox'
                                name='employee'
                                checked={
                                    topic?.category == 1 || topic?.category == 2
                                }
                                onChange={(ev) => {
                                    setTopic({
                                        ...topic,
                                        category:
                                            peer?.current?.checked &&
                                            ev.target.checked
                                                ? 2
                                                : ev.target.checked
                                                ? 1
                                                : peer?.current?.checked
                                                ? 0
                                                : null,
                                    });
                                }}
                            />
                            <span></span>
                            Employee Voice
                        </label>
                    </div>
                    {message && (
                        <div className='invalid-feedback'>{message}</div>
                    )}
                </div>
                <div style={{ justifyContent: 'flex-end' }}>
                    <button type='submit' className='btn highlighted'>
                        Save
                    </button>
                </div>
            </form>
        </Fragment>
    );
}

Topic.propTypes = {
    topicId: PropTypes.string,
    onSubmit: PropTypes.func,
};
