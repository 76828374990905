import _uniqueId from 'lodash/uniqueId';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useWindowEvent } from '../lib/useWindowEvent';
import './components.scss';

export function Modal({
    open,
    children,
    className,
    onClose,
    fixed,
    noBackground,
    padding,
}) {
    const [id] = useState(_uniqueId('modal-'));

    const [animatedOpen, setAnimatedOpen] = useState(null);

    const [firstRender, setFirstRender] = useState(true);

    const modalRef = useRef(null);

    const restoreFocusOnElement = document.activeElement;

    useEffect(() => {
        if (!firstRender) {
            if (!open) {
                setAnimatedOpen(open);
                setTimeout(() => setAnimatedOpen(null), 300);
            } else {
                setAnimatedOpen(open);
            }
        } else {
            setFirstRender(false);
        }
    }, [open]);

    useWindowEvent(
        'keydown',
        useCallback(
            (event) => {
                if (event.keyCode == 27) {
                    restoreFocusOnElement.focus();
                    onClose();
                }
            },
            [onClose]
        )
    );

    useWindowEvent(
        'mousedown',
        useCallback(
            (event) => {
                if (
                    event.target.closest(`#${id}`) ||
                    !open ||
                    event.target.tagName.toLowerCase().includes('grammarly')
                ) {
                    return;
                } else if (typeof onClose === 'function' && !fixed) {
                    restoreFocusOnElement.focus();
                    onClose();
                }
            },
            [open, onClose, fixed]
        )
    );

    useEffect(() => {
        if (animatedOpen) {
            var childs = modalRef.current.querySelectorAll(
                'StyledSlider, input, a, select, checkbox, textarea'
            );

            if (childs?.length) {
                childs[0]?.focus();
            }
        }
    }, [animatedOpen]);

    return createPortal(
        <div
            role='dialog'
            aria-modal='true'
            aria-labelledby={children[0]}
            className={`pulse360-modal ${
                animatedOpen === null ? '' : animatedOpen ? 'open' : 'out'
            } ${noBackground ? 'no-background' : ''}`}
            style={padding ? { paddingTop: '50rem' } : {}}
        >
            <div
                ref={modalRef}
                id={id}
                className={`modal-body ${className ?? ''}`}
            >
                {children}
            </div>
        </div>,
        document.body || document.createElement('div')
    );
}
