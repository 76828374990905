import { Fragment, useEffect, useState } from 'react';
import { useAsyncError } from '../lib/helper';
import { CheckboxList } from './shared/CheckboxList';
import { DateSection } from './shared/DateSection';
import { PropTypes } from 'prop-types';
import { getPriorityTypes } from '../../../services/actionService';
import { getPPRTopicsParents } from '../../../services/pprTopicService';
import './ActionFilter.scss';

export function ActionFilter({ filter, team, pprTopicId, onChange }) {
    const [items, setItems] = useState([]);

    const [categories, setCategories] = useState([]);

    const [subCategories, setSubCategories] = useState([]);

    const [priorities, setPriorities] = useState([]);

    const [outFilter, setOutFilter] = useState({});

    const throwError = useAsyncError();

    useEffect(() => {
        if (team?.id) {
            getUsersByTeam(team?.id).then((users) => {
                setItems(users);
                handleUsersChange(users);
            });
        }

        return () => setItems([]);
    }, [team?.id]);

    useEffect(() => {
        if (filter.To && filter.From && filter.Status && filter !== outFilter) {
            let out = { ...outFilter };

            out.To = filter.To;
            out.From = filter.From;
            out.Status = filter.Status;

            setOutFilter(out);
        }
    }, [filter.To, filter.From, filter.Status]);

    function getUsersByTeam(teamId) {
        return fetch(`/api/User/getUsersSelectByTeam/${teamId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then((res) => res.json());
    }

    useEffect(() => {
        async function fetchData() {
            let topics = await getPPRTopicsParents();

            let mapTopics = topics.map((x) => ({
                label: x.title,
                value: x.id,
            }));

            if (pprTopicId) {
                let t = mapTopics.find((x) => x.value === pprTopicId);
                if (t) {
                    t.selected = true;
                }
            }

            setCategories(mapTopics);
        }

        fetchData();
    }, [pprTopicId]);

    function getSubTopics(topics) {
        return fetch('/api/PPRTopic/getParentChildrenForRT', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(topics),
        }).then((res) => {
            if (res.status >= 400) {
                throwError(new Error());
            }
            return res.json();
        });
    }

    useEffect(() => {
        async function fetch() {
            const pr = await getPriorityTypes();
            setPriorities(
                pr.map((p) => ({
                    label: setPriorityLabel(p),
                    value: p.value.toString(),
                }))
            );
        }

        fetch();
    }, []);

    function setPriorityLabel(item) {
        let priority = null;

        switch (item.value) {
            case 0:
                priority = <i className='icon icon-score-active'></i>;
                break;
            case 1:
                priority = (
                    <Fragment>
                        {' '}
                        <i className='icon icon-score-active'></i>{' '}
                        <i className='icon icon-score-active'></i>{' '}
                    </Fragment>
                );
                break;
            default:
                priority = (
                    <Fragment>
                        {' '}
                        <i className='icon icon-score-active'></i>{' '}
                        <i className='icon icon-score-active'></i>{' '}
                        <i className='icon icon-score-active'></i>{' '}
                    </Fragment>
                );
                break;
        }

        return priority;
    }

    function handleUsersChange(users) {
        let newFilter = { ...outFilter };
        newFilter.Users = users.map((x) => x.value);
        setOutFilter(newFilter);
        onChange({ ...newFilter });
    }

    async function handleCategoriesChange(cat) {
        if (cat && cat.length) {
            let subTopics = await getSubTopics(cat.map((x) => x.value));

            setSubCategories(
                subTopics.map((sub) => ({
                    value: sub.value,
                    label: sub.label,
                    selected: true,
                }))
            );
        } else if (!cat || !cat.length) {
            setSubCategories([]);
        }
    }

    function handleSubCategoriesChange(subCat) {
        let newFilter = { ...outFilter };
        newFilter.SubTopics = subCat.map((x) => x.value);
        setOutFilter(newFilter);
        onChange(newFilter);
    }

    function handlePriorityChange(prior) {
        let newFilter = { ...outFilter };
        newFilter.Priority = prior.map((x) => parseInt(x.value));
        setOutFilter(newFilter);
        onChange(newFilter);
    }

    function handleDates(from, to) {
        let newFilter = { ...outFilter };
        newFilter.From = from.toISOString();
        newFilter.To = to.toISOString();
        setOutFilter(newFilter);
        onChange(newFilter);
    }

    return (
        <div className='action-filter'>
            <CheckboxList
                list={items}
                all={`Team: ${team?.title}`}
                onChange={handleUsersChange}
                onStartSelect={true}
                bubble={true}
                title={'Members'}
                search={true}
                label={'Members'}
            />

            <CheckboxList
                list={priorities}
                onStartSelect={true}
                onChange={handlePriorityChange}
                title={'Priority'}
            />

            <CheckboxList
                list={categories}
                all={`All`}
                onChange={handleCategoriesChange}
                title={'Category'}
            />

            <CheckboxList
                list={subCategories}
                onChange={handleSubCategoriesChange}
                title={'Sub category'}
            />

            <DateSection
                className='small'
                from={outFilter.From}
                to={outFilter.To}
                onChange={handleDates}
                title={'Duration'}
            />
        </div>
    );
}

ActionFilter.propTypes = {
    filter: PropTypes.object,
    team: PropTypes.object,
    pprTopicId: PropTypes.string,
    onChange: PropTypes.func,
};
