import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import './ChoiceDistributionChart.scss';

export function ChoiceDistributionChart({ data, legend, onClick, isAnswered }) {
    const [selected, setSelected] = useState(null);

    function handleClick(value) {
        value = selected === value?.key ? null : value?.key;
        setSelected(value);
        onClick(value);
    }

    return (
        <div className='choice-chart'>
            {legend && <ChoiceDistributionLegend data={data} />}
            {!isAnswered ? (
                <div className='empty'></div>
            ) : (
                <ResponsiveContainer width='100%' height={250}>
                    <PieChart>
                        <Pie
                            data={data}
                            cx='50%'
                            cy='50%'
                            labelLine={true}
                            fill='#8884d8'
                            dataKey='value'
                        >
                            {data.map((d, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    style={{ cursor: 'pointer' }}
                                    fill={d.color}
                                    onClick={() => handleClick(d)}
                                    opacity={
                                        selected && selected !== d.key ? 0.5 : 1
                                    }
                                />
                            ))}
                        </Pie>
                        <Tooltip
                            allowEscapeViewBox={{ x: true, y: true }}
                            wrapperStyle={{ zIndex: 1000 }}
                            content={({ active }) => {
                                if (active) {
                                    return (
                                        <div className='pulse-index-chart-tooltip'>
                                            {data.map((x, i) => (
                                                <div
                                                    className='label'
                                                    key={`label-${i}`}
                                                    style={{
                                                        marginBottom: '2px',
                                                    }}
                                                >
                                                    <div
                                                        className='tooltip-field-box'
                                                        style={{
                                                            backgroundColor:
                                                                x.color,
                                                        }}
                                                    ></div>
                                                    <div
                                                        style={{
                                                            color: 'black',
                                                            marginLeft: '1rem',
                                                        }}
                                                    >
                                                        {`${x.title.substring(
                                                            0,
                                                            5
                                                        )}...`}
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: 'black',
                                                            marginRight: '1rem',
                                                        }}
                                                    >
                                                        {`${x.value}%`}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }

                                return null;
                            }}
                        />
                    </PieChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

ChoiceDistributionChart.propTypes = {
    data: PropTypes.array,
    legend: PropTypes.bool,
    onClick: PropTypes.func,
    isAnswered: PropTypes.bool,
};

export function ChoiceDistributionLegend({ data }) {
    return (
        <div className='choice-legend'>
            {data.map((d, i) => {
                return (
                    <div key={i} className='legend-box'>
                        <div
                            className='field-box'
                            style={{
                                backgroundColor: d.color,
                            }}
                        ></div>
                        <span className='field-text'>{d.title}</span>
                    </div>
                );
            })}
        </div>
    );
}

ChoiceDistributionLegend.propTypes = {
    data: PropTypes.array,
};
