import { useEffect } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { PropTypes } from 'prop-types';
import { Loading } from '../loadings/Loading';
import './table.scss';
import './components.scss';

export function Table({
    className,
    data,
    loading,
    columns,
    pagination,
    noDataText,
    onSortedChange,
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, sortBy },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 2 },
            autoResetSortBy: false,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (onSortedChange) {
            onSortedChange(sortBy);
        }
    }, [sortBy]);

    return (
        <div className={className}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((hG, i) => (
                        <tr key={i} {...hG.getHeaderGroupProps()}>
                            {hG.headers.map((column, j) => (
                                <th
                                    key={j}
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <svg
                                                    className='th-align-left'
                                                    focusable='false'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='14px'
                                                    height='10px'
                                                    fill='currentColor'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                                                </svg>
                                            ) : (
                                                <svg
                                                    className='th-align-right'
                                                    focusable='false'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='14px'
                                                    height='10px'
                                                    fill='currentColor'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z' />
                                                </svg>
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr key={i} {...row.getRowProps()}>
                                {row.cells.map((cell, j) => {
                                    return (
                                        <td
                                            key={j}
                                            title={
                                                typeof cell.value !== 'object'
                                                    ? cell.value
                                                    : ''
                                            }
                                            width={cell.column.width}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className='loading'>
                {loading ? (
                    <Loading />
                ) : (
                    rows.length < 1 && (
                        <tr>
                            <td className='no-data'>{noDataText}</td>
                        </tr>
                    )
                )}
            </div>

            {pagination && (
                <div className='pagination'>
                    <button
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {'<<'}
                    </button>{' '}
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    >
                        {'<'}
                    </button>{' '}
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    >
                        {'>>'}
                    </button>{' '}
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                </div>
            )}
        </div>
    );
}

Table.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array,
    columns: PropTypes.array,
    noDataText: PropTypes.string,
    pagination: PropTypes.bool,
    loading: PropTypes.bool,
    onSortedChange: PropTypes.func,
};
