import { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const initialState = {
    userId: null,
    permissions: null,
    authenticated: false,
    isAnonymous: true,
    isPerformance: false,
    retry: 0,
    loading: true,
};
export const SessionContext = createContext(initialState);

export const ActionTypes = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    LOADED: 'LOADED',
};

export function SessionProvider({ children }) {
    const { Provider } = SessionContext;
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case ActionTypes.LOGIN:
                return { ...action.payload };
            case ActionTypes.LOGOUT:
                return { ...initialState, loading: false };
            case ActionTypes.LOADED:
                return { loading: false };
            default:
                break;
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

SessionProvider.propTypes = {
    children: PropTypes.object,
};
