import moment from 'moment';
import { useEffect, useState } from 'react';
import { DoubleRadialChart } from '../../../../../../shared/charts/DoubleRadialChart';
import { useRagIntervals } from '../../../../../../shared/lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import { getPriorityTypes } from '../../../../../../../services/actionService';
import './components.scss';

export function PPRTopicBox({ question, onEdit }) {
    const [color, setColor] = useState({});

    const [priorityTypes, setPPriorityTypes] = useState(null);

    const { getColor } = useRagIntervals();

    useEffect(() => {
        async function fetch() {
            const priorities = await getPriorityTypes();

            setPPriorityTypes(
                priorities.map((x) => ({
                    label: `${x.value + 1} - ${x.key} priority`,
                    value: x.value,
                }))
            );
        }

        fetch();
    }, []);

    useEffect(() => {
        setColor(getColor(question?.value));
    }, []);

    return (
        <div
            key={`ptb-${question.index}`}
            className='ppr-topic-box'
            role='section'
        >
            <div key={`topic-${question.index}`} className='topic'>
                <div
                    key={`title-icon-${question.index}`}
                    className='title-icon'
                >
                    <div
                        key={`title-${question.index}`}
                        className='title'
                        role='section'
                    >
                        {question.title}
                    </div>
                    <div key={`icon-${question.index}`} className='icon'>
                        <i
                            tabIndex={0}
                            role='button'
                            title={`Edit`}
                            key={`edit-icon-${question.index}`}
                            onClick={() => onEdit(question)}
                            className='icon-edit inline-icon '
                        ></i>
                    </div>
                </div>

                <div
                    key={`description-${question.index}`}
                    className='description'
                >
                    <p> {`"${question.description}"`}</p>
                </div>
            </div>

            <div key={`chart-${question.index}`} className='chart'>
                <DoubleRadialChart
                    key={`doubleRadialChart-${question.index}`}
                    score={question.value}
                    target={question.targetValue}
                    scoreColor={color}
                    targetColor={'#363D56'}
                />
            </div>

            <div key={`action-${question.index}`} className='action'>
                <div
                    key={`action-title-${question.index}`}
                    className='action-title'
                >
                    Action
                </div>

                <div
                    key={`action-description-${question.index}`}
                    className={`action-description ${
                        question.action ? '' : 'disabled'
                    }`}
                >
                    <p>
                        {' '}
                        {question.action && question.action.description
                            ? question.action.description
                            : 'No action set'}
                    </p>
                </div>

                <div
                    key={`action-buttons-${question.index}`}
                    className='action-buttons'
                >
                    <button
                        className='btn assess'
                        aria-hidden={true}
                        tabIndex={-1}
                    >
                        {question.action && question.action.completeDate
                            ? moment(question.action.completeDate).format(
                                  'DD/MM/YYYY'
                              )
                            : 'No date set'}{' '}
                    </button>
                    <button
                        className='btn assess'
                        aria-hidden={true}
                        tabIndex={-1}
                    >
                        {question.action &&
                        (question.action.priority ||
                            question.action.priority === 0) &&
                        priorityTypes
                            ? priorityTypes.find(
                                  (x) => x.value === question.action.priority
                              ).label
                            : 'No priority set'}{' '}
                    </button>
                </div>
            </div>
        </div>
    );
}

PPRTopicBox.propTypes = {
    question: PropTypes.object,
    onEdit: PropTypes.func,
};
