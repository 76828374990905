import { get, put, post, remove } from '../components/shared/lib/axios';

export async function getGoal(userId) {
    return get(`users/${userId}/goals`);
}

export async function getGoalsInProgress(userId) {
    return get(`users/${userId}/goals/in-progress`);
}

export async function getUserGoalsPaginated(userId, limit, term) {
    return get(`users/${userId}/goals/filtered`, { limit, term });
}

export async function getUserGoalsInProgressPaginated(userId, limit, term) {
    return get(`users/${userId}/goals/in-progress/filtered`, {
        limit,
        term,
    });
}

export async function getTeamGoals(teamId, term /*optional*/) {
    return get(`teams/${teamId}/goals`, { term });
}

export async function getTerms() {
    return get(`types/goal-term`);
}

export async function completeGoal(goalId) {
    return put(`goals/${goalId}/complete`);
}

export async function updateGoal(goalId, req) {
    return put(`goals/${goalId}`, req);
}

export async function createGoal(req) {
    return post(`goals`, req);
}

export async function deleteGoal(goalId) {
    return remove(`goals/${goalId}`);
}
