import { PropTypes } from 'prop-types';
import './Loading.scss';

export function GradientLoading({ className, style, children }) {
    return (
        <div
            className={`gradient-loading ${className ?? ''}`}
            style={style ?? {}}
        >
            {children}
        </div>
    );
}

GradientLoading.propTypes = {
    className: PropTypes.string,
    style: PropTypes.string,
    children: PropTypes.array,
};
