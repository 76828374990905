import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../../shared/constants';
import { useAsyncError } from '../../../shared/lib/helper';
import { useSession } from '../../../shared/lib/useSession';
import { isAuthorized } from '../../authentication/AuthControl';

export function MyTeam() {
    const throwError = useAsyncError();

    const navigate = useNavigate();

    const session = useSession();

    useEffect(() => {
        if (isAuthorized('team::feedback', session?.permissions)) {
            navigate('/my-team/feedback/overview', { replace: true });
        }
        else if (isAuthorized('team::assess', session?.permissions)) {
            navigate('/my-team/assess', { replace: true });
        }

    })
    if (isAuthorized('team::feedback', session?.permissions)) {
        return <div></div>;

    } else if (isAuthorized('team::assess', session?.permissions)) {
        return <div></div>;
        
    } else {
        return (
            <Fragment>{throwError(constants.errorCodes.forbidden)}</Fragment>
        );
    }
}
