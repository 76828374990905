import { Fragment, useEffect, useState } from 'react';
import { getOrganisationalFeedbackInsights } from '../../../../../services/organisationalFeedbackService';
import { getTeam } from '../../../../../services/teamService';
import { useClient } from '../../../../shared/lib/useClient';
import { WGroupIconChart } from './WGroupIconChart';
import { WLayout } from './WLayout';
import { PropTypes } from 'prop-types';
import './components.scss';

export function WInsights({ teamId, dates, isTeam }) {
    const [loading, setLoading] = useState(true);

    const [insights, setInsights] = useState(null);

    const client = useClient();

    const [team, setTeam] = useState(null);

    useEffect(() => {
        async function fetch() {
            setLoading(true);

            let ins = await getOrganisationalFeedbackInsights(
                dates.from,
                dates.to,
                teamId
            );

            setInsights(ins.entity);

            setLoading(false);
        }

        if (dates && ((isTeam && teamId) || !isTeam)) {
            fetch();
        }
    }, [dates, teamId]);

    useEffect(() => {
        async function fetchTeam() {
            setTeam(await getTeam(teamId));
        }
        if (teamId) {
            fetchTeam();
        }
    }, [teamId]);

    return (
        <WLayout
            to={{
                pathname: `/${
                    teamId ? 'my-team' : 'company'
                }/feedback/organisation`,
            }}
            state={teamId}
            info={
                !isTeam ? (
                    <Fragment>
                        <ul>
                            <li>
                                Volume &ndash; The amount of feedback received
                                by {client?.companyName} during the defined time
                                period.{' '}
                                <em>
                                    Note: This is not a sum of feedback received
                                    by teams or entities. This relates solely to
                                    feedback issued directly to the
                                    organisation.
                                </em>
                            </li>
                            <li>
                                Score &ndash; The average score of any feedback,
                                during the time period, issued to{' '}
                                {client?.companyName}.
                            </li>
                            <li>
                                Sentiment &ndash; The average sentiment of any
                                qualitative feedback, during the time period,
                                issued to {client?.companyName}.<br />
                                NB &ndash; Sentiment is only analysed where
                                feedback is provided without a score.
                            </li>
                        </ul>
                    </Fragment>
                ) : (
                    <Fragment>
                        <ul>
                            <li>
                                Volume &ndash; The amount of feedback received
                                by {team?.title} during the defined time period.
                            </li>
                            <li>
                                Score &ndash; The average score of any feedback,
                                during the time period, issued to {team?.title}.
                            </li>
                            <li>
                                Sentiment &ndash; The average sentiment of any
                                qualitative feedback, during the time period,
                                issued to {team?.title}.<br />
                                NB &ndash; Sentiment is only analysed where
                                feedback is provided without a score.
                            </li>
                        </ul>
                    </Fragment>
                )
            }
            title={'Employee Voice'}
            loading={loading}
            className='w-insights'
        >
            <Fragment>
                <WGroupIconChart
                    label={'Volume'}
                    type={'total'}
                    value={insights?.volume}
                />

                <WGroupIconChart
                    label={'Average score'}
                    type={'score'}
                    value={insights?.score}
                />

                <WGroupIconChart
                    label={'Average sentiment'}
                    type={'sentiment'}
                    value={insights?.sentiment}
                />
            </Fragment>
        </WLayout>
    );
}

WInsights.propTypes = {
    teamId: PropTypes.string,
    dates: PropTypes.object,
    isTeam: PropTypes.bool,
};
