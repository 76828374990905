import constants from '../../constants';
import { DistributionChart } from './charts/distribution/DistributionChart';
import { SurveyChart } from './charts/SurveyChart';
import { PropTypes } from 'prop-types';
import { formatQuestionType } from '../../lib/helper';

export function QuestionCardOneOff({
    question,
    survey,
    iteration,
    teamId,
    iterations,
}) {
    return (
        <div className={'charts distribution'}>
            <div>
                <h4>{`${formatQuestionType(
                    question?.questionType
                )} distribution`}</h4>
                <DistributionChart
                    surveyId={survey?.id}
                    questionId={question?.id}
                    teamId={teamId}
                    iteration={iteration ?? 0}
                />
            </div>

            {question?.questionType !== constants.QuestionType.Likert &&
                question?.questionType !== constants.QuestionType.Choice && (
                    <div>
                        <h4>{`Survey ${formatQuestionType(
                            question?.questionType
                        ).toLowerCase()}`}</h4>
                        <SurveyChart
                            current={
                                iterations?.length
                                    ? iterations?.find(
                                          (x) => x.iteration === iteration
                                      )
                                    : null
                            }
                            before={
                                iterations?.length > 1
                                    ? iterations?.find(
                                          (x) => x.iteration === iteration - 1
                                      )
                                    : null
                            }
                            question={question}
                            survey={survey}
                            teamId={teamId}
                            iteration={iteration}
                        />
                    </div>
                )}
        </div>
    );
}

QuestionCardOneOff.propTypes = {
    question: PropTypes.object,
    survey: PropTypes.object,
    iteration: PropTypes.number,
    teamId: PropTypes.string,
    iterations: PropTypes.array,
};
