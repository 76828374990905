import { useState } from 'react';
import { Page } from '../../../shared/layout/Page';
import { useUser } from '../../../shared/lib/useUser';
import { DateSelector } from '../../../shared/utils/DateSelector';
import { MePulseIndexWidget } from '../../../shared/widgets/MePulseIndexWidget';
import { OrganisationalFeedbackWidget } from '../../../shared/widgets/OrganisationalFeedbackWidget';
import { PeerFeedback } from '../../../shared/widgets/PeerFeedback';
import { PulseSurveysWidget } from '../../../shared/widgets/PulseSurveysWidget';
import './MeInsights.scss';

export function MeInsights() {
    const [dates, setDates] = useState(null);

    const user = useUser();

    return (
        <Page className='me-insights' testId='me-insights'>
            <div className='header'>
                <label>Time period:</label>
                <DateSelector
                    onChange={(from, to, months) =>
                        setDates({ from, to, months })
                    }
                    autoFocus
                />
            </div>
            {!!dates && user?.id && (
                <div className='widgets'>
                    <MePulseIndexWidget
                        from={dates?.from}
                        to={dates?.to}
                        months={dates?.months}
                        userId={user?.id}
                    />
                    <PeerFeedback
                        from={dates?.from}
                        to={dates?.to}
                        months={dates?.months}
                        userId={user?.id}
                    />
                    <PulseSurveysWidget
                        from={dates?.from}
                        to={dates?.to}
                        userId={user?.id}
                    />

                    <OrganisationalFeedbackWidget
                        from={dates?.from}
                        to={dates?.to}
                        userId={user?.id}
                    />
                </div>
            )}
        </Page>
    );
}
