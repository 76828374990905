import { Fragment, useCallback, useEffect, useState } from 'react';
import { ReactComponent as Image0 } from '../../../assets/images/tips/undraw-tip-image-0.svg';
import { ReactComponent as Image1 } from '../../../assets/images/tips/undraw-tip-image-1.svg';
import { ReactComponent as Image10 } from '../../../assets/images/tips/undraw-tip-image-10.svg';
import { ReactComponent as Image2 } from '../../../assets/images/tips/undraw-tip-image-2.svg';
import { ReactComponent as Image3 } from '../../../assets/images/tips/undraw-tip-image-3.svg';
import { ReactComponent as Image4 } from '../../../assets/images/tips/undraw-tip-image-4.svg';
import { ReactComponent as Image5 } from '../../../assets/images/tips/undraw-tip-image-5.svg';
import { ReactComponent as Image6 } from '../../../assets/images/tips/undraw-tip-image-6.svg';
import { ReactComponent as Image7 } from '../../../assets/images/tips/undraw-tip-image-7.svg';
import { ReactComponent as Image8 } from '../../../assets/images/tips/undraw-tip-image-8.svg';
import { ReactComponent as Image9 } from '../../../assets/images/tips/undraw-tip-image-9.svg';
import './components.scss';

export function Tip() {
    const getTip = useTip();

    const random = useRandomInt;

    const [tip, setTip] = useState(null);

    useEffect(() => {
        setTip(getTip(random(0, 10)));
    }, [getTip, random]);

    return <div className='random-tip'>{!!tip && tip}</div>;
}

export function useTip() {
    return useCallback((number) => {
        switch (number) {
            case 0:
                return (
                    <Fragment>
                        <h4>Giving feedback</h4>
                        <Image0 />
                        <p>
                            Remember, the key is to always have positive intent.
                        </p>
                    </Fragment>
                );

            case 1:
                return (
                    <Fragment>
                        <h4>Giving feedback</h4>
                        <Image1 />
                        <p>
                            Start with {'"'}It was great because…{'"'} and
                            finish with {'"'}it would be even better if…
                            {'"'}
                        </p>
                    </Fragment>
                );

            case 2:
                return (
                    <Fragment>
                        <h4>Giving feedback</h4>
                        <Image2 />
                        <p>
                            Give others the encouragement, direction and
                            appreciation to excel.
                        </p>
                    </Fragment>
                );

            case 3:
                return (
                    <Fragment>
                        <h4>Giving feedback</h4>
                        <Image3 />
                        <p>
                            Feedback is great when it is{' '}
                            <span>
                                <strong>S</strong>
                            </span>
                            pecific,{' '}
                            <span>
                                <strong>M</strong>
                            </span>
                            easurable,{' '}
                            <span>
                                <strong>A</strong>
                            </span>
                            greed,{' '}
                            <span>
                                <strong>R</strong>
                            </span>
                            elevant and{' '}
                            <span>
                                <strong>T</strong>
                            </span>
                            imely.
                        </p>
                    </Fragment>
                );

            case 4:
                return (
                    <Fragment>
                        <h4>Giving feedback</h4>
                        <Image4 />
                        <p>
                            Make your feedback concise to be easily understood
                            and immediately relevant.
                        </p>
                    </Fragment>
                );

            case 5:
                return (
                    <Fragment>
                        <h4>Asking for feedback</h4>
                        <Image5 />
                        <p>
                            Ask the right people, at the right time on things
                            that really matter.
                        </p>
                    </Fragment>
                );

            case 6:
                return (
                    <Fragment>
                        <h4>Asking for feedback</h4>
                        <Image6 />
                        <p>
                            Be specific with your request to ensure the most
                            relevant response.
                        </p>
                    </Fragment>
                );

            case 7:
                return (
                    <Fragment>
                        <h4>Asking for feedback</h4>
                        <Image7 />
                        <p>
                            Don{"'"}t just go hunting for praise or compliments.
                        </p>
                    </Fragment>
                );

            case 8:
                return (
                    <Fragment>
                        <h4>Asking for feedback</h4>
                        <Image8 />
                        <p>
                            Ask for constructive feedback for direction or
                            support on key areas.
                        </p>
                    </Fragment>
                );

            case 9:
                return (
                    <Fragment>
                        <h4>Employee voice</h4>
                        <Image9 />
                        <p>
                            Have your say by offering feedback on key areas to
                            inform relevant business decisions.
                        </p>
                    </Fragment>
                );

            case 10:
                return (
                    <Fragment>
                        <h4>Employee voice</h4>
                        <Image10 />
                        <p>
                            Suggestions, concerns or areas for improvement are
                            welcomed and encouraged.
                        </p>
                    </Fragment>
                );
        }
    }, []);
}

function useRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}
