import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFeedback } from '../../../../services/guestFeedbackService';
import { useAsyncError } from '../../../shared/lib/helper';
import { useQuery } from '../../../shared/lib/useQuery';
import { useValidation } from '../../../shared/lib/useValidation';
import { Loading } from '../../../shared/loadings/Loading';
import { GuestFeedbackItem } from './components/GuestFeedbackItem';
import { GuestFeedbackLayout } from './GuestFeedbackLayout';

export function GuestFeedback() {
    const query = useQuery();

    const isValid = useValidation('guid');

    const throwError = useAsyncError();

    const [feedbackId, setFeedbackId] = useState(null);

    const [tenantId, setTenantId] = useState(null);

    const [feedback, setFeedback] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (query) {
            let t = query.get('tenantId');

            let f = query.get('feedbackId');

            if (!isValid(t) || !isValid(f)) {
                throwError(new Error('Not valid tenantId or feedbackId'));
            }

            setTenantId(t);
            setFeedbackId(f);
        }
    }, [query]);

    useEffect(() => {
        async function fetch() {
            try {
                let f = await getFeedback(feedbackId, tenantId);

                setFeedback(f);

                setLoading(false);
            } catch (error) {
                throwError(error);
            }
        }
        if (feedbackId && tenantId) {
            fetch();
        }
    }, [tenantId, feedbackId]);

    function onChange(data) {
        setFeedback({ ...feedback, ...data });
    }

    return (
        <GuestFeedbackLayout
            link={<Link to={'guest-feedback/tips'}>Help</Link>}
        >
            {loading ? (
                <Loading />
            ) : (
                <Fragment>
                    <div>
                        <h3>Hi {feedback?.feedbackGiver?.firstName},</h3>
                        <br />
                        <p>
                            {feedback?.feedbackReceiver?.name} from{' '}
                            {feedback?.client?.companyName} would appreciate
                            your feedback.
                        </p>
                    </div>

                    <GuestFeedbackItem
                        feedback={feedback}
                        tenantId={tenantId}
                        onChange={onChange}
                    />

                    <div>
                        {!feedback?.isAnswered ? (
                            <p className='semi-bold'>
                                This feedback will only be sent to{' '}
                                {feedback?.feedbackReceiver?.name}
                            </p>
                        ) : (
                            <div>
                                <h4>Find out more about...</h4>
                                <a
                                    className='find-out'
                                    target='_blank'
                                    rel='noreferrer'
                                    href='https://pulse360.io'
                                >
                                    <img src='/logo-alternative.svg' /> Pulse
                                    360
                                </a>
                            </div>
                        )}
                    </div>
                </Fragment>
            )}
        </GuestFeedbackLayout>
    );
}
