import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import './components.scss';

export function Tabs({ className, options, onChange, disabled, value }) {
    const [active, setActive] = useState(null);

    useEffect(() => {
        if (onChange) {
            onChange(active);
        }
    }, [active]);

    useEffect(() => {
        if (value !== undefined || value !== null) {
            setActive(value);
        }
    }, [value]);
    return (
        <nav
            className={`tabs-component ${className ?? ''} ${
                disabled ? 'disabled' : ''
            }`}
            role='navigation'
            data-testid='tabs'
        >
            {options?.map((o, i) => (
                <Tab
                    key={`tab-${o?.value}`}
                    active={o?.value === active}
                    ariaLabel={o?.ariaLabel}
                    ariaSelected={o?.value === active}
                    onClick={() =>
                        disabled
                            ? void 0
                            : setActive(active === o?.value ? null : o?.value)
                    }
                    autoFocus={i === 0}
                >
                    {o?.label}
                </Tab>
            ))}
        </nav>
    );
}

Tabs.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    value: PropTypes.number,
};

function Tab({
    active,
    children,
    onClick,
    ariaLabel,
    ariaSelected,
    autoFocus,
}) {
    return (
        <a
            role='button'
            aria-label={ariaLabel}
            aria-pressed={ariaSelected}
            tabIndex={0}
            className={`tab ${active ? 'active' : ''}`}
            onClick={onClick}
            onKeyPress={onClick}
            autoFocus={autoFocus}
        >
            {children}
        </a>
    );
}

Tab.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
    onClick: PropTypes.func,
    ariaLabel: PropTypes.string,
    ariaSelected: PropTypes.bool,
    autoFocus: PropTypes.bool,
};
