import { get, post } from '../components/shared/lib/axios';

export async function getPPR(pprId) {
    return get(`/api/ppr/${pprId}`);
}

export async function getUserLastPPR(userId, topicId) {
    return get(`/api/users/${userId}/pprs/${topicId}/last`);
}

export async function getUserLastPPRs(userId) {
    return get(`/api/users/${userId}/ppr/last`);
}

export async function savePPRQuestion(req) {
    return post('/api/ppr/save-question', req);
}

export async function shareAssessment(pprId, req) {
    return post(`/api/ppr/${pprId}/share`, req);
}
