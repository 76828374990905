import axios from 'axios';
import { Fragment, useState } from 'react';
import { MainContent } from '../../../../shared/layout/MainContent';
import { GenericModal } from '../../../../shared/modal/GenericModal';
import { DragNDrop } from '../../../../shared/utils/DragNDrop';
import { PropTypes } from 'prop-types';
import '../User.scss';

export function BulkUser({
    children,
    loadingMessage,
    csvParseUrl,
    postUrl,
    successMessage,
    buttonText,
    title,
}) {
    const [modal, setModal] = useState(false);
    const [succeed, setSucceed] = useState(null);

    const [message, setMessage] = useState('');

    const [bulkInvite, setBulkInvite] = useState(null);

    const [bulkInviteFile, setBulkInviteFile] = useState(null);

    const [bulkLoading, setBulkLoading] = useState(true);

    const [bulkLoadingMessage, setBulkLoadingMessage] = useState('');

    async function handleBulkInviteUsers(file) {
        var responseObject = { success: false, error: '' };
        setBulkInviteFile(file);
        const formData = new FormData();
        formData.append('csv', file);

        try {
            let result = await axios
                .post(csvParseUrl, formData)
                .then((res) => res.data);
            responseObject.success = true;

            setBulkInvite(result);
        } catch (error) {
            setBulkInvite(null);
            responseObject.error = error.response.data
                ? error.response.data.error
                : '';
        }

        return responseObject;
    }

    async function handleResponse(req) {
        let response = await axios.post(`/api/functions/status`, req);

        if (
            response.data &&
            (response.data.runtimeStatus === 'Completed' ||
                response.data.runtimeStatus === 'Error' ||
                response.data.runtimeStatus === 'Finished')
        ) {
            return response.data.output;
        } else {
            await timeout(10000);
            return await handleResponse(req);
        }
    }

    async function timeout(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async function handleBulkInviteClick(e) {
        if (e.target) {
            e.target.disabled = true;
        }

        setBulkLoadingMessage(loadingMessage);
        setBulkLoading(true);

        setSucceed(null);
        setModal(true);

        try {
            let result = await axios
                .post(postUrl, bulkInvite)
                .then((res) => res.data);
            let response = await handleResponse(result);

            setSucceed(response.success);

            setMessage(
                response.success ? (
                    successMessage
                ) : (
                    <Fragment>
                        Error in email(s): {response.userEmailErrors.join(', ')}
                        .
                        <br />
                        Please review the relevant fields and try again. If the
                        problem persists, please contact support@pulse360.io.
                    </Fragment>
                )
            );
        } catch (e) {
            setSucceed(false);
            setMessage(
                <Fragment>
                    Something went wrong. Please review your CSV template or
                    contact
                    <a href='mailto:support@pulse360.io'>
                        support@pulse360.io.
                    </a>
                </Fragment>
            );
        }

        if (e.target) {
            e.target.disabled = false;
        }

        setBulkInviteFile(null);
        setBulkLoading(false);
        setBulkInvite(null);
    }

    return (
        <Fragment>
            <MainContent title={title} className='bulk-users'>
                <div className='content'>
                    <div className='explanation'>{!!children && children}</div>
                    <div className='dnd-container'>
                        <DragNDrop
                            onChange={handleBulkInviteUsers}
                            uploadedFiles={bulkInviteFile}
                        />
                    </div>

                    <div className='footer'>
                        <button
                            className='btn highlighted'
                            disabled={!bulkInvite}
                            onClick={handleBulkInviteClick}
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            </MainContent>

            <GenericModal
                open={modal}
                success={succeed}
                loading={bulkLoading}
                loadingMessage={bulkLoadingMessage}
                message={message}
                onClose={() => {
                    setModal(false);
                    setSucceed(null);
                }}
                route={`/admin/users`}
            />
        </Fragment>
    );
}

BulkUser.propTypes = {
    children: PropTypes.object,
    loadingMessage: PropTypes.string,
    csvParseUrl: PropTypes.string,
    postUrl: PropTypes.string,
    successMessage: PropTypes.string,
    buttonText: PropTypes.string,
    title: PropTypes.string,
};
