import { Fragment, useEffect, useState } from 'react';
import { getClient } from '../../../../../services/clientService';
import {
    getSurveyQuestionComments,
    commentSurvey,
} from '../../../../../services/surveyService';
import { getTeam } from '../../../../../services/teamService';
import { UserPhoto } from '../../../../pages/me/hub/hubItems/Items/Components/UserPhoto';
import { PulseSelect } from '../../../inputs/PulseSelect';
import { SearchInput } from '../../../inputs/SearchInput';
import { Textarea } from '../../../inputs/Textarea';
import { CommentScoreLabel } from './CommentScoreLabel';
import { MainContent } from '../../../layout/MainContent';
import { humanizeDiffToday } from '../../../lib/dateTimeHelper';
import { useAsyncError } from '../../../lib/helper';
import { Loading } from '../../../loadings/Loading';
import { PropTypes } from 'prop-types';
import { Checkbox } from '../../../components/Checkbox';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useRagIntervals } from '../../../lib/useRagIntervals';
import { useClient } from '../../../lib/useClient';
import constants from '../../../constants';
import './CommentBar.scss';

export function CommentBar({
    surveyId,
    questionId,
    teamId,
    iteration,
    distributionFilter,
    questionType,
}) {
    const [loading, setLoading] = useState(true);

    const [hasMoreValues, setHasMoreValues] = useState(true);

    const [fetch, setFetch] = useState(true);

    const [data, setData] = useState([]);

    const [offset, setOffset] = useState(0);

    const [client, setClient] = useState(null);

    const [sorting, setSorting] = useState(0);

    const [filterText, setFilterText] = useState('');

    const [onlyComments, setOnlyComments] = useState(false);

    const bottomBoundaryRef = useRef(null);

    const tenant = useClient();

    async function fetchData() {
        try {
            setLoading(true);

            let comments = await getSurveyQuestionComments(
                surveyId,
                questionId,
                teamId,
                iteration,
                10,
                offset,
                filterText,
                sorting,
                distributionFilter,
                onlyComments,
                questionType
            );

            setData([...data, ...comments.data]);

            setHasMoreValues(comments.hasMoreItems);

            setOffset(offset + 10);

            setLoading(false);

            setFetch(false);
        } catch (e) {
            throwError(e);
        }
    }

    const scrollObserver = useCallback(
        (node) => {
            new IntersectionObserver((entries) => {
                entries.forEach((en) => {
                    if (en.intersectionRatio > 0) {
                        setFetch(true);
                    }
                });
            }).observe(node);
        },
        [data?.length]
    );

    useEffect(() => {
        if (bottomBoundaryRef?.current) {
            scrollObserver(bottomBoundaryRef.current);
        }
    }, [scrollObserver, bottomBoundaryRef]);

    useEffect(() => {
        if (fetch && hasMoreValues) {
            fetchData();
        }
    }, [fetch, hasMoreValues]);

    function reloadTable() {
        setFetch(false);
        setHasMoreValues(true);
        setData([]);
        setOffset(0);
        setFetch(true);
    }

    useEffect(() => {
        if (filterText || sorting || typeof iteration === 'number') {
            reloadTable();
        }
    }, [
        filterText,
        sorting,
        iteration,
        questionId,
        teamId,
        distributionFilter,
        onlyComments,
    ]);

    const throwError = useAsyncError();

    const filterOptions = [
        {
            label: 'Recent',
            value: 0,
        },
        {
            label: 'Oldest',
            value: 1,
        },
        {
            label: 'Alphabetical',
            value: 2,
        },
        {
            label: 'Lowest - Highest',
            value: 3,
        },
        {
            label: 'Highest - Lowest',
            value: 4,
        },
    ];

    useEffect(() => {
        async function fetchData() {
            var client = {};

            if (teamId) {
                let team = await getTeam(teamId);

                client = {
                    id: teamId,
                    logo: team.image,
                    companyName: team.title,
                };
            } else {
                client = await getClient();
            }

            setClient(client);
        }

        fetchData();
    }, [teamId]);

    async function onComment(questionId, actorId, text) {
        try {
            await commentSurvey(
                {
                    ItemId: questionId,
                    Comment: text,
                },
                actorId
            );
        } catch (e) {
            throwError(e);
        }
    }

    async function handleEnter(e, userQuestionId) {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.target.disabled = true;
            let text = e.target.value;

            if (text) {
                await onComment(userQuestionId, client.id, text);
                let comments = await getSurveyQuestionComments(
                    surveyId,
                    questionId,
                    teamId,
                    iteration,
                    10,
                    0,
                    filterText,
                    sorting,
                    distributionFilter,
                    onlyComments,
                    questionType
                );

                setData([...comments.data]);
            }
        }
    }

    return (
        <div className='comment-bar-container'>
            <div className='background-filter-bar'>
                <MainContent>
                    <div className='filter-section'>
                        <SearchInput
                            onSearch={(text) => setFilterText(text)}
                            placeholder={'Search user or text'}
                        />

                        <PulseSelect
                            value={filterOptions?.find(
                                (x) => x.value === sorting
                            )}
                            options={filterOptions}
                            onChange={(e) => setSorting(e.value)}
                            isSearchable={false}
                        />
                        {!tenant?.surveyConfiguration.isCommentRequired && (
                            <div className='only-comment-checkbox'>
                                <Checkbox
                                    checked={onlyComments}
                                    onChange={() =>
                                        setOnlyComments(
                                            (prevState) => !prevState
                                        )
                                    }
                                />
                                <label>Comments only</label>
                            </div>
                        )}
                    </div>
                </MainContent>
            </div>

            <div className='comment-bar'>
                {data?.map((comment, index) => {
                    return (
                        <TransitionComment
                            key={`transition-comment-${comment.userQuestionId}-${index}`}
                            comment={comment}
                            client={client}
                            handleEnter={(e, userQuestionId) =>
                                handleEnter(e, userQuestionId)
                            }
                        />
                    );
                })}
                <div
                    ref={bottomBoundaryRef}
                    style={{ minHeight: '2rem' }}
                ></div>
                {loading && <Loading />}
            </div>
        </div>
    );
}

CommentBar.propTypes = {
    surveyId: PropTypes.string,
    questionId: PropTypes.string,
    teamId: PropTypes.string,
    iteration: PropTypes.number,
    distributionFilter: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
    ]),
    questionType: PropTypes.number,
};

export function TransitionComment({ comment, client, handleEnter }) {
    const [hidden, setHidden] = useState(true);

    const { choiceArray } = useRagIntervals();

    return (
        <Fragment>
            <div className='main-box'>
                <div className='box '>
                    <div className='user-name'>
                        <div className='image'>
                            <UserPhoto
                                giver={comment.user}
                                anonymous={comment.user.isAnonymous}
                            />
                            <div>
                                <h4>{comment.user.name}</h4>
                                {!comment.user.isAnonymous && (
                                    <div className='team-bubbles-container'>
                                        <label className='title'> Teams:</label>
                                        {comment?.userTeams?.length ? (
                                            comment.userTeams.map((x, i) => (
                                                <span
                                                    key={`userTeam-${i}`}
                                                    className='team-bubble'
                                                >
                                                    {x.name}
                                                </span>
                                            ))
                                        ) : (
                                            <div className='team-bubble'>-</div>
                                        )}
                                    </div>
                                )}
                                {comment.choice && (
                                    <div className='choice-option'>
                                        <div
                                            className='choice-box'
                                            style={{
                                                backgroundColor:
                                                    choiceArray[
                                                        comment.choice.key - 11
                                                    ],
                                            }}
                                        ></div>
                                        <span className='choice-label'>
                                            {comment.choice.value}
                                        </span>
                                    </div>
                                )}
                                <p className='comment'>
                                    {comment.comment === '' ? (
                                        <i style={{ color: '#757575' }}>
                                            No comment added
                                        </i>
                                    ) : (
                                        comment.comment
                                    )}
                                </p>
                            </div>
                        </div>

                        <div className='date-value'>
                            <span>{humanizeDiffToday(comment.date)}</span>
                            {comment.type !== constants.QuestionType.Choice && (
                                <CommentScoreLabel
                                    value={
                                        comment?.score ??
                                        comment?.yesNo ??
                                        comment?.likert
                                    }
                                    type={comment.type}
                                />
                            )}

                            <a
                                role='button'
                                className='comments-button'
                                onClick={() => setHidden((prev) => !prev)}
                                onKeyPress={(e) =>
                                    e.key === 'Enter' &&
                                    setHidden((prev) => !prev)
                                }
                                tabIndex={0}
                            >
                                <i className='icon-comments'></i>
                                <span>Comment</span>
                            </a>
                        </div>
                    </div>
                </div>

                {comment?.reactions?.length > 0 &&
                    comment.reactions.map((reaction, i) => (
                        <TransitionReaction
                            key={`reaction-transition-${i}`}
                            reaction={reaction}
                        />
                    ))}

                {client &&
                    comment?.reactions?.length < 4 &&
                    ((comment?.reactions.length > 0 &&
                        comment?.reactions[comment.reactions.length - 1]?.actor
                            .id === comment?.user.id) ||
                        comment?.reactions?.length === 0) && (
                        <div
                            className={`comment-box ${hidden ? 'hidden' : ''}`}
                        >
                            <div>
                                <UserPhoto
                                    giver={{
                                        image: client.logo,
                                        name: client.companyName,
                                    }}
                                />
                            </div>
                            <div>
                                <div className='user-title'>
                                    <h4>{client.companyName} </h4>
                                </div>

                                <Textarea
                                    placeholder='Write a comment...'
                                    className='commentText'
                                    onKeyDown={(e) =>
                                        handleEnter(e, comment.userQuestionId)
                                    }
                                    maxLength={constants.maxLength}
                                    autoFocus
                                />
                            </div>
                        </div>
                    )}
            </div>
        </Fragment>
    );
}

TransitionComment.propTypes = {
    comment: PropTypes.object,
    client: PropTypes.object,
    handleEnter: PropTypes.func,
};

export function TransitionReaction({ reaction, color }) {
    return (
        <div className='comment-box'>
            <div>
                <UserPhoto
                    giver={reaction.actor}
                    anonymous={
                        reaction?.actor?.type === 0 &&
                        reaction.actor?.isAnonymous
                    }
                    color={color}
                />
            </div>
            <div>
                <div className='user-title'>
                    <h4>
                        {reaction?.actor?.type === 0 &&
                        reaction.actor?.isAnonymous
                            ? 'Anonymous'
                            : reaction.actor.name}
                    </h4>
                </div>

                <div>
                    <p>{reaction?.commentText}</p>
                </div>
            </div>
            <div className='date'>{humanizeDiffToday(reaction?.date)}</div>
        </div>
    );
}

TransitionReaction.propTypes = {
    reaction: PropTypes.object,
    color: PropTypes.string,
};
