import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { signIn } from '../../../services/authenticationService';
import helper from '../../shared/lib/helper';
import { Loading } from '../../shared/loadings/Loading';
import { HomePageTemplate } from '../../shared/templates/HomePageTemplate';
import './Login.scss';

export function Login() {
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();

    const watchEmail = watch('email');

    useEffect(() => {
        setError(helper.getParameterByName('error'));
        setLoading(false);
    }, []);

    function onSubmit(data) {
        signIn(data?.email, window.location.pathname);
    }

    return (
        <Fragment>
            {loading && <Loading className='login-loading' />}
            <HomePageTemplate className='login'>
                <form onSubmit={handleSubmit(onSubmit)} role='login'>
                    <img
                        className='home-page-logo'
                        alt='Pulse 360 logo'
                        src='logo.svg'
                    />
                    <h4>
                        Pulse 360 helps organisations, and their employees, be
                        the best they can be.
                    </h4>
                    <div className='group-form'>
                        <input
                            aria-label='Please introduce your exisiting email account'
                            aria-required='true'
                            id='email'
                            {...register('email', {
                                required: 'required',
                                pattern: {
                                    value: /^[a-zA-Z0-9.'!#$%&*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                    message:
                                        'Entered value does not match email format',
                                },
                            })}
                            type='email'
                            placeholder='Email'
                            autoFocus
                        />
                        {!!error && <p role='alert'>{error}</p>}
                        {errors?.email && (
                            <p role='alert'>{errors?.email?.message}</p>
                        )}
                    </div>

                    <a
                        className='home-page-login-link'
                        href='mailto:support@pulse360.io'
                        aria-label='Click the link if you have problems signing in, it will open your email application.'
                    >
                        Problem signing in?
                    </a>
                    <input
                        type='submit'
                        className='btn highlighted'
                        disabled={!watchEmail}
                        value={'SIGN IN'}
                    />

                    <p>
                        Don{"'"}t have an account?{' '}
                        <a
                            href='https://pulse360.io/contact'
                            rel='noreferrer'
                            target='_blank'
                            aria-label='Click the link if you want to contact Pulse 360. This will open a new tab.'
                        >
                            Get in touch.
                        </a>
                    </p>
                </form>
            </HomePageTemplate>
        </Fragment>
    );
}
