import SliderUnstyled from '@mui/base/SliderUnstyled';
import { styled, alpha } from '@mui/system';
import PropTypes from 'prop-types';

export const StyledSlider = styled(SliderUnstyled)(
    ({ theme }) => `
                    height: 4px;
                    width: 100%;
                    padding: 13px 0;
                    display: inline-block;
                    position: relative;
                    cursor: pointer;
                    touch-action: none;
                    -webkit-tap-highlight-color: transparent;
                    opacity: 0.75;

                    &:hover {
                        opacity: 1;
                    }
                
                    & .MuiSlider-rail {
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 4px;
                        border-radius: 2px;
                        background-color: currentColor;
                        opacity: 0.38;
                    }
                
                    & .MuiSlider-track {
                        display: block;
                        position: absolute;
                        height: 4px;
                        border-radius: 2px;
                        background-color: currentColor;
                    }

                    & .MuiSlider-markLabel {
                        position: absolute;
                        font-size: 15px;           
                        width: 14px;
                        height: 14px;
                        margin-left: -3px;
                        margin-top: 8px;
                        justify-content: space-between;
                    }

                    & .MuiSlider-valueLabelLabel {
                        font-size: 20px;
                        margin-bottom: 10rem;
                        background-color: #7ae2be;
                        color: whitesmoke;
                        border-radius: 50%
                    }
                
                    & .MuiSlider-thumb {
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        margin-left: -6px;
                        margin-top: -5px;
                        box-sizing: border-box;
                        border-radius: 50%;
                        outline: 0;
                        border: 2px solid currentColor;
                        background-color: #fff;
                
                        :hover,
                        &.Mui-focusVisible {
                            box-shadow: 0 0 0 0.25rem ${alpha(
                                theme.palette.mode === 'light'
                                    ? '#4cbabb'
                                    : '#90caf9',
                                0.15
                            )};
                        }
                    
                        &.Mui-active {
                            box-shadow: 0 0 0 0.25rem ${alpha(
                                theme.palette.mode === 'light'
                                    ? '#4cbabb'
                                    : '#90caf9',
                                0.3
                            )};
                        }
                }
                `
);

const marks = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
    {
        value: 9,
        label: '9',
    },
    {
        value: 10,
        label: '10',
    },
];

export function Slider({ name, errors, ...rest }) {
    return (
        <StyledSlider
            color='success'
            id={name}
            name={name}
            min={rest.min}
            max={rest.max}
            defaultValue={1}
            step={rest.step}
            getAriaValueText={(value) =>
                `Your selected score is: ${value} out of ten.`
            }
            aria-label='Score selector'
            aria-labelledby={name}
            aria-required={rest.required}
            aria-invalid={
                errors?.[name] && errors?.[name]?.message ? true : false
            }
            aria-describedby='error'
            autoFocus={rest.autoFocus}
            value={rest.value}
            marks={marks}
            required={rest.required ? true : false}
            disabled={rest.disabled ? true : false}
        />
    );
}

Slider.propTypes = {
    control: PropTypes.object,
    name: PropTypes.string,
    errors: PropTypes.object,
};
