import { Fragment, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    deleteQuestion,
    getQuestionTypes,
    getQuestionsPaginated,
} from '../../../../services/questionService';
import { AddButton } from '../../../shared/inputs/AddButton';
import { IconButtonModal } from '../../../shared/inputs/IconButtonModal';
import { SearchInput } from '../../../shared/inputs/SearchInput';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { ResponsiveTable } from '../../../shared/utils/ResponsiveTable';
import { IconButton } from '../../../shared/inputs/IconButton';
import './Questions.scss';

export function Questions() {
    const [filter, setFilter] = useState('');

    const [deleted, setDeleted] = useState(null);

    const [types, setTypes] = useState([]);

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    async function handleDelete(id) {
        try {
            await deleteQuestion(id);
            setDeleted(true);
        } catch (e) {
            setDeleted(false);
        }

        setFilter('');
    }

    useEffect(() => {
        async function fetchData() {
            let type = await getQuestionTypes();

            setTypes(type);
            setLoading(false);
        }

        fetchData();
    }, []);

    return (
        <Page
            title='Questions'
            description=''
            className='questions'
            loading={loading}
        >
            <MainContent testId='questions'>
                <div className='container-page'>
                    <div className='header'>
                        <AddButton
                            onClick={() =>
                                navigate('/admin/bank-of-questions/question')
                            }
                            autoFocus
                        >
                            Add Question
                        </AddButton>

                        <SearchInput
                            onSearch={(text) => setFilter(text)}
                            placeholder={'Search Question or Topic'}
                        />
                    </div>
                    <div className='row'>
                        <ResponsiveTable
                            className='questions'
                            noDataText='No current questions'
                            onFetchData={getQuestionsPaginated}
                            limit={10}
                            innerScroll={false}
                            defaultSorted={{
                                id: 'questionText',
                                desc: false,
                            }}
                            filter={filter}
                            refresh={deleted}
                            columns={[
                                {
                                    Header: 'Question',
                                    accessor: 'questionText',
                                    width: 400,
                                    sortDescFirst: true,
                                    sortType: useMemo(
                                        () => (a, b) => {
                                            var textA = a.original.questionText
                                                ? a.original.questionText?.toLowerCase()
                                                : '';
                                            var textB = b.original.questionText
                                                ? b.original.questionText.toLowerCase()
                                                : '';

                                            return textA > textB ? 1 : -1;
                                        },
                                        []
                                    ),
                                },
                                {
                                    Header: 'Type of question',
                                    accessor: 'questionType',
                                    sortDescFirst: true,
                                    sortType: useMemo(
                                        () => (a, b) => {
                                            return a.original.questionType >
                                                b.original.questionType
                                                ? 1
                                                : -1;
                                        },
                                        []
                                    ),
                                    Cell: (row) => (
                                        <div
                                            title={
                                                types.find(
                                                    (x) => x.value === row.value
                                                ).key
                                            }
                                            style={{ textAlign: 'center' }}
                                        >
                                            {
                                                types.find(
                                                    (x) => x.value === row.value
                                                ).key
                                            }
                                        </div>
                                    ),
                                },
                                {
                                    Header: 'Topic',
                                    width: 150,
                                    accessor: 'topic.title',
                                    sortDescFirst: true,
                                    sortType: useMemo(
                                        () => (a, b) => {
                                            return a.original.topic.title.toLowerCase() >
                                                b.original.topic.title.toLowerCase()
                                                ? 1
                                                : -1;
                                        },
                                        []
                                    ),
                                },
                                {
                                    Header: 'Survey',
                                    accessor: 'categories',
                                    id: 'survey',
                                    headerClassName: 'center',
                                    className: 'center',
                                    disableSortBy: true,
                                    Cell: (row) => (
                                        <Fragment>
                                            {row.value !== 1 ? (
                                                <span className='topic-checkmark'></span>
                                            ) : (
                                                ''
                                            )}
                                        </Fragment>
                                    ),
                                },
                                {
                                    Header: 'Feedback',
                                    accessor: 'categories',
                                    id: 'feedback',
                                    headerClassName: 'center',
                                    className: 'center',
                                    disableSortBy: true,
                                    Cell: (row) => (
                                        <Fragment>
                                            {row.value !== 2 ? (
                                                <span className='topic-checkmark'></span>
                                            ) : (
                                                ''
                                            )}
                                        </Fragment>
                                    ),
                                },
                                {
                                    Header: 'total-here',
                                    accessor: 'id',
                                    headerClassName: 'buttons',
                                    className: 'buttons',
                                    disableSortBy: true,
                                    width: 50,
                                    Cell: (row) => (
                                        <Fragment>
                                            <IconButton
                                                type='edit'
                                                onClick={() =>
                                                    navigate(
                                                        '/admin/bank-of-questions/question',
                                                        {
                                                            replace: true,
                                                            state: {
                                                                id: row.value,
                                                            },
                                                        }
                                                    )
                                                }
                                            />
                                            <IconButtonModal
                                                type='delete'
                                                success={deleted}
                                                message={
                                                    deleted
                                                        ? 'Question deleted successfully.'
                                                        : 'Ops! Something went wrong, please try again later.'
                                                }
                                                confirmMessage={
                                                    'Do you want to delete the question?'
                                                }
                                                onConfirm={() =>
                                                    handleDelete(row.value)
                                                }
                                                onClose={() => setDeleted(null)}
                                            />
                                        </Fragment>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            </MainContent>
        </Page>
    );
}
