import _uniqueId from 'lodash/uniqueId';
import { Fragment, useCallback, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useFormattedMessage } from '../lib/helper';
import { PropTypes } from 'prop-types';
import './components.scss';

export function InformationTooltip({ text, idText }) {
    const [id] = useState(_uniqueId('prefix-'));

    const [tooltipId] = useState(_uniqueId('tooltip-'));

    const formatMessage = useFormattedMessage;

    const [position, setPosition] = useState('top');

    useEffect(() => {
        if (id) {
            setPlace();
        }
    }, [id]);

    function offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft =
                window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop =
                window.pageYOffset || document.documentElement.scrollTop;
        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft,
            right: screen.width - rect.right,
            bottom: screen.height - rect.bottom,
        };
    }

    const setPlace = useCallback(() => {
        if (id) {
            let pos = offset(document.getElementById(tooltipId));

            let place = '';
            if (pos.bottom < 200) {
                place = 'top';
            } else if (pos.top < 200) {
                place = 'bottom';
            } else if (pos.right < 200) {
                place = 'left';
            } else if (pos.left < 200) {
                place = 'right';
            }

            setPosition(place);
        }
    }, [id]);

    return (
        <Fragment>
            <div
                data-tip
                data-for={id}
                id={tooltipId}
                className='pulse360-information'
            >
                ?
            </div>
            <ReactTooltip
                className={'pulse360-information-tooltip'}
                id={id}
                place={position}
                effect='solid'
                clickable={true}
            >
                <span>{idText ? formatMessage(idText) : text}</span>
            </ReactTooltip>
        </Fragment>
    );
}

InformationTooltip.propTypes = {
    text: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
    ]),
    idText: PropTypes.string,
};
