import { ProgressBarDistribution } from "../../../../components/ProgressBar";

export function YesNoDistributionChart({ data, onClick, selected, clickable }) {
    return data?.map((d, i) => (
        <ProgressBarDistribution
            key={`${d.title}-${i}`}
            color={d.color}
            title={d.title}
            selected={selected === d.title}
            onClick={(value) =>
                onClick(typeof value === 'boolean' ? value : null)
            }
            value={d?.value}
            clickable={clickable}
        />
    ));
}