import constants from '../../constants';
import { SurveyChart } from './charts/SurveyChart';
import { TrendChart } from './charts/trend/TrendChart';
import { PropTypes } from 'prop-types';
import { DistributionChart } from './charts/distribution/DistributionChart';
import { formatQuestionType } from '../../lib/helper';

export function QuestionCardRecurring({
    question,
    teamId,
    survey,
    iterations,
    iteration,
}) {
    return (
        <div className={'charts'}>
            <div>
                <h4>{`${formatQuestionType(question?.questionType)} trend`}</h4>
                <TrendChart
                    question={question}
                    iterations={[
                        ...(iterations?.sort(
                            (x, y) => x?.iteration - y?.iteration
                        ) ?? []),
                    ]}
                    teamId={teamId}
                    survey={survey}
                />
            </div>

            <hr className='vertical' />

            {question?.questionType === constants.QuestionType.Likert ||
            question?.questionType === constants.QuestionType.Choice ? (
                <div
                    style={
                        question?.questionType === constants.QuestionType.Likert
                            ? { width: '100%' }
                            : { width: '70%' }
                    }
                >
                    <DistributionChart
                        surveyId={survey?.id}
                        questionId={question?.id}
                        teamId={teamId}
                        iteration={iteration ?? 0}
                    />
                </div>
            ) : (
                <div>
                    <h4>{`#${iteration + 1}
                Survey ${formatQuestionType(
                    question?.questionType
                ).toLowerCase()}`}</h4>
                    <SurveyChart
                        current={
                            iterations?.length
                                ? iterations?.find(
                                      (x) => x.iteration === iteration
                                  )
                                : null
                        }
                        before={
                            iterations?.length > 1
                                ? iterations?.find(
                                      (x) => x.iteration === iteration - 1
                                  )
                                : null
                        }
                        question={question}
                        survey={survey}
                        iteration={iteration}
                        teamId={teamId}
                    />
                </div>
            )}
        </div>
    );
}

QuestionCardRecurring.propTypes = {
    question: PropTypes.object,
    teamId: PropTypes.string,
    survey: PropTypes.object,
    iterations: PropTypes.array,
    iteration: PropTypes.number,
};
