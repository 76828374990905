import { Fragment, useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import './components.scss';

export function StepBar({
    current,
    steps,
    labeled,
    notifications,
    next,
    previous,
    onNext,
    onPrevious,
    onCirclePress,
}) {
    const onKeyPress = useCallback(
        (event) => {
            if (
                event.key === 'ArrowRight' &&
                next &&
                event.target.tagName !== 'TEXTAREA'
            ) {
                onNext();
            }

            if (
                event.key === 'ArrowLeft' &&
                previous &&
                event.target.tagName !== 'TEXTAREA'
            ) {
                onPrevious();
            }
        },
        [onNext, onPrevious, next, previous]
    );

    const isBig = steps && steps.length > 6;

    useEffect(() => {
        document.addEventListener('keydown', onKeyPress, false);

        return () => document.removeEventListener('keydown', onKeyPress, false);
    }, [onKeyPress]);

    return (
        <Fragment>
            <div className='pulse-step-bar' tabIndex={0}>
                {previous !== undefined && (
                    <i
                        aria-hidden={true}
                        className={`icon icon-arrow-left ${
                            previous ? 'active' : ''
                        }`}
                        onClick={previous ? onPrevious : () => void 0}
                    ></i>
                )}
                {steps.map((x, index) => (
                    <div
                        key={`stepbar-${index}`}
                        className='step'
                        aria-label={
                            (index === current ? 'current ' : ' ') +
                            'step ' +
                            (index + 1) +
                            x +
                            '.'
                        }
                    >
                        <span
                            aria-hidden={true}
                            key={`span-${index}`}
                            className={`${index === current ? 'active' : ''}`}
                        >
                            {labeled && !isBig ? x : ''}
                        </span>

                        {index !== 0 && (
                            <div
                                key={`bar-${index}`}
                                className={`bar ${
                                    index <= current ? 'active' : ''
                                } ${labeled && !isBig ? 'labeled' : ''}`}
                            ></div>
                        )}

                        <div
                            key={`circle-${index}`}
                            className={`circle ${
                                index <= current ? 'active' : ''
                            } ${
                                notifications &&
                                !isNaN(notifications.find((x) => x === index))
                                    ? 'notification'
                                    : ''
                            } ${onCirclePress ? 'clickable' : ''}`}
                            onClick={() =>
                                onCirclePress ? onCirclePress(index) : void 0
                            }
                        ></div>
                    </div>
                ))}
                {next !== undefined && (
                    <i
                        className={`icon icon-arrow-right ${
                            next ? 'active' : ''
                        }`}
                        onClick={next ? onNext : () => void 0}
                    ></i>
                )}
            </div>
        </Fragment>
    );
}

StepBar.propTypes = {
    current: PropTypes.number,
    steps: PropTypes.array,
    labeled: PropTypes.bool,
    notifications: PropTypes.array,
    next: PropTypes.bool,
    previous: PropTypes.bool,
    onNext: PropTypes.func,
    onPrevious: PropTypes.func,
    onCirclePress: PropTypes.func,
};
