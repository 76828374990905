import { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';

export function NavigationHelper({ route, routes, onHide }) {
    useEffect(() => {
        onHide(!route?.noBreadcrumb);
    }, [route]);

    const fillIds = route => {
        let path = recursivePath(window.location.pathname, route);

        return path;
    };

    const recursivePath = (location, route) => {
        let ocurrence = route.indexOf('/:');

        if (ocurrence === -1) {
            return route;
        }

        if (location.indexOf(route.substring(0, ocurrence)) !== -1) {
            let substr = location.substring(ocurrence).substring(1);

            let id = substr.substring(
                0,
                substr.indexOf('/') !== -1
                    ? substr.indexOf('/')
                    : substr.length - 1
            );

            let routeWithId = route.slice(route.indexOf('/:')).substring(1);

            if (routeWithId.indexOf('/') !== -1) {
                route = route.replace(
                    routeWithId.substring(0, routeWithId.indexOf('/')),
                    id
                );
            } else {
                route = route.replace(routeWithId, id);
            }

            return recursivePath(location, route);
        }

        return route;
    };

    const isBackNavigation = pathname => {
        let route = routes.filter((x) => x.backRoute === pathname);

        return route.length > 0;
    };

    return isBackNavigation(route.path) ? (
        <Fragment>
            <a onClick={() => history.goBack()}>{route.title}</a>
            <span className='bold'>/</span>
        </Fragment>
    ) : (
        <Fragment>
            <Link to={fillIds(route.path)}>{route.title}</Link>
            <span className='bold'>/</span>
        </Fragment>
    );
}

NavigationHelper.propTypes = {
    route: PropTypes.object,
    routes: PropTypes.array,
    onHide: PropTypes.func,
};
