import { useCallback } from 'react';
import { useRef } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { getEntitiesInsights } from '../../../../services/insightsService';
import { InformationTooltip } from '../../../shared/components/InformationTooltip';
import { TeamSelector } from '../../../shared/components/TeamSelector';
import { SearchInput } from '../../../shared/inputs/SearchInput';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper';
import { useCache } from '../../../shared/lib/useCache';
import { useSelectedTeam } from '../../../shared/lib/useSelectedTeam';
import { useSession } from '../../../shared/lib/useSession';
import { DateSelector } from '../../../shared/utils/DateSelector';
import { WEntityInsights } from './widgets/WEntityInsights';
import { WInsights } from './widgets/WInsights';
import { WPulseIndex } from './widgets/WPulseIndex';
import { WPulseRate } from './widgets/WPulseRate';
import { Loading } from '../../../shared/loadings/Loading';
import './FeedbackOverview.scss';

export function FeedbackOverview() {
    const limit = 20;

    const [entitiesInsights, setEntitiesInsights] = useState([]);

    const [loadingEntities, setLoadingEntities] = useState(true);

    const [hasMoreValues, setHasMoreValues] = useState(true);

    const [fetch, setFetch] = useState(true);

    const [dates, setDates] = useState(null);

    const [search, setSearch] = useState(null);

    const { teams } = useCache();

    const [offset, setOffset] = useState(0);

    const bottomBoundaryRef = useRef(null);

    const session = useSession();

    const throwError = useAsyncError();

    const { team } = useSelectedTeam();

    async function fetchData() {
        try {
            setLoadingEntities(true);

            var entities = await getEntitiesInsights(
                dates.from,
                dates.to,
                team?.id,
                limit,
                offset,
                search,
                team
                    ? { id: 'Name', desc: false }
                    : { id: 'Title', desc: false }
            );

            setEntitiesInsights([
                ...entitiesInsights,
                ...(entities.data ?? []),
            ]);

            setHasMoreValues(entities.hasMoreItems);

            setOffset(offset + limit);

            setLoadingEntities(false);

            setFetch(false);
        } catch (e) {
            throwError(e);
        }
    }

    const scrollObserver = useCallback(
        (node) => {
            new IntersectionObserver((entries) => {
                entries.forEach((en) => {
                    if (en.intersectionRatio > 0) {
                        setFetch(true);
                    }
                });
            }).observe(node);
        },
        [entitiesInsights?.length]
    );

    useEffect(() => {
        if (bottomBoundaryRef?.current) {
            scrollObserver(bottomBoundaryRef.current);
        }
    }, [scrollObserver, bottomBoundaryRef]);

    function reloadTable() {
        setFetch(false);
        setHasMoreValues(true);
        setEntitiesInsights([]);
        setOffset(0);
        setFetch(true);
    }

    useEffect(() => {
        if (fetch && hasMoreValues && dates && teams) {
            fetchData();
        }
    }, [fetch, hasMoreValues, dates, teams]);

    useEffect(() => {
        reloadTable();
    }, [team, search, dates]);

    function sortByName(x, y) {
        return x.name.localeCompare(y.name, { sensitivity: 'base' });
    }

    return (
        <Page className='feedback-overview'>
            <Fragment>
                <div className='filter-bar'>
                    <DateSelector
                        onChange={(from, to, months) =>
                            setDates({ from, to, months })
                        }
                    />

                    {team?.id && <TeamSelector />}
                </div>

                <div className='widgets'>
                    <WPulseRate
                        teamId={team?.id}
                        isTeam={!!team?.id}
                        dates={dates}
                    />
                    <WPulseIndex
                        teamId={team?.id}
                        isTeam={!!team?.id}
                        dates={dates}
                    />
                    <WInsights
                        teamId={team?.id}
                        isTeam={!!team?.id}
                        dates={dates}
                    />
                </div>

                <div className='legend-search'>
                    <div className='feedback-legend'>
                        <i className='icon icon-pulse'></i>
                        <p>Pulse Survey completion rate</p>
                        <i className='icon icon-feedback-given'></i>
                        <p>Feedback given</p>
                        <i className='icon icon-feedback-received'></i>
                        <p>Feedback received</p>
                    </div>
                    <InformationTooltip
                        text={
                            !team?.id ? (
                                <Fragment>
                                    <ul>
                                        <li>
                                            Pulse Survey completion rate &ndash;
                                            Team survey completion rate during
                                            the selected time period.
                                        </li>
                                        <li>
                                            Feedback given &ndash; Volume of
                                            feedback given, across the team
                                            during the selected time period.
                                        </li>
                                        <li>
                                            Feedback received - Volume of
                                            feedback received, across the team
                                            during the selected time period.{' '}
                                            <em>
                                                Note: Feedback volume includes
                                                both peer and organisational
                                                feedback.
                                            </em>
                                        </li>
                                    </ul>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <ul>
                                        <li>
                                            Pulse Survey completion rate &ndash;
                                            Personal survey completion rate
                                            during the selected time period.
                                        </li>
                                        <li>
                                            Feedback given &ndash; Volume of
                                            feedback given during the selected
                                            time period.
                                        </li>
                                        <li>
                                            Feedback received - Volume of
                                            feedback received during the
                                            selected time period.{' '}
                                            <em>
                                                Note:&nbsp;Feedback given
                                                includes both peer and employee
                                                voice.
                                            </em>
                                        </li>
                                    </ul>
                                </Fragment>
                            )
                        }
                    />
                    <div className='search'>
                        <SearchInput
                            onSearch={(text) => setSearch(text)}
                            placeholder={'Search'}
                        />
                    </div>
                </div>

                {!team?.id && (
                    <div className='boxes'>
                        {entitiesInsights
                            ?.filter((x) =>
                                search
                                    ? x.name
                                          .trim()
                                          .toLowerCase()
                                          .includes(search.trim().toLowerCase())
                                    : x
                            )
                            .map((team) => (
                                <WEntityInsights
                                    months={dates?.months}
                                    key={team.id}
                                    teamId={team.id}
                                    image={team.image}
                                    name={team.name}
                                    feedbackGiven={
                                        entitiesInsights?.find(
                                            (x) => x.id === team.id
                                        )?.feedbackGiven
                                    }
                                    feedbackReceived={
                                        entitiesInsights?.find(
                                            (x) => x.id === team.id
                                        )?.feedbackReceived
                                    }
                                    pulseRate={
                                        entitiesInsights?.find(
                                            (x) => x.id === team.id
                                        )?.pulseCompletionRate
                                    }
                                />
                            ))}
                        <div ref={bottomBoundaryRef}></div>
                    </div>
                )}
                {team?.id && (
                    <Fragment>
                        {!!team?.teamLeads?.length && (
                            <div className='team-leads'>
                                <h3 role='none' aria-labelledby='Team Leads'>
                                    Team Lead(s)
                                </h3>
                                <div className='boxes'>
                                    {team?.teamLeads
                                        .sort(sortByName)
                                        ?.filter((x) =>
                                            search
                                                ? x.name
                                                      ?.trim()
                                                      ?.toLowerCase()
                                                      ?.includes(
                                                          search
                                                              ?.trim()
                                                              ?.toLowerCase()
                                                      )
                                                : x
                                        )
                                        ?.map((tl) => (
                                            <WEntityInsights
                                                selectedTeamId={team?.id}
                                                isAnonymous={
                                                    session?.isAnonymous
                                                }
                                                months={dates?.months}
                                                key={tl.id}
                                                userId={tl.id}
                                                image={tl.image}
                                                name={tl.name}
                                                feedbackGiven={
                                                    entitiesInsights?.find(
                                                        (x) => x.id === tl.id
                                                    )?.feedbackGiven
                                                }
                                                feedbackReceived={
                                                    entitiesInsights?.find(
                                                        (x) => x.id === tl.id
                                                    )?.feedbackReceived
                                                }
                                                pulseRate={
                                                    entitiesInsights?.find(
                                                        (x) => x.id === tl.id
                                                    )?.pulseCompletionRate
                                                }
                                            />
                                        ))}
                                </div>
                            </div>
                        )}

                        {!!team?.teamMembers?.length && (
                            <div className='team-leads'>
                                <h3 role='none'>Team Member(s)</h3>
                                <div className='boxes'>
                                    {entitiesInsights
                                        ?.filter(
                                            (x) =>
                                                !team?.teamLeads?.some(
                                                    (y) => y.id === x.id
                                                )
                                        )
                                        ?.filter((x) =>
                                            search
                                                ? x.name
                                                      ?.trim()
                                                      ?.toLowerCase()
                                                      ?.includes(
                                                          search
                                                              ?.trim()
                                                              ?.toLowerCase()
                                                      )
                                                : x
                                        )
                                        ?.map((tm) => (
                                            <WEntityInsights
                                                selectedTeamId={team?.id}
                                                isAnonymous={
                                                    session?.isAnonymous
                                                }
                                                months={dates?.months}
                                                key={tm.id}
                                                userId={tm.id}
                                                image={tm.image}
                                                name={tm.name}
                                                feedbackGiven={
                                                    tm?.feedbackGiven
                                                }
                                                feedbackReceived={
                                                    tm?.feedbackReceived
                                                }
                                                pulseRate={
                                                    tm?.pulseCompletionRate
                                                }
                                            />
                                        ))}
                                    <div ref={bottomBoundaryRef}></div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
                {loadingEntities && <Loading />}
                {!loadingEntities && entitiesInsights.length < 1 && (
                    <p className='no-data'>No elements have been found</p>
                )}
            </Fragment>
        </Page>
    );
}
