import { LineTrendChart } from './LineTrendChart';
import { PropTypes } from 'prop-types';
import { Loading } from '../../../../loadings/Loading';

export function ChoiceTrendChart({ iterations }) {
    return iterations && iterations[0].insight.choice ? (
        <LineTrendChart
            data={[
                ...iterations
                    .filter((x) => x.insight)
                    .map((x) => ({
                        Choice: Math.max(
                            ...Object.values(x?.insight?.choice).map(
                                (y) => y.popularity
                            )
                        ),
                        name: x?.iteration + 1,
                        index: Object.entries(x?.insight?.choice).find(
                            (y) =>
                                y[1].popularity ===
                                Math.max(
                                    ...Object.values(x?.insight?.choice).map(
                                        (z) => z.popularity
                                    )
                                )
                        )[0],
                    })),
            ]}
            dataKey={'Choice'}
            domain={[0, 100]}
        />
    ) : (
        <Loading />
    );
}

ChoiceTrendChart.propTypes = {
    iterations: PropTypes.array,
};
