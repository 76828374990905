import autosize from 'autosize';
import { Fragment, useState, useEffect, useRef } from 'react';
import constants from '../../../../shared/constants';
import { MainContent } from '../../../../shared/layout/MainContent';
import { Loading } from '../../../../shared/loadings/Loading';
import { PropTypes } from 'prop-types';
import {
    createCondition,
    updateCondition,
    deleteCondition,
    getUserConditions,
    getUserConditionsFiltered,
} from '../../../../../services/conditionService';
import './MyConditionWidget.scss';

export function MyConditionWidget({ showMore, success, allItems, userId }) {
    const [conditions, setConditions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [take, setTake] = useState(3);
    const [conditionId, setConditionId] = useState(null);

    const domRef = useRef([]);

    async function loadData(userId) {
        if (userId) {
            let conditionData = await getConditions(userId);

            var cond = conditionData.flatMap((x) => ({
                isActive: false,
                ...x,
            }));

            setConditions([...cond]);
            setLoading(false);
        }
    }

    useEffect(() => {
        loadData(userId);
    }, [userId]);

    useEffect(() => {
        if (conditionId) {
            domRef.current[conditionId].focus();
        }
    }, [conditionId]);

    function saveCondition(conditionId) {
        var condition = conditions.find((x) => x.id === conditionId);
        updateCondition(condition);
        loadData(userId);
    }

    function handleEdit(event, conditionId) {
        autosize(document.querySelectorAll('textarea'));

        var newConditions = conditions;

        newConditions
            .filter((x) => x.isActive && conditionId !== x.id)
            .forEach((goal) => {
                goal.isActive = false;
            });

        setConditionId(conditionId);

        if (!conditionId) {
            newConditions.find((x) => x.id === conditionId).isActive =
                !newConditions.find((x) => x.id === conditionId).isActive;

            setConditions([...newConditions]);

            if (!newConditions.find((x) => x.id === conditionId).isActive) {
                createConditionAsync(
                    newConditions.find((x) => x.id === conditionId)
                ).then(() => {
                    loadData(userId);
                });
            }
        } else {
            newConditions.find((x) => x.id === conditionId).isActive =
                !newConditions.find((x) => x.id === conditionId).isActive;

            newConditions = conditions.filter((x) => x.id !== null);

            setConditions([...newConditions]);

            if (!newConditions.find((x) => x.id === conditionId).isActive) {
                saveCondition(conditionId);
            }
            textareaRows();
        }
    }

    async function handleDelete(conditionId) {
        await deleteCondition(conditionId);
        loadData(userId);
    }

    function handleChange(event, conditionId) {
        var newConditions = conditions;

        newConditions.find((x) => x.id === conditionId).description =
            event.target.value;

        setConditionId(conditionId);
        setConditions([...newConditions]);
    }

    function textareaRows() {
        autosize(document.querySelectorAll('textarea'));
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.target.blur();
        }
    }

    function handleCreate() {
        if (conditions.filter((x) => x.isActive).length === 0) {
            var newCondition = {
                description: '',
                isActive: true,
                id: null,
                user: userId,
            };

            conditions.unshift(newCondition);

            setConditions([...conditions]);
            setConditionId(null);
        }
    }

    function getConditions(userId) {
        if (allItems) {
            return getUserConditions(userId);
        } else {
            return getUserConditionsFiltered(userId, take);
        }
    }

    function createConditionAsync(condition) {
        delete condition.id;
        condition.userId = condition.user;

        return createCondition(condition);
    }

    function handleShow() {
        setTake(take === 3 ? 5 : 3);
        loadData(userId);
    }

    function renderShow() {
        if (conditions.length >= 3 && showMore) {
            return (
                <div className='show-more-less'>
                    <a
                        tabIndex={0}
                        role='button'
                        onClick={handleShow}
                        onKeyPress={handleShow}
                    >{`Show ${take === 3 ? 'more' : 'less'}`}</a>
                </div>
            );
        }
    }

    function handleOnBlur(event, conditionId) {
        if (event.target.value) {
            handleEdit(event, conditionId);
        } else if (!event.target.value && conditionId) {
            event.target.focus();
            event.target.classList.add('invalid');
        } else {
            setConditions([...conditions.filter((x) => x.id)]);
        }
    }

    return (
        <MainContent
            title='My Conditions'
            className={` ${success ? '' : 'container-me-home-col'} `}
            line={success}
            addButton={(event) => {
                handleCreate(event);
            }}
        >
            <div className='condition-widget'>
                {loading ? (
                    <Loading />
                ) : (
                    <Fragment>
                        <div className='condition-content'>
                            {conditions.map((condition, index) => (
                                <div
                                    key={`mc-${index}`}
                                    className='main-container'
                                >
                                    <div key={`tit-${index}`} className='title'>
                                        <i
                                            key={`edit-${index}`}
                                            onClick={() => {}}
                                            className='icon-bullet inline-icon '
                                        ></i>
                                        <textarea
                                            rows={1}
                                            ref={(ref) =>
                                                (domRef.current[condition.id] =
                                                    ref)
                                            }
                                            className={`input-description ${
                                                condition.isActive ? '' : 'none'
                                            }`}
                                            onChange={(event) => {
                                                handleChange(
                                                    event,
                                                    condition.id
                                                );
                                            }}
                                            onKeyPress={(event) => {
                                                event.key === 'Enter' &&
                                                    handleKeyPress(
                                                        event,
                                                        condition.id
                                                    );
                                            }}
                                            onBlur={(event) => {
                                                handleOnBlur(
                                                    event,
                                                    condition.id
                                                );
                                            }}
                                            value={condition.description}
                                            maxLength={constants.maxLength}
                                            autoFocus
                                        ></textarea>
                                        <div
                                            className={`description ${
                                                condition.isActive ? 'none' : ''
                                            }`}
                                        >
                                            {condition.description}
                                        </div>
                                    </div>
                                    <div
                                        key={`but-${index}`}
                                        className='buttons'
                                    >
                                        <i
                                            role='button'
                                            tabIndex={0}
                                            key={`edit-${index}`}
                                            onClick={(event) => {
                                                handleEdit(event, condition.id);
                                            }}
                                            onKeyPress={(event) => {
                                                event.key === 'Enter' &&
                                                    handleEdit(
                                                        event,
                                                        condition.id
                                                    );
                                            }}
                                            title='Edit'
                                            className={`inline-icon ${
                                                condition.isActive
                                                    ? 'icon-edit active'
                                                    : 'icon-edit'
                                            }`}
                                        ></i>
                                        <i
                                            role='button'
                                            tabIndex={0}
                                            key={`delete-${index}`}
                                            onClick={() => {
                                                handleDelete(condition.id);
                                            }}
                                            onKeyPress={(event) => {
                                                event.key === 'Enter' &&
                                                    handleDelete(condition.id);
                                            }}
                                            title='Delete'
                                            className='icon-delete inline-icon '
                                        ></i>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {renderShow()}
                    </Fragment>
                )}
            </div>
        </MainContent>
    );
}

MyConditionWidget.propTypes = {
    showMore: PropTypes.bool,
    success: PropTypes.bool,
    allItems: PropTypes.bool,
    userId: PropTypes.string,
};
