import { useParams, useLocation, useNavigate } from 'react-router-dom';

export function withRouter(Child) {
    return function useWithRouter(props) {
        const params = useParams();
        const location = useLocation();
        const navigate = useNavigate();

        return <Child {...props} router={{ params, location, navigate }} />;
    };
}
