import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { Fragment } from 'react';
import './charts.scss';

const DEFAULT_COLOR = '#040404';

function RadialChart({
    className,
    radius,
    progress,
    strokeWidth,
    color,
    secondColor,
    text,
    numberText,
}) {
    const setStrokeLength = true;

    const circleRadius = Math.min(radius, 85);
    const circumference = 2 * 3.14 * circleRadius;
    const strokeLength = setStrokeLength ? (circumference / 100) * progress : 0;

    return (
        <div
            className={classNames('radial-chart', className, {
                'no-progress': strokeLength === 0,
            })}
        >
            <svg viewBox='0 0 180 180'>
                <circle
                    className='radial-chart-total'
                    opacity={typeof progress !== 'number' ? 1 : 0.3}
                    stroke={secondColor ? secondColor : color}
                    strokeWidth={strokeWidth}
                    fill='none'
                    cx='90'
                    cy='90'
                    r={circleRadius}
                />
                <circle
                    className='radial-chart-progress'
                    stroke={color}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${strokeLength},${circumference}`}
                    strokeLinecap={'round'}
                    fill='none'
                    cx='90'
                    cy='90'
                    r={circleRadius}
                />

                <text
                    x='50%'
                    y={text ? '45%' : '52%'}
                    dominantBaseline='middle'
                    fontSize='40'
                    strokeWidth='0'
                    stroke='#000'
                    textAnchor='middle'
                >
                    {numberText ? numberText : progress / 10}
                </text>

                {text ? (
                    <text
                        id='textValue'
                        x='50%'
                        y='58%'
                        textAnchor='middle'
                        strokeWidth='0'
                        stroke='#000'
                        fontSize='30'
                    >
                        {text}
                    </text>
                ) : (
                    <Fragment> </Fragment>
                )}
            </svg>
        </div>
    );
}

RadialChart.defaultProps = {
    radius: 80,
    progress: 100,
    strokeWidth: 10,

    color: DEFAULT_COLOR,
};

RadialChart.propTypes = {
    className: PropTypes.string,
    radius: PropTypes.number,
    color: PropTypes.string,
    progress: PropTypes.number,
    dimension: PropTypes.number,
    strokeWidth: PropTypes.string,
    text: PropTypes.string,
    numberText: PropTypes.string,
    secondColor: PropTypes.string,
};

export default RadialChart;
