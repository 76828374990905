import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    AppActionTypes,
    AppContext,
} from '../../../../contexts/AppContext.jsx';
import { getClient, updateClient } from '../../../../services/clientService';
import { SubmitButton } from '../../../shared/components/SubmitButton';
import { AvatarImage } from '../../../shared/inputs/AvatarImage';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper.js';
import './Organisation.scss';

export function Organisation() {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [client, setClient] = useState(null);

    const [imageFile, setImageFile] = useState(null);

    const { dispatch } = useContext(AppContext);

    const [form, setForm] = useState(null);

    const throwError = useAsyncError();

    useEffect(() => {
        async function getClientAsync() {
            try {
                let c = await getClient();

                reset(c);
                setClient(c);
            } catch (error) {
                throwError(error);
            }
        }

        getClientAsync();
    }, []);

    async function onSubmit(data) {
        try {
            setForm({ loading: true, disabled: true });
            let request = {
                ...data,
                ...imageFile,
            };

            let c = await updateClient(request);
            setClient(c);
            dispatch({
                type: AppActionTypes.UPDATE,
                prop: 'client',
                payload: c,
            });
            reset(c);

            setForm({ ...form, loading: false, success: true });
        } catch (error) {
            setForm({
                ...form,
                loading: false,
                success: false,
                disabled: false,
            });
        }
    }

    useEffect(() => {
        if (form?.success) {
            setTimeout(() => {
                setForm({ success: false, disabled: false });
            }, 1000);
        }
    }, [form]);

    return (
        <Page
            title='Organisation'
            description=''
            className='organisation'
            loading={!client}
        >
            <MainContent testId='organisation'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='group-form'>
                        <label
                            htmlFor='companyName'
                            aria-label='Organisation name'
                        >
                            Name
                        </label>
                        <input
                            name='companyName'
                            id='companyName'
                            aria-invalid={
                                errors?.companyName?.message ? true : false
                            }
                            aria-describedby='error'
                            className='max'
                            {...register('companyName', {
                                required: 'Required',
                            })}
                            autoFocus
                        />

                        {errors?.companyName?.message && (
                            <div className='error'>
                                {errors?.companyName?.message}
                            </div>
                        )}
                    </div>
                    <div className='group-form avatar'>
                        <label>Logo</label>
                        <AvatarImage
                            image={imageFile?.file || client?.logo}
                            onChange={(file, toBlob) =>
                                setImageFile({
                                    base64Image: toBlob,
                                    file: file,
                                })
                            }
                        />
                    </div>
                    <SubmitButton
                        loading={form?.loading}
                        success={form?.success}
                        disabled={form?.disabled}
                    >
                        Save
                    </SubmitButton>
                </form>
            </MainContent>
        </Page>
    );
}
