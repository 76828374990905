import { get } from '../components/shared/lib/axios';

const controllerRoute = 'widgets';

export async function getConnections(from, to, teamId) {
    return get(`${controllerRoute}/pulse-index/connections`, {
        from,
        to,
        teamId,
    });
}

export async function getPulseIndex(from, to, teamId) {
    return get(`${controllerRoute}/pulse-index`, {
        from,
        to,
        teamId,
    });
}

export async function getUserPulseIndex(from, to, userId) {
    return get(`${controllerRoute}/user/pulse-index`, {
        from,
        to,
        userId,
    });
}

export async function getUserConnections(from, to, userId) {
    return get(`${controllerRoute}/user/pulse-index/connections`, {
        from,
        to,
        userId,
    });
}

export async function getUserSurveyIndex(from, to, userId) {
    return get(`${controllerRoute}/user/pulse-index/survey`, {
        from,
        to,
        userId,
    });
}
