import { Fragment, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    deleteTeam,
    getTeamsPaginated,
    getUserTeamLeadTeam,
    getTeams,
} from '../../../../services/teamService';
import { AddButton } from '../../../shared/inputs/AddButton';
import { IconButton } from '../../../shared/inputs/IconButton';
import { IconButtonModal } from '../../../shared/inputs/IconButtonModal';
import { SearchInput } from '../../../shared/inputs/SearchInput';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { ResponsiveTable } from '../../../shared/utils/ResponsiveTable';
import { AppActionTypes, AppContext } from '../../../../contexts/AppContext';
import moment from 'moment';
import './Teams.scss';

export function Teams() {
    const { dispatch } = useContext(AppContext);

    const [filter, setFilter] = useState('');

    const [deleted, setDeleted] = useState(null);

    const navigate = useNavigate();

    async function handleDelete(id) {
        try {
            await deleteTeam(id);
            let tl = await getUserTeamLeadTeam();
            let t = await getTeams();

            setDeleted(true);
            dispatch({
                type: AppActionTypes.UPDATE,
                prop: 'selectedTeam',
                payload: t[0],
            });

            dispatch({
                type: AppActionTypes.UPDATE,
                prop: 'cache',
                payload: {
                    date: moment(),
                    teamLeadTeams: tl,
                },
            });
        } catch (e) {
            setDeleted(false);
        }
    }

    return (
        <Page title='Teams' className='teams'>
            <MainContent testId='teams'>
                <Fragment>
                    <div className='header'>
                        <AddButton
                            onClick={() => navigate('/admin/teams/new')}
                            autoFocus
                        >
                            Add Team
                        </AddButton>

                        <SearchInput
                            onSearch={(text) => setFilter(text)}
                            placeholder={'Name'}
                        />
                    </div>

                    <div className='table-content'>
                        <ResponsiveTable
                            className='teams'
                            noDataText='No current teams'
                            onFetchData={getTeamsPaginated}
                            limit={10}
                            innerScroll={false}
                            defaultSorted={{ id: 'title', desc: false }}
                            filter={filter}
                            refresh={deleted}
                            columns={[
                                {
                                    Header: '',
                                    accessor: 'image',
                                    headerClassName: 'image',
                                    className: 'image',
                                    disableSortBy: true,
                                    width: 50,
                                    Cell: (row) => (
                                        <img
                                            src={row.value}
                                            alt='Image'
                                            className='image'
                                        />
                                    ),
                                },
                                {
                                    Header: 'Team',
                                    accessor: 'title',
                                    sortDescFirst: true,
                                },
                                {
                                    Header: 'Team Leaders/Managers',
                                    accessor: 'teamLeads',
                                    disableSortBy: true,
                                    Cell: (row) => (
                                        <div
                                            title={row.value
                                                .map((x) => x.name)
                                                .join(', ')}
                                        >
                                            <div className='count'>
                                                {row.value.length}
                                            </div>
                                            {row.value
                                                .map((x) => x.name)
                                                .join(', ')}
                                        </div>
                                    ),
                                },
                                {
                                    Header: 'Team Members',
                                    accessor: 'teamMembers',
                                    disableSortBy: true,
                                    Cell: (row) => (
                                        <div
                                            title={row.value
                                                .map((x) => x.name)
                                                .join(', ')}
                                        >
                                            <div className='count'>
                                                {row.value.length}
                                            </div>
                                            {row.value
                                                .map((x) => x.name)
                                                .join(', ')}
                                        </div>
                                    ),
                                },
                                {
                                    Header: 'total-here',
                                    accessor: 'id',
                                    headerClassName: 'buttons',
                                    className: 'buttons',
                                    disableSortBy: true,
                                    width: 50,
                                    Cell: (row) => (
                                        <div style={{ textAlign: 'center' }}>
                                            <IconButton
                                                type='edit'
                                                route={`/admin/teams/edit/${row.value}`}
                                            />
                                            <IconButtonModal
                                                type='delete'
                                                success={deleted}
                                                message={
                                                    deleted
                                                        ? 'Team deleted successfully.'
                                                        : 'Ops! Something went wrong, please try again later.'
                                                }
                                                confirmMessage={
                                                    <Fragment>
                                                        Are you sure you want to
                                                        delete this team?
                                                        <br />
                                                        <strong>
                                                            All users will be
                                                            removed from the
                                                            team and the team
                                                            insights will no
                                                            longer be
                                                            accessible.
                                                        </strong>
                                                    </Fragment>
                                                }
                                                onConfirm={() =>
                                                    handleDelete(row.value)
                                                }
                                                onClose={() => setDeleted(null)}
                                            />
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </Fragment>
            </MainContent>
        </Page>
    );
}
