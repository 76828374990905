import Slider from 'rc-slider';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    feedbackGive,
    feedbackReact,
} from '../../../../../services/guestFeedbackService';
import { getQuestionType } from '../../../../../services/utilService';
import { SubmitButton } from '../../../../shared/components/SubmitButton';
import { Tabs } from '../../../../shared/components/Tabs';
import constants from '../../../../shared/constants';
import { Textarea } from '../../../../shared/inputs/Textarea';
import { humanizeDiffToday } from '../../../../shared/lib/dateTimeHelper';
import { useAsyncError } from '../../../../shared/lib/helper';
import { useRecaptcha } from '../../../../shared/lib/useRecaptcha';
import { PropTypes } from 'prop-types';

export function GuestFeedbackItem({ feedback, tenantId, onChange }) {
    const [questionType, setQuestionType] = useState(null);

    const {
        handleSubmit,
        control,
        formState: { isValid, errors, isSubmitted },
    } = useForm();

    const onRecaptcha = useRecaptcha();

    const {
        handleSubmit: handleComment,
        control: controlComment,
        formState: formStateComment,
    } = useForm();

    const { errors: errorsComment, isSubmitting: isSubmittingComment } =
        formStateComment;

    const [form, setForm] = useState(null);

    const inputRef = useRef(null);

    const tabs = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ];

    const throwError = useAsyncError();

    const [formComment] = useState(null);

    useEffect(() => {
        async function fetch() {
            let type = await getQuestionType();

            setQuestionType(type);
        }

        fetch();
    }, []);

    async function onSubmit(data) {
        setForm({ loading: true });
        try {
            onRecaptcha(async () => {
                let feed = await feedbackGive(
                    {
                        Id: feedback?.id,
                        feedbackGiver: feedback?.feedbackGiver,
                        feedbackReceiver: feedback?.feedbackReceiver,
                        ...data,
                    },
                    tenantId
                );
                setForm({ loading: false, success: true });

                onChange(feed);
            });
        } catch (error) {
            setForm({ loading: false, success: false });
        }
    }

    async function onComment(data) {
        if (!formComment?.commented) {
            setForm({ loading: true, commented: true });
            try {
                onRecaptcha(async () => {
                    let feed = await feedbackReact(
                        {
                            itemId: feedback?.id,
                            comment: data.reaction,
                        },
                        tenantId
                    );

                    onChange(feed);
                });
            } catch (error) {
                throwError(error);
            } finally {
                setForm({ loading: false, commented: true });
            }
        }
    }

    return (
        <Fragment>
            <div className='guest-feedback-item'>
                <div className='top'>
                    <div className='logo-user-client'>
                        <img src={feedback?.client?.logo} />
                        <img src={feedback?.feedbackReceiver?.image} />
                    </div>
                    <div>
                        <h4>{feedback?.feedbackReceiver?.name}</h4>
                        <h5>{feedback?.feedbackReceiver?.jobTitle}</h5>
                    </div>
                    <div>
                        <p className='small light'>
                            {humanizeDiffToday(feedback?.created)}
                        </p>
                    </div>
                </div>

                <p className='large question'>
                    {feedback?.givenFeedback?.question}
                </p>
                {!feedback?.isAnswered && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='group-form'>
                            {feedback?.givenFeedback?.type ===
                                questionType?.score && (
                                <Controller
                                    name='value'
                                    control={control}
                                    defaultValue={null}
                                    rules={{ required: 'Score is required' }}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <Fragment>
                                            <Slider
                                                min={1}
                                                max={10}
                                                marks={{
                                                    1: '1',
                                                    2: '2',
                                                    3: '3',
                                                    4: '4',
                                                    5: '5',
                                                    6: '6',
                                                    7: '7',
                                                    8: '8',
                                                    9: '9',
                                                    10: '10',
                                                }}
                                                value={value}
                                                onChange={(s) => onChange(s)}
                                            />
                                        </Fragment>
                                    )}
                                />
                            )}

                            {feedback?.givenFeedback?.type ===
                                questionType?.yesNo && (
                                <Controller
                                    name='value'
                                    control={control}
                                    defaultValue={undefined}
                                    rules={{
                                        validate: (value) =>
                                            typeof value === 'boolean' ||
                                            'Yes no is required',
                                    }}
                                    render={({ field: { onChange } }) => {
                                        return (
                                            <Fragment>
                                                <Tabs
                                                    options={tabs}
                                                    onChange={(active) => {
                                                        onChange(active);
                                                    }}
                                                />
                                            </Fragment>
                                        );
                                    }}
                                />
                            )}

                            {errors?.value?.message && (
                                <p role='alert'>{errors?.value?.message}</p>
                            )}
                        </div>

                        <div className='group-form'>
                            <Controller
                                name='answer'
                                control={control}
                                rules={{ required: 'Answer is required' }}
                                defaultValue={''}
                                render={({
                                    field: {
                                        onChange,
                                        onBlur,
                                        value,
                                        name,
                                        ref,
                                    },
                                }) => (
                                    <Fragment>
                                        <Textarea
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            maxLength={constants.maxLength}
                                            placeholder='Type your feedback...'
                                            rows={3}
                                            value={value}
                                            name={name}
                                            ref={ref}
                                        />
                                    </Fragment>
                                )}
                            />
                            {errors?.answer?.message && (
                                <p role='alert'>{errors?.answer?.message}</p>
                            )}
                        </div>

                        <SubmitButton
                            disabled={!isValid && isSubmitted}
                            loading={form?.loading}
                            success={form?.success}
                        >
                            Respond
                        </SubmitButton>
                    </form>
                )}

                {feedback?.isAnswered && (
                    <div className='answered'>
                        <div className='answer'>
                            <div className='user-title'>
                                <img
                                    src={`https://eu.ui-avatars.com/api/?size=512&font-size=0.5&background=1d3544&color=fff&format=svg&name=${feedback?.feedbackGiver?.name}`}
                                    alt='Reaction actor image miniature'
                                />
                                <h4> {feedback?.feedbackGiver?.name}</h4>
                                <p className='small light'>
                                    {humanizeDiffToday(
                                        feedback?.givenFeedback?.date
                                    )}
                                </p>
                            </div>
                            {feedback?.givenFeedback?.type ===
                                questionType?.score && (
                                <Slider
                                    min={1}
                                    max={10}
                                    marks={{
                                        1: '1',
                                        2: '2',
                                        3: '3',
                                        4: '4',
                                        5: '5',
                                        6: '6',
                                        7: '7',
                                        8: '8',
                                        9: '9',
                                        10: '10',
                                    }}
                                    value={feedback?.givenFeedback?.value}
                                    onChange={(s) => onChange(s)}
                                />
                            )}

                            {feedback?.givenFeedback?.type ===
                                questionType?.yesNo && (
                                <Tabs
                                    options={tabs}
                                    disabled={true}
                                    value={feedback?.givenFeedback?.value}
                                />
                            )}

                            <div className='feedback'>
                                <h5>{feedback?.givenFeedback?.feedback}</h5>{' '}
                            </div>
                        </div>
                        {/* <i
                            className='icon icon-respond-active'
                            onClick={() => setEmailModal(true)}></i> */}

                        {!!feedback?.reactions?.length &&
                            feedback?.reactions?.map((reaction, i) => (
                                <div key={i} className='reaction'>
                                    {reaction?.actor?.image ? (
                                        <img
                                            src={reaction?.actor.image}
                                            alt='Reaction actor image miniature'
                                        />
                                    ) : (
                                        <img
                                            src={`https://eu.ui-avatars.com/api/?size=512&font-size=0.5&background=1d3544&color=fff&format=svg&name=${feedback?.feedbackGiver?.name}`}
                                            alt='Reaction actor image miniature'
                                        />
                                    )}
                                    <div>
                                        <div>
                                            <h4>{reaction?.actor?.name}</h4>
                                            <p className='small light'>
                                                {humanizeDiffToday(
                                                    reaction?.date
                                                )}
                                            </p>
                                        </div>
                                        <p>{reaction?.commentText}</p>
                                    </div>
                                </div>
                            ))}
                        {feedback?.reactions.length < 4 &&
                            feedback?.reactions.length > 0 &&
                            feedback?.reactions[feedback?.reactions.length - 1]
                                .actor.id === feedback?.feedbackReceiver.id && (
                                <form
                                    className='comment-box'
                                    onSubmit={handleComment(onComment)}
                                >
                                    <div>
                                        <img
                                            src={`https://eu.ui-avatars.com/api/?size=512&font-size=0.5&background=1d3544&color=fff&format=svg&name=${feedback?.feedbackGiver?.name}`}
                                            alt='Reaction actor image miniature'
                                        />

                                        <div className='group-form'>
                                            <Controller
                                                name='reaction'
                                                control={controlComment}
                                                rules={{
                                                    required:
                                                        'Comment is required',
                                                }}
                                                defaultValue={''}
                                                render={({
                                                    field: {
                                                        onChange,
                                                        onBlur,
                                                        value,
                                                        name,
                                                        ref,
                                                    },
                                                }) => (
                                                    <Fragment>
                                                        <Textarea
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            disabled={
                                                                isSubmittingComment
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (
                                                                    e.keyCode ==
                                                                        13 &&
                                                                    e.shiftKey ==
                                                                        false
                                                                ) {
                                                                    e.preventDefault();

                                                                    if (
                                                                        inputRef?.current &&
                                                                        !isSubmittingComment
                                                                    ) {
                                                                        inputRef?.current?.click();
                                                                    }
                                                                }
                                                            }}
                                                            maxLength={
                                                                constants.maxLength
                                                            }
                                                            placeholder='Write a comment...'
                                                            rows={3}
                                                            value={value}
                                                            name={name}
                                                            ref={ref}
                                                        />
                                                    </Fragment>
                                                )}
                                            />
                                            {errorsComment?.reaction
                                                ?.message && (
                                                <p role='alert'>
                                                    {
                                                        errorsComment?.reaction
                                                            ?.message
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    </div>

                                    <i
                                        className='icon icon-send'
                                        onClick={() => {
                                            if (
                                                inputRef?.current &&
                                                !isSubmittingComment &&
                                                !inputRef?.current?.disabled &&
                                                !formComment?.loading &&
                                                !formComment?.commented
                                            ) {
                                                inputRef?.current?.click();
                                            }
                                        }}
                                    ></i>

                                    <SubmitButton
                                        ref={inputRef}
                                        hidden={true}
                                        disabled={
                                            formComment?.commented ||
                                            formComment?.loading ||
                                            isSubmittingComment
                                        }
                                        loading={formComment?.loading}
                                    />
                                </form>
                            )}
                    </div>
                )}
            </div>
        </Fragment>
    );
}

GuestFeedbackItem.propTypes = {
    feedback: PropTypes.object,
    tenantId: PropTypes.string,
    onChange: PropTypes.func,
};
