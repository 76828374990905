import { useEffect } from 'react';
import { Aside } from '../../../shared/components/Aside';
import { Tip } from '../../../shared/components/Tip';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useMediaQueries } from '../../../shared/lib/useMediaQuery';
import { HubItems } from './hubItems/HubItems';
import { AskGive } from './widgets/AskGive';
import { MeHomeWidget } from './widgets/MeHomeWidget';
import ReactTooltip from 'react-tooltip';
import './MeHome.scss';

export function MeHome() {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    const { mediumDown } = useMediaQueries();

    return (
        <Page className='me-home' testId='me-home'>
            <div className='ask-me-items'>
                <MainContent>
                    <AskGive />
                </MainContent>
                <HubItems />
            </div>
            {!mediumDown && (
                <Aside>
                    <div className='me-widgets'>
                        <MeHomeWidget />
                        <Tip />
                    </div>
                </Aside>
            )}
        </Page>
    );
}
