import { Fragment } from 'react';
import { useSession } from '../../shared/lib/useSession';
import { SectionButton } from './Components';
import { PropTypes } from 'prop-types';

export function SideBarMe({ onBack }) {
    const session = useSession();

    return (
        <Fragment>
            <div role='menu'>
                <a
                    tabIndex={0}
                    aria-label='Click to go back'
                    className='title'
                    onClick={() => onBack()}
                >
                    <i
                        aria-label='Click to go back'
                        className='icon-long-arrow-left'
                    ></i>
                    <h5>Me</h5>
                </a>
                <SectionButton
                    to={'/me/hub'}
                    icon={'icon-home-active'}
                    title='Hub'
                >
                    Hub
                </SectionButton>
                <SectionButton
                    to={'/me/insight'}
                    icon={'icon-insights-active'}
                    title='Insights'
                >
                    Insights
                </SectionButton>
                {session?.isPerformance && (
                    <Fragment>
                        <div className='sub-title'>
                            <h6>Assess</h6>
                        </div>
                        <SectionButton
                            to={'/me/assess/success'}
                            icon={'icon-results'}
                            title='Success'
                        >
                            Success
                        </SectionButton>
                        <SectionButton
                            to={'/me/assess/assessment'}
                            icon={'icon-ppr-active'}
                            title='Assessment'
                        >
                            Assessment
                        </SectionButton>
                        <SectionButton
                            to={'/me/assess/actions'}
                            icon={'icon-summary-active'}
                            title='Actions'
                        >
                            Actions
                        </SectionButton>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}

SideBarMe.propTypes = {
    onBack: PropTypes.func,
};
