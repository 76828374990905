import { Fragment } from 'react';
import { ReactComponent as InsightsComponent } from '../../../../assets/images/guest-feedback/insights-component.svg';
import { ReactComponent as Ideas } from '../../../../assets/images/guest-feedback/undraw-ideas.svg';
import { ReactComponent as NewIdea } from '../../../../assets/images/guest-feedback/undraw-new-ideas.svg';
import instagram from '../../../../assets/images/instagram.png';
import linkedin from '../../../../assets/images/linkedin.png';
import twitter from '../../../../assets/images/twitter.png';
import { useMediaQueries } from '../../../shared/lib/useMediaQuery';
import { PropTypes } from 'prop-types';
import './GuestFeedback.scss';

export function GuestFeedbackLayout({ children, link }) {
    const { smallDown: mobile } = useMediaQueries();

    return (
        <div className='guest-feedback-layout'>
            <main className={mobile ? 'mobile' : ''}>
                <div className='top-bar'>
                    <div className='logo'>
                        <img src='/logo.svg' alt='Pulse 360 app logo'></img>
                        {mobile ? <h4>Pulse 360</h4> : <h1>Pulse 360</h1>}
                    </div>
                    {mobile && (
                        <div className='image-mobile up'>
                            <NewIdea />
                        </div>
                    )}
                    {!mobile && (
                        <Fragment>
                            <div>{link}</div>
                            <div>
                                <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href='https://pulse360.io'
                                >
                                    About Pulse360
                                </a>
                            </div>
                        </Fragment>
                    )}
                </div>
                <div className='body'>{children}</div>

                {mobile && (
                    <div className='image-mobile down'>
                        <Ideas />
                    </div>
                )}

                <div className='footer'>
                    <hr />
                    <div>
                        {!mobile && (
                            <div>
                                <p className='smallest'>
                                    FSP Consulting Services Limited. Company
                                    number: 07717182 VAT number: 369 7632 44
                                </p>
                                <p className='smallest'>
                                    Now Building, Here & Now, Thames Valley
                                    Park, Reading, Berkshire, England, RG6 1WG
                                </p>
                            </div>
                        )}
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='mailto:support@pulse360.io'
                        >
                            Support
                        </a>

                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.pulse360.io/contact'
                        >
                            Contact
                        </a>
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.linkedin.com/company/pulse-360-software/about/'
                        >
                            <img src={linkedin} />
                        </a>
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.instagram.com/pulse_360/?hl=en'
                        >
                            <img src={instagram} />
                        </a>
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://twitter.com/Pulse360io'
                        >
                            <img src={twitter} />
                        </a>
                    </div>
                </div>
            </main>
            {!mobile && (
                <aside>
                    <NewIdea />
                    <div>
                        <InsightsComponent />
                    </div>
                    <Ideas />
                </aside>
            )}
        </div>
    );
}

GuestFeedbackLayout.propTypes = {
    children: PropTypes.object,
    link: PropTypes.string,
};
