import { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const initialState = {
    user: null,
    cache: null,
    client: null,
    ragIntervals: null,
    selectedTeam: null,
    selectedMonths: null,
    currentLocation: null,
    refresh: 0,
};
export const AppContext = createContext(initialState);

export const AppActionTypes = {
    ADD: 'add',
    UPDATE: 'update',
    REMOVE: 'remove',
    REFRESH: 'refresh',
};

export function AppProvider({ children }) {
    const { Provider } = AppContext;
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case AppActionTypes.ADD:
                return { ...state, ...action.payload };
            case AppActionTypes.UPDATE: {
                let aux = { ...state };
                if (Array.isArray(action.prop)) {
                    action.prop?.forEach(
                        (p, i) => (aux[p] = action.payload[i])
                    );
                } else {
                    aux[action.prop] = action.payload;
                }

                return aux;
            }
            case AppActionTypes.REMOVE: {
                let aux = { ...state };
                delete aux[action.prop];
                return { ...initialState, ...aux };
            }
            case AppActionTypes.REFRESH:
                return { ...state, refresh: state?.refresh + 1 };

            default:
                break;
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

AppProvider.propTypes = {
    children: PropTypes.object,
};
