import { useTable } from 'react-table';
import { PropTypes } from 'prop-types';
import './components.scss';

export function WidgetTable({ data, columns }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data,
        });

    return (
        <div className='widget-table'>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((hG, i) => (
                        <tr key={i} {...hG.getHeaderGroupProps()}>
                            {hG.headers.map((column, j) => (
                                <th key={j} {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr key={i} {...row.getRowProps()}>
                                {row.cells.map((cell, j) => {
                                    return (
                                        <td
                                            key={j}
                                            title={
                                                typeof cell.value !== 'object'
                                                    ? cell.value
                                                    : ''
                                            }
                                            width={cell.column.width}
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

WidgetTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
};
