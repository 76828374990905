import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    getMeItem,
    getSurveyMeItem,
} from '../../../../../services/meItemService';
import { useAsyncError } from '../../../../shared/lib/helper';
import { HubItems } from './HubItems';
import './HubItems.scss';

export function DeepLinkItem() {
    const { surveyId, iteration, itemId } = useParams();

    const [loading, setLoading] = useState(true);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchData() {
            await loadItem();

            setLoading(false);
        }

        fetchData();
    }, []);

    async function loadItem() {
        let item = {};

        if (itemId) {
            item = await getMeItem(itemId);
        } else if (surveyId) {
            item = await getSurveyMeItem(surveyId, iteration);
        } else {
            throwError(new Error('Bad request'));
        }

        return item;
    }

    return (
        <div data-testid='deeplink-item'>
            {!loading && <HubItems deepLink={true} getItem={loadItem} />}
        </div>
    );
}
