import { PropTypes } from 'prop-types';
import {
    Legend,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    ResponsiveContainer,
} from 'recharts';

export function LineGraph({ data }) {
    let colors = [
        'ED3241',
        '40B2FF',
        '7FD321',
        'FFAB21',
        'CC33CC',
        'FF6666',
        '999999',
        '66CC99',
        'FF9966',
        'FF66CC',
    ];

    data?.datasets?.forEach((elem, index) => {
        elem.borderColor = '#' + colors[index % 10];
        elem.borderWidth = 5;
        elem.fill = false;
        elem.lineTension = 0;
        elem.pointBackgroundColor = '#' + colors[index % 10];
        elem.backgroundColor = '#' + colors[index % 10];
        elem.pointHoverBorderWidth = 11;
    });

    let result = [];

    data?.datasets?.forEach((elem, index) => {
        elem?.data?.map((d, j) => {
            index === 0
                ? result.push({
                      label: data?.labels[j],
                      [elem.label]: d,
                  })
                : (result[j][elem.label] = d);
        });
    });

    return (
        <div className='line-chart'>
            <ResponsiveContainer width='95%' height={200}>
                <LineChart width={550} height={200} data={result}>
                    <XAxis dataKey='label' />
                    <YAxis domain={[1, 10]}/>
                    <Tooltip />
                    <Legend />
                    {data?.datasets?.map((d, i) => {
                        return (
                            <Line
                                connectNulls
                                key={i}
                                type='monotone'
                                dataKey={d.label}
                                stroke={d.backgroundColor}
                                isAnimationActive={false}
                            />
                        );
                    })}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

LineGraph.propTypes = {
    data: PropTypes.object,
    options: PropTypes.object,
};
