import { get } from '../components/shared/lib/axios';

export async function getSurveyCompletionRate(from, to, teamId) {
    return get('/survey-completion-rate', { from, to, teamId });
}

export async function getSurveyQuestionCompletionRate(
    surveyId,
    iteration,
    teamId
) {
    return get(`/surveys/${surveyId}/completion-rate`, {
        iteration,
        teamId,
    });
}

export async function getLastClosedSurveyCompletionRate() {
    return get('/last-survey-completion-rate');
}

export async function getLastActiveSurveyCompletionRate() {
    return get('/active-survey-completion-rate');
}
