import { get, put } from '../components/shared/lib/axios';

var controllerRoute = 'client';

export async function getClient() {
    return get(controllerRoute);
}

export async function getClientNotifications() {
    return get(`${controllerRoute}/notifications`);
}

export async function updateClientNotifications(req) {
    return put(`${controllerRoute}/notifications`, req);
}

export async function updateClient(body) {
    return put(controllerRoute, body);
}

export async function updateClientImage(body) {
    return put(`${controllerRoute}/image`, body);
}
