import moment from 'moment';
import { useEffect, useState } from 'react';
import { getUserPulseIndex } from '../../../services/pulseIndexService';
import { useFormattedMessage } from '../lib/helper';
import { GradientBarCircle } from './components/GradientBarCircle';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function MePulseIndexWidget({ from, to, userId }) {
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            let pI = await getUserPulseIndex(from, to, userId);

            setData(pI);
            setLoading(false);
        }

        if (from && to && userId) {
            fetchData();
        }
    }, [from, to, userId]);

    function renderResponseTime(time) {
        let duration = moment.duration(time.value);

        let result = '-';

        if (duration.isValid()) {
            if (duration.days() >= 1) {
                result = `${Math.floor(duration.days())}d`;
            } else if (duration.hours() >= 1) {
                result = `${Math.floor(duration.hours())}h`;
            } else if (duration.minutes() >= 1) {
                result = `${Math.floor(duration.minutes())}m`;
            } else if (duration.seconds() >= 1) {
                result = `${Math.floor(duration.seconds())}s`;
            }
        }

        return result;
    }

    return (
        <div
            aria-label={useFormattedMessage('mePulseIndexWidget.header.title')}
            className='me-pulse-index-widget'
        >
            <WidgetLayout
                loading={loading}
                information={useFormattedMessage(
                    'mePulseIndexWidget.header.information'
                )}
            >
                <WidgetHeader
                    title={useFormattedMessage(
                        'mePulseIndexWidget.header.title'
                    )}
                    route={''}
                />
                <hr role='none' aria-label='New section.' />
                <div role='section'>
                    <h4 role='none' id='region-heading'>
                        {useFormattedMessage('mePulseIndexWidget.body.title1')}
                    </h4>
                    <p className='upper-title'>
                        <strong>
                            {useFormattedMessage(
                                `mePulseIndexWidget.body.${
                                    !data ||
                                    !data.feedbackGiven ||
                                    data.feedbackGiven.type === 0
                                        ? 'beginner'
                                        : data.feedbackGiven.type === 1
                                        ? 'intermediate'
                                        : 'expert'
                                }`
                            )}
                        </strong>
                        {' - '}
                        {useFormattedMessage('mePulseIndexWidget.body.topOf', {
                            value:
                                data && data.feedbackGiven
                                    ? data.feedbackGiven.top
                                    : '-',
                        })}
                    </p>
                    <GradientBarCircle
                        percentile={
                            data && data.feedbackGiven
                                ? data.feedbackGiven.percentile
                                : null
                        }
                        label={
                            data && data.feedbackGiven
                                ? data.feedbackGiven.value
                                : '-'
                        }
                    />
                    <h5 role='none' className='bottom-title'>
                        {useFormattedMessage('mePulseIndexWidget.body.monthly')}
                    </h5>
                </div>
                <hr role='none' aria-label='New section.' />
                <div role='section'>
                    <h4 role='none'>
                        {useFormattedMessage('mePulseIndexWidget.body.title2')}
                    </h4>
                    <p className='upper-title'>
                        <strong>
                            {useFormattedMessage(
                                `mePulseIndexWidget.body.${
                                    !data ||
                                    !data.feedbackAsked ||
                                    data.feedbackAsked.type === 0
                                        ? 'beginner'
                                        : data.feedbackAsked.type === 1
                                        ? 'intermediate'
                                        : 'expert'
                                }`
                            )}
                        </strong>
                        {' - '}
                        {useFormattedMessage('mePulseIndexWidget.body.topOf', {
                            value:
                                data && data.feedbackAsked
                                    ? data.feedbackAsked.top
                                    : '-',
                        })}
                    </p>
                    <GradientBarCircle
                        percentile={
                            data && data.feedbackAsked
                                ? data.feedbackAsked.percentile
                                : null
                        }
                        label={
                            data && data.feedbackAsked
                                ? data.feedbackAsked.value
                                : '-'
                        }
                    />
                    <h5 role='none' className='bottom-title'>
                        {useFormattedMessage('mePulseIndexWidget.body.monthly')}
                    </h5>
                </div>
                <hr role='none' aria-label='New section.' />
                <div role='section'>
                    <h4 role='none'>
                        {useFormattedMessage('mePulseIndexWidget.body.title3')}
                    </h4>
                    <p className='upper-title'>
                        <strong>
                            {useFormattedMessage(
                                `mePulseIndexWidget.body.${
                                    !data ||
                                    !data.responseTime ||
                                    data.responseTime.type === 0
                                        ? 'beginner'
                                        : data.responseTime.type === 1
                                        ? 'intermediate'
                                        : 'expert'
                                }`
                            )}
                        </strong>
                        {' - '}
                        {useFormattedMessage('mePulseIndexWidget.body.topOf', {
                            value:
                                data && data.responseTime
                                    ? data.responseTime.top
                                    : '-',
                        })}
                    </p>
                    <GradientBarCircle
                        percentile={
                            data?.responseTime
                                ? data.responseTime.percentile
                                : null
                        }
                        label={
                            data?.responseTime
                                ? renderResponseTime(data?.responseTime)
                                : '-'
                        }
                    />
                    <h5 role='none' className='bottom-title'>
                        {useFormattedMessage('mePulseIndexWidget.body.monthly')}
                    </h5>
                </div>
            </WidgetLayout>
        </div>
    );
}

MePulseIndexWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    userId: PropTypes.string,
};
