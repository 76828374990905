import { Fragment } from 'react';
import { Loading } from '../../loadings/Loading';
import { SurveyQuestion } from './SurveyBox';
import { PropTypes } from 'prop-types';
import './components.scss';

export function AssociatedQuestions({ questions, onClick }) {
    return !questions ? (
        <Loading />
    ) : (
        <Fragment>
            {questions?.map((q) => (
                <SurveyQuestion
                    className='clickable'
                    onClick={() =>
                        typeof onClick === 'function' ? onClick(q?.id) : void 0
                    }
                    key={`ass-q-${q?.id}`}
                    question={q}
                    ellipsisTopic
                />
            ))}
        </Fragment>
    );
}

AssociatedQuestions.propTypes = {
    questions: PropTypes.array,
    onClick: PropTypes.func,
};
