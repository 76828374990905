import moment from 'moment';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { humanizeDiffToday } from '../../../../../shared/lib/dateTimeHelper';
import { useAsyncError } from '../../../../../shared/lib/helper';
import { ActionModal } from './Components/ActionModal';
import { BoxItem } from './Components/BoxItem';
import { UserPhoto } from './Components/UserPhoto';
import { PropTypes } from 'prop-types';
import '../HubItems.scss';

export function ActionItem({ item, userId }) {
    const [action, setAction] = useState(null);

    const [modal, setModal] = useState(false);

    const [lastComment, setLastComment] = useState(undefined);

    const userSent = userId === item.feedbackReceiver.id;

    const throwError = useAsyncError();

    const getSharedActions = useCallback(() => {
        async function fetchData() {
            try {
                let act = await getSharedActions(item.id);

                if (act.question.reactions && act.question.reactions.length) {
                    setLastComment(
                        act.question.reactions.sort((x, y) =>
                            moment(y.date).diff(x.date)
                        )[0]
                    );
                }

                setAction(act);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, [action]);

    useEffect(() => {
        getSharedActions();
    }, []);

    return (
        !!action && (
            <Fragment>
                <BoxItem
                    className='hub-item'
                    reviewed={
                        !(
                            (!lastComment && !userSent) ||
                            (lastComment && lastComment.actor.id !== userId)
                        )
                    }
                    header={
                        <Fragment>
                            <UserPhoto
                                giver={item?.feedbackReceiver}
                                receiver={item?.feedbackGiver}
                            />
                            <div className='title-date'>
                                <div>
                                    {`Action ${
                                        userSent ? 'shared' : 'received'
                                    }`}{' '}
                                </div>
                                <div>{humanizeDiffToday(item.created)} </div>
                            </div>
                        </Fragment>
                    }
                    action={
                        <Fragment>
                            <p
                                className={`description ${
                                    lastComment &&
                                    lastComment.actor.id !== userId
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                {!lastComment && userSent
                                    ? 'Awaiting Response'
                                    : ''}
                                {lastComment && lastComment.actor.id === userId
                                    ? 'Response sent'
                                    : lastComment
                                    ? 'Comments received'
                                    : ''}
                            </p>
                            <button
                                className={`btn ${
                                    (!lastComment && !userSent) ||
                                    (lastComment &&
                                        lastComment.actor.id !== userId)
                                        ? 'highlighted'
                                        : ''
                                }`}
                                onClick={() => setModal(true)}
                            >
                                {(!lastComment && !userSent) ||
                                (lastComment && lastComment.actor.id !== userId)
                                    ? 'Respond'
                                    : 'View'}
                            </button>
                        </Fragment>
                    }
                >
                    <div className='label-section'>
                        <div className='group'>
                            <div>
                                <span>{userSent ? 'To' : 'From'}:</span>
                                <span className='bold'>
                                    {userSent
                                        ? item.feedbackGiver?.name
                                        : item.feedbackReceiver?.name}
                                </span>
                            </div>
                        </div>

                        <div className='group-row'>
                            <div>
                                <span>Action topic:</span>
                                <span className='bubble'>{item.topic}</span>
                            </div>
                        </div>
                    </div>
                </BoxItem>

                {action && (
                    <ActionModal
                        sharedAction={action}
                        open={modal}
                        item={item}
                        userId={userId}
                        onClose={() => setModal(false)}
                        onComment={async () => await getSharedActions()}
                    />
                )}
            </Fragment>
        )
    );
}

ActionItem.propTypes = {
    item: PropTypes.object,
    userId: PropTypes.string,
};
