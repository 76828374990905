import { useContext } from 'react';
import { SnackbarContext } from '../../../contexts/SnackbarContext';

export function useSnackbar() {
    const { dispatch } = useContext(SnackbarContext);

    const showSnackbarWithTimer = (msg, timer, success) => {
        dispatch({
            message: msg,
            isDisplayed: true,
            success: success,
        });

        if (timer) {
            setTimeout(() => {
                dispatch({ isDisplayed: false });
            }, timer);
        }
    };

    const showSnackbarWithLoading = (msg) => {
        dispatch({
            message: msg,
            isLoading: true,
            isDisplayed: true,
        });
    };

    const hideSnackar = () => {
        dispatch({
            isDisplayed: false,
        });
    };

    return { showSnackbarWithTimer, showSnackbarWithLoading, hideSnackar };
}
