import { Fragment, useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { getUserTeamLeadTeam } from '../../../../services/teamService';
import { getUser } from '../../../../services/userService';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper';
import { useSession } from '../../../shared/lib/useSession';
import { DateSelector } from '../../../shared/utils/DateSelector';
import { MePulseIndexWidget } from '../../../shared/widgets/MePulseIndexWidget';
import { OrganisationalFeedbackWidget } from '../../../shared/widgets/OrganisationalFeedbackWidget';
import { PeerFeedback } from '../../../shared/widgets/PeerFeedback';
import { PulseSurveysWidget } from '../../../shared/widgets/PulseSurveysWidget';
import { AlternativeUsersWidget } from './components/AlternativeUsersWidget';
import './MTUserOverview.scss';

export function MTUserOverview() {
    const { teamId, userId } = useParams();

    const { state } = useLocation();

    const [dates, setDates] = useState(null);

    const session = useSession();

    const [user, setUser] = useState(null);

    const [redirect, setRedirect] = useState(null);

    const [isTeamLead, setIsTeamLead] = useState(false);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchUser() {
            try {
                let u = await getUser(userId);
                window.scrollTo(0, 0);
                setUser(u);
            } catch (error) {
                throwError(error);
            }
        }

        if (userId && isTeamLead) {
            fetchUser();
        }
    }, [userId, isTeamLead]);

    useEffect(() => {
        async function checkTeam() {
            try {
                let teams = await getUserTeamLeadTeam();

                setIsTeamLead(!!teams.find((x) => x.value === teamId));

                if (!teams.find((x) => x.value === teamId)) {
                    throwError(new Error('forbidden'));
                }
            } catch (error) {
                throwError(error);
            }
        }

        if (teamId) {
            checkTeam();
        }
    }, [teamId]);

    return (
        <Page className='mt-user-overview me-insights'>
            <Fragment>
                <div className='filter-bar'>
                    <DateSelector
                        months={state?.months}
                        onChange={(from, to, months) =>
                            setDates({ from, to, months })
                        }
                    />
                </div>

                {user && (
                    <div className='user-navigation '>
                        <div className='background-box p-shadow'>
                            <img
                                tabIndex={0}
                                aria-label='Press enter to go back to Team overview.'
                                onClick={() =>
                                    setRedirect(
                                        `/my-team/feedback/overview/${teamId}`
                                    )
                                }
                                onKeyPress={(e) =>
                                    e.key === 'Enter' &&
                                    setRedirect(
                                        `/my-team/feedback/overview/${teamId}`
                                    )
                                }
                                alt='User photo'
                                className='user-image'
                                src={user?.image}
                            />
                            <p>{user?.jobTitle ?? 'Team member'}</p>
                        </div>
                        <div
                            className='back-box p-shadow'
                            onClick={() =>
                                setRedirect(
                                    `/my-team/feedback/overview/${teamId}`
                                )
                            }
                        >
                            {redirect && (
                                <Navigate to={redirect} state={state?.months} />
                            )}
                            <i className='icon icon-long-arrow-left'></i>
                            <div></div>
                            <p>
                                {user
                                    ? `${user?.firstName} ${user?.lastName}`
                                    : ''}
                            </p>
                        </div>
                    </div>
                )}

                {isTeamLead && (
                    <div className='widgets'>
                        <MePulseIndexWidget
                            from={dates?.from}
                            to={dates?.to}
                            months={dates?.months}
                            userId={userId}
                        />
                        <PeerFeedback
                            from={dates?.from}
                            to={dates?.to}
                            months={dates?.months}
                            userId={userId}
                        />
                        <PulseSurveysWidget
                            from={dates?.from}
                            to={dates?.to}
                            userId={userId}
                        />

                        {!session?.isAnonymous && (
                            <OrganisationalFeedbackWidget
                                from={dates?.from}
                                to={dates?.to}
                                userId={userId}
                            />
                        )}
                    </div>
                )}

                <AlternativeUsersWidget teamId={teamId} userId={userId} />
            </Fragment>
        </Page>
    );
}
