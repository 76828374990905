import * as _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { LineGraph } from '../../../../shared/charts/LineGraph';
import { MainContent } from '../../../../shared/layout/MainContent';
import { Page } from '../../../../shared/layout/Page';
import { useAsyncError } from '../../../../shared/lib/helper';
import { Loading } from '../../../../shared/loadings/Loading';
import { PPRTeamFilter } from './components/PPRTeamFilter';
import './PPRHistoric.scss';

export function PPRHistoric() {

    const { state } = useLocation();

    const [loading, setLoading] = useState(true);

    const [selectedTeams, setSelectedTeams] = useState([]);

    const [parentPPRTopic, setParentPPRTopic] = useState(null);

    const [subTopics, setSubTopics] = useState(null);

    const [startDate, setStartDate] = useState(state?.StartDate);

    const [endDate, setEndDate] = useState(state?.EndDate);

    const [topicData, setTopicData] = useState(null);

    const [firstTime, setFirstTime] = useState(true);

    const throwError = useAsyncError();

    useEffect(() => {
        if (state) {
            setParentPPRTopic(state.PPRTopic);
            getSubTopics([state.PPRTopic.id]).then((sub) => {
                setSubTopics(sub);

                Promise.all(
                    sub.map((s) => ({
                        label: s.label,
                        data: HistoricPPR({
                            PPRTopicId: s.value,
                            StartDate: state.StartDate,
                            EndDate: state.EndDate
                        })
                    }))
                ).then((result) => {
                    Promise.all(
                        result.map((topic) => {
                            return topic.data.then((d) => {
                                return {
                                    label: topic.label,
                                    data: formatData(d)
                                };
                            });
                        })
                    ).then((c) => {
                        setTopicData(c);

                    });
                });
            });
        }
        setLoading(false);
    }, [state]);

    useEffect(() => {
        if (subTopics && selectedTeams.length > 0) {
            Promise.all(
                subTopics.map((s) => ({
                    label: s.label,
                    teamData: selectedTeams.map((team) => ({
                        data:
                            team.value === 'Company'
                                ? HistoricPPR({
                                      PPRTopicId: s.value,
                                      StartDate: startDate,
                                      EndDate: endDate
                                  })
                                : HistoricPPRTeam(
                                      {
                                          PPRTopicId: s.value,
                                          StartDate: startDate,
                                          EndDate: endDate
                                      },
                                      team.value
                                  )
                    }))
                }))
            )
                .then((result) => {
                    return Promise.all(
                        result.map((topic) => {
                            return Promise.all(
                                topic.teamData.map((a) => {
                                    return a.data.then((d) => {
                                        return { label: topic.label, data: d };
                                    });
                                })
                            );
                        })
                    );
                })
                .then((res) => {
                    var logs = res.flatMap((item) => item);
                    handleDatesChange(
                        _(logs)
                            .groupBy((x) => x.label)
                            .flatMap((value, key) => ({
                                label: key,
                                data: value.map((x) => x.data)
                            }))
                            .value()
                    );
                });
        }
        setLoading(false);
    }, [startDate, endDate]);

    function getSubTopics(topics) {
        return fetch('/api/PPRTopic/getParentChildrenForRT', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(topics)
        }).then((res) => {
            if (res.status >= 400) {
                throwError(new Error('error'));
            }
            return res.json();
        });
    }

    function HistoricPPR(search) {
        return fetch('/api/ppr/historic', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(search)
        }).then((res) => {
            if (res.status >= 400) {
                throwError(new Error());
            }
            return res.json();
        });
    }

    function HistoricPPRTeam(search, teamId) {
        return fetch(`/api/ppr/teams/${teamId}/historic`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(search)
        }).then((res) => {
            if (res.status >= 400) {
                throwError(new Error());
            }
            return res.json();
        });
    }

    function handleTeams(teams) {
        if (firstTime) {
            setFirstTime(false);
        } else {
            let newTeam = teams.filter(
                (x) => !selectedTeams.some((y) => y.value === x.value)
            );

            if (newTeam.length > 0) {
                Promise.all(
                    subTopics.map((s) => ({
                        label: s.label,
                        data:
                            newTeam[0].value === 'Company'
                                ? HistoricPPR({
                                      PPRTopicId: s.value,
                                      StartDate: startDate,
                                      EndDate: endDate
                                  })
                                : HistoricPPRTeam(
                                      {
                                          PPRTopicId: s.value,
                                          StartDate: startDate,
                                          EndDate: endDate
                                      },
                                      newTeam[0].value
                                  )
                    }))
                ).then((result) => {
                    Promise.all(
                        result.map((topic) => {
                            return topic.data.then((d) => {
                                return {
                                    label: topic.label,
                                    data: formatData(d)
                                };
                            });
                        })
                    ).then((c) => {
                        let tData = [...topicData];

                        c.forEach((to) => {
                            tData
                                .find((x) => x.label === to.label)
                                .data.datasets.push(to.data.datasets[0]);
                        });

                        setTopicData(tData);
                        setLoading(false);
                    });
                });
            } else {
                let oldTeam = selectedTeams.filter(
                    (x) => !teams.some((y) => y.value === x.value)
                );

                let tData = [...topicData];

                tData.forEach((x) => {
                    x.data.datasets.splice(
                        x.data.datasets
                            .map((x) => x.label)
                            .indexOf(oldTeam[0].label),
                        1
                    );
                });

                setTopicData(tData);
            }
        }
        setSelectedTeams(teams);
    }

    function handleDates(from, to) {
        setStartDate(from);
        setEndDate(to);
    }

    function handleDatesChange(result) {
        setTopicData(formatMultipleData(result));
    }

    function formatMultipleData(topics) {
        var array = [];

        topics.forEach((topic) => {
            var topicData = {
                label: topic.label,
                data: {
                    labels: topic.data[0].ranges,
                    datasets: []
                }
            };

            topic.data.forEach((e) => {
                topicData.data.datasets.push({
                    label: e.historic.entity,
                    data: e.historic.value
                });
            });

            array.push(topicData);
        });

        return array;
    }

    function formatData(data) {
        var resultData = {
            labels: data.ranges,
            datasets: []
        };

        resultData.datasets.push({
            label: data.historic.entity,
            data: data.historic.value
        });

        return resultData;
    }

    return loading ? (
        <Loading />
    ) : (
        <Fragment>
            <Page title={parentPPRTopic?.title} className='ppr-topic-search'>
                <Fragment>
                    <MainContent title='Filter' line={true}>
                        <PPRTeamFilter
                            filter={state}
                            handleTeams={handleTeams}
                            handleDates={handleDates}
                        />
                    </MainContent>

                    <div className='ppr-historic-graph-container'>
                        {topicData?.map((topic) => (
                            <MainContent
                                key={`main-content-${topic?.label}`}
                                title={topic?.label}
                                line={true}>
                                <LineGraph
                                    key={`line-graph-${topic?.label}`}
                                    data={topic?.data}
                                    options={{
                                        max: 10,
                                        min: 0,
                                        stepSize: 1
                                    }}
                                />
                            </MainContent>
                        ))}
                    </div>
                </Fragment>
            </Page>
        </Fragment>
    );
}
