import { Fragment, useState } from 'react';
import { Modal } from '../components/Modal';
import { IconButton } from './IconButton';
import { PropTypes } from 'prop-types';
import './inputs.scss';

export function IconConfirmationModal({
    type,
    confirmMessage,
    onConfirm,
    className,
}) {
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <IconButton
                type={type}
                className={className ? className : ''}
                onClick={() => setOpen(true)}
            />
            <Modal
                className={`generic-modal ${className ?? ''}`}
                open={open}
                onHide={() => {
                    setOpen(false);
                }}
            >
                <p className='text-center'>{confirmMessage}</p>

                <div className='text-center'>
                    <button
                        className='btn highlighted modalBtn'
                        onClick={() => {
                            onConfirm();
                            setOpen(false);
                        }}
                    >
                        Yes
                    </button>
                    <button
                        className='btn'
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        No
                    </button>
                </div>
            </Modal>
        </Fragment>
    );
}

IconConfirmationModal.propTypes = {
    type: PropTypes.string,
    confirmMessage: PropTypes.object,
    onConfirm: PropTypes.func,
    className: PropTypes.string,
};
