import {
    PulseChart,
    ScoreChart,
    SentimentChart,
    TotalChart,
} from '../../../../shared/charts/RadialChartIcon';
import { useRagIntervals } from '../../../../shared/lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import constants from '../../../../shared/constants';

export const IconChartTypes = {
    SCORE: 'score',
    TOTAL: 'total',
    SENTIMENT: 'sentiment',
    PULSE: 'pulse',
};

export function WGroupIconChart({ type, value, label, className, showText }) {
    const { getColor } = useRagIntervals();
    return (
        <div className={`chart-content ${className ?? ''}`} role='section'>
            {type === IconChartTypes.SENTIMENT && (
                <SentimentChart sentiment={value} showText={showText} />
            )}
            {type === IconChartTypes.SCORE && (
                <ScoreChart score={value} showText={showText} />
            )}
            {type === IconChartTypes.TOTAL && (
                <TotalChart total={value} showText={showText} />
            )}
            {type === IconChartTypes.PULSE && (
                <PulseChart rate={value} showText={showText} />
            )}

            <div className='w-c-group'>
                {!showText && (
                    <h3
                        role='none'
                        style={
                            type !== 'total'
                                ? {
                                      color: getColor(
                                          value,
                                          type === 'score'
                                              ? constants.QuestionType.Score
                                              : null
                                      ),
                                  }
                                : {}
                        }
                    >
                        {typeof value === 'number' && !isNaN(value)
                            ? `${Number(value.toFixed(0))}${
                                  type === 'sentiment' || type === 'pulse'
                                      ? '%'
                                      : ''
                              }`
                            : type === 'total'
                            ? '0'
                            : '-'}
                    </h3>
                )}

                <label>{label}</label>
            </div>
        </div>
    );
}

WGroupIconChart.propTypes = {
    type: PropTypes.string,
    value: PropTypes.number,
    label: PropTypes.string,
    className: PropTypes.string,
    showText: PropTypes.bool,
};
