import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { AskForm } from './AskForm';
import { Tabs } from '../../../../shared/components/Tabs';
import './AskGive.scss';

export function AskGive() {
    const [activeTab, setActiveTab] = useState(null);

    const TabType = {
        EMPLOYEE: 'employee',
        GIVE: 'give',
        ASK: 'ask',
    };

    const [hide, setHide] = useState(true);

    const [, setOpenTimer] = useState(0);

    const tabs = [
        {
            label: (
                <Fragment>
                    <i className='icon icon-feedback-ask'></i>
                    <h5>Ask</h5>
                </Fragment>
            ),
            value: TabType.ASK,
            ariaLabel: 'Ask feedback',
        },
        {
            label: (
                <Fragment>
                    <i className='icon icon-feedback-give'></i>
                    <h5>Give</h5>
                </Fragment>
            ),
            value: TabType.GIVE,
            ariaLabel: 'Give feedback',
        },
        {
            label: (
                <Fragment>
                    <i className='icon icon-megaphone'></i>
                    <h5>Employee Voice</h5>
                </Fragment>
            ),
            value: TabType.EMPLOYEE,
            ariaLabel: 'Employee voice',
        },
    ];

    useEffect(() => {
        if (activeTab === null) {
            setOpenTimer(setTimeout(() => setHide(activeTab === null), 500));
        }
    }, [activeTab]);

    return (
        <Fragment>
            <div className='ask-give-component'>
                {!activeTab && <h4 className='ask-give-title'>Feedback</h4>}
                <Tabs
                    className='ask-give-tabs'
                    options={tabs}
                    onChange={(active) => {
                        setActiveTab(active);
                        setHide(!active);
                    }}
                />

                {!hide && <AskForm activeTab={activeTab} />}
            </div>
        </Fragment>
    );
}

export function AnswerTypeSelector({ onChange }) {
    const types = [
        { value: 0, icon: 'icon-feedback-total', text: 'Suggestion' },
        { value: 1, icon: 'icon-feedback-score', text: 'Score' },
        { value: 2, icon: 'icon-yn', text: 'Yes or No' },
    ];

    return (
        <div className='answer-type-selector'>
            {types.map((type) => (
                <AnswerType
                    key={`answer-type-${type.icon}`}
                    option={type}
                    onClick={onChange}
                />
            ))}
        </div>
    );
}

function AnswerType({ option, onClick }) {
    return (
        <a
            role='button'
            tabIndex={0}
            className='answer-type-item'
            title={option.text}
            onClick={() => onClick(option.value)}
            onKeyPress={(ev) => ev.key === 'Enter' && onClick(option.value)}
        >
            <i className={option.icon}></i>
        </a>
    );
}

AnswerTypeSelector.propTypes = {
    onChange: PropTypes.func,
};

AnswerType.propTypes = {
    option: PropTypes.object,
    onClick: PropTypes.func,
};
