import { PropTypes } from 'prop-types';
import './Loading.scss';

export function Loading({ className, style }) {
    return (
        <div
            className={`loading-container ${className ?? ''}`}
            style={style}
            data-testid='loading'
        >
            <div className='new-pulse-loading'>
                <div className='new-pulse-loading-dot'></div>
                <div className='new-pulse-loading-dot'></div>
                <div className='new-pulse-loading-dot'></div>
                <div className='new-pulse-loading-dot'></div>
                <div className='new-pulse-loading-dot'></div>
                <div className='new-pulse-loading-dot'></div>
            </div>
        </div>
    );
}

Loading.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
