import PropTypes from 'prop-types';
import './AssessTopicBox.scss';

export function AssessTopicBox(props) {
    return (
        <div className='assess-topic-container'>
            <h3 className='overflow-hidden'>{props.topic.title}</h3>
            <button
                className='btn highlighted'
                onClick={() => props.scoresClick(props.topic)}
            >
                Scores <i />
            </button>
            <button
                className='btn highlighted'
                onClick={() => props.actionClick(props.topic)}
            >
                Actions <i />
            </button>
        </div>
    );
}

AssessTopicBox.propTypes = {
    topic: PropTypes.object,
    scoresClick: PropTypes.func,
    actionClick: PropTypes.func,
};
