import * as _ from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
    createAction,
    getPriorityTypes,
    updateAction,
} from '../../../../../../../services/actionService';
import {
    getSharedPPR,
    commentPPR,
} from '../../../../../../../services/pprService';
import { Modal } from '../../../../../../shared/components/Modal';
import { ProgressBar } from '../../../../../../shared/components/ProgressBar';
import { StepBar } from '../../../../../../shared/components/StepBar';
import constants from '../../../../../../shared/constants';
import { PulseSelect } from '../../../../../../shared/inputs/PulseSelect';
import { Textarea } from '../../../../../../shared/inputs/Textarea';
import { humanizeDiffToday } from '../../../../../../shared/lib/dateTimeHelper';
import { useAsyncError } from '../../../../../../shared/lib/helper';
import { Comments } from './Comments';
import { UserPhoto } from './UserPhoto';
import { PropTypes } from 'prop-types';
import './HubItemModal.scss';

export function AssessmentModal({ onClose, item, userId, open }) {
    const [sharedPPR, setSharedPPR] = useState(null);

    const [modal, setModal] = useState(false);

    const [current, setCurrent] = useState(undefined);

    const [edit, setEdit] = useState(false);

    const [action, setAction] = useState(undefined);

    const [priorities, setPriorities] = useState(undefined);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchData() {
            try {
                let [ppr, pr] = await Promise.all([
                    getSharedPPR(item.id),
                    getPriorityTypes(),
                ]);

                setCurrent(ppr.questions[0]);

                setPriorities(pr);
                setSharedPPR(ppr);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (open) {
            setModal(true);
        }
    }, [open]);

    useEffect(() => {
        if (!modal) {
            onClose();
        }
    }, [modal]);

    useEffect(() => {
        if (current && current.action) {
            setAction({ ...current.action });
        } else if (current) {
            setAction(undefined);
        }
    }, [current]);

    useEffect(() => {
        async function saveAction(action) {
            try {
                let request = {
                    actionId: action.id,
                    description: action.description,
                    priority: action.priority,
                    completionDate: action.completeDate,
                };

                if (action.isNew) {
                    request.PPRId = sharedPPR.pprId;
                    request.pprTopicId = current.topicId;
                }

                await (action.isNew
                    ? createAction(request)
                    : updateAction(request));
                setSharedPPR(await getSharedPPR(item.id));
            } catch (e) {
                throwError(e);
            }
        }

        if (edit && !action) {
            setAction({
                isNew: true,
                priority: 0,
            });
        } else if (!edit && action && action.description) {
            saveAction(action);
        } else if (!edit && action && !action.description) {
            setAction(undefined);
        }
    }, [edit]);

    async function onComment(comment) {
        try {
            let request = {
                sharedWith: sharedPPR.destinationUser.id,
                index: current.index,
                itemId: sharedPPR.pprId,
                comment: comment,
            };

            await commentPPR(request);
            let ppr = await getSharedPPR(item.id);
            let actual = ppr.questions.find((x) => x.index === current.index);
            setCurrent({ ...actual });
            setSharedPPR(ppr);
        } catch (e) {
            throwError(e);
        }
    }

    function handleGoTo(index) {
        if (edit) {
            setEdit(false);
        }

        setCurrent({ ...sharedPPR.questions[index] });
    }

    const userSent = userId === item.feedbackReceiver.id;

    return !sharedPPR ? (
        <Fragment> </Fragment>
    ) : (
        <Modal
            className='hub-item-modal'
            open={modal}
            onClose={() => setModal(false)}
        >
            <i
                className='icon icon-close-solid'
                onClick={() => setModal(false)}
            ></i>
            <div className='header'>
                <UserPhoto
                    giver={item.feedbackReceiver}
                    receiver={item.feedbackGiver}
                />
                <div className='label-section'>
                    <div className='group-row'>
                        <div>
                            <span>{userSent ? 'To' : 'From'}:</span>
                            <span className='bold'>
                                {userSent
                                    ? item.feedbackGiver?.name
                                    : item.feedbackReceiver?.name}
                            </span>
                        </div>
                        <div>
                            {`Assessment ${userSent ? 'shared' : 'received'}`}
                        </div>
                    </div>
                    <div className='group-row'>
                        <div>
                            <span>Assessment:</span>
                            <span className='bubble'>{item.topic}</span>
                        </div>

                        <span>{humanizeDiffToday(item.created)}</span>
                    </div>

                    <div className='group-row'>
                        <div>
                            <span>Completed:</span>
                            <span>
                                {humanizeDiffToday(sharedPPR.completedDate)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            {sharedPPR.questions && sharedPPR.questions.length > 1 && (
                <div className='navigation'>
                    <StepBar
                        current={current.index}
                        steps={sharedPPR.questions.map((x) => x.title)}
                        notifications={sharedPPR.questions
                            .filter(
                                (x) =>
                                    _.last(x.reactions) &&
                                    _.last(x.reactions).actor.id !== userId
                            )
                            .map((x) => x.index)}
                        next={current.index !== sharedPPR.questions.length - 1}
                        previous={current.index !== 0}
                        onNext={() => handleGoTo(current.index + 1)}
                        onPrevious={() => handleGoTo(current.index - 1)}
                        onCirclePress={(index) => handleGoTo(index)}
                    />
                </div>
            )}

            <div className='shared-ppr-section'>
                <h3> {current.title}</h3>
                <p className='margin-bottom-1'>{`"${current.description}"`}</p>

                <p className='bold margin-bottom-1'>Score </p>
                <ProgressBar
                    value={current.value}
                    target={current.targetValue}
                />
            </div>

            <hr />
            <div className='action-edit space-between'>
                <p className='bold'>Action </p>
                {userSent && (
                    <i
                        className={`icon icon-${action ? 'edit' : 'add'} ${
                            edit && action && !action.isCompleted
                                ? 'active'
                                : ''
                        } ${action && action.isCompleted ? ' disabled' : ''}`}
                        onClick={() =>
                            action && action.isCompleted
                                ? void 0
                                : setEdit(!edit)
                        }
                    ></i>
                )}
            </div>
            <hr />
            {action && (
                <Fragment>
                    <div className='action-form'>
                        {!edit ? (
                            <p style={{ marginBottom: '2.5rem' }}>
                                {action.description}
                            </p>
                        ) : (
                            <Textarea
                                maxLength={constants.maxLength}
                                rows={1}
                                placeholder='Action description'
                                onChange={(event) =>
                                    setAction({
                                        ...action,
                                        description: event.target.value,
                                    })
                                }
                                value={action.description}
                            />
                        )}

                        <div className='action-boxes space-between'>
                            <div>
                                <p className='bold margin-bottom-1'>Status </p>

                                <div className='box'>
                                    {!action.isCompleted ? 'Open' : 'Closed'}
                                </div>
                            </div>

                            <div>
                                <p className='bold margin-bottom-1'>
                                    Completion
                                </p>
                                {!edit ? (
                                    <div className='box'>
                                        {action.completeDate ? (
                                            moment(action.completeDate).format(
                                                'DD/MM/YY'
                                            )
                                        ) : (
                                            <i className='icon icon-calendar'></i>
                                        )}
                                    </div>
                                ) : (
                                    <DatePicker
                                        customInput={
                                            <div>
                                                <div className='calendar-action-button'>
                                                    {action.completeDate ? (
                                                        moment(
                                                            action.completeDate
                                                        ).format('DD/MM/YY')
                                                    ) : (
                                                        <i className='icon icon-calendar'></i>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                        selected={
                                            action && action.completeDate
                                                ? moment(
                                                      action.completeDate
                                                  ).toDate()
                                                : undefined
                                        }
                                        onChange={(date) => {
                                            setAction({
                                                ...action,
                                                completeDate: moment(date),
                                            });
                                        }}
                                        minDate={moment().toDate()}
                                    />
                                )}
                            </div>

                            <div>
                                <p className='bold margin-bottom-1'>Priority</p>

                                {!edit ? (
                                    <div className='box'>
                                        {!!priorities &&
                                            priorities.find(
                                                (x) =>
                                                    x.value === action.priority
                                            ).key}
                                    </div>
                                ) : (
                                    <PulseSelect
                                        options={priorities.map((x) => ({
                                            label: x.key,
                                            value: x.value,
                                        }))}
                                        defaultValue={priorities
                                            .map((x) => ({
                                                label: x.key,
                                                value: x.value,
                                            }))
                                            .find(
                                                (x) =>
                                                    x.value === action.priority
                                            )}
                                        onChange={(option) =>
                                            setAction({
                                                ...action,
                                                priority: option.value,
                                            })
                                        }
                                        isClearable={false}
                                        isSearchable={false}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <hr />
                </Fragment>
            )}

            {((!!current.reactions && !!current.reactions.length) ||
                !userSent) && (
                <Comments
                    reactions={current.reactions}
                    user={sharedPPR.user}
                    onComment={onComment}
                    isAssess
                />
            )}
        </Modal>
    );
}

AssessmentModal.propTypes = {
    onClose: PropTypes.func,
    item: PropTypes.object,
    userId: PropTypes.string,
    open: PropTypes.bool,
};
