import { useReducer } from 'react';
import { PropTypes } from 'prop-types';
import { createContext } from 'react';

const initialState = {
    isDisplayed: false,
    message: '',
    isLoading: false,
    success: null,
};
export const SnackbarContext = createContext(initialState);

export const SnackbarProvider = ({ children }) => {
    const { Provider } = SnackbarContext;

    const [state, dispatch] = useReducer((_, action) => {
        return action;
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

SnackbarProvider.propTypes = {
    children: PropTypes.object,
};
