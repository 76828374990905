import moment from 'moment';
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { AppContext } from '../../../../../contexts/AppContext';
import { react } from '../../../../../services/feedbackService';
import {
    getMeItems,
    getMeItemFilter,
    getMeItemTypes,
} from '../../../../../services/meItemService';
import {
    readNotification,
    getUserNotifications,
} from '../../../../../services/notificationService';
import { getRatings } from '../../../../../services/ratingsService';
import { Tabs } from '../../../../shared/components/Tabs';
import { useAsyncError } from '../../../../shared/lib/helper';
import { usePageVisibility } from '../../../../shared/lib/usePageVisibility';
import { useSession } from '../../../../shared/lib/useSession';
import { useUser } from '../../../../shared/lib/useUser';
import { useWindowEvent } from '../../../../shared/lib/useWindowEvent';
import { GradientLoading } from '../../../../shared/loadings/GradientLoading';
import { ActionItem } from './Items/ActionItem';
import { AssessmentItem } from './Items/AssessmentItem';
import { FeedbackItem } from './Items/FeedbackItem';
import { FeedbackRequestItem } from './Items/FeedbackRequestItem';
import { GFeedbackItem } from './Items/GFeedbackItem';
import { PulseItem } from './Items/PulseItem';
import { PropTypes } from 'prop-types';
import './HubItems.scss';

export function HubItems({ deepLink, getItem }) {
    const user = useUser();

    const session = useSession();

    const [meItems, setMeItems] = useState([]);
    const [ratings, setRatings] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [filters, setFilters] = useState(null);
    const [fetch, setFetch] = useState(true);
    const [notifications, setNotifications] = useState(false);
    const colors = ['#BD10E0', '#50E3C2', '#F5A623', '#F8E71C'];

    const [refreshTime, setRefreshTime] = useState(moment());

    const bottomBoundaryRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [meItemTypes, setMeItemTypes] = useState(null);

    const throwError = useAsyncError();

    const { state: appState } = useContext(AppContext);

    const isVisible = usePageVisibility();

    function isScrolledIntoView(elem) {
        var windowHeight = window.innerHeight * 0.8;

        var bounding = elem.getBoundingClientRect();

        var elemBottom = bounding.top + elem.offsetHeight;

        return elemBottom <= windowHeight;
    }

    async function readNotificationAsync(notId) {
        try {
            await readNotification(notId);

            let notificationIndex = notifications.indexOf(
                (n) => n.id === notId
            );
            let newNotifications = [...notifications];

            newNotifications.splice(notificationIndex, 1);

            setNotifications(newNotifications);
        } catch (e) {
            throwError(e);
        }
    }

    async function getMeItemsAsync() {
        if (!fetching) {
            await setFetching(true);

            if (deepLink) {
                let item = await getItem();

                setMeItems([item]);
                setHasMoreItems(false);
            } else {
                let lastDate =
                    meItems?.length === 0
                        ? moment.utc().toISOString()
                        : meItems[meItems.length - 1]?.modified;

                let numberOfItems = 6;

                if (typeof selectedFilter === 'number') {
                    let newItems = await getMeItems(
                        numberOfItems,
                        lastDate,
                        selectedFilter
                    );

                    newItems.forEach((item, index, items) => {
                        if (notifications?.some) {
                            items[index].read = !notifications?.some(
                                (n) => n.itemId === items[index].id
                            );
                        }
                    });

                    setMeItems([...meItems, ...newItems]);
                    setHasMoreItems(newItems?.length !== 0);
                }
            }

            await setFetching(false);
        }
    }

    const reset = useCallback(() => {
        if (typeof selectedFilter === 'number' && !loading && !fetching) {
            setMeItems([]);
            setHasMoreItems(true);
            setFetch(true);
        }
    }, [selectedFilter, loading, fetching]);

    useEffect(() => {
        reset();
    }, [selectedFilter, loading, appState?.refresh]);

    useEffect(() => {
        if (isVisible && moment().diff(refreshTime, 'minutes') > 5) {
            reset();
            setRefreshTime(moment());
        }
    }, [isVisible]);

    useEffect(() => {
        async function fetchMeItems() {
            await getMeItemsAsync();
        }

        if (fetch && hasMoreItems) {
            setFetch(false);
            fetchMeItems();
        }
    }, [fetch, hasMoreItems]);

    async function onComment(feedback, actorId, comment) {
        try {
            await react({
                ItemId: feedback.id,
                Comment: comment,
            });
        } catch (e) {
            throwError(e);
        }
    }

    function submitFeedbackComment(feedback, actorId, comment) {
        onComment(feedback, actorId, comment);

        let itemIndex = meItems.findIndex(
            (element) => element.id === feedback.id
        );
        let newItems = [...meItems];

        var newReaction = {
            commentText: comment,
            userId: newItems[itemIndex].userIsFeedbackGiver
                ? feedback.feedbackGiver.id
                : feedback.feedbackReceiver.id,
            actor: newItems[itemIndex].userIsFeedbackGiver
                ? feedback.feedbackGiver
                : feedback.feedbackReceiver,
            emoji:
                feedback.reactions && feedback.reactions.length > 0
                    ? feedback.reactions[0].emoji
                    : null,
            date: new Date().toISOString(),
        };
        if (
            newItems[itemIndex].reactions &&
            newItems[itemIndex].reactions[0] &&
            newItems[itemIndex].reactions[0].emoji
        ) {
            newItems[itemIndex].reactions = [newReaction];
        } else if (
            newItems[itemIndex].reactions &&
            newItems[itemIndex].reactions.length > 0
        ) {
            newItems[itemIndex].reactions = [
                ...newItems[itemIndex].reactions,
                newReaction,
            ];
        } else {
            newItems[itemIndex].reactions = [newReaction];
        }

        newItems[itemIndex].hasBeenCommented = true;
        newItems[itemIndex].hasBeenReacted = true;

        setMeItems(newItems);
    }

    function onFeedbackAnswered(feedback) {
        let itemIndex = meItems.findIndex(
            (element) => element.id == feedback.id
        );
        let newItems = [...meItems];

        newItems[itemIndex].score =
            typeof feedback.givenFeedback.value === 'number'
                ? feedback.givenFeedback.value
                : null;

        newItems[itemIndex].hasScore =
            typeof feedback.givenFeedback.value === 'number';

        newItems[itemIndex].yesNo =
            typeof feedback.givenFeedback.value === 'boolean'
                ? feedback.givenFeedback.value
                : null;
        newItems[itemIndex].text = feedback.givenFeedback?.feedback;
        newItems[itemIndex].answered = true;

        newItems[itemIndex].answerDate = new Date().toISOString();

        setMeItems(newItems);
    }

    async function rateFeedback(feedback, rating) {
        let request = {
            ItemId: feedback.id,
            Emoji: rating.id,
        };

        await react(request);

        let itemIndex = meItems.findIndex(
            (element) => element.id == feedback.id
        );
        let newItems = [...meItems];

        var newReaction = {
            commentText:
                feedback.reactions != null &&
                feedback.reactions.length > 0 &&
                feedback.reactions[0]
                    ? feedback.reactions[0].commentText
                    : null,
            userId: feedback.feedbackReceiver.id,
            emoji: {
                description: rating.description,
                value: rating.value,
                id: rating.id,
            },
            Date: new Date().toISOString(),
        };

        newItems[itemIndex].reactions = [newReaction];

        newItems[itemIndex].hasBeenRated = true;
        newItems[itemIndex].hasBeenReacted = true;

        setMeItems(newItems);
    }

    function handleTwoClasses(items) {
        for (var i = 0; i < items.length; i++) {
            let item = items[i];
            let index = item.getAttribute('index');

            if (index == null) continue;
            let meItem = meItems[index];

            if (isScrolledIntoView(item) && meItem != null && !meItem.read) {
                let newItems = [...meItems];
                newItems[index].read = true;

                setMeItems(newItems);
                let notificationId = notifications?.find(
                    (x) => x?.itemId === meItem?.id
                )?.id;

                if (notificationId) {
                    readNotificationAsync(notificationId);
                }
            }
        }
    }

    function handleScroll() {
        handleTwoClasses(
            window.document.getElementsByClassName('me-items-box')
        );

        handleTwoClasses(window.document.getElementsByClassName('box-item'));
    }

    useEffect(() => {
        async function fetch() {
            try {
                let [n, rat, f, t] = await Promise.all([
                    getUserNotifications(),
                    getRatings(),
                    getMeItemFilter(),
                    getMeItemTypes(),
                ]);

                setNotifications(n);
                setRatings(rat);

                if (!session?.isPerformance) {
                    f = f.filter((x) => x.value !== 4);
                }
                setFilters(f);
                setMeItemTypes(t);
                setSelectedFilter(f[0]?.value);
                setLoading(false);
            } catch (e) {
                throwError(e);
            }
        }

        fetch();
    }, []);

    useWindowEvent('scroll', handleScroll);

    const scrollObserver = useCallback(
        (node) => {
            new IntersectionObserver((entries) => {
                entries.forEach((en) => {
                    if (
                        en.intersectionRatio > 0 &&
                        hasMoreItems &&
                        !loading &&
                        !fetching
                    ) {
                        setFetch(true);
                    }
                });
            }).observe(node);
        },
        [meItems?.length, hasMoreItems, loading, fetching]
    );
    useEffect(() => {
        if (bottomBoundaryRef?.current) {
            scrollObserver(bottomBoundaryRef.current);
        }
    }, [scrollObserver, bottomBoundaryRef]);

    return (
        <div className='me-items-container'>
            <Fragment>
                {!deepLink && (
                    <div className='filter-items'>
                        <Tabs
                            className={`navy ${fetching ? 'fetching' : ''}`}
                            options={filters
                                ?.filter((x) => x.value !== 0)
                                ?.map((x) => ({
                                    ...x,
                                    label:
                                        x.key === 'Assess'
                                            ? 'Assessment & Actions'
                                            : x.key,
                                    ariaLabel: x.key + ' filter',
                                }))}
                            onChange={(active) =>
                                setSelectedFilter(!active ? 0 : active)
                            }
                        />
                    </div>
                )}

                <div>
                    {meItems.map((item, index) => {
                        switch (item.type) {
                            case meItemTypes?.feedbackGiven:
                            case meItemTypes?.feedbackReceived:
                                return (
                                    <FeedbackItem
                                        read={
                                            !notifications.filter(
                                                (x) => item?.id === x.itemId
                                            ).length
                                        }
                                        key={index}
                                        item={item}
                                        submitComment={submitFeedbackComment}
                                        anonymity={
                                            item?.feedbackGiver?.isAnonymous ||
                                            item?.feedbackReceiver?.isAnonymous
                                        }
                                        color={colors[index % 4]}
                                        submitRate={rateFeedback}
                                        ratings={ratings}
                                    />
                                );

                            case meItemTypes?.pulse:
                                return (
                                    <PulseItem
                                        read={
                                            !notifications.filter(
                                                (x) => item?.id === x.itemId
                                            ).length
                                        }
                                        key={index}
                                        userId={user?.id}
                                        item={item}
                                    />
                                );

                            case meItemTypes?.receivedRequest:
                            case meItemTypes?.givenRequest:
                                return (
                                    <FeedbackRequestItem
                                        read={
                                            !notifications.filter(
                                                (x) => item?.id === x.itemId
                                            ).length
                                        }
                                        key={index}
                                        item={item}
                                        color={colors[index % 4]}
                                        onFeedbackAnswered={onFeedbackAnswered}
                                        submitComment={submitFeedbackComment}
                                        submitRate={rateFeedback}
                                        ratings={ratings}
                                    />
                                );

                            case meItemTypes?.guestFeedback:
                                return (
                                    <GFeedbackItem
                                        read={
                                            !notifications.filter(
                                                (x) => item?.id === x.itemId
                                            ).length
                                        }
                                        key={index}
                                        item={item}
                                        onFeedbackAnswered={onFeedbackAnswered}
                                        submitComment={submitFeedbackComment}
                                        submitRate={rateFeedback}
                                        ratings={ratings}
                                    />
                                );

                            case meItemTypes?.sharedAssesment:
                                return (
                                    <AssessmentItem
                                        read={
                                            !notifications.filter(
                                                (x) => item?.id === x.itemId
                                            ).length
                                        }
                                        key={index}
                                        item={item}
                                        userId={user?.id}
                                    />
                                );

                            case meItemTypes?.sharedAction:
                                return (
                                    <ActionItem
                                        read={
                                            !notifications.filter(
                                                (x) => item?.id === x.itemId
                                            ).length
                                        }
                                        key={index}
                                        item={item}
                                        userId={user?.id}
                                    />
                                );
                            default:
                                return <div key={index}></div>;
                        }
                    })}

                    {(hasMoreItems || fetching) && (
                        <div ref={bottomBoundaryRef} className='loading'>
                            <GradientLoading className='loading-container'>
                                <div className='element1'></div>
                                <div className='element2'></div>
                                <div className='element3'></div>
                                <div className='element4'></div>
                            </GradientLoading>
                        </div>
                    )}
                </div>
            </Fragment>
        </div>
    );
}

HubItems.propTypes = {
    deepLink: PropTypes.object,
    getItem: PropTypes.func,
};
