import moment from 'moment';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    SURVEY_STATUS_TYPE,
    closeSurvey,
    deleteSurvey,
} from '../../../../services/surveyService';
import { getUser } from '../../../../services/userService';
import {
    SurveyActionTypes,
    SurveyContext,
} from '../../../pages/admin/surveys/SurveyContext';
import { PulseChart } from '../../charts/RadialChartIcon';
import { MenuSelector } from '../../components/MenuSelector';
import { humanizeDiffToday } from '../../lib/dateTimeHelper';
import { useClient } from '../../lib/useClient';
import { PropTypes } from 'prop-types';
import './components.scss';

export function SurveyBox({
    survey,
    insights,
    onExportPdf,
    onExportCsv,
    teamId,
    clickable,
    withModified,
}) {
    let statusLabel = Object.keys(SURVEY_STATUS_TYPE).find(
        (k) => SURVEY_STATUS_TYPE[k] === survey?.status
    );

    const navigate = useNavigate();

    const client = useClient();

    const [modifiedBy, setModifiedBy] = useState(null);

    const { dispatch } = useContext(SurveyContext);

    const [color, setColor] = useState(null);

    useEffect(() => {
        async function fetch() {
            let user = await getUser(survey?.lastActorsMiniature);
            setModifiedBy(user);
        }

        if (withModified) {
            fetch();
        }
    }, [withModified]);

    const exportPdfOption = {
        label: (
            <>
                <i className='icon icon-export-pdf'></i>
                <label>Export PDF</label>
            </>
        ),
        onClick: () => onExportPdf(survey),
    };

    const exportCsvOption = {
        label: (
            <>
                <i className='icon icon-export-csv'></i>
                <label>Export CSV</label>
            </>
        ),
        onClick: () => onExportCsv(survey),
    };

    const viewOption = {
        label: (
            <>
                <i className='icon icon-view-details'></i>
                <label>View</label>
            </>
        ),
        onClick: () => navigate(`/admin/surveys-settings/survey/${survey?.id}`),
    };
    const editOption = {
        label: (
            <>
                <i className='icon icon-edit'></i>
                <label>Edit</label>
            </>
        ),
        onClick: () => navigate(`/admin/surveys-settings/survey/${survey?.id}`),
    };
    const insightOption = {
        label: (
            <>
                <i className='icon icon-insight-active'></i>
                <label>Insights</label>
            </>
        ),
        onClick: () =>
            navigate(
                teamId
                    ? `/my-team/feedback/pulse/survey-overview/${survey?.id}/team/${teamId}`
                    : `/company/feedback/pulse/survey-overview/${survey?.id}`
            ),
    };
    const closeOption = {
        label: (
            <>
                <i className='icon icon-close'></i>
                <label>Close</label>
            </>
        ),
        onClick: () =>
            dispatch({
                type: SurveyActionTypes.CLOSE,
                payload: {
                    onConfirm: async () => {
                        let success = true;
                        try {
                            await closeSurvey(survey?.id);
                        } catch (error) {
                            success = false;
                        }

                        await dispatch({
                            type: SurveyActionTypes.SUCCESS,
                            payload: { success: success },
                        });
                    },
                },
            }),
    };
    const deleteOption = {
        label: (
            <Fragment>
                <i className='icon icon-delete'></i>
                <label>Delete</label>
            </Fragment>
        ),
        onClick: () =>
            dispatch({
                type: SurveyActionTypes.DELETE,
                payload: {
                    onConfirm: async () => {
                        let success = true;
                        try {
                            await deleteSurvey(survey?.id);
                        } catch (error) {
                            success = false;
                        }

                        await dispatch({
                            type: SurveyActionTypes.SUCCESS,
                            payload: { success: success },
                        });
                    },
                },
            }),
    };

    return (
        <div
            className={`survey-box ${clickable ? 'clickable' : ''}`}
            onClick={(ev) =>
                clickable && !ev.target.closest('.menu-selector')
                    ? insightOption.onClick()
                    : void 0
            }
            onKeyPress={(ev) =>
                clickable &&
                !ev.target.closest('.menu-selector') &&
                ev.key === 'Enter'
                    ? insightOption.onClick()
                    : void 0
            }
            tabIndex={0}
        >
            <div className='title'>
                <label className={`status small ${statusLabel.toLowerCase()}`}>
                    {statusLabel}
                </label>
                <label>{survey.title}</label>
                <i
                    className={`icon-${
                        survey.surveyType === 0 ? 'one-off' : 'recurring'
                    }`}
                    title={survey.surveyType === 0 ? 'One Off' : 'Recurring'}
                ></i>
                {survey.isAnonymous && (
                    <i
                        role='img'
                        aria-label='Anonymous'
                        className='icon-eye-anonymous'
                        title='Anonymous'
                    />
                )}
                <label>
                    {typeof survey?.iteration === 'number'
                        ? `#${survey?.iteration + 1}`
                        : ''}
                </label>
                <div className='release-time'>
                    <i className='icon-clock'></i>
                    <label>
                        {typeof insights === 'number'
                            ? humanizeDiffToday(survey?.releaseDate)
                            : moment(survey?.releaseDate).format('HH:mm')}
                    </label>
                </div>

                <OptionMenu
                    options={
                        onExportCsv && onExportPdf
                            ? [insightOption, exportPdfOption, exportCsvOption]
                            : survey?.status === SURVEY_STATUS_TYPE.Active
                            ? [viewOption, insightOption, closeOption]
                            : survey?.status === SURVEY_STATUS_TYPE.Closed
                            ? [viewOption, insightOption]
                            : survey?.iteration > 0
                            ? [viewOption, closeOption]
                            : [editOption, deleteOption]
                    }
                />
            </div>

            <div className='sent-to'>
                <label>Sent to:</label>
                {survey?.sentTo ? (
                    <>
                        {survey?.sentTo?.slice(0, 3).map((actor) => (
                            <SurveyActor
                                actor={actor}
                                key={`survey-actor-${actor?.id}`}
                            />
                        ))}
                        {survey?.sentTo?.length > 3 && (
                            <SurveyActor
                                actor={{
                                    name: `+ ${survey?.sentTo.length - 3}...`,
                                }}
                                title={survey?.sentTo
                                    .slice(3, survey?.sentTo?.length)
                                    .map((x) => x.name)
                                    .join(',')}
                            />
                        )}
                    </>
                ) : (
                    <SurveyActor
                        actor={{
                            name: client?.companyName,
                            image: client?.logo,
                        }}
                    />
                )}

                {typeof insights === 'number' && !isNaN(insights) && (
                    <div className='insights'>
                        <h2 style={{ color: color }}>{insights}%</h2>
                        <PulseChart
                            rate={insights}
                            onColor={(c) => setColor(c)}
                        />
                    </div>
                )}
            </div>

            <div>
                <label>Start date:</label>
                <p>{moment(survey?.releaseDate).format('DD/MM/YYYY')}</p>
            </div>

            <div>
                <label>End date:</label>
                <p>{moment(survey?.endDate).format('DD/MM/YYYY')}</p>
            </div>

            <div className='questions'>
                {survey?.questions?.map((q) => (
                    <SurveyQuestion
                        question={q}
                        key={`survey-question-${q?.id}`}
                    />
                ))}
            </div>

            {withModified && (
                <div className='created-modified'>
                    {`${survey?.isModified ? 'Modified' : 'Created'} by ${
                        modifiedBy?.name ?? 'System'
                    } on ${moment(survey?.lastActionDate).format(
                        'DD/MM/yyyy'
                    )}`}
                </div>
            )}
        </div>
    );
}

SurveyBox.propTypes = {
    survey: PropTypes.object,
    insights: PropTypes.number,
    onExportPdf: PropTypes.func,
    onExportCsv: PropTypes.func,
    teamId: PropTypes.string,
    clickable: PropTypes.bool,
    withModified: PropTypes.bool,
};

export function OptionMenu({ options, position }) {
    return (
        <MenuSelector
            title='Click to open options'
            className={`three-dots ${position}`}
            clickable={<i className='icon-three-dots-more'></i>}
        >
            <div className='options'>
                {options?.map((o, i) => (
                    <Option
                        label={o?.label}
                        onClick={o?.onClick}
                        key={`option-${i}`}
                    />
                ))}
            </div>
        </MenuSelector>
    );
}

OptionMenu.propTypes = {
    options: PropTypes.array,
    position: PropTypes.string,
};

function Option({ label, onClick }) {
    return (
        <Fragment>
            <div onClick={onClick} onKeyPress={onClick} tabIndex={0}>
                {label}
            </div>
        </Fragment>
    );
}

Option.propTypes = {
    label: PropTypes.object,
    onClick: PropTypes.func,
};

export function SurveyActor({ actor, title }) {
    return (
        <div className='survey-actor' title={title ?? ''}>
            {actor?.image && <img src={actor?.image} />}
            <label>{actor?.name}</label>
        </div>
    );
}

SurveyActor.propTypes = {
    actor: PropTypes.object,
    title: PropTypes.string,
};

export function SurveyQuestion({
    question,
    children,
    className,
    onClick,
    ellipsisTopic,
    survey,
}) {
    return (
        <div
            className={`survey-question ${className ?? ''}`}
            onClick={() => (typeof onClick === 'function' ? onClick() : void 0)}
        >
            <p>{question?.questionText}</p>
            {survey && (
                <ul>
                    {question?.choices?.map((c, i) => (
                        <li key={i}>{c.value}</li>
                    ))}
                </ul>
            )}
            <div className='topic-type'>
                <QuestionTopic
                    topic={question?.topic?.title}
                    ellipsis={ellipsisTopic}
                />
                <QuestionType type={question?.questionType} />
            </div>

            {!!children && children}
        </div>
    );
}

SurveyQuestion.propTypes = {
    question: PropTypes.object,
    children: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
    ellipsisTopic: PropTypes.bool,
    survey: PropTypes.bool,
};

export function QuestionType({ type }) {
    let icon = 'icon-yn';
    let title = 'Yes or no';
    switch (type) {
        case 0:
            icon = 'icon-feedback-give';
            title = 'Comment';
            break;
        case 1:
            icon = 'icon-new-score';
            title = 'Score';
            break;
        case 2:
            icon = 'icon-yn';
            title = 'Yes or no';
            break;
        case 3:
            icon = 'icon-likert';
            title = 'Likert scale';
            break;
        case 4:
            icon = 'icon-choice';
            title = 'Choice';
            break;
        default:
            break;
    }

    return (
        <div className='question-type' title={title}>
            <i className={icon}></i>
        </div>
    );
}

QuestionType.propTypes = {
    type: PropTypes.number,
};

export function QuestionTopic({ topic, ellipsis }) {
    return (
        <div className='topic-bubble'>
            <i className='icon icon-topic'></i>
            <p className={ellipsis ? 'topic-name' : ''} title={topic}>
                {topic}
            </p>
        </div>
    );
}

QuestionTopic.propTypes = {
    topic: PropTypes.string,
    ellipsis: PropTypes.bool,
};
