import { InformationTooltip } from '../../../../shared/components/InformationTooltip';
import { PropTypes } from 'prop-types';
import './components.scss';

export function Widget({
    title,
    info,
    children,
    className,
    boxClassName,
    hideTitle,
}) {
    return (
        <div className={`w-box p-shadow ${boxClassName ? boxClassName : ''}`}>
            <div className='header'>
                <h4>{!hideTitle ? title : ''}</h4>
            </div>

            <div className={`w-body ${className ? className : ''}`}>
                {children}
            </div>
            <div className='footer'>
                <InformationTooltip text={info} />
            </div>
        </div>
    );
}

Widget.propTypes = {
    title: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    className: PropTypes.string,
    boxClassName: PropTypes.string,
    hideTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
