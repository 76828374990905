import _uniqueId from 'lodash/uniqueId';
import { useCallback, useState } from 'react';
import { useWindowEvent } from '../lib/useWindowEvent';
import { PropTypes } from 'prop-types';

export function MenuSelector({ className, clickable, title, children }) {
    const [open, setOpen] = useState(false);

    const [id] = useState(_uniqueId('menu-selector-'));
    useWindowEvent(
        'mousedown',
        useCallback((event) => {
            if (!event.target.closest(`#${id}`)) {
                setOpen(false);
            }
        }, [])
    );

    return (
        <div
            id={id}
            className={`menu-selector ${className ?? ''} ${
                open ? 'focus' : ''
            }`}
        >
            <a
                aria-label={title}
                title={title}
                tabIndex={0}
                className='clickable'
                onClick={() => {
                    setOpen(!open);
                }}
                onKeyPress={(e) => {
                    e.key === 'Enter' && setOpen(!open);
                }}
            >
                {clickable}
            </a>
            <div className={`tooltip ${open ? 'active' : ''}`}>{children}</div>
        </div>
    );
}

MenuSelector.propTypes = {
    className: PropTypes.string,
    clickable: PropTypes.object,
    title: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
