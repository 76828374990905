import { Fragment, useState } from 'react';
import {
    deleteTopic,
    getTopicsPaginated,
} from '../../../../services/topicService';
import { Box } from '../../../shared/Box';
import { Modal } from '../../../shared/components/Modal';
import { AddButton } from '../../../shared/inputs/AddButton';
import { IconButtonModal } from '../../../shared/inputs/IconButtonModal';
import { IconConfirmationModal } from '../../../shared/inputs/IconConfirmationModal';
import { SearchInput } from '../../../shared/inputs/SearchInput';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { ResponsiveTable } from '../../../shared/utils/ResponsiveTable';
import { Topic } from './Topic';
import './Topics.scss';

export function Topics() {
    const [filter, setFilter] = useState('');

    const [deleted, setDeleted] = useState(null);

    async function handleDelete(id) {
        try {
            await deleteTopic(id);
            setDeleted(true);
        } catch (e) {
            setDeleted(false);
        }

        setFilter('');
    }

    const [topicId, setTopicId] = useState(null);

    const [open, setOpen] = useState(false);

    return (
        <Page
            title={`Topics`}
            className='topics'
            description={
                <div>
                    <Box>
                        <div className='box'>
                            <span>
                                All surveys and feedback within Pulse 360 are
                                aligned to topics. These topics are configurable
                                and aligned to areas that your organisation
                                wishes to gather input on. Topics help ensure
                                employee feedback is specific and relevant.
                            </span>
                            <br />
                            <br />
                            <span>
                                We distinguish between ‘organisation only’
                                topics and those that are suitable for both peer
                                and employee voice. Certain topic areas for the
                                organisation may be irrelevant or unsuitable for
                                employee peer-to-peer feedback.
                            </span>
                        </div>
                    </Box>
                </div>
            }
        >
            <MainContent testId='topics'>
                <div className='container-page'>
                    <div className='header'>
                        <AddButton onClick={() => setOpen(true)} autoFocus>
                            Add Topic
                        </AddButton>

                        <SearchInput
                            onSearch={(text) => setFilter(text)}
                            placeholder={'Name or Description'}
                        />
                    </div>
                    <div className='row'>
                        <ResponsiveTable
                            className='topics'
                            noDataText='No current topics'
                            onFetchData={getTopicsPaginated}
                            limit={10}
                            innerScroll={false}
                            defaultSorted={{ id: 'title', desc: false }}
                            refresh={deleted}
                            filter={filter}
                            columns={[
                                {
                                    Header: 'Name',
                                    accessor: 'title',
                                    sortDescFirst: true,
                                },
                                {
                                    Header: 'Description',
                                    accessor: 'description',
                                    sortDescFirst: true,
                                },
                                {
                                    Header: 'Peer',
                                    accessor: 'category',
                                    id: 'peer',
                                    headerClassName: 'text-center',
                                    disableSortBy: true,
                                    Cell: (row) => (
                                        <Fragment>
                                            {(row.value === 2 ||
                                                row.value == 0) && (
                                                <span className='topic-checkmark'></span>
                                            )}
                                        </Fragment>
                                    ),
                                },
                                {
                                    Header: 'Employee Voice',
                                    accessor: 'category',
                                    id: 'employeeVoice',
                                    headerClassName: 'text-center',
                                    disableSortBy: true,
                                    Cell: (row) => (
                                        <Fragment>
                                            {(row.value === 2 ||
                                                row.value == 1) && (
                                                <span className='topic-checkmark'></span>
                                            )}
                                        </Fragment>
                                    ),
                                },
                                {
                                    Header: 'total-here',
                                    accessor: 'id',
                                    headerClassName: 'buttons',
                                    className: 'buttons',
                                    disableSortBy: true,
                                    width: 50,
                                    Cell: (row) => (
                                        <Fragment>
                                            <div className='float-right'>
                                                <IconConfirmationModal
                                                    type='edit'
                                                    success={true}
                                                    confirmMessage={
                                                        <p>
                                                            Are you sure you
                                                            would like to edit
                                                            this topic?
                                                            <br />
                                                            <br />
                                                            <strong>
                                                                The historical
                                                                information
                                                                related to this
                                                                topic will be
                                                                overwritten by
                                                                the new changes
                                                                (i.e. A feedback
                                                                created using
                                                                the original
                                                                topic will have
                                                                now been
                                                                replaced by your
                                                                changes){' '}
                                                            </strong>
                                                        </p>
                                                    }
                                                    onConfirm={() => {
                                                        setTopicId(row?.value);
                                                        setOpen(true);
                                                    }}
                                                />

                                                <IconButtonModal
                                                    type='delete'
                                                    success={deleted}
                                                    message={
                                                        deleted
                                                            ? 'Topic deleted successfully.'
                                                            : 'Ops! Something went wrong, please try again later.'
                                                    }
                                                    hideConfirmation={true}
                                                    confirmMessage={
                                                        <p>
                                                            Are you sure you
                                                            would like to delete
                                                            this topic? <br />{' '}
                                                            <br />
                                                            <strong>
                                                                {' '}
                                                                Historical
                                                                feedback data
                                                                will be stored
                                                                but users will
                                                                not be able to
                                                                select this
                                                                topic when
                                                                asking
                                                                for/giving
                                                                feedback. <br />{' '}
                                                                <br />
                                                                Information
                                                                related to this
                                                                topic will no
                                                                longer show on
                                                                feedback
                                                                dashboards.
                                                            </strong>
                                                        </p>
                                                    }
                                                    onConfirm={() =>
                                                        handleDelete(row.value)
                                                    }
                                                    onClose={() =>
                                                        setDeleted(null)
                                                    }
                                                />
                                            </div>
                                        </Fragment>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            </MainContent>

            <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                    setTopicId(null);
                    setDeleted(null);
                }}
            >
                {open && (
                    <Topic
                        topicId={topicId}
                        onSubmit={() => {
                            setOpen(false);
                            setTopicId(null);
                            setDeleted(true);
                        }}
                    />
                )}
            </Modal>
        </Page>
    );
}
