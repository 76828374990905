import { get } from '../components/shared/lib/axios';

var controllerRoute = 'roles';

export async function getRoles() {
    return get(controllerRoute);
}

export async function getRole(roleId) {
    return get(`${controllerRoute}/${roleId}`);
}
