import { useEffect, useState } from 'react';
import {
    Bar,
    BarChart,
    Cell,
    ResponsiveContainer,
    Text,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { Loading } from '../../loadings/Loading';
import { PropTypes } from 'prop-types';
import './components.scss';

export function ResponseRateTrend({ iterations, teamId, onChange, iteration }) {
    const data = useResponseRateData(iterations, teamId);

    return !data?.length ? (
        <Loading />
    ) : (
        <div>
            <ResponsiveContainer width='100%' height={200} minHeight='20rem'>
                <BarChart
                    barCategoryGap='0%'
                    data={data}
                    margin={{
                        top: 5,
                        right: 0,
                        left: -20,
                        bottom: 5,
                    }}
                >
                    <XAxis
                        tickLine={false}
                        dataKey='name'
                        tick={({ payload, ...props }) => (
                            <Text
                                {...props}
                                fontSize={
                                    payload.value - 1 === iteration
                                        ? '1.5rem'
                                        : '1.2rem'
                                }
                                fontFamily='Open Sans'
                                fontWeight={
                                    payload.value - 1 === iteration ? 800 : 500
                                }
                            >{`#${payload.value}`}</Text>
                        )}
                    />
                    <YAxis
                        tickLine={false}
                        tickFormatter={(tick) => `${tick}%`}
                        tick={{ fontSize: '1.2rem', fontFamily: 'Open Sans' }}
                        type='number'
                        domain={[0, 100]}
                    />
                    {typeof onChange === 'function' && (
                        <Tooltip
                            wrapperStyle={{ zIndex: 1000 }}
                            cursor={{ fill: 'transparent' }}
                            content={({ active, payload }) => {
                                if (active) {
                                    return (
                                        <div className='historic-tooltip'>
                                            {payload.map((x, i) => (
                                                <CustomTooltip key={`ct-${i}`}>
                                                    <h4>{x.payload.rate}%</h4>
                                                </CustomTooltip>
                                            ))}
                                        </div>
                                    );
                                }

                                return null;
                            }}
                        />
                    )}
                    <Bar
                        maxBarSize={data?.length > 6 ? 30 : 50}
                        dataKey='rate'
                        fill='#4cbabb'
                        minPointSize={2}
                        onClick={(data, index) =>
                            typeof onChange === 'function'
                                ? onChange(index)
                                : void 0
                        }
                    >
                        {data.map((entry, index) => (
                            <Cell
                                cursor='pointer'
                                fill={
                                    index === iteration ? '#741f82' : '#4cbabb'
                                }
                                key={`cell-${index}`}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

ResponseRateTrend.propTypes = {
    iterations: PropTypes.array,
    teamId: PropTypes.string,
    onChange: PropTypes.func,
    iteration: PropTypes.number,
};

export function CustomTooltip({ children }) {
    return <div className='custom-tooltip-recharts'>{children}</div>;
}

CustomTooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export function useResponseRateData(iterations, teamId) {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (iterations?.length) {
            let aux = [
                ...(teamId
                    ? iterations.map((x) => ({
                          iteration: x.iteration,
                          insight: x.teamInsights.find(
                              (x) => x.team.id === teamId
                          ),
                      }))
                    : iterations),
            ];

            setData(
                aux
                    ?.sort((x, y) => x?.iteration - y?.iteration)
                    .map((d) => ({
                        name: d?.iteration + 1,
                        rate: Number(
                            d?.insight?.completionRate?.toFixed(0) ?? 0
                        ),
                    }))
            );
        }
    }, [teamId, iterations]);

    return data;
}

export function ResponseRateTrendChart({ data, onChange, iteration }) {
    return (
        <BarChart
            barCategoryGap='0%'
            data={data}
            margin={{
                top: 5,
                right: 0,
                left: -20,
                bottom: 5,
            }}
        >
            <XAxis
                tickLine={false}
                dataKey='name'
                tick={({ payload, ...props }) => (
                    <Text
                        {...props}
                        fontSize={
                            payload.value - 1 === iteration
                                ? '1.5rem'
                                : '1.2rem'
                        }
                        fontFamily='Open Sans'
                        fontWeight={payload.value - 1 === iteration ? 800 : 500}
                    >{`#${payload.value}`}</Text>
                )}
            />
            <YAxis
                tickLine={false}
                tickFormatter={(tick) => `${tick}%`}
                tick={{ fontSize: '1.2rem', fontFamily: 'Open Sans' }}
                type='number'
                domain={[0, 100]}
            />
            {typeof onChange === 'function' && (
                <Tooltip
                    wrapperStyle={{ zIndex: 1000 }}
                    cursor={{ fill: 'transparent' }}
                    content={({ active, payload }) => {
                        if (active) {
                            return (
                                <div className='historic-tooltip'>
                                    {payload.map((x, i) => (
                                        <CustomTooltip key={`ct-${i}`}>
                                            <h4>{x.payload.rate}%</h4>
                                        </CustomTooltip>
                                    ))}
                                </div>
                            );
                        }

                        return null;
                    }}
                />
            )}
            <Bar
                maxBarSize={data?.length > 6 ? 30 : 50}
                dataKey='rate'
                fill='#4cbabb'
                minPointSize={2}
                onClick={(data, index) =>
                    typeof onChange === 'function' ? onChange(index) : void 0
                }
            >
                {data.map((entry, index) => (
                    <Cell
                        cursor='pointer'
                        fill={index === iteration ? '#741f82' : '#4cbabb'}
                        key={`cell-${index}`}
                    />
                ))}
            </Bar>
        </BarChart>
    );
}

ResponseRateTrendChart.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    iteration: PropTypes.number,
};
