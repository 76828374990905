import { Fragment, useState, useEffect } from 'react';
import { Loading } from '../../../../loadings/Loading';
import { SentimentScoreDistributionChart } from './SentimentScoreDistributionChart';
import { YesNoDistributionChart } from './YesNoDistributionChart';
import { useDistributionData } from '../../useDistributionData';
import { LikertDistributionChart } from './LikertDistributionChart';
import { PropTypes } from 'prop-types';
import { ChoiceDistributionChart } from './ChoiceDistributionChart';
import constants from '../../../../constants';

export function DistributionChart({
    surveyId,
    questionId,
    teamId,
    iteration,
    onClick,
    clickable,
    legend,
}) {
    const [loading, setLoading] = useState(true);

    const [filter, setFilter] = useState(null);

    const distributionData = useDistributionData(
        surveyId,
        questionId,
        teamId,
        iteration
    );

    useEffect(() => {
        setLoading(true);
    }, [questionId, iteration, teamId]);

    useEffect(() => {
        setLoading(!distributionData);
    }, [distributionData]);

    function handleOnClick(f) {
        if (typeof onClick === 'function') {
            setFilter(f);
            onClick(f);
        }
    }

    return (
        <div
            className={`score-distribution-chart ${
                distributionData?.type === constants.QuestionType.YesNo
                    ? 'horizontal'
                    : ''
            } ${filter ? 'selected' : ''}`}
        >
            {loading ? (
                <Loading />
            ) : (
                <Fragment>
                    {(distributionData?.type ===
                        constants.QuestionType.Suggestion ||
                        distributionData?.type ===
                            constants.QuestionType.Score) && (
                        <SentimentScoreDistributionChart
                            onClick={handleOnClick}
                            selected={filter}
                            data={distributionData?.valueDistribution}
                            clickable={clickable}
                        />
                    )}
                    {distributionData?.type ===
                        constants.QuestionType.YesNo && (
                        <YesNoDistributionChart
                            onClick={handleOnClick}
                            selected={filter}
                            data={distributionData?.valueDistribution}
                            clickable={clickable}
                        />
                    )}
                    {distributionData?.type ===
                        constants.QuestionType.Likert && (
                        <LikertDistributionChart
                            legend={legend}
                            onClick={handleOnClick}
                            selected={filter}
                            data={distributionData?.valueDistribution}
                            clickable={clickable}
                            isAnswered={distributionData?.isAnswered}
                        />
                    )}
                    {distributionData?.type ===
                        constants.QuestionType.Choice && (
                        <ChoiceDistributionChart
                            onClick={handleOnClick}
                            data={distributionData?.valueDistribution}
                            legend={true}
                            isAnswered={distributionData?.isAnswered}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
}

DistributionChart.propTypes = {
    surveyId: PropTypes.string,
    questionId: PropTypes.string,
    teamId: PropTypes.string,
    iteration: PropTypes.number,
    onClick: PropTypes.func,
    clickable: PropTypes.bool,
    legend: PropTypes.bool,
};
