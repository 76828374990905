import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import {
    getPriorityTypes,
    getUserActions,
} from '../../../../../../services/actionService';
import { Table } from '../../../../../shared/components/Table';
import { useAsyncError } from '../../../../../shared/lib/helper';
import { useSession } from '../../../../../shared/lib/useSession';
import '../Actions.scss';

export function CompletedActionsSection() {
    const [actions, setActions] = useState([]);

    const [priorityTypes, setPriorityTypes] = useState([]);

    const [loading, setLoading] = useState(true);

    const session = useSession();

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchData() {
            try {
                let [act, prior] = await Promise.all([
                    getUserActions(session?.userId, 2),
                    getPriorityTypes(),
                ]);

                setPriorityTypes(prior);

                setActions(act);

                setLoading(false);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, []);

    return (
        <div className='main-container completed-actions'>
            <Table
                className='actions'
                data={actions}
                loading={loading}
                noDataText={'No completed actions'}
                pageSize={actions.length}
                columns={[
                    {
                        Header: 'Action',
                        width: 300,
                        accessor: 'description',
                    },
                    {
                        Header: 'Topic',
                        accessor: 'pprTopic',
                        width: 150,
                        Cell: (row) => (
                            <div title={row.value.title}>{row.value.title}</div>
                        ),
                    },
                    {
                        Header: 'Priority',
                        accessor: 'priority',
                        width: 150,
                        Cell: (row) => (
                            <div
                                title={`${row.value + 1} - ${
                                    priorityTypes.find(
                                        (x) => x.value === row.value
                                    ).key
                                }`}
                            >
                                {`${row.value + 1} - ${
                                    priorityTypes.find(
                                        (x) => x.value === row.value
                                    ).key
                                }`}
                            </div>
                        ),
                    },
                    {
                        Header: 'Completed',
                        headerClassName: 'align-center',
                        className: 'completed-action-date',
                        accessor: 'completedDate',
                        width: 150,
                        Cell: (row) => (
                            <Fragment>
                                <div
                                    className='completed-action-date'
                                    title={moment(
                                        row.row.original.completedDate
                                    ).format('DD MMM YY')}
                                >
                                    {moment(
                                        row.row.original.completedDate
                                    ).format('DD MMM YY')}
                                </div>
                            </Fragment>
                        ),
                    },
                ]}
            />
        </div>
    );
}
