import { PropTypes } from 'prop-types';

export function Hamburger({ open, onClick }) {
    return (
        <a
            aria-expanded={open}
            className={`hamburger ${open ? 'open' : ''}`}
            onClick={onClick}
            tabIndex={0}
        >
            <span></span>
            <span></span>
            <span></span>
        </a>
    );
}

Hamburger.propTypes = {
    open: PropTypes.bool,
    onClick: PropTypes.func,
};
