import { Fragment, useEffect, useState } from 'react';
import { SearchInput } from '../../inputs/SearchInput';
import { PropTypes } from 'prop-types';
import './CheckboxList.scss';

export function CheckboxList({
    list,
    onChange,
    title,
    search,
    bubble,
    all,
    onStartSelect,
}) {
    const [items, setItems] = useState([]);

    const [allItems, setAllItems] = useState('');

    const [filteredItems, setFilteredItems] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (list.length > 0) {
            let it = list;

            if (onStartSelect) {
                it = list.map((item) => ({
                    selected: true,
                    value: item.value,
                    label: item.label,
                }));
                onChange(
                    it.map((item) => ({ value: item.value, label: item.label }))
                );
            }

            setItems(it);
            setFilteredItems(it);
            setAllItems(all);
        }

        if (list.find((x) => x.selected)) {
            onChange(
                list
                    .filter((x) => x.selected)
                    .map((item) => ({ value: item.value, label: item.label }))
            );
        }

        setLoading(false);
    }, [list]);

    function handleAll({ target: { checked } }) {
        if (!loading) {
            let it = items.map((item) => ({
                selected: checked,
                value: item.value,
                label: item.label,
            }));
            setItems(it);

            let fit = filteredItems.map((item) => ({
                selected: checked,
                value: item.value,
                label: item.label,
            }));
            setFilteredItems(fit);

            onChange(
                items
                    .filter((x) => x.selected)
                    .map((item) => ({ value: item.value, label: item.label }))
            );
        }
    }

    function handleSelection({ target: { name, checked } }) {
        if (!loading) {
            let it = [...items];
            it.find((x) => x.value === name).selected = checked;
            setItems(it);

            let fit = [...filteredItems];
            let item = fit.find((x) => x.value === name);

            if (item) {
                item.selected = checked;
            }
            setFilteredItems(fit);

            onChange(
                items
                    .filter((x) => x.selected)
                    .map((item) => ({ value: item.value, label: item.label }))
            );
        }
    }

    function filterItems(value) {
        if (value) {
            setFilteredItems(
                filteredItems.filter((x) =>
                    x.label.toLowerCase().includes(value.toLowerCase())
                )
            );
        } else if (items.length) {
            setFilteredItems(items);
        }
    }

    return (
        <div className='check-box-list'>
            <div className='title'>{title}</div>

            {bubble && (
                <div className='filter-section selected-bubbles'>
                    {items.map((item) =>
                        item.selected ? (
                            <div
                                key={`bubble-${item.value}`}
                                className='bubble'
                            >
                                <span key={`bubble-span-${item.value}`}>
                                    {item.label}
                                </span>
                                <i
                                    key={`bubble-icon-${item.value}`}
                                    className='glyphicon glyphicon-remove'
                                    onClick={() =>
                                        handleSelection({
                                            target: {
                                                name: item.value,
                                                checked: false,
                                            },
                                        })
                                    }
                                ></i>
                            </div>
                        ) : (
                            <Fragment key={`bubble-rf-${item.value}`}>
                                {' '}
                            </Fragment>
                        )
                    )}
                </div>
            )}

            <div className='filter-section'>
                {search && (
                    <Fragment>
                        <SearchInput
                            placeholder={`Search ${title}`}
                            onSearch={filterItems}
                        />
                    </Fragment>
                )}

                <ul className='list-group'>
                    {allItems && (
                        <li className='list-group-item checkbox'>
                            <label className='pulse360-checkbox tick'>
                                <input
                                    type='checkbox'
                                    checked={
                                        items.filter((x) => !x.selected)
                                            .length === 0
                                    }
                                    onChange={handleAll}
                                />
                                {allItems}
                                <span></span>
                            </label>
                        </li>
                    )}

                    {filteredItems.map((item) => (
                        <li
                            className='list-group-item checkbox'
                            key={`li-ch-${item.value}`}
                        >
                            <label
                                key={`lb-${item.value}`}
                                className='pulse360-checkbox tick'
                            >
                                <input
                                    key={`inp-${item.value}`}
                                    type='checkbox'
                                    name={item.value}
                                    checked={item.selected || false}
                                    onChange={handleSelection}
                                />
                                {item.label}
                                <span key={`sp-${item.value}`}></span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

CheckboxList.propTypes = {
    list: PropTypes.array,
    onChange: PropTypes.func,
    title: PropTypes.string,
    search: PropTypes.bool,
    bubble: PropTypes.bool,
    all: PropTypes.string,
    onStartSelect: PropTypes.bool,
};
