import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { DoubleRadialChart } from '../../../../../../shared/charts/DoubleRadialChart';
import { useRagIntervals } from '../../../../../../shared/lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import constants from '../../../../../../shared/constants';
import './components.scss';

export function SliderChartBox({ question, onChange }) {
    const [score, setScore] = useState(0);

    const [color, setColor] = useState('');

    const [target, setTarget] = useState(0);

    const { getColor } = useRagIntervals();

    const marks = {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
    };

    useEffect(() => {
        async function color() {
            let color = getColor(score, constants.QuestionType.Score);
            setColor(color);
        }

        color();

        if (target < score) {
            setTarget(score);
        }
    }, [score]);

    useEffect(() => {
        if (target < score) {
            setScore(target);
        }
    }, [target]);

    useEffect(
        () => onChange({ score: score, target: target }),
        [score, target]
    );

    useEffect(() => {
        setScore(question.value ? question.value : 0);
        setTarget(question.targetValue ? question.targetValue : 0);
    }, [question]);

    return (
        <div className='slider-chart-box' role='section'>
            <div className='title'>{question.title}</div>
            <div className='description'>{`"${question.description}"`}</div>
            <div className='slider-section' role='section'>
                <div className='title'>Current Score </div>
                <Slider
                    ariaLabelForHandle='Move the slider to select your current score.'
                    ariaValueTextFormatterForHandle={(value) =>
                        `Your selected score is: ${value} out of ten.`
                    }
                    className='current-score'
                    trackStyle={{
                        backgroundColor: color,
                        borderColor: color,
                    }}
                    handleStyle={{
                        backgroundColor: color,
                        borderColor: color,
                    }}
                    min={1}
                    max={10}
                    marks={marks}
                    onChange={(value) => setScore(value)}
                    value={score}
                />
                <div className='title'>Target Score </div>
                <Slider
                    ariaLabelForHandle='Move the slider to select your target score.'
                    ariaValueTextFormatterForHandle={(value) =>
                        `Your selected score is: ${value} out of ten.`
                    }
                    className='target-score'
                    min={1}
                    max={10}
                    marks={marks}
                    onChange={(value) => setTarget(value)}
                    value={target}
                />
            </div>

            <div className='radial-chart-section'>
                <DoubleRadialChart
                    score={score}
                    target={target}
                    scoreColor={color}
                    targetColor={'#363D56'}
                />
            </div>
        </div>
    );
}

SliderChartBox.propTypes = {
    question: PropTypes.object,
    onChange: PropTypes.func,
};
