import { get, post, put } from '../components/shared/lib/axios';

export async function getFeedback(feedbackId, tenantId) {
    return get(`/guest/feedback`, { feedbackId, tenantId });
}

export async function feedbackGive(body, tenantId) {
    return post(`/guest/feedback`, body, { tenantId });
}

export async function feedbackReact(body, tenantId) {
    return put(`/guest/feedback`, body, { tenantId });
}
