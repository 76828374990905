import { Fragment, useState } from 'react';
import {
    Emoji,
    EmojiRating,
} from '../../../../../shared/components/EmojiRating';
import constants from '../../../../../shared/constants';
import { Textarea } from '../../../../../shared/inputs/Textarea';
import { humanizeDiffToday } from '../../../../../shared/lib/dateTimeHelper';
import { UserPhoto } from './Components/UserPhoto';
import { PropTypes } from 'prop-types';
import '../HubItems.scss';

export function FeedbackItem({
    submitComment,
    item,
    submitRate,
    read,
    anonymity,
    color,
    ratings,
}) {
    const [commented, setCommented] = useState(false);
    let newCommentText = '';

    function onCommentEnterPress(e, actorId) {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();

            if (newCommentText) {
                submitComment(item, actorId, newCommentText);

                setCommented(true);
            }
        }
    }

    function handleCommentChange({ target: { value } }) {
        newCommentText = value;
    }

    return (
        <div className={`me-items-box ${read ? 'grey-border' : 'blue-border'}`}>
            <div className='main-section' role='section'>
                <div className='row row-box'>
                    <UserPhoto
                        giver={item.feedbackGiver}
                        anonymous={item.isOrganization && anonymity}
                        receiver={item.feedbackReceiver}
                    />

                    {item.hasScore && (
                        <div className='score-container'>
                            <span className='score'>{item.score}</span>
                            <span className='score-separator'>{'/'}</span>
                            <span>10</span>
                        </div>
                    )}

                    <div className='end-div'>
                        {item.type == constants.MeItemsType.FeedbackGiven ? (
                            <span className='me-item-type'>Feedback given</span>
                        ) : (
                            ''
                        )}

                        {item.type == constants.MeItemsType.FeedbackReceived ? (
                            <span className='me-item-type'>
                                Feedback received
                            </span>
                        ) : (
                            ''
                        )}

                        <p className='small light'>
                            {humanizeDiffToday(item.created)}
                        </p>
                    </div>
                </div>
                <div className='row row-box'>
                    <div className='from-topic-col'>
                        {item.userIsFeedbackGiver ? 'To:' : 'From:'}
                    </div>
                    <div className='user-title'>
                        {item.userIsFeedbackGiver
                            ? item.feedbackReceiver?.name
                            : item.feedbackGiver?.name}
                    </div>
                </div>
                <div className='row row-box'>
                    <div className='topic-bubble'>
                        <i className='icon icon-topic'></i>
                        {item.topic}
                    </div>
                </div>

                <div
                    className='row row-box text-row'
                    style={{ marginBottom: '1rem' }}
                >
                    <p className='item-text'>{item.text}</p>
                </div>

                {!item.isOrganization ? (
                    <Fragment>
                        <div className='rate-comment-row'>
                            {item.hasBeenRated ? (
                                <div className='box-reaction'>
                                    {item.reactions &&
                                        item.reactions.length === 1 && (
                                            <Fragment>
                                                <Emoji
                                                    description={
                                                        ratings.find(
                                                            (x) =>
                                                                x.id ===
                                                                item
                                                                    .reactions[0]
                                                                    .emoji.id
                                                        ).description
                                                    }
                                                    value={
                                                        ratings.find(
                                                            (x) =>
                                                                x.id ===
                                                                item
                                                                    .reactions[0]
                                                                    .emoji.id
                                                        ).value
                                                    }
                                                />
                                            </Fragment>
                                        )}
                                </div>
                            ) : (
                                ''
                            )}

                            {!item.userIsFeedbackGiver &&
                                !item.hasBeenRated && (
                                    <div className='box-reaction'>
                                        <EmojiRating
                                            ratings={ratings}
                                            onChange={(rating) =>
                                                submitRate(item, rating)
                                            }
                                        />
                                    </div>
                                )}

                            {item.hasBeenCommented && (
                                <div className='box-reaction'>
                                    <i className='icon-comment-active' />{' '}
                                    <span>Responded</span>
                                </div>
                            )}

                            {!item.userIsFeedbackGiver &&
                                !item.hasBeenCommented && (
                                    <div className='box-reaction'>
                                        <i className='icon-comments' />{' '}
                                        <span>Respond</span>
                                    </div>
                                )}
                        </div>

                        {(!item.userIsFeedbackGiver ||
                            (item.userIsFeedbackGiver &&
                                item.hasBeenCommented)) && (
                            <div className='comment-box'>
                                <div className='row'>
                                    <div className='col-md-1 comment-image'>
                                        <UserPhoto
                                            giver={item.feedbackReceiver}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='user-title'>
                                                    {item.feedbackReceiver.name}
                                                </div>
                                            </div>
                                            {item.hasBeenReacted && (
                                                <div className='col-md-4'>
                                                    {item.reactions &&
                                                        item.reactions
                                                            .length === 1 && (
                                                            <p className='small light'>
                                                                {humanizeDiffToday(
                                                                    item
                                                                        .reactions[0]
                                                                        .date
                                                                )}
                                                            </p>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        <div className='row'>
                                            <div
                                                className='col-xs-12'
                                                style={{ paddingRight: 0 }}
                                            >
                                                {item.hasBeenCommented ? (
                                                    <div className='comment-text-wrap'>
                                                        {' '}
                                                        {item.reactions &&
                                                        item.reactions
                                                            .length === 1
                                                            ? item.reactions[0]
                                                                  .commentText
                                                            : ''}
                                                    </div>
                                                ) : (
                                                    <Textarea
                                                        placeholder='Write a comment...'
                                                        className='commentText'
                                                        onKeyDown={(e) =>
                                                            onCommentEnterPress(
                                                                e,
                                                                item
                                                                    .feedbackReceiver
                                                                    .id
                                                            )
                                                        }
                                                        onChange={
                                                            handleCommentChange
                                                        }
                                                        maxLength={
                                                            constants.maxLength
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {item.reactions.length > 0 &&
                            item.reactions?.map((reaction, i) => (
                                <CommentBox
                                    key={`reaction-${i}`}
                                    reaction={reaction}
                                    anonymity={anonymity}
                                    item={item}
                                    color={color}
                                />
                            ))}

                        {!commented &&
                        item.reactions.length < 4 &&
                        item.reactions.length > 0 &&
                        item.reactions[item.reactions.length - 1].actor.id ===
                            item.feedbackReceiver.id ? (
                            <div className='comment-box'>
                                <div className='row'>
                                    <div className='col-md-1 comment-image'>
                                        <UserPhoto
                                            giver={item.feedbackGiver}
                                            anonymous={anonymity}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='user-title'>
                                                    {anonymity
                                                        ? 'Anonymous'
                                                        : item.feedbackGiver
                                                              .name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-xs-12'>
                                                <Textarea
                                                    placeholder='Write a comment...'
                                                    className='commentText'
                                                    onKeyDown={(e) =>
                                                        onCommentEnterPress(
                                                            e,
                                                            item.feedbackGiver
                                                                .id
                                                        )
                                                    }
                                                    onChange={
                                                        handleCommentChange
                                                    }
                                                    maxLength={
                                                        constants.maxLength
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    );
}

FeedbackItem.propTypes = {
    submitComment: PropTypes.func,
    item: PropTypes.object,
    submitRate: PropTypes.func,
    read: PropTypes.bool,
    anonymity: PropTypes.bool,
    color: PropTypes.string,
    ratings: PropTypes.array,
};

export function CommentBox({ anonymity, item, reaction }) {
    return (
        <div className='comment-box'>
            <div className='row'>
                <div className='col-md-1 comment-image'>
                    {!reaction?.actor?.icon ? (
                        <UserPhoto
                            giver={reaction.actor}
                            anonymous={
                                anonymity &&
                                item.feedbackGiver?.id === reaction.actor.id
                            }
                        />
                    ) : (
                        <i className={reaction?.actor?.icon}></i>
                    )}
                </div>
                <div className='col-md-11'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='user-title'>
                                {anonymity &&
                                item.feedbackGiver?.id === reaction.actor.id
                                    ? 'Anonymous'
                                    : reaction.actor.name}
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <p className='small light'>
                                {humanizeDiffToday(reaction.date)}
                            </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div
                            className='col-xs-12 comment-text-wrap'
                            style={{
                                paddingRight: 0,
                            }}
                        >
                            <div> {reaction.commentText}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CommentBox.propTypes = {
    anonymity: PropTypes.bool,
    reaction: PropTypes.object,
    item: PropTypes.object,
};
