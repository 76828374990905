import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { getSurveyCompletionRate } from '../../../services/surveyCompletionRateService';
import { HorizontalBarChart } from '../charts/HorizontalBarChart';
import { PulseSelect } from '../inputs/PulseSelect';
import { useAsyncError } from '../lib/helper';
import { Loading } from '../loadings/Loading';
import { DateSelector } from '../utils/DateSelector';
import { PropTypes } from 'prop-types';
import './SurveyCompletionRateWidget.scss';

export function SurveyCompletionRateWidget({
    take,
    filterable,
    team,
    onRedirect,
    onRedirectTeam,
    from,
    to,
}) {
    const [chartData, setChartData] = useState(null);

    const [completionRate, setCompletionRate] = useState(null);

    const [startDate, setStartDate] = useState(moment.utc());

    const [endDate, setEndDate] = useState(moment.utc().add(-1, 'months'));

    const [filter, setFilter] = useState(2);

    const [loading, setLoading] = useState(true);

    const throwError = useAsyncError();

    const filterOptions = [
        {
            label: 'Alphabetical',
            value: 0,
        },
        {
            label: 'Lowest - Highest',
            value: 1,
        },
        {
            label: 'Highest - Lowest',
            value: 2,
        },
    ];

    useEffect(() => {
        async function fetchData() {
            try {
                let data = await getSurveyCompletionRate(
                    from ? from : startDate,
                    to ? to : endDate,
                    team ? team.id : null
                );

                setCompletionRate(data);
                setLoading(false);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, [to, endDate, team]);

    useEffect(() => {
        if (completionRate) {
            switch (filter) {
                case 1:
                    completionRate.sort((x, y) => x.rate - y.rate);
                    break;
                case 2:
                    completionRate.sort((x, y) => y.rate - x.rate);
                    break;

                default:
                    completionRate.sort((x, y) => x.name.localeCompare(y.name));
                    break;
            }

            completionRate.sort((x, y) => y.type - x.type);

            let data = completionRate
                .slice(0, take ? take : completionRate.length)
                .map((x, i) => {
                    let color =
                        i == 0 ? '#31353a' : i == 1 && team ? '#424953' : null;

                    return {
                        label: i == 0 ? 'Company' : x.name,
                        value:
                            typeof x.rate === 'number'
                                ? Number(x.rate?.toFixed(0))
                                : null,
                        color: color,
                        sLabel: `${x.rate}%`,
                        onClick:
                            x.type === 1 && !team
                                ? () => onRedirectTeam(x.id)
                                : null,
                    };
                });

            if (completionRate.length - take > 0) {
                data.push({
                    label: '',
                    value: 100,
                    color: '#6c737f',
                    barText: `+ ${completionRate.length - take} more`,
                    barTextColor: '#efefef',
                    sLabel: '',
                    onClick: () => onRedirect(),
                });
            }

            setChartData(data);
        }
    }, [filter, completionRate]);

    return (
        <div className='survey-completion-rate-widget'>
            {loading ? (
                <Loading />
            ) : (
                <Fragment>
                    <div className='title-filter'>
                        <div>
                            <h4>Average survey completion rate (%)</h4>
                        </div>

                        {filterable && (
                            <div>
                                <PulseSelect
                                    value={filterOptions.find(
                                        (x) => x.value === filter
                                    )}
                                    options={filterOptions}
                                    onChange={(e) => setFilter(e.value)}
                                    isSearchable={false}
                                />
                            </div>
                        )}

                        {!from && !to && (
                            <div>
                                <DateSelector
                                    onChange={(start, end) => {
                                        setStartDate(start);
                                        setEndDate(end);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <HorizontalBarChart data={chartData} />
                </Fragment>
            )}
        </div>
    );
}

SurveyCompletionRateWidget.propTypes = {
    take: PropTypes.number,
    filterable: PropTypes.bool,
    team: PropTypes.object,
    onRedirect: PropTypes.func,
    onRedirectTeam: PropTypes.func,
    from: PropTypes.object,
    to: PropTypes.object,
};
