import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { TeamSelector } from '../../../shared/components/TeamSelector';
import { Page } from '../../../shared/layout/Page';
import { useSelectedTeam } from '../../../shared/lib/useSelectedTeam';
import { DateSelector } from '../../../shared/utils/DateSelector';
import { ConnectionsWidget } from '../../../shared/widgets/ConnectionsWidget';
import { ExtremeFeedbackWidget } from '../../../shared/widgets/ExtremeFeedbackWidget';
import { FeedbackScoreWidget } from '../../../shared/widgets/FeedbackScoreWidget';
import { PulseIndexWidget } from '../../../shared/widgets/PulseIndexWidget';
import { SentimentScoreWidget } from '../../../shared/widgets/SentimentScoreWidget';
import { TopTopicsWidget } from '../../../shared/widgets/TopTopicsWidget';

export function MTPeerToPeer() {
    const { teamId } = useParams();

    const [dates, setDates] = useState(null);

    const { team, setTeam } = useSelectedTeam();

    const { state } = useLocation();

    useEffect(() => {
        if (teamId) {
            setTeam(teamId)
        }
        else if (state?.teamId) {
            setTeam(state?.teamId);
        }
    }, [state?.teamId]);

    return (
        <Page
            className='c-peer-to-peer'
            header={
                <div className='title-margin space-between'>
                    <h1>Peer Feedback</h1>
                    <TeamSelector className='margin-right' />
                </div>
            }>
            <Fragment>
                <div className='header'>
                    <label>Time Period:</label>
                    <DateSelector
                        onChange={(from, to, months) =>
                            setDates({ from, to, months })
                        }
                    />
                </div>

                {!!dates && !!team?.id && (
                    <div className='widgets'>
                        <FeedbackScoreWidget
                            from={dates.from}
                            to={dates.to}
                            months={dates.months}
                            teamId={team?.id}
                        />
                        <PulseIndexWidget
                            from={dates.from}
                            to={dates.to}
                            teamId={team?.id}
                        />
                        <ConnectionsWidget
                            from={dates.from}
                            to={dates.to}
                            months={dates.months}
                            teamId={team?.id}
                        />

                        <ExtremeFeedbackWidget
                            from={dates.from}
                            to={dates.to}
                            teamId={team?.id}
                        />
                        <SentimentScoreWidget
                            from={dates.from}
                            to={dates.to}
                            teamId={team?.id}
                        />
                        <TopTopicsWidget
                            from={dates.from}
                            to={dates.to}
                            teamId={team?.id}
                        />
                    </div>
                )}
            </Fragment>
        </Page>
    );
}
