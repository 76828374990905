import { Fragment, useEffect, useState } from 'react';
import { GenericModal } from '../modal/GenericModal';
import { IconButton } from './IconButton';
import { PropTypes } from 'prop-types';
import './inputs.scss';

export function IconButtonModal({
    route,
    type,
    success,
    message,
    confirmMessage,
    onConfirm,
    className,
    onClose,
}) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!open && onClose) {
            setOpen(false);
            onClose();
        }
    }, [open]);

    return (
        <Fragment>
            <IconButton
                type={type}
                className={className ? className : ''}
                onClick={() => setOpen(true)}
            />
            <GenericModal
                className='icon-button-generic-modal'
                route={route}
                open={open}
                success={success}
                confirmMessage={confirmMessage}
                message={message}
                loading={success === null || success === undefined}
                onConfirm={onConfirm}
                onClose={() => setOpen(false)}
            />
        </Fragment>
    );
}

IconButtonModal.propTypes = {
    route: PropTypes.string,
    type: PropTypes.string,
    success: PropTypes.bool,
    message: PropTypes.string,
    confirmMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onConfirm: PropTypes.func,
    className: PropTypes.string,
    onClose: PropTypes.func,
};
