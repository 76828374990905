import { Fragment, useEffect, useState } from 'react';
import {
    getOrganisationalFeedbackByOrganisation,
    getOrganisationalFeedbackByTopic,
} from '../../../services/organisationalFeedbackService';
import { ScoreChart, SentimentChart } from '../charts/RadialChartIcon';
import { DisplayRagValue } from '../inputs/DisplayRagValue';
import { useFormattedMessage } from '../lib/helper';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { WidgetTable } from './components/WidgetTable';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function OrganisationalFeedbackWidget({ from, to, userId }) {
    const limit = 3;

    const [volume, setVolume] = useState(null);

    const [firstTable, setFirstTable] = useState([]);

    const [secondTable, setSecondTable] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            let [org, topics] = await Promise.all([
                getOrganisationalFeedbackByOrganisation(userId, from, to),
                getOrganisationalFeedbackByTopic(userId, from, to),
            ]);

            let vol =
                org && org.length > 0
                    ? org.reduce((x, y) => x + (y.volume || 0), 0)
                    : 0;

            setVolume(vol);
            setFirstTable(fillArray(org));
            setSecondTable(fillArray(topics));
            setLoading(false);
        }

        if (from && to) {
            fetchData();
        }
    }, [from, to, userId]);

    function fillArray(array) {
        let result = [...array.slice(0, limit)];

        while (result.length < limit) {
            result.push({
                entityName: '',
                volume: null,
                sentiment: null,
                score: null,
            });
        }

        return result;
    }

    return (
        <div className='organisational-feedback-widget'>
            <WidgetLayout
                information={useFormattedMessage(
                    'organisationalWidget.header.information'
                )}
                loading={loading}
            >
                <WidgetHeader
                    title={useFormattedMessage(
                        'organisationalWidget.header.title'
                    )}
                    route={''}
                />
                <hr role='none' aria-label='Content of Employee voice.' />
                <div className='first-table'>
                    <div className='volume'>
                        <DisplayRagValue
                            value={volume !== null ? volume : '-'}
                            color={'#F2F4F8'}
                            textColor={'black'}
                        />

                        <label>
                            {useFormattedMessage(
                                'organisationalWidget.body.volume'
                            )}
                        </label>
                    </div>
                    <WidgetTable
                        data={firstTable}
                        columns={[
                            {
                                Header: '',
                                accessor: (d) => d,
                                id: 'numeration',
                                width: 20,
                                Cell: (row) => (
                                    <p className='numeration'>
                                        {row.row.index + 1}.
                                    </p>
                                ),
                            },
                            {
                                Header: useFormattedMessage(
                                    'organisationalWidget.table.recipient'
                                ),
                                accessor: 'name',
                                Cell: (row) => (
                                    <p
                                        className='text-ellipsis'
                                        title={row.value}
                                    >
                                        {row.value}
                                    </p>
                                ),
                            },
                            {
                                Header: useFormattedMessage(
                                    'organisationalWidget.table.volume'
                                ),
                                accessor: 'volume',
                                headerClassName: 'text-center',
                                className: 'text-center numeration',
                                width: 75,
                                Cell: (row) => (
                                    <Fragment>
                                        {!isNaN(row.value) && row.value !== null
                                            ? row.value
                                            : '-'}
                                    </Fragment>
                                ),
                            },
                        ]}
                    />
                </div>

                <div className='second-table'>
                    <WidgetTable
                        data={secondTable}
                        columns={[
                            {
                                Header: '',
                                accessor: (d) => d,
                                id: 'numeration',
                                width: 20,
                                Cell: (row) => (
                                    <p className='numeration'>
                                        {row.row.index + 1}.
                                    </p>
                                ),
                            },
                            {
                                Header: useFormattedMessage(
                                    'organisationalWidget.table.topic'
                                ),
                                accessor: 'name',
                                Cell: (row) => (
                                    <p
                                        className='text-ellipsis'
                                        title={row.value}
                                    >
                                        {row.value}{' '}
                                    </p>
                                ),
                            },

                            {
                                Header: useFormattedMessage(
                                    'organisationalWidget.table.score'
                                ),
                                accessor: 'score',
                                headerClassName: 'text-center',
                                className: 'text-center',
                                width: 75,
                                Cell: (row) => (
                                    <Fragment>
                                        <ScoreChart
                                            className='radial-chart-in-table'
                                            score={row.value}
                                            showText={true}
                                        />
                                    </Fragment>
                                ),
                            },
                            {
                                Header: useFormattedMessage(
                                    'organisationalWidget.table.sentiment'
                                ),
                                accessor: 'sentiment',
                                headerClassName: 'text-center',
                                className: 'text-center',
                                width: 75,
                                Cell: (row) => (
                                    <Fragment>
                                        <SentimentChart
                                            className='radial-chart-in-table'
                                            sentiment={row.value}
                                            showText={true}
                                        />
                                    </Fragment>
                                ),
                            },
                            {
                                Header: useFormattedMessage(
                                    'organisationalWidget.table.volume'
                                ),
                                accessor: 'volume',
                                headerClassName: 'text-center',
                                className: 'text-center numeration',
                                width: 75,
                                Cell: (row) => (
                                    <Fragment>
                                        {!isNaN(row.value) && row.value !== null
                                            ? row.value
                                            : '-'}
                                    </Fragment>
                                ),
                            },
                        ]}
                    />
                </div>
            </WidgetLayout>
        </div>
    );
}

OrganisationalFeedbackWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    userId: PropTypes.string,
};
