import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

function redirectWithClear(route) {
    sessionStorage.clear();
    window.location = `${window.location.origin}/${route}`;
}

axios.interceptors.request.use(
    function (config) {
        if (config.url.indexOf('api/') === -1) {
            config.baseURL = `api/`;
        }

        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        if (response.status === 302) {
            window.location = `${window.location.origin}/api/Authentication/try-sign-in`;
        }

        return response;
    },
    function (error) {
        if (error?.response?.status === 403) {
            return Promise.reject(new Error('forbidden'));
        }
        if (error?.response?.status === 401) {
            if (error?.response?.data?.signIn) {
                redirectWithClear(
                    `api/Authentication/try-sign-in?url=${window.location.pathname}`
                );
            } else if (error?.response?.data?.signOut) {
                redirectWithClear(`api/Authentication/sign-out`);
            }
        } else {
            return Promise.reject(error);
        }
    }
);

async function globalRequest(type, route, body, query) {
    body = body || null;
    query = query || null;

    if (query) {
        for (let [key, value] of Object.entries(query).filter(
            (x) => x[1] instanceof Date || x[1] instanceof moment
        )) {
            query[key] = moment(value).toISOString();
        }
    }

    route = `${route}${
        query ? `?${qs.stringify(query, { allowDots: true })}` : ''
    }`;

    let request = {};
    try {
        request = await axios.request({
            method: type,
            url: route,
            data: body,
        });
        return request?.data;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export async function get(route, query) {
    return await globalRequest('get', route, null, query);
}

export async function put(route, body, query) {
    return await globalRequest('put', route, body, query);
}

export async function post(route, body, query) {
    return await globalRequest('post', route, body, query);
}

export async function patch(route, body, query) {
    return await globalRequest('patch', route, body, query);
}

export async function remove(route, query) {
    return await globalRequest('delete', route, null, query);
}

export async function downloadFile(route, fileName, query = null) {
    route = `${route}${
        query ? `?${qs.stringify(query, { allowDots: true })}` : ''
    }`;
    return await axios
        .request({
            url: route,
            method: 'GET',
            responseType: 'blob',
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
}
