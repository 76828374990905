import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NavigationHelper } from './NavigationHelper';
import './Breadcrumb.scss';

export function Breadcrumb(props) {
    const [display, setDisplay] = useState(true);

    return (
        <div
            role='region'
            aria-label='breadcrumb'
            className={`breadcrumb ${display ? '' : 'hide'}`}
        >
            <Routes>
                {props?.routes
                    .slice()
                    .reverse()
                    .map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            render={() => (
                                <div className='breadcrumb-item '>
                                    <NavigationHelper
                                        route={route}
                                        routes={props.routes}
                                        onHide={(hide) => setDisplay(hide)}
                                    />
                                </div>
                            )}
                        />
                    ))}
            </Routes>
        </div>
    );
}

Breadcrumb.propTypes = {
    routes: PropTypes.array.isRequired,
};
