import autosize from 'autosize';
import { Fragment, useState, useEffect, useRef } from 'react';
import {
    getTerms,
    getGoalsInProgress,
    updateGoal,
    deleteGoal,
    completeGoal,
    createGoal,
} from '../../../../../services/goalService';
import constants from '../../../../shared/constants';
import { MainContent } from '../../../../shared/layout/MainContent';
import { Loading } from '../../../../shared/loadings/Loading';
import { PoSModal } from './PoSModal';
import { PropTypes } from 'prop-types';
import '../Success.scss';

export function PoSComponent({ userId }) {
    const [goals, setGoals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [terms, setTerms] = useState([]);
    const [newGoals, setNewGoals] = useState([]);
    const [show, setShow] = useState(false);
    const [goalId, setGoalId] = useState(null);

    const domRef = useRef([]);

    useEffect(() => {
        loadData(userId);
    }, [userId]);

    useEffect(() => {
        if (goalId) {
            domRef.current[goalId].focus();
        }
    }, [goalId]);

    async function loadData(userId) {
        if (userId) {
            var promises = [getTerms(), getGoalsInProgress(userId)];

            Promise.all(promises).then((result) => {
                let terms = result[0].map((x) => ({
                    label: x.key,
                    value: x.value,
                }));
                let goals = result[1];

                setGoals([...goals]);
                setLoading(false);
                setTerms([...terms]);
            });
        }
    }

    function saveGoal(goalId) {
        var goal = goals.find((x) => x.id === goalId);

        updateGoalAsync(goal).then(() => {
            loadData(userId);
        });
    }

    function handleEdit(event, goalId) {
        setGoalId(goalId);

        if (!goalId) {
            let goals = newGoals;
            goals.find((x) => x.id === goalId).isActive = !goals.find(
                (x) => x.id === goalId
            ).isActive;

            setNewGoals([...goals]);
        } else {
            let newGoals = goals;
            newGoals.find((x) => x.id === goalId).isActive = !goals.find(
                (x) => x.id === goalId
            ).isActive;

            setGoals([...newGoals]);

            if (!newGoals.find((x) => x.id === goalId).isActive) {
                saveGoal(goalId);
            }
            textareaRows();
        }
    }

    async function handleDelete(event, goalId) {
        if (goalId) {
            await deleteGoal(goalId);
            await loadData(userId);
        } else {
            setNewGoals([]);
        }
    }

    function textareaRows() {
        autosize(document.querySelectorAll('textarea'));
    }

    function handleChange(event, goalId) {
        setGoalId(goalId);
        if (goalId) {
            let newGoals = goals;
            newGoals.find((x) => x.id === goalId).description =
                event.target.value;

            setGoals([...newGoals]);
        } else {
            let goals = newGoals;

            goals.find((x) => x.id === goalId).description = event.target.value;

            setNewGoals([...goals]);
        }
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.target.blur();
        }
    }

    function handleCreate() {
        setShow(true);
        // DRAG AND DROP OPTION
        //let newGoals = newGoals;
        //if (newGoals.length === 0) {
        //    var newGoal = {
        //        Description: '',
        //        isActive: true,
        //        Term: null,
        //        Id: null,
        //        User: userId

        //    }

        //    newGoals.unshift(newGoal);

        //    setState({
        //        newGoals: newGoals
        //    },
        //    () => {
        //        domRef[newGoal.id].focus();
        //    });
        //}
    }

    function handleComplete(goalId) {
        if (goalId) {
            completeGoal(goalId).then(() => {
                loadData(userId);
            });
        }
    }

    function updateGoalAsync(goal) {
        var passGoal = {
            Id: goal.id,
            Description: goal.description,
            Term: Number(goal.term),
        };

        return updateGoal(goal.id, passGoal);
    }

    function createGoalAsync(goal) {
        var passGoal = {
            UserId: goal.user,
            Description: goal.description,
            Term: Number(goal.term),
        };
        return createGoal(passGoal);
    }

    function handleOnBlur(event, goalId) {
        if (event.target.value) {
            handleEdit(event, goalId);
        } else if (!event.target.value && goalId) {
            event.target.focus();
            event.target.classList.add('invalid');
        } else {
            setNewGoals([]);
        }
    }

    function onDragStart(e, goalId) {
        if (!goalId) {
            goalId = 'null';
        }
        e.dataTransfer.setData('Text', goalId);
    }

    function onDragOver(e) {
        e.stopPropagation();
        e.preventDefault();
        e.target.classList.add('over');
    }

    function onDrop(e, term) {
        let goalId = e.dataTransfer.getData('Text');

        if (goalId === 'null' || !goalId) {
            let goal = newGoals[0];

            goal.term = term;

            setNewGoals([]);

            createGoalAsync(goal).then(() => {
                loadData(userId);
            });
        } else {
            let goal = goals.find((x) => x.id === goalId);

            goal.term = term;

            updateGoalAsync(goal).then(() => {
                loadData(userId);
            });
        }
        e.target.classList.remove('over');
    }

    function onDragEnter(event) {
        event.preventDefault();
        event.target.classList.add('over');
    }

    function onDragLeave(event) {
        event.preventDefault();
        event.target.classList.remove('over');
    }

    function renderGoalContainer(goal, index, ind) {
        return (
            <div
                key={`mc-${index}-${ind}`}
                className={`main-container ${ind === -1 ? 'new' : ''}`}
                draggable
                onDragStart={(e) => {
                    onDragStart(e, goal.id);
                }}
            >
                <div key={`tit-${index}-${ind}`} className='title'>
                    <i
                        key={`edit-${index}-${ind}`}
                        className='icon-bullet inline-icon '
                    ></i>
                    <textarea
                        ref={(ref) => (domRef.current[goal.id] = ref)}
                        key={`txtAr-${index}-${ind}`}
                        className={`input-description ${
                            goal.isActive ? '' : 'none'
                        }`}
                        onChange={(event) => {
                            handleChange(event, goal.id);
                        }}
                        onBlur={(event) => {
                            handleOnBlur(event, goal.id);
                        }}
                        onKeyPress={(event) => {
                            event.key === 'Enter' && handleKeyPress(event);
                        }}
                        value={goal.description}
                        maxLength={constants.maxLength}
                    >
                        {' '}
                    </textarea>
                    <div
                        key={`desc-${index}-${ind}`}
                        aria-label={goal.term}
                        className={`description ${goal.isActive ? 'none' : ''}`}
                    >
                        {goal.description}
                    </div>
                </div>
                <div key={`but-${index}-${ind}`} className='buttons'>
                    <i
                        tabIndex={0}
                        role='button'
                        key={`edit-${index}-${ind}`}
                        onClick={(event) => {
                            handleEdit(event, goal.id);
                        }}
                        title='Edit'
                        className={`inline-icon ${
                            goal.isActive ? 'icon-edit active' : 'icon-edit'
                        }`}
                    ></i>
                    {goal.Status === 1 ? (
                        <i
                            tabIndex={0}
                            role='button'
                            key={`complete-${index}`}
                            onClick={(event) => {
                                handleComplete(event, goal.id);
                            }}
                            title='Complete'
                            className='icon-complete inline-icon '
                        ></i>
                    ) : (
                        ''
                    )}
                    <i
                        tabIndex={0}
                        role='button'
                        key={`delete-${index}-${ind}`}
                        onClick={(event) => {
                            handleDelete(event, goal.id);
                        }}
                        title='Delete'
                        className='icon-delete inline-icon '
                    ></i>
                </div>
            </div>
        );
    }

    return (
        <MainContent
            title='My Picture of Success'
            addButton={(event) => {
                handleCreate(event);
            }}
        >
            <hr />
            <Fragment>
                <div className='picture-of-success-component'>
                    {loading ? (
                        <Loading />
                    ) : (
                        <Fragment>
                            <div className='goal-content'>
                                {newGoals.map((goal, index) =>
                                    renderGoalContainer(goal, index, -1)
                                )}
                            </div>

                            <div className='col-xs-12 '>
                                {terms.map((term, ind) => (
                                    <div
                                        key={`tt-${ind}`}
                                        className='term-title'
                                        onDragOver={(e) => {
                                            onDragOver(e);
                                        }}
                                        onDragEnter={(e) => onDragEnter(e)}
                                        onDragLeave={(e) => onDragLeave(e)}
                                        onDrop={(e) => onDrop(e, term.value)}
                                    >
                                        <label
                                            key={`lb-${ind}`}
                                            aria-label={term.label + ' section'}
                                        >
                                            {term.label}{' '}
                                        </label>
                                        <div className='goal-content'>
                                            {goals
                                                .filter(
                                                    (g) => g.term === term.value
                                                )
                                                .map((goal, index) =>
                                                    renderGoalContainer(
                                                        goal,
                                                        index,
                                                        ind
                                                    )
                                                )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Fragment>
                    )}
                </div>
                <PoSModal
                    show={show}
                    addGoal={(term, goal) => {
                        var passGoal = {
                            user: userId,
                            description: goal,
                            term: term,
                        };
                        createGoalAsync(passGoal).then(() => {
                            loadData(userId);
                        });
                    }}
                    handleClose={() => setShow(false)}
                />
            </Fragment>
        </MainContent>
    );
}

PoSComponent.propTypes = {
    userId: PropTypes.string,
};
