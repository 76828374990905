import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTip } from '../../../shared/components/Tip';
import { GuestFeedbackLayout } from './GuestFeedbackLayout';

export function GuestTips() {
    const getTip = useTip();

    const navigate = useNavigate();
    return (
        <GuestFeedbackLayout
            link={<Link onClick={() => navigate.goBack()}>Help</Link>}>
            <div className='guest-tips'>
                <i
                    className='icon icon-long-arrow-left'
                    onClick={() => navigate.goBack()}></i>
                <div className='grid-tips'>
                    {[0, 1, 2, 3].map((number, i) => (
                        <div key={i} className='random-tip'>
                            {getTip(number)}
                        </div>
                    ))}
                </div>
            </div>
        </GuestFeedbackLayout>
    );
}
