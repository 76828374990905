import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../shared/constants';
import { useAsyncError } from '../../shared/lib/helper';
import { useSession } from '../../shared/lib/useSession';
import { isAuthorized } from '../authentication/AuthControl';

export function Company() {
    const throwError = useAsyncError();

    const navigate = useNavigate();

    const session = useSession();

    useEffect(() => {
        if (isAuthorized('company::feedback', session?.permissions)) {
            navigate('/company/feedback/overview', { replace: true });
        }
        else if (isAuthorized('company::assess', session?.permissions)) {
            navigate('/company/assess', { replace: true });
        }
        else if (isAuthorized('company::pulse_settings', session?.permissions)) {
            navigate('/company/feedback/pulse', { replace: true });
        }
        else if ( isAuthorized('company::company_settings', session?.permissions)) {
            navigate('/admin', { replace: true });
        }
    })

    if (isAuthorized('company::feedback', session?.permissions)) {
        return <div></div>;
        
    } else if (isAuthorized('company::assess', session?.permissions)) {
        return <div></div>;

    } else if (isAuthorized('company::pulse_settings', session?.permissions)) {
        return <div></div>;

    } else if (isAuthorized('company::company_settings', session?.permissions)) {
        return <div></div>;
    } else {
        return (
            <Fragment>{throwError(constants.errorCodes.forbidden)}</Fragment>
        );
    }
}
