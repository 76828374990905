import { useRef } from 'react';
import { PropTypes } from 'prop-types';
import './inputs.scss';

export function AvatarImage({ image, onChange, className }) {
    function handleOnChange(e) {
        let file = e.target.files[0];

        getBase64(file, onChange);
    }

    const inputRef = useRef(null);

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result, reader.result.split(',')[1], file.name);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
        <div className={'avatar-image-comp ' + (className ? className : '')}>
            <div className='avatar-upload'>
                <div className='avatar-edit'>
                    <input
                        tabIndex={0}
                        type='file'
                        ref={inputRef}
                        accept='.png, .jpg, .jpeg'
                        onChange={handleOnChange}
                        onKeyPress={(e) => {
                            e.key === 'Event' && handleOnChange;
                        }}
                    />
                </div>
                <div className='avatar-preview'>
                    <div
                        className='overlay'
                        onClick={() =>
                            inputRef?.current
                                ? inputRef?.current?.click()
                                : void 0
                        }
                    >
                        <i className='icon-camera'> </i>
                    </div>
                    {image ? (
                        <img alt='Team logo' src={image} />
                    ) : (
                        <i className='icon icon-my-team'></i>
                    )}
                </div>
            </div>
        </div>
    );
}

AvatarImage.propTypes = {
    image: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
};
