import { Fragment, useEffect, useState } from 'react';
import { getSurveyCompletionRate } from '../../../../../services/surveyCompletionRateService';
import {
    getScheduledSurveys,
    SURVEY_STATUS_TYPE,
} from '../../../../../services/surveyService';
import './components.scss';
import { WGroupIconChart } from './WGroupIconChart';
import { WLayout } from './WLayout';
import { PropTypes } from 'prop-types';

export function WPulseRate({ teamId, dates, isTeam }) {
    const [loading, setLoading] = useState(true);

    const [rates, setRates] = useState(null);

    const [companyRate, setCompanyRate] = useState(null);

    const [closedSurvey, setClosedSurvey] = useState(null);

    const [activeSurvey, setActiveSurvey] = useState(null);

    const [loadingSurveys, setLoadingSurveys] = useState(true);

    useEffect(() => {
        async function fetch() {
            setLoading(true);

            let rate = await getSurveyCompletionRate(
                dates.from,
                dates.to,
                teamId
            );

            setRates(
                rate
                    .filter((x) => x.type === 1)
                    .sort((x, y) => x.rate - y.rate)
                    .slice(0, 3)
            );

            setCompanyRate(rate.find((x) => x.type === 2));

            setLoading(false);
        }

        if (dates && ((isTeam && teamId) || !isTeam)) {
            fetch();
        }
    }, [dates, teamId]);

    useEffect(() => {
        async function getSurveys() {
            setLoadingSurveys(true);

            let [active, closed] = await Promise.all([
                getScheduledSurveys(
                    dates?.from,
                    dates?.to,
                    'releaseDate.asc',
                    SURVEY_STATUS_TYPE.Active,
                    teamId
                ),
                getScheduledSurveys(
                    dates?.from,
                    dates?.to,
                    'releaseDate.desc',
                    SURVEY_STATUS_TYPE.Closed,
                    teamId
                ),
            ]);
            setLoadingSurveys(false);

            setActiveSurvey(active?.length ? active[0] : null);

            setClosedSurvey(closed?.length ? closed[0] : null);
        }
        if ((!isTeam || (isTeam && teamId)) && dates?.from && dates?.to) {
            getSurveys();
        }
    }, [teamId, dates]);

    return (
        <WLayout
            info={
                !isTeam ? (
                    <Fragment>
                        <ul>
                            <li>
                                Company &ndash; The average percentage of
                                completion of surveys across the organisation
                                during the selected time period.
                            </li>
                            <li>
                                Current active survey &ndash; The current
                                completion rate of your organisation{"'"}s most
                                recent open survey.
                            </li>
                            <li>
                                Last closed survey &ndash; The completion rate
                                of your latest closed survey.
                            </li>
                        </ul>
                    </Fragment>
                ) : (
                    <Fragment>
                        <ul>
                            <li>
                                Team &ndash; The average percentage of
                                completion of surveys in your team during the
                                selected time period.
                            </li>
                            <li>
                                Company &ndash; The average percentage of
                                completion of surveys across the organisation
                                during the selected time period.
                            </li>
                            <li>
                                Current active survey &ndash; The current
                                completion rate of your organisations most
                                recent open survey.
                            </li>
                            <li>
                                Last closed survey &ndash; The completion rate
                                of your latest closed survey.
                            </li>
                        </ul>
                    </Fragment>
                )
            }
            title={'Pulse Surveys'}
            loading={loading || loadingSurveys}
            to={{
                pathname: `/${teamId ? 'my-team' : 'company'}/feedback/pulse`,
            }}
            state={teamId ?? null}
            className='w-pulse-rate'
        >
            <Fragment>
                <div className='team-group'>
                    {teamId && rates?.length && (
                        <WGroupIconChart
                            label={'Team'}
                            type={'pulse'}
                            value={rates[0]?.rate}
                        />
                    )}

                    <WGroupIconChart
                        className={isTeam ? 'is-team' : ''}
                        label={'Company'}
                        type={'pulse'}
                        value={companyRate?.rate}
                    />
                </div>

                <WGroupIconChart
                    label={'Current active survey'}
                    type={'pulse'}
                    value={
                        isTeam
                            ? typeof activeSurvey?.teamInsights?.find(
                                  (x) => x.team?.id === teamId
                              )?.completionRate === 'number'
                                ? Number(
                                      activeSurvey?.teamInsights
                                          ?.find((x) => x.team?.id === teamId)
                                          ?.completionRate?.toFixed(0)
                                  )
                                : null
                            : typeof activeSurvey?.insight?.completionRate ===
                              'number'
                            ? Number(
                                  activeSurvey?.insight?.completionRate.toFixed(
                                      0
                                  )
                              )
                            : null
                    }
                />

                <WGroupIconChart
                    label={'Last closed survey'}
                    type={'pulse'}
                    value={
                        isTeam
                            ? typeof closedSurvey?.teamInsights?.find(
                                  (x) => x.team?.id === teamId
                              )?.completionRate === 'number'
                                ? Number(
                                      closedSurvey?.teamInsights
                                          ?.find((x) => x.team?.id === teamId)
                                          ?.completionRate?.toFixed(0)
                                  )
                                : null
                            : typeof closedSurvey?.insight?.completionRate ===
                              'number'
                            ? Number(
                                  closedSurvey?.insight?.completionRate.toFixed(
                                      0
                                  )
                              )
                            : null
                    }
                />
            </Fragment>
        </WLayout>
    );
}

WPulseRate.propTypes = {
    teamId: PropTypes.string,
    dates: PropTypes.object,
    isTeam: PropTypes.bool,
};
