import { get, put } from '../components/shared/lib/axios';

var controllerRoute = 'notifications';

export async function getUserNotifications() {
    return get(`${controllerRoute}/`);
}

export async function readNotification(notificationId) {
    return put(`${controllerRoute}/${notificationId}/read`);
}

export async function getNotificationSettingsType() {
    return get(`/types/notification/settings`);
}
