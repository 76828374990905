import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from './components/shared/error/ErrorBoundary';
import { SessionProvider } from './contexts/SessionContext';
import { createRoot } from 'react-dom/client'
import App from './App';
import locale_en from './lang/en.json';
import './styles/main.scss';
import './assets/fonts/icon/style.scss';
import './assets/fonts/text/fonts.css';
import './assets/fonts/text/lato/latofonts.css';
import 'regenerator-runtime/runtime';
import 'core-js/stable';

const rootElement = createRoot(document.getElementById('root'));

const data = {
    en: locale_en
};

rootElement.render(
    <BrowserRouter>
        <IntlProvider locale='en' defaultLocale='en' messages={data['en']}>
            <ErrorBoundary>
                <SessionProvider>
                    <App />
                </SessionProvider>
            </ErrorBoundary>
        </IntlProvider>
    </BrowserRouter>
);
