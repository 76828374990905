import { PropTypes } from 'prop-types';

export function Box({ title, children }) {
    return (
        <div className='page-box-container'>
            {!!title && <h2 className='page-box-title'>{title}</h2>}
            {!!children && children}
        </div>
    );
}

Box.propTypes = {
    title: PropTypes.string,
    children: PropTypes.object,
};
