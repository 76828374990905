import { get } from '../components/shared/lib/axios';

const controllerRoute = 'widgets';

export async function getWidgetSentimentScore(from, to) {
    return get(`${controllerRoute}/sentiment-score`, { from, to });
}

export async function getWidgetSentimentScoreForUser(
    userId,
    from,
    to,
    received
) {
    return get(`${controllerRoute}/users/${userId}/sentiment-score`, {
        from,
        to,
        received,
    });
}

export async function getWidgetSentimentScoreForTeam(teamId, from, to) {
    return get(`${controllerRoute}/teams/${teamId}/sentiment-score`, {
        from,
        to,
    });
}

export async function getWidgetSentimentScoreByEntity(from, to, limit, desc) {
    return get(`${controllerRoute}/sentiment-score/entities`, {
        from,
        to,
        desc,
        limit,
    });
}

export async function getWidgetSentimentScoreByEntityForTeam(
    teamId,
    from,
    to,
    limit,
    desc
) {
    return get(`${controllerRoute}/teams/${teamId}/sentiment-score/entities`, {
        from,
        to,
        desc,
        limit,
    });
}
