import { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import './utils.scss';

export function DragNDrop({ className, multiple, onChange, uploadedFiles }) {
    const inputFile = useRef(null);

    const [files, setFiles] = useState(null);

    const [filesUploaded, setFilesUploaded] = useState([]);

    async function handleOnDrop(event) {
        handleClassAndPrevention(event, 'remove');

        await handleFiles(event.dataTransfer.files);
    }

    useEffect(() => {
        if (!uploadedFiles) {
            removeFiles();
        }
    }, [uploadedFiles]);

    async function handleFiles(files) {
        //TODO: MULTIPLE DND
        setFiles(Array.from(files));
        let file = files[0];
        //

        let result = await onChange(file);

        setFilesUploaded([{ name: file.name, index: 0, error: result.error }]);
    }

    function removeFile(file) {
        inputFile.current.value = '';

        setFiles([...files.filter((x) => x.name !== file.name)]);

        setFilesUploaded([
            ...filesUploaded.filter((x) => x.name !== file.name),
        ]);
    }

    function removeFiles() {
        inputFile.current.value = '';

        setFiles([]);

        setFilesUploaded([]);
    }

    function handleClassAndPrevention(event, operation) {
        event.preventDefault();
        event.stopPropagation();

        switch (operation) {
            case 'add':
                if (!event.target.classList.contains('over')) {
                    event.target.classList.add('over');
                }
                break;
            default:
                if (event.target.classList.contains('over')) {
                    event.target.classList.remove('over');
                }
                break;
        }
    }

    return (
        <div className='drag-n-drop'>
            <div
                className={`drag-n-drop-container ${
                    className ? className : ''
                }`}
                onDragOver={(e) => handleClassAndPrevention(e, 'add')}
                onDrop={handleOnDrop}
                onDragEnter={(e) => handleClassAndPrevention(e, 'add')}
                onDragLeave={(e) => handleClassAndPrevention(e, 'remove')}
            >
                <div className='box-input'>
                    <input
                        ref={inputFile}
                        onChange={(e) => handleFiles(e.target.files)}
                        className='box-file'
                        type='file'
                        name='files[]'
                        data-multiple-caption={
                            multiple ? '{count} files selected' : ''
                        }
                        multiple={!!multiple}
                    />

                    <i className='icon icon-action-send'> </i>
                    <label htmlFor='file'>
                        <a onClick={() => inputFile.current.click()}>
                            Choose a file
                        </a>
                        <span className='box-dragndrop'> or drag it here</span>.
                    </label>
                </div>
            </div>

            <div className={`file-container`}>
                {filesUploaded.map((file, i) => (
                    <FileUploaded
                        key={`file-uploaded-${i}`}
                        file={file}
                        removeFile={removeFile}
                    />
                ))}
            </div>
        </div>
    );
}

DragNDrop.propTypes = {
    className: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    uploadedFiles: PropTypes.object,
};

function FileUploaded({ file, removeFile }) {
    return (
        <div className={`file ${file.error ? 'error' : ''}`}>
            <div className='body'>{file.name}</div>
            <div className='remove' onClick={() => removeFile(file)}>
                <i className='icon icon-close'></i>{' '}
            </div>
            <div className='error'>
                <p>{file.error}</p>
            </div>

            {/*TODO PROGRESS BAR*/}
            <div className='progress-bar'> </div>
        </div>
    );
}

FileUploaded.propTypes = {
    file: PropTypes.object,
    removeFile: PropTypes.func,
};
