import moment from 'moment';
import { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { getPPRTopicsParents } from '../../../../../services/pprTopicService';
import { AssessTopicBox } from './AssessTopicBox';
import { Page } from '../../../../shared/layout/Page';

export function AssessTopicsBoxes() {
    const [loading, setLoading] = useState(true);
    const [pprTopics, setPprTopics] = useState([]);
    const [filter, setFilter] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);

    function actionClick(topic) {
        setRedirectUrl('/company/assess/actions-topic');
        setFilter({
            StartDate: moment().add(-30, 'days').toISOString(),
            EndDate: moment().toISOString(),
            ParentTopicId: topic.id,
        });
    }

    function scoresClick(topic) {
        setRedirectUrl(`/company/assess/ppr-historic`);
        setFilter({
            StartDate: moment().add(-30, 'days').toISOString(),
            EndDate: moment().toISOString(),
            PPRTopic: topic,
        });
    }

    function renderRedirect(redirectUrl, filter) {
        return redirectUrl ? (
            <Navigate to={redirectUrl} state={filter} />
        ) : (
            <Fragment> </Fragment>
        );
    }

    useEffect(() => {
        async function fetch() {
            let [top] = await Promise.all([getPPRTopicsParents()]);

            setPprTopics(top);

            setLoading(false);
        }

        fetch();
    }, []);

    return (
        <Page loading={loading}>
            <div className='assess-boxes-container'>
                {renderRedirect(redirectUrl, filter)}

                {pprTopics.map((topic, index) => (
                    <AssessTopicBox
                        key={index}
                        topic={topic}
                        actionClick={actionClick}
                        scoresClick={scoresClick}
                    />
                ))}
            </div>
        </Page>
    );
}
