import { createContext, useReducer } from 'react';
import { PropTypes } from 'prop-types';

const initialState = {
    open: false,
    success: null,
    confirmMessage: '',
    message: '',
    onConfirm: () => void 0,
    refresh: 0,
};
export const SurveyContext = createContext(initialState);

export const SurveyActionTypes = {
    CLOSE: 'close',
    DELETE: 'delete',
    SUCCESS: 'success',
    RESET: 'reset',
};

export function SurveyProvider({ children }) {
    const { Provider } = SurveyContext;
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case SurveyActionTypes.CLOSE: {
                return {
                    ...state,
                    open: true,
                    success: null,
                    confirmMessage: 'Do you want to close the survey?',
                    message: 'Survey closed',
                    onConfirm: () => action.payload.onConfirm(),
                };
            }
            case SurveyActionTypes.DELETE:
                return {
                    ...state,
                    open: true,
                    success: null,
                    confirmMessage: 'Do you want to delete the survey?',
                    message: 'Survey deleted',
                    onConfirm: () => action.payload.onConfirm(),
                };
            case SurveyActionTypes.SUCCESS:
                return {
                    ...state,
                    success: action.payload.success,
                    refresh: state?.refresh + 1,
                };
            case SurveyActionTypes.RESET:
                return { ...initialState };

            default:
                break;
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
}

SurveyProvider.propTypes = {
    children: PropTypes.object,
};
