import moment from 'moment';
import { Fragment, useContext, useEffect, useState } from 'react';
import { getScheduledSurveys } from '../../../services/surveyService';
import { GenericModal } from '../modal/GenericModal';
import { SurveyBox } from './components/SurveyBox';
import { PropTypes } from 'prop-types';
import {
    SurveyActionTypes,
    SurveyContext,
} from '../../pages/admin/surveys/SurveyContext';
import { Loading } from '../loadings/Loading';
import './Surveys.scss';

export function Surveys({ dates }) {
    const [loading, setLoading] = useState(false);
    const [surveys, setSurveys] = useState([]);
    const [openTimer, setOpenTimer] = useState(0);
    const { state, dispatch } = useContext(SurveyContext);

    useEffect(() => {
        async function fetchSurveys() {
            if (isFetching) {
                let s = await getScheduledSurveys(
                    dates?.from,
                    dates?.to,
                    'releaseDate.asc'
                );
                setSurveys(s);
            }

            setLoading(false);
        }

        if (
            dates?.from &&
            dates?.to &&
            moment(dates?.from).isValid() &&
            moment(dates?.to).isValid()
        ) {
            var isFetching = true;
            if (openTimer) {
                clearTimeout(openTimer);
            }
            setLoading(true);
            setOpenTimer(setTimeout(() => fetchSurveys(), 1000));
        }

        return () => {
            isFetching = false;
        };
    }, [dates, state?.refresh]);

    return (
        <div className='new-surveys' data-testid='surveys'>
            <div className='container'>
                {loading ? (
                    <Fragment>
                        <Loading />
                    </Fragment>
                ) : (
                    <Fragment>
                        {surveys?.map((s, i) => (
                            <Fragment key={i}>
                                {(i == 0 ||
                                    !moment(surveys[i - 1]?.releaseDate).isSame(
                                        s?.releaseDate,
                                        'day'
                                    )) && (
                                    <Fragment>
                                        <h3>
                                            {moment(s?.releaseDate).format(
                                                'DD ddd'
                                            )}
                                        </h3>
                                        <hr />
                                    </Fragment>
                                )}
                                <SurveyBox survey={s} withModified />
                            </Fragment>
                        ))}
                        {!surveys?.length && <h4>No surveys found</h4>}
                    </Fragment>
                )}

                <GenericModal
                    className='icon-button-generic-modal'
                    open={state?.open}
                    success={state?.success}
                    confirmMessage={state?.confirmMessage}
                    message={state?.message}
                    loading={
                        state?.success === null || state?.success === undefined
                    }
                    onConfirm={state?.onConfirm}
                    onClose={() => dispatch({ type: SurveyActionTypes.RESET })}
                />
            </div>
        </div>
    );
}

Surveys.propTypes = {
    dates: PropTypes.object,
};
