import { useEffect, useState } from 'react';

export function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [matches, query]);

    return matches;
}

export function useMediaQueries() {
    const breakpoints = {
        extraSmallDown: '575.98px',
        extraSmall: ' 576px',
        smallDown: ' 767.98px',
        small: '768px',
        mediumDown: ' 991.98px',
        medium: '992px',
        largeDown: ' 1199.98px',
        large: '1200px'
    };

    const extraSmallOnly = useMediaQuery(
        `(max-width: ${breakpoints?.extraSmallDown})`
    );

    const smallOnly = useMediaQuery(
        `(min-width: ${breakpoints?.extraSmall}) and (max-width: ${breakpoints?.smallDown})`
    );

    const mediumOnly = useMediaQuery(
        `(min-width: ${breakpoints?.small}) and (max-width: ${breakpoints?.mediumDown})`
    );

    const largeOnly = useMediaQuery(
        `(min-width: ${breakpoints?.medium}) and (max-width: ${breakpoints?.largeDown})`
    );

    const extraLargeOnly = useMediaQuery(`(min-width: ${breakpoints?.large})`);

    const ExtraSmallDown = useMediaQuery(
        `(max-width: ${breakpoints?.extraSmallDown})`
    );
    const smallDown = useMediaQuery(`(max-width: ${breakpoints?.smallDown})`);
    const mediumDown = useMediaQuery(`(max-width: ${breakpoints?.mediumDown})`);
    const largeDown = useMediaQuery(`(max-width: ${breakpoints?.largeDown})`);

    const smallUp = useMediaQuery(`(min-width: ${breakpoints?.extraSmall})`);
    const mediumUp = useMediaQuery(`(min-width: ${breakpoints?.small})`);
    const largeUp = useMediaQuery(`(min-width: ${breakpoints?.medium})`);
    const extraLargeUp = useMediaQuery(`(min-width: ${breakpoints?.large})`);

    return {
        extraSmallOnly,
        smallOnly,
        mediumOnly,
        largeOnly,
        extraLargeOnly,
        ExtraSmallDown,
        smallDown,
        mediumDown,
        largeDown,
        smallUp,
        mediumUp,
        largeUp,
        extraLargeUp
    };
}
