import { GuestFeedback } from './components/pages/external/guest-feedback/GuestFeedback';
import { GuestTips } from './components/pages/external/guest-feedback/GuestTips';
import { InviteError } from './components/pages/external/invite/InviteError';
import { InviteSuccess } from './components/pages/external/invite/InviteSuccess';
import { ValidateInvitation } from './components/pages/external/invite/ValidateInvitation';

const externalRoutes = [
    {
        path: '/ext/invited/success',
        key: '/ext/invited/success',
        exact: true,
        element: <InviteSuccess push />
    },
    {
        path: '/ext/invited/error',
        key: '/ext/invited/error',
        exact: true,
        element: <InviteError push />
    },
    {
        path: '/ext/validate',
        key: '/ext/validate',
        exact: true,
        element: <ValidateInvitation push />
    },
    {
        path: '/ext/guest-feedback',
        key: '/ext/guest-feedback',
        exact: true,
        element: <GuestFeedback push />
    },
    {
        path: '/ext/guest-feedback/tips',
        key: '/ext/guest-feedback/tips',
        exact: true,
        element: <GuestTips push />
    }
];

export default externalRoutes;
