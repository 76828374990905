import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppActionTypes, AppContext } from '../../../contexts/AppContext';
import { getDateFilterTypes } from '../../../services/surveyService';
import { useAsyncError } from '../lib/helper';
import { PropTypes } from 'prop-types';
import './utils.scss';

export function DateSelector({ onChange, months, autoFocus }) {
    const [dates, setDates] = useState(null);

    const [types, setTypes] = useState([]);

    const [selected, setSelected] = useState(null);

    const throwError = useAsyncError();

    const location = useLocation();

    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        async function fetchData() {
            try {
                let t = await getDateFilterTypes();
                setTypes(t);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (dates) onChange(dates.from, dates.to, selected);
    }, [dates]);

    useEffect(() => {
        if (selected) {
            setDates({
                from: moment.utc().add(-selected, 'months'),
                to: moment.utc(),
            });

            dispatch({
                type: AppActionTypes.UPDATE,
                prop: 'selectedMonths',
                payload: selected,
            });
        }
    }, [selected]);

    useEffect(() => {
        if (location && types?.length) {
            let match = location.pathname.match(/\/(.*?)\//)[1];

            if (
                state?.currentLocation === match &&
                typeof state?.selectedMonths === 'number'
            ) {
                setSelected(state?.selectedMonths);
            } else {
                setSelected(
                    typeof months === 'number' ? months : types[0].value
                );
            }

            dispatch({
                type: AppActionTypes.UPDATE,
                prop: 'currentLocation',
                payload: match,
            });
        }
    }, [location, types, months]);

    return (
        <div className='date-selector'>
            <div
                role='region'
                aria-label='Time period selector'
                className='buttons'
            >
                {types.map((x, i) => (
                    <button
                        role='button'
                        aria-label={
                            x.key[0] +
                            (x.key[1] == 'M' ? ' month filter' : 'year filter')
                        }
                        aria-pressed={selected === x.value}
                        key={`btn-${i}`}
                        className={`btn ${
                            selected === x.value ? 'highlighted' : ''
                        }`}
                        onClick={() => setSelected(x.value)}
                        autoFocus={autoFocus && i === 0}
                    >
                        {x.key}
                    </button>
                ))}
            </div>
        </div>
    );
}

DateSelector.propTypes = {
    onChange: PropTypes.func,
    months: PropTypes.any,
    autoFocus: PropTypes.bool,
};
