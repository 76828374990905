import { useState } from 'react';
import { useRagIntervals } from '../../../../lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import { renderLikertAnswer } from '../../../../lib/helper';
import constants from '../../../../constants';
import './LikertDistributionChart.scss';

export function LikertDistributionChart({ data, legend, onClick, isAnswered }) {
    const [selected, setSelected] = useState(null);

    function handleClick(value) {
        value = selected === value?.key ? null : value?.key;
        setSelected(value);
        onClick(value);
    }

    return (
        <div className='chart'>
            <div className='likert-chart'>
                {data?.map((d, i) => {
                    return !isAnswered ? (
                        <div
                            key={i}
                            className='bar-data'
                            style={{
                                width: '20%',
                                backgroundColor: d.color,
                            }}
                        >
                            <p className='percent-text'>{'0%'}</p>
                        </div>
                    ) : (
                        d.value != 0 && (
                            <div
                                key={i}
                                className={`bar-data ${
                                    selected && selected !== d?.key
                                        ? 'disabled'
                                        : ''
                                }`}
                                style={{
                                    width: `${d.value + 1}%`,
                                    backgroundColor: d.color,
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleClick(d)}
                            >
                                <p className='percent-text'>{`${d.value}%`}</p>
                            </div>
                        )
                    );
                })}
            </div>
            {legend && <LikertDistributionLegend />}
        </div>
    );
}

LikertDistributionChart.propTypes = {
    data: PropTypes.array,
    legend: PropTypes.bool,
    onClick: PropTypes.func,
    isAnswered: PropTypes.bool,
};

export function LikertDistributionLegend() {
    const fields = [
        constants.Likert.StronglyDisagree,
        constants.Likert.Disagree,
        constants.Likert.Neutral,
        constants.Likert.Agree,
        constants.Likert.StronglyAgree,
    ];

    const { getColor } = useRagIntervals();
    return (
        <div className='legend'>
            {fields?.map((f, i) => {
                return (
                    <div key={i} className='legend-box'>
                        <div
                            className='field-box'
                            style={{
                                backgroundColor: getColor(
                                    f,
                                    constants.QuestionType.Likert
                                ),
                            }}
                        ></div>
                        <span className='field-text'>
                            {renderLikertAnswer(f)}
                        </span>
                    </div>
                );
            })}
        </div>
    );
}
