import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';

export function IconButton({
    route,
    type,
    onClick,
    className,
    title,
    style,
    children,
}) {
    const navigate = useNavigate();

    className = className
        ? `${className} icon icon-button `
        : 'icon icon-button ';
    var innerTitle = '';

    switch (type) {
        case 'view':
            className += 'icon-insight';
            innerTitle = 'View';
            break;
        case 'edit':
            className += 'icon-edit';
            innerTitle = 'Edit';
            break;
        case 'close':
            className += 'icon-complete';
            innerTitle = 'Close';
            break;
        case 'delete':
            className += 'icon-delete';
            innerTitle = 'Delete';
            break;
        case 'share':
            className += 'icon-respond-active';
            innerTitle = 'Send';
            break;
        case 'add':
            className += 'icon-add';
            innerTitle = 'Add';
            break;
        case 'inside':
            className += 'icon-add-square';
            innerTitle = 'Inside';
            break;
        default:
            throw new Error('Cannot find this type');
    }

    return (
        <Fragment>
            <i
                role='button'
                tabIndex={0}
                title={title ?? innerTitle}
                className={className}
                onClick={() => (onClick ? onClick() : navigate(route))}
                style={style ? style : {}}
                aria-label={`${type}`}
            >
                {children}
            </i>
        </Fragment>
    );
}

IconButton.propTypes = {
    route: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.string,
    children: PropTypes.object,
};
