import { forwardRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import Select from 'react-select';
import './inputs.scss';

function PSelect(props, ref) {
    const [, setAriaFocusMessage] = useState('');
    const [, setIsMenuOpen] = useState(false);

    const onFocus = ({ focused, isDisabled }) => {
        const msg = `You are currently focused on option ${focused.label}${
            isDisabled ? ', disabled' : ''
        }`;
        setAriaFocusMessage(msg);
        return msg;
    };

    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);

    return (
        <Select
            {...props}
            ref={ref}
            className={`react-select ${props?.className ?? ''}`}
            classNamePrefix={'react-select'}
            ariaLiveMessages={{
                onFocus,
            }}
            aria-labelledby='aria-label'
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
        />
    );
}

export const PulseSelect = forwardRef(PSelect);

PSelect.propTypes = {
    className: PropTypes.string,
};
