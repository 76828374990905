import { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import constants from '../constants';

export function useRagIntervals() {
    const { state } = useContext(AppContext);

    function sortedDescendent() {
        return state?.ragIntervals?.sort((x, y) => y.minValue - x.minValue);
    }

    function sortedAscendent() {
        return state?.ragIntervals?.sort((x, y) => x.minValue - y.minValue);
    }

    function getColor(value, questionType) {
        let hexColor = constants.Colors.Default;

        if (value == null) return hexColor;

        switch (questionType) {
            case constants.QuestionType.Score:
                value = Math.floor(value);

                hexColor = state?.ragIntervals?.find(
                    (x) => x.minValue <= value && x.maxValue >= value
                )?.hexColor;
                break;

            case constants.QuestionType.Suggestion:
                value = Math.floor(value / 10);

                hexColor = state?.ragIntervals?.find(
                    (x) => x.minValue <= value && x.maxValue >= value
                )?.hexColor;
                break;

            case constants.QuestionType.YesNo:
                if (value) hexColor = constants.Colors.Yes;
                else hexColor = constants.Colors.No;
                break;

            case constants.QuestionType.Likert:
                if (value === constants.Likert.StronglyDisagree)
                    hexColor = constants.Colors.StronglyDisagree;
                else if (value === constants.Likert.Disagree)
                    hexColor = constants.Colors.Disagree;
                else if (value === constants.Likert.Neutral)
                    hexColor = constants.Colors.Neutral;
                else if (value === constants.Likert.Agree)
                    hexColor = constants.Colors.Agree;
                else if (value === constants.Likert.StronglyAgree)
                    hexColor = constants.Colors.StronglyAgree;
                break;

            case constants.QuestionType.Choice:
                hexColor = choiceArray[value - 11];
                break;

            default:
                value = Math.floor(value / 10);

                hexColor = state?.ragIntervals?.find(
                    (x) => x.minValue <= value && x.maxValue >= value
                )?.hexColor;
                break;
        }

        return hexColor;
    }

    function getColorArray(array) {
        let hexColor = constants.Colors.Default;

        let colors = array.map((element) => {
            if (!isNaN(element) && element !== null) {
                if (element > 10) {
                    element = element / 10;
                }

                let interval = state?.ragIntervals.find(
                    (x) =>
                        x.minValue <= Math.floor(element) &&
                        Math.floor(element) <= x.maxValue
                );

                if (interval) {
                    hexColor = interval.hexColor;
                }
            }
            return hexColor;
        });

        return colors;
    }

    function getHighestRag() {
        let rag = state?.ragIntervals?.sort((a, b) =>
            a.minValue > b.minValue ? -1 : 1
        )[0];
        return rag;
    }

    function getLowestRag() {
        let rag = state?.ragIntervals?.sort((a, b) =>
            a.minValue > b.minValue ? 1 : -1
        )[0];
        return rag;
    }

    function getRandomColor() {
        var letters = '0123456789ABCDEF';

        var color = '#';

        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    }

    function getRandomColorArray(length) {
        let array = [];

        for (let i = 0; i < length; i++) {
            array.push(this.getRandomColor());
        }

        return array;
    }

    function hexToRgbA(hex, opacity) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
                ','
            )},${opacity ? opacity : '0.7'})`;
        }
        return constants.Colors.Default;
    }

    function getRandomColorArrayForRGBA(length) {
        let array = [];

        for (let i = 0; i < length; i++) {
            array.push(this.hexToRgbA(this.getRandomColor()));
        }

        return array;
    }

    function getPredefinedColors() {
        return [
            '#ED3241',
            '#40B2FF',
            '#7FD321',
            '#FFAB21',
            '#CC33CC',
            '#FF6666',
            '#999999',
            '#66CC99',
            '#FF9966',
            '#FF66CC',
            '#628395',
            '#262A53',
            '#FFA0A0',
            '#7952B3',
            '#480032',
            '#F55C47',
            '#7B6079',
            '#1F441E',
        ];
    }

    const anonymousArray = [
        '#ED3241',
        '#40B2FF',
        '#7FD321',
        '#FFAB21',
        '#CC33CC',
        '#FF6666',
        '#999999',
        '#66CC99',
        '#FF9966',
        '#FF66CC',
        '#628395',
        '#262A53',
        '#FFA0A0',
        '#7952B3',
        '#480032',
        '#F55C47',
        '#7B6079',
        '#1F441E',
    ];

    const choiceArray = [
        '#FF0F0F',
        '#46B4B4',
        '#8420A6',
        '#78823A',
        '#F29100',
        '#6C996F',
        '#2E89CE',
        '#ED5661',
        '#4B637C',
        '#716B69',
    ];

    return {
        rags: state?.ragIntervals,
        sortedAscendent,
        sortedDescendent,
        getColor,
        getColorArray,
        getHighestRag,
        getLowestRag,
        getRandomColor,
        getRandomColorArray,
        hexToRgbA,
        getRandomColorArrayForRGBA,
        getPredefinedColors,
        anonymousArray,
        choiceArray,
    };
}
