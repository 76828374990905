import { Fragment } from 'react';
import constants from '../../../../constants';
import { Loading } from '../../../../loadings/Loading';
import { LikertTrendChart } from './LikertTrendChart';
import { ScoreTrendChart } from './ScoreTrendChart';
import { SentimentTrendChart } from './SentimentTrendChart';
import { YesNoTrendChart } from './YesNoTrendChart';
import { ChoiceTrendChart } from './ChoiceTrendChart';
import { PropTypes } from 'prop-types';

export function TrendChart({ survey, question, iterations, teamId }) {
    return (
        <Fragment>
            {iterations?.length ? (
                <div>
                    {question?.questionType ===
                        constants.QuestionType.Suggestion && (
                        <Fragment>
                            <SentimentTrendChart iterations={iterations} />
                        </Fragment>
                    )}
                    {question?.questionType ===
                        constants.QuestionType.Score && (
                        <Fragment>
                            <ScoreTrendChart iterations={iterations} />
                        </Fragment>
                    )}
                    {question?.questionType ===
                        constants.QuestionType.YesNo && (
                        <Fragment>
                            <YesNoTrendChart
                                surveyId={survey?.id}
                                questionId={question?.id}
                                teamId={teamId}
                                iterations={iterations?.map(
                                    (x) => x?.iteration
                                )}
                            />
                        </Fragment>
                    )}
                    {question?.questionType ==
                        constants.QuestionType.Likert && (
                        <Fragment>
                            <LikertTrendChart
                                surveyId={survey?.id}
                                questionId={question?.id}
                                teamId={teamId}
                                iterations={iterations?.map(
                                    (x) => x?.iteration
                                )}
                            />
                        </Fragment>
                    )}
                    {question?.questionType ==
                        constants.QuestionType.Choice && (
                        <Fragment>
                            <ChoiceTrendChart iterations={iterations} />
                        </Fragment>
                    )}
                </div>
            ) : (
                <Loading />
            )}
        </Fragment>
    );
}

TrendChart.propTypes = {
    survey: PropTypes.object,
    question: PropTypes.object,
    iterations: PropTypes.array,
    teamId: PropTypes.string,
};
