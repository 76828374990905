import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { getPulseIndex } from '../../../services/pulseIndexService';
import { useFormattedMessage } from '../lib/helper';
import { useRagIntervals } from '../lib/useRagIntervals';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function PulseIndexWidget({ from, to, teamId }) {
    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(true);

    const [colors, setColors] = useState(null);

    const formatMessage = useFormattedMessage;

    const { sortedDescendent } = useRagIntervals();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            var d = await getPulseIndex(from, to, teamId);

            setData(d);

            setColors(sortedDescendent()?.map((x) => x.hexColor));
            setLoading(false);
        }

        if (from && to) {
            fetchData();
        }
    }, [from, to, teamId]);

    function renderSection(titleId, category, horizontal) {
        let momentFormatted = '-';
        if (horizontal && category?.average) {
            let duration = moment.duration(category?.average);

            momentFormatted =
                duration.days() > 0
                    ? `${duration.days()}d ${duration.hours()}h`
                    : `${duration.hours()}h ${duration.minutes()}m`;
        }
        return (
            <div className='section'>
                <h4 role='none'>{formatMessage(titleId)}</h4>
                <div className={`content ${horizontal ? 'horizontal' : ''}`}>
                    <div className='average'>
                        <h5 role='none'>
                            {formatMessage(
                                'pulseIndexWidget.body.monthlyAverage'
                            )}
                        </h5>
                        <div className='circle'>
                            <h3 role='none'>
                                {horizontal
                                    ? momentFormatted
                                    : !!category &&
                                      category.average &&
                                      !horizontal
                                    ? category.average
                                    : '-'}
                            </h3>
                        </div>
                    </div>
                    <div className='breakdown'>
                        <h5>
                            {formatMessage(
                                'pulseIndexWidget.body.userBreakdown'
                            )}
                        </h5>
                        {!horizontal && (
                            <div className='chart'>
                                <PulseIndexChart category={category} />
                            </div>
                        )}

                        {horizontal && (
                            <div className='chart'>
                                {renderHorizontalChart(category)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    function renderHorizontalChart(category) {
        let chartData = [
            {
                name: formatMessage('pulseIndexWidget.body.beginner'),
                value: category ? category.beginner : null,
            },
            {
                name: formatMessage('pulseIndexWidget.body.intermediate'),
                value: category ? category.intermediate : null,
            },
            {
                name: formatMessage('pulseIndexWidget.body.expert'),
                value: category ? category.expert : null,
            },
        ];
        return (
            <Fragment>
                {category && category.beginner !== null && (
                    <i className='icon icon-triangle-down'></i>
                )}
                <div
                    className='horizontal-chart'
                    style={
                        !category || category.beginner === null
                            ? { backgroundColor: '#F2F4F8' }
                            : {}
                    }
                >
                    {chartData &&
                        chartData.length &&
                        chartData.map((x, i) => (
                            <div
                                className='bar'
                                key={`bar-${i}`}
                                style={{
                                    width: x.value !== null ? `${x.value}%` : 0,
                                    backgroundColor:
                                        colors && colors.length
                                            ? colors.slice().reverse()[i]
                                            : '#F2F4F8',
                                }}
                            ></div>
                        ))}

                    <div className='pulse-index-chart-tooltip'>
                        {chartData &&
                            chartData.length &&
                            chartData.map((x, i) => (
                                <div className='label' key={`label-${i}`}>
                                    <p key={`p-${i}`}>{x.name}</p>
                                    <div
                                        key={`div-${i}`}
                                        style={{
                                            backgroundColor:
                                                colors && colors.length
                                                    ? colors.slice().reverse()[
                                                          i
                                                      ]
                                                    : '#F2F4F8',
                                        }}
                                    >
                                        {x && x.value !== null
                                            ? `${x.value}%`
                                            : '-'}
                                    </div>
                                </div>
                            ))}
                    </div>

                    {(!category || category.beginner === null) && (
                        <p className='no-data'>-</p>
                    )}
                </div>
            </Fragment>
        );
    }

    return (
        <div className='pulse-index-widget' role='section'>
            <WidgetLayout
                loading={loading}
                information={
                    <Fragment>
                        {useFormattedMessage(
                            'pulseIndexWidget.header.information'
                        )}
                    </Fragment>
                }
            >
                <WidgetHeader
                    title={useFormattedMessage('pulseIndexWidget.header.title')}
                    route={''}
                />

                <hr />

                <div className='legend'>
                    <div>
                        <div
                            style={{
                                backgroundColor:
                                    colors && colors.length
                                        ? colors[2]
                                        : '#F2F4F8',
                            }}
                        ></div>
                        <p>
                            {useFormattedMessage(
                                'pulseIndexWidget.body.beginner'
                            )}
                        </p>
                    </div>
                    <div>
                        <div
                            style={{
                                backgroundColor:
                                    colors && colors.length
                                        ? colors[1]
                                        : '#F2F4F8',
                            }}
                        ></div>
                        <p>
                            {useFormattedMessage(
                                'pulseIndexWidget.body.intermediate'
                            )}
                        </p>
                    </div>
                    <div>
                        <div
                            style={{
                                backgroundColor:
                                    colors && colors.length
                                        ? colors[0]
                                        : '#F2F4F8',
                            }}
                        ></div>
                        <p>
                            {useFormattedMessage(
                                'pulseIndexWidget.body.expert'
                            )}
                        </p>
                    </div>
                </div>
                {renderSection(
                    'pulseIndexWidget.body.title1',
                    data ? data.feedbackGiven : undefined
                )}

                <hr />

                {renderSection(
                    'pulseIndexWidget.body.title2',
                    data ? data.feedbackAsked : undefined
                )}

                <hr />

                {renderSection(
                    'pulseIndexWidget.body.title3',
                    data ? data.responseTime : undefined,
                    true
                )}
            </WidgetLayout>
        </div>
    );
}

PulseIndexWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    teamId: PropTypes.string,
};

export function PulseIndexChart({ category }) {
    const { sortedDescendent } = useRagIntervals();

    const colors = sortedDescendent()?.map((x) => x.hexColor);

    let chartData = [
        {
            name: useFormattedMessage('pulseIndexWidget.body.expert'),
            value: category && category.expert !== null ? category.expert : 100,
        },
        {
            name: useFormattedMessage('pulseIndexWidget.body.intermediate'),
            value:
                category && category.intermediate !== null
                    ? category.intermediate
                    : 0,
        },
        {
            name: useFormattedMessage('pulseIndexWidget.body.beginner'),
            value:
                category && category.beginner !== null ? category.beginner : 0,
        },
    ];
    return (
        <PieChart width={80} height={80}>
            <Pie
                data={chartData}
                dataKey='value'
                cx='50%'
                cy='50%'
                startAngle={90}
                endAngle={450}
                outerRadius='100%'
                fill='#F2F4F8'
            >
                {chartData &&
                    chartData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={
                                colors &&
                                colors.length &&
                                category &&
                                category.expert !== null
                                    ? colors[index % colors.length]
                                    : '#F2F4F8'
                            }
                        />
                    ))}
                {/* {(!category || category.expert === null) && (
                    <Label
                        value=''
                        position='center'
                        style={{
                            fontSize: '2.5rem',
                            fontWeight: 'bold',
                            color: '#424953',
                            fill: '#424953'
                        }}
                    />
                )} */}
            </Pie>

            <Tooltip
                allowEscapeViewBox={{ x: true, y: true }}
                wrapperStyle={{ zIndex: 1000 }}
                content={({ active }) => {
                    if (active) {
                        return (
                            <div className='pulse-index-chart-tooltip'>
                                {chartData
                                    .slice()
                                    .reverse()
                                    .map((x, i) => (
                                        <div
                                            className='label'
                                            key={`label-${i}`}
                                        >
                                            <p key={`p-${i}`}>{x.name}</p>
                                            <div
                                                key={`div-${i}`}
                                                style={{
                                                    backgroundColor:
                                                        colors && colors.length
                                                            ? colors
                                                                  .slice()
                                                                  .reverse()[i]
                                                            : '#F2F4F8',
                                                }}
                                            >
                                                {category &&
                                                category.beginner !== null
                                                    ? `${x.value}%`
                                                    : '-'}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        );
                    }

                    return null;
                }}
            />
        </PieChart>
    );
}

PulseIndexChart.propTypes = {
    category: PropTypes.object,
};
