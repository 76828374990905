import { useEffect, useState } from 'react';
import { useRagIntervals } from '../../../../../shared/lib/useRagIntervals';

export function useResponseRateChart(survey, iteration, team) {
    const [chartData, setChartData] = useState(null);
    const [completionRate, setCompletionRate] = useState(null);

    const { getColor } = useRagIntervals();

    useEffect(() => {
        if (survey && typeof iteration === 'number') {
            let teamInsights = [];
            let cData = [];

            let cRate = null;

            if (survey?.surveyType === 1) {
                if (!team?.id) {
                    teamInsights = (
                        survey?.iterations?.length
                            ? survey?.iterations?.find(
                                  (x) => x.iteration === iteration
                              )
                            : survey?.lastIteration
                    )?.teamInsights;
                    cRate =
                        typeof (
                            survey?.iterations?.length
                                ? survey?.iterations?.find(
                                      (x) => x.iteration === iteration
                                  )
                                : survey?.lastIteration
                        )?.insight?.completionRate === 'number'
                            ? Number(
                                  (survey?.iterations?.length
                                      ? survey?.iterations?.find(
                                            (x) => x.iteration === iteration
                                        )
                                      : survey?.lastIteration
                                  )?.insight?.completionRate?.toFixed(0)
                              )
                            : null;
                } else {
                    cRate =
                        typeof (
                            survey?.iterations?.length
                                ? survey?.iterations?.find(
                                      (x) => x.iteration === iteration
                                  )
                                : survey?.lastIteration
                        )?.teamInsights?.find((x) => x.team.id === team?.id)
                            ?.completionRate === 'number'
                            ? Number(
                                  (survey?.iterations?.length
                                      ? survey?.iterations?.find(
                                            (x) => x.iteration === iteration
                                        )
                                      : survey?.lastIteration
                                  )?.teamInsights
                                      ?.find((x) => x.team.id === team?.id)
                                      ?.completionRate?.toFixed(0)
                              )
                            : null;
                }

                cData = [
                    {
                        value: (survey?.iterations?.length
                            ? survey?.iterations?.find(
                                  (x) => x.iteration === iteration
                              )
                            : survey?.lastIteration
                        )?.insight?.completionRate,
                        label: 'Company',
                        color: '#31353a',
                        sLabel: `${
                            (survey?.iterations?.length
                                ? survey?.iterations?.find(
                                      (x) => x.iteration === iteration
                                  )
                                : survey?.lastIteration
                            )?.insight?.completionRate?.toFixed(0) ?? 0
                        }%`,
                    },
                ];
            } else {
                if (!team?.id) {
                    teamInsights = survey?.teamInsights;
                    cRate =
                        typeof survey?.insight?.completionRate === 'number'
                            ? Number(
                                  survey?.insight?.completionRate?.toFixed(0)
                              )
                            : null;
                } else {
                    cRate =
                        typeof survey?.teamInsights?.find(
                            (x) => x?.team?.id === team?.id
                        )?.completionRate === 'number'
                            ? Number(
                                  survey?.teamInsights
                                      ?.find((x) => x?.team?.id === team?.id)
                                      ?.completionRate?.toFixed(0)
                              )
                            : null;
                }

                cData = [
                    {
                        value: survey?.insight?.completionRate,
                        label: 'Company',
                        color: '#31353a',
                        sLabel: `${
                            survey?.insight?.completionRate?.toFixed(0) ?? 0
                        }%`,
                    },
                ];
            }

            cData = [
                ...(cData ?? []),
                ...(teamInsights ?? [])
                    .sort((x, y) => y?.completionRate - x?.completionRate)
                    .map((t) => ({
                        color: getColor(t?.completionRate),
                        value: t?.completionRate,
                        label: t?.team?.name,
                        sLabel: `${t?.completionRate?.toFixed(0) ?? 0}%`,
                    })),
            ];

            setChartData(cData);
            setCompletionRate(cRate);
        }
    }, [survey, iteration, team]);

    return { chartData, completionRate };
}
