import { Fragment, useEffect, useState } from 'react';
import { useRagIntervals } from '../../../lib/useRagIntervals';
import SDisagreeIcon from '../../../../../assets/images/likert-sd.svg';
import DisagreeIcon from '../../../../../assets/images/likert-d.svg';
import NeutralIcon from '../../../../../assets/images/likert-n.svg';
import AgreeIcon from '../../../../../assets/images/likert-a.svg';
import SAgreeIcon from '../../../../../assets/images/likert-sa.svg';
import constants from '../../../constants';
import { PropTypes } from 'prop-types';
import '../../../inputs/inputs.scss';

export function CommentScoreLabel({ value, type }) {
    const { getColor, hexToRgbA } = useRagIntervals();

    const [style, setStyle] = useState({});

    useEffect(() => {
        let color = getColor(value, type);

        setStyle({
            backgroundColor: hexToRgbA(color, 0.3),
            borderColor: color,
        });
    }, [value, type]);

    function renderLikertIconComment() {
        let result;
        switch (value) {
            case -1:
                result = (
                    <Fragment>
                        <img src={SDisagreeIcon} height='70' />
                        <br />
                        <span style={{ fontSize: '11px' }}>
                            Strongly disagree
                        </span>
                    </Fragment>
                );
                break;
            case -2:
                result = (
                    <Fragment>
                        <img src={DisagreeIcon} height='70' />
                        <br />
                        <span style={{ fontSize: '11px' }}>Disagree</span>
                    </Fragment>
                );
                break;
            case -3:
                result = (
                    <Fragment>
                        <img src={NeutralIcon} height='70' />
                        <br />
                        <span style={{ fontSize: '11px' }}>Neutral</span>
                    </Fragment>
                );
                break;
            case -4:
                result = (
                    <Fragment>
                        <img src={AgreeIcon} height='70' />
                        <br />
                        <span style={{ fontSize: '11px' }}>Agree</span>
                    </Fragment>
                );
                break;
            case -5:
                result = (
                    <Fragment>
                        <img src={SAgreeIcon} height='70' />
                        <br />
                        <span style={{ fontSize: '11px' }}>Strongly agree</span>
                    </Fragment>
                );
                break;
            default:
                break;
        }
        return result;
    }

    return (
        <Fragment>
            {type === constants.QuestionType.Likert ? (
                renderLikertIconComment()
            ) : (
                <div style={style} className={`yesno-score-container`}>
                    <span>
                        {type === constants.QuestionType.YesNo
                            ? value
                                ? 'Y'
                                : 'N'
                            : typeof value === 'number'
                            ? value
                            : '-'}
                        {type === constants.QuestionType.Suggestion &&
                        typeof value === 'number'
                            ? '%'
                            : ''}
                    </span>
                </div>
            )}
        </Fragment>
    );
}

CommentScoreLabel.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    type: PropTypes.number,
};
