import { PropTypes } from 'prop-types';

export function HomePageTemplate({ className, children }) {
    return (
        <div className={`home-page ${!!className && className}`}>
            <div className='home-page-container'>
                <div className='home-page-form-content'>
                    {!!children && children}
                </div>
                <div className='home-page-image-gradient'>
                    <img
                        alt='Pulse 360 marketing image'
                        src='pulse-gradient.webp'
                    />
                </div>
            </div>
        </div>
    );
}

HomePageTemplate.propTypes = {
    className: PropTypes.string,
    children: PropTypes.object,
};
