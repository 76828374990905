import { Fragment, useEffect, useState } from 'react';
import { useFormattedMessage } from '../../../../shared/lib/helper';
import { WidgetTable } from '../../../../shared/widgets/components/WidgetTable';
import { Widget } from './Widget';
import { PropTypes } from 'prop-types';
import './components.scss';

export function RankingWidget({
    title,
    ranking,
    info,
    header,
    style,
    className,
}) {
    const [tableData, setTableData] = useState([]);

    const limit = 3;
    useEffect(() => {
        setTableData(
            fillArray(ranking && !!ranking.length ? ranking.slice(0, 3) : [])
        );
    }, [ranking]);

    function fillArray(array) {
        let result = [...array];
        while (result.length < limit) {
            result.push({
                name: '',
                volume: null,
            });
        }

        return result;
    }

    return (
        <Widget
            title={title}
            info={info}
            className={`ranking-widget`}
            hideTitle={true}
            boxClassName={className ? className : ''}
            style={style}
        >
            <WidgetTable
                data={tableData}
                columns={[
                    {
                        Header: '',
                        accessor: (d) => d,
                        id: 'numeration',
                        width: 20,
                        Cell: (row) => (
                            <div className='numeration'>
                                {row.row.index + 1}.
                            </div>
                        ),
                    },
                    {
                        Header: header,
                        accessor: 'name',
                        minWidth: 30,
                        Cell: (row) => (
                            <div className='text-ellipsis' title={row.value}>
                                {row.value}
                            </div>
                        ),
                    },
                    {
                        Header: useFormattedMessage(
                            'peerFeedbackWidget.body.volume'
                        ),
                        accessor: 'volume',
                        headerClassName: 'text-center',
                        className: 'text-center numeration',
                        width: 90,
                        Cell: (row) => (
                            <Fragment>
                                {!isNaN(row.value) && row.value !== null
                                    ? row.value
                                    : '-'}
                            </Fragment>
                        ),
                    },
                ]}
            />
        </Widget>
    );
}

RankingWidget.propTypes = {
    title: PropTypes.string,
    ranking: PropTypes.array,
    info: PropTypes.string,
    header: PropTypes.string,
    style: PropTypes.string,
    className: PropTypes.string,
};
