import { Fragment, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ComponentLoading } from '../../../../shared/loadings/ComponentLoading';
import { PropTypes } from 'prop-types';
import './components.scss';
import { useSelectedTeam } from '../../../../shared/lib/useSelectedTeam';

export function WEntityInsights({
    teamId,
    userId,
    image,
    name,
    pulseRate,
    feedbackGiven,
    feedbackReceived,
    months,
    selectedTeamId,
    loading,
}) {
    const [redirect, setRedirect] = useState(null);
    const { setTeam } = useSelectedTeam();

    return (
        <div
            className='w-entity-insights'
            tabIndex={0}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                    setTeam(teamId);
                    setRedirect(
                        teamId
                            ? `/my-team/feedback/overview/${teamId}`
                            : `/my-team/feedback/overview/${selectedTeamId}/user/${userId}`
                    );
                }
            }}
        >
            <img
                aria-label={'Press enter to view ' + name + ' user insights'}
                src={image}
                alt={'Team or user image miniature'}
                onClick={() => {
                    setTeam(teamId);
                    setRedirect(
                        teamId
                            ? `/my-team/feedback/overview/${teamId}`
                            : `/my-team/feedback/overview/${selectedTeamId}/user/${userId}`
                    );
                }}
            />
            <div className='w-i-body p-shadow interactive'>
                {loading && <ComponentLoading />}
                <label>{name}</label>
                {!loading && (
                    <Fragment>
                        <div className='scale-in-center'>
                            <i
                                aria-label={
                                    'Press enter to view ' +
                                    name +
                                    ' user insights'
                                }
                                className='icon icon-long-arrow-right'
                                onClick={() => {
                                    setTeam(teamId);
                                    setRedirect(
                                        teamId
                                            ? `/my-team/feedback/overview/${teamId}`
                                            : `/my-team/feedback/overview/${selectedTeamId}/user/${userId}`
                                    );
                                }}
                            ></i>
                        </div>

                        {redirect && (
                            <Navigate to={redirect} state={(months, teamId)} />
                        )}
                    </Fragment>
                )}
                <div className='w-i-group'>
                    {
                        /* {!isAnonymous && teamId && */ <IGroup
                            loading={loading}
                            icon={'icon-pulse'}
                            title={'Pulse completion rate'}
                            value={pulseRate ?? '-'}
                            percentile={true}
                        />
                    }
                    <IGroup
                        loading={loading}
                        title={'Total feedback given'}
                        icon={'icon-feedback-given'}
                        value={feedbackGiven ?? '-'}
                    />

                    <IGroup
                        loading={loading}
                        title={'Total feedback received'}
                        icon={'icon-feedback-received'}
                        value={feedbackReceived ?? '-'}
                    />
                </div>
            </div>
        </div>
    );
}

WEntityInsights.propTypes = {
    teamId: PropTypes.string,
    userId: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
    pulseRate: PropTypes.number,
    feedbackReceived: PropTypes.number,
    feedbackGiven: PropTypes.number,
    months: PropTypes.any,
    selectedTeamId: PropTypes.string,
    loading: PropTypes.bool,
};

function IGroup({ loading, title, value, percentile, icon }) {
    return (
        <div className='i-group'>
            <i
                aria-label={title}
                className={`icon ${icon}`}
                title={title ?? ''}
            ></i>

            {loading ? (
                <p>...</p>
            ) : (
                <p className='value' title={title}>
                    {typeof value === 'number'
                        ? `${value}${percentile ? '%' : ''}`
                        : '-'}
                </p>
            )}
        </div>
    );
}

IGroup.propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.any,
    percentile: PropTypes.bool,
    icon: PropTypes.string,
};
