import { Fragment, useEffect, useState } from 'react';
import { useSession } from '../../shared/lib/useSession';
import { PropTypes } from 'prop-types';

export function AuthControl({ authorized, permissions }) {
    const session = useSession();

    const [allow, setAllow] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (session?.permissions) {
            setAllow(isAuthorized(permissions, session?.permissions));

            setLoading(false);
        }
    }, [session?.permissions]);

    if (loading) {
        return <Fragment></Fragment>;
    }

    if (!loading) {
        return allow ? authorized : <Fragment></Fragment>;
    }
}

export function isAuthorized(permissionsString, sessionPermissions) {
    let allowedPermissions = permissionsString
        ?.split(';')
        ?.map((x) => x.trim());

    if (!sessionPermissions) {
        return false;
    }

    if (
        allowedPermissions?.some((perm) => sessionPermissions?.includes(perm))
    ) {
        return true;
    }

    return false;
}

AuthControl.propTypes = {
    authorized: PropTypes.object,
    permissions: PropTypes.string,
};
