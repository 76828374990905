import { useEffect, useState } from 'react';
import { CheckboxList } from '../../../../../../shared/filters/shared/CheckboxList';
import { DateSection } from '../../../../../../shared/filters/shared/DateSection';
import { PropTypes } from 'prop-types';

export function PPRUserFilter({ filter, handleUsers, handleDates, team }) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (team?.id) {
            getUsers(team?.id).then((t) => {
                t.unshift({
                    label: team?.title,
                    value: 'Team',
                    selected: true,
                });
                setUsers(t);
            });
        }
    }, [team]);

    function getUsers(teamId) {
        return fetch(`/api/User/getUsersSelectByTeam/${teamId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then((res) => res.json());
    }

    function handleUsersChange(user) {
        handleUsers(user);
    }

    function onDateChanges(from, to) {
        handleDates(from, to);
    }

    return (
        <div className='action-filter'>
            <CheckboxList
                list={users}
                onChange={handleUsersChange}
                bubble={true}
                title={'Teams'}
                search={true}
                label={'Teams'}
            />

            <DateSection
                className='small'
                from={filter.StartDate}
                to={filter.EndDate}
                onChange={onDateChanges}
                title={'Duration'}
            />
        </div>
    );
}

PPRUserFilter.propTypes = {
    filter: PropTypes.object,
    handleUsers: PropTypes.func,
    handleDates: PropTypes.func,
    team: PropTypes.object,
};
