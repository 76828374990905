import { get, put } from '../components/shared/lib/axios';

export async function getPPR(pprId) {
    return get(`ppr/${pprId}`);
}

export async function getSharedPPR(sharedPPRId) {
    return get(`shared-ppr/${sharedPPRId}`);
}

export async function commentPPR(req) {
    return put('ppr/comment', req);
}

export async function reviewPPR(pprId) {
    return put(`ppr/${pprId}/review`);
}
