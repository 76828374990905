import * as _ from 'lodash';
import moment from 'moment';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { getSharedPPR } from '../../../../../../services/pprService';
import { humanizeDiffToday } from '../../../../../shared/lib/dateTimeHelper';
import { useAsyncError } from '../../../../../shared/lib/helper';
import { AssessmentModal } from './Components/AssessmentModal';
import { BoxItem } from './Components/BoxItem';
import { UserPhoto } from './Components/UserPhoto';
import { PropTypes } from 'prop-types';
import '../HubItems.scss';

export function AssessmentItem({ item, userId }) {
    const [sharedPPR, setSharedPPR] = useState(null);

    const [modal, setModal] = useState(false);

    const [lastComment, setLastComment] = useState(undefined);

    const userSent = userId === item.feedbackReceiver.id;

    const throwError = useAsyncError();

    const onClose = useCallback(() => {
        async function fetchData() {
            try {
                let ppr = await getSharedPPR(item.id);

                let comments = _.compact(
                    _.flatMap(ppr.questions, ({ reactions }) =>
                        _.last(reactions)
                    )
                ).sort((x, y) => moment(y.date).diff(x.date));

                if (comments && comments.length) {
                    setLastComment(comments[0]);
                }

                setSharedPPR(ppr);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (!modal) {
            onClose();
        }
    }, [modal]);

    return !sharedPPR ? (
        <Fragment> </Fragment>
    ) : (
        <div role='section'>
            <BoxItem
                className='hub-item'
                reviewed={
                    !(
                        (!lastComment && !userSent) ||
                        (lastComment && lastComment.actor.id !== userId)
                    )
                }
                header={
                    <Fragment>
                        <UserPhoto
                            giver={item.feedbackReceiver}
                            receiver={item.feedbackGiver}
                        />
                        <div className='title-date'>
                            <div>
                                {`Assessment ${
                                    userSent ? 'shared' : 'received'
                                }`}
                            </div>
                            <div>{humanizeDiffToday(item.created)}</div>
                        </div>
                    </Fragment>
                }
                action={
                    <Fragment>
                        <p
                            className={`description ${
                                lastComment && lastComment.actor.id !== userId
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            {!lastComment && userSent
                                ? 'Awaiting Response'
                                : ''}
                            {lastComment && lastComment.actor.id === userId
                                ? 'Response sent'
                                : lastComment
                                ? 'Comments received'
                                : ''}
                        </p>
                        <button
                            className={`btn ${
                                (!lastComment && !userSent) ||
                                (lastComment && lastComment.actor.id !== userId)
                                    ? 'highlighted'
                                    : ''
                            }`}
                            onClick={() => setModal(true)}
                        >
                            {(!lastComment && !userSent) ||
                            (lastComment && lastComment.actor.id !== userId)
                                ? 'Respond'
                                : 'View'}
                        </button>
                    </Fragment>
                }
            >
                <div className='label-section'>
                    <div className='group'>
                        <div>
                            <span>{userSent ? 'To' : 'From'}:</span>
                            <span className='bold'>
                                {userSent
                                    ? item.feedbackGiver?.name
                                    : item.feedbackReceiver?.name}
                            </span>
                        </div>
                    </div>

                    <div className='group'>
                        <div>
                            <span>Assessment:</span>
                            <span className='bubble'>{item.topic}</span>
                        </div>
                    </div>

                    <div className='group'>
                        <div>
                            <span>Completed:</span>
                            <span>
                                {humanizeDiffToday(sharedPPR.completedDate)}
                            </span>
                        </div>
                    </div>
                </div>
            </BoxItem>

            {sharedPPR && (
                <AssessmentModal
                    open={modal}
                    item={item}
                    userId={userId}
                    onClose={() => setModal(false)}
                />
            )}
        </div>
    );
}

AssessmentItem.propTypes = {
    item: PropTypes.object,
    userId: PropTypes.string,
};
