import { ErrorPageTemplate } from '../templates/ErrorPageTemplate';
import { ErrorAnimation } from './ErrorAnimation';
import { PropTypes } from 'prop-types';
import './ErrorPage.scss';

export function ErrorPage({ message, onClick, buttonTitle }) {
    return (
        <ErrorPageTemplate
            className='error-page'
            header={
                <div className='header-animation'>
                    <ErrorAnimation />
                    <p>{message}</p>
                </div>
            }
        >
            {buttonTitle && (
                <div className='buttons'>
                    <button
                        type='submit'
                        className='btn highlighted'
                        onClick={onClick}
                    >
                        {buttonTitle}
                    </button>
                </div>
            )}
        </ErrorPageTemplate>
    );
}

ErrorPage.propTypes = {
    message: PropTypes.object,
    onClick: PropTypes.func,
    buttonTitle: PropTypes.string,
};
