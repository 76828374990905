import { PropTypes } from 'prop-types';
import '../../HubItems.scss';

export function BoxItem({ children, header, action, reviewed, className }) {
    return (
        <div
            role='section'
            className={`box-item ${reviewed ? 'inactive' : ''}  ${
                className ? className : ''
            }`}
        >
            <div className='header'>{header}</div>

            <div className='content'>{children}</div>

            <div className='action'>{action}</div>
        </div>
    );
}

BoxItem.propTypes = {
    children: PropTypes.object,
    header: PropTypes.object,
    action: PropTypes.object,
    reviewed: PropTypes.bool,
    className: PropTypes.string,
};
