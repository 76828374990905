import { NavLink, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';

export function MainButton({
    icon,
    className,
    children,
    to,
    onClick,
    expanded,
    title,
    ...props
}) {
    const location = useLocation();

    return !expanded ? (
        <NavLink
            {...props}
            title={title}
            to={to}
            className={`side-bar-button ${className ? className : ''}`}
        >
            {!!icon && (
                <i role='img' className={`icon ${icon ? icon : ''} `}></i>
            )}
            {!!children && <label>{children}</label>}
        </NavLink>
    ) : (
        <div
            {...props}
            onClick={(e) => (onClick ? onClick(e) : void 0)}
            title={title}
            className={`side-bar-button ${className ? className : ''} ${
                location.pathname.includes(to) ? 'active' : ''
            }`}
        >
            {!!icon && (
                <i role='img' className={`icon ${icon ? icon : ''} `}></i>
            )}
            {!!children && <label>{children}</label>}
        </div>
    );
}

MainButton.propTypes = {
    icon: PropTypes.string,
    children: PropTypes.string,
    to: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    expanded: PropTypes.bool,
    title: PropTypes.string,
};

export function SectionButton({
    icon,
    className,
    children,
    to,
    title,
    ...props
}) {
    return (
        <NavLink
            {...props}
            aria-label={title}
            title={title}
            to={to}
            className={`side-bar-button ${className ? className : ''}`}
        >
            {!!icon && (
                <i role='img' className={`icon ${icon ? icon : ''} `}></i>
            )}
            {!!children && <label>{children}</label>}
        </NavLink>
    );
}

SectionButton.propTypes = {
    icon: PropTypes.string,
    children: PropTypes.string,
    to: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
};
