import { useEffect, useState } from 'react';
import { useRagIntervals } from '../../lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import './components.scss';

export function GradientBarCircle({ percentile, label }) {
    const [rags, setRags] = useState(null);

    const [circleColor, setCircleColor] = useState('#8f9498');

    const { sortedAscendent } = useRagIntervals();

    useEffect(() => {
        setRags(sortedAscendent());
    }, [sortedAscendent]);

    useEffect(() => {
        function getColor(color1, color2, color3, ratio) {
            color1 = color1.replace('#', '');
            color2 = color2.replace('#', '');
            color3 = color3.replace('#', '');

            var hex = function (x) {
                x = x.toString(16);
                return x.length == 1 ? '0' + x : x;
            };

            var r, g, b;
            if (ratio <= 0.5) {
                r = Math.ceil(
                    parseInt(color2.substring(0, 2), 16) * ratio * 2.0 +
                        parseInt(color1.substring(0, 2), 16) *
                            (0.5 - ratio) *
                            2.0
                );
                g = Math.ceil(
                    parseInt(color2.substring(2, 4), 16) * ratio * 2.0 +
                        parseInt(color1.substring(2, 4), 16) *
                            (0.5 - ratio) *
                            2.0
                );
                b = Math.ceil(
                    parseInt(color2.substring(4, 6), 16) * ratio +
                        parseInt(color1.substring(4, 6), 16) *
                            (0.5 - ratio) *
                            2.0
                );
            } else {
                r = Math.ceil(
                    parseInt(color3.substring(0, 2), 16) * (ratio - 0.5) * 2.0 +
                        parseInt(color2.substring(0, 2), 16) *
                            (1.0 - ratio) *
                            2.0
                );
                g = Math.ceil(
                    parseInt(color3.substring(2, 4), 16) * (ratio - 0.5) * 2.0 +
                        parseInt(color2.substring(2, 4), 16) *
                            (1.0 - ratio) *
                            2.0
                );
                b = Math.ceil(
                    parseInt(color3.substring(4, 6), 16) * (ratio - 0.5) * 2.0 +
                        parseInt(color2.substring(4, 6), 16) *
                            (1.0 - ratio) *
                            2.0
                );
            }

            return hex(r) + hex(g) + hex(b);
        }

        if (rags && rags.length && typeof percentile === 'number') {
            setCircleColor(
                `#${getColor(
                    rags[0].hexColor,
                    rags[1].hexColor,
                    rags[2].hexColor,
                    percentile / 100
                )}`
            );
        }
    }, [rags, percentile]);

    return (
        <div
            className='gradient-bar-circle'
            style={
                rags && rags.length && typeof percentile === 'number'
                    ? {
                          background: `linear-gradient(to right, ${rags[0].hexColor}, ${rags[1].hexColor}, ${rags[2].hexColor})`,
                      }
                    : { backgroundColor: '#8f9498' }
            }
        >
            <div
                className='circle'
                style={{
                    left: `calc(${
                        percentile
                            ? percentile > 95
                                ? 95
                                : percentile < 5
                                ? 5
                                : percentile
                            : '5'
                    }% - 2.25rem)`,
                    borderColor: circleColor,
                }}
            >
                {label}
            </div>
        </div>
    );
}

GradientBarCircle.propTypes = {
    percentile: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
