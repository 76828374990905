import { Fragment } from 'react';
import { Loading } from '../loadings/Loading';
import { PropTypes } from 'prop-types';
import './layout.scss';

export function Page({
    header,
    className,
    loading,
    title,
    description,
    children,
}) {
    return (
        <div className={`page ${className ?? ''}`}>
            {loading ? (
                <div className='page-loading'>
                    <Loading />
                </div>
            ) : (
                <Fragment>
                    {!!title && (
                        <div
                            className='title-margin space-between'
                            aria-label={title + ' page'}
                        >
                            <h1 role='none'>{title}</h1>
                        </div>
                    )}
                    {!!header && header}
                    {!!description && description}

                    <div className='page-container'>
                        {!!children && children}
                    </div>
                </Fragment>
            )}
        </div>
    );
}

Page.propTypes = {
    header: PropTypes.object,
    className: PropTypes.string,
    loading: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
