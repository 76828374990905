import { Fragment, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import './components.scss';

export function SurveyIterationCarousel({ value, steps, onChange, onClick }) {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    return (
        <Fragment>
            <div className='survey-step-bar'>
                {[...steps]
                    ?.sort((x, y) => x.value - y.value)
                    ?.map((x, i) => (
                        <Fragment key={`step-${i}`}>
                            {x?.value > 0 && <div className='bar'></div>}

                            <div
                                className={`circle ${
                                    x?.value === localValue ? 'active' : ''
                                }  ${onClick ? 'clickable' : ''}`}
                                onClick={() =>
                                    typeof onChange === 'function'
                                        ? onChange(x?.value)
                                        : void 0
                                }
                            >
                                <h4>{x?.circleLabel}</h4>
                                <h5>{x?.label}</h5>
                            </div>
                        </Fragment>
                    ))}
            </div>
        </Fragment>
    );
}

SurveyIterationCarousel.propTypes = {
    value: PropTypes.number,
    steps: PropTypes.array,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
};
