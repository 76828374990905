import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { getConnections } from '../../../services/pulseIndexService';
import { useFormattedMessage } from '../lib/helper';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { WidgetTable } from './components/WidgetTable';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function ConnectionsWidget({ from, to, teamId, months }) {
    const [tableData, setTableData] = useState([]);

    const [currentData, setCurrentData] = useState(null);

    const [previousData, setPreviousData] = useState(null);

    const [loading, setLoading] = useState(true);

    const limit = 3;

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            var previousTo = moment(from);

            var previousFrom = moment(from).add(-months, 'months');

            var curr = await getConnections(from, to, teamId);

            var prev = await getConnections(previousFrom, previousTo, teamId);

            setCurrentData(curr);
            setPreviousData({
                total:
                    curr.total && prev.total
                        ? Math.round(
                              ((curr.total - prev.total) / curr.total) * 100
                          )
                        : null,
                withOtherTeams:
                    curr.withOtherTeams && prev.withOtherTeams
                        ? Math.round(curr.withOtherTeams - prev.withOtherTeams)
                        : null,
            });

            setLoading(false);

            setTableData(fillArray(curr.actorsConnections));
        }

        if (from && to) {
            fetchData();
        }
    }, [from, to, teamId]);

    function fillArray(array) {
        let result = [...array];
        while (result.length < limit) {
            result.push({
                name: '',
                volume: null,
                primaryConnection: null,
                withOtherTeams: null,
            });
        }

        return result;
    }

    return (
        <div className='connections-widget'>
            <WidgetLayout
                information={
                    <Fragment>
                        {useFormattedMessage(
                            'connectionsWidget.header.information'
                        )}
                    </Fragment>
                }
                loading={loading}
            >
                <WidgetHeader
                    title={useFormattedMessage(
                        'connectionsWidget.header.title'
                    )}
                    route={''}
                />

                <hr />
                <div className='total-connection-section'>
                    <div>
                        <div className='icon-title'>
                            <i
                                className='icon icon-connect'
                                aria-hidden={true}
                            ></i>
                            <h4 role='none'>
                                {useFormattedMessage(
                                    'connectionsWidget.body.connection'
                                )}
                            </h4>
                        </div>
                        <div className='total'>
                            <h2 role='none'>
                                {!!currentData && !!currentData.total
                                    ? `${currentData.total}`
                                    : '-'}
                            </h2>
                            {!!previousData && !!previousData.total && (
                                <div>
                                    <i
                                        style={{
                                            color:
                                                previousData.total > 0
                                                    ? '#7ed321'
                                                    : ' #d0021b',
                                        }}
                                        className={`icon icon-trend-arrow-${
                                            previousData.total > 0
                                                ? 'high'
                                                : 'bad'
                                        }`}
                                        aria-label={
                                            previousData.total > 0
                                                ? 'up'
                                                : 'down'
                                        }
                                    ></i>
                                    <p>{`${Math.abs(previousData.total)}%`}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className='icon-title'>
                            <i className='icon icon-link'></i>
                            <h4 role='none'>
                                {useFormattedMessage(
                                    'connectionsWidget.body.connectionAcrossTeam'
                                )}
                            </h4>
                        </div>
                        <div className='total'>
                            <h2 role='none'>
                                {currentData?.withOtherTeams
                                    ? `${currentData.withOtherTeams}%`
                                    : '-'}
                            </h2>
                            {!!previousData?.withOtherTeams && (
                                <div>
                                    <i
                                        style={{
                                            color:
                                                previousData.withOtherTeams > 0
                                                    ? '#7ed321'
                                                    : ' #d0021b',
                                        }}
                                        className={`icon icon-trend-arrow-${
                                            previousData.withOtherTeams > 0
                                                ? 'high'
                                                : 'bad'
                                        }`}
                                        aria-label={
                                            previousData.withOtherTeams > 0
                                                ? 'up'
                                                : 'down'
                                        }
                                    ></i>
                                    <p>
                                        {`${Math.abs(
                                            previousData.withOtherTeams
                                        )}%`}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <WidgetTable
                    data={tableData}
                    columns={[
                        {
                            Header: '',
                            accessor: (d) => d,
                            id: 'numeration',
                            width: 20,
                            Cell: (row) => (
                                <p className='numeration'>
                                    {row.row.index + 1}.
                                </p>
                            ),
                        },
                        {
                            Header: useFormattedMessage(
                                teamId
                                    ? 'widgets.table.headerUser'
                                    : 'widgets.table.headerTeam'
                            ),
                            accessor: 'name',
                            width: 50,
                            Cell: (row) => (
                                <p
                                    style={{ maxWidth: 75 }}
                                    role='cell'
                                    className='text-ellipsis'
                                    title={row.value}
                                    aria-labelledby={row.value}
                                >
                                    {row.value}
                                </p>
                            ),
                        },
                        {
                            Header: useFormattedMessage(
                                'connectionsWidget.table.header2'
                            ),
                            accessor: 'volume',
                            headerClassName: 'text-center',
                            className: 'text-center numeration',
                            width: 10,
                            Cell: (row) => (
                                <p role='cell' aria-labelledby={row.value}>
                                    {!isNaN(row.value) && row.value !== null
                                        ? row.value
                                        : '-'}
                                </p>
                            ),
                        },
                        {
                            Header: useFormattedMessage(
                                'connectionsWidget.table.header3'
                            ),
                            accessor: 'teamOutreach',
                            headerClassName: 'text-center',
                            className: 'text-center numeration',
                            width: 10,
                            Cell: (row) => (
                                <p role='cell' aria-labelledby={row.value}>
                                    {!isNaN(row.value) && row.value !== null
                                        ? row.value
                                        : '-'}
                                </p>
                            ),
                        },
                        {
                            Header: useFormattedMessage(
                                'connectionsWidget.table.header4'
                            ),
                            accessor: 'primaryConnection',
                            headerClassName: 'text-center',
                            className: 'text-center',
                            width: 50,
                            Cell: (row) => (
                                <Fragment>
                                    {row.value && row.value.length ? (
                                        <Fragment>
                                            <p
                                                style={{ maxWidth: 50 }}
                                                role='cell'
                                                className='text-ellipsis'
                                                title={
                                                    row.value
                                                        ? row.value.join('\n')
                                                        : ' '
                                                }
                                                aria-labelledby={row.value[0]}
                                            >
                                                {!!row.value && row.value.length
                                                    ? row.value[0]
                                                    : ''}
                                            </p>
                                            {row.value &&
                                                row.value.length > 1 && (
                                                    <p>{`,+${
                                                        row.value.length - 1
                                                    }`}</p>
                                                )}
                                        </Fragment>
                                    ) : (
                                        <p
                                            style={{
                                                width: '100%',
                                                textAlign: 'center',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            -
                                        </p>
                                    )}
                                </Fragment>
                            ),
                        },
                    ]}
                />
            </WidgetLayout>
        </div>
    );
}

ConnectionsWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    teamId: PropTypes.string,
    months: PropTypes.number,
};
