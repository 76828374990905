import { useEffect, useState } from 'react';
import { CheckboxList } from '../../../../../shared/filters/shared/CheckboxList';
import { DateSection } from '../../../../../shared/filters/shared/DateSection';
import { PropTypes } from 'prop-types';
import './PPRTeamFilter.scss';

export function PPRTeamFilter({ filter, handleTeams, handleDates }) {
    const [teams, setTeams] = useState([]);

    useEffect(() => {
        getTeams().then((t) => {
            t.unshift({ label: 'Company', value: 'Company', selected: true });
            setTeams(t);
        });
    }, []);

    function getTeams() {
        return fetch('/api/User/getTeamsSelect', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        }).then((res) => res.json());
    }

    function handleTeamsChange(teams) {
        handleTeams(teams);
    }

    function onDateChanges(from, to) {
        handleDates(from, to);
    }

    return (
        <div className='action-filter'>
            <CheckboxList
                list={teams}
                onChange={handleTeamsChange}
                bubble={true}
                title={'Teams'}
                search={true}
                label={'Teams'}
            />

            <DateSection
                className='small'
                from={filter?.startDate}
                to={filter?.EndDate}
                onChange={onDateChanges}
                title={'Duration'}
            />
        </div>
    );
}

PPRTeamFilter.propTypes = {
    filter: PropTypes.object,
    handleDates: PropTypes.func,
    handleTeams: PropTypes.func,
};
