import { useState, useEffect, Fragment } from 'react';
import { getSurvey } from '../../../../../../services/surveyService';
import { Loading } from '../../../../loadings/Loading';
import { LikertDominanceChart } from './LikertDominanceChart';
import { ChoiceDominanceChart } from './ChoiceDominanceChart';
import constants from '../../../../constants';
import PropTypes from 'prop-types';

export function DominanceChart({ surveyId, questionId, teamId, iteration }) {
    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let s = await getSurvey(surveyId);

            s.surveyType === constants.SurveyType.Recurring
                ? setData(
                      s.iterations
                          ?.find((s) => s.iteration === iteration)
                          .insight?.questionInsight.find(
                              (qI) => qI.questionId === questionId
                          )
                  )
                : setData(
                      s.insight.questionInsight.find(
                          (qI) => qI.questionId === questionId
                      )
                  );
        }

        fetchData();
        setLoading(false);
    }, [surveyId, iteration, questionId, teamId]);

    return (
        <Fragment>
            {loading ? (
                <Loading />
            ) : data?.likert ? (
                <LikertDominanceChart data={data?.likert} />
            ) : (
                <ChoiceDominanceChart
                    data={
                        data?.choice
                            ? Object.values(data?.choice)
                            : data?.choice
                    }
                />
            )}
        </Fragment>
    );
}

DominanceChart.propTypes = {
    surveyId: PropTypes.string,
    questionId: PropTypes.string,
    teamId: PropTypes.string,
    iteration: PropTypes.number,
};
