import autosize from 'autosize';
import _uniqueId from 'lodash/uniqueId';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import './inputs.scss';

export const Textarea = forwardRef(function (props, ref) {
    const [count, setCount] = useState(0);

    const [id] = useState(_uniqueId('autocomplete-'));

    const [active, setActive] = useState(false);

    const textarea = document.querySelector(`#${id}`);

    useEffect(() => {
        if (textarea) {
            autosize(textarea);
        }

        return () => {
            if (textarea) {
                autosize.destroy(textarea);
            }
        };
    }, [textarea]);

    useEffect(() => {
        if (props.focusOnRender && textarea) {
            textarea?.focus();
        }
    }, []);

    useEffect(() => {
        if (textarea?.value) {
            setCount(textarea?.value?.length);
        }
    }, [textarea]);

    useEffect(() => {
        if (props.value) {
            setCount(props.value.length);
        }
    }, [props.value]);

    function handleChange(event) {
        if (props.maxLength) {
            setCount(event.target.value.length);
        }

        autosize.update(textarea);

        if (props.onChange) {
            props.onChange(event);
        }
    }

    function handleFocus(event) {
        autosize.update(textarea);

        setActive(true);

        if (props.onFocus) {
            props.onFocus(event);
        }
    }

    function handleBlur(event) {
        autosize.update(textarea);

        setActive(false);

        if (props.onBlur) {
            props.onBlur(event);
        }
    }

    return (
        <Fragment>
            <div className='pulse-textarea-container'>
                <div className={`content-text ${props.icon ? 'icon' : ''}`}>
                    <textarea
                        id={id}
                        {...props}
                        ref={ref}
                        className={`pulse-textarea ${
                            props.className ? props.className : ''
                        }`}
                        value={props?.value ?? undefined}
                        onChange={handleChange}
                        maxLength={props.maxLength}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        placeholder={props.placeholder}
                    />
                    {!!props.icon && props.icon}
                </div>
                {props.maxLength && (
                    <span
                        className={`${active && 'active'} char-count`}
                    >{`${count} / ${props.maxLength}`}</span>
                )}
            </div>
        </Fragment>
    );
});

Textarea.propTypes = {
    focusOnRender: PropTypes.bool,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    icon: PropTypes.object,
    className: PropTypes.string,
    placeholder: PropTypes.string,
};
