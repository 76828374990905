function sortNotifications(a, b) {
    return a.setting - b.setting || a.channel - b.channel;
}

function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, []);
}

export function parseNotifications(uNotifications, settings) {
    let uNotificationsParsed = {
        feedback: {
            notify: [
                ...uNotifications
                    .filter(
                        (x) =>
                            x.setting === settings.notifyFeedbackReceived ||
                            x.setting === settings.notifyFeedbackRequested ||
                            x.setting === settings.notifyPeerFeedbackReaction ||
                            x.setting ===
                                settings.notifyOrganisationalFeedbackReaction
                    )
                    .sort(sortNotifications)
            ],

            remind: [
                ...uNotifications
                    .filter(
                        (x) => x.setting === settings.notifyFeedbackReminder
                    )
                    .sort(sortNotifications)
            ]
        },
        pulse: {
            notify: [
                ...uNotifications
                    .filter(
                        (x) =>
                            x.setting === settings.notifyPulseSurveyRelease ||
                            x.setting === settings.notifyPulseSurveyReaction
                    )
                    .sort(sortNotifications)
            ],
            remind: [
                ...uNotifications
                    .filter(
                        (x) => x.setting === settings.notifyPulseSurveyReminder
                    )
                    .sort(sortNotifications)
            ]
        },
        team: {
            notify: [
                ...uNotifications
                    .filter(
                        (x) =>
                            x.setting ===
                                settings.notifyTeamPulseSurveyReaction ||
                            x.setting ===
                                settings.notifyTeamOrganisationalFeedbackReaction ||
                            x.setting ===
                                settings.notifyTeamOrganisationalFeedbackReceived
                    )
                    .sort(sortNotifications)
            ],
            remind: []
        },
        assess: {
            notify: [
                ...uNotifications
                    .filter(
                        (x) =>
                            x.setting === settings.notifySharedAssessment ||
                            x.setting ===
                                settings.notifySharedAssessmentReaction ||
                            x.setting === settings.notifySharedAction ||
                            x.setting === settings.notifySharedActionReaction
                    )
                    .sort(sortNotifications)
            ],
            remind: []
        },
        company: {
            notify: [
                ...uNotifications
                    .filter(
                        (x) =>
                            x.setting ===
                                settings.notifyCompanyOrganisationalFeedbackReaction ||
                            x.setting ===
                                settings.notifyCompanyOrganisationalFeedbackReceived ||
                            x.setting ===
                                settings.notifyCompanyPulseSurveyReaction
                    )
                    .sort(sortNotifications)
            ],
            remind: []
        },
        teams: Object.entries(
            groupBy(
                uNotifications.filter((x) => x.team),
                'team'
            )
        )
            .map((x) => x[1])
            .map((y) => ({
                team: y[0].team,
                notify: [
                    ...y
                        .filter(
                            (x) =>
                                x.setting ===
                                    settings.notifyTeamPulseSurveyReaction ||
                                x.setting ===
                                    settings.notifyTeamOrganisationalFeedbackReaction ||
                                x.setting ===
                                    settings.notifyTeamOrganisationalFeedbackReceived
                        )
                        .sort(sortNotifications)
                ],
                remind: []
            }))
    };

    return uNotificationsParsed;
}
