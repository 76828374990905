import { Fragment } from 'react';
import { Admin } from './components/pages/admin/Admin';
import { Notifications } from './components/pages/admin/notifications/Notifications';
import { Organisation } from './components/pages/admin/organisation/Organisation';
import { Assess as AdminAssess } from './components/pages/admin/performance/Assess';
import { PPRTopic } from './components/pages/admin/performance/PPRTopic';
import { Team } from './components/pages/admin/teams/Team';
import { Teams } from './components/pages/admin/teams/Teams';
import { Topics } from './components/pages/admin/topics/Topics';
import { InviteUser } from './components/pages/admin/users/InviteUser';
import { User } from './components/pages/admin/users/User';
import { Users } from './components/pages/admin/users/Users';
import { ActionsTopic } from './components/pages/company/assess/actions/ActionsTopic';
import { Assess } from './components/pages/company/assess/Assess';
import { PPRHistoric } from './components/pages/company/assess/ppr/PPRHistoric';
import { Company } from './components/pages/company/Company';
import { CPeerToPeer } from './components/pages/company/feedback/CPeerToPeer';
import { FeedbackOverview } from './components/pages/company/feedback/FeedbackOverview';
import { OrganisationalFeedback } from './components/pages/company/feedback/OrganisationalFeedback';
import { Question } from './components/pages/admin/questions/Question';
import { Questions } from './components/pages/admin/questions/Questions';
import { AdminSurveys } from './components/pages/admin/surveys/AdminSurveys';
import { Survey } from './components/pages/admin/surveys/Survey';
import { SurveySelector } from './components/pages/admin/surveys/SurveySelector';
import { Pulse } from './components/pages/company/pulse/Pulse';
import { PulseCompletionRate } from './components/pages/company/pulse/PulseCompletionRate';
import { SurveyOverview } from './components/pages/company/pulse/surveyOverview/SurveyOverview';
import { SurveyQuestionOverview } from './components/pages/company/pulse/surveyOverview/SurveyQuestionOverview';
import { Actions } from './components/pages/me/assess/actions/Actions';
import { AssessmentForm } from './components/pages/me/assess/assess/assessment-form/AssessmentForm';
import { MeAssess } from './components/pages/me/assess/assess/MeAssess';
import { Success } from './components/pages/me/assess/Success';
import { DeepLinkItem } from './components/pages/me/hub/hubItems/DeepLinkItem';
import { MeHome } from './components/pages/me/hub/MeHome';
import { MeInsights } from './components/pages/me/insights/MeInsights';
import { Me } from './components/pages/me/Me';
import { UserNotifications } from './components/pages/me/UserNotifications';
import { MTPeerToPeer } from './components/pages/my-team/feedback/MTPeerToPeer';
import { MTUserOverview } from './components/pages/my-team/feedback/MTUserOverview';
import { MyTeamActions } from './components/pages/my-team/insights/assess/actions/MyTeamActions';
import { MyTeamAssess } from './components/pages/my-team/insights/assess/MyTeamAssess';
import { MyTeamPPRHistoric } from './components/pages/my-team/insights/assess/ppr/MyTeamPPRHistoric';
import { MyTeam } from './components/pages/my-team/insights/MyTeam';
import { MTPulseCompletionRate } from './components/pages/my-team/insights/pulse/MTPulseCompletionRate';
import { PulseNavigate } from './components/shared/utils/PulseNavigate';

const routes = [
    {
        path: '/',
        key: '/',
        exact: true,
        authPermission: 'user',
        title: 'Hub',

        element: <Me />,
    },
    /* ME - FEEDBACK */
    {
        path: '/me/notifications',
        key: '/me/notifications',
        authPermission: 'user',
        title: 'Notifications Settings',

        element: <UserNotifications />,
    },
    /* ME - FEEDBACK */
    {
        path: '/me/insight',
        key: '/me/insight',
        authPermission: 'user',
        title: 'Peer Feedback',

        element: <MeInsights />,
    },
    /* ME - PERFORMANCE */

    {
        path: '/me/assess/actions',
        key: '/me/assess/actions',
        authPermission: 'user',
        title: 'Actions',

        element: <Actions />,
    },

    {
        path: '/me/assess/assessment/:id',
        key: '/me/assess/assessment/:id',
        authPermission: 'user',
        title: 'Overview',

        element: <AssessmentForm />,
    },
    {
        path: '/me/assess/assessment',
        key: '/me/assess/assessment',
        authPermission: 'user',
        title: 'Assessment',

        element: <MeAssess />,
    },
    {
        path: '/me/assess/success',
        key: '/me/assess/success',
        authPermission: 'user',
        title: 'Success',

        element: <Success />,
    },

    /*ME*/

    {
        path: '/me/hub/survey/:surveyId/iteration/:iteration',
        key: '/me/hub/survey/:surveyId/iteration/:iteration',
        authPermission: 'user',
        title: 'Item',
        exact: true,

        element: <DeepLinkItem />,
    },
    {
        path: '/me/hub/survey/:surveyId/iteration',
        key: '/me/hub/survey/:surveyId/iteration',
        authPermission: 'user',
        title: 'Item',
        exact: true,

        element: <DeepLinkItem />,
    },
    {
        path: '/me/hub/:itemId',
        key: '/me/hub/:itemId',
        authPermission: 'user',
        title: 'Item',
        exact: true,
        noBreadcrumb: true,

        element: <DeepLinkItem />,
    },
    {
        path: '/me/hub',
        key: '/me/hub',
        authPermission: 'user',
        title: 'Hub',
        exact: true,
        noBreadcrumb: true,

        element: <MeHome />,
    },
    {
        path: '/me',
        key: '/me',
        authPermission: 'user',
        title: 'Me',

        element: <Me />,
    },

    /* MY TEAM */

    {
        path: '/my-team/assess/ppr-historic',
        key: '/my-team/assess/ppr-historic',
        authPermission: 'team::assess',
        title: 'Scores',

        element: <MyTeamPPRHistoric />,
    },
    {
        path: '/my-team/assess/actions',
        key: '/my-team/assess/actions',
        authPermission: 'team::assess',
        title: 'Actions',

        element: <MyTeamActions />,
    },
    {
        path: '/my-team/assess',
        key: '/my-team/assess',
        authPermission: 'team::assess',
        title: 'Assess',

        element: <MyTeamAssess />,
    },
    {
        path: '/my-team/feedback/pulse/survey-overview/:id/team/:teamId/question/:questionId/:iteration',
        key: '/my-team/feedback/pulse/survey-overview/:id/team/:teamId/question/:questionId/:iteration',
        authPermission: 'team::feedback',
        title: 'Survey Question',

        element: <SurveyQuestionOverview />,
    },
    {
        path: '/my-team/feedback/pulse/survey-overview/:id/team/:teamId/question/:questionId',
        key: '/my-team/feedback/pulse/survey-overview/:id/team/:teamId/question/:questionId',
        authPermission: 'team::feedback',
        title: 'Survey Question',

        element: <SurveyQuestionOverview />,
    },
    {
        path: '/my-team/feedback/pulse/survey-overview/:id/team/:teamId',
        key: '/my-team/feedback/pulse/survey-overview/:id/team/:teamId',
        authPermission: 'team::feedback',
        title: 'Survey Overview',

        element: <SurveyOverview />,
    },
    {
        path: '/my-team/feedback/pulse/all-surveys',
        key: '/my-team/feedback/pulse/all-surveys',
        authPermission: 'team::feedback',
        title: 'Surveys',

        element: <Fragment> {/* TODO */}</Fragment>,
    },
    {
        path: '/my-team/feedback/pulse/completion-rate/:teamId',
        key: '/my-team/feedback/pulse/completion-rate/:teamId',
        authPermission: 'team::feedback',
        title: 'Completion Rate',

        element: <MTPulseCompletionRate />,
    },
    {
        path: '/my-team/feedback/pulse',
        key: '/my-team/feedback/pulse',
        authPermission: 'team::feedback',
        title: 'Pulse Surveys',

        element: <Pulse isTeam={true} />,
    },
    {
        path: '/my-team/feedback/peer-to-peer',
        key: '/my-team/feedback/peer-to-peer',
        authPermission: 'team::feedback',
        title: 'Peer Feedback',

        element: <MTPeerToPeer />,
    },
    {
        path: '/my-team/feedback/peer-to-peer/:teamId',
        key: '/my-team/feedback/peer-to-peer/:teamId',
        authPermission: 'team::feedback',
        title: 'Peer Feedback',

        element: <MTPeerToPeer />,
    },
    {
        path: '/my-team/feedback/organisation/:feedbackId/team/:teamId',
        key: '/my-team/feedback/organisation/:feedbackId/team/:teamId',
        authPermission: 'team::feedback',
        title: 'Employee Voice',

        element: <PulseNavigate />,
    },
    {
        path: '/my-team/feedback/organisation/:teamId',
        key: '/my-team/feedback/organisation/:teamId',
        authPermission: 'team::feedback',
        title: 'Employee Voice',

        element: <OrganisationalFeedback isTeam={true} />,
    },
    {
        path: '/my-team/feedback/organisation',
        key: '/my-team/feedback/organisation',
        authPermission: 'team::feedback',
        title: 'Employee Voice',

        element: <OrganisationalFeedback />,
    },
    {
        path: '/my-team/feedback/overview/:teamId/user/:userId',
        key: '/my-team/feedback/overview/:teamId/user/:userId',
        authPermission: 'team::feedback',
        title: 'User insights',

        element: <MTUserOverview />,
    },
    {
        path: '/my-team/feedback/overview',
        key: '/my-team/feedback/overview',
        authPermission: 'team::feedback',
        title: 'Overview',

        element: <FeedbackOverview />,
    },
    {
        path: '/my-team/feedback/overview/:teamId',
        key: '/my-team/feedback/overview/:teamId',
        authPermission: 'team::feedback',
        title: 'Overview',

        element: <FeedbackOverview />,
    },
    {
        path: '/my-team',
        key: '/my-team',
        authPermission: 'team',
        title: 'My Team',

        element: <MyTeam />,
    },

    /* COMPANY */
    {
        path: '/company/assess/ppr-historic',
        key: '/company/assess/ppr-historic',
        authPermission: 'company::assess',
        title: 'Scores',

        element: <PPRHistoric />,
    },
    {
        path: '/company/assess/actions-topic',
        key: '/company/assess/actions-topic',
        authPermission: 'company::assess',
        title: 'Actions',

        element: <ActionsTopic />,
    },
    {
        path: '/company/assess/',
        key: '/company/assess/',
        authPermission: 'company::assess',
        title: 'Assess',

        element: <Assess />,
    },
    /* COMPANY - FEEDBACK */
    {
        path: '/company/feedback/pulse/survey-overview/:id/question/:questionId/:iteration',
        key: '/company/feedback/pulse/survey-overview/:id/question/:questionId/:iteration',
        authPermission: 'company::feedback;company::pulse_settings',
        title: 'Question',

        element: <SurveyQuestionOverview />,
    },
    {
        path: '/company/feedback/pulse/survey-overview/:id/question/:questionId',
        key: '/company/feedback/pulse/survey-overview/:id/question/:questionId',
        authPermission: 'company::feedback;company::pulse_settings',
        title: 'Question',

        element: <SurveyQuestionOverview />,
    },
    {
        path: '/company/feedback/pulse/survey-overview/:id',
        key: '/company/feedback/pulse/survey-overview/:id',
        authPermission: 'company::feedback;company::pulse_settings',
        title: 'Survey Overview',

        element: <SurveyOverview />,
    },
    {
        path: '/company/feedback/pulse/completion-rate',
        key: '/company/feedback/pulse/completion-rate',
        authPermission: 'company::feedback;company::pulse_settings',
        title: 'Completion Rate',

        element: <PulseCompletionRate />,
    },
    {
        path: '/company/feedback/pulse',
        key: '/company/feedback/pulse',
        authPermission: 'company::feedback;company::pulse_settings',
        title: 'Pulse Surveys',

        element: <Pulse />,
    },
    {
        path: '/company/feedback/organisation',
        key: '/company/feedback/organisation',
        authPermission: 'company::feedback',
        title: 'Employee Voice',

        element: <OrganisationalFeedback />,
    },
    {
        path: '/company/feedback/organisation/:teamId',
        key: '/company/feedback/organisation/:teamId',
        authPermission: 'company::feedback',
        title: 'Employee Voice',

        element: <OrganisationalFeedback />,
    },
    {
        path: '/company/feedback/peer-to-peer',
        key: '/company/feedback/peer-to-peer',
        authPermission: 'company::feedback',
        title: 'Peer Feedback',

        element: <CPeerToPeer />,
    },
    {
        path: '/company/feedback/overview',
        key: '/company/feedback/overview',
        authPermission: 'company::feedback',
        title: 'Overview',

        element: <FeedbackOverview />,
    },
    {
        path: '/company',
        key: '/company',
        title: 'Company',
        authPermission: 'company',

        element: <Company />,
    },
    /* ADMIN */
    {
        path: '/admin/organisation',
        key: '/admin/organisation',
        authPermission: 'company::company_settings',
        title: 'Organisation',

        element: <Organisation />,
    },
    //{
    //    path: '/admin/policy',
    //    key: '/admin/policy',
    //    authPermission: "company::company_settings",
    //    title: 'Policy',
    //    element: () => <Policy />
    //},

    {
        path: '/admin/users/invite-user',
        key: '/admin/users/invite-user',
        authPermission: 'company::company_settings',
        title: 'Invite User',

        element: <InviteUser />,
    },
    {
        path: '/admin/users/user/:id',
        key: '/admin/user/user/:id',
        authPermission: 'company::company_settings',
        title: 'Edit User',

        element: <User />,
    },
    {
        path: '/admin/users',
        key: '/admin/users',
        authPermission: 'company::company_settings',
        title: 'Users',

        element: <Users />,
    },
    {
        path: '/admin/teams/edit/:id',
        key: '/admin/teams/edit/:id',
        authPermission: 'company::company_settings',
        title: 'Edit Team',

        element: <Team />,
    },
    {
        path: '/admin/teams/new',
        key: '/admin/teams/new',
        authPermission: 'company::company_settings',
        title: 'Create Team',

        element: <Team />,
    },
    {
        path: '/admin/teams',
        key: '/admin/teams',
        authPermission: 'company::company_settings',
        title: 'Teams',

        element: <Teams />,
    },
    {
        path: '/admin/topics',
        key: '/admin/topics',
        authPermission: 'company::company_settings;company::pulse_settings',
        title: 'Topics',

        element: <Topics />,
    },
    {
        path: '/admin/performance/edit/:id',
        key: '/admin/performance/edit/:id',
        authPermission: 'company::company_settings; company::pulse_settings',
        title: 'Edit',

        element: <PPRTopic />,
    },
    {
        path: '/admin/performance',
        key: '/admin/performance',
        authPermission: 'company::company_settings;company::pulse_settings',
        title: 'Assess',

        element: <AdminAssess />,
    },

    {
        path: '/admin/surveys-settings/survey/:id',
        key: '/admin/surveys-settings/survey/:id',
        authPermission: 'company::pulse_settings',
        title: 'Edit Survey',

        element: <Survey />,
    },
    {
        path: '/admin/surveys-settings/new-survey/:type',
        key: '/admin/surveys-settings/new-survey/:type',
        authPermission: 'company::pulse_settings',
        title: '',

        element: <Survey />,
    },
    {
        path: '/admin/surveys-settings/new-survey',
        key: '/admin/surveys-settings/new-survey',
        authPermission: 'company::pulse_settings',
        title: 'New Survey',

        element: <SurveySelector />,
    },
    {
        path: '/admin/surveys-settings/:type',
        key: '/admin/surveys-settings/:type',
        authPermission: 'company::pulse_settings',
        title: 'Surveys',

        element: <AdminSurveys surveyPath='/admin/surveys-settings/survey/' />,
    },
    {
        path: '/admin/surveys-settings',
        key: '/admin/surveys-settings',
        authPermission: 'company::pulse_settings',
        title: 'Surveys',

        element: <AdminSurveys surveyPath='/admin/surveys-settings/survey/' />,
    },
    {
        path: '/admin/bank-of-questions/question',
        key: '/admin/bank-of-questions/question',
        authPermission: 'company::pulse_settings',
        title: 'Add question',

        element: <Question />,
    },
    {
        path: '/admin/bank-of-questions',
        key: '/admin/bank-of-questions',
        authPermission: 'company::pulse_settings',
        title: 'Questions',

        element: <Questions />,
    },
    {
        path: '/admin/notifications',
        key: '/admin/notifications',
        authPermission: 'company::company_settings',
        title: 'Notifications',

        element: <Notifications />,
    },
    {
        path: '/admin',
        key: '/admin',
        authPermission: 'company::company_settings; company::pulse_settings',
        title: 'Admin',

        element: <Admin />,
    },
];

export default routes;
