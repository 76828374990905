import { PropTypes } from 'prop-types';
import './components.scss';

export function Switch({ checked, disabled, onChange, children, className, style }) {
    return (
        <label className={`pulse360-switch-refactor ${className ?? ''}`} style={style}>
            {!!children && children}
            <input
                type='checkbox'
                checked={checked}
                disabled={disabled}
                onChange={(e) => onChange(e.target.checked)}
            />
            <span className='slider'></span>
        </label>
    );
}

Switch.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
};
