import moment from 'moment';
import DatePicker from 'react-datepicker';
import constants from '../../../../../../shared/constants';
import { useEffect, useState } from 'react';
import { PulseSelect } from '../../../../../../shared/inputs/PulseSelect';
import { Textarea } from '../../../../../../shared/inputs/Textarea';
import { PropTypes } from 'prop-types';
import { getPriorityTypes } from '../../../../../../../services/actionService';
import './components.scss';

export function ActionBox({ question, onChange }) {
    const [priorityTypes, setPriorityTypes] = useState(null);

    const [localAction, setLocalAction] = useState({
        description:
            question.action && question.action.description
                ? question.action.description
                : '',
        priority:
            question.action &&
            (question.action.priority || question.action.priority === 0)
                ? question.action.priority
                : null,
        completionDate:
            question.action && question.action.completeDate
                ? moment(question.action.completeDate)
                : null,
    });

    useEffect(() => {
        getPriorityTypes().then((priorities) => {
            let mappedPriorities = priorities.map((x) => ({
                label: x.key,
                value: x.value,
            }));

            setPriorityTypes(mappedPriorities);
        });
    }, []);

    useEffect(() => {
        setLocalAction({
            description:
                question.action && question.action.description
                    ? question.action.description
                    : '',
            priority:
                question.action &&
                question.action.description &&
                (question.action.priority || question.action.priority === 0)
                    ? question.action.priority
                    : null,
            completionDate:
                question.action &&
                question.action.description &&
                question.action.completeDate
                    ? moment(question.action.completeDate)
                    : null,
        });
    }, [question]);

    useEffect(() => {
        if (!localAction.description) {
            setLocalAction({
                description: '',
                completionDate: null,
                priority: null,
            });
        } else if (!localAction.priority && localAction.priority !== 0) {
            setLocalAction({ ...localAction, priority: 0 });
        }
    }, [localAction.description]);

    useEffect(() => {
        let action = { ...localAction };
        if (!action.description) {
            action = null;
        }
        onChange(action);
    }, [localAction]);

    return (
        <div className='action-box'>
            <div className='form-group'>
                <div className='title'>Action </div>
                <Textarea
                    type='text'
                    rows={4}
                    name='action'
                    maxLength={constants.maxLength}
                    placeholder='Enter your action...'
                    onChange={(e) =>
                        setLocalAction({
                            ...localAction,
                            description: e.target.value,
                        })
                    }
                    value={localAction.description}
                />
            </div>

            <div className='form-group'>
                <div className='title'>Priority </div>
                <div className='select-container'>
                    <PulseSelect
                        className='react-select'
                        placeholder='Select priority'
                        options={priorityTypes || []}
                        value={
                            priorityTypes
                                ? priorityTypes.find(
                                      (x) => x.value === localAction.priority
                                  )
                                    ? priorityTypes.find(
                                          (x) =>
                                              x.value === localAction.priority
                                      )
                                    : null
                                : null
                        }
                        onChange={(option) =>
                            setLocalAction({
                                ...localAction,
                                priority: option.value,
                            })
                        }
                        isDisabled={
                            !localAction.description ||
                            localAction.description.length <= 0
                        }
                        isClearable={false}
                    />
                </div>
            </div>

            <div className='form-group '>
                <div className='title'>Completion Date </div>
                <div className='datepicker-container'>
                    <DatePicker
                        disabled={
                            !localAction.description ||
                            localAction.description.length <= 0
                        }
                        className='date-picker'
                        dateFormat='dd/MM/yyyy'
                        placeholderText='Select date'
                        selected={
                            localAction.completionDate
                                ? moment(localAction.completionDate).toDate()
                                : null
                        }
                        onChange={(date) =>
                            setLocalAction({
                                ...localAction,
                                completionDate: date ? moment(date) : null,
                            })
                        }
                        isClearable={true}
                        minDate={moment().toDate()}
                    />
                </div>
            </div>
        </div>
    );
}

ActionBox.propTypes = {
    question: PropTypes.object,
    onChange: PropTypes.func,
};
