import { useNavigate } from 'react-router-dom';
import './Survey.scss';

export function SurveySelector() {
    const navigate = useNavigate();

    return (
        <div className='survey-selector'>
            <div
                className='one-off'
                onClick={() =>
                    navigate('/admin/surveys-settings/new-survey/0')
                }>
                <i className='icon-one-off'></i>
                <h3>ONE OFF</h3>
                <p className='text-center'></p>
            </div>

            <div
                className='recurring'
                onClick={() =>
                    navigate('/admin/surveys-settings/new-survey/1')
                }>
                <i className='icon-recurring'></i>
                <h3>RECURRING</h3>
                <p className='text-center'></p>
            </div>
        </div>
    );
}
