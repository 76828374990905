import { Fragment, useEffect, useRef, useState } from 'react';
import {
    completeAction,
    deleteAction,
    getPriorityTypes,
    getUserActions,
    shareAction,
    updateAction,
} from '../../../../../../services/actionService';
import { Table } from '../../../../../shared/components/Table';
import { IconButton } from '../../../../../shared/inputs/IconButton';
import { useAsyncError } from '../../../../../shared/lib/helper';
import { useSession } from '../../../../../shared/lib/useSession';
import { GenericModal } from '../../../../../shared/modal/GenericModal';
import { ContactModal } from '../../../hub/widgets/ContactModal';
import autosize from 'autosize';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import '../Actions.scss';

export function OpenActionsSection() {
    const [actions, setActions] = useState([]);

    const [loading, setLoading] = useState(true);

    const [priorityTypes, setPriorityTypes] = useState([]);

    const [showUserSelectModal, setShowUserSelectModal] = useState(false);

    const [sharedUsers, setSharedUsers] = useState([]);

    const [actionToShare, setActionToShare] = useState(null);

    const [modal, setModal] = useState(false);

    const [success, setSuccess] = useState(false);

    const session = useSession();

    const throwError = useAsyncError();

    const domRef = useRef([]);

    useEffect(() => {
        async function fetchData() {
            try {
                let [act, prior] = await Promise.all([
                    getUserActions(session?.userId, 0),
                    getPriorityTypes(),
                ]);

                setPriorityTypes(prior);

                setActions(act);

                setLoading(false);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (actions) {
            var actionToFocus = actions.find((x) => x.isActive);

            if (actionToFocus) {
                var textarea = domRef.current[actionToFocus.id];

                if (document.activeElement !== textarea) {
                    textarea.focus();
                    textarea.setSelectionRange(
                        textarea.value.length,
                        textarea.value.length
                    );
                }
            }
        }
    }, [actions]);

    async function loadActions() {
        try {
            setActions(await getUserActions(session?.userId, 0));
        } catch (e) {
            throwError(e);
        }
    }

    async function handleComplete(actionId) {
        try {
            await completeAction(actionId);
            await loadActions();
        } catch (e) {
            throwError(e);
        }
    }

    async function handleDelete(actionId) {
        try {
            await deleteAction(actionId);
            await loadActions();
        } catch (e) {
            throwError(e);
        }
    }

    async function handleUpdate(actionId) {
        let action = actions.find((x) => x.id === actionId);

        try {
            await updateAction({
                ActionId: action.id,
                Description: action.description,
                CompletionDate: action.completeDate,
                Priority: action.priority,
            });

            await loadActions();
        } catch (e) {
            throwError(e);
        }
    }

    async function handleEdit(actionId) {
        let updatedActions = [...actions];

        updatedActions
            .filter((x) => x.isActive && actionId !== x.id)
            .forEach((action) => {
                action.isActive = false;
            });

        updatedActions.find((x) => x.id === actionId).isActive =
            !updatedActions.find((x) => x.id === actionId).isActive;

        setActions(updatedActions);

        if (!updatedActions.find((x) => x.id === actionId).isActive) {
            await handleUpdate(actionId);
        } else {
            domRef.current[actionId].focus();
        }
    }

    async function confirm(action, date) {
        action.completeDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');

        await handleUpdate(action.id);
    }

    function handleDescriptionChange(event, actionId) {
        let updatedActions = [...actions];
        updatedActions.find((x) => x.id === actionId).description =
            event.target.value;

        setActions(updatedActions);
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.target.blur();
        }
    }

    function handleOnBlur(event, actionId) {
        if (event.target.value) {
            handleEdit(actionId);
        } else if (!event.target.value && actionId) {
            event.target.focus();
            event.target.classList.add('invalid');
        } else {
            let updatedActions = actions.filter((x) => x.id);
            setActions(updatedActions);
        }
    }

    async function handleShareActionSelection(selectedUsersAndTeams) {
        try {
            if (selectedUsersAndTeams.length > 0) {
                let users = selectedUsersAndTeams
                    .filter((x) => !x.isTeam)
                    .map((x) => x.id);

                let teams = selectedUsersAndTeams
                    .filter((x) => x.isTeam)
                    .map((x) => x.id);

                await shareAction(actionToShare, users, teams);

                setSuccess(true);
                setModal(true);
            }

            setShowUserSelectModal(false);
            setActionToShare(null);
        } catch (e) {
            setSuccess(false);
            setModal(true);
        }

        await loadActions();
    }

    useEffect(() => {
        if (!loading) {
            setShowUserSelectModal(true);
        }
    }, [sharedUsers]);

    function handleShare(actionId) {
        setActionToShare(actionId);

        setSharedUsers([...actions.find((x) => x.id === actionId).sharedTo]);
    }

    return (
        <div className='main-container open-actions'>
            <Table
                className='actions'
                role='table'
                data={actions}
                loading={loading}
                noDataText={'No open actions'}
                pageSize={actions.length}
                columns={[
                    {
                        Header: 'Action',
                        accessor: 'description',
                        width: 300,
                        Cell: (row) => (
                            <div role='cell'>
                                <textarea
                                    ref={(ref) =>
                                        (domRef.current[row.row.original.id] =
                                            ref)
                                    }
                                    className={`input-description ${
                                        row.row.original.isActive ? '' : 'none'
                                    }`}
                                    onChange={(event) => {
                                        handleDescriptionChange(
                                            event,
                                            row.row.original.id
                                        );
                                    }}
                                    onKeyPress={(event) => {
                                        event.key === 'Enter' &&
                                            handleKeyPress(
                                                event,
                                                row.row.original.id
                                            );
                                    }}
                                    onFocus={(event) => {
                                        autosize.update(event.target);
                                    }}
                                    onBlur={(event) => {
                                        handleOnBlur(
                                            event,
                                            row.row.original.id
                                        );
                                    }}
                                    value={row.row.original.description}
                                    maxLength='125'
                                />
                                <div
                                    className={`description ${
                                        row.row.original.isActive ? 'none' : ''
                                    }`}
                                >
                                    {row.row.original.description}
                                </div>
                            </div>
                        ),
                    },
                    {
                        Header: 'Topic',
                        accessor: 'pprTopic',
                        width: 100,
                        Cell: (row) => (
                            <div role='cell' title={row.value.title}>
                                {row.value.title}
                            </div>
                        ),
                    },
                    {
                        Header: 'Priority',
                        accessor: 'priority',
                        width: 100,
                        Cell: (row) => (
                            <div
                                role='cell'
                                title={`${row.value + 1} - ${
                                    priorityTypes.find(
                                        (x) => x.value === row.value
                                    ).key
                                }`}
                            >
                                {`${row.value + 1} - ${
                                    priorityTypes.find(
                                        (x) => x.value === row.value
                                    ).key
                                }`}
                            </div>
                        ),
                    },
                    {
                        Header: 'Complete by',
                        accessor: 'completeDate',
                        width: 150,
                        Cell: (row) => (
                            <div role='cell'>
                                {row.row.original.completeDate == null ? (
                                    <Fragment>
                                        <DatePicker
                                            customInput={
                                                <div>
                                                    <button className='calendar-cont-new-action'>
                                                        <i className='icon-calendar'></i>
                                                    </button>
                                                </div>
                                            }
                                            onChange={(date) => {
                                                confirm(
                                                    row.row.original,
                                                    moment(date)
                                                );
                                            }}
                                            minDate={moment().toDate()}
                                        />
                                    </Fragment>
                                ) : (
                                    <div
                                        title={moment(
                                            row.row.original.completeDate
                                        ).format('DD MMM YY')}
                                    >
                                        <DatePicker
                                            customInput={
                                                <div>
                                                    <button className='calendar-cont-confirmed-action'>
                                                        <span>
                                                            {moment(
                                                                row.row.original
                                                                    .completeDate
                                                            ).format(
                                                                'DD MMM YY'
                                                            )}
                                                        </span>
                                                    </button>
                                                </div>
                                            }
                                            selected={moment(
                                                row.row.original.completeDate
                                            ).toDate()}
                                            onChange={(date) => {
                                                confirm(
                                                    row.row.original,
                                                    moment(date)
                                                );
                                            }}
                                            minDate={moment().toDate()}
                                        />
                                    </div>
                                )}
                            </div>
                        ),
                    },
                    {
                        Header: '',
                        accessor: 'edit',
                        disableSortBy: true,
                        width: 40,
                        headerClassName: 'align-center',
                        className: 'align-center text-clip',
                        Cell: (row) => (
                            <IconButton
                                type='edit'
                                onClick={() => {
                                    handleEdit(row.row.original.id);
                                }}
                            />
                        ),
                    },
                    {
                        Header: '',
                        accessor: 'share',
                        disableSortBy: true,
                        width: 40,
                        headerClassName: 'align-center',
                        className: 'align-center text-clip',
                        Cell: (row) => (
                            <IconButton
                                type='share'
                                onClick={() => {
                                    handleShare(row.row.original.id);
                                }}
                            />
                        ),
                    },
                    {
                        Header: '',
                        disableSortBy: true,
                        accessor: 'delete',
                        width: 40,
                        headerClassName: 'align-center',
                        className: 'align-center text-clip',
                        Cell: (row) => (
                            <IconButton
                                type='delete'
                                onClick={() => {
                                    handleDelete(row.row.original.id);
                                }}
                            />
                        ),
                    },
                    {
                        Header: '',
                        accessor: 'close',
                        disableSortBy: true,
                        width: 40,
                        headerClassName: 'align-center',
                        className: 'align-center text-clip',
                        Cell: (row) => (
                            <IconButton
                                type='close'
                                onClick={() => {
                                    handleComplete(row.row.original.id);
                                }}
                            />
                        ),
                    },
                ]}
            />

            <div className='modal-user-select'>
                <ContactModal
                    onAdd={handleShareActionSelection}
                    open={showUserSelectModal}
                    isEmployee={false}
                    text='Share'
                />
            </div>
            <GenericModal
                open={modal}
                success={success}
                message={'Action has been shared successfully.'}
                onClose={() => setModal(false)}
            />
        </div>
    );
}
