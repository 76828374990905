import { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import './components.scss';

export function WidgetHeader({ title }) {
    return (
        <Fragment>
            <div className='widget-header' role='none'>
                <div>
                    <h3 role='none' aria-label={title + 'section'}>
                        {title}
                    </h3>
                </div>

                {/* <div>
                    <IconButton type="inside" route={route}/>

                </div> */}
            </div>
        </Fragment>
    );
}

WidgetHeader.propTypes = {
    title: PropTypes.string,
};
