import { PropTypes } from 'prop-types';
import './BoxBubble.scss';

export function BoxBubble({ image, description, labels }) {
    return (
        <div key={`${image}-dv-bx-bubble`} className='box-bubble p-shadow'>
            <div key={`${image}-image`} className='image'>
                <img
                    alt='User photo'
                    key={`${image}-actor-image`}
                    className='actor-image'
                    src={image}
                />
            </div>

            {labels.map((lab, i) =>
                lab.className ? (
                    <div
                        key={`${image}-${lab.value}-${i}`}
                        className={`${lab.className}`}
                    >
                        <p>
                            {lab.label} <span>{lab.value}</span>
                        </p>
                    </div>
                ) : (
                    <> </>
                )
            )}

            <div key={`${image}-bx-description`} className='description'>
                <p>{description}</p>
            </div>
        </div>
    );
}

BoxBubble.propTypes = {
    image: PropTypes.string,
    description: PropTypes.string,
    labels: PropTypes.array,
};
