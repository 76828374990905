import { PropTypes } from 'prop-types';
import './inputs.scss';

export function AddButton({
    className,
    onClick,
    children,
    disabled,
    autoFocus,
}) {
    return (
        <button
            type='button'
            disabled={disabled}
            onClick={onClick}
            className={`btn add-button highlighted ${className ?? ''}`}
            autoFocus={autoFocus}
        >
            <i className='icon icon-add'></i>
            {children}
        </button>
    );
}

AddButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
};
