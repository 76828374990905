const constants = {
    maxLength: 360,

    errorCodes: {
        forbidden: 'forbidden',
        teamLeadEmpty: 'team-lead-empty-teams-error',
    },
    MeItemsType: {
        SharedAssesment: 0,
        SharedAction: 1,
        PPR: 2,
        Pulse: 3,
        ReceivedRequest: 4,
        GivenRequest: 5,
        FeedbackReceived: 6,
        FeedbackGiven: 7,
        FeedbackCommented: 8,
        FeedbackRated: 9,
        FeedbackAnswered: 10,
    },

    ActionStatus: {
        New: 0,
        Confirmed: 1,
        Completed: 2,
    },
    PprStatus: {
        New: 0,
        Started: 1,
        Completed: 2,
    },
    QuestionType: {
        Suggestion: 0,
        Score: 1,
        YesNo: 2,
        Likert: 3,
        Choice: 4,
    },
    SurveyType: {
        OneOff: 0,
        Recurring: 1,
    },
    Likert: {
        StronglyDisagree: -1,
        Disagree: -2,
        Neutral: -3,
        Agree: -4,
        StronglyAgree: -5,
    },
    Colors: {
        Green: '#63ce2e',
        Amber: '#ffca4c',
        Red: '#ED3241',
        Yes: '#741f82',
        No: '#4b637d',
        StronglyDisagree: '#4b637d',
        Disagree: '#8497aa',
        Neutral: '#7e737c',
        Agree: '#8420A6',
        StronglyAgree: '#581474',
        Default: '#8F9498'
    },
    ConditionValueTypes: {
        String: 0,
        Int: 1,
        Bool: 2,
    }
};

export default constants;
