import { useEffect, useState } from 'react';
import { getSurveyQuestionSegments } from '../../../../services/surveyService';

export function useSegmentsScoreData(survey, question, iteration, team) {
    const [segments, setSegments] = useState(null);

    useEffect(() => {
        async function fetchData() {
            let segments = await getSurveyQuestionSegments(
                survey?.id,
                question?.id,
                team?.id,
                iteration
            );

            setSegments(segments);
        }

        if (survey && question) {
            fetchData();
        }
    }, [team, iteration, survey, question]);

    return segments;
}
