import { Fragment, useState } from 'react';
import {
    Emoji,
    EmojiRating,
} from '../../../../../shared/components/EmojiRating';
import constants from '../../../../../shared/constants';
import { Textarea } from '../../../../../shared/inputs/Textarea';
import { humanizeDiffToday } from '../../../../../shared/lib/dateTimeHelper';
import { FeedbackRequestModal } from './Components/FeedbackRequestModal';
import { UserPhoto } from './Components/UserPhoto';
import { PropTypes } from 'prop-types';
import '../HubItems.scss';

export function FeedbackRequestItem({
    submitComment,
    item,
    submitRate,
    read,
    ratings,
    onFeedbackAnswered,
}) {
    const [showModal, setShowModal] = useState(false);

    let newCommentText = '';

    function getBorderColor() {
        return read ? 'grey-border' : 'blue-border';
    }

    function onCommentEnterPress(e) {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            if (newCommentText) {
                submitComment(item, item.feedbackGiver.id, newCommentText);
            }
        }
    }

    function handleCommentChange({ target: { value } }) {
        newCommentText = value;
    }

    return (
        <div className={'me-items-box ' + getBorderColor()}>
            <div className='main-section'>
                <div className='row row-box'>
                    <UserPhoto
                        giver={item.feedbackReceiver}
                        receiver={item.feedbackGiver}
                    />

                    <div className='end-div'>
                        {item.type == constants.MeItemsType.ReceivedRequest && (
                            <span className='me-item-type'>
                                Feedback request to me
                            </span>
                        )}

                        {item.type == constants.MeItemsType.GivenRequest && (
                            <span className='me-item-type'>
                                Feedback request from me
                            </span>
                        )}

                        <p className='small light'>
                            {humanizeDiffToday(item.created)}
                        </p>
                    </div>
                </div>
                <div className='row row-box'>
                    <div className='from-topic-col'>
                        {item.userIsFeedbackGiver ? 'From:' : 'To:'}
                    </div>
                    <div className='user-title'>
                        {item.userIsFeedbackGiver
                            ? item.feedbackReceiver?.name
                            : item.feedbackGiver?.name}
                    </div>
                </div>
                <div className='row row-box'>
                    <div className='topic-bubble'>
                        <i className='icon icon-topic'></i>
                        {item.topic}
                    </div>
                </div>

                <div className='row row-box text-row'>
                    <span className='item-text'>{item.question}</span>
                </div>

                {!item.answered && item.userIsFeedbackGiver && (
                    <Fragment>
                        <div className='row row-box'>
                            <button
                                name='surveysRedirect'
                                className='btn highlighted button-survey-respond'
                                onClick={() => setShowModal(true)}
                            >
                                Respond
                            </button>
                        </div>

                        <FeedbackRequestModal
                            feedbackId={item.id}
                            onAnswer={onFeedbackAnswered}
                            onClosedModal={() => setShowModal(false)}
                            open={showModal}
                        />
                    </Fragment>
                )}

                {item.answered && (
                    <Fragment>
                        <div className='comment-box'>
                            <div className='row'>
                                <div className='col-md-1 comment-image'>
                                    <UserPhoto giver={item.feedbackGiver} />
                                </div>
                                <div className='col-md-11'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <div className='user-title'>
                                                {item.feedbackGiver.name}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <p className='small light'>
                                                {humanizeDiffToday(
                                                    item.answerDate
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div
                                            className={`${
                                                item.hasScore ||
                                                item.yesNo != null
                                                    ? 'col-xs-9 col-md-11'
                                                    : 'text-description'
                                            } `}
                                        >
                                            <div> {item.text}</div>
                                        </div>
                                        <div
                                            className={`${
                                                item.hasScore ||
                                                item.yesNo != null
                                                    ? 'col-xs-3 col-md-1'
                                                    : ''
                                            } `}
                                        >
                                            {item.hasScore && (
                                                <div className='answer-score-container'>
                                                    <span className='answer-score-bold'>
                                                        {item.score}
                                                    </span>
                                                    <span className='answer-score-separator'>
                                                        {'/'}
                                                    </span>
                                                    <span>10</span>
                                                </div>
                                            )}
                                            {item.yesNo != null && (
                                                <div className='answer-score-container'>
                                                    <span
                                                        className={
                                                            item.yesNo
                                                                ? 'answer-score-bold'
                                                                : 'answer-score'
                                                        }
                                                    >
                                                        Y
                                                    </span>
                                                    <span className='answer-score-separator'>
                                                        {'/'}
                                                    </span>
                                                    <span
                                                        className={
                                                            !item.yesNo
                                                                ? 'answer-score-bold'
                                                                : 'answer-score'
                                                        }
                                                    >
                                                        N
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='rate-comment-row'>
                            {item.hasBeenRated && (
                                <div className='box-reaction'>
                                    <Emoji
                                        description={
                                            ratings.find(
                                                (x) =>
                                                    x.id ===
                                                    item.reactions[0].emoji.id
                                            ).description
                                        }
                                        value={
                                            ratings.find(
                                                (x) =>
                                                    x.id ===
                                                    item.reactions[0].emoji.id
                                            ).value
                                        }
                                    />
                                </div>
                            )}

                            {!item.userIsFeedbackGiver &&
                                !item.hasBeenRated && (
                                    <div className='box-reaction'>
                                        <EmojiRating
                                            ratings={ratings}
                                            onChange={(rating) =>
                                                submitRate(item, rating)
                                            }
                                        />
                                    </div>
                                )}

                            {item.hasBeenCommented && (
                                <div className='box-reaction'>
                                    <i className='icon-comment-active' />{' '}
                                    <span>Responded</span>
                                </div>
                            )}

                            {!item.userIsFeedbackGiver &&
                                !item.hasBeenCommented && (
                                    <div className='box-reaction'>
                                        <i className='icon-comments' />{' '}
                                        <span>Respond</span>
                                    </div>
                                )}
                        </div>

                        {(!item.userIsFeedbackGiver ||
                            (item.userIsFeedbackGiver &&
                                item.hasBeenCommented)) && (
                            <div className='comment-box'>
                                <div className='row'>
                                    <div className='col-md-1 comment-image'>
                                        <UserPhoto
                                            giver={item.feedbackReceiver}
                                        />
                                    </div>
                                    <div className='col-md-11'>
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='user-title'>
                                                    {item.feedbackReceiver.name}
                                                </div>
                                            </div>
                                            {item.hasBeenReacted && (
                                                <div className='col-md-4'>
                                                    <p className='small light'>
                                                        {humanizeDiffToday(
                                                            item.reactions[0]
                                                                .date
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <div className='row'>
                                            <div
                                                className='col-xs-12'
                                                style={{ paddingRight: 0 }}
                                            >
                                                {item.hasBeenCommented ? (
                                                    <div className='comment-text-wrap'>
                                                        {' '}
                                                        {
                                                            item.reactions[0]
                                                                .commentText
                                                        }
                                                    </div>
                                                ) : (
                                                    <Textarea
                                                        placeholder='Write a comment...'
                                                        className='commentText'
                                                        onKeyDown={
                                                            onCommentEnterPress
                                                        }
                                                        onChange={
                                                            handleCommentChange
                                                        }
                                                        maxLength={
                                                            constants.maxLength
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    );
}

FeedbackRequestItem.propTypes = {
    submitComment: PropTypes.func,
    submitRate: PropTypes.func,
    item: PropTypes.object,
    read: PropTypes.bool,
    ratings: PropTypes.array,
    onFeedbackAnswered: PropTypes.func,
};
