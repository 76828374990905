import { Fragment, useEffect, useState } from 'react';
import {
    getWidgetExtremeFeedback,
    getWidgetExtremeFeedbackByEntity,
    getWidgetExtremeFeedbackByEntityForTeam,
    getWidgetExtremeFeedbackForTeam,
} from '../../../services/extremeFeedbackService';
import { TotalChart } from '../charts/RadialChartIcon';
import { useFormattedMessage } from '../lib/helper';
import { useRagIntervals } from '../lib/useRagIntervals';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { WidgetTable } from './components/WidgetTable';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function ExtremeFeedbackWidget({ from, to, teamId }) {
    const [concerningData, setConcerningData] = useState([]);

    const [exceptionalData, setExceptionalData] = useState([]);

    const [extremeFeedback, setExtremeFeedback] = useState({});

    const [loading, setLoading] = useState(true);

    const limit = 3;

    const { getHighestRag, getLowestRag } = useRagIntervals();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            let promises = teamId
                ? [
                      getWidgetExtremeFeedbackForTeam(teamId, from, to),
                      getWidgetExtremeFeedbackByEntityForTeam(
                          teamId,
                          from,
                          to,
                          limit,
                          true
                      ),
                      getWidgetExtremeFeedbackByEntityForTeam(
                          teamId,
                          from,
                          to,
                          limit,
                          false
                      ),
                  ]
                : [
                      getWidgetExtremeFeedback(from, to),
                      getWidgetExtremeFeedbackByEntity(from, to, limit, true),
                      getWidgetExtremeFeedbackByEntity(from, to, limit, false),
                  ];

            let [extreme, excData, conData] = await Promise.all(promises);

            setExtremeFeedback(extreme);

            setExceptionalData(fillArray(excData));

            setConcerningData(fillArray(conData));

            setLoading(false);
        }

        if (from && to) {
            fetchData();
        }
    }, [from, to, teamId]);

    function fillArray(array) {
        let result = [...array];
        while (result.length < limit) {
            result.push({
                entityName: '',
                averageScore: null,
            });
        }

        return result;
    }

    const columns = [
        {
            Header: '',
            accessor: (d) => d,
            id: 'numeration',
            Cell: (row) => <p className='numeration'>{row.row.index + 1}.</p>,
        },
        {
            Header: useFormattedMessage(
                teamId ? 'widgets.table.headerUser' : 'widgets.table.headerTeam'
            ),
            accessor: 'entityName',
            Cell: (row) => (
                <p title={row.value} style={{ width: 100 }}>
                    {row.value}
                </p>
            ),
        },
        {
            Header: useFormattedMessage('extremeFeedbackWidget.body.table2'),
            accessor: 'frequency',
            headerClassName: 'text-center',
            className: 'text-center numeration',
            Cell: (row) => (
                <Fragment>
                    {typeof row.value === 'number' ? row.value : '-'}
                </Fragment>
            ),
        },
    ];

    return (
        <div className='extreme-feedback-widget' role='section'>
            <WidgetLayout
                information={
                    <Fragment>
                        {useFormattedMessage(
                            'extremeFeedbackWidget.header.information'
                        )}
                        <br />
                        {useFormattedMessage(
                            'extremeFeedbackWidget.header.information2'
                        )}
                    </Fragment>
                }
                loading={loading}
            >
                <WidgetHeader
                    title={useFormattedMessage(
                        'extremeFeedbackWidget.header.title'
                    )}
                    route={''}
                />
                <hr />

                <div className='content'>
                    <div className='chart'>
                        <label>
                            {useFormattedMessage(
                                'extremeFeedbackWidget.body.total'
                            )}
                        </label>
                        <TotalChart
                            total={
                                extremeFeedback.exceptionalFeedbackFrequency
                                    ? extremeFeedback.exceptionalFeedbackFrequency
                                    : 0
                            }
                            color={
                                extremeFeedback.exceptionalFeedbackFrequency !==
                                null
                                    ? getHighestRag()?.hexColor
                                    : null
                            }
                            showText={true}
                        />
                    </div>
                    <div className='tables'>
                        <label>
                            {useFormattedMessage(
                                'extremeFeedbackWidget.body.highest'
                            )}
                        </label>
                        <WidgetTable data={exceptionalData} columns={columns} />
                    </div>
                </div>
                <hr />
                <WidgetHeader
                    title={useFormattedMessage(
                        'extremeFeedbackWidget.header.title2'
                    )}
                    route={''}
                />
                <hr />

                <div className='content'>
                    <div className='chart'>
                        <label>
                            {useFormattedMessage(
                                'extremeFeedbackWidget.body.total'
                            )}
                        </label>
                        <TotalChart
                            total={
                                extremeFeedback.concerningFeedbackFrequency
                                    ? extremeFeedback.concerningFeedbackFrequency
                                    : 0
                            }
                            color={
                                extremeFeedback.concerningFeedbackFrequency !==
                                null
                                    ? getLowestRag()?.hexColor
                                    : null
                            }
                            showText={true}
                        />
                    </div>
                    <div className='tables'>
                        <label>
                            {useFormattedMessage(
                                'extremeFeedbackWidget.body.lowest'
                            )}
                        </label>
                        <WidgetTable data={concerningData} columns={columns} />
                    </div>
                </div>
            </WidgetLayout>
        </div>
    );
}

ExtremeFeedbackWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    teamId: PropTypes.string,
};
