import moment from 'moment';
import { get, post } from '../components/shared/lib/axios';

const controllerRoute = 'feedback';

export async function getCompanyFeedback(startDate, endDate) {
    return get(
        `${controllerRoute}/company-team/${moment(
            startDate
        ).toISOString()}/${moment(endDate).toISOString()}`
    );
}

export async function getRecentContacts(userId) {
    return get(`${controllerRoute}/recent-contacts`, { userId });
}

export async function getTeamFeedback(teamId, startDate, endDate) {
    return get(
        `${controllerRoute}/company-team/${moment(
            startDate
        ).toISOString()}/${moment(endDate).toISOString()}`,
        { teamId: teamId }
    );
}

export async function getFeedback(id) {
    return get(`${controllerRoute}/${id}`);
}

export async function feedbackAsk(req, userTo) {
    delete userTo.teams;
    let newReq = { ...req };
    newReq.FeedbackGiver = userTo;
    newReq.isExternal = userTo?.isExternal;
    delete newReq.FeedbackReceiver.teams;

    return post(`/feedback/ask`, newReq);
}

export async function feedbackGive(req, userTo) {
    delete userTo.teams;
    let newReq = { ...req };
    newReq.FeedbackReceiver = userTo;
    delete newReq.FeedbackGiver.teams;
    return post(`/feedback/give`, newReq);
}

export async function react(req, actorId) {
    return post('feedback/react', req, { actorId: actorId });
}

export async function getFeedbackCount(teamId, userId, from, to, received) {
    return get(`${controllerRoute}/count`, {
        teamId,
        userId,
        from,
        to,
        received,
    });
}

export async function getMeanScoreOfGivenFeedback(from, to, userId, teamId) {
    return get(`feedback/given-score-mean`, {
        from,
        to,
        userId,
        teamId,
    });
}

export async function getMeanScoreOfReceivedFeedback(from, to, userId, teamId) {
    return get(`feedback/received-score-mean`, {
        from,
        to,
        userId,
        teamId,
    });
}
