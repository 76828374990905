import { Fragment } from 'react';
import { PropTypes } from 'prop-types';
import './UserPhoto.scss';

export function UserPhoto({ giver, receiver, anonymous, receiverAnonymous }) {
    return (
        <div className='user-photo-component' aria-hidden='true'>
            {giver && !anonymous ? (
                <img
                    alt={`${!anonymous ? giver?.name : 'Anonymous'} photo`}
                    className='actor-image'
                    src={giver?.image}
                />
            ) : (
                anonymous && (
                    <div className='icon-box'>
                        <i
                            role='img'
                            aria-label='Anonymous'
                            className='icon-eye-anonymous'
                        ></i>
                    </div>
                )
            )}

            {!!receiver && (
                <Fragment>
                    {(!!giver || !!anonymous) && (
                        <div className='arrow-container'>
                            <i
                                role='img'
                                aria-label='Arrow right'
                                className='icon-arrow-right'
                            ></i>
                        </div>
                    )}
                    {receiverAnonymous ? (
                        <div className='icon-box'>
                            <i
                                role='img'
                                aria-label='Anonymous'
                                className='icon-eye-anonymous'
                            ></i>
                        </div>
                    ) : (
                        <img
                            alt={`${
                                !anonymous ? receiver?.name : 'Anonymous'
                            } photo`}
                            className='actor-image'
                            src={receiver?.image}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
}

UserPhoto.propTypes = {
    giver: PropTypes.object,
    receiver: PropTypes.object,
    anonymous: PropTypes.bool,
    receiverAnonymous: PropTypes.bool,
};
