import { useEffect, useState } from 'react';
import { useRagIntervals } from '../lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import constants from '../constants';
import './components.scss';

export function ProgressBar({ title, value, target }) {
    const [valueStyle, setValueStyle] = useState({ width: '0%' });

    const [targetStyle, setTargetStyle] = useState({ width: '0%' });

    const [color, setColor] = useState(null);

    const [load, setLoad] = useState(false);

    const { getColor } = useRagIntervals();

    useEffect(() => {
        setColor(getColor(value, constants.QuestionType.Score));

        if (value !== null) {
            setValueStyle({
                width: `${value * 10}%`,
            });
        }

        if (target != null) {
            setTargetStyle({
                width: `${(target - value) * 10}%`,
            });
        }
    }, [value, target]);

    useEffect(() => {
        setTimeout(() => setLoad(true), 1000);
    }, []);

    return (
        <div
            className={`pulse-progress-bar ${
                value === null && target === null ? 'disabled' : ''
            }`}
        >
            {title && <div className='title'>{title} </div>}
            <div className='meter'>
                <div className='progress-bar' style={valueStyle}>
                    <div
                        className='bar score'
                        style={{
                            backgroundColor: color,
                            borderColor: color,
                        }}
                    >
                        <p aria-describedby={value + ' field'}>{value ?? ''}</p>
                    </div>
                </div>

                {target - value > 0 && (
                    <div
                        className={`progress-bar target ${
                            load ? 'display' : ''
                        }`}
                        style={targetStyle}
                    >
                        <div
                            className='bar target'
                            style={{
                                backgroundColor:
                                    target !== null ? '#424953' : 'transparent',
                                borderColor:
                                    target !== null ? '#424953' : 'transparent',
                            }}
                        >
                            <p aria-describedby={target + ' field'}>
                                {target ?? ''}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

ProgressBar.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    target: PropTypes.number,
};

export function ProgressBarDistribution({
    title,
    value,
    color,
    onClick,
    selected,
    clickable,
}) {
    const [valueStyle, setValueStyle] = useState({ minWidth: '0%' });

    const [localColor, setColor] = useState(null);

    const { getColor } = useRagIntervals();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setColor(getColor(value));

        if (typeof value === 'number' && !isNaN(value)) {
            setValueStyle({
                minWidth: `${value}%`,
            });
        } else {
            setValueStyle({
                minWidth: '0%',
            });
        }

        setLoading(false);
    }, [value]);

    return (
        <div
            onClick={() =>
                clickable ? onClick(selected ? null : title === 'Y') : void 0
            }
            className={`pulse-progress-bar-distribution ${
                value === null ? 'disabled' : ''
            } ${clickable ? 'clickable' : ''} ${selected ? 'selected' : ''}`}
        >
            {title && <div className='title'>{title} </div>}
            <div className='meter'>
                {!loading && (
                    <div className='score' style={valueStyle}>
                        <div
                            className='bar'
                            style={{
                                backgroundColor: color ? color : localColor,
                                borderColor: color ? color : localColor,
                            }}
                        >
                            <p>
                                {typeof value === 'number' && !isNaN(value)
                                    ? `${value}%`
                                    : '0%'}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

ProgressBarDistribution.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    clickable: PropTypes.bool,
};

export function BarDistribution({ title, value, color, onClick, selected }) {
    const [valueStyle, setValueStyle] = useState({ height: '0%' });

    const [rgbaColor, setRgbaColor] = useState(null);

    const { hexToRgbA } = useRagIntervals();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (color) {
            setRgbaColor(hexToRgbA(color, 0.3));
        }
    }, [color]);

    useEffect(() => {
        setLoading(true);

        if (typeof value === 'number') {
            setValueStyle({
                height: `${value}%`,
            });
        }

        setLoading(false);
    }, [value]);

    return (
        <div
            onClick={() =>
                typeof onClick === 'function'
                    ? onClick(selected ?? null)
                    : void 0
            }
            className={`pulse-bar ${
                selected != null && selected != title ? 'disabled' : ''
            } ${typeof onClick === 'function' ? 'clickable' : ''} ${
                selected == title ? 'selected' : ''
            }`}
        >
            <div className='meter' style={{ backgroundColor: rgbaColor }}>
                {!loading && (
                    <div className='score' style={valueStyle}>
                        <div
                            className='score-bar'
                            style={{
                                backgroundColor: color,
                                borderColor: color,
                            }}
                        >
                            <p>
                                {typeof value === 'number' && !isNaN(value)
                                    ? `${value}%`
                                    : '0%'}
                            </p>
                        </div>
                    </div>
                )}
            </div>

            {title && <label>{title} </label>}
        </div>
    );
}

BarDistribution.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.number,
    color: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
};
