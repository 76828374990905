import moment from 'moment';
import { Fragment, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { RadarGraph } from '../../../../../shared/charts/RadarGraph';
import { ProgressBar } from '../../../../../shared/components/ProgressBar';
import { GenericModal } from '../../../../../shared/modal/GenericModal';
import { PropTypes } from 'prop-types';
import { ContactModal } from '../../../hub/widgets/ContactModal';
import { shareAssessment } from '../../../../../../services/assessService';
import './components.scss';

export function PPRBox({ ppr }) {
    const pill = {
        graph: 'graph',
        bar: 'bar',
    };

    const [show, setShow] = useState(false);

    const [activePill, setActivePill] = useState(pill.bar);

    const [redirectToPPR, setRedirectToPPR] = useState(false);

    const [modal, setModal] = useState(false);

    function getDateFormatted() {
        let rDate = '';

        switch (ppr.status) {
            case 0:
                rDate = `Not started`;
                break;
            case 1:
                {
                    let duration = moment.duration(
                        moment(ppr.startDate).diff(moment.utc())
                    );
                    rDate = `Started ${duration.humanize(true)}`;
                }
                break;
            default:
                {
                    let duration = moment.duration(
                        moment(ppr.completedDate).diff(moment.utc())
                    );
                    rDate = `Last completed ${duration.humanize(true)}`;
                }
                break;
        }

        return rDate;
    }

    async function handleSendPPR(selectedUsersAndTeams) {
        setModal(false);

        if (selectedUsersAndTeams.length > 0) {
            let users = selectedUsersAndTeams
                .filter((x) => !x.isTeam)
                .map((x) => x.id);

            let teams = selectedUsersAndTeams
                .filter((x) => x.isTeam)
                .map((x) => x.id);

            await shareAssessment(ppr.id, {
                Users: users,
                Teams: teams,
            });

            setShow(true);
        }
    }

    return (
        <div className='ppr-box p-shadow'>
            <div className='title-section'>
                <h1 role='none' aria-label={ppr.category.title + ' section'}>
                    {ppr.category.title}
                    <span aria-hidden={true}>.</span>{' '}
                </h1>
                <span> {getDateFormatted()} </span>
            </div>
            <hr role='none' aria-label='contents:' />
            <div className='content'>
                <div className='content-section'>
                    {activePill === pill.bar ? (
                        <div className='progress-bar-section'>
                            {ppr.pprQuestions.map((q, i) => (
                                <ProgressBar
                                    key={`progress-bar-${ppr.id}-${q.id}-${i}`}
                                    title={q.title}
                                    value={q.value}
                                    target={q.targetValue}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className='graph-section'>
                            <RadarGraph
                                key={`radar-graph-${ppr.id}`}
                                labels={ppr.pprQuestions.map((x) => x.title)}
                                values={ppr.pprQuestions.map((x) => x.value)}
                                targets={ppr.pprQuestions.map(
                                    (x) => x.targetValue
                                )}
                            />
                        </div>
                    )}
                </div>

                <div className='right'>
                    <div className='switch'>
                        {ppr.pprQuestions.length > 2 ? (
                            <Fragment>
                                <a
                                    role='button'
                                    tabIndex={0}
                                    className={
                                        activePill === pill.bar ? 'active' : ''
                                    }
                                    onClick={() => setActivePill(pill.bar)}
                                    onKeyPress={(ev) =>
                                        ev.key === 'Enter' &&
                                        setActivePill(pill.bar)
                                    }
                                    aria-pressed={activePill === pill.bar}
                                    aria-label='Show as bar'
                                >
                                    <i
                                        aria-hidden={true}
                                        className='icon-dials'
                                    ></i>
                                </a>
                                <a
                                    role='button'
                                    tabIndex={0}
                                    className={
                                        activePill === pill.graph
                                            ? 'active'
                                            : ''
                                    }
                                    onClick={() => setActivePill(pill.graph)}
                                    onKeyPress={(ev) =>
                                        ev.key === 'Enter' &&
                                        setActivePill(pill.graph)
                                    }
                                    aria-pressed={activePill === pill.graph}
                                    aria-label='Show as graph'
                                >
                                    <i
                                        aria-hidden={true}
                                        className='icon-spider'
                                    ></i>{' '}
                                </a>
                            </Fragment>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className='button-section'>
                        <div className='buttons'>
                            <button
                                className='btn'
                                disabled={!ppr.completedDate}
                                onClick={() =>
                                    ppr.completedDate
                                        ? setModal(true)
                                        : () => void 0
                                }
                            >
                                Send
                            </button>
                            <button
                                className='btn highlighted'
                                onClick={() => setRedirectToPPR(true)}
                            >
                                {ppr.status === 0 || ppr.status === 2
                                    ? 'Start'
                                    : 'Continue'}
                            </button>
                        </div>
                    </div>
                </div>

                {redirectToPPR && (
                    <Navigate to={`/me/assess/assessment/${ppr.category.id}`} />
                )}
            </div>

            <div className='modal-user-select'>
                <ContactModal
                    onAdd={handleSendPPR}
                    open={modal}
                    isEmployee={false}
                    text='Send'
                />
            </div>

            <GenericModal
                open={show}
                success={show}
                loading={false}
                message={'Assessment has been shared successfully.'}
                onClose={() => setShow(false)}
            />
        </div>
    );
}

PPRBox.propTypes = {
    ppr: PropTypes.object,
    userId: PropTypes.string,
    isEmployee: PropTypes.bool,
};
