import { useEffect, useState } from 'react';
import { HomePageTemplate } from '../../../shared/templates/HomePageTemplate';
import './Invitation.scss';

export function InviteSuccess() {
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (redirect) {
            window.location = `${window.location.origin}/`;
        }
    }, [redirect]);

    return (
        <HomePageTemplate className='invitation success'>
            <div className='login header-logo'>
                <img
                    className='home-page-logo'
                    alt='main logo'
                    src='logo.svg'
                />
                <h2>Welcome to Pulse 360!</h2>
                <br />
                <h3>You have signed up successfully.</h3>

                <div className='buttons'>
                    <button
                        type='button'
                        className='btn highlighted'
                        onClick={() => setRedirect(true)}
                    >
                        LOGIN
                    </button>
                </div>
                <div className='store-buttons'>
                    <div className='store-image'>
                        <a
                            rel='noreferrer'
                            target='_blank'
                            href='https://play.google.com/store/apps/details?id=com.fsp.pulse360'
                        >
                            <img
                                alt='Get it on Google Play'
                                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                            />
                        </a>
                    </div>

                    <div className='store-image apple-custom'>
                        <a
                            rel='noreferrer'
                            target='_blank'
                            href='https://itunes.apple.com/us/app/pulse-360/id1451824425?ls=1&mt=8'
                        >
                            <img
                                alt='Get it on App Store'
                                src='app-store.svg'
                            />{' '}
                        </a>
                    </div>
                </div>
            </div>
        </HomePageTemplate>
    );
}
