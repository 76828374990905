import { get } from '../components/shared/lib/axios';

export async function getOrganisationalFeedback(from, to, entityId) {
    return get(`organisational-feedback`, { from, to, entityId });
}

export async function getTeamOverview(from, to) {
    return get(`organisational-feedback/insights/team-overview`, {
        from,
        to,
    });
}

export async function getOrganisationalFeedbackInsights(from, to, entityId) {
    return get(`organisational-feedback/insights`, {
        from,
        to,
        entityId,
    });
}

export async function getOrganisationalFeedbackByOrganisation(
    userId,
    from,
    to
) {
    return get(`users/${userId}/organisational-feedback/organisation`, {
        from,
        to,
    });
}

export async function getOrganisationalFeedbackByTopic(userId, from, to) {
    return get(`users/${userId}/organisational-feedback/topic`, {
        from,
        to,
    });
}
