import { Fragment } from 'react';
import { BarDistribution } from '../../../../components/ProgressBar';
import { PropTypes } from 'prop-types';
import { useState } from 'react';

export function SentimentScoreDistributionChart({ data, onClick, clickable }) {
    const [selected, setSelected] = useState(null);

    function handleClick(value) {
        value = selected === value ? null : value;
        setSelected(value);
        onClick(value);
    }

    return (
        <Fragment>
            <div className='vertical'>
                {data?.map((x) => (
                    <BarDistribution
                        key={`distribution-${x?.title}`}
                        title={x?.title}
                        onClick={() => handleClick(x.key)}
                        selected={selected}
                        color={x?.color}
                        value={x?.value}
                        clickable={clickable}
                    />
                ))}
            </div>
        </Fragment>
    );
}

SentimentScoreDistributionChart.propTypes = {
    data: PropTypes.array,
    onClick: PropTypes.func,
    selected: PropTypes.string,
    clickable: PropTypes.bool,
};
