import { Fragment, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { InformationTooltip } from '../../../../shared/components/InformationTooltip';
import { Loading } from '../../../../shared/loadings/Loading';
import { PropTypes } from 'prop-types';
import './components.scss';

export function WLayout({
    title,
    info,
    children,
    className,
    loading,
    to,
    state,
}) {
    const [redirect, setRedirect] = useState(null);
    return (
        <div
            className={`w-layout p-shadow interactive ${className ?? ''}`}
            aria-label={`Press enter to navigate to ${title}`}
            tabIndex={0}
        >
            <div className='header'>
                <h3 role='none'>{title}</h3>
            </div>
            {loading && (
                <div className='w-loading'>
                    <Loading />
                </div>
            )}

            {to && !loading && (
                <Fragment>
                    <div
                        className='goto-label'
                        role='button'
                        onClick={() => setRedirect(to)}
                    >
                        {to?.label ?? (
                            <i
                                tabIndex={0}
                                aria-label={`Press enter to navigate to ${title}`}
                                className='icon icon-long-arrow-right'
                            ></i>
                        )}
                    </div>
                    {redirect && <Navigate to={redirect} state={state} />}
                </Fragment>
            )}
            <div className={`w-body `}>{children}</div>
            {!!info && (
                <div className='w-footer'>
                    <InformationTooltip text={info} />
                </div>
            )}
        </div>
    );
}

WLayout.propTypes = {
    title: PropTypes.string,
    info: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]),
    children: PropTypes.object,
    className: PropTypes.string,
    loading: PropTypes.bool,
    to: PropTypes.object,
    state: PropTypes.object,
};
