import { useSession } from './useSession'

export function useAuthorization(pagePermissions) {

    const session = useSession();

    let allowedPermissions = pagePermissions
                                ?.split(';')
                                ?.map((x) => x.trim());

    if (!session?.permissions) {
        return 'NOT_LOGGED';
    }

    if (allowedPermissions?.some((perm) => session?.permissions.includes(perm))) {
        return 'OK';
    }

    return 'FORBIDDEN';

}