import { Fragment, useState } from 'react';
import { Page } from '../../../shared/layout/Page';
import { DateSelector } from '../../../shared/utils/DateSelector';
import { ConnectionsWidget } from '../../../shared/widgets/ConnectionsWidget';
import { ExtremeFeedbackWidget } from '../../../shared/widgets/ExtremeFeedbackWidget';
import { FeedbackScoreWidget } from '../../../shared/widgets/FeedbackScoreWidget';
import { PulseIndexWidget } from '../../../shared/widgets/PulseIndexWidget';
import { SentimentScoreWidget } from '../../../shared/widgets/SentimentScoreWidget';
import { TopTopicsWidget } from '../../../shared/widgets/TopTopicsWidget';
import './CPeerToPeer.scss';

export function CPeerToPeer() {
    const [dates, setDates] = useState(null);

    return (
        <Page title='Peer Feedback' className='c-peer-to-peer'>
            <Fragment>
                <div className='header'>
                    <label>Time period:</label>
                    <DateSelector
                        onChange={(from, to, months) =>
                            setDates({ from, to, months })
                        }
                    />
                </div>

                {!!dates && (
                    <div className='widgets'>
                        <FeedbackScoreWidget
                            from={dates?.from}
                            to={dates?.to}
                            months={dates?.months}
                        />
                        <PulseIndexWidget from={dates?.from} to={dates?.to} />
                        <ConnectionsWidget
                            from={dates?.from}
                            to={dates?.to}
                            months={dates?.months}
                        />
                        <ExtremeFeedbackWidget
                            from={dates?.from}
                            to={dates?.to}
                        />
                        <SentimentScoreWidget
                            from={dates?.from}
                            to={dates?.to}
                        />

                        <TopTopicsWidget from={dates?.from} to={dates?.to} />
                    </div>
                )}
            </Fragment>
        </Page>
    );
}
