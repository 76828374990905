import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import {
    getWidgetFeedbackAverageScore,
    getWidgetFeedbackAverageScoreForTeam,
    getWidgetFeedbackAverageScoreByEntity,
    getWidgetFeedbackAverageScoreByEntityForTeam,
} from '../../../services/feedbackScoreService';
import { ScoreChart } from '../charts/RadialChartIcon';
import { useFormattedMessage } from '../lib/helper';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { WidgetTable } from './components/WidgetTable';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function FeedbackScoreWidget({ from, to, teamId, months }) {
    const [received, setReceived] = useState(true);

    const [tableData, setTableData] = useState([]);

    const [currentAverage, setCurrentAverage] = useState(null);

    const [previousAverage, setPreviousAverage] = useState(null);

    const [loading, setLoading] = useState(true);

    const limit = 3;

    useEffect(() => {
        async function fetchData() {
            if (!teamId) {
                var previousTo = moment(from);

                var previousFrom = moment(from).add(-months, 'months');

                await setAverages([
                    getWidgetFeedbackAverageScore(from, to),
                    getWidgetFeedbackAverageScore(previousFrom, previousTo),
                ]);
            }
        }

        if (from && to) {
            fetchData();
        }
    }, [from, to]);

    async function setAverages(promises) {
        let [fAverage, sAverage] = await Promise.all(promises);

        if (!fAverage) {
            fAverage = null;
        }

        if (!sAverage) {
            sAverage = null;
        }

        setCurrentAverage(fAverage);
        setPreviousAverage(sAverage);
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            if (teamId) {
                var previousTo = moment(from);

                var previousFrom = moment(from).add(-months, 'months');

                await setAverages([
                    getWidgetFeedbackAverageScoreForTeam(
                        teamId,
                        from,
                        to,
                        received
                    ),
                    getWidgetFeedbackAverageScoreForTeam(
                        teamId,
                        previousFrom,
                        previousTo,
                        received
                    ),
                ]);
            }

            let promise = teamId
                ? getWidgetFeedbackAverageScoreByEntityForTeam(
                      teamId,
                      from,
                      to,
                      received,
                      limit
                  )
                : getWidgetFeedbackAverageScoreByEntity(
                      from,
                      to,
                      received,
                      limit
                  );

            let data = await promise;

            while (data.length < limit) {
                data.push({
                    entityName: '',
                    averageScore: null,
                    highestScore: null,
                    lowestScore: null,
                });
            }

            setTableData(data);

            setLoading(false);
        }

        if (from && to) {
            fetchData();
        }
    }, [received, from, to, teamId]);

    return (
        <div className='feedback-score-widget'>
            <WidgetLayout
                information={
                    <Fragment>
                        Average score represents all peer feedback issued with a
                        score component.
                        <br />
                        It does not include employee voice, peer Y/N feedback or
                        comment only feedback.
                    </Fragment>
                }
                loading={loading}
            >
                <WidgetHeader title={'Average Score'} route={''} />
                <hr />
                <div className='received-given-selector'>
                    <button
                        className={`btn received ${
                            received ? 'highlighted' : ''
                        }`}
                        onClick={() => setReceived(true)}
                        aria-pressed={received}
                    >
                        Received
                    </button>

                    <button
                        className={`btn given ${
                            !received ? 'highlighted' : ''
                        }`}
                        onClick={() => setReceived(false)}
                        aria-pressed={!received}
                    >
                        Given
                    </button>
                </div>
                <div className='radial-chart-section'>
                    <div className='radial-chart-container'>
                        <label> Current Average </label>

                        <ScoreChart score={currentAverage} showText={true} />
                    </div>
                    <div>
                        <div className='radial-chart-container'>
                            <label>
                                {useFormattedMessage(
                                    'feedbackScoreWidget.body.title2'
                                )}
                            </label>
                            <ScoreChart
                                className='second'
                                score={previousAverage}
                                showText={true}
                            />
                        </div>
                    </div>
                </div>
                <WidgetTable
                    data={tableData}
                    columns={[
                        {
                            Header: '',
                            accessor: (d) => d,
                            id: 'numeration',
                            Cell: (row) => (
                                <p className='numeration' role='none'>
                                    {row.row.index + 1}.
                                </p>
                            ),
                        },
                        {
                            Header: teamId ? 'User' : 'Team',
                            accessor: 'entityName',
                            Cell: (row) => (
                                <p
                                    role='cell'
                                    className='text-ellipsis'
                                    title={row.value}
                                    aria-labelledby={row.value}
                                    style={{ maxWidth: 90 }}
                                >
                                    {row.value}
                                </p>
                            ),
                        },
                        {
                            Header: 'Average Score',
                            accessor: 'averageScore',
                            headerClassName: 'text-center',
                            Cell: (row) => (
                                <div
                                    role='cell'
                                    aria-label={row.value + ' out of 10'}
                                >
                                    <ScoreChart
                                        className='radial-chart-in-table'
                                        score={row.value}
                                        showText={true}
                                    />
                                </div>
                            ),
                        },
                        {
                            Header: 'Lowest Score',
                            accessor: 'lowestScore',
                            headerClassName: 'text-center',
                            Cell: (row) => (
                                <div
                                    role='cell'
                                    aria-label={row.value + ' out of 10'}
                                >
                                    <ScoreChart
                                        className='radial-chart-in-table'
                                        score={row.value}
                                        showText={true}
                                    />
                                </div>
                            ),
                        },
                        {
                            Header: 'Highest Score',
                            accessor: 'highestScore',
                            headerClassName: 'text-center',
                            Cell: (row) => (
                                <div
                                    role='cell'
                                    aria-label={row.value + ' out of 10'}
                                >
                                    <ScoreChart
                                        className='radial-chart-in-table'
                                        score={row.value}
                                        showText={true}
                                    />
                                </div>
                            ),
                        },
                    ]}
                />
            </WidgetLayout>
        </div>
    );
}

FeedbackScoreWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    teamId: PropTypes.string,
    months: PropTypes.number,
};
