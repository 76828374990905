import { Fragment } from 'react';
import { AuthControl } from '../../pages/authentication/AuthControl';
import { useSession } from '../../shared/lib/useSession';
import { SectionButton } from './Components';
import { PropTypes } from 'prop-types';

export function SideBarMyTeam({ onBack }) {
    const session = useSession();

    return (
        <Fragment>
            <div role='menu'>
                <a
                    tabIndex={0}
                    aria-label='Click to go back'
                    className='title'
                    onClick={() => onBack()}
                >
                    <i
                        aria-label='Click to go back'
                        className='icon-long-arrow-left'
                    ></i>
                    <h5>My Team</h5>
                </a>

                <AuthControl
                    permissions='team::feedback'
                    authorized={
                        <Fragment>
                            <div className='sub-title'>
                                <h6>Feedback</h6>
                            </div>
                            <SectionButton
                                to={'/my-team/feedback/overview'}
                                icon={'icon-insight-active'}
                                title='Overview'
                            >
                                Overview
                            </SectionButton>
                            <SectionButton
                                to={'/my-team/feedback/pulse'}
                                icon={'icon-pulse'}
                                title='Pulse Surveys'
                            >
                                Pulse Surveys
                            </SectionButton>
                            <SectionButton
                                to={'/my-team/feedback/peer-to-peer'}
                                icon={'icon-peer-active'}
                                title='Peer Feedback'
                            >
                                Peer
                            </SectionButton>
                            <SectionButton
                                to={'/my-team/feedback/organisation'}
                                icon={'icon-megaphone-active'}
                                title='Employee Voice'
                            >
                                Employee Voice
                            </SectionButton>
                        </Fragment>
                    }
                />

                {session?.isPerformance && (
                    <AuthControl
                        permissions='team::assess'
                        authorized={
                            <Fragment>
                                <div className='sub-title'>
                                    <h6>Assess</h6>
                                </div>
                                <SectionButton
                                    to={'/my-team/assess'}
                                    icon={'icon-ppr-active'}
                                    title='Assess'
                                >
                                    Assess
                                </SectionButton>
                            </Fragment>
                        }
                    />
                )}
            </div>
        </Fragment>
    );
}

SideBarMyTeam.propTypes = {
    onBack: PropTypes.func,
};
