import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { getRoles } from '../../../../services/roleService';
import { getTeams } from '../../../../services/teamService';
import { getUser, updateUser } from '../../../../services/userService';
import { SubmitButton } from '../../../shared/components/SubmitButton';
import { AvatarImage } from '../../../shared/inputs/AvatarImage';
import { PulseSelect } from '../../../shared/inputs/PulseSelect';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper';
import './User.scss';

export function User() {
    const { id } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
    } = useForm();

    const [user, setUser] = useState(null);

    const [imageFile, setImageFile] = useState(null);

    const [teams, setTeams] = useState(null);

    const [roles, setRoles] = useState(null);

    const [redirect, setRedirect] = useState(null);

    const [loading, setLoading] = useState(true);

    const [form, setForm] = useState(null);

    const throwError = useAsyncError();

    useEffect(() => {
        async function getUserAsync() {
            try {
                setLoading(true);
                let [c, r, t] = await Promise.all([
                    getUser(id),
                    getRoles(),
                    getTeams(),
                ]);

                setUser(c);
                setRoles(r);
                setTeams(t);

                reset({
                    ...c,
                    roles: c?.roles?.map((x) => ({
                        ...x,
                        label: x.name,
                        value: x.id,
                    })),
                    teams: c?.teams?.map((x) => ({
                        ...x,
                        label: x.name,
                        value: x.id,
                    })),
                });
                setLoading(false);
            } catch (error) {
                throwError(error);
            }
        }

        if (id) {
            getUserAsync();
        }
    }, [id]);

    const transform = (callBack) => (data) => {
        callBack({
            ...data,
            teams: data?.teams?.map((y) => y.value) ?? undefined,
            roles: data?.roles?.map((y) => y.value) ?? undefined,
        });
    };

    async function onSubmit(data) {
        try {
            setForm({ loading: true, disabled: true });
            let request = {
                ...user,
                ...data,
                ...imageFile,
            };

            let c = await updateUser(request);
            setUser(c);

            reset({
                ...c,
                roles: c?.roles?.map((x) => ({
                    ...x,
                    label: x.name,
                    value: x.id,
                })),
                teams: c?.teams?.map((x) => ({
                    ...x,
                    label: x.name,
                    value: x.id,
                })),
                email: c?.email,
            });

            setForm({ ...form, loading: false, success: true });
        } catch (error) {
            setForm({
                ...form,
                loading: false,
                success: false,
                disabled: false,
            });
        }
    }

    useEffect(() => {
        if (form?.success) {
            setTimeout(() => {
                setForm({ success: false, disabled: false });
                setRedirect('/admin/users');
            }, 1000);
        }
    }, [form]);

    return (
        <Page className='user-form' loading={loading}>
            {redirect && <Navigate to={redirect} />}
            <MainContent>
                <div className='group-form avatar'>
                    <label>User photo</label>
                    <AvatarImage
                        image={imageFile?.image || user?.image}
                        onChange={(file, toBlob, fileName) =>
                            setImageFile({
                                image: file,
                                imageBase64: toBlob,
                                imageFileName: fileName,
                            })
                        }
                    />
                </div>
                <form onSubmit={handleSubmit(transform(onSubmit))}>
                    <div className='section'>
                        <div className='group-form'>
                            <label htmlFor='firstName'>First Name</label>
                            <input
                                id='firstName'
                                type='text'
                                aria-required={true}
                                {...register('firstName', {
                                    required: 'Required',
                                })}
                                autoFocus
                            />

                            {errors?.firstName?.message && (
                                <div className='error'>
                                    {errors?.firstName?.message}
                                </div>
                            )}
                        </div>

                        <div className='group-form'>
                            <label htmlFor='lastName'>Last Name</label>
                            <input
                                id='lastName'
                                type='text'
                                aria-required={true}
                                {...register('lastName', {
                                    required: 'Required',
                                })}
                            />

                            {errors?.lastName?.message && (
                                <div className='error'>
                                    {errors?.lastName?.message}
                                </div>
                            )}
                        </div>

                        <div className='group-form'>
                            <label htmlFor='email'>Email</label>
                            <input
                                id='email'
                                type='text'
                                aria-disabled={true}
                                disabled
                                {...register('email', {
                                    required: 'Required',
                                })}
                            />

                            {errors?.email?.message && (
                                <div className='error'>
                                    {errors?.email?.message}
                                </div>
                            )}
                        </div>

                        <div className='group-form'>
                            <label htmlFor='jobTitle'>Job Title</label>
                            <input
                                id='jobTitle'
                                type='text'
                                aria-required={true}
                                {...register('jobTitle', {
                                    required: 'Required',
                                })}
                            />

                            {errors?.jobTitle?.message && (
                                <div className='error'>
                                    {errors?.jobTitle?.message}
                                </div>
                            )}
                        </div>

                        <div className='group-form'>
                            <label htmlFor='location'>Location</label>
                            <input
                                id='location'
                                type='text'
                                aria-required={true}
                                {...register('location')}
                            />
                        </div>
                    </div>

                    <div className='section'>
                        <div className='group-form'>
                            <label>Role(s)</label>
                            <Controller
                                name='roles'
                                control={control}
                                render={({ field }) => (
                                    <PulseSelect
                                        {...field}
                                        className={'react-select'}
                                        isMulti
                                        options={roles?.map((x) => ({
                                            value: x.id,
                                            label: x.name,
                                        }))}
                                        defaultValue={user?.roles?.map((x) => ({
                                            value: x.id,
                                            label: x.name,
                                        }))}
                                    />
                                )}
                            />

                            {errors?.roles?.message && (
                                <div className='error'>
                                    {errors?.roles?.message}
                                </div>
                            )}
                        </div>

                        <div className='group-form'>
                            <label>Team(s)</label>
                            <Controller
                                name='teams'
                                control={control}
                                render={({ field }) => (
                                    <PulseSelect
                                        {...field}
                                        className={'react-select'}
                                        isMulti
                                        options={teams?.map((x) => ({
                                            value: x.id,
                                            label: x.title,
                                        }))}
                                    />
                                )}
                            />

                            {errors?.teams?.message && (
                                <div className='error'>
                                    {errors?.teams?.message}
                                </div>
                            )}
                        </div>
                    </div>

                    <SubmitButton
                        loading={form?.loading}
                        success={form?.success}
                        disabled={form?.disabled}
                    >
                        Save
                    </SubmitButton>
                </form>
            </MainContent>
        </Page>
    );
}
