import { useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { DisplayRagValue } from '../../inputs/DisplayRagValue';
import { useRagIntervals } from '../../lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import './components.scss';

export function SentimentGaugeChart({ highest, average, lowest }) {
    const [data, setData] = useState([]);

    const [colors, setColors] = useState([]);

    const { sortedAscendent } = useRagIntervals();

    useEffect(() => {
        let rags = sortedAscendent()?.map((x) => ({
            color: x.hexColor,
            value: x.maxValue + 1 > 10 ? 10 : x.maxValue + 1,
        }));

        let range = 0;
        let colorsArray = [];

        for (let i = 0; i < rags.length; i++) {
            let value = rags[i].value * 10 - range;
            colorsArray.push({ color: rags[i].color, value: value });
            range += value;
        }

        setColors(colorsArray);
    }, []);

    useEffect(() => {
        if (colors && colors.length > 0 && average) {
            let sumValues = colors
                .map((cur) => cur.value)
                .reduce((a, b) => a + b);

            let arrowData = [
                { value: average },
                { value: 0 },
                { value: sumValues - average },
            ];

            setData(arrowData);
        } else if (!average && colors && colors.length > 0) {
            setData(null);
        }
    }, [highest, average, lowest, colors]);

    const width = 600;

    const pieProps = {
        startAngle: 180,
        endAngle: 0,
    };

    const pieRadius = {
        innerRadius: (width / 2) * 0.3,
        outerRadius: (width / 2) * 0.4,
    };

    const Arrow = ({ cx, cy, midAngle, outerRadius }) => {
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);

        const nCx = cx + (outerRadius + width * 0.055) * cos;
        const nCy = cy + (outerRadius + width * 0.055) * sin;

        const middleX = cx + (outerRadius + width * 0.07) * cos;
        const middleY = cy + (outerRadius + width * 0.07) * sin;

        var triangleWidth = 6;

        const mx = middleX + triangleWidth * sin;
        const my = middleY + triangleWidth * -cos;

        const tx = middleX + triangleWidth * -sin;
        const ty = middleY + triangleWidth * cos;

        Arrow.propTypes = {
            cx: PropTypes.number,
            cy: PropTypes.number,
            midAngle: PropTypes.number,
            outerRadius: PropTypes.number,
        };

        return (
            <g>
                <path
                    d={`M${nCx},${nCy}L${mx},${my},${tx},${ty}Z`}
                    strokeWidth='3'
                    stroke='#31353a'
                    fill='#31353a'
                    strokeLinecap='round'
                />
            </g>
        );
    };

    return (
        <div className='sentiment-chart'>
            <div className='lowest-highest'>
                <div>
                    <label>Lowest Score </label>
                    <DisplayRagValue type='circle' value={lowest} />
                </div>
                <div>
                    <label>Highest Score </label>
                    <DisplayRagValue type='circle' value={highest} />
                </div>
            </div>
            <ResponsiveContainer width='100%' height={225}>
                {colors && colors.length > 0 && (
                    <PieChart
                        margin={{ top: 50, right: 0, left: 0, bottom: 0 }}
                    >
                        <Pie
                            data={colors}
                            dataKey={null}
                            fill='#8884d8'
                            {...pieRadius}
                            {...pieProps}
                        >
                            {colors.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            ))}
                        </Pie>

                        {data && data.length > 0 && (
                            <Pie
                                stroke='none'
                                activeIndex={1}
                                activeShape={Arrow}
                                data={data}
                                outerRadius={pieRadius.innerRadius}
                                fill='none'
                                {...pieProps}
                            />
                        )}
                    </PieChart>
                )}
            </ResponsiveContainer>
            <div className='average'>
                <DisplayRagValue type='circle' value={average} />
                <label>Average Score</label>
            </div>
        </div>
    );
}

SentimentGaugeChart.propTypes = {
    highest: PropTypes.number,
    average: PropTypes.number,
    lowest: PropTypes.number,
};
