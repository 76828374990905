import * as _ from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LineGraph } from '../../../../../shared/charts/LineGraph';
import { TeamSelector } from '../../../../../shared/components/TeamSelector';
import { MainContent } from '../../../../../shared/layout/MainContent';
import { Page } from '../../../../../shared/layout/Page';
import { useAsyncError } from '../../../../../shared/lib/helper';
import { useSelectedTeam } from '../../../../../shared/lib/useSelectedTeam';
import { PPRUserFilter } from './components/PPRUserFilter';

export function MyTeamPPRHistoric() {
    const { state } = useLocation();

    const [loading, setLoading] = useState(true);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [parentPPRTopic, setParentPPRTopic] = useState(null);

    const [subTopics, setSubTopics] = useState(null);

    const [startDate, setStartDate] = useState(
        state && state.StartDate
            ? state.StartDate
            : moment.utc().add(-1, 'months')
    );

    const [endDate, setEndDate] = useState(
        state && state.EndDate ? state.EndDate : moment.utc()
    );

    const [topicData, setTopicData] = useState(null);

    const [firstTime, setFirstTime] = useState(true);

    const throwError = useAsyncError();

    const { team } = useSelectedTeam();

    useEffect(() => {
        async function fetch() {
            let result = await Promise.all(
                subTopics.map(async (s) => ({
                    label: s.label,
                    data: await HistoricPPRTeam(
                        {
                            PPRTopicId: s.value,
                            StartDate: startDate,
                            EndDate: endDate,
                        },
                        team?.id
                    ),
                }))
            );

            setTopicData(
                result.map((topic) => ({
                    label: topic.label,
                    data: formatData(topic?.data),
                }))
            );

            setLoading(false);
        }
        if (subTopics && team?.id) {
            fetch();
        }
    }, [team]);

    useEffect(() => {
        if (state) {
            setParentPPRTopic(state.PPRTopic);
            getSubTopics([state.PPRTopic.id]).then((sub) => {
                setSubTopics(sub);

                Promise.all(
                    sub.map((s) => ({
                        label: s.label,
                        data: HistoricPPRTeam(
                            {
                                PPRTopicId: s.value,
                                StartDate: state.StartDate,
                                EndDate: state.EndDate,
                            },
                            state.Team
                        ),
                    }))
                ).then((result) => {
                    Promise.all(
                        result.map((topic) => {
                            return topic.data.then((d) => {
                                return {
                                    label: topic.label,
                                    data: formatData(d),
                                };
                            });
                        })
                    ).then((c) => {
                        setTopicData(c);

                        setLoading(false);
                    });
                });
            });
        }
    }, [state]);

    useEffect(() => {
        if (subTopics && selectedUsers.length > 0) {
            Promise.all(
                subTopics.map((s) => ({
                    label: s.label,
                    teamData: selectedUsers.map((user) => ({
                        data:
                            user.value === 'Team'
                                ? HistoricPPRTeam(
                                      {
                                          PPRTopicId: s.value,
                                          StartDate: startDate,
                                          EndDate: endDate,
                                      },
                                      team?.id
                                  )
                                : HistoricPPRUser(
                                      {
                                          PPRTopicId: s.value,
                                          StartDate: startDate,
                                          EndDate: endDate,
                                      },
                                      user.value
                                  ),
                    })),
                }))
            )
                .then((result) => {
                    return Promise.all(
                        result.map((topic) => {
                            return Promise.all(
                                topic.teamData.map((a) => {
                                    return a.data.then((d) => {
                                        return { label: topic.label, data: d };
                                    });
                                })
                            );
                        })
                    );
                })
                .then((res) => {
                    var logs = res.flatMap((item) => item);
                    handleDatesChange(
                        _(logs)
                            .groupBy((x) => x.label)
                            .flatMap((value, key) => ({
                                label: key,
                                data: value.map((x) => x.data),
                            }))
                            .value()
                    );
                });
        }
    }, [startDate, endDate]);

    function getSubTopics(topics) {
        return fetch('/api/PPRTopic/getParentChildrenForRT', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(topics),
        }).then((res) => {
            if (res.status >= 400) {
                throwError(new Error(''));
            }
            return res.json();
        });
    }

    function HistoricPPRTeam(search, teamId) {
        return fetch(`/api/ppr/teams/${teamId}/historic`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(search),
        }).then((res) => {
            if (res.status >= 400) {
                throwError(new Error());
            }
            return res.json();
        });
    }

    function HistoricPPRUser(search, userId) {
        return fetch(`/api/ppr/users/${userId}/historic`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(search),
        }).then((res) => {
            if (res.status >= 400) {
                throwError(new Error());
            }
            return res.json();
        });
    }

    function handleUsers(t) {
        if (firstTime) {
            setFirstTime(false);
        } else {
            let newUser = t.filter(
                (x) => !selectedUsers.some((y) => y.value === x.value)
            );

            if (newUser.length > 0) {
                Promise.all(
                    subTopics.map((s) => ({
                        label: s.label,
                        data:
                            newUser[0].value === 'Team'
                                ? HistoricPPRTeam(
                                      {
                                          PPRTopicId: s.value,
                                          StartDate: startDate,
                                          EndDate: endDate,
                                      },
                                      team?.id
                                  )
                                : HistoricPPRUser(
                                      {
                                          PPRTopicId: s.value,
                                          StartDate: startDate,
                                          EndDate: endDate,
                                      },
                                      newUser[0].value
                                  ),
                    }))
                ).then((result) => {
                    Promise.all(
                        result.map((topic) => {
                            return topic.data.then((d) => {
                                return {
                                    label: topic.label,
                                    data: formatData(d),
                                };
                            });
                        })
                    ).then((c) => {
                        let tData = [...topicData];

                        c.forEach((to) => {
                            tData
                                .find((x) => x.label === to.label)
                                .data.datasets.push(to.data.datasets[0]);
                        });

                        setTopicData(tData);
                        setLoading(false);
                    });
                });
            } else {
                let oldUser = selectedUsers.filter(
                    (x) => !t.some((y) => y.value === x.value)
                );

                if (oldUser[0]) {
                    let tData = [...topicData];

                    tData.forEach((x) => {
                        x.data?.datasets?.splice(
                            x?.data?.datasets
                                .map((x) => x.label)
                                .indexOf(oldUser[0].label),
                            1
                        );
                    });

                    setTopicData(tData);
                }
            }
        }
        setSelectedUsers(t);
    }

    function handleDates(from, to) {
        setStartDate(from);
        setEndDate(to);
    }

    function handleDatesChange(result) {
        setTopicData(formatMultipleData(result));
    }

    function formatMultipleData(topics) {
        var array = [];

        topics.forEach((topic) => {
            var topicData = {
                label: topic.label,
                data: {
                    labels: topic.data[0].ranges,
                    datasets: [],
                },
            };

            topic.data.forEach((e) => {
                topicData.data.datasets.push({
                    label: e.historic.entity,
                    data: e.historic.value,
                });
            });

            array.push(topicData);
        });

        return array;
    }

    function formatData(data) {
        var resultData = {
            labels: data.ranges,
            datasets: [],
        };

        resultData.datasets.push({
            label: data.historic.entity,
            data: data.historic.value,
        });

        return resultData;
    }

    return (
        <Page
            loading={loading}
            header={
                <div className='title-margin space-between'>
                    <h1>{parentPPRTopic?.title}</h1>
                    <TeamSelector />
                </div>
            }
            className='ppr-topic-search'
            testId='team-ppr-historic'
        >
            {!state && <Navigate to={'/my-team/assess'} />}

            {!loading && (
                <Fragment>
                    <div>
                        <MainContent title='Filter' line={true}>
                            <PPRUserFilter
                                team={team}
                                filter={state}
                                handleUsers={handleUsers}
                                handleDates={handleDates}
                            />
                        </MainContent>
                    </div>
                    <div className='ppr-historic-graph-container'>
                        {topicData.map((topic) => (
                            <MainContent
                                key={`main-content-${topic.label}`}
                                title={topic.label}
                                line={true}
                            >
                                <LineGraph
                                    key={`line-graph-${topic.label}`}
                                    data={topic.data}
                                    options={{
                                        max: 10,
                                        min: 0,
                                        stepSize: 1,
                                    }}
                                />
                            </MainContent>
                        ))}
                    </div>
                </Fragment>
            )}
        </Page>
    );
}
