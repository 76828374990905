import { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getRoles } from '../../../../services/roleService';
import { getTeams } from '../../../../services/teamService';
import { invite } from '../../../../services/userService';
import { InformationTooltip } from '../../../shared/components/InformationTooltip';
import { PulseSelect } from '../../../shared/inputs/PulseSelect';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { GenericModal } from '../../../shared/modal/GenericModal';
import { BulkUser } from './components/BulkUser';
import { downloadFile } from '../../../shared/lib/axios';
import { IconButton } from '../../../shared/inputs/IconButton';
import { getProviders } from '../../../../services/authenticationService';
import './User.scss';

export function InviteUser() {
    const [modal, setModal] = useState(false);

    const [succeed, setSucceed] = useState(null);

    const [message, setMessage] = useState('');

    const [isImportAllowed, setImportAllowed] = useState(false);

    const [loading, setLoading] = useState(true);

    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [roles, setRoles] = useState(null);

    const [teams, setTeams] = useState(null);

    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: { invitation: [{ email: '' }] },
    });

    const watchForm = watch('invitation');

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'invitation',
    });

    useEffect(() => {
        async function fetchData() {
            try {
                let [providers, r, t] = await Promise.all([
                    getProviders(),
                    getRoles(),
                    getTeams(),
                ]);

                setImportAllowed(
                    !!providers.filter((x) => x === 'localAccount').length
                );

                setRoles(r);
                setTeams(t);
            } catch (e) {
                //ignore
            }
        }

        fetchData();
    }, []);

    const transform = (onSubmitFunc) => (data) => {
        onSubmitFunc(
            data.invitation.map((x) => ({
                ...x,
                teams: x?.teams?.map((y) => y.value) ?? undefined,
                roles: x?.roles?.map((y) => y.value) ?? undefined,
                managedTeams: x?.managedTeams?.map((y) => y.value) ?? undefined,
            }))
        );
    };

    async function onSubmit(data) {
        setSubmitDisabled(true);
        setLoading(true);
        setModal(true);

        try {
            await invite(data);

            setSucceed(true);
            setMessage('Your invitations have been successfully sent.');
        } catch (e) {
            setSucceed(false);
            setMessage(
                <Fragment>
                    Something went wrong. Please review your emails or contact
                    <a href='mailto:support@pulse360.io'>
                        support@pulse360.io.
                    </a>
                </Fragment>
            );
        } finally {
            setLoading(false);
        }

        setSubmitDisabled(false);
    }

    async function downloadBulkInviteTemplate() {
        await downloadFile('/api/template/bulk-invite', 'Template.csv');
    }

    async function downloadBulkImportTemplate() {
        await downloadFile('/api/template/bulk-users', 'Template.csv');
    }

    return (
        <Fragment>
            <Page title='Add User' description='' className='invite-user'>
                <Fragment>
                    <MainContent title='Invite Users'>
                        <form
                            onSubmit={handleSubmit(transform(onSubmit))}
                            className='invite-user'
                        >
                            {fields.map((field, index) => (
                                <div key={field.id} className='invitation'>
                                    <div className='group-form email'>
                                        <label htmlFor='email'>Email</label>
                                        <input
                                            id='email'
                                            type='email'
                                            {...register(
                                                `invitation[${index}].email`,
                                                {
                                                    required: 'Required',
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9.'!#$%&*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                        message:
                                                            'Invalid email address',
                                                    },
                                                }
                                            )}
                                            defaultValue={''}
                                            autoFocus
                                        />
                                        {errors?.invitation?.length &&
                                            errors?.invitation[index]?.email
                                                ?.message && (
                                                <div className='error'>
                                                    {
                                                        errors?.invitation[
                                                            index
                                                        ]?.email?.message
                                                    }
                                                </div>
                                            )}
                                    </div>
                                    <div className='group-form'>
                                        <label>Role(s)</label>

                                        <Controller
                                            name={`invitation[${index}].roles`}
                                            control={control}
                                            render={({ field }) => (
                                                <PulseSelect
                                                    {...field}
                                                    className={'react-select'}
                                                    isMulti
                                                    options={roles?.map(
                                                        (x) => ({
                                                            value: x.id,
                                                            label: x.name,
                                                        })
                                                    )}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='group-form'>
                                        <label>Team(s)</label>
                                        <Controller
                                            name={`invitation[${index}].teams`}
                                            control={control}
                                            render={({ field }) => (
                                                <PulseSelect
                                                    {...field}
                                                    className={'react-select'}
                                                    isMulti
                                                    options={teams?.map(
                                                        (x) => ({
                                                            value: x.id,
                                                            label: x.title,
                                                        })
                                                    )}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className='group-form'>
                                        <div className='flex flex-center'>
                                            <label>Team Leadership</label>
                                            {index === 0 && (
                                                <InformationTooltip
                                                    text={
                                                        'Select any team this individual manages'
                                                    }
                                                />
                                            )}
                                        </div>
                                        <Controller
                                            name={`invitation[${index}].managedTeams`}
                                            control={control}
                                            render={({ field }) => (
                                                <PulseSelect
                                                    {...field}
                                                    className={'react-select'}
                                                    isMulti
                                                    options={
                                                        watchForm[index]
                                                            ?.teams ?? undefined
                                                    }
                                                />
                                            )}
                                        />
                                    </div>
                                    {fields?.length > 1 && (
                                        <IconButton
                                            type='delete'
                                            onClick={() => {
                                                remove(index);
                                            }}
                                            style={{ paddingTop: '2.5rem' }}
                                        />
                                    )}
                                </div>
                            ))}

                            <div className='buttons'>
                                <i
                                    className='icon icon-add'
                                    onClick={() =>
                                        append({
                                            firstName: 'appendBill',
                                            lastName: 'appendLuo',
                                        })
                                    }
                                ></i>
                                <button
                                    type='submit'
                                    disabled={submitDisabled}
                                    className='btn highlighted'
                                >
                                    Invite
                                </button>
                            </div>
                        </form>
                    </MainContent>
                    <BulkUser
                        loadingMessage={
                            'Inviting users. Please do not refresh the page. This can take a few minutes.'
                        }
                        title={'Bulk invite users'}
                        csvParseUrl={'csv/bulk-invite'}
                        postUrl={'api/users/bulk-invite'}
                        successMessage={
                            'Your invitations have been successfully sent.'
                        }
                        buttonText={'Invite Users'}
                    >
                        <p>
                            Using the Bulk invite tool, you can quickly and
                            easily invite multiple users to Pulse 360.
                            <br />
                            To do so, please follow the instructions below:
                            <ol>
                                <li>
                                    Download the CSV sample template{' '}
                                    <a onClick={downloadBulkInviteTemplate}>
                                        here
                                    </a>
                                    .
                                </li>
                                <li>
                                    Open the .csv file and populate the
                                    following fields for each user.
                                </li>
                                <ol>
                                    <li>
                                        Email: The email address for the user.
                                        (Required)
                                    </li>
                                    <li>
                                        Role: The system role or permissions
                                        level of the user. Users can have
                                        multiple roles, they are cumulative.
                                        these need to be split by a semi-colon
                                        “;” (Optional).
                                    </li>
                                    <li>
                                        Team: The relevant team(s) the user
                                        belongs to. If there is more than one,
                                        these need to be split by a semi-colon
                                        “;” (Optional).
                                    </li>

                                    <li>
                                        Team Leadership: If the user is a team
                                        lead, specify which team(s) the user has
                                        managerial responsibility for. If there
                                        is more than one, these need to be split
                                        by a semi-colon “;” (Optional)
                                    </li>
                                </ol>
                                <li>Upload the file and click Invite Users</li>
                            </ol>
                            If you have any issues, please contact{' '}
                            <a href='mailto:support@pulse360.io'>
                                support@pulse360.io
                            </a>
                        </p>
                    </BulkUser>

                    {isImportAllowed && (
                        <BulkUser
                            loadingMessage={
                                'Importing users. Please do not refresh the page. This can take a few minutes.'
                            }
                            title={'Bulk import users'}
                            csvParseUrl={'csv/bulk-user'}
                            postUrl={'users/bulk-users'}
                            successMessage={'Users created successfully'}
                            buttonText={'Import Users'}
                        >
                            <p>
                                If you are using Pulse 360 local accounts (as
                                opposed to Office 365 accounts) for user
                                registration and login, you can utilise the
                                Pulse 360 bulk upload tool to simplify that
                                process.
                                <br />
                                The tool will invite multiple users to your
                                organisation, at the same time and will generate
                                the relevant teams and users automatically.
                                <br />
                                <br />
                                Bulk upload instructions for Pulse 360 local
                                accounts:
                                <br />
                                <ol>
                                    <li>
                                        Download the CSV sample template{' '}
                                        <a onClick={downloadBulkImportTemplate}>
                                            here.
                                        </a>
                                    </li>
                                    <li>
                                        Open the .csv file and populate the
                                        following fields for each user. The key
                                        information required is:
                                        <ol>
                                            <li>
                                                <strong>Name</strong>: The first
                                                name of the user. (Required)
                                            </li>
                                            <li>
                                                <strong>Surname</strong>: The
                                                surname / family name of the
                                                user. (Required)
                                            </li>
                                            <li>
                                                <strong>Email</strong>: The
                                                email address for the user.
                                                (Required
                                            </li>
                                            <li>
                                                <strong>Job Title</strong>: The
                                                job title of the user.
                                                (Optional)
                                            </li>
                                            <li>
                                                <strong>Location</strong>: The
                                                location of the user. (Optional)
                                            </li>
                                            <li>
                                                <strong>Role</strong>: The
                                                system role or permissions level
                                                of the user. Users can have
                                                multiple roles, they are
                                                cumulative. these need to be
                                                split by a semi-colon
                                                &ldquo;;&rdquo; (Optional)
                                            </li>
                                            <ul>
                                                <li>
                                                    User: Access to the Me
                                                    section &amp; the mobile
                                                    app. THis is the default
                                                    value if empty/other value.
                                                </li>
                                                <li>
                                                    Team Lead: Access to Team
                                                    dashboards and insights. The
                                                    user can only view
                                                    information about their
                                                    respective team.
                                                </li>
                                                <li>
                                                    Business Leader: Access to
                                                    Team &amp; Company
                                                    dashboards and insights. The
                                                    user can view the
                                                    information of all teams.
                                                </li>
                                                <li>
                                                    Survey Admin: Access to the
                                                    survey and question
                                                    administration functions
                                                    (survey creation, question
                                                    bank).
                                                </li>
                                                <li>
                                                    System Admin: Access to the
                                                    company administration
                                                    functions (users, topics,
                                                    teams).
                                                </li>
                                                <li>
                                                    Full Control: Access to
                                                    everything (all the above).
                                                </li>
                                            </ul>
                                            <li>
                                                <strong>Team</strong>: The
                                                relevant team(s) for the user.
                                                If there is more than one, these
                                                need to be split by a semi-colon
                                                &ldquo;;&rdquo; (Optional)
                                            </li>
                                            <li>
                                                <strong>Team Leadership</strong>
                                                : If the user is a team lead,
                                                specify which team(s) the user
                                                has managerial responsibility
                                                for. If there is more than one,
                                                these need to be split by a
                                                semi-colon &ldquo;;&rdquo;
                                                (Optional)
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </p>
                        </BulkUser>
                    )}
                </Fragment>
            </Page>
            <GenericModal
                open={modal}
                success={succeed}
                loading={loading}
                loadingMessage={'Inviting users. Please wait'}
                message={message}
                onClose={() => {
                    setModal(false);
                    setSucceed(null);
                }}
                route={`/admin/users`}
            />
        </Fragment>
    );
}
