import { InformationTooltip } from '../../components/InformationTooltip';
import { Loading } from '../../loadings/Loading';
import { PropTypes } from 'prop-types';
import './components.scss';

export function WidgetLayout({
    children,
    loading,
    style,
    className,
    information,
}) {
    return (
        <div
            className={`widget-layout p-shadow ${className ?? ''}`}
            style={{ ...style }}
            role='section'
        >
            {loading && (
                <div className='widget-loading'>
                    <Loading />
                </div>
            )}
            {children}
            {information && (
                <div
                    role='tooltip'
                    aria-label='Hover for more information about this widget.'
                    className='widget-footer'
                >
                    <InformationTooltip text={information} />
                </div>
            )}
        </div>
    );
}

WidgetLayout.propTypes = {
    children: PropTypes.array,
    loading: PropTypes.bool,
    style: PropTypes.string,
    className: PropTypes.string,
    information: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
