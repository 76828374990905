import { get, put, post, remove } from '../components/shared/lib/axios';

export async function getUserConditionsFiltered(userId, limit) {
    return get(`users/${userId}/conditions/filtered`, { limit });
}

export async function getUserConditions(userId) {
    return get(`users/${userId}/conditions`);
}

export async function getTeamConditions(teamId) {
    return get(`teams/${teamId}/conditions`);
}

export async function updateCondition(req) {
    return put(`conditions`, req);
}

export async function createCondition(req) {
    return post(`conditions`, req);
}

export async function deleteCondition(conditionId) {
    return remove(`conditions/${conditionId}`);
}
