import moment from 'moment';
import { Fragment, useEffect, useState, useRef } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { StepBar } from '../../../../../shared/components/StepBar';
import { MainContent } from '../../../../../shared/layout/MainContent';
import { Page } from '../../../../../shared/layout/Page';
import { useSession } from '../../../../../shared/lib/useSession';
import { ActionBox } from './components/ActionBox';
import { PPRTopicBox } from './components/PPRTopicBox';
import { SliderChartBox } from './components/SliderChartBox';
import {
    getPPR,
    getUserLastPPR,
    savePPRQuestion,
} from '../../../../../../services/assessService';
import './AssessmentForm.scss';

export function AssessmentForm() {
    const { id } = useParams();

    const [ppr, setPPR] = useState(null);

    const [currentQuestion, setCurrentQuestion] = useState(null);

    const [loading, setLoading] = useState(true);

    const [overview, setOverview] = useState(false);

    const [action, setAction] = useState(null);

    const [values, setValues] = useState(null);

    const [finished, setFinished] = useState(false);

    const [nextPressed, setNextPressed] = useState(false);

    const overviewButtonRef = useRef(null);

    const session = useSession();

    useEffect(() => {
        async function fetchData() {
            let ppr = await getUserLastPPR(session?.userId, id);

            setPPR(ppr);

            let questions = ppr?.pprQuestions?.filter(
                (x) => !x.value && !x.targetValue
            );

            if (questions.length === 0) {
                setCurrentQuestion(
                    ppr?.pprQuestions[ppr?.pprQuestions?.length - 1] ?? 0
                );
            } else {
                setCurrentQuestion(
                    questions.sort((a, b) => a.index - b.index)[0]
                );
            }

            setLoading(false);
        }

        fetchData();
    }, []);

    useEffect(() => {
        async function controlOverview() {
            if (overview) {
                let request = buildQuestionRq(false);

                if (request) {
                    setPPR(await savePPRQuestion(request));
                }

                getPPR(ppr?.id).then((ppr) => {
                    setPPR(ppr);
                });
            }
        }

        controlOverview();
    }, [overview]);

    useEffect(() => {
        if (ppr && currentQuestion) {
            setCurrentQuestion(ppr?.pprQuestions[currentQuestion?.index]);
        }
    }, [ppr]);

    useEffect(() => {
        if (nextPressed) {
            overviewButtonRef.current.focus();
            setNextPressed(false);
        }
    }, [nextPressed]);

    async function handleNextPrevious(num) {
        let request = buildQuestionRq(false);
        setNextPressed(true);
        var updatedPpr = ppr;
        if (request) {
            updatedPpr = await savePPRQuestion(request);
            setPPR(updatedPpr);
        }

        setOverview(
            currentQuestion?.index === ppr?.pprQuestions?.length - 1 && num > 0
        );

        let index =
            currentQuestion?.index + num === updatedPpr?.pprQuestions?.length ||
            currentQuestion?.index + num < 0
                ? currentQuestion?.index
                : currentQuestion?.index + num;

        setCurrentQuestion(updatedPpr?.pprQuestions[index]);
    }

    function buildQuestionRq(complete) {
        if (!complete) {
            if (
                !values.score &&
                !values.Target &&
                (!action || !action.description)
            ) {
                return null;
            }

            if (
                values.score === currentQuestion?.value &&
                values.Target === currentQuestion?.targetValue &&
                (!action ||
                    (action && isEqual(action, currentQuestion?.action)))
            ) {
                return null;
            }
        }

        return {
            CompletePPR: complete,
            PPRId: ppr?.id,
            Question: {
                TopicId: currentQuestion?.topicId,
                Index: currentQuestion?.index,
                Title: currentQuestion?.title,
                Description: currentQuestion?.description,
                Value: values.score,
                Target: values.target,
            },
            Action: !action
                ? null
                : {
                      Description: action.description,
                      Priority: action.priority,
                      CompletionDate: action.completionDate,
                  },
        };
    }

    function isEqual(action, pprAction) {
        let equals = false;

        if (!pprAction) {
            equals =
                !action.description &&
                !action.priority &&
                !action.CompletionDate;
        } else {
            equals =
                action.description === pprAction.description &&
                action.priority === pprAction.priority &&
                ((action.CompletionDate &&
                    pprAction.completeDate &&
                    moment(action.CompletionDate).isSame(
                        pprAction.completeDate
                    )) ||
                    (!action.CompletionDate && !pprAction.completeDate));
        }

        return equals;
    }

    function isCompleted() {
        if (ppr) {
            let isCompleted = true;

            let withoutTarget = ppr?.pprQuestions?.filter(
                (x) => !x.value && !x.targetValue
            );

            if (withoutTarget.length > 0) {
                isCompleted =
                    withoutTarget.filter(
                        (x) => !x.Action || !x.Action.description
                    ).length === 0;
            }

            return isCompleted;
        }
    }

    async function handleCompleteExit() {
        let request = buildQuestionRq(isCompleted() && overview);
        if (request) {
            await savePPRQuestion(request);
        }

        setFinished(true);
    }

    return (
        <Fragment>
            {finished && <Navigate to='/me/assess/assessment/' />}
            <Page
                loading={loading}
                title={`Assess - ${ppr?.category?.title}`}
                className='assessment-form'
                testId='assessment-form'
                header={
                    <div className='overview-exit'>
                        <button
                            ref={overviewButtonRef}
                            className={`btn highlighted ${
                                overview ? 'assess' : ''
                            }`}
                            onClick={() => setOverview(!overview)}
                        >
                            Overview <i className='icon-overview'> </i>
                        </button>
                        <button
                            className={`btn highlighted exit-complete`}
                            onClick={() => handleCompleteExit()}
                        >
                            {isCompleted() && overview ? 'Complete' : 'Exit'}
                            <i
                                className={`icon-${
                                    isCompleted() && overview ? 'tick' : 'close'
                                }`}
                            ></i>
                        </button>
                    </div>
                }
            >
                {!overview ? (
                    <Fragment>
                        <MainContent>
                            <StepBar
                                current={currentQuestion?.index}
                                steps={ppr?.pprQuestions?.map((x) => x.title)}
                                labeled={true}
                            />
                        </MainContent>

                        <div className='next-previous-section'>
                            <button
                                className='btn highlighted'
                                onClick={() => handleNextPrevious(-1)}
                            >
                                <i className='icon-arrow-left'> </i>
                                Previous
                            </button>

                            <button
                                className='btn highlighted'
                                onClick={() => handleNextPrevious(1)}
                            >
                                {currentQuestion?.index ===
                                ppr?.pprQuestions?.length - 1 ? (
                                    'Review'
                                ) : (
                                    <Fragment>
                                        Next
                                        <i className='icon-arrow-right'></i>
                                    </Fragment>
                                )}
                            </button>
                        </div>

                        <MainContent className=' left-box'>
                            <SliderChartBox
                                question={currentQuestion}
                                onChange={(values) => setValues(values)}
                            />
                        </MainContent>

                        <MainContent className=' right-box'>
                            <ActionBox
                                question={currentQuestion}
                                onChange={(action) => setAction(action)}
                            />
                            <button
                                className='btn highlighted'
                                onClick={() => handleNextPrevious(1)}
                            >
                                {currentQuestion?.index ===
                                ppr?.pprQuestions?.length - 1 ? (
                                    'Review'
                                ) : (
                                    <Fragment>
                                        Next
                                        <i className='icon-arrow-right'></i>
                                    </Fragment>
                                )}
                            </button>
                        </MainContent>
                    </Fragment>
                ) : (
                    <MainContent className=' transparent'>
                        <div className='ppr-topic-boxes'>
                            {ppr?.pprQuestions?.map((question, i) => (
                                <PPRTopicBox
                                    key={`ppr-topic-box-${i}`}
                                    question={question}
                                    onEdit={(question) => {
                                        setCurrentQuestion(question);
                                        setOverview(false);
                                    }}
                                />
                            ))}
                        </div>
                    </MainContent>
                )}
            </Page>
        </Fragment>
    );
}
