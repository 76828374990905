import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import { useSelectedTeam } from '../../lib/useSelectedTeam';
import { Loading } from '../../loadings/Loading';
import { useSegmentsScoreData } from './useSegmentsScoreData';
import { PropTypes } from 'prop-types';
import { useRagIntervals } from '../../lib/useRagIntervals';
import './components.scss';

export function SegmentsScore({ survey, question, iteration }) {
    const { team } = useSelectedTeam();

    const [showMore, setShowMore] = useState(false);

    const MAX_ITEMS = 6;

    const navigate = useNavigate();

    const data = useSegmentsScoreData(survey, question, iteration, team);

    const { hexToRgbA } = useRagIntervals();

    return !data?.length ? (
        <Loading />
    ) : (
        <Fragment>
            <div className='segments-score'>
                {(showMore ? data : data?.slice(0, MAX_ITEMS))?.map((d, i) => (
                    <div
                        onClick={() =>
                            !d.isClickable
                                ? void 0
                                : navigate(
                                      `/my-team/feedback/pulse/survey-overview/${survey?.id}/team/${d.actor.id}/question/${question?.id}/${iteration}`
                                  )
                        }
                        className={`segment ${
                            !d.isClickable ? 'no-hover' : ''
                        }`}
                        key={`team-${i}`}
                    >
                        {d.isBold ? (
                            <h4>{d.actor.title}</h4>
                        ) : (
                            <h5>{d.actor.title}</h5>
                        )}
                        <div
                            className='value'
                            style={{
                                backgroundColor: hexToRgbA(d.color, 0.3),
                                borderColor: d.color,
                            }}
                        >
                            <span>{d.score ?? '-'}</span>
                        </div>

                        {question?.questionType ===
                            constants.QuestionType.YesNo && (
                            <div
                                className='value'
                                style={{
                                    backgroundColor: hexToRgbA(
                                        d.score !== '-%'
                                            ? constants.Colors.No
                                            : constants.Colors.Default,
                                        0.3
                                    ),
                                    borderColor:
                                        d.score !== '-%'
                                            ? constants.Colors.No
                                            : constants.Colors.Default,
                                }}
                            >
                                <span>
                                    {d.score !== '-%'
                                        ? `${
                                              100 -
                                              d.score.substring(
                                                  0,
                                                  d.score.indexOf('%')
                                              )
                                          }%`
                                        : '-%'}
                                </span>
                            </div>
                        )}
                    </div>
                ))}

                {data?.length > MAX_ITEMS && (
                    <a
                        tabIndex={0}
                        className='show-more'
                        onClick={() => setShowMore((prev) => !prev)}
                    >
                        <span>
                            {showMore
                                ? 'Collapse'
                                : `${data?.length - MAX_ITEMS}+ ${
                                      team?.id ? 'users' : 'teams'
                                  }`}
                        </span>
                    </a>
                )}
            </div>
        </Fragment>
    );
}

SegmentsScore.propTypes = {
    survey: PropTypes.object,
    question: PropTypes.object,
    iteration: PropTypes.number,
};
