import { get } from '../components/shared/lib/axios';

var controllerRoute = 'me-items';

export async function getMeItems(limit, lastDate, filter) {
    return get(`${controllerRoute}/`, {
        limit: limit,
        lastDate: lastDate,
        filter: filter,
    });
}
export async function getMeItem(itemId) {
    return get(`${controllerRoute}/${itemId}`);
}

export async function getSurveyMeItem(surveyId, iteration) {
    return get(`${controllerRoute}/survey/${surveyId}`, {
        iteration,
    });
}

export async function getMeItemFilter() {
    return get('types/me-item-filter');
}

export async function getMeItemTypes() {
    return get('types/me-item-types');
}
