import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getPPRTopic } from '../../../../../services/pprTopicService';
import { getTeams } from '../../../../../services/teamService';
import { Page } from '../../../../shared/layout/Page';
import { Loading } from '../../../../shared/loadings/Loading';
import { DateSelector } from '../../../../shared/utils/DateSelector';
import { PropTypes } from 'prop-types';
import './ActionsTopic.scss';

export function ActionsTopic() {
    const { state } = useLocation();

    const [isLoading, setLoading] = useState(true);

    const [teams, setTeams] = useState([]);

    const [pprTopic, setPPRTopic] = useState(null);

    const [filter, setFilter] = useState(state);

    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        async function fetch() {
            let [t, ppr] = await Promise.all([
                getTeams(),
                getPPRTopic(state?.ParentTopicId),
            ]);
            setTeams(t);
            setPPRTopic(ppr);
            setLoading(false);
        }

        fetch();

        return () => {
            setTeams([]);
            setPPRTopic(null);
            setLoading(true);
        };
    }, []);

    function handleRedirect(team) {
        setFilter({ ...filter, Team: team });
        setRedirect('/my-team/assess/actions');
    }

    return (
        <Fragment>
            <Page
                loading={isLoading}
                header={
                    <Fragment>
                        <h1>{`Actions - ${pprTopic?.title}`}</h1>
                        <DateSelector
                            onChange={(from, to) =>
                                setFilter({
                                    ...filter,
                                    StartDate: moment(from).utc().toISOString(),
                                    EndDate: moment(to).utc().toISOString(),
                                })
                            }
                        />
                    </Fragment>
                }
            >
                {!isLoading && (
                    <div className='actions-topic'>
                        {teams.map((team) => (
                            <ActionBox
                                key={`action-box-${team.id}`}
                                filter={filter}
                                handleRedirect={handleRedirect}
                                team={team}
                            />
                        ))}
                    </div>
                )}
            </Page>

            {redirect && <Navigate to={redirect} state={filter} />}
        </Fragment>
    );
}

function ActionBox({ filter, team, handleRedirect }) {
    const [action, setAction] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getActions(
            team.id,
            filter.ParentTopicId,
            filter.StartDate,
            filter.EndDate
        ).then((action) => {
            setAction(action);
            setLoading(false);
        });

        return () => {
            setAction(null);
            setLoading(true);
        };
    }, [filter]);

    function getActions(teamId, parentTopicId, startDate, endDate) {
        return fetch(
            `/api/teams/${teamId}/pprTopic/${parentTopicId}/actions?startDate=${startDate}&endDate=${endDate}`
        ).then((res) => res.json());
    }

    return (
        <Fragment>
            <div className='action-box p-shadow'>
                <h3 title={team.title} className='overflow-hidden'>
                    {team.title}
                </h3>
                <br />
                <h4>Actions</h4>
                {loading ? (
                    <Loading />
                ) : (
                    <div className='open-closed-actions'>
                        <Fragment>
                            <div>
                                Open
                                <div>
                                    {action.openActions
                                        ? action.openActions
                                        : '0'}
                                </div>
                            </div>

                            <div>
                                Closed
                                <div>
                                    {action.closedActions
                                        ? action.closedActions
                                        : '0'}
                                </div>
                            </div>
                        </Fragment>
                    </div>
                )}
                <div className='button'>
                    <button
                        className='btn highlighted'
                        onClick={() => handleRedirect(team)}
                    >
                        View all
                    </button>
                </div>
            </div>
        </Fragment>
    );
}

ActionBox.propTypes = {
    filter: PropTypes.object,
    team: PropTypes.object,
    handleRedirect: PropTypes.func,
};
