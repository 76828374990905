import { Fragment, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

export function Accordion({ children, title, defaultActive, expand, onClick }) {
    const [active, setActive] = useState(defaultActive);

    useEffect(() => {
        setActive(expand);
    }, [expand]);
    return (
        <Fragment>
            <div
                data-testid='accordion'
                tabIndex={0}
                role='listbox'
                className={`pulse360-accordion ${active ? 'active' : ''}`}
                onClick={(e) => {
                    setActive(!active);
                    onClick(e);
                }}
                onKeyPress={(e) => {
                    e.key === 'Space' && setActive(!active);
                    onClick(e);
                }}
            >
                <div className='title'>
                    <h3>{title}</h3>
                </div>
                <i className={`icon icon-arrow-${active ? 'up' : 'down'}`}> </i>
            </div>
            <div className={`pulse360-panel ${active ? 'active' : ''}`}>
                {children}
            </div>
        </Fragment>
    );
}

Accordion.propTypes = {
    children: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultActive: PropTypes.bool,
    expand: PropTypes.bool,
    onClick: PropTypes.func,
};
