import { useState, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import './DominanceChart.scss';

export function LikertDominanceChart({ data }) {
    const [selectedScore, setSelectedScore] = useState(null);

    return (
        <Fragment>
            <div className='dominance'>
                <div
                    className={`dominance-box ${
                        selectedScore && selectedScore !== 'stronglyDisagree'
                            ? ' not-selected'
                            : ''
                    }`}
                    onClick={() =>
                        setSelectedScore(
                            selectedScore === 'stronglyDisagree'
                                ? null
                                : 'stronglyDisagree'
                        )
                    }
                >
                    <div className='score sd'>Strongly Disagree</div>
                    <div className='teams'>
                        {data?.stronglyDisagree?.teamsDominance
                            .slice(0, 2)
                            .map((td, i) => {
                                return (
                                    <div key={i} style={{ marginBottom: 5 }}>
                                        {td.name}
                                    </div>
                                );
                            })}
                        {data?.stronglyDisagree?.teamsDominance?.length > 2 && (
                            <div>
                                {' '}
                                <br />+ More
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`dominance-box ${
                        selectedScore && selectedScore !== 'disagree'
                            ? ' not-selected'
                            : ''
                    }`}
                    onClick={() =>
                        setSelectedScore(
                            selectedScore === 'disagree' ? null : 'disagree'
                        )
                    }
                >
                    <div className='score d'>Disagree</div>
                    <div className='teams'>
                        {data?.disagree?.teamsDominance
                            .slice(0, 2)
                            .map((td, i) => {
                                return (
                                    <div key={i} style={{ marginBottom: 5 }}>
                                        {td.name}
                                    </div>
                                );
                            })}
                        {data?.disagree?.teamsDominance?.length > 2 && (
                            <div>
                                {' '}
                                <br />+ More
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`dominance-box ${
                        selectedScore && selectedScore !== 'neutral'
                            ? ' not-selected'
                            : ''
                    }`}
                    onClick={() =>
                        setSelectedScore(
                            selectedScore === 'neutral' ? null : 'neutral'
                        )
                    }
                >
                    {' '}
                    <div className='score n'>Neutral</div>
                    <div className='teams'>
                        {data?.neutral?.teamsDominance
                            .slice(0, 2)
                            .map((td, i) => {
                                return (
                                    <div key={i} style={{ marginBottom: 5 }}>
                                        {td.name}
                                    </div>
                                );
                            })}
                        {data?.neutral?.teamsDominance?.length > 2 && (
                            <div>
                                {' '}
                                <br />+ More
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`dominance-box ${
                        selectedScore && selectedScore !== 'agree'
                            ? ' not-selected'
                            : ''
                    }`}
                    onClick={() =>
                        setSelectedScore(
                            selectedScore === 'agree' ? null : 'agree'
                        )
                    }
                >
                    {' '}
                    <div className='score a'>Agree</div>
                    <div className='teams'>
                        {data?.agree?.teamsDominance
                            .slice(0, 2)
                            .map((td, i) => {
                                return (
                                    <div key={i} style={{ marginBottom: 5 }}>
                                        {td.name}
                                    </div>
                                );
                            })}
                        {data?.agree?.teamsDominance?.length > 2 && (
                            <div>
                                {' '}
                                <br />+ More
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`dominance-box ${
                        selectedScore && selectedScore !== 'stronglyAgree'
                            ? ' not-selected'
                            : ''
                    }`}
                    onClick={() =>
                        setSelectedScore(
                            selectedScore === 'stronglyAgree'
                                ? null
                                : 'stronglyAgree'
                        )
                    }
                >
                    {' '}
                    <div className='score sa'>Strongly Agree</div>
                    <div className='teams'>
                        {data?.stronglyAgree?.teamsDominance
                            .slice(0, 2)
                            .map((td, i) => {
                                return (
                                    <div key={i} style={{ marginBottom: 5 }}>
                                        {td.name}
                                    </div>
                                );
                            })}
                        {data?.stronglyAgree?.teamsDominance?.length > 2 && (
                            <div>
                                {' '}
                                <br />+ More
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {selectedScore && data[selectedScore]?.teamsDominance?.length > 2 && (
                <div className={`team-score ${selectedScore}`}>
                    {data[selectedScore]?.teamsDominance?.map((td, i) => {
                        return (
                            <div key={i} className='team'>
                                {td.name}&nbsp;
                            </div>
                        );
                    })}
                </div>
            )}
        </Fragment>
    );
}

LikertDominanceChart.propTypes = {
    data: PropTypes.object,
};
