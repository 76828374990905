import { useCallback, useEffect, useState } from 'react';
import { getPPRTopicParents } from '../../../../services/pprTopicService';
import { Table } from '../../../shared/components/Table';
import { IconButton } from '../../../shared/inputs/IconButton';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper';
import './Assess.scss';

export function Assess() {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);

    const throwError = useAsyncError();

    const fetchData = useCallback(async () => {
        try {
            let topics = await getPPRTopicParents();

            setData(topics);
            setLoading(false);
        } catch (e) {
            throwError(e);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Page title='Assess' testId='assess' loading={loading}>
            <div>
                {data.map((pprTopic, index) => {
                    return (
                        <MainContent key={index}>
                            <div className='pprtopic' data-testid='pprTopic'>
                                <div className='space-between'>
                                    <h3>{pprTopic.title}</h3>

                                    <IconButton
                                        type='edit'
                                        route={`/admin/performance/edit/${pprTopic.id}`}
                                        title={pprTopic.title}
                                    />
                                </div>
                                <div
                                    key={'rt-' + index}
                                    className='row pprtopic-table'
                                >
                                    <Table
                                        className='ppr-topic'
                                        data={pprTopic.children}
                                        loading={loading}
                                        columns={[
                                            {
                                                Header: 'Name',
                                                accessor: 'title',
                                                width: 200,
                                            },
                                            {
                                                Header: 'Description',
                                                accessor: 'description',
                                                width: 400,
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </MainContent>
                    );
                })}
            </div>
        </Page>
    );
}
