import { useRagIntervals } from '../../../../lib/useRagIntervals';
import { PropTypes } from 'prop-types';

export function Trend({ trend }) {
    const trendColor =
        trend && !isNaN(trend)
            ? trend === 0
                ? '#f29100'
                : trend > 0
                ? '#93c01f'
                : '#eb1426'
            : '#757575';

    const { hexToRgbA } = useRagIntervals();

    const rgbaColor = hexToRgbA(trendColor, 0.2);

    return !isNaN(trend) && typeof trend === 'number' ? (
        <div
            className={`trend ${
                trend === 0 ? 'medium' : trend > 0 ? 'high' : 'bad'
            }`}
            style={{
                color: trendColor,
                backgroundColor: rgbaColor,
            }}
        >
            <h4>{trend}%</h4>
            <i
                style={{ color: trendColor }}
                className={`icon-trend-arrow-${
                    trend === 0 ? 'medium' : trend > 0 ? 'high' : 'bad'
                }`}
            ></i>
        </div>
    ) : (
        <div></div>
    );
}

Trend.propTypes = {
    trend: PropTypes.number,
};
