import { Fragment } from 'react';
import { Loading } from '../../loadings/Loading';
import { QuestionTopic, QuestionType } from './SurveyBox';
import { PropTypes } from 'prop-types';
import './components.scss';

export function QuestionInformationCard({ question }) {
    return !question ? (
        <Loading />
    ) : (
        <Fragment>
            <div className='question-information-card'>
                <div>
                    <h3>{question?.questionText}</h3>
                    <QuestionTopic topic={question?.topic?.title} />
                </div>
                <div>
                    <QuestionType type={question?.questionType} />
                </div>
            </div>
        </Fragment>
    );
}

QuestionInformationCard.propTypes = {
    question: PropTypes.object,
};
