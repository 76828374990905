import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import './charts.scss';

const DEFAULT_COLOR1 = '#8f9498';
const DEFAULT_COLOR2 = '#ED3241';

export function DoubleRadialChart({ score, scoreColor, target, targetColor }) {
    const [axisScore, setAxisScore] = useState({ y: 20, x: 90 });
    const [axisTarget, setAxisTarget] = useState({ y: 35, x: 90 });

    const strokeWidth = 13;
    const circleInnerRadius = 55;
    const innerCircumference = 2 * 3.14 * circleInnerRadius;
    const innerStrokeLength = ((innerCircumference * 1.0) / 10) * target;

    const circleExternalRadius = 70;
    const externalCircumference = 2 * 3.14 * circleExternalRadius;
    const externalStrokeLength = ((externalCircumference * 1.0) / 10) * score;

    const scoreAxis = [
        {
            score: 0,
            x: 90,
            y: 20,
        },
        {
            score: 1,
            x: 131,
            y: 33,
        },
        {
            score: 2,
            x: 156,
            y: 68,
        },
        {
            score: 3,
            x: 156,
            y: 112,
        },
        {
            score: 4,
            x: 131,
            y: 147,
        },
        {
            score: 5,
            x: 90,
            y: 160,
        },
        {
            score: 6,
            x: 49,
            y: 147,
        },
        {
            score: 7,
            x: 24,
            y: 112,
        },
        {
            score: 8,
            x: 24,
            y: 68,
        },
        {
            score: 9,
            x: 49,
            y: 33,
        },
        {
            score: 10,
            x: 90,
            y: 20,
        },
    ];

    const targetAxis = [
        {
            score: 0,
            x: 90,
            y: 35,
        },
        {
            score: 1,
            x: 122,
            y: 45,
        },
        {
            score: 2,
            x: 142,
            y: 72,
        },
        {
            score: 3,
            x: 142,
            y: 107,
        },
        {
            score: 4,
            x: 122,
            y: 135,
        },
        {
            score: 5,
            x: 90,
            y: 145,
        },
        {
            score: 6,
            x: 58,
            y: 135,
        },
        {
            score: 7,
            x: 38,
            y: 107,
        },
        {
            score: 8,
            x: 38,
            y: 72,
        },
        {
            score: 9,
            x: 58,
            y: 45,
        },
        {
            score: 10,
            x: 90,
            y: 35,
        },
    ];

    useEffect(() => {
        let axis = targetAxis.find((x) => x.score === target);

        if (axis) {
            setAxisTarget({ x: axis.x, y: axis.y });
        }
    }, [target]);

    useEffect(() => {
        let axis = scoreAxis.find((x) => x.score === score);

        if (axis) {
            setAxisScore({ x: axis.x, y: axis.y });
        }
    }, [score]);

    return (
        <div className='double-radial-chart'>
            <svg viewBox='0 0 180 180'>
                <circle
                    className='double-radial-chart-total'
                    stroke={targetColor}
                    strokeWidth={strokeWidth}
                    fill='none'
                    cx='90'
                    cy='90'
                    r={circleInnerRadius}
                />
                <circle
                    className='double-radial-chart-progress'
                    stroke={targetColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${innerStrokeLength},${innerCircumference}`}
                    strokeLinecap='round'
                    fill='none'
                    cx='90'
                    cy='90'
                    r={circleInnerRadius}
                />

                <circle
                    className='double-radial-chart-total'
                    stroke={scoreColor}
                    strokeWidth={strokeWidth}
                    fill='none'
                    cx='90'
                    cy='90'
                    r={circleExternalRadius}
                />
                <circle
                    className='double-radial-chart-progress'
                    stroke={scoreColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${externalStrokeLength},${externalCircumference}`}
                    strokeLinecap='round'
                    fill='none'
                    cx='90'
                    cy='90'
                    r={circleExternalRadius}
                />

                <circle
                    fill='white'
                    cx={axisScore.x}
                    cy={axisScore.y}
                    r={5.5}
                />
                <text
                    x={axisScore.x}
                    y={axisScore.y}
                    fill='black'
                    strokeWidth='1'
                    textAnchor='middle'
                    dy='.3em'
                    fontSize='9'
                >
                    {score ? score : 0}
                </text>

                <circle
                    fill='white'
                    cx={axisTarget.x}
                    cy={axisTarget.y}
                    r={5.5}
                />
                <text
                    x={axisTarget.x}
                    y={axisTarget.y}
                    fill='black'
                    strokeWidth='1'
                    textAnchor='middle'
                    dy='.3em'
                    fontSize='9'
                >
                    {target ? target : 0}
                </text>
            </svg>
        </div>
    );
}

DoubleRadialChart.defaultProps = {
    innerRadius: 60,
    externalRadius: 70,
    progress: 10,
    strokeWidth: 10,
    dimension: 180,
    scoreColor: DEFAULT_COLOR1,
    targetColor: DEFAULT_COLOR2,
};

DoubleRadialChart.propTypes = {
    score: PropTypes.number,
    scoreColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    target: PropTypes.number,
    targetColor: PropTypes.string,
};
