import { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Login } from './components/pages/authentication/Login';
import { Loading } from './components/shared/loadings/Loading';
import { AppProvider } from './contexts/AppContext';
import { ActionTypes, SessionContext } from './contexts/SessionContext';
import { getAuthentication } from './services/authenticationService';
import { withRouter } from './components/shared/lib/useWithRouter';
import { SnackbarProvider } from './contexts/SnackbarContext';
import externalRoutes from './externalRoutes';
import routes from './routes';

export function App() {
    const location = useLocation();

    const isExternal = location.pathname.substring(1, 4) === 'ext';

    const { state, dispatch } = useContext(SessionContext);

    useEffect(() => {
        const fetchData = async () => {
            if (!isExternal) {
                try {
                    if (isFetching) {
                        let auth = await getAuthentication();

                        if (auth?.authenticated) {
                            await dispatch({
                                type: ActionTypes.LOGIN,
                                payload: { ...auth, loading: false },
                            });
                        } else {
                            await dispatch({ type: ActionTypes.LOADED });
                        }
                    }
                } catch (error) {
                    //ignore
                }
            }
        };

        if (location) {
            var isFetching = true;
            fetchData();
        }

        return () => {
            isFetching = false;
        };
    }, []);

    useEffect(() => {
        if (isExternal) {
            dispatch({ type: ActionTypes.LOADED });
        }
    }, [isExternal]);

    useEffect(() => {
        async function fetch() {
            let auth = await getAuthentication();

            if (!auth.authenticated && isFetching) {
                await dispatch({ type: ActionTypes.LOGOUT });
            }
        }
        if (state?.logOut) {
            var isFetching = true;
            fetch();
        }

        return () => {
            isFetching = false;
        };
    }, [state?.logOut]);

    if (state?.loading) {
        return (
            <div className='main-loading'>
                <Loading />
            </div>
        );
    }

    if (isExternal) {
        return (
            <Routes>
                {externalRoutes.map((route) => (
                    <Route key={route.path} {...route} />
                ))}
            </Routes>
        );
    }

    if (!state?.authenticated && !state?.loading) {
        return <Login />;
    }

    return (
        <AppProvider>
            <SnackbarProvider>
                <Layout routes={routes} />
            </SnackbarProvider>
        </AppProvider>
    );
}

export default withRouter(App);
