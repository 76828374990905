import { useState, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { useRagIntervals } from '../../../../lib/useRagIntervals';
import './DominanceChart.scss';

export function ChoiceDominanceChart({ data }) {
    const [selectedScore, setSelectedScore] = useState(null);

    const { choiceArray } = useRagIntervals();

    return (
        <Fragment>
            <div className='dominance'>
                {data?.map((x, i) => (
                    <div
                        key={i}
                        className={`dominance-box ${
                            selectedScore && selectedScore !== i + 1
                                ? 'not-selected'
                                : ''
                        }`}
                        onClick={() =>
                            setSelectedScore(
                                selectedScore === i + 1 ? null : i + 1
                            )
                        }
                    >
                        <div
                            className='score'
                            style={{ backgroundColor: choiceArray[i] }}
                        >
                            {x.value}
                        </div>
                        <div className='teams'>
                            {x?.teamsDominance.slice(0, 2).map((td, i) => {
                                return (
                                    <div key={i} style={{ marginBottom: 5 }}>
                                        {td.name}
                                    </div>
                                );
                            })}
                            {x?.teamsDominance?.length > 2 && (
                                <div>
                                    <br />+ More
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {selectedScore &&
                data[selectedScore - 1]?.teamsDominance?.length > 2 && (
                    <div
                        className={`team-score ${selectedScore}`}
                        style={{
                            borderTop: `1px solid ${
                                choiceArray[selectedScore - 1]
                            }`,
                            borderLeft: `1px solid ${
                                choiceArray[selectedScore - 1]
                            }`,
                            borderBottom: `1px solid ${
                                choiceArray[selectedScore - 1]
                            }`,
                            borderRight: `20px solid ${
                                choiceArray[selectedScore - 1]
                            }`,
                        }}
                    >
                        {data[selectedScore - 1]?.teamsDominance?.map(
                            (td, i) => {
                                return (
                                    <div key={i} className='team'>
                                        {td.name}&nbsp;
                                    </div>
                                );
                            }
                        )}
                    </div>
                )}
        </Fragment>
    );
}

ChoiceDominanceChart.propTypes = {
    data: PropTypes.array,
};
