import { Fragment, useEffect, useState } from 'react';
import { getUserSurveyIndex } from '../../../services/pulseIndexService';
import { HorizontalBarChart } from '../charts/HorizontalBarChart';
import { DisplayRagValue } from '../inputs/DisplayRagValue';
import { useFormattedMessage } from '../lib/helper';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function PulseSurveysWidget({ from, to, userId }) {
    const [data, setData] = useState(null);

    const [userCompletionRate, setUserCompletionRate] = useState(null);
    const [teamsCompletionRate, setTeamsCompletionRate] = useState(null);
    const [companyCompletionRate, setCompanyCompletionRate] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            let sur = await getUserSurveyIndex(from, to, userId);

            setUserCompletionRate(
                sur.actorsCompletionRates.find((x) => x.type === 0)
            );

            let teams = sur.actorsCompletionRates.filter((x) => x.type === 1);

            if (teams && teams.length > 3) {
                teams = teams.slice(0, 3);
            }
            setTeamsCompletionRate(teams);

            setCompanyCompletionRate(
                sur.actorsCompletionRates.find((x) => x.type === 2)
            );

            setData(sur);
            setLoading(false);
        }

        if (from && to && userId) {
            fetchData();
        }
    }, [from, to, userId]);

    return (
        <Fragment>
            <div className='pulse-surveys-widget completion-rate'>
                <WidgetLayout loading={loading}>
                    <WidgetHeader
                        title={useFormattedMessage(
                            'pulseSurveysWidget.header.title'
                        )}
                        route={''}
                    />
                    <hr role='none' aria-label='Completion rates content.' />

                    <h5 role='none'>
                        {useFormattedMessage('pulseSurveysWidget.body.title1')}
                    </h5>
                    <div className='s-charts'>
                        <div className='c-chart' role='region'>
                            <p className='bold'>
                                {useFormattedMessage(
                                    'pulseSurveysWidget.body.rate.you',
                                    {
                                        value:
                                            data &&
                                            typeof data.percentile === 'number'
                                                ? 100 - data.percentile
                                                : '-',
                                    }
                                )}
                            </p>
                            <HorizontalBarChart
                                className='big'
                                data={[
                                    {
                                        value:
                                            userCompletionRate &&
                                            typeof userCompletionRate.completionRate ===
                                                'number'
                                                ? userCompletionRate.completionRate
                                                : 5,
                                        sLabel:
                                            userCompletionRate &&
                                            typeof userCompletionRate.completionRate ===
                                                'number'
                                                ? `${userCompletionRate.completionRate}%`
                                                : '-',
                                    },
                                ]}
                            />
                        </div>

                        <div className='c-chart'>
                            <p className='bold'>
                                {useFormattedMessage(
                                    'pulseSurveysWidget.body.rate.team',
                                    {
                                        value:
                                            data &&
                                            typeof data.percentile === 'number'
                                                ? 100 - data.percentile
                                                : '-',
                                    }
                                )}
                            </p>
                            {teamsCompletionRate &&
                            teamsCompletionRate.length ? (
                                teamsCompletionRate.map((team, i) => (
                                    <HorizontalBarChart
                                        className={`${
                                            teamsCompletionRate.length === 1
                                                ? 'big'
                                                : ''
                                        }`}
                                        key={`chart${team.completionRate}${i}`}
                                        data={[
                                            {
                                                value: team.completionRate,
                                                sLabel: `${team.completionRate}%`,
                                                color: '#4a4a4a',
                                                hover: team.name,
                                            },
                                        ]}
                                    />
                                ))
                            ) : (
                                <HorizontalBarChart
                                    className='big'
                                    data={[
                                        {
                                            value: 5,
                                            sLabel: '-',
                                            color: '#4a4a4a',
                                        },
                                    ]}
                                />
                            )}
                        </div>

                        <div className='c-chart'>
                            <p className='bold'>
                                {useFormattedMessage(
                                    'pulseSurveysWidget.body.rate.company',
                                    {
                                        value:
                                            data &&
                                            typeof data.percentile === 'number'
                                                ? 100 - data.percentile
                                                : '-',
                                    }
                                )}
                            </p>
                            <HorizontalBarChart
                                className='big'
                                data={[
                                    {
                                        value:
                                            companyCompletionRate &&
                                            typeof companyCompletionRate.completionRate ===
                                                'number'
                                                ? companyCompletionRate.completionRate
                                                : 5,
                                        sLabel:
                                            companyCompletionRate &&
                                            typeof companyCompletionRate.completionRate ===
                                                'number'
                                                ? `${companyCompletionRate.completionRate}%`
                                                : '-',
                                        color: '#000000',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </WidgetLayout>
            </div>

            <div className='pulse-surveys-widget completion-streak'>
                <WidgetLayout
                    className='hide-on-mobile'
                    loading={loading}
                    information={useFormattedMessage(
                        'pulseSurveysWidget.header.information'
                    )}
                >
                    <div className='simulated-header'></div>
                    <hr role='none' aria-label='Completion streak content' />

                    <h5 role='none'>
                        {useFormattedMessage('pulseSurveysWidget.body.title2')}
                    </h5>
                    <div className='w-row'>
                        <div>
                            <i className='icon icon-survey-alltime'></i>
                            <div>
                                <DisplayRagValue
                                    value={data ? data.bestStreak : 0}
                                    color={'#424953'}
                                />
                                <p className='bold'>
                                    {useFormattedMessage(
                                        'pulseSurveysWidget.body.streak.time'
                                    )}
                                </p>
                            </div>
                        </div>
                        <div>
                            <i className='icon icon-streak'></i>
                            <div>
                                <DisplayRagValue
                                    value={data ? data.currentStreak : 0}
                                    color={'#424953'}
                                />
                                <p className='bold'>
                                    {useFormattedMessage(
                                        'pulseSurveysWidget.body.streak.current'
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='w-row' style={{ justifyContent: 'center' }}>
                        <div>
                            <i className='icon icon-survey-missed'></i>
                            <div>
                                {data ? (
                                    <div className='circle-diagonal'>
                                        <p>{data.missed}</p>
                                        <div className='diagonal'></div>
                                        <p>
                                            {data.surveyStatus
                                                ? data.surveyStatus.length
                                                : 0}
                                        </p>
                                    </div>
                                ) : (
                                    <DisplayRagValue
                                        value={'-'}
                                        color={'#424953'}
                                    />
                                )}

                                <p className='bold'>
                                    {useFormattedMessage(
                                        'pulseSurveysWidget.body.streak.missed'
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </WidgetLayout>
            </div>
        </Fragment>
    );
}

PulseSurveysWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    userId: PropTypes.string,
};
