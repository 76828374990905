import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { getWidgetExtremeFeedbackForUser } from '../../../services/extremeFeedbackService';
import { getWidgetFeedbackAverageScoreForUser } from '../../../services/feedbackScoreService';
import { getUserConnections } from '../../../services/pulseIndexService';
import { getWidgetSentimentScoreForUser } from '../../../services/sentimentScoreService';
import RadialChart from '../charts/RadialChart';
import {
    ScoreChart,
    SentimentChart,
    TotalChart,
} from '../charts/RadialChartIcon';
import { DisplayRagValue } from '../inputs/DisplayRagValue';
import { useFormattedMessage } from '../lib/helper';
import { useRagIntervals } from '../lib/useRagIntervals';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { PropTypes } from 'prop-types';
import constants from '../constants';
import './widgets.scss';

export function PeerFeedback({ from, to, userId, months }) {
    const [isScore, setIsScore] = useState(true);

    const [average, setAverage] = useState(null);

    const [extreme, setExtreme] = useState(null);

    const [loading, setLoading] = useState(true);

    const [connections, setConnections] = useState(null);

    const [connectionActors, setConnectionActors] = useState([
        null,
        null,
        null,
    ]);

    const [loadingExtreme, setLoadingExtreme] = useState(true);

    const [loadingConnections, setLoadingConnections] = useState(true);

    const translations = [
        useFormattedMessage('userConnections.body.tooltip.position0'),
        useFormattedMessage('userConnections.body.tooltip.position1'),
        useFormattedMessage('userConnections.body.tooltip.position2'),
    ];

    const totalTranslation = useFormattedMessage(
        'userConnections.body.tooltip.total'
    );

    const formatMessage = useFormattedMessage;

    const { getHighestRag, getLowestRag } = useRagIntervals();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            var previousTo = moment(from);

            var previousFrom = moment(from).add(-months, 'months');

            let averageFunc = isScore
                ? getWidgetFeedbackAverageScoreForUser
                : getWidgetSentimentScoreForUser;

            let [givenPrevious, receivedPrevious, given, received] =
                await Promise.all([
                    averageFunc(userId, previousFrom, previousTo, false),
                    averageFunc(userId, previousFrom, previousTo, true),
                    averageFunc(userId, from, to, false),
                    averageFunc(userId, from, to, true),
                ]);

            let data = {
                given: {
                    previous: givenPrevious === '' ? null : givenPrevious,
                    current: given === '' ? null : given,
                },
                received: {
                    previous: receivedPrevious === '' ? null : receivedPrevious,
                    current: received === '' ? null : received,
                },
            };

            setAverage(data);

            setLoading(false);
        }

        if (from && to && userId) {
            fetchData();
        }
    }, [isScore, from, to, userId]);

    useEffect(() => {
        async function fetchData() {
            setLoadingExtreme(true);

            let ext = await getWidgetExtremeFeedbackForUser(userId, from, to);

            setExtreme(ext);
            setLoadingExtreme(false);
        }

        if (from && to && userId) {
            fetchData();
        }
    }, [from, to, userId]);

    useEffect(() => {
        async function fetchData() {
            setLoadingConnections(true);

            let con = await getUserConnections(from, to, userId);

            let actors = [...con.actorsConnections, null, null].slice(0, 2);

            actors.push(con.topTeam ? { ...con.topTeam } : null);

            setConnections(con);
            setConnectionActors([...actors]);
            setLoadingConnections(false);
        }

        if (from && to && userId) {
            fetchData();
        }
    }, [from, to, userId]);

    function renderRadialRow(data, title) {
        return (
            <div className='chart-row'>
                <h5 role='none' aria-label={title + ' feedback.'}>
                    {title}
                </h5>
                <div className='primary-chart'>
                    {isScore ? (
                        <ScoreChart
                            score={data ? data.current : null}
                            showText={true}
                        />
                    ) : (
                        <SentimentChart
                            sentiment={data ? data.current : null}
                            showText={true}
                        />
                    )}
                    <label className='small'>
                        {formatMessage('peerFeedbackWidget.body.current')}
                    </label>
                </div>
                <div className='secondary-chart'>
                    {isScore ? (
                        <ScoreChart
                            score={data ? data.previous : null}
                            showText={true}
                        />
                    ) : (
                        <SentimentChart
                            sentiment={data ? data.previous : null}
                            showText={true}
                        />
                    )}
                    <label className='small'>
                        {formatMessage('peerFeedbackWidget.body.previous')}
                    </label>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <div className='peer-feedback-widget'>
                <WidgetLayout
                    loading={loading || loadingExtreme || loadingConnections}
                >
                    <WidgetHeader
                        title={useFormattedMessage(
                            'peerFeedbackWidget.header.title'
                        )}
                        route={''}
                    />
                    <hr aria-label='Received and Given feedback section.' />

                    <div className='selector'>
                        <button
                            className={`btn received ${
                                isScore ? 'highlighted' : ''
                            }`}
                            onClick={() => setIsScore(true)}
                            aria-pressed={isScore}
                        >
                            {useFormattedMessage(
                                'peerFeedbackWidget.button.score'
                            )}
                        </button>

                        <button
                            className={`btn given ${
                                !isScore ? 'highlighted' : ''
                            }`}
                            onClick={() => setIsScore(false)}
                            aria-pressed={!isScore}
                        >
                            {useFormattedMessage(
                                'peerFeedbackWidget.button.sentiment'
                            )}
                        </button>
                    </div>
                    {renderRadialRow(
                        average ? average.received : null,
                        useFormattedMessage('peerFeedbackWidget.body.received')
                    )}
                    {renderRadialRow(
                        average ? average.given : null,
                        useFormattedMessage('peerFeedbackWidget.body.given')
                    )}
                    <hr aria-label='Extreme feedback section.' />
                    <div className='extreme-feedback'>
                        <div className='chart'>
                            <h5 role='none'>
                                {useFormattedMessage(
                                    'peerFeedbackWidget.body.exceptional'
                                )}
                            </h5>
                            <TotalChart
                                total={
                                    extreme &&
                                    extreme.exceptionalFeedbackFrequency !==
                                        null
                                        ? extreme.exceptionalFeedbackFrequency
                                        : null
                                }
                                color={
                                    extreme &&
                                    extreme.exceptionalFeedbackFrequency !==
                                        null
                                        ? getHighestRag().hexColor
                                        : null
                                }
                                showText={true}
                            />
                            <label className='small'>
                                {useFormattedMessage(
                                    'peerFeedbackWidget.body.volume'
                                )}
                            </label>
                        </div>

                        <div className='chart'>
                            <h5 role='none'>
                                {useFormattedMessage(
                                    'peerFeedbackWidget.body.concerning'
                                )}
                            </h5>
                            <TotalChart
                                total={extreme?.concerningFeedbackFrequency}
                                color={
                                    extreme?.concerningFeedbackFrequency !==
                                    null
                                        ? getLowestRag().hexColor
                                        : null
                                }
                                showText={true}
                            />
                            <label className='small'>
                                {useFormattedMessage(
                                    'peerFeedbackWidget.body.volume'
                                )}
                            </label>
                        </div>
                    </div>
                </WidgetLayout>
            </div>

            <div className='user-connections'>
                <WidgetLayout
                    information={useFormattedMessage(
                        'peerFeedbackWidget.header.information'
                    )}
                    className='hide-on-mobile'
                    loading={loading || loadingExtreme || loadingConnections}
                >
                    <div className='simulated-header'></div>

                    <hr aria-label='Connections and outreach content.' />

                    <div className='w-section'>
                        <h5 role='none'>
                            {useFormattedMessage('userConnections.body.title1')}
                        </h5>
                        <div
                            className='w-content'
                            style={{ maxHeight: '10rem' }}
                        >
                            <i className='icon icon-connect'></i>
                            <RadialChart
                                className='total-chart'
                                progress={connections?.proactiveFeedback}
                                color={'#31353a'}
                                numberText={connections?.total}
                                secondColor={
                                    connections
                                        ? '#ed3241'
                                        : constants.Colors.Default
                                }
                                strokeLinecap={'butt'}
                                strokeWidth={'20px'}
                                textHeight={'45%'}
                            />
                            <div className='w-legend'>
                                <div className='w-row'>
                                    <div
                                        style={{
                                            backgroundColor: '#31353a',
                                        }}
                                    ></div>
                                    <p className='bold'>
                                        {useFormattedMessage(
                                            'userConnections.body.proactive'
                                        )}
                                    </p>

                                    <h3 role='none'>
                                        {typeof connections?.proactiveFeedback ===
                                        'number'
                                            ? `${connections.proactiveFeedback}%`
                                            : '-'}
                                    </h3>
                                </div>
                                <div className='w-row'>
                                    <div
                                        style={{
                                            backgroundColor: '#ed3241',
                                        }}
                                    ></div>
                                    <p className='bold'>
                                        {useFormattedMessage(
                                            'userConnections.body.reactive'
                                        )}
                                    </p>
                                    <h3 role='none'>
                                        {typeof connections?.reactiveFeedback ===
                                        'number'
                                            ? `${connections.reactiveFeedback}%`
                                            : '-'}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-section'>
                        <h5 role='none'>
                            {useFormattedMessage('userConnections.body.title2')}
                        </h5>
                        <div className='w-content outreach'>
                            <i className='icon icon-link'></i>
                            <div className='chart-title'>
                                <RadialChart
                                    progress={connections?.withOtherTeams ?? 0}
                                    color={'#31353a'}
                                    numberText={
                                        connections?.withOtherTeams
                                            ? `${connections.withOtherTeams}%`
                                            : '0%'
                                    }
                                    strokeLinecap={'butt'}
                                    strokeWidth={'20px'}
                                    textHeight={'45%'}
                                />
                                <p className='bold'>
                                    {useFormattedMessage(
                                        'userConnections.body.teamConnections'
                                    )}
                                </p>
                            </div>
                            <div className='chart-title no-margin'>
                                <DisplayRagValue
                                    value={connections?.teamsConnected ?? '-'}
                                    color={
                                        connections
                                            ? '#31353a'
                                            : constants.Colors.Default
                                    }
                                />
                                <p className='bold'>
                                    {useFormattedMessage(
                                        'userConnections.body.teamsConnected'
                                    )}
                                </p>
                            </div>
                            <div className='chart-title no-margin'>
                                <DisplayRagValue
                                    value={
                                        connections
                                            ? `${connections.employeesConnected}`
                                            : '-'
                                    }
                                    color={'#f2f4f8'}
                                    textColor={'black'}
                                />
                                <p className='bold'>
                                    {useFormattedMessage(
                                        'userConnections.body.employeesConnected'
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-section'>
                        <h5 role='none'>
                            {useFormattedMessage('userConnections.body.title3')}
                        </h5>
                        <div className='w-content outreach'>
                            {connectionActors?.map((act, i) => (
                                <ActAuxBox
                                    key={`act-${act?.id}-${i}`}
                                    i={i}
                                    translations={translations}
                                    totalTranslation={totalTranslation}
                                    act={act}
                                />
                            ))}
                        </div>
                    </div>
                </WidgetLayout>
            </div>
        </Fragment>
    );
}

PeerFeedback.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    userId: PropTypes.string,
    months: PropTypes.number,
};

function ActAuxBox({ act, i, totalTranslation, translations }) {
    return act ? (
        <div className='chart-title'>
            <div className='w-actor'>
                <img alt={`${act.name} photo`} src={act.image} />
                <div className='w-tooltip'>
                    <div>
                        <h4>{act.name}</h4>
                        {act.primaryConnection &&
                            act.primaryConnection.map((x, i) => (
                                <div
                                    key={`bubble-${act.id}-${i}`}
                                    className='w-bubble'
                                >
                                    {x}
                                </div>
                            ))}
                    </div>
                    <div>
                        <DisplayRagValue value={act.volume} color={'#31353a'} />
                        <p>{totalTranslation}</p>
                    </div>
                </div>
                <p className='bold'>{translations[i]}</p>
            </div>
        </div>
    ) : (
        <div className='chart-title'>
            <div className='w-actor'>
                <div
                    className='icon-container'
                    style={{
                        backgroundColor: i == 2 ? '#424953' : 'white',
                        borderColor: i == 2 ? '#424953' : '#9b9b9b',
                    }}
                >
                    <i
                        className={`icon icon-${
                            i == 2 ? 'collaborators' : 'my-profile-active'
                        }`}
                    ></i>
                </div>
                <p className='bold'>{translations[i]}</p>
            </div>
        </div>
    );
}

ActAuxBox.propTypes = {
    act: PropTypes.object,
    i: PropTypes.number,
    totalTranslation: PropTypes.string,
    translations: PropTypes.array,
};
