import { Fragment, useEffect, useState } from 'react';
import { BarChart, ResponsiveContainer, XAxis, Tooltip, Bar } from 'recharts';
import { getSurveyQuestionDistribution } from '../../../../../../services/surveyService';
import { Loading } from '../../../../loadings/Loading';
import { CustomTooltip } from '../../ResponseRateTrend';
import { PropTypes } from 'prop-types';
import constants from '../../../../constants';
import './LikertTrendChart.scss';

export function LikertTrendChart({ surveyId, questionId, teamId, iterations }) {
    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let uQ = await Promise.all(
                iterations?.map(async (it) => ({
                    data: await getSurveyQuestionDistribution(
                        surveyId,
                        questionId,
                        teamId,
                        it
                    ),

                    iteration: it,
                }))
            );

            setData(
                uQ.map((x) => ({
                    stronglyDisagree: x.data.valueDistribution[0].value,
                    disagree: x.data.valueDistribution[1].value,
                    neutral: x.data.valueDistribution[2].value,
                    agree: x.data.valueDistribution[3].value,
                    stronglyAgree: x.data.valueDistribution[4].value,
                    iteration: x.iteration + 1,
                }))
            );
        }

        if (surveyId && questionId && iterations?.length) {
            fetchData();
            setLoading(false);
        }
    }, [surveyId, questionId, teamId, iterations]);

    return (
        <Fragment>
            {loading ? (
                <Loading />
            ) : (
                <ResponsiveContainer
                    width='100%'
                    height='100%'
                    minHeight={'15rem'}
                >
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                        }}
                    >
                        <XAxis
                            tickLine={false}
                            dataKey='iteration'
                            tick={{
                                fontSize: '1.2rem',
                                fontFamily: 'Open Sans',
                            }}
                            tickFormatter={(tick) => `#${tick}`}
                        />
                        <Tooltip
                            wrapperStyle={{ zIndex: 1000 }}
                            cursor={{ fill: 'transparent' }}
                            content={({ active, payload }) => {
                                if (active && payload) {
                                    return (
                                        <CustomTooltip>
                                            <div>
                                                <div className='tooltip-box stronglyAgree'></div>
                                                <h4
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {
                                                        payload[0]?.payload
                                                            ?.stronglyAgree
                                                    }
                                                    %
                                                </h4>
                                            </div>
                                            <div>
                                                <div className='tooltip-box agree'></div>
                                                <h4
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {payload[0]?.payload?.agree}
                                                    %
                                                </h4>
                                            </div>
                                            <div>
                                                <div className='tooltip-box neutral'></div>
                                                <h4
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {
                                                        payload[0]?.payload
                                                            ?.neutral
                                                    }
                                                    %
                                                </h4>
                                            </div>
                                            <div>
                                                <div className='tooltip-box disagree'></div>
                                                <h4
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {
                                                        payload[0]?.payload
                                                            ?.disagree
                                                    }
                                                    %
                                                </h4>
                                            </div>
                                            <div>
                                                <div className='tooltip-box stronglyDisagree'></div>
                                                <h4
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {
                                                        payload[0]?.payload
                                                            ?.stronglyDisagree
                                                    }
                                                    %
                                                </h4>
                                            </div>
                                        </CustomTooltip>
                                    );
                                }
                                return <Tooltip />;
                            }}
                        />
                        <Bar
                            maxBarSize={35}
                            dataKey='stronglyDisagree'
                            stackId='a'
                            fill={constants.Colors.StronglyDisagree}
                        />
                        <Bar
                            maxBarSize={35}
                            dataKey='disagree'
                            stackId='a'
                            fill={constants.Colors.Disagree}
                        />
                        <Bar
                            maxBarSize={35}
                            dataKey='neutral'
                            stackId='a'
                            fill={constants.Colors.Neutral}
                        />
                        <Bar
                            maxBarSize={35}
                            dataKey='agree'
                            stackId='a'
                            fill={constants.Colors.Agree}
                        />
                        <Bar
                            maxBarSize={35}
                            dataKey='stronglyAgree'
                            stackId='a'
                            fill={constants.Colors.StronglyAgree}
                        />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </Fragment>
    );
}

LikertTrendChart.propTypes = {
    surveyId: PropTypes.string,
    questionId: PropTypes.string,
    teamId: PropTypes.string,
    iterations: PropTypes.array,
};
