import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../shared/inputs/AddButton';
import { Page } from '../../../shared/layout/Page';
import { Surveys } from '../../../shared/surveys/Surveys';
import { SurveyProvider } from './SurveyContext';
import './AdminSurveys.scss';

export function AdminSurveys() {
    const navigate = useNavigate();

    const [dates, setDates] = useState({
        from: moment().startOf('month'),
        to: moment().endOf('month'),
    });

    return (
        <div className='admin-surveys'>
            <Page>
                <div className='header'>
                    <AddButton
                        onClick={() =>
                            navigate('/admin/surveys-settings/new-survey')
                        }
                        autoFocus
                    >
                        Add Survey
                    </AddButton>
                    <div className='date-month-selector'>
                        <h2 role='none'>
                            {dates?.from?.format('MMM YYYY').toUpperCase()}{' '}
                        </h2>
                        <i
                            role='button'
                            tabIndex={0}
                            aria-label='Previous month'
                            className='icon icon-arrow-left'
                            onClick={() => {
                                setDates({
                                    from: moment(dates?.from)
                                        .subtract(1, 'month')
                                        .startOf('month'),
                                    to: moment(dates?.to)
                                        .subtract(1, 'month')
                                        .endOf('month'),
                                });
                            }}
                            onKeyPress={(e) =>
                                e.key === 'Enter' &&
                                setDates({
                                    from: moment(dates?.from)
                                        .subtract(1, 'month')
                                        .startOf('month'),
                                    to: moment(dates?.to)
                                        .subtract(1, 'month')
                                        .endOf('month'),
                                })
                            }
                        ></i>
                        <i
                            role='button'
                            tabIndex={0}
                            aria-label='Next month'
                            className='icon icon-arrow-right'
                            onClick={() => {
                                setDates({
                                    from: moment(dates?.from)
                                        .add(1, 'month')
                                        .startOf('month'),
                                    to: moment(dates?.to)
                                        .add(1, 'month')
                                        .endOf('month'),
                                });
                            }}
                            onKeyPress={(e) => {
                                e.key === 'event' &&
                                    setDates({
                                        from: moment(dates?.from)
                                            .add(1, 'month')
                                            .startOf('month'),
                                        to: moment(dates?.to)
                                            .add(1, 'month')
                                            .endOf('month'),
                                    });
                            }}
                        ></i>
                    </div>
                </div>
                <SurveyProvider>
                    <Surveys dates={dates} />
                </SurveyProvider>
            </Page>
        </div>
    );
}
