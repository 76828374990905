import { Fragment, useEffect, useState } from 'react';
import {
    getFeedbackQuestions,
    getQuestionTypes,
} from '../../../../../services/questionService';
import { Modal } from '../../../../shared/components/Modal';
import { SearchInput } from '../../../../shared/inputs/SearchInput';
import { useAsyncError } from '../../../../shared/lib/helper';
import { Loading } from '../../../../shared/loadings/Loading';
import PropTypes from 'prop-types';
import './SuggestedQuestionModal.scss';

export function SuggestedQuestionModal({ onSelect, open, topic }) {
    const [loading, setLoading] = useState(true);

    const [question] = useState(null);

    const [questions, setQuestions] = useState([]);

    const [, setQuestionTypes] = useState([]);

    const [topicQuestions, setTopicQuestions] = useState([]);

    const [otherTopicQuestions, setOtherTopicQuestions] = useState([]);

    const [search, setSearch] = useState('');

    useEffect(() => {
        setOtherTopicQuestions(
            search
                ? questions.filter(
                      (x) =>
                          x.topic.id !== topic?.id &&
                          x.questionText
                              .toUpperCase()
                              .includes(search.toUpperCase())
                  )
                : questions.filter((x) => x.topic.id !== topic?.id)
        );
    }, [search]);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchData() {
            try {
                let [q, types] = await Promise.all([
                    getFeedbackQuestions(),
                    getQuestionTypes(),
                ]);

                setQuestions(q);
                setQuestionTypes(types);
                setOtherTopicQuestions(q?.sort(sortTopics));
                setLoading(false);
            } catch (e) {
                throwError(e);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (topic?.id && questions.length) {
            let tQuestions = questions.filter((x) => x.topic.id === topic?.id);

            let oTopicQuestions = questions.filter(
                (x) => x.topic.id !== topic?.id
            );

            setTopicQuestions(tQuestions);

            setOtherTopicQuestions(oTopicQuestions);
        }
    }, [topic?.id]);

    function sortTopics(a, b) {
        return a?.topic?.title
            ?.toLowerCase()
            .localeCompare(b?.topic?.title?.toLowerCase());
    }

    return (
        <Fragment>
            <Modal
                className='suggested-question-modal'
                open={open}
                onClose={() => onSelect(question ? question : null)}
            >
                {loading ? (
                    <Loading />
                ) : (
                    <Fragment>
                        <div className='search'>
                            <SearchInput
                                onSearch={(text) => setSearch(text)}
                                placeholder={'Search alternative questions'}
                            />
                        </div>
                        {!!topicQuestions?.length && (
                            <div className='topic-questions'>
                                <h3 role='none'>Suggested questions</h3>

                                <div className='wrapper'>
                                    {topicQuestions?.map((q) => (
                                        <Question
                                            key={`question-${q?.id}`}
                                            question={q}
                                            onClick={(question, event) =>
                                                onSelect(question, event)
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        )}

                        {!!otherTopicQuestions?.length && (
                            <div className='topic-questions'>
                                <h3>{`${
                                    topic?.id
                                        ? 'Alternative questions'
                                        : 'Suggested questions'
                                }`}</h3>
                                <div className='wrapper'>
                                    {otherTopicQuestions?.map((q) => (
                                        <Question
                                            key={`question-${q?.id}`}
                                            question={q}
                                            onClick={(question, event) =>
                                                onSelect(question, event)
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </Modal>
        </Fragment>
    );
}

function Question({ question, onClick }) {
    const types = [
        { value: 0, icon: 'icon-feedback-total', text: 'Suggestion' },
        { value: 1, icon: 'icon-feedback-score', text: 'Score' },
        { value: 2, icon: 'icon-yn', text: 'Yes or No' },
        { value: 3, icon: 'icon-likert', text: 'Likert scale' },
        { value: 4, icon: 'icon-choice', text: 'Choice' },
    ];

    useEffect(() => {}, []);

    const type = types?.find((t) => t?.value === question?.questionType);
    return (
        <div
            className='question'
            onClick={(e) => onClick(question, e)}
            onKeyPress={(e) => e.key === 'Enter' && onClick(question, e)}
            tabIndex={0}
        >
            <h5 role='none'>{question?.questionText}</h5>
            <div>
                <div className='topic'>
                    <i aria-hidden={true} className='icon icon-topic'></i>
                    <p className='small'>{question?.topic?.title}</p>
                </div>
                <div>
                    <i aria-hidden={true} className={`icon ${type?.icon}`}></i>
                    <p className='small'>{type?.text}</p>
                </div>
            </div>
        </div>
    );
}

SuggestedQuestionModal.propTypes = {
    onSelect: PropTypes.func,
    open: PropTypes.bool,
    topic: PropTypes.object,
};

Question.propTypes = {
    question: PropTypes.object,
    onClick: PropTypes.func,
};
