import { Fragment, useEffect, useState } from 'react';
import {
    getMeanScoreOfGivenFeedback,
    getMeanScoreOfReceivedFeedback,
} from '../../../../../services/feedbackService';
import { getUserSurveyIndex } from '../../../../../services/pulseIndexService';
import { useAsyncError } from '../../../../shared/lib/helper';
import { useUser } from '../../../../shared/lib/useUser';
import { DateSelector } from '../../../../shared/utils/DateSelector';
import {
    IconChartTypes,
    WGroupIconChart,
} from '../../../company/feedback/widgets/WGroupIconChart';
import { WLayout } from '../../../company/feedback/widgets/WLayout';
import './MeHomeWidget.scss';

export function MeHomeWidget() {
    const [dates, setDates] = useState(null);

    const [data, setData] = useState(null);

    const user = useUser();

    const [loading, setLoading] = useState(true);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetch() {
            try {
                setLoading(true);
                let [given, received, survey] = await Promise.all([
                    getMeanScoreOfGivenFeedback(
                        dates?.from,
                        dates?.to,
                        user?.id
                    ),
                    getMeanScoreOfReceivedFeedback(
                        dates?.from,
                        dates?.to,
                        user?.id
                    ),
                    getUserSurveyIndex(dates?.from, dates?.to, user?.id),
                ]);

                setData({
                    given,
                    received,
                    completionRate: survey?.actorsCompletionRates.find(
                        (x) => x.type === 0
                    ).completionRate,
                });
                setLoading(false);
            } catch (error) {
                throwError(error);
            }
        }

        if (dates?.months && user) {
            fetch();
        }
    }, [dates, user]);

    return (
        <WLayout info='' loading={loading} className='me-home-widget'>
            <Fragment>
                <DateSelector
                    onChange={(from, to, months) =>
                        setDates({ from, to, months })
                    }
                />

                <WGroupIconChart
                    label={'Feedback given'}
                    type={IconChartTypes.SCORE}
                    value={data?.given}
                />
                <WGroupIconChart
                    label={'Feedback received'}
                    type={IconChartTypes.SCORE}
                    value={data?.received}
                />
                <WGroupIconChart
                    label={'Pulse completion rate'}
                    type={IconChartTypes.PULSE}
                    value={data?.completionRate}
                />
            </Fragment>
        </WLayout>
    );
}
