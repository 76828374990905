import { useEffect, useState } from 'react';

export function useIterationQuestionData(survey, question, teamId) {
    const [iterations, setIterations] = useState(null);

    useEffect(() => {
        let data = [];
        if (survey && question) {
            if (teamId) {
                if (survey.surveyType === 1) {
                    data = survey?.iterations?.map((survey) => ({
                        iteration: survey?.iteration,
                        insight: survey?.teamInsights
                            ?.find((x) => x?.team.id === teamId)
                            ?.questionInsight?.find(
                                (q) => q.questionId === question?.id
                            ),
                    }));
                } else {
                    data = [
                        {
                            ...survey?.teamInsights?.find(
                                (x) => x.team.id === teamId
                            ),
                            insight: survey?.teamInsights
                                ?.find((x) => x?.team.id === teamId)
                                ?.questionInsight?.find(
                                    (q) => q.questionId === question?.id
                                ),
                            iteration: 0,
                        },
                    ];
                }
            } else {
                if (survey.surveyType === 1) {
                    data = survey?.iterations?.map((x) => ({
                        ...x,
                        insight: x?.insight?.questionInsight?.find(
                            (y) => y.questionId === question?.id
                        ),
                    }));
                } else {
                    data = [
                        {
                            insight: survey?.insight?.questionInsight?.find(
                                (y) => y.questionId === question?.id
                            ),
                            iteration: 0,
                        },
                    ];
                }
            }

            setIterations(data);
        }
    }, [teamId, survey, question]);

    return iterations;
}

export function useQuestionsIterationQuestionData(survey, questions, teamId) {
    const [iterations, setIterations] = useState(null);

    useEffect(() => {
        if (survey && questions?.length) {
            let data = questions?.map((question) => {
                let aux = [];
                if (teamId) {
                    if (survey.surveyType === 1) {
                        aux = survey?.iterations?.map((survey) => ({
                            iteration: survey?.iteration,
                            insight: survey?.teamInsights
                                ?.find((x) => x?.team.id === teamId)
                                ?.questionInsight?.find(
                                    (q) => q.questionId === question?.id
                                ),
                        }));
                    } else {
                        aux = [
                            {
                                ...survey?.teamInsights?.find(
                                    (x) => x.team.id === teamId
                                ),
                                insight: survey?.teamInsights
                                    ?.find((x) => x?.team.id === teamId)
                                    ?.questionInsight?.find(
                                        (q) => q.questionId === question?.id
                                    ),
                                iteration: 0,
                            },
                        ];
                    }
                } else {
                    if (survey.surveyType === 1) {
                        aux = survey?.iterations?.map((x) => ({
                            ...x,
                            insight: x?.insight?.questionInsight?.find(
                                (y) => y.questionId === question?.id
                            ),
                        }));
                    } else {
                        aux = [
                            {
                                insight: survey?.insight?.questionInsight?.find(
                                    (y) => y.questionId === question?.id
                                ),
                                iteration: 0,
                            },
                        ];
                    }
                }

                return {
                    questionId: question?.id,
                    data: aux?.sort((x, y) => x.iteration - y.iteration),
                };
            });

            setIterations(data);
        }
    }, [teamId, survey, questions]);

    return iterations;
}
