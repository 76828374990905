import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AppActionTypes, AppContext } from '../../../contexts/AppContext';

export function useSelectedTeam() {
    const { state, dispatch } = useContext(AppContext);

    const location = useLocation();

    const [isTeam, setIsTeam] = useState(false);

    useEffect(() => {
        if (state?.selectedTeam === null && isTeam) {
            setTeam(state?.cache?.teamLeadTeams?.[0]);
        }
    }, [isTeam]);

    useEffect(() => {
        let match = location.pathname.match(/\/(.*?)\//);

        setIsTeam(match?.length > 0 && match[1] === 'my-team');
    }, [location]);

    function setTeam(teamId) {
        let t = state?.cache?.teams?.find((x) => x?.id === teamId);

        if (t) {
            dispatch({
                type: AppActionTypes.UPDATE,
                prop: 'selectedTeam',
                payload: t,
            });
        }
    }

    return { team: isTeam ? state?.selectedTeam : null, setTeam };
}
