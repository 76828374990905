import { isAuthorized } from '../components/pages/authentication/AuthControl';
import helper from '../components/shared/lib/helper';
import pagHelper from '../components/shared/lib/paginationHelper';
import { get, post, put, remove } from '../components/shared/lib/axios';
import { getAuthentication } from './authenticationService';

export async function getTeam(teamId) {
    return get(`teams/${teamId}`);
}

export async function getUserTeams(userId) {
    return get(`users/${userId}/teams`);
}

export async function getTeams() {
    return get(`teams`);
}

export async function getTeamsActors() {
    return get(`teams/actors`);
}

export async function getUserTeamLeadTeam() {
    let auth = await getAuthentication();
    let teams = await (isAuthorized('team::all_teams', auth?.permissions)
        ? get(`user/getTeamsSelect`)
        : get(`users/${auth?.userId}/teams/lead`));

    teams.sort(helper.reactSelectSort);

    return teams;
}

export async function getTeamsPaginated(offset, limit, filter, orderBy) {
    let pagination = pagHelper.createTeamPagination(
        offset,
        limit,
        filter,
        orderBy
    );

    return get(`teams/paginated`, pagination);
}

export async function updateTeam(teamId, body) {
    return put(`team/${teamId}`, body);
}

export async function createTeam(body) {
    return post(`team`, body);
}

export async function deleteTeam(teamId) {
    return remove(`team/${teamId}`);
}
