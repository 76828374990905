import constants from '../constants';

function createDefaultPagination (
    offset,
    limit,
    searchFilter,
    sortables,
    filters
) {
    let pagination = {
        offset: offset || 0,
        limit: limit || 10,
        filters: filters,
        sortables: sortables,
        searchFilter: searchFilter,
    };

    return pagination;
}

function createUserPagination (offset, limit, filter, orderBy) {
    let searchFilter = filter
        ? {
            propertiesToSearch: [
                'FirstName',
                'LastName',
                'JobTitle',
            ],
            searchValue: filter,
        }
        : null;

    let sortables = orderBy
        ? [
            {
                propertyName:
                    orderBy.id === 'name' ? 'FirstName' : orderBy.id,
                ascendent: !orderBy.desc,
            },
        ]
        : [];

    let userPagination = createDefaultPagination(
        offset,
        limit,
        searchFilter,
        sortables
    );

    return userPagination;
}

function createTeamPagination (offset, limit, filter, orderBy) {
    let searchFilter = filter
        ? {
            propertiesToSearch: ['Title'],
            searchValue: filter,
        }
        : null;

    let sortables = orderBy
        ? [
            {
                propertyName: orderBy.id === 'title' ? 'Title' : orderBy.id,
                ascendent: !orderBy.desc,
            },
        ]
        : [];
    let pagination = createDefaultPagination(
        offset,
        limit,
        searchFilter,
        sortables
    );

    return pagination;
}

function createTopicPagination (offset, limit, filter, orderBy) {
    let searchFilter = filter
        ? {
            propertiesToSearch: ['Title', 'Description'],
            searchValue: filter,
        }
        : null;

    let sortables = orderBy
        ? [
            {
                propertyName: orderBy.id === 'title' ? 'Title' : orderBy.id,
                ascendent: !orderBy.desc,
            },
        ]
        : [];
    let pagination = createDefaultPagination(
        offset,
        limit,
        searchFilter,
        sortables
    );

    return pagination;
}

function createQuestionPagination (offset, limit, filter, orderBy) {
    let searchFilter = filter
        ? {
            propertiesToSearch: ['QuestionText', 'Topic.Title'],
            searchValue: filter,
        }
        : null;
    let sortables = orderBy
        ? [
            {
                propertyName:
                    orderBy.id === 'questionText'
                        ? 'QuestionText'
                        : orderBy.id,
                ascendent: !orderBy.desc,
            },
        ]
        : [];
    let pagination = createDefaultPagination(
        offset,
        limit,
        searchFilter,
        sortables
    );

    return pagination;
}

function createQuestionCommentsPagination (
    take,
    offset,
    filter,
    orderBy,
    distributionFilter,
    questionType
) {
    let searchFilter = filter
        ? {
            propertiesToSearch: ['UserTo.Name', 'Question.Answer'],
            searchValue: filter,
        }
        : null;

    let filters =
        distributionFilter != null
            ? [
                {
                    propertyName: questionType === constants.QuestionType.Suggestion ? 'Sentiment.Score' : 'Question.Value',
                    conditionValue: distributionFilter,
                    conditionValueType:
                        typeof distributionFilter === 'number'
                            ? constants.ConditionValueTypes.Int
                            : typeof distributionFilter === 'string'
                                ? constants.ConditionValueTypes.String
                                : constants.ConditionValueTypes.Bool,
                },
            ]
            : [];

    let sortables = null;

    switch (orderBy) {
        case 0:
            sortables = [
                {
                    propertyName: 'Question.AnswerDate',
                    ascendent: false,
                },
            ];
            break;
        case 1:
            sortables = [
                {
                    propertyName: 'Question.AnswerDate',
                    ascendent: true,
                },
            ];
            break;
        case 2:
            sortables = [
                {
                    propertyName: 'UserTo.FirstName',
                    ascendent: true,
                },
            ];
            break;
        case 3:
            sortables = [
                {
                    propertyName: questionType === constants.QuestionType.Suggestion ? 'Sentiment.Score' : 'Question.Value',
                    ascendent: questionType != constants.QuestionType.Likert,
                },
            ];
            break;
        case 4:
            sortables = [
                {
                    propertyName: questionType === constants.QuestionType.Suggestion ? 'Sentiment.Score' : 'Question.Value',
                    ascendent: questionType === constants.QuestionType.Likert,
                },
            ];
            break;
        default:
            sortables = [];
            break;
    }

    let pagination = createDefaultPagination(
        offset,
        take,
        searchFilter,
        sortables,
        filters
    );

    return pagination;
}

function createFeedbackInsightsPagination (
    limit,
    offset,
    filter,
    orderBy,
    teamId
) {
    let searchFilter = filter
        ? {
            propertiesToSearch: teamId ? ['Name'] : ['Title'],
            searchValue: filter,
        }
        : null;

    let sortables = orderBy
        ? [
            {
                propertyName: orderBy.id,
                ascendent: !orderBy.desc,
            },
        ]
        : [];

    let pagination = createDefaultPagination(
        offset,
        limit,
        searchFilter,
        sortables,
        null
    );

    return pagination;
}

const paginationHelper = {
    createDefaultPagination,
    createUserPagination,
    createTeamPagination,
    createTopicPagination,
    createQuestionPagination,
    createQuestionCommentsPagination,
    createFeedbackInsightsPagination,
};

export default paginationHelper;
