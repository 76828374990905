import { Page } from '../../../shared/layout/Page';
import { useUser } from '../../../shared/lib/useUser';
import { MyConditionWidget } from './components/MyConditionWidget';
import { PoSComponent } from './components/PoSComponent';
import './Success.scss';

export function Success() {
    const user = useUser();
    return (
        <Page title='Success' className='success' loading={!user}>
            <div className='widgets'>
                <PoSComponent userId={user?.id} />
            </div>
            <div className='widgets'>
                <MyConditionWidget
                    success={true}
                    allItems={true}
                    userId={user?.id}
                    showMore={false}
                />
            </div>
        </Page>
    );
}
