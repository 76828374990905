import { LineTrendChart } from './LineTrendChart';
import { PropTypes } from 'prop-types';

export function ScoreTrendChart({ iterations }) {
    return (
        <LineTrendChart
            data={[
                ...iterations.map((x) => ({
                    Score: Number(x?.insight?.average?.toFixed(1) ?? 0),
                    name: x?.iteration + 1,
                })),
            ]}
            dataKey={'Score'}
            domain={[0, 10]}
        />
    );
}

ScoreTrendChart.propTypes = {
    iterations: PropTypes.array,
};
