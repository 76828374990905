import { forwardRef, Fragment } from 'react';

function forwardCheckbox(
    { checked, disabled, onChange, className, label, ...props },
    ref
) {
    return (
        <Fragment>
            <label className={`pulse360-checkbox-refactor ${className ?? ''}`}>
                {label && <label>{label}</label>}
                <input
                    title='checkbox'
                    ref={ref}
                    type='checkbox'
                    disabled={disabled}
                    checked={checked}
                    onChange={(e) => onChange(e.target.checked)}
                    {...props}
                />
                <span></span>
            </label>
        </Fragment>
    );
}

export const Checkbox = forwardRef(forwardCheckbox);
