import { Fragment, useContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import { AppActionTypes, AppContext } from '../../../contexts/AppContext';
import { updateUserImage } from '../../../services/userService';
import { AuthControl } from '../../pages/authentication/AuthControl';
import { Hamburger } from '../../shared/components/Hamburger';
import { MenuSelector } from '../../shared/components/MenuSelector';
import { Modal } from '../../shared/components/Modal';
import { AvatarImage } from '../../shared/inputs/AvatarImage';
import { useAsyncError } from '../../shared/lib/helper';
import { useClient } from '../../shared/lib/useClient';
import { useMediaQueries } from '../../shared/lib/useMediaQuery';
import { useUser } from '../../shared/lib/useUser';
import { MainButton } from './Components';
import { SideBarAdmin } from './SideBarAdmin';
import { SideBarCompany } from './SideBarCompany';
import { SideBarMe } from './SideBarMe';
import { SideBarMyTeam } from './SideBarMyTeam';
import { PropTypes } from 'prop-types';
import { signOut } from '../../../services/authenticationService';
import './SideBar.scss';

export function SideBar() {
    const [menuSelected, dispatchMenu] = useReducer(
        (_state, action) => {
            switch (action.type) {
                case 'me':
                    return { me: true };
                case 'my-team':
                    return { myTeam: true };
                case 'company':
                    return { company: true };
                case 'admin':
                    return { admin: true };
                default:
                    throw new Error();
            }
        },
        {
            me: false,
            myTeam: false,
            company: false,
            admin: false,
        }
    );

    const { largeOnly: collapsable, mediumDown: horizontal } =
        useMediaQueries();

    const location = useLocation();

    const client = useClient();

    const user = useUser();

    const [mainExpanded, setExpanded] = useState(false);

    const [, setLoading] = useState(true);

    const [collapsed, setCollapsed] = useState(null);

    const [hExpand, setHExpand] = useState(false);

    useEffect(() => {
        setCollapsed(collapsable);
    }, [collapsable]);

    useEffect(() => {
        if (!!user && !!client) {
            setLoading(false);
        }
    }, [user, client]);

    useEffect(() => {
        let match = location.pathname.match(/\/(.*?)\//);
        dispatchMenu({ type: match?.length ? match[1] : 'me' });
        setCollapsed(collapsable);
        setHExpand(false);
    }, [location]);

    useEffect(() => {
        setExpanded(false);
    }, [menuSelected]);

    useEffect(() => {
        if (horizontal) {
            document
                .getElementsByClassName('side-bar-container')[0]
                ?.classList.add('horizontal');
        } else {
            document
                .getElementsByClassName('side-bar-container')[0]
                ?.classList.remove('horizontal');
        }
    }, [horizontal]);

    useEffect(() => {
        let target = document.getElementsByClassName('side-bar-container')[0];
        if (collapsable && !collapsed) {
            target.classList.add('expanded');
        } else if (collapsable && collapsed) {
            target.classList.remove('expanded');
        }

        if (target.classList.contains('expanded') && !collapsable) {
            target.classList.remove('expanded');
        }
    }, [collapsable, mainExpanded, collapsed]);

    return (
        <Fragment>
            {horizontal && (
                <div className='side-bar-horizontal-bar'>
                    <Hamburger
                        onClick={() => setHExpand(!hExpand)}
                        open={hExpand}
                    />

                    {horizontal && <Client />}

                    <UserControl />
                </div>
            )}
            <nav
                aria-expanded={!collapsed}
                className={`side-bar ${collapsed ? 'collapsed' : ''} ${
                    horizontal ? 'horizontal' : ''
                } ${collapsable ? 'collapsable' : ''}`}
            >
                <div
                    role='navigation'
                    className={`main-menu ${
                        mainExpanded && !collapsed ? 'expanded' : ''
                    } ${horizontal && !hExpand ? 'collapsed' : ''}`}
                >
                    <div className='overflow'>
                        {collapsable && (
                            <Hamburger
                                onClick={() => setCollapsed(!collapsed)}
                                open={!collapsed}
                            />
                        )}
                        {!horizontal && <Client />}
                        <AuthControl
                            permissions='user'
                            authorized={
                                <MainButton
                                    title={'Me'}
                                    aria-label='Me'
                                    aria-expanded={
                                        menuSelected?.me ? true : false
                                    }
                                    expanded={menuSelected?.me ? true : false}
                                    to={'/me'}
                                    icon='icon-my-profile-active'
                                    onClick={() => dispatchMenu({ type: 'me' })}
                                >
                                    Me
                                </MainButton>
                            }
                        />

                        <AuthControl
                            permissions='team'
                            authorized={
                                <MainButton
                                    title={'My Team'}
                                    aria-label='My Team'
                                    aria-expanded={
                                        menuSelected?.myTeam ? true : false
                                    }
                                    expanded={
                                        menuSelected?.myTeam ? true : false
                                    }
                                    to={'/my-team'}
                                    icon='icon-my-team-active'
                                    onClick={() =>
                                        dispatchMenu({ type: 'my-team' })
                                    }
                                >
                                    My Team
                                </MainButton>
                            }
                        />

                        <AuthControl
                            permissions='company;company::pulse_settings'
                            authorized={
                                <MainButton
                                    title={'Company'}
                                    aria-label='Company'
                                    aria-expanded={
                                        menuSelected?.company ? true : false
                                    }
                                    expanded={
                                        menuSelected?.company ? true : false
                                    }
                                    to={'/company'}
                                    icon='icon-company-active'
                                    onClick={() =>
                                        dispatchMenu({ type: 'company' })
                                    }
                                >
                                    Company
                                </MainButton>
                            }
                        />

                        <AuthControl
                            permissions='company::company_settings;company::pulse_settings'
                            authorized={
                                <MainButton
                                    title={'Admin settings'}
                                    aria-label='Admin settings'
                                    aria-expanded={
                                        menuSelected?.admin ? true : false
                                    }
                                    expanded={
                                        menuSelected?.admin ? true : false
                                    }
                                    to={'/admin'}
                                    icon='icon-settings-active'
                                    onClick={() =>
                                        dispatchMenu({ type: 'admin' })
                                    }
                                >
                                    Admin
                                </MainButton>
                            }
                        />
                    </div>
                    {!horizontal && (
                        <UserControl
                            mainExpanded={mainExpanded}
                            collapsed={collapsed}
                        />
                    )}
                </div>
                <div
                    className={`main-sections overflow ${
                        mainExpanded || collapsed ? '' : 'expanded'
                    } ${horizontal && !hExpand ? 'collapsed' : ''}`}
                >
                    {menuSelected?.me && (
                        <SideBarMe onBack={() => setExpanded(true)} />
                    )}
                    {menuSelected?.myTeam && (
                        <SideBarMyTeam onBack={() => setExpanded(true)} />
                    )}

                    {menuSelected?.company && (
                        <SideBarCompany onBack={() => setExpanded(true)} />
                    )}

                    {menuSelected?.admin && (
                        <SideBarAdmin onBack={() => setExpanded(true)} />
                    )}
                </div>
            </nav>
        </Fragment>
    );
}

export function UserControl({ mainExpanded, collapsed }) {
    const user = useUser();

    const client = useClient();

    const { dispatch: appDispatch } = useContext(AppContext);

    const [, setLoading] = useState(true);

    const [open, setOpen] = useState(false);

    const throwError = useAsyncError();

    function httpHtml(content) {
        const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
        let policy = content.replace(
            reg,
            "<a target='_blank' href='$1$2'>$1$2</a>"
        );
        return (
            <p
                dangerouslySetInnerHTML={{
                    __html: policy,
                }}
            ></p>
        );
    }

    async function updateImage(file, toBlob, fileName) {
        try {
            setLoading(true);
            let userImageUrl = await updateUserImage({
                file: toBlob,
                fileName: fileName,
                userId: user?.id,
            });

            let auxUser = { ...user, image: userImageUrl };

            appDispatch({
                type: AppActionTypes.UPDATE,
                prop: 'user',
                payload: auxUser,
            });

            setTimeout(
                () => appDispatch({ type: AppActionTypes.REFRESH }),
                1000
            );
        } catch (error) {
            throwError(error);
        }
    }

    return (
        <Fragment>
            <MenuSelector
                clickable={
                    <div className='user-footer'>
                        {user?.image ? (
                            <img
                                role='img'
                                aria-label='Click to open your settings'
                                alt={user?.name}
                                src={user?.image}
                            />
                        ) : (
                            <i
                                role='img'
                                aria-label='User icon'
                                className='icon icon-person'
                            ></i>
                        )}
                        {mainExpanded && !collapsed && (
                            <label>{user?.name}</label>
                        )}

                        <div className='user-options'></div>
                    </div>
                }
                title='Click to open your settings'
            >
                <AvatarImage image={user?.image} onChange={updateImage} />
                <div role='user properties' className='user-props'>
                    <label>{user?.name}</label>
                    <h6>{user?.jobTitle}</h6>
                    <hr />
                    <label>{client?.companyName}</label>
                    {user?.teams?.map((t, i) => (
                        <h5 key={`t-${t?.id}-${i}`}>{t?.name}</h5>
                    ))}
                    <hr />
                </div>
                <div className='group link'>
                    <i
                        role='img'
                        aria-label='Notifications icon'
                        className='icon icon-notification'
                    ></i>
                    <Link
                        title='Notifications'
                        aria-label='Click to open notification settings'
                        to={'/me/notifications'}
                    >
                        <h5>Notifications</h5>
                    </Link>
                </div>

                <div className='group link'>
                    <i
                        role='img'
                        aria-label='Privacy policy'
                        className='icon icon-multiple-choice'
                    ></i>
                    <a
                        tabIndex={0}
                        role='button'
                        title='Privacy policy'
                        aria-label='Click to open the privacy policy'
                        onClick={() => setOpen(true)}
                    >
                        <h5>Privacy policy</h5>
                    </a>
                </div>

                <div className='group link'>
                    <i
                        role='img'
                        title='About Pulse 360'
                        aria-label='About Pulse 360. This will open a link in a new tab.'
                        className='icon icon-pulse'
                    ></i>
                    <a
                        title='Privacy policy'
                        aria-label='Click to find out more about Pulse 360.'
                        target='_blank'
                        rel='noopener noreferal noreferrer'
                        href='https://www.pulse360.io/'
                    >
                        About Pulse 360
                    </a>
                </div>
                <div className='group link'>
                    <i className='icon icon-logout'></i>
                    <a
                        tabIndex={0}
                        role='button'
                        title='Log out'
                        aria-label='Click to log out from Pulse 360.'
                        onClick={signOut}
                    >
                        Log out
                    </a>
                </div>
            </MenuSelector>
            <Modal
                className='modal-policies'
                open={open}
                onClose={() => setOpen(false)}
            >
                <div className='row header space-between'>
                    <h1 className='title'>Privacy policy</h1>

                    <i
                        role='button'
                        className='icon-close'
                        style={{ cursor: 'pointer' }}
                        onClick={() => setOpen(false)}
                    />
                </div>
                <div>
                    <ol>
                        {client?.privacyPolicy?.map((policy, index) => (
                            <li key={index}> {httpHtml(policy)}</li>
                        ))}
                    </ol>
                </div>
            </Modal>
        </Fragment>
    );
}

UserControl.propTypes = {
    mainExpanded: PropTypes.bool,
    collapsed: PropTypes.bool,
};

function Client() {
    const client = useClient();

    return (
        <div className='client'>
            {client?.logo ? (
                <img alt='Customer logo' src={client?.logo} />
            ) : (
                <Logo />
            )}
            <h3 title={client?.companyName} className='text-ellipsis'>
                {client?.companyName}
            </h3>
        </div>
    );
}
