import {
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { CustomTooltip } from '../../ResponseRateTrend';
import { PropTypes } from 'prop-types';
import { useRagIntervals } from '../../../../lib/useRagIntervals';

export function LineTrendChart({ data, dataKey, domain, tickFormatter }) {
    return (
        <ResponsiveContainer width='100%' height='100%' minHeight='15rem'>
            {!!data && (
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 5,
                        left: -20,
                        bottom: 5,
                    }}
                >
                    <XAxis
                        dataKey='name'
                        tickLine={false}
                        tick={{ fontSize: '1.2rem', fontFamily: 'Open Sans' }}
                        tickFormatter={(tick) => `#${tick}`}
                    />
                    <YAxis
                        domain={domain}
                        tickLine={false}
                        tick={{ fontSize: '1.2rem', fontFamily: 'Open Sans' }}
                        tickFormatter={tickFormatter}
                    />
                    <Tooltip
                        wrapperStyle={{ zIndex: 1000 }}
                        cursor={{ fill: 'transparent' }}
                        content={({ active, payload }) => {
                            if (active) {
                                return (
                                    <div className='historic-tooltip'>
                                        {payload.map((x, i) => (
                                            <CustomTooltip key={`ct-${i}`}>
                                                <h4>
                                                    {'Score' in x.payload
                                                        ? x?.payload?.Score
                                                        : 'Sentiment' in
                                                          x.payload
                                                        ? `${x?.payload?.Sentiment}%`
                                                        : `${x?.payload?.Choice}%`}
                                                </h4>
                                            </CustomTooltip>
                                        ))}
                                    </div>
                                );
                            }

                            return null;
                        }}
                    />
                    <Line
                        connectNulls
                        type='monotone'
                        dataKey={dataKey}
                        stroke='#4cbabb'
                        activeDot={<CustomDot active />}
                        dot={<CustomDot />}
                    />
                </LineChart>
            )}
        </ResponsiveContainer>
    );
}

LineTrendChart.propTypes = {
    data: PropTypes.array,
    dataKey: PropTypes.string,
    domain: PropTypes.array,
    tickFormatter: PropTypes.func,
};

export const CustomDot = ({ active, ...props }) => {
    const { cx, cy, payload } = props;

    const { choiceArray } = useRagIntervals();

    return (
        <circle
            cx={cx}
            cy={cy}
            r={payload?.index ? 5 : 4}
            stroke={
                payload?.index ? choiceArray[payload?.index - 11] : '#4cbabb'
            }
            strokeDasharray=''
            fill={
                active
                    ? payload?.index
                        ? choiceArray[payload?.index - 11]
                        : '#4cbabb'
                    : '#fff'
            }
            strokeWidth={1}
        />
    );
};

CustomDot.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    payload: PropTypes.object,
    active: PropTypes.bool,
};
