import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import constants from '../constants';
import moment from 'moment';

const reactSelectSort = (a, b) => {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
};

function getParameterByName (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const helper = {
    getParameterByName: getParameterByName,
    reactSelectSort: reactSelectSort,
};

export default helper;

export function renderLikertAnswer (value) {
    if (value === -1 || value === 'stronglyDisagree') {
        return 'Strongly Disagree';
    } else if (value === -2 || value === 'disagree') {
        return 'Disagree';
    } else if (value === -3 || value === 'neutral') {
        return 'Neutral';
    } else if (value === -4 || value === 'agree') {
        return 'Agree';
    } else if (value === -5 || value === 'stronglyAgree') {
        return 'Strongly Agree';
    }

    return 'unknown';
}

export function formatQuestionType (questionType) {
    switch (questionType) {
        case constants.QuestionType.Suggestion:
            return 'Sentiment';
        case constants.QuestionType.Score:
            return 'Score';
        case constants.QuestionType.YesNo:
            return 'Yes/No';
        case constants.QuestionType.Likert:
            return 'Likert scale';
        case constants.QuestionType.Choice:
            return 'Choice';
        default:
            return;
    }
}

export function getValueByQuestionType (userQuestion) {
    if (userQuestion.questionType == constants.QuestionType.Suggestion || userQuestion.questionType == constants.QuestionType.Score) {
        return userQuestion.score;
    } else if (userQuestion.questionType == constants.QuestionType.YesNo) {
        return userQuestion.yesNo;
    } else if (userQuestion.questionType == constants.QuestionType.Likert) {
        return userQuestion.likert;
    } else if (userQuestion.questionType == constants.QuestionType.Choice) {
        return userQuestion.choice;
    }

    return 'unknown';
}

export function getSurveyTitleFileName (title, pdf = false) {
    return `${title.substring(0, 150)} - ${moment().format(
        'DD MMM YY HH.mm'
    )}${pdf ? '.pdf' : '.csv'}`;
}

export const useAsyncError = () => {
    const [, setError] = useState();

    return useCallback(
        (e) => {
            console.log(e);
            setError(() => {
                throw e;
            });
        },
        [setError]
    );
};

export const useFormattedMessage = (id, values) => {
    const intl = useIntl();
    return intl.formatMessage(
        { id: id },
        {
            br: <br />,
            ul: (...chunks) => <ul>{chunks}</ul>,
            li: (...chunks) => <li>{chunks}</li>,
            strong: (...chunks) => <strong>{chunks}</strong>,
            ...values,
        }
    );
};
