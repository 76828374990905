import _uniqueId from 'lodash/uniqueId';
import { useCallback, useState } from 'react';
import { useWindowEvent } from '../lib/useWindowEvent';
import { PropTypes } from 'prop-types';

export function TooltipSelector({ value, children, ...props }) {
    const [open, setOpen] = useState(false);

    const [id] = useState(_uniqueId('tooltip-selector-'));

    useWindowEvent(
        'mousedown',
        useCallback((event) => {
            if (!event.target.closest(`#${id}`)) {
                setOpen(false);
            }
        }, [])
    );

    return (
        <a
            {...props}
            tabIndex={0}
            className={`tooltip-selector ${open ? 'focus' : ''}`}
            id={id}
            onClick={() => {
                setOpen(true);
            }}
            onKeyPress={() => {
                setOpen(true);
            }}
            aria-expanded={open}
        >
            <div className='value'>{value}</div>
            <div className={`tooltip ${open ? 'active' : ''}`}>{children}</div>
        </a>
    );
}

TooltipSelector.propTypes = {
    value: PropTypes.object,
    children: PropTypes.object,
};
