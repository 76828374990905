import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSurvey } from '../../../../../services/surveyService';
import { HorizontalBarChart } from '../../../../shared/charts/HorizontalBarChart';
import { Aside } from '../../../../shared/components/Aside';
import { TeamSelector } from '../../../../shared/components/TeamSelector';
import constants from '../../../../shared/constants';
import { MainContent } from '../../../../shared/layout/MainContent';
import { Page } from '../../../../shared/layout/Page';
import { useAsyncError } from '../../../../shared/lib/helper';
import { useCache } from '../../../../shared/lib/useCache';
import { useMediaQueries } from '../../../../shared/lib/useMediaQuery';
import { useSelectedTeam } from '../../../../shared/lib/useSelectedTeam';
import { Loading } from '../../../../shared/loadings/Loading';
import { QuestionCard } from '../../../../shared/surveys/components/QuestionCard';
import { ResponseRateTrend } from '../../../../shared/surveys/components/ResponseRateTrend';
import { SurveyInformationCard } from '../../../../shared/surveys/components/SurveyInformationCard';
import { WGroupIconChart } from '../../feedback/widgets/WGroupIconChart';
import { useResponseRateChart } from './components/useResponseRateChart';
import './SurveyOverview.scss';

export function SurveyOverview() {
    const { id, teamId } = useParams();

    const [survey, setSurvey] = useState(null);

    const [loading, setLoading] = useState(true);

    const throwError = useAsyncError();

    const { team, setTeam } = useSelectedTeam();

    const { teamLeadTeams } = useCache();

    const [teamOptions, setTeamOptions] = useState(null);

    const { mediumDown } = useMediaQueries();

    const [selectedIteration, setSelectedIteration] = useState(null);

    const { chartData, completionRate } = useResponseRateChart(
        survey,
        selectedIteration,
        team
    );

    useEffect(() => {
        async function fetchData() {
            if (id) {
                try {
                    var s = await getSurvey(id);

                    setSurvey(s);

                    setSelectedIteration(s?.lastIteration?.iteration ?? 0);

                    setLoading(false);
                } catch (e) {
                    throwError(e);
                }
            } else {
                throwError(new Error('Survey Id is null'));
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (teamId) {
            setTeam(teamId);
        }
    }, [teamId]);

    useEffect(() => {
        if (survey && teamLeadTeams?.length) {
            let teamInsights =
                survey?.surveyType === 1
                    ? survey?.lastIteration?.teamInsights
                    : survey?.teamInsights;

            var teams = teamLeadTeams.filter((x) =>
                teamInsights?.some((y) => y.team.id === x?.value)
            );

            setTeamOptions(teams);
        }
    }, [survey, teamLeadTeams]);

    return loading ? (
        <Loading />
    ) : (
        <Fragment>
            <Page className='survey-overview'>
                {team?.id && <TeamSelector options={teamOptions} />}
                <MainContent>
                    <SurveyInformationCard
                        survey={survey}
                        iteration={selectedIteration}
                    />
                </MainContent>

                {survey?.surveyType === constants.SurveyType.Recurring &&
                    survey?.iterations?.length > 1 && (
                        <MainContent title={`Response rate trend`} line={true}>
                            <ResponseRateTrend
                                iterations={survey?.iterations}
                                teamId={team?.id}
                                onChange={(it) => setSelectedIteration(it)}
                                iteration={selectedIteration}
                            />
                        </MainContent>
                    )}

                {survey?.questions?.map((question) => (
                    <MainContent clickable key={`question-bar-${question?.id}`}>
                        <QuestionCard
                            survey={survey}
                            question={question}
                            teamId={team?.id}
                            iteration={selectedIteration}
                        />
                    </MainContent>
                ))}
            </Page>

            {!mediumDown && (
                <Aside className='survey-overview'>
                    <MainContent
                        line={true}
                        title={`Response rate${
                            survey?.surveyType ===
                            constants.SurveyType.Recurring
                                ? ` #${selectedIteration + 1}`
                                : ''
                        }`}
                        className='aside-content'
                    >
                        <WGroupIconChart
                            label={'Completion rate'}
                            type={'pulse'}
                            value={completionRate}
                        />

                        <HorizontalBarChart data={chartData} />
                    </MainContent>
                </Aside>
            )}
        </Fragment>
    );
}
