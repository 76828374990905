import { PulseSelect } from '../inputs/PulseSelect';
import { useCache } from '../lib/useCache';
import { PropTypes } from 'prop-types';
import { useSelectedTeam } from '../lib/useSelectedTeam';

export function TeamSelector({ className }) {
    const { teamLeadTeams } = useCache();
    const { team, setTeam } = useSelectedTeam();

    return (
        <>
            <PulseSelect
                aria-labelledby='Team Dropdown'
                className={`highlighted team-selector ${className ?? ''}`}
                label='Team'
                value={{ value: team?.id, label: team?.title }}
                options={teamLeadTeams}
                isLoading={!teamLeadTeams?.length}
                onChange={(team) => setTeam(team?.value)}
                isSearchable={false}
            />
        </>
    );
}

TeamSelector.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
};
