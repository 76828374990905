/* eslint-disable react/prop-types */
import { Fragment, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { updatePprTopic } from '../../../../services/pprTopicService';
import { Table } from '../../../shared/components/Table';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { GenericModal } from '../../../shared/modal/GenericModal';
import './Assess.scss';

export function PPRTopic() {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);

    const pprTopic = useRef([]);
    const parentTopic = useRef({ title: '', children: [] });

    useEffect(() => {
        fetch('/api/PPRTopic/getParentChildren/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
        })
            .then((res) => res.json())
            .then((data) => {
                pprTopic.current = data[0];
                parentTopic.current = data;
                setLoading(false);
            });
    }, [id]);

    function handleParentTitle(value) {
        parentTopic.current[0].title = value;
    }

    function handleParentDescription(value) {
        parentTopic.current[0].description = value;
    }

    function handleTitle(value, id) {
        pprTopic.current.children.find((x) => x.id === id).title = value;
    }

    function handleDescription(value, id) {
        pprTopic.current.children.find((x) => x.id === id).description = value;
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (validate()) {
            try {
                await updatePprTopic(pprTopic.current);
                setModal(true);
                setSuccess(true);
                setMessage(
                    'Assessment category description saved successfully'
                );
                setLoading(false);
            } catch (e) {
                setModal(true);
                setSuccess(false);
                setMessage(
                    "Can't save the Assessment category, please try it later"
                );
                setLoading(false);
            }
        }
    }

    function validate() {
        return (
            !pprTopic.current.children.filter((x) => !x.title || !x.description)
                .length > 0 &&
            pprTopic.current.title &&
            pprTopic.current.description
        );
    }

    return (
        <Fragment>
            <Page title={pprTopic.current.title} loading={loading}>
                <MainContent>
                    <div className='pprtopic'>
                        <div className='row pprtopic-table'>
                            <Table
                                className='ppr-topic'
                                data={parentTopic.current}
                                loading={loading}
                                columns={[
                                    {
                                        Header: 'Main Topic Name',
                                        accessor: 'title',
                                        width: 200,

                                        Cell: (row) =>
                                            EditableCell(
                                                row.value,
                                                handleParentTitle,
                                                row.id,
                                                true
                                            ),
                                    },
                                    {
                                        Header: 'Description',
                                        accessor: 'description',
                                        width: 400,
                                        Cell: (row) =>
                                            EditableCell(
                                                row.value,
                                                handleParentDescription
                                            ),
                                    },
                                ]}
                            />
                            <Table
                                className='ppr-topic'
                                data={pprTopic.current.children}
                                loading={loading}
                                columns={[
                                    {
                                        Header: 'Name',
                                        accessor: (d) => d,
                                        id: 'name',
                                        width: 200,
                                        Cell: (row) =>
                                            EditableCell(
                                                row.value.title,
                                                handleTitle,
                                                row.value.id
                                            ),
                                    },
                                    {
                                        Header: 'Description',
                                        accessor: (d) => d,
                                        id: 'Description',
                                        width: 400,
                                        Cell: (row) =>
                                            EditableCell(
                                                row.value.description,
                                                handleDescription,
                                                row.value.id
                                            ),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div>
                        <button
                            className='btn highlighted'
                            style={{ marginTop: '2rem' }}
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                    </div>
                </MainContent>
            </Page>

            <GenericModal
                open={modal}
                onClose={() => setModal(false)}
                success={success}
                message={message}
                route={`/admin/performance`}
            />
        </Fragment>
    );
}

const EditableCell = (row, updateMyData, id, autoFocus) => {
    const [value, setValue] = useState(row);
    const onChange = (e) => {
        setValue(e.target.value);
    };
    const onBlur = () => {
        updateMyData(value, id);
    };

    return (
        <input
            onBlur={onBlur}
            type='text'
            className={!value ? 'invalid' : 'valid'}
            placeholder=''
            onChange={onChange}
            value={value}
            required
            autoFocus={autoFocus}
        />
    );
};
