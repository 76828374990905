import { useEffect, useState } from 'react';
import { getSurveyQuestionDistribution } from '../../../../services/surveyService';

export function useDistributionData(surveyId, questionId, teamId, iteration) {
    const [distributionData, setDistributionData] = useState(null);

    useEffect(() => {
        async function fetch() {
            let distribution = await getSurveyQuestionDistribution(
                surveyId,
                questionId,
                teamId,
                iteration
            );

            setDistributionData(distribution);
        }
        if (surveyId && questionId && typeof iteration === 'number') {
            fetch();
        }
    }, [surveyId, questionId, teamId, iteration]);

    return distributionData;
}
