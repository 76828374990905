import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal } from '../components/Modal';
import { useSession } from '../lib/useSession';
import { PropTypes } from 'prop-types';
import { usePDF } from '../lib/usePDF';
import './ExportPdfModal.scss';

export function ExportPdfModal({
    open,
    questions,
    survey,
    iteration,
    teamId,
    onClose,
}) {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm();

    const [disableAnonymous, setDisableAnonymous] = useState(true);

    const session = useSession();

    const { downloadSurveyPDF } = usePDF();

    const onSubmit = async (data) => {
        onClose(true, data);
        await downloadSurveyPDF(
            survey.title,
            survey.id,
            iteration,
            data.questions,
            data.includeResponses,
            data.isAnonymous,
            data.isAccessible,
            teamId
        );

        reset();
    };

    return (
        <Fragment>
            <Modal
                className='export-pdf-modal'
                open={open}
                onClose={() => {
                    onClose(false, null);
                    reset();
                }}
            >
                <div className='header'>
                    <h3>Export as PDF</h3>
                </div>
                <hr />
                <div className='body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h4>Question(s)</h4>
                        {questions?.length > 1 && (
                            <label className='pulse360-checkbox tick'>
                                <input
                                    type='checkbox'
                                    name='allQuestions'
                                    onClick={(e) =>
                                        setValue(
                                            'questions',
                                            e.target.checked
                                                ? questions.map((x) => x.id)
                                                : []
                                        )
                                    }
                                />
                                <span></span>
                                Select all
                            </label>
                        )}

                        {questions?.map((question) => (
                            <label
                                key={`label-${question.id}`}
                                className='pulse360-checkbox tick'
                            >
                                <input
                                    key={`input-${question.id}`}
                                    type='checkbox'
                                    value={question.id}
                                    {...register('questions', {
                                        validate: (data) =>
                                            data?.length > 0 ||
                                            'You need to select at least one question',
                                    })}
                                />
                                <span key={`span-${question.id}`}></span>
                                {question.questionText}
                            </label>
                        ))}

                        <p className='validation-error'>
                            {errors?.questions && errors?.questions?.message}
                        </p>
                        <h4> Responses </h4>
                        <label className='pulse360-checkbox tick'>
                            <input
                                type='checkbox'
                                {...register('includeResponses')}
                                onClick={(e) => {
                                    !e.target.checked
                                        ? setValue('isAnonymous', false)
                                        : null;
                                    setDisableAnonymous(!e.target.checked);
                                }}
                            />
                            <span></span>
                            Include responses
                        </label>
                        {!session?.isAnonymous && !survey?.isAnonymous && (
                            <label className='pulse360-checkbox tick with-margin-left'>
                                <input
                                    type='checkbox'
                                    disabled={disableAnonymous}
                                    {...register('isAnonymous')}
                                />
                                <span></span>
                                Anonymous
                            </label>
                        )}
                        <h4> Accessibility </h4>
                        <label className='pulse360-checkbox tick'>
                            <input
                                type='checkbox'
                                {...register('isAccessible')}
                            />
                            <span></span>
                            Accessible
                        </label>

                        <div className='button'>
                            <input
                                type='submit'
                                className='btn highlighted'
                                value='Export'
                            />
                        </div>
                    </form>
                </div>
            </Modal>
        </Fragment>
    );
}

ExportPdfModal.propTypes = {
    open: PropTypes.bool,
    questions: PropTypes.array,
    onClose: PropTypes.func,
    survey: PropTypes.object,
    iteration: PropTypes.number,
    teamId: PropTypes.string,
};
