import { Fragment, useEffect, useState } from 'react';
import { getClientNotifications } from '../../../services/clientService';
import { getNotificationSettingsType } from '../../../services/notificationService';
import { getTeam } from '../../../services/teamService';
import {
    getUserNotifications,
    updateNotification,
    updateUserTeamNotification,
} from '../../../services/userService';
import { Accordion } from '../../shared/components/Accordion';
import { Checkbox } from '../../shared/components/Checkbox';
import { Switch } from '../../shared/components/Switch';
import { MainContent } from '../../shared/layout/MainContent';
import { Page } from '../../shared/layout/Page';
import { useAsyncError } from '../../shared/lib/helper';
import { parseNotifications } from '../admin/notifications/notificationsUtils';
import { PropTypes } from 'prop-types';
import './UserNotifications.scss';

export function UserNotifications() {
    const [notifications, setNotifications] = useState(null);

    const [clientNotifications, setClientNotifications] = useState(null);

    const [loading, setLoading] = useState(true);

    const [active, setActive] = useState(null);

    const [teams, setTeams] = useState(null);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchNotifications() {
            try {
                let [set, cNotifications, uNotifications] = await Promise.all([
                    getNotificationSettingsType(),
                    getClientNotifications(),
                    getUserNotifications(),
                ]);

                let cn = cNotifications
                    .filter((x) =>
                        uNotifications.some(
                            (y) => y.setting === x.setting.setting
                        )
                    )
                    .map((x) => ({
                        setting: x.setting.setting,
                        overridable: x.overridable,
                    }))
                    .filter(
                        (obj, pos, arr) =>
                            arr
                                .map((mapObj) => mapObj.setting)
                                .indexOf(obj.setting) === pos
                    );

                setClientNotifications(cn);

                let parsedNotifications = parseNotifications(
                    uNotifications,
                    set
                );

                if (parsedNotifications?.teams?.length) {
                    let t = await Promise.all(
                        parsedNotifications.teams.map((x) => getTeam(x?.team))
                    );

                    setTeams(t);
                }

                setNotifications(parsedNotifications);

                setLoading(false);
            } catch (error) {
                throwError(error);
            }
        }

        fetchNotifications();
    }, []);

    async function updateSetting(setting) {
        try {
            await updateNotification(setting);
        } catch (error) {
            throwError(error);
        }
    }

    async function updateTeamSetting(setting) {
        try {
            await updateUserTeamNotification(setting);
        } catch (error) {
            throwError(error);
        }
    }

    function buildAccordion(category, update) {
        return (
            <Fragment>
                {!!category?.notify?.length && <label>Notify me when...</label>}
                {category?.notify
                    ?.filter(
                        (obj, pos, arr) =>
                            arr
                                .map((mapObj) => mapObj.setting)
                                .indexOf(obj.setting) === pos
                    )
                    ?.map((notification, i) => (
                        <NotificationSetting
                            key={`setting-${notification.setting}-${i} ${
                                category?.team ?? ''
                            }`}
                            teamId={category?.team}
                            disabled={
                                !!clientNotifications?.find(
                                    (x) =>
                                        x.setting === notification.setting &&
                                        !x.overridable
                                )
                            }
                            onChange={(s) => update(s)}
                            notifications={category?.notify.filter(
                                (x) => x.setting === notification.setting
                            )}
                        >
                            {notification.description}
                        </NotificationSetting>
                    ))}
                {!!category?.remind?.length && <label>Remind me when...</label>}
                {category?.remind
                    ?.filter(
                        (obj, pos, arr) =>
                            arr
                                .map((mapObj) => mapObj.setting)
                                .indexOf(obj.setting) === pos
                    )
                    ?.map((notification, i) => (
                        <NotificationSetting
                            key={`setting-${notification.setting}-${i} ${
                                category?.team ?? ''
                            }`}
                            disabled={
                                !!clientNotifications?.find(
                                    (x) =>
                                        x.setting === notification.setting &&
                                        !x.overridable
                                )
                            }
                            onChange={(s) => update(s)}
                            notifications={category?.remind.filter(
                                (x) => x.setting === notification.setting
                            )}
                        >
                            {notification.description}
                        </NotificationSetting>
                    ))}
            </Fragment>
        );
    }
    return (
        <Page
            className='user-notifications'
            loading={loading}
            title={'Notifications settings'}
        >
            <div className='description'>
                Pulse 360 allows you to configure the notifications you receive
                aligned with core features or interactions within the app.
                <br />
                <br />
                Please note - some notification settings are automatically
                turned on, and controlled, by your organisation.
            </div>
            <MainContent testId='user-notifications'>
                {(!!notifications?.feedback?.notify?.length ||
                    !!notifications?.feedback?.remind?.length) && (
                    <Accordion
                        onClick={() => setActive(0)}
                        expand={active === 0}
                        title={
                            <Fragment>
                                <i className='icon icon-feedback-give'></i>
                                Feedback
                            </Fragment>
                        }
                    >
                        {buildAccordion(notifications?.feedback, updateSetting)}
                    </Accordion>
                )}
                {(!!notifications?.pulse?.notify?.length ||
                    !!notifications?.pulse?.remind?.length) && (
                    <Accordion
                        onClick={() => setActive(1)}
                        expand={active === 1}
                        title={
                            <Fragment>
                                <i className='icon icon-pulse'></i>
                                Pulse Surveys
                            </Fragment>
                        }
                    >
                        {buildAccordion(notifications?.pulse, updateSetting)}
                    </Accordion>
                )}
                {(!!notifications?.assess?.notify?.length ||
                    !!notifications?.assess?.remind?.length) && (
                    <Accordion
                        expand={active === 2}
                        onClick={() => setActive(2)}
                        title={
                            <Fragment>
                                <i className='icon icon-ppr'></i>
                                Assess
                            </Fragment>
                        }
                    >
                        {buildAccordion(notifications?.assess, updateSetting)}
                    </Accordion>
                )}
                {notifications?.teams?.map(
                    (team, i) =>
                        (!!team?.notify?.length || !!team?.remind?.length) && (
                            <Accordion
                                key={`accordion-${team.team}`}
                                expand={active === i + 4}
                                onClick={() => setActive(i + 4)}
                                title={
                                    <Fragment>
                                        <i className='icon icon-my-team'></i>
                                        {
                                            teams?.find(
                                                (x) => x.id === team.team
                                            )?.title
                                        }
                                    </Fragment>
                                }
                            >
                                {buildAccordion(team, updateTeamSetting)}
                            </Accordion>
                        )
                )}
                {(!!notifications?.company?.notify?.length ||
                    !!notifications?.company?.remind?.length) && (
                    <Accordion
                        expand={active === 3}
                        onClick={() => setActive(3)}
                        title={
                            <Fragment>
                                <i className='icon icon-company'></i>
                                Company
                            </Fragment>
                        }
                    >
                        {buildAccordion(notifications?.company, updateSetting)}
                    </Accordion>
                )}
            </MainContent>
        </Page>
    );
}

export function NotificationSetting({
    children,
    onChange,
    notifications,
    disabled,
    teamId,
}) {
    return (
        <div className='setting'>
            <p className='description'>{children}</p>
            {notifications
                ?.filter((x) => x.channel !== 2)
                ?.map((y) => (
                    <NotificationSwitch
                        key={`switch-${y.setting}-${y.channel}-${teamId ?? ''}`}
                        onChange={(set, checked) =>
                            onChange({ ...set, enabled: checked })
                        }
                        channel={y.channel}
                        enabled={y.enabled}
                        disabled={disabled}
                        notification={y}
                    />
                ))}
        </div>
    );
}

NotificationSetting.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    notifications: PropTypes.array,
    disabled: PropTypes.bool,
    teamId: PropTypes.string,
};

export function NotificationSwitch({
    onChange,
    channel,
    notification,
    enabled,
    disabled,
    overridable,
    onOverride,
    children,
}) {
    const [switchChecked, setSwitchChecked] = useState(enabled);

    const [overrideChecked, setOverrideChecked] = useState(overridable);

    let channelString,
        channelIcon = '';

    switch (channel) {
        case 0:
            channelString = 'Email';
            channelIcon = 'icon icon-email';
            break;
        case 1:
            channelString = 'Mobile app push';
            channelIcon = 'icon icon-push-notification';
            break;
        case 2:
            channelString = 'Teams';
            channelIcon = 'icon icon-teams';
            break;
        default:
            break;
    }

    return (
        <div className='switch'>
            {children && <div className='text'>{children}</div>}
            {typeof channel === 'number' && (
                <div className='icon-title'>
                    <i className={channelIcon}></i>
                    <p>{channelString}</p>
                </div>
            )}

            <Switch
                checked={switchChecked}
                disabled={disabled}
                className={`notifications-switch ${disabled ? 'override' : ''}`}
                onChange={(checked) => {
                    onChange(
                        { ...notification, overridable: overrideChecked },
                        checked
                    );
                    setSwitchChecked(checked);
                }}
            >
                <div className='text'>
                    {!!disabled && <i className='icon icon-lock'></i>}
                    {switchChecked ? 'On' : 'Off'}
                </div>
            </Switch>

            {typeof overridable === 'boolean' && (
                <Checkbox
                    checked={overrideChecked}
                    onChange={(checked) => {
                        onOverride(
                            { ...notification, enabled: switchChecked },
                            checked
                        );
                        setOverrideChecked(checked);
                    }}
                />
            )}
        </div>
    );
}

NotificationSwitch.propTypes = {
    onChange: PropTypes.func,
    channel: PropTypes.number,
    notification: PropTypes.object,
    enabled: PropTypes.bool,
    disabled: PropTypes.bool,
    overridable: PropTypes.bool,
    onOverride: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
