export function ErrorAnimation() {
    const stringSvg = `<svg >
        <defs>
            <pattern id="GPattern" x="0" y="0" width="20" height="20"
                patternUnits="userSpaceOnUse"
                patternTransform="rotate(35)">
                <animateTransform attributeType="xml"
                    attributeName="patternTransform"
                    type="rotate"
                    from="35"
                    to="395"
                    begin="0"
                    dur="160s" repeatCount="indefinite" />
                <circle cx="10" cy="10" r="10" stroke="none" fill="yellow">
                    <animate attributeName="r"
                        type="xml"
                        from="1" to="1"
                        values="1; 10; 1"
                        begin="0s" dur="2s"
                        repeatCount="indefinite" />
                </circle>
            </pattern>
        </defs>


        <symbol id="e-text">
            <text text-anchor="middle"
                x="30" y="50%" dy=".35em">
                E
                    </text>
        </symbol>
        <symbol id="r-text">
            <text text-anchor="middle"
                x="90" y="50%" dy=".35em">
                R
                    </text>
        </symbol>

        <symbol id="r2-text">
            <text text-anchor="middle"
                x="150" y="50%" dy=".35em">
                R
                    </text>
        </symbol>
        <symbol id="o-text">
            <text text-anchor="middle"
                x="210" y="50%" dy=".35em">
                O
                    </text>
        </symbol>
        <symbol id="r3-text">
            <text text-anchor="middle"
                x="270" y="50%" dy=".35em">
                R
                    </text>
        </symbol>

        <use xlink: href="#e-text" class="text"></use>
        <use xlink: href="#e-text" class="text"></use>
        <use xlink: href="#e-text" class="text"></use>
        <use xlink: href="#e-text" class="text"></use>
        <use xlink: href="#e-text" class="text"></use>

        <use xlink: href="#r-text" class="text"></use>
        <use xlink: href="#r-text" class="text"></use>
        <use xlink: href="#r-text" class="text"></use>
        <use xlink: href="#r-text" class="text"></use>
        <use xlink: href="#r-text" class="text"></use>

        <use xlink: href="#r2-text" class="text"></use>
        <use xlink: href="#r2-text" class="text"></use>
        <use xlink: href="#r2-text" class="text"></use>
        <use xlink: href="#r2-text" class="text"></use>
        <use xlink: href="#r2-text" class="text"></use>

        <use xlink: href="#o-text" class="text"></use>
        <use xlink: href="#o-text" class="text"></use>
        <use xlink: href="#o-text" class="text"></use>
        <use xlink: href="#o-text" class="text"></use>
        <use xlink: href="#o-text" class="text"></use>

        <use xlink: href="#r3-text" class="text"></use>
        <use xlink: href="#r3-text" class="text"></use>
        <use xlink: href="#r3-text" class="text"></use>
        <use xlink: href="#r3-text" class="text"></use>
        <use xlink: href="#r3-text" class="text"></use>


            </svg >`;

    return (
        <div
            className='error-animation'
            dangerouslySetInnerHTML={{ __html: stringSvg }}
        ></div>
    );
}
