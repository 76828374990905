import { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import {
    getQuestionTypes,
    createQuestion,
    getQuestion,
    updateQuestion,
} from '../../../../services/questionService';
import { getTopics } from '../../../../services/topicService';
import { Checkbox } from '../../../shared/components/Checkbox';
import { SubmitButton } from '../../../shared/components/SubmitButton';
import { PulseSelect } from '../../../shared/inputs/PulseSelect';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper';
import { IconButton } from '../../../shared/inputs/IconButton';
import { PropTypes } from 'prop-types';
import constants from '../../../shared/constants';
import './Question.scss';

export function Question({
    questionModal,
    setSelectedQuestions,
    setQuestions,
    setDisplay,
    setShowQuestionForm,
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setValue,
    } = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'choices',
    });

    const [topics, setTopics] = useState(undefined);

    const [maxOptions, setMaxOptions] = useState(false);

    const [question, setQuestion] = useState(null);

    const [redirect, setRedirect] = useState(null);

    const [answerTypes, setAnswerTypes] = useState(undefined);

    const { state } = useLocation();

    const [loading, setLoading] = useState(true);

    const [errorMessage, setErrorMessage] = useState('');

    const [form, setForm] = useState(null);

    const throwError = useAsyncError();

    const categories = { feedback: 1, pulse: 2 };

    const watchQuestionType = watch('questionType', undefined);

    const watchChoices = watch('choices', []);

    useEffect(() => {
        async function fetchQuestion() {
            let q = await getQuestion(state?.id);
            setQuestion(q);
            setValue('questionText', q.questionText);
            setValue('topicId', { label: q.topic.title, value: q.topic.id });
            setValue('questionType', {
                label: answerTypes.find((x) => x.value === q.questionType).key,
                value: q.questionType,
            });
            setValue('categories', q.categories);
            setValue('choices', q.choices);
        }
        if (state?.id && answerTypes) {
            fetchQuestion();
        }
    }, [state, answerTypes]);

    useEffect(() => {
        async function fetch() {
            try {
                setLoading(true);

                let [aT, t] = await Promise.all([
                    await getQuestionTypes(),
                    await getTopics(),
                ]);

                setAnswerTypes(aT);
                setTopics(t);

                setLoading(false);
            } catch (error) {
                throwError(error);
            }
        }

        fetch();
    }, []);

    const transform = (callBack) => (data) => {
        callBack({
            ...data,
            topicId: data?.topicId?.value,
            questionType: data?.questionType?.value,
            choices: data?.choices?.map((c) => c.value),
        });
    };

    async function onSubmit(data) {
        if (questionModal) {
            data.categories = 2;
        }
        if (!data.categories) {
            setErrorMessage('One of both options must be selected');
        } else if (
            data?.questionType === constants.QuestionType.Choice &&
            data?.choices?.length < 2
        ) {
            setErrorMessage('2 or more options must be entered');
        } else {
            try {
                setForm({ loading: true, disabled: true });

                let q = question
                    ? await updateQuestion({ ...data, id: state?.id })
                    : await createQuestion({ ...data });

                setForm({ ...form, loading: false, success: true });

                setErrorMessage('');

                if (questionModal) {
                    setDisplay((display) => [
                        { ...q, newest: true },
                        ...display.filter((x) => !x.new),
                    ]);
                    setQuestions((questions) => [q, ...questions]);
                    setSelectedQuestions((prevState) =>
                        prevState.length < 5 ? [...prevState, q] : prevState
                    );
                }
            } catch (error) {
                setForm({
                    ...form,
                    loading: false,
                    success: false,
                    disabled: false,
                });
            }
        }
    }

    useEffect(() => {
        if (form?.success && !questionModal) {
            setTimeout(() => {
                setForm({ success: false, disabled: false });
                setRedirect('/admin/bank-of-questions');
            }, 1000);
        } else if (form?.success) {
            setTimeout(() => {
                setForm({ success: false, disabled: false });
                setShowQuestionForm((prevState) => !prevState);
            }, 1000);
        }
    }, [form]);

    return (
        <Page className='question-form' loading={loading} testId='question'>
            {redirect && <Navigate to={redirect} />}
            <MainContent style={{ maxWidth: '80rem' }}>
                <form onSubmit={handleSubmit(transform(onSubmit))}>
                    <div className='group-form'>
                        <label htmlFor='questionText'>Question</label>
                        <input
                            id='questionText'
                            type='text'
                            aria-required={true}
                            {...register('questionText', {
                                required: 'Question text is required',
                            })}
                            autoFocus
                        />

                        {errors?.questionText?.message && (
                            <div className='error'>
                                {errors?.questionText?.message}
                            </div>
                        )}
                    </div>

                    <div className='group-form'>
                        <label>Answer Type(s)</label>
                        <Controller
                            name='questionType'
                            control={control}
                            rules={{ required: 'Question type is required' }}
                            render={({ field }) => (
                                <PulseSelect
                                    {...field}
                                    className={'react-select'}
                                    options={answerTypes?.map((x) => ({
                                        value: x.value,
                                        label: x.key,
                                    }))}
                                    onChange={(opt) => {
                                        if (
                                            opt.value ===
                                                constants.QuestionType.Likert ||
                                            opt.value ===
                                                constants.QuestionType.Choice
                                        ) {
                                            setValue(
                                                'categories',
                                                categories.pulse
                                            );
                                        }

                                        field.onChange(opt);
                                    }}
                                />
                            )}
                        />

                        {errors?.questionType?.message && (
                            <div className='error'>
                                {errors?.questionType?.message}
                            </div>
                        )}
                    </div>

                    {watchQuestionType?.value ===
                        constants.QuestionType.Choice && (
                        <>
                            <ul className='option-list'>
                                {fields.map((option, i) => (
                                    <li key={option.id}>
                                        <div className='option'>
                                            <input
                                                key={option.id}
                                                type='text'
                                                placeholder={`Option ${i + 1}`}
                                                {...register(
                                                    `choices.${i}.value`,
                                                    {
                                                        required:
                                                            'Question text is required',
                                                        maxLength: {
                                                            value: 60,
                                                            message:
                                                                'Maximum length exceeded',
                                                        },
                                                    }
                                                )}
                                                maxLength={60}
                                                required
                                                onFocus={(event) =>
                                                    event.target.select()
                                                }
                                            />
                                            <span className={`char-count`}>{`${
                                                watchChoices[i]?.value
                                                    ?.length ??
                                                watchChoices[i]?.length
                                            } / 60`}</span>
                                            {fields.length > 2 && (
                                                <IconButton
                                                    type='delete'
                                                    onClick={() => {
                                                        remove(i);
                                                        setMaxOptions(false);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            <IconButton
                                type='add'
                                onClick={() =>
                                    fields.length < 10
                                        ? append({
                                              value: `Option ${
                                                  fields.length + 1
                                              }`,
                                          })
                                        : setMaxOptions(true)
                                }
                                title='Add option'
                                style={{ fontSize: '1.5rem' }}
                            >
                                <label
                                    style={{
                                        fontSize: 'medium',
                                        fontWeight: 700,
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                    }}
                                >
                                    Add option
                                </label>
                            </IconButton>

                            {maxOptions && (
                                <div className='error'>
                                    The maximum number of options is 10
                                </div>
                            )}
                        </>
                    )}

                    <div className='group-form'>
                        <label>Topic</label>
                        <Controller
                            name='topicId'
                            control={control}
                            rules={{ required: 'Topic is required' }}
                            render={({ field }) => (
                                <PulseSelect
                                    {...field}
                                    className={'react-select'}
                                    options={topics?.map((x) => ({
                                        value: x.id,
                                        label: x.title,
                                    }))}
                                />
                            )}
                        />

                        {errors?.topicId?.message && (
                            <div className='error'>
                                {errors?.topicId?.message}
                            </div>
                        )}
                    </div>
                    {!questionModal && (
                        <div className='checkbox'>
                            <Controller
                                name='categories'
                                control={control}
                                render={({ field }) => (
                                    <Fragment>
                                        <div className='group-form'>
                                            <label>Pulse Surveys</label>
                                            <Checkbox
                                                {...field}
                                                checked={
                                                    field.value ===
                                                        categories.pulse ||
                                                    field.value === 3
                                                }
                                                onChange={(checked) =>
                                                    field.onChange(
                                                        typeof field.value ===
                                                            'number'
                                                            ? checked
                                                                ? field.value |
                                                                  categories.pulse
                                                                : field.value -
                                                                  categories.pulse
                                                            : checked
                                                            ? categories.pulse
                                                            : 0
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className='group-form'>
                                            <label>Feedback</label>

                                            <Checkbox
                                                {...field}
                                                checked={
                                                    (watchQuestionType?.value !==
                                                        constants.QuestionType
                                                            .Likert ||
                                                        watchQuestionType?.value !==
                                                            constants
                                                                .QuestionType
                                                                .Choice) &&
                                                    (field.value ===
                                                        categories.feedback ||
                                                        field.value === 3)
                                                }
                                                disabled={
                                                    watchQuestionType?.value ===
                                                        constants.QuestionType
                                                            .Likert ||
                                                    watchQuestionType?.value ===
                                                        constants.QuestionType
                                                            .Choice
                                                }
                                                onChange={(checked) =>
                                                    field.onChange(
                                                        typeof field.value ===
                                                            'number'
                                                            ? checked
                                                                ? field.value |
                                                                  categories.feedback
                                                                : field.value -
                                                                  categories.feedback
                                                            : checked
                                                            ? categories.feedback
                                                            : 0
                                                    )
                                                }
                                            />
                                        </div>
                                    </Fragment>
                                )}
                            />
                        </div>
                    )}

                    {errorMessage && (
                        <div className='error'>{errorMessage}</div>
                    )}

                    <SubmitButton
                        loading={form?.loading}
                        success={form?.success}
                        disabled={form?.disabled}
                    >
                        Save
                    </SubmitButton>
                </form>
            </MainContent>
        </Page>
    );
}

Question.propTypes = {
    questionModal: PropTypes.bool,
    setDisplay: PropTypes.func,
    setQuestions: PropTypes.func,
    setSelectedQuestions: PropTypes.func,
    setShowQuestionForm: PropTypes.func,
};
