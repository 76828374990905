import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Modal } from '../../../../../../shared/components/Modal';
import { ProgressBar } from '../../../../../../shared/components/ProgressBar';
import { humanizeDiffToday } from '../../../../../../shared/lib/dateTimeHelper';
import { useAsyncError } from '../../../../../../shared/lib/helper';
import { Comments } from './Comments';
import { UserPhoto } from './UserPhoto';
import { PropTypes } from 'prop-types';
import './HubItemModal.scss';
import {
    commentAction,
    getPriorityTypes,
} from '../../../../../../../services/actionService';

export function ActionModal({
    onComment,
    onClose,
    item,
    sharedAction,
    userId,
    open,
}) {
    const [modal, setModal] = useState(false);

    const [reactions, setReactions] = useState(undefined);

    const [priorities, setPriorities] = useState(undefined);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchData() {
            try {
                let pr = await getPriorityTypes();

                setPriorities(pr);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (open) {
            setModal(true);
        }
    }, [open]);

    useEffect(() => {
        if (!modal) {
            onClose();
        }
    }, [modal]);

    useEffect(() => {
        if (sharedAction && sharedAction.question.reactions) {
            setReactions(sharedAction.question.reactions.reverse());
        }
    }, [sharedAction]);

    async function handleComment(comment) {
        try {
            let request = {
                sharedWith: sharedAction.destinationUser.id,
                itemId: sharedAction.actionId,
                comment: comment,
            };

            await commentAction(request);
            await onComment();
        } catch (e) {
            throwError(e);
        }
    }

    const userSent = userId === item.feedbackReceiver.id;

    return !sharedAction ? (
        <Fragment> </Fragment>
    ) : (
        <Modal
            className='hub-item-modal'
            open={modal}
            onClose={() => setModal(false)}
        >
            <i
                className='icon icon-close-solid'
                onClick={() => setModal(false)}
            >
                {' '}
            </i>
            <div className='header'>
                <UserPhoto
                    giver={item.feedbackReceiver}
                    receiver={item.feedbackGiver}
                />
                <div className='label-section'>
                    <div className='group-row'>
                        <div>
                            <span>{userSent ? 'To' : 'From'}:</span>
                            <span className='bold'>
                                {userSent
                                    ? item.feedbackGiver?.name
                                    : item.feedbackReceiver?.name}
                            </span>
                        </div>
                        <div>
                            {`Action ${userSent ? 'shared' : 'received'}`}{' '}
                        </div>
                    </div>
                    <div className='group-row'>
                        <div>
                            <span>Action topic:</span>
                            <span className='bubble'>{item.topic}</span>
                        </div>
                        <span>{humanizeDiffToday(item.created)}</span>
                    </div>
                </div>
            </div>

            <hr />

            <div className='shared-ppr-section'>
                <h3> {sharedAction.question.title}</h3>
                <p className='margin-bottom-1'>
                    {`"${sharedAction.question.description}"`}
                </p>

                <p className='bold margin-bottom-1'>Score </p>
                <ProgressBar
                    value={sharedAction.question.value}
                    target={sharedAction.question.targetValue}
                />
            </div>

            <p className='bold  margin-bottom-1'>Action </p>

            <div className='action-form'>
                <p style={{ marginBottom: '2.5rem' }}>
                    {sharedAction.question.action.description}
                </p>

                <div className='action-boxes space-between'>
                    <div>
                        <p className='bold margin-bottom-1'>Status </p>

                        <div className='box'>
                            {!sharedAction.question.action.isCompleted
                                ? 'Open'
                                : 'Closed'}
                        </div>
                    </div>

                    <div>
                        <p className='bold margin-bottom-1'>Completion </p>

                        <div className='box'>
                            {sharedAction.question.action.completeDate
                                ? moment(
                                      sharedAction.question.action.completeDate
                                  ).format('DD/MM/YY')
                                : '-'}
                        </div>
                    </div>

                    <div>
                        <p className='bold margin-bottom-1'>Priority</p>

                        <div className='box'>
                            {!!priorities &&
                                priorities.find(
                                    (x) =>
                                        x.value ===
                                        sharedAction.question.action.priority
                                ).key}
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            {((!!sharedAction.question.reactions &&
                !!sharedAction.question.reactions.length) ||
                !userSent) && (
                <Comments
                    reactions={reactions}
                    user={sharedAction.destinationUser}
                    onComment={handleComment}
                />
            )}
        </Modal>
    );
}

ActionModal.propTypes = {
    onComment: PropTypes.func,
    onClose: PropTypes.func,
    item: PropTypes.object,
    sharedAction: PropTypes.object,
    userId: PropTypes.string,
    open: PropTypes.bool,
};
