import { Fragment } from 'react';
import { AuthControl } from '../../pages/authentication/AuthControl';
import { useSession } from '../../shared/lib/useSession';
import { SectionButton } from './Components';
import { PropTypes } from 'prop-types';

export function SideBarAdmin({ onBack }) {
    const session = useSession();

    return (
        <Fragment>
            <div role='menu'>
                <a
                    tabIndex={0}
                    aria-label='Click to go back'
                    className='title'
                    onClick={() => onBack()}
                >
                    <i
                        aria-label='Click to go back'
                        className='icon-long-arrow-left'
                    ></i>
                    <h5>Admin</h5>
                </a>

                <AuthControl
                    permissions='company::company_settings'
                    authorized={
                        <Fragment>
                            <SectionButton
                                icon={'icon-company'}
                                to={'/admin/organisation'}
                                title='Organisation'
                            >
                                Organisation
                            </SectionButton>
                            <SectionButton
                                icon={'icon-peer'}
                                to={'/admin/users'}
                                title='Users'
                            >
                                Users
                            </SectionButton>

                            <SectionButton
                                icon={'icon-my-team'}
                                to={'/admin/teams'}
                                title='Teams'
                            >
                                Teams
                            </SectionButton>
                        </Fragment>
                    }
                />
                <AuthControl
                    permissions='company::company_settings;company::pulse_settings'
                    authorized={
                        <SectionButton
                            icon={'icon-topic'}
                            to={'/admin/topics'}
                            title='Topics'
                        >
                            Topics
                        </SectionButton>
                    }
                />

                <AuthControl
                    permissions='company::pulse_settings'
                    authorized={
                        <Fragment>
                            <SectionButton
                                icon={'icon-comments'}
                                to={'/admin/bank-of-questions'}
                                title='Questions'
                            >
                                Questions
                            </SectionButton>
                            <SectionButton
                                icon={'icon-pulse'}
                                to={'/admin/surveys-settings'}
                                title='Surveys'
                                exact='true'
                            >
                                Surveys
                            </SectionButton>
                        </Fragment>
                    }
                />

                <AuthControl
                    permissions='company::company_settings'
                    authorized={
                        <Fragment>
                            <SectionButton
                                icon={'icon-notification'}
                                to={'/admin/notifications'}
                                title='Notifications'
                            >
                                Notifications
                            </SectionButton>
                            {session?.isPerformance && (
                                <SectionButton
                                    icon='icon-ppr'
                                    to={'/admin/performance'}
                                    title='Assess'
                                >
                                    Assess
                                </SectionButton>
                            )}
                        </Fragment>
                    }
                />
            </div>
        </Fragment>
    );
}

SideBarAdmin.propTypes = {
    onBack: PropTypes.func,
};
