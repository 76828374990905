import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import './inputs.scss';

export function SearchInput({ placeholder, onSearch, className, autoFocus }) {
    const [text, setText] = useState('');

    const [done, setDone] = useState(0);

    useEffect(() => {
        if (done) {
            clearTimeout(done);
        }

        setDone(setTimeout(() => onSearch(text), 500));
    }, [text]);

    return (
        <div className={`search-input ${className ? className : ''}`}>
            <i
                className='icon icon-search'
                onClick={(e) => setText(e.target.value)}
            ></i>
            <input
                type='text'
                placeholder={placeholder}
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && setText(e.target.value)}
                autoFocus={autoFocus}
            />
        </div>
    );
}

SearchInput.propTypes = {
    placeholder: PropTypes.string,
    onSearch: PropTypes.func,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
};
