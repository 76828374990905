import { Fragment, useEffect, useState } from 'react';
import {
    getWidgetSentimentScore,
    getWidgetSentimentScoreForTeam,
    getWidgetSentimentScoreByEntityForTeam,
    getWidgetSentimentScoreByEntity,
} from '../../../services/sentimentScoreService';
import { SentimentChart } from '../charts/RadialChartIcon';
import { SentimentGaugeChart } from './components/SentimentGaugeChart';
import { WidgetHeader } from './components/WidgetHeader';
import { WidgetLayout } from './components/WidgetLayout';
import { WidgetTable } from './components/WidgetTable';
import { PropTypes } from 'prop-types';
import './widgets.scss';

export function SentimentScoreWidget({ from, to, teamId }) {
    const [ascendentData, setAscendentData] = useState([]);

    const [descendentData, setDescendentData] = useState([]);

    const [scores, setScores] = useState({});

    const [loading, setLoading] = useState(true);

    const limit = 3;

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            let promises = teamId
                ? [
                      getWidgetSentimentScoreForTeam(teamId, from, to),
                      getWidgetSentimentScoreByEntityForTeam(
                          teamId,
                          from,
                          to,
                          limit,
                          true
                      ),
                      getWidgetSentimentScoreByEntityForTeam(
                          teamId,
                          from,
                          to,
                          limit,
                          false
                      ),
                  ]
                : [
                      getWidgetSentimentScore(from, to),
                      getWidgetSentimentScoreByEntity(from, to, limit, true),
                      getWidgetSentimentScoreByEntity(from, to, limit, false),
                  ];

            let [s, lDesc, lAsc] = await Promise.all(promises);

            setScores(s);
            setDescendentData(fillArray(lDesc));
            setAscendentData(fillArray(lAsc));

            setLoading(false);
        }

        if (from && to) {
            fetchData();
        }
    }, [from, to, teamId]);

    function fillArray(array) {
        let result = [...array];
        while (result.length < limit) {
            result.push({
                entityName: '',
                averageScore: null,
            });
        }

        return result;
    }

    const columns = (desc) => [
        {
            Header: '',
            accessor: (d) => d,
            id: 'numeration',
            Cell: (row) => <p className='numeration'>{row.row.index + 1}.</p>,
        },
        {
            Header: `${desc ? 'Top' : 'Bottom'} ${teamId ? 'Users' : 'Teams'}`,
            accessor: 'entityName',
            Cell: (row) => (
                <p
                    style={{ maxWidth: 70 }}
                    className='text-ellipsis'
                    role='cell'
                    title={row.value}
                    aria-label={row.value}
                >
                    {row.value}{' '}
                </p>
            ),
        },
        {
            Header: 'Received Sentiment',
            accessor: 'averageScore',
            headerClassName: 'text-center',
            Cell: (row) => (
                <div
                    role='cell'
                    aria-label={row.value + '%'}
                    style={{ maxWidth: 100 }}
                >
                    <SentimentChart
                        className='radial-chart-in-table'
                        sentiment={row.value}
                        showText={true}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className='sentiment-score-widget' role='section'>
            <WidgetLayout
                information={
                    <Fragment>
                        Sentiment score uses Microsoft Azure Cognitive Services
                        to analyse <br />
                        the qualitative feedback of users and give it a score
                        between 1-100.
                    </Fragment>
                }
                loading={loading}
            >
                <WidgetHeader title={'Sentiment Score'} route={''} />
                <hr />
                <div className='graph-section'>
                    <SentimentGaugeChart
                        average={scores.averageScore}
                        lowest={scores.lowestScore}
                        highest={scores.highestScore}
                    />
                </div>
                <div className='tables-content'>
                    <WidgetTable
                        data={ascendentData}
                        columns={columns(false)}
                    />
                    <WidgetTable
                        data={descendentData}
                        columns={columns(true)}
                    />
                </div>
            </WidgetLayout>
        </div>
    );
}

SentimentScoreWidget.propTypes = {
    from: PropTypes.object,
    to: PropTypes.object,
    teamId: PropTypes.string,
};
