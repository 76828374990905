import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    getPriorityTypes,
    searchAction,
} from '../../../../../../services/actionService';
import { Table } from '../../../../../shared/components/Table';
import { TeamSelector } from '../../../../../shared/components/TeamSelector';
import { ActionFilter } from '../../../../../shared/filters/ActionFilter';
import { MainContent } from '../../../../../shared/layout/MainContent';
import { Page } from '../../../../../shared/layout/Page';
import { useAsyncError } from '../../../../../shared/lib/helper';
import { useSelectedTeam } from '../../../../../shared/lib/useSelectedTeam';
import './MyTeamActions.scss';

export function MyTeamActions() {
    const { state } = useLocation();

    const [loading, setLoading] = useState(true);

    const [tableLoading, setTableLoading] = useState(true);

    const [actionFilter, setActionFilter] = useState(null);

    const { team } = useSelectedTeam();

    const [tableData, setTableData] = useState({});

    const [activePill, setActivePill] = useState(1);

    const [priorities, setPriorities] = useState([]);

    const pageSize = 20;

    const throwError = useAsyncError();

    const width = [...document.getElementsByClassName('main-content-container')]
        ?.length
        ? `${
              [...document.getElementsByClassName('main-content-container')]
                  ?.map((x) => x.offsetWidth)
                  ?.reduce((a, b) => a + b) /
                  10 +
              1
          }rem`
        : '1000rem';

    useEffect(() => {
        async function fetchData() {
            try {
                let priorities = await getPriorityTypes();

                setPriorities(priorities);

                let teamActionFilter = {
                    From: moment(
                        state ? state.StartDate : moment().add(-1, 'M')
                    ),
                    To: moment(state ? state.EndDate : moment()),

                    Priority: [],
                    SubTopics: [],
                    Status: [0, 1],
                };

                setActionFilter(teamActionFilter);
                setLoading(false);
            } catch (e) {
                throwError(e);
            }
        }

        fetchData();
    }, []);

    async function loadData(filter, itemsPerPage, page) {
        setTableLoading(true);
        try {
            if (
                filter.Users &&
                filter.Users.length > 0 &&
                filter.SubTopics &&
                filter.SubTopics.length > 0 &&
                filter.Status.length > 0
            ) {
                let data = await searchAction(filter, itemsPerPage, page);

                setTableData(data);
            }
        } catch (e) {
            throwError(e);
        }

        setTableLoading(false);
    }

    useEffect(() => {
        if (actionFilter && !loading) {
            loadData(actionFilter, pageSize, 0);
        }
    }, [actionFilter]);

    function togglePills(pill) {
        let fil = { ...actionFilter };

        if (pill === 1) {
            fil.Status = [0];
        } else {
            fil.Status = [2];
        }

        setActionFilter(fil);
        setActivePill(pill);
    }

    return (
        <Page
            className='my-team-actions'
            header={
                <div
                    className='space-between title-margin'
                    style={{ maxWidth: width }}
                >
                    <h1>Actions</h1>
                    <TeamSelector />
                </div>
            }
            loading={loading}
            testId='team-actions'
        >
            <MainContent title='Filter' line={true}>
                <ActionFilter
                    filter={actionFilter}
                    team={team}
                    pprTopicId={state ? state.ParentTopicId : null}
                    onChange={(filter) => setActionFilter(filter)}
                />
            </MainContent>
            <MainContent title='Actions'>
                <div className='tabs'>
                    <div
                        className={activePill === 1 ? 'tab active' : 'tab'}
                        onClick={() => togglePills(1)}
                    >
                        <h4>Open</h4>
                    </div>
                    <div
                        className={activePill === 2 ? 'tab active' : 'tab'}
                        onClick={() => togglePills(2)}
                    >
                        <h4>Closed</h4>
                    </div>
                </div>
                <Table
                    className='actions-aside'
                    data={tableData.items ?? []}
                    loading={tableLoading}
                    columns={[
                        {
                            Header: 'Date set',
                            accessor: 'date',
                            width: 150,
                            Cell: (row) => (
                                <Fragment>
                                    {moment(row.value).format('DD/MM/YYYY')}
                                </Fragment>
                            ),
                        },

                        {
                            Header: 'Name',
                            accessor: 'user',
                            width: 200,
                        },
                        {
                            Header: 'Action',
                            accessor: 'action',
                        },
                        {
                            Header: 'Sub-category',
                            accessor: 'subCategory',
                            width: 200,
                        },
                        {
                            Header: 'Priority',
                            accessor: 'priority',
                            width: 150,
                            Cell: (row) => {
                                let p = priorities.find(
                                    (x) => x.value === row.value
                                );
                                return <Fragment>{p.key}</Fragment>;
                            },
                        },
                    ]}
                />
            </MainContent>
        </Page>
    );
}
