import { Fragment } from 'react';
import { useAsyncError } from '../../shared/lib/helper';
import { useAuthorization } from '../../shared/lib/useAuthorization';
import { PropTypes } from 'prop-types';
import constants from '../../shared/constants';

export function AuthResolver({ children }) {
    const error = useAuthorization(children.authPermission);

    const throwError = useAsyncError();

    if (error === 'OK') {
        return children.element;
    } else if (error === 'FORBIDDEN') {
        return (
            <Fragment>{throwError(constants.errorCodes.forbidden)}</Fragment>
        );
    } else {
        return <Fragment>{throwError()}</Fragment>;
    }
}

AuthResolver.propTypes = {
    children: PropTypes.object,
};
