import { Fragment, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
    getTeamOverview,
    getOrganisationalFeedback,
    getOrganisationalFeedbackInsights,
} from '../../../../services/organisationalFeedbackService';
import { getTeam } from '../../../../services/teamService';
import { TeamSelector } from '../../../shared/components/TeamSelector';
import { PulseSelect } from '../../../shared/inputs/PulseSelect';
import { SearchInput } from '../../../shared/inputs/SearchInput';
import { Page } from '../../../shared/layout/Page';
import { useClient } from '../../../shared/lib/useClient';
import { useSelectedTeam } from '../../../shared/lib/useSelectedTeam';
import { useSession } from '../../../shared/lib/useSession';
import { DateSelector } from '../../../shared/utils/DateSelector';
import { FeedbackBar } from './components/FeedbackBar';
import { OrganisationalInsightsWidget } from './components/OrganisationalInsightsWidget';
import { RankingWidget } from './components/RankingWidget';
import { SwotWidget } from './components/SwotWidget';
import { TeamOverview } from './components/TeamOverview';
import './OrganisationalFeedback.scss';

export function OrganisationalFeedback() {
    const { teamId } = useParams();

    const { state } = useLocation();

    const { team, setTeam } = useSelectedTeam();

    const session = useSession();

    const client = useClient();

    const [feedbacks, setFeedbacks] = useState(null);

    const [loading, setLoading] = useState(true);

    const [teamOverview, setTeamOverview] = useState(true);

    const [insights, setInsights] = useState(null);

    const [entitiesInsights, setEntitiesInsights] = useState(null);

    const [chatUser, setChatUser] = useState(null);

    const [dates, setDates] = useState(null);

    const [topics, setTopics] = useState(null);

    const [textFilter, setTextFilter] = useState('');

    const [organisation, setOrganisation] = useState(true);

    const [sortOption, setSortOption] = useState(0);

    const isAnonymous = session?.isAnonymous;

    const sortTeamOptions = [
        { label: 'Alphabetical', value: 0 },
        { label: 'Highest volume', value: 1 },
        { label: 'Highest unanswered', value: 2 },
        { label: 'Latest feedback received', value: 3 },
    ];

    const sortOptions = [
        { label: 'Most recent', value: 0 },
        { label: 'Alphabetical', value: 1 },
        { label: 'Topic (A-Z)', value: 2 },
        { label: 'Highest Score', value: 3 },
        { label: 'Highest Sentiment', value: 4 },
    ];

    useEffect(() => {
        if (teamId) {
            setTeam(teamId);
            setOrganisation(true);
        } else if (state?.teamId) {
            setTeam(state?.teamId);
            setOrganisation(true);
        }
    }, [state?.teamId, teamId]);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let teamOv = await getTeamOverview(dates.from, dates.to);

            setInsights(teamOv);

            setEntitiesInsights(
                teamOv.entities.map((x) => ({
                    ...x,
                    name: x.name,
                    id: x.id,
                    total: x.volume,
                    image: x.image,
                }))
            );
            setTopics(
                teamOv.topics.map((x) => ({
                    ...x,
                    name: x.name,
                    id: x.id,
                    total: x.volume,
                    image: `https://eu.ui-avatars.com/api/?background=random&size=256x256&name=${x.name}`,
                }))
            );

            setTeamOverview(teamOv.teams);

            setLoading(false);
        }

        if (dates && !organisation) {
            setSortOption(0);
            fetch();
        }
    }, [dates, organisation]);

    async function onComment(feedback) {
        let aux = [...feedbacks];
        aux[aux.map((x) => x.id).indexOf(feedback.id)] = feedback;

        setFeedbacks(aux);
    }

    useEffect(() => {
        async function fetch() {
            setLoading(true);

            let feed = await getOrganisationalFeedback(
                dates.from,
                dates.to,
                team?.id ? team?.id : client.id
            );

            let ins = await getOrganisationalFeedbackInsights(
                dates.from,
                dates.to,
                team?.id ? team?.id : null
            );

            setInsights(ins);

            setEntitiesInsights(
                ins.entities.map((x) => ({
                    ...x,
                    name: x.name,
                    id: x.id,
                    total: x.volume,
                    image: x.image,
                }))
            );
            setTopics(
                ins.topics.map((x) => ({
                    ...x,
                    name: x.name,
                    id: x.id,
                    total: x.volume,
                    image: `https://eu.ui-avatars.com/api/?background=random&size=256x256&name=${x.name}`,
                }))
            );

            let chat = {
                id: client.id,
                name: client.companyName,
                image: client.logo,
            };

            if (team?.id) {
                let retrievedTeam = await getTeam(team?.id);

                chat = {
                    id: retrievedTeam?.id,
                    name: retrievedTeam.title,
                    image: retrievedTeam.image,
                };
            }

            setChatUser(chat);

            setFeedbacks([...feed]);

            setLoading(false);
        }

        if (dates && organisation) {
            setSortOption(0);
            fetch();
        }
    }, [dates, organisation, team]);

    return (
        <Page title='Employee Voice' className='organisation-feedback'>
            <Fragment>
                <div className='filter-bar'>
                    <DateSelector
                        months={state?.months}
                        onChange={(from, to, months) =>
                            setDates({ from, to, months })
                        }
                    />

                    {team?.id ? (
                        <TeamSelector />
                    ) : (
                        <div className='organisation-bar'>
                            <div
                                className={`option ${
                                    organisation ? 'active' : ''
                                }`}
                                onClick={() => setOrganisation(true)}
                            >
                                Organisation overview
                            </div>
                            <div
                                className={`option ${
                                    organisation ? '' : 'active'
                                }`}
                                onClick={() => setOrganisation(false)}
                            >
                                Teams overview
                            </div>
                        </div>
                    )}
                </div>

                <div className='content'>
                    <div className='widgets'>
                        <OrganisationalInsightsWidget
                            title='Score'
                            info={
                                <Fragment>
                                    Volume – The amount of feedback to the
                                    specific organisational team or entity,
                                    within the defined time period.
                                    <br />
                                    Score – The average of all scored feedback,
                                    received by the specific team or entity,
                                    within the defined time period.
                                    <br />
                                    Sentiment – The average sentiment, of all
                                    non-scored feedback, received by the
                                    specific team or entity, within the defined
                                    time period.
                                </Fragment>
                            }
                            horizontal={true}
                            score={
                                insights &&
                                insights.entity &&
                                typeof insights.entity.score === 'number'
                                    ? insights.entity.score
                                    : null
                            }
                            sentiment={
                                insights &&
                                insights.entity &&
                                typeof insights.entity.sentiment === 'number'
                                    ? insights.entity.sentiment
                                    : null
                            }
                            volume={
                                insights &&
                                insights.entity &&
                                typeof insights.entity.volume === 'number'
                                    ? insights.entity.volume
                                    : null
                            }
                        />

                        <RankingWidget
                            title='Top topics'
                            info={
                                'Topics – The most prevalent themes of feedback received by the specific team or entity.'
                            }
                            ranking={topics}
                            loading={loading}
                            className={`${
                                isAnonymous ? 'alone' : ''
                            } responsive`}
                            header={'Topic'}
                        />

                        {organisation ? (
                            !isAnonymous && (
                                <RankingWidget
                                    title={team?.id ? 'Top users' : 'Top teams'}
                                    info={
                                        <Fragment>
                                            Source - The most prevalent source
                                            (originators) of feedback received
                                            by the specific team or entity.{' '}
                                            <br />
                                            Please note: If your Pulse 360
                                            system is anonymous, source
                                            information will not be displayed.
                                        </Fragment>
                                    }
                                    className='responsive'
                                    ranking={entitiesInsights}
                                    header={'Source'}
                                    loading={loading}
                                />
                            )
                        ) : (
                            <SwotWidget
                                title={'DafoWidget'}
                                info={
                                    <Fragment>
                                        High / Low Score {'&'} Sentiment – The
                                        team or entity with the highest and
                                        lowest average values of quantitative
                                        (score) and qualitative (sentiment)
                                        feedback during the time period. <br />
                                        The associated colours reflect the RAG
                                        levels of the score and sentiment values
                                        and align to your organisational
                                        settings
                                    </Fragment>
                                }
                                className='responsive'
                                dafo={teamOverview}
                                loading={loading}
                            />
                        )}
                    </div>
                    <div className='filter-bar'>
                        <SearchInput
                            onSearch={(text) => setTextFilter(text)}
                            placeholder={'Search feedback'}
                        />

                        <PulseSelect
                            className='highlighted'
                            options={
                                organisation
                                    ? isAnonymous
                                        ? sortOptions.filter(
                                              (x) => x.value !== 1
                                          )
                                        : sortOptions
                                    : sortTeamOptions
                            }
                            onChange={(e) => setSortOption(e.value)}
                            value={(organisation
                                ? sortOptions
                                : sortTeamOptions
                            ).find((x) => x.value === sortOption)}
                            isClearable={false}
                            isSearchable={false}
                        />
                    </div>
                    {organisation ? (
                        <FeedbackBar
                            data={feedbacks}
                            chatUser={chatUser}
                            sortOption={sortOption}
                            textFilter={textFilter}
                            onComment={onComment}
                        />
                    ) : (
                        <TeamOverview
                            data={teamOverview}
                            months={dates.months}
                            textFilter={textFilter}
                            sortOption={sortOption}
                        />
                    )}
                </div>
            </Fragment>
        </Page>
    );
}
