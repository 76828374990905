import { Fragment, useEffect, useState } from 'react';
import { getTerms } from '../../../../../services/goalService';
import { Modal } from '../../../../shared/components/Modal';
import { PulseSelect } from '../../../../shared/inputs/PulseSelect';
import { Textarea } from '../../../../shared/inputs/Textarea';
import constants from '../../../../shared/constants';
import { PropTypes } from 'prop-types';
import './PoSModal.scss';

export function PoSModal({ show, handleClose, addGoal }) {
    const [terms, setTerms] = useState([]);
    const [active, setActive] = useState(null);
    const [goalText, setGoalText] = useState(null);
    const [showValidation, setShowValidation] = useState(false);
    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        if (firstTime) {
            getTerms().then((terms) => {
                terms = terms.map((x) => ({ label: x.key, value: x.value }));

                setTerms(terms);
                setActive(terms[0].value);
            });
            setFirstTime(false);
        }
    }, [firstTime]);

    function renderValidation(variable, message) {
        return !variable && showValidation ? (
            <p className='error'>{message}</p>
        ) : (
            <Fragment> </Fragment>
        );
    }

    function addGoalFunction() {
        if (goalText) {
            addGoal(active, goalText);
            handleClose();
            setGoalText('');
        } else {
            setShowValidation(true);
        }
    }

    return (
        <Fragment>
            <Modal
                className='picture-of-success-modal '
                open={show}
                onClose={() => {
                    handleClose();
                }}
            >
                <Fragment>
                    <h3>Add new </h3>

                    <PulseSelect
                        className='highlighted'
                        label='Select term'
                        options={terms}
                        isClearable={false}
                        placeholder='Select term'
                        onChange={(term) => setActive(term.value)}
                        value={terms.filter((x) => x.value === active)}
                    />

                    <Textarea
                        maxLength={constants.maxLength}
                        rows={5}
                        placeholder='Enter your goal...'
                        onChange={(e) => setGoalText(e.target.value)}
                        value={goalText}
                    />
                    {renderValidation(
                        goalText,
                        'Please fill the goal description.'
                    )}

                    <button
                        type='button'
                        className={`btn  float-right highlighted`}
                        onClick={addGoalFunction}
                    >
                        Add
                    </button>
                </Fragment>
            </Modal>
        </Fragment>
    );
}

PoSModal.propTypes = {
    handleClose: PropTypes.func,
    addGoal: PropTypes.func,
    show: PropTypes.bool,
};
