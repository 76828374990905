import { Fragment, useEffect, useState } from 'react';
import { FeedbackBox } from './FeedbackBox';
import { useRagIntervals } from '../../../../shared/lib/useRagIntervals';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import RadialChart from '../../../../shared/charts/RadialChart';
import constants from '../../../../shared/constants';
import './components.scss';

export function FeedbackBar({
    chatUser,
    data,
    sortOption,
    textFilter,
    onComment,
}) {
    const [feedbacks, setFeedbacks] = useState(null);

    const [feedback, setFeedback] = useState(null);

    function reorderOrganisation(text, sort) {
        let result = data;

        switch (sort) {
            case 0:
                result = result.sort((a, b) =>
                    moment(a.modified).isAfter(moment(b.modified)) ? -1 : 1
                );
                break;
            case 1:
                result = result.sort((a, b) =>
                    a.feedbackGiver.name.toLowerCase() >
                    b.feedbackGiver.name.toLowerCase()
                        ? 1
                        : -1
                );
                break;
            case 2:
                result = result.sort((a, b) =>
                    a.topic.title.toLowerCase() > b.topic.title.toLowerCase()
                        ? 1
                        : -1
                );
                break;
            case 3:
                result = result.sort((a, b) =>
                    a.givenFeedback?.value < b.givenFeedback?.value ? 1 : -1
                );
                break;

            default:
                result = result.sort((a, b) => {
                    let aSentiment =
                        a?.sentiment?.score && !a?.givenFeedback?.value
                            ? a.sentiment.score
                            : 0;
                    let bSentiment =
                        b?.sentiment?.score && !b.givenFeedback?.value
                            ? b.sentiment.score
                            : 0;

                    return aSentiment < bSentiment ? 1 : -1;
                });
                break;
        }

        if (text.length > 0) {
            result = result.filter(
                (f) =>
                    f.givenFeedback?.feedback
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                    f.feedbackGiver.name
                        .toLowerCase()
                        .includes(text.toLowerCase()) ||
                    f.topic.title.toLowerCase().includes(text.toLowerCase())
            );
        }

        if (feedback && !result.find((x) => x.id === feedback.id)) {
            setFeedback(null);
        }
        setFeedbacks([...result]);
    }

    useEffect(() => {
        if (data && data.length) {
            reorderOrganisation(textFilter.trim(), sortOption);
        } else {
            setFeedbacks(null);
            setFeedback(null);
        }
    }, [textFilter, sortOption, data]);

    return (
        <Fragment>
            <div className='organisational-feedback-feed'>
                <div className='feedback-bar'>
                    {feedbacks && feedbacks.length > 0 ? (
                        feedbacks.map((x) => (
                            <FeedMiniature
                                key={`feed-${x.id}`}
                                feedback={x}
                                chatUser={chatUser}
                                active={feedback && x.id === feedback.id}
                                onClick={(f) => setFeedback(f)}
                            />
                        ))
                    ) : (
                        <div className='feed p-shadow empty'>
                            <p className='centered'>No feedback received</p>
                        </div>
                    )}
                </div>
                <div className='selected-feedback p-shadow'>
                    {feedback ? (
                        <FeedbackBox
                            chatUser={chatUser}
                            feedback={feedback}
                            onComment={onComment}
                        />
                    ) : (
                        <div className='feedback-box empty'>
                            <i className='icon icon-person empty'></i>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

FeedbackBar.propTypes = {
    chatUser: PropTypes.object,
    data: PropTypes.array,
    sortOption: PropTypes.number,
    textFilter: PropTypes.string,
    onComment: PropTypes.func,
};

function FeedMiniature({ feedback, onClick, chatUser, active }) {
    const { getColor } = useRagIntervals();

    return (
        <div
            className={`feed p-shadow ${active ? 'active' : ''}`}
            onClick={() => onClick(feedback)}
        >
            <div className='user-description'>
                {feedback.feedbackGiver.isAnonymous ? (
                    <div className='icon-box'>
                        <i
                            role='img'
                            aria-label='Anonymous'
                            className='icon-eye-anonymous'
                        ></i>
                    </div>
                ) : (
                    <img
                        alt='Feedback giver'
                        className='miniature'
                        src={feedback.feedbackGiver.image}
                    />
                )}

                <div>
                    <label>{feedback.feedbackGiver.name}</label>
                    <p className='secondary-text'>
                        {moment(feedback.created).format('DD MMM [at] HH:mm')}
                    </p>
                </div>
                <div className='score'>
                    {typeof feedback.givenFeedback?.value === 'number' ? (
                        <RadialChart
                            progress={feedback.givenFeedback?.value * 10}
                            color={getColor(
                                feedback.givenFeedback?.value,
                                constants.QuestionType.Score
                            )}
                            secondColor={'#d3d7df'}
                            numberText={
                                feedback.givenFeedback?.value === null
                                    ? ''
                                    : feedback.givenFeedback?.value
                            }
                            strokeWidth={20}
                            textHeight={'40%'}
                        />
                    ) : (
                        feedback &&
                        feedback.sentiment && (
                            <div
                                className='sentiment'
                                style={{
                                    borderColor: getColor(
                                        feedback &&
                                            feedback.sentiment &&
                                            feedback.sentiment.score !== null
                                            ? feedback.sentiment.score
                                            : null
                                    ),
                                }}
                            >
                                {feedback &&
                                feedback.sentiment &&
                                feedback.sentiment.score !== null
                                    ? `${feedback.sentiment.score}%`
                                    : ''}
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className='f-content'>
                <p className='text'>{feedback.givenFeedback?.feedback}</p>
                <div className='topic-bubble'>
                    <i className='icon icon-topic'></i>
                    {feedback.topic.title}
                </div>
            </div>
            <div className='notifications'>
                <div
                    className={`circle ${
                        !feedback.reactions || !feedback.reactions.length
                            ? 'red'
                            : feedback.reactions &&
                              feedback.reactions.length > 0 &&
                              feedback.reactions.length < 4 &&
                              feedback.reactions[feedback.reactions.length - 1]
                                  .actor.id !== chatUser.id
                            ? 'navy'
                            : ''
                    }`}
                ></div>
                <label>
                    {!feedback.reactions || !feedback.reactions.length
                        ? 'Feedback unanswered'
                        : feedback.reactions &&
                          feedback.reactions.length > 0 &&
                          feedback.reactions.length < 4 &&
                          feedback.reactions[feedback.reactions.length - 1]
                              .actor.id !== chatUser.id
                        ? 'Comments received'
                        : ''}
                </label>
            </div>
        </div>
    );
}

FeedMiniature.propTypes = {
    feedback: PropTypes.object,
    onClick: PropTypes.func,
    chatUser: PropTypes.object,
    active: PropTypes.bool,
};
