import { Fragment, useEffect, useState } from 'react';
import { SearchInput } from '../../../shared/inputs/SearchInput';
import { PropTypes } from 'prop-types';
import './Team.scss';

export function TeamMembersHandler({ users, onRemove, onAdd }) {
    return (
        <div className='team-members-handler'>
            {users?.map((u) => (
                <UserRow key={u.id}>
                    <div className='image'>
                        <img
                            alt={u?.name}
                            className='avatar-small'
                            src={u?.image}
                        />
                    </div>
                    <p>{u?.name}</p>
                    <i
                        className='icon icon-close'
                        onClick={(e) => onRemove(e, u.id)}
                        onKeyPress={(e) =>
                            e.key === 'Enter' && onRemove(e, u.id)
                        }
                    ></i>
                </UserRow>
            ))}
            <UserRow onClick={(e) => onAdd(e)} className='empty'>
                <div className='image'>
                    <Fragment>
                        <i className='icon icon-person'></i>
                        <i className='icon icon-close'></i>
                    </Fragment>
                </div>
                <div className='name'>
                    <p>Add new member</p>
                </div>
            </UserRow>
        </div>
    );
}

TeamMembersHandler.propTypes = {
    users: PropTypes.array,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
};

export function UserRow({ className, children, onClick }) {
    return (
        <div
            className={`user-row ${className ?? ''}`}
            onClick={(e) => (onClick ? onClick(e) : void 0)}
            onKeyPress={(e) =>
                onClick && e.key === 'Enter' ? onClick(e) : void 0
            }
            tabIndex={0}
        >
            {!!children && children}
        </div>
    );
}

UserRow.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
};

export function UserSelector({ users, onAdd, open, title, onClose }) {
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [filter, setFilter] = useState('');

    const [firstRender, setFirstRender] = useState(true);

    function handleUser(event, user) {
        if (selectedUsers.find((x) => x.id === user.id)) {
            setSelectedUsers([
                ...selectedUsers.filter((x) => x.id !== user.id),
            ]);
        } else {
            setSelectedUsers([...selectedUsers, user]);
        }
    }

    useEffect(() => {
        if (firstRender && open) {
            setFirstRender(false);
        }
    }, [firstRender, open]);

    function handleOnClose(e) {
        let event = e;
        setSelectedUsers([]);
        onClose(event);
    }

    function handleOnAdd(e) {
        let event = e;
        let returnUsers = [...selectedUsers];
        setSelectedUsers([]);

        onAdd(returnUsers, event);
    }

    return (
        <div
            className={`user-selector p-shadow ${
                firstRender
                    ? ''
                    : open
                    ? 'active slide-in-right'
                    : 'active slide-out-right'
            }`}
        >
            <i className='icon icon-close-solid' onClick={handleOnClose}></i>
            <label>{title}</label>

            <SearchInput
                onSearch={(text) => setFilter(text ?? '')}
                placeholder='User name'
            />

            <div className='user-list'>
                {users
                    ?.filter((x) =>
                        x.name
                            ?.toLowerCase()
                            ?.trim()
                            ?.includes(filter?.toLowerCase()?.trim())
                    )
                    ?.sort((x, y) => x.name.localeCompare(y.name))
                    ?.map((u) => (
                        <UserRow
                            key={u.id}
                            onClick={(e) => handleUser(e, u)}
                            className={
                                selectedUsers.find((x) => x.id === u.id)
                                    ? 'selected'
                                    : ''
                            }
                        >
                            <div className='image'>
                                <img
                                    alt={u?.name}
                                    className='avatar-small'
                                    src={u?.image}
                                />
                                <i className='icon icon-add'></i>
                                <i className='icon icon-checkbox-circle'></i>
                                <i className='icon icon-close-solid'></i>
                            </div>
                            <p>{u?.name}</p>
                        </UserRow>
                    ))}
            </div>
            <div className='buttons'>
                <button
                    className='btn highlighted'
                    onClick={() => handleOnAdd()}
                >
                    Add
                </button>
            </div>
        </div>
    );
}

UserSelector.propTypes = {
    users: PropTypes.array,
    onAdd: PropTypes.func,
    open: PropTypes.any,
    title: PropTypes.string,
    onClose: PropTypes.func,
};
