import { Component, Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import { ErrorPage } from './ErrorPage';
import { PropTypes } from 'prop-types';
import constants from '../constants';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorInfo: null,
            redirect: false,
            unAuthorized: false,
        };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        this.setState({
            hasError: true,
            errorInfo: errorInfo,
            forbidden: error?.message
                ? error?.message?.includes(constants.errorCodes.forbidden)
                : error?.includes(constants.errorCodes.forbidden),
            teamLeadEmpty: error?.message?.includes(
                constants.errorCodes.teamLeadEmpty
            ),
        });
    }

    redirectToHome() {
        this.setState({ redirect: true, hasError: false });
    }

    renderMessage() {
        let message = (
            <Fragment>
                <h3>Oops! Something went wrong.</h3>
                <br />
                <p>
                    Please, try again. If the issue persists, please contact our
                    support team <a href='mailto:support@pulse360.io'>here</a>.
                </p>
            </Fragment>
        );

        if (this.state.forbidden) {
            message = (
                <Fragment>
                    <h3>You don{"'"}t have access to this page.</h3>
                    <br />
                    <p>
                        Please, contact your administrator if you need
                        permissions or contact our support team{' '}
                        <a href='mailto:support@pulse360.io'>here</a>.
                    </p>
                </Fragment>
            );
        }

        if (this.state.teamLeadEmpty) {
            message = (
                <Fragment>
                    <h3>
                        You have Team Lead permissions but currently there{"'"}s
                        no team in our system where you figure as a Team Lead.
                    </h3>
                    <br />
                    <p>
                        Please, contact your administrator or send an email to
                        our support team{' '}
                        <a href='mailto:support@pulse360.io'>here</a>.
                    </p>
                </Fragment>
            );
        }

        return message;
    }

    render() {
        if (this.state.hasError) {
            return (
                <Fragment>
                    {this.state.redirect && <Navigate to={'/'} />}
                    <ErrorPage
                        message={this.renderMessage()}
                        buttonTitle='RETURN HOME'
                        onClick={() => this.redirectToHome()}
                    />
                </Fragment>
            );
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.object,
};
