import { get, put } from '../components/shared/lib/axios';

export async function getPPRTopic(pprTopicId) {
    return get(`ppr-topics/${pprTopicId}`);
}

export async function getPPRTopicsParents() {
    return get('/api/PPRTopic/parents');
}

export async function getPPRTopicParents(parentId) {
    return get(`ppr-topics`, { parentId: parentId });
}

export async function updatePprTopic(pprTopic) {
    return put(`PPRTopic/UpdateParentChildren`, pprTopic);
}
