import _uniqueId from 'lodash/uniqueId';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useWindowEvent } from '../lib/useWindowEvent';
import { ComponentLoading } from '../loadings/ComponentLoading';
import { PropTypes } from 'prop-types';

export function AutoComplete({
    onChange,
    options,
    defaultOptions,
    button,
    filterProperties,
    selected,
    placeholder,
    onGuestFeedback,
    autoFocus,
}) {
    const [id] = useState(_uniqueId('autocomplete-'));

    const [searchInput, setSearchInput] = useState('');

    const inputRef = useRef();

    const [focused, setFocused] = useState(false);

    const [entityArray, setEntityArray] = useState([]);

    function handleOnKeyDown(event) {
        if (
            inputRef?.current &&
            !inputRef?.current?.value &&
            event.key === 'Backspace'
        ) {
            onChange([...selected.slice(0, -1)]);
        }
    }

    useWindowEvent(
        'mousedown',
        useCallback((event) => {
            if (!event.target.closest(`#${id}`)) {
                setFocused(false);
            }
        }, [])
    );

    useEffect(() => {
        let array = options;
        if (searchInput) {
            array = [
                ...new Set(
                    filterProperties.flatMap((prop) =>
                        array?.filter((x) =>
                            x[prop]?.toLowerCase()?.includes(searchInput)
                        )
                    )
                ),
            ];
        } else {
            array = defaultOptions;
        }

        setEntityArray(
            array?.filter((x) => !selected?.some((y) => y.id === x.id))
        );
    }, [searchInput, selected, options, defaultOptions]);

    return (
        <div className='autocomplete' id={id}>
            <div className='input-items'>
                {!!selected?.length &&
                    selected?.map((sl, i) => (
                        <EntityBox
                            key={`selected-item-${sl.id}-${i}`}
                            className='selected-item'
                            name={`${sl?.name}${
                                sl.isExternal ? ' (Guest)' : ''
                            }`}
                            image={sl?.image}
                            icon={sl?.icon}
                            onClick={() => {
                                onChange([
                                    ...selected.filter((x) =>
                                        sl.isExternal
                                            ? x.email !== sl.email
                                            : x.id !== sl.id
                                    ),
                                ]);
                            }}
                        >
                            <i
                                aria-labelledby='Press enter to remove selected'
                                key={`icon-close-${sl.id}`}
                                className='icon icon-close'
                            ></i>
                        </EntityBox>
                    ))}
                <input
                    aria-labelledby='to-selector'
                    aria-required='true'
                    ref={inputRef}
                    type='text'
                    placeholder={!selected?.length ? placeholder : ''}
                    onKeyDown={handleOnKeyDown}
                    onFocus={() => setFocused(true)}
                    onChange={({ target: { value } }) =>
                        setSearchInput(value?.trim()?.toLowerCase())
                    }
                    autoFocus={autoFocus}
                />
            </div>

            {!!button && button}

            <div className={`dropdown ${focused ? 'active' : ''}`}>
                {focused &&
                ((!defaultOptions?.length && !searchInput) ||
                    !options?.length) ? (
                    <ComponentLoading />
                ) : entityArray?.length ? (
                    <Fragment>
                        {!searchInput && (
                            <Fragment>
                                {!!onGuestFeedback && (
                                    <EntityBox
                                        icon='icon-person'
                                        name='Add guest user'
                                        className='dropdown-item guest-item'
                                        onClick={(e) => {
                                            onGuestFeedback(e, searchInput);
                                            setFocused(false);
                                            inputRef.current.value = '';
                                            setSearchInput('');
                                        }}
                                    />
                                )}
                                <h4 role='none'>Recent contacts</h4>
                            </Fragment>
                        )}
                        {entityArray?.map((opt, i) => (
                            <EntityBox
                                key={`entity-box-${opt?.id}-${i}`}
                                className='dropdown-item'
                                name={opt?.name}
                                image={opt?.image}
                                description={opt?.description}
                                id={opt?.id}
                                onClick={() => {
                                    inputRef.current.value = '';
                                    setSearchInput('');
                                    onChange([
                                        ...(Array.isArray(selected)
                                            ? selected
                                            : []),
                                        options?.find((x) => x.id === opt.id),
                                    ]);
                                    setFocused(false);
                                }}
                            />
                        ))}

                        {!!searchInput && !!onGuestFeedback && (
                            <EntityBox
                                icon='icon-person'
                                name='Add guest user'
                                className='dropdown-item'
                                onClick={(e) => {
                                    onGuestFeedback(e, searchInput);
                                    setFocused(false);
                                    inputRef.current.value = '';
                                    setSearchInput('');
                                }}
                            />
                        )}
                    </Fragment>
                ) : onGuestFeedback ? (
                    <EntityBox
                        icon='icon-person'
                        name='Add guest user'
                        className='dropdown-item guest-item'
                        onClick={(e) => {
                            onGuestFeedback(e, searchInput);
                            setFocused(false);
                            inputRef.current.value = '';
                            setSearchInput('');
                        }}
                    />
                ) : (
                    <h4 className='italic'>No users found</h4>
                )}
            </div>
        </div>
    );
}

AutoComplete.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    defaultOptions: PropTypes.array,
    button: PropTypes.object,
    filterProperties: PropTypes.array,
    selected: PropTypes.array,
    placeholder: PropTypes.string,
    onGuestFeedback: PropTypes.func,
    autoFocus: PropTypes.bool,
};

function EntityBox({
    name,
    description,
    className,
    image,
    icon,
    onClick,
    children,
}) {
    return (
        <a
            tabIndex={0}
            className={`entity-box ${className ?? ''}`}
            onClick={(e) =>
                typeof onClick === 'function' ? onClick(e) : void 0
            }
            onKeyPress={(e) =>
                typeof onClick === 'function'
                    ? e.key === 'Enter' && onClick(e)
                    : void 0
            }
        >
            {!!image && (
                <img
                    aria-hidden={true}
                    className='rounded'
                    src={image}
                    alt='user or team image miniature'
                />
            )}
            {!!icon && <i className={`icon ${icon}`}></i>}
            <div>
                {!!name && (
                    <h5 role='none' className='overflow-hidden'>
                        {name}
                    </h5>
                )}
                {!!description && <p className='small'>{description}</p>}
            </div>
            {!!children && children}
        </a>
    );
}

EntityBox.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.object,
};
