import { Fragment, useEffect, useState } from 'react';
import { useAsyncError } from '../lib/helper';
import { useRagIntervals } from '../lib/useRagIntervals';
import { Loading } from '../loadings/Loading';
import { PropTypes } from 'prop-types';
import './charts.scss';

export function HorizontalBarChart({ data, className, children }) {
    const [formattedData, setFormattedData] = useState(null);

    const [loading, setLoading] = useState(true);

    const { getColorArray } = useRagIntervals();

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchData() {
            try {
                let colors = getColorArray(
                    data.filter((x) => x !== null).map((x) => x.value / 10)
                );

                setFormattedData(
                    data
                        .filter((x) => x !== null)
                        .map((x, i) => ({
                            ...x,
                            color: x.color ? x.color : colors[i],
                        }))
                );

                setLoading(false);
            } catch (e) {
                throwError(e);
            }
        }

        if (data && data.length) {
            fetchData();
        }
    }, [data]);

    return (
        <div className={`horizontal-bar-chart ${className ? className : ''}`}>
            {loading ? (
                <Loading />
            ) : (
                <div>
                    <Fragment>
                        {formattedData.map((d, i) => (
                            <div className='c-row' key={`c-row-${i}`}>
                                {d.label && (
                                    <div
                                        title={d?.label}
                                        className='c-label'
                                        key={`c-label-${i}`}
                                    >
                                        {d.label}
                                    </div>
                                )}

                                <div
                                    className={`c-bar ${
                                        d.onClick ? 'clickable' : ''
                                    }`}
                                    title={d.hover ? d.hover : undefined}
                                    key={`c-bar-${i}`}
                                    style={{
                                        backgroundColor: `${d.color}1E`,
                                    }}
                                    onClick={() =>
                                        d.onClick ? d.onClick() : void 0
                                    }
                                >
                                    <div
                                        className={`c-value`}
                                        key={`c-value-${i}`}
                                        style={{
                                            width: `${d.value}%`,
                                            backgroundColor: d.color,
                                            color: d.barTextColor
                                                ? d.barTextColor
                                                : undefined,
                                        }}
                                    >
                                        <p>{d.barText}</p>
                                    </div>
                                </div>
                                {d.sLabel && (
                                    <div
                                        className='c-percentage'
                                        key={`c-percentage-${i}`}
                                    >
                                        {d.sLabel}
                                    </div>
                                )}
                            </div>
                        ))}
                        {!!children && children}
                    </Fragment>
                </div>
            )}
        </div>
    );
}

HorizontalBarChart.propTypes = {
    data: PropTypes.array,
    className: PropTypes.string,
    children: PropTypes.object,
};
