import paginationHelper from '../components/shared/lib/paginationHelper';
import { get } from '../components/shared/lib/axios';

export async function getEntitiesInsights(
    from,
    to,
    teamId,
    limit,
    offset,
    searchFilter,
    orderBy
) {
    let pagination = paginationHelper.createFeedbackInsightsPagination(
        limit,
        offset,
        searchFilter,
        orderBy,
        teamId
    );
    return get(`/entities/insights`, { from, to, ...pagination, teamId });
}
