import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import './Assess.scss';
import { AssessTopicsBoxes } from './components/AssessTopicsBoxes';

export function Assess() {
    return (
        <Page title='Assess'>
            <MainContent >
                <AssessTopicsBoxes />
            </MainContent>
        </Page>
    );
}
