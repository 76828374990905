import { useState, useEffect } from 'react';
import {
    Bar,
    BarChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { getSurveyQuestionDistribution } from '../../../../../../services/surveyService';
import { CustomTooltip } from '../../ResponseRateTrend';
import { PropTypes } from 'prop-types';
import constants from '../../../../constants';
import { Loading } from '../../../../loadings/Loading';

export function YesNoTrendChart({ surveyId, questionId, teamId, iterations }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetch() {
            let uQ = await Promise.all(
                iterations?.map(async (it) => ({
                    data: await getSurveyQuestionDistribution(
                        surveyId,
                        questionId,
                        teamId,
                        it
                    ),

                    iteration: it,
                }))
            );

            setData(
                uQ.map((x) => ({
                    yes: x.data.valueDistribution[0].value,
                    no: x.data.valueDistribution[1].value,
                    iteration: x.iteration + 1,
                }))
            );
        }
        if (surveyId && questionId && iterations?.length) {
            fetch();
        }
    }, [surveyId, questionId, teamId, iterations]);

    return data ? (
        <ResponsiveContainer width='100%' height='100%' minHeight={'15rem'}>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 5,
                    left: -20,
                    bottom: 10,
                }}
            >
                <XAxis
                    tickLine={false}
                    dataKey='iteration'
                    tick={{ fontSize: '1.2rem', fontFamily: 'Open Sans' }}
                    tickFormatter={(tick) => `#${tick}`}
                />
                <YAxis
                    tickLine={false}
                    domain={[0, 100]}
                    tick={{ fontSize: '1.2rem', fontFamily: 'Open Sans' }}
                    tickFormatter={(tick) => `${tick}%`}
                />
                <Tooltip
                    wrapperStyle={{ zIndex: 1000 }}
                    cursor={{ fill: 'transparent' }}
                    content={({ active, payload }) => {
                        if (active) {
                            return (
                                <CustomTooltip>
                                    <div>
                                        <label>Y:</label>
                                        <h4>{payload[0]?.payload?.yes}%</h4>
                                    </div>
                                    <div>
                                        <label>N:</label>
                                        <h4>{payload[0]?.payload?.no}%</h4>
                                    </div>
                                </CustomTooltip>
                            );
                        }

                        return null;
                    }}
                />
                <Bar
                    maxBarSize={35}
                    dataKey='yes'
                    stackId='a'
                    fill={constants.Colors.Yes}
                />
                <Bar
                    maxBarSize={35}
                    dataKey='no'
                    stackId='a'
                    fill={constants.Colors.No}
                />
            </BarChart>
        </ResponsiveContainer>
    ) : (
        <Loading />
    );
}

YesNoTrendChart.propTypes = {
    surveyId: PropTypes.string,
    questionId: PropTypes.string,
    teamId: PropTypes.string,
    iterations: PropTypes.array,
};
