import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useState,
    useRef,
} from 'react';
import { Loading } from '../../../../../components/shared/loadings/Loading';
import { Controller, useForm } from 'react-hook-form';
import { AppActionTypes, AppContext } from '../../../../../contexts/AppContext';
import {
    getRecentContacts,
    feedbackAsk,
    feedbackGive,
} from '../../../../../services/feedbackService';
import { getTopics } from '../../../../../services/topicService';
import { getActorType } from '../../../../../services/utilService';
import { AutoComplete } from '../../../../shared/components/AutoComplete';
import { SubmitButton } from '../../../../shared/components/SubmitButton';
import { TooltipSelector } from '../../../../shared/components/TooltipSelector';
import constants from '../../../../shared/constants';
import { StyledSlider } from '../../../../shared/forms/StyledSlider';
import { PulseSelect } from '../../../../shared/inputs/PulseSelect';
import { Textarea } from '../../../../shared/inputs/Textarea';
import { useCache } from '../../../../shared/lib/useCache';
import { useClient } from '../../../../shared/lib/useClient';
import { useRecaptcha } from '../../../../shared/lib/useRecaptcha';
import { useUser } from '../../../../shared/lib/useUser';
import { ContactModal } from './ContactModal';
import { GuestModal } from './GuestModal';
import { SuggestedQuestionModal } from './SuggestedQuestionModal';
import { AnswerTypeSelector } from './AskGive';
import { PropTypes } from 'prop-types';
import { Switch } from '../../../../shared/components/Switch';
import './AskGive.scss';

export function AskForm({ activeTab }) {
    const { users, teams } = useCache();
    const [form, setForm] = useState(null);
    const [loading, setLoading] = useState(true);

    const TabType = {
        EMPLOYEE: 'employee',
        GIVE: 'give',
        ASK: 'ask',
    };

    const submitRef = useRef(null);

    const topicRef = useRef(null);

    const {
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue,
        watch,
        trigger,
        clearErrors,
    } = useForm();

    const [focus, setFocus] = useState(false);

    const [visible, setVisible] = useState(false);

    const client = useClient();

    const user = useUser();

    const isEmployee = activeTab === TabType.EMPLOYEE;

    const [topics, setTopics] = useState(undefined);

    const [openContactsModal, setOpenContactsModal] = useState(false);

    const [openQuestionModal, setOpenQuestionModal] = useState(false);

    const { dispatch } = useContext(AppContext);

    const [openGuestModal, setOpenGuestModal] = useState(false);

    const [actorType, setActorType] = useState(null);

    const [defaultOptions, setDefaultOptions] = useState([]);

    const [isAnonymous, setIsAnonymous] = useState(false);

    const [options, setOptions] = useState([]);

    const [guestEmail, setGuestEmail] = useState(null);

    const watchTopic = watch('topic', null);

    const [recentContacts, setRecentContacts] = useState(null);

    const watchEntities = watch('entities', null);

    const onRecaptcha = useRecaptcha();

    const fetchTopics = useCallback(
        async (management) => {
            setValue('topic', null);

            let t = await getTopics();

            setTopics(t.filter((x) => x.category !== (management ? 0 : 1)));
        },
        [isEmployee, activeTab]
    );

    useEffect(() => {
        async function fetch() {
            let recent = await getRecentContacts(user?.id);
            await fetchTopics(isEmployee);
            setActorType(await getActorType());
            setRecentContacts(recent);
        }
        if (user?.id) {
            fetch();
        }
    }, [user?.id, isEmployee]);

    useEffect(() => {
        setTimeout(() => setVisible(!!activeTab), 400);

        setValue('entities', []);
        setValue('score', null);
        setValue('answerType', null);
        setValue('feedback', '');
        clearErrors();
    }, [activeTab]);

    useEffect(() => {
        if (actorType && teams && users && client) {
            let def = [];

            let opt = [];
            if (isEmployee) {
                def = [
                    {
                        ...client,
                        name: client?.companyName,
                        image: client?.logo,
                        description: 'Company',
                        type: actorType?.client,
                    },
                    ...teams.map((team) => mapArray(team, 'team')),
                ];

                opt = [
                    {
                        ...client,
                        name: client?.companyName,
                        image: client?.logo,
                        description: 'Company',
                        type: actorType?.client,
                    },
                    ...teams.map((team) => mapArray(team, 'team')),
                ];
            } else {
                def = recentContacts?.length
                    ? recentContacts
                          ?.filter((x) => x?.id !== user?.id)
                          ?.map((x) => mapArray(x, 'user'))
                    : [
                          ...user.teams
                              .flatMap((x) => [
                                  ...teams
                                      .find((t) => t?.id === x?.id)
                                      ?.teamMembers.map((user) =>
                                          mapArray(
                                              users.find(
                                                  (x) => x?.id === user?.id
                                              ),
                                              'user'
                                          )
                                      ),
                              ])
                              .filter((x) => x?.id !== user?.id),
                      ];

                opt = [
                    ...teams.map((team) => mapArray(team, 'team')),
                    ...users
                        .map((user) => mapArray(user, 'user'))
                        .filter((x) => x?.id !== user?.id),
                ];
            }

            setDefaultOptions(def);
            setOptions(opt);
            setLoading(false);
        }
    }, [actorType, isEmployee, recentContacts, teams, users]);

    function mapArray(value, type) {
        if (type === 'team') {
            return {
                ...value,
                name: value?.title ? value?.title : value?.name,
                isTeam: true,
                description: isEmployee ? 'Team' : 'All Team',
                type: actorType?.team,
            };
        } else {
            return {
                ...value,
                type: actorType?.user,
                description: value?.teams?.length
                    ? value.teams
                          .map((x) => (x?.title ? x?.title : x?.name))
                          .join(', ')
                    : teams
                          ?.filter((x) =>
                              x?.teamMembers?.some((y) => y?.id === value?.id)
                          )
                          ?.map((x) => x?.title)
                          .join(', '),
            };
        }
    }

    async function onSubmit(data) {
        let request = null;
        if (activeTab === TabType.ASK) {
            //ASK
            request = {
                Question: data.feedback,
                Topic: data.topic,
                FeedbackReceiver: { ...user, type: actorType?.user },
                Type: data.answerType,
                IsOrganization: isEmployee,
            };
        } else {
            //GIVE

            request = {
                Topic: data.topic,
                FeedbackGiver: { ...user, type: actorType?.user },
                Type: data?.score ? 1 : 0,
                Answer: data.feedback,
                Value: data?.score,
                IsOrganization: isEmployee,
                IsAnonymous: isAnonymous,
            };
        }
        if (request) {
            setForm({ loading: true });
            try {
                onRecaptcha(async () => {
                    await Promise.all(
                        data?.entities?.map((entity) =>
                            activeTab === TabType.ASK
                                ? feedbackAsk(request, entity)
                                : feedbackGive(request, entity)
                        )
                    );
                    setForm({ loading: false, success: true });
                });
            } catch (e) {
                setForm({ loading: false, success: false });
            }
        }
    }

    useEffect(() => {
        if (form?.success) {
            setTimeout(() => {
                dispatch({ type: AppActionTypes.REFRESH });

                setForm(null);
                reset({
                    topic: null,
                    feedback: '',
                    entities: [],
                    answerType: null,
                    score: null,
                });
                setIsAnonymous(false);
            }, 1000);
        }
    }, [form?.success]);

    useEffect(() => {
        if (focus) {
            document.activeElement.focus();
            setFocus(false);
        }
    }, [focus]);

    return (
        <>
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <form
                        role='form'
                        onSubmit={handleSubmit(onSubmit)}
                        className={`content ${activeTab ? 'show' : ''} ${
                            visible ? 'visible' : ''
                        }`}
                    >
                        <div className='row'>
                            <div className='group-form'>
                                <label aria-label='To' id='to-selector'>
                                    To
                                </label>

                                <Controller
                                    name='entities'
                                    control={control}
                                    rules={{
                                        required:
                                            'At least one entity is required',
                                    }}
                                    defaultValue={[]}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <AutoComplete
                                            autoFocus
                                            placeholder={
                                                'Type a name or multiple names...'
                                            }
                                            button={
                                                <a
                                                    tabIndex={0}
                                                    role='button'
                                                    className='button'
                                                    aria-label='Open contact modal'
                                                    onClick={() =>
                                                        setOpenContactsModal(
                                                            true
                                                        )
                                                    }
                                                    onKeyPress={(ev) =>
                                                        ev.key === 'Enter' &&
                                                        setOpenContactsModal(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <i
                                                        className={`icon icon-${
                                                            isEmployee
                                                                ? 'company-active'
                                                                : 'peer-active'
                                                        }`}
                                                    ></i>
                                                </a>
                                            }
                                            filterProperties={[
                                                'name',
                                                'description',
                                            ]}
                                            options={options}
                                            defaultOptions={defaultOptions}
                                            onGuestFeedback={
                                                activeTab === TabType.ASK
                                                    ? (e, filter) => {
                                                          setOpenGuestModal(
                                                              true
                                                          );
                                                          setGuestEmail(filter);
                                                      }
                                                    : null
                                            }
                                            onChange={(u) => {
                                                let teams = [];

                                                u?.filter(
                                                    (x) => !x.isTeam
                                                )?.forEach(
                                                    (u, i) => (u.index = i)
                                                );

                                                if (!isEmployee) {
                                                    teams = u?.filter(
                                                        (x) => x.isTeam
                                                    );

                                                    let externals = u?.filter(
                                                        (x) => x.isExternal
                                                    );

                                                    let membersIds = teams
                                                        ?.flatMap((t) =>
                                                            users.filter((x) =>
                                                                x?.teams?.some(
                                                                    (y) =>
                                                                        y.id ===
                                                                        t.id
                                                                )
                                                            )
                                                        )
                                                        ?.map((x) => x.id);

                                                    var unique = [
                                                        ...u
                                                            .filter(
                                                                (x) =>
                                                                    !x.isTeam &&
                                                                    !!x.id
                                                            )
                                                            .map((x) => x.id),
                                                        ...membersIds,
                                                    ].filter(
                                                        (v, i, a) =>
                                                            a.indexOf(v) === i
                                                    );

                                                    onChange(
                                                        [
                                                            ...unique
                                                                .filter(
                                                                    (x) =>
                                                                        x !==
                                                                        user?.id
                                                                )
                                                                .map(
                                                                    (
                                                                        id,
                                                                        index
                                                                    ) => ({
                                                                        ...options.find(
                                                                            (
                                                                                x
                                                                            ) =>
                                                                                x?.id ===
                                                                                id
                                                                        ),
                                                                        index:
                                                                            u?.find(
                                                                                (
                                                                                    y
                                                                                ) =>
                                                                                    y.id ===
                                                                                    id
                                                                            )
                                                                                ?.index ??
                                                                            1000 +
                                                                                index,
                                                                    })
                                                                ),
                                                            ...externals,
                                                        ].sort(
                                                            (a, b) =>
                                                                a.index -
                                                                b.index
                                                        )
                                                    );
                                                } else {
                                                    onChange([...u]);
                                                }
                                            }}
                                            selected={value}
                                        />
                                    )}
                                />

                                {errors?.entities?.message && (
                                    <div className='error'>
                                        {errors?.entities?.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row split-medium-down'>
                            <div className='group-form topic'>
                                <label aria-label='Topic' id='topicSelect'>
                                    Topic
                                </label>
                                <Controller
                                    name='topic'
                                    control={control}
                                    rules={{ required: 'Topic is required' }}
                                    defaultValue={undefined}
                                    render={({ field }) => {
                                        let options = topics?.map((x) => ({
                                            value: x.id,
                                            label: x.title,
                                        }));
                                        return (
                                            <Fragment>
                                                <PulseSelect
                                                    ref={topicRef}
                                                    aria-label='Topic dropdown'
                                                    aria-required='true'
                                                    aria-labelledby='Topic dropdown'
                                                    {...field}
                                                    options={options}
                                                    onChange={(obj) =>
                                                        field.onChange(
                                                            obj?.value
                                                        )
                                                    }
                                                    value={
                                                        options?.find(
                                                            (x) =>
                                                                x?.value ===
                                                                field?.value
                                                        ) ?? null
                                                    }
                                                />
                                            </Fragment>
                                        );
                                    }}
                                />

                                {errors?.topic?.message && (
                                    <div className='error'>
                                        {errors?.topic?.message}
                                    </div>
                                )}
                            </div>
                            <div className='group-form score-answer-type'>
                                <label
                                    aria-label={
                                        activeTab === TabType.ASK
                                            ? 'Answer Type Selector'
                                            : 'Score (optional) Selector'
                                    }
                                    id='answerType'
                                >
                                    {activeTab === TabType.ASK
                                        ? 'Answer Type'
                                        : 'Score (optional)'}
                                </label>

                                {activeTab === TabType.ASK && (
                                    <Fragment>
                                        <Controller
                                            name='answerType'
                                            control={control}
                                            rules={{
                                                required:
                                                    'Answer type is required',
                                            }}
                                            defaultValue={undefined}
                                            render={({
                                                field: { onChange, value },
                                            }) => (
                                                <Fragment>
                                                    <TooltipSelector
                                                        aria-label={
                                                            'Answer Type Selector'
                                                        }
                                                        aria-required='true'
                                                        value={
                                                            typeof value !==
                                                            'number' ? (
                                                                <Fragment>
                                                                    <i className='icon icon-help'></i>
                                                                </Fragment>
                                                            ) : (
                                                                <i
                                                                    style={{
                                                                        fontSize:
                                                                            '2.5rem',
                                                                    }}
                                                                    className={`icon-${
                                                                        value ===
                                                                        1
                                                                            ? 'feedback-score'
                                                                            : value ===
                                                                              2
                                                                            ? 'yn'
                                                                            : 'feedback-total'
                                                                    }`}
                                                                ></i>
                                                            )
                                                        }
                                                    >
                                                        <AnswerTypeSelector
                                                            onChange={(type) =>
                                                                onChange(type)
                                                            }
                                                        />
                                                    </TooltipSelector>
                                                </Fragment>
                                            )}
                                        />

                                        {errors?.answerType?.message && (
                                            <div className='error'>
                                                {errors?.answerType?.message}
                                            </div>
                                        )}
                                    </Fragment>
                                )}

                                {activeTab !== TabType.ASK && (
                                    <Controller
                                        name='score'
                                        control={control}
                                        defaultValue={null}
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Fragment>
                                                <TooltipSelector
                                                    aria-label={
                                                        'Score selector'
                                                    }
                                                    aria-required='true'
                                                    value={
                                                        typeof value !==
                                                        'number' ? (
                                                            <Fragment>
                                                                <i className='icon icon-new-score'></i>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                <h3>{value}</h3>
                                                                <i
                                                                    className='icon-close'
                                                                    onClick={() =>
                                                                        onChange(
                                                                            null
                                                                        )
                                                                    }
                                                                    onKeyPress={(
                                                                        ev
                                                                    ) =>
                                                                        ev.key ===
                                                                            'Enter' &&
                                                                        onChange(
                                                                            null
                                                                        )
                                                                    }
                                                                ></i>
                                                            </Fragment>
                                                        )
                                                    }
                                                >
                                                    <StyledSlider
                                                        style={{
                                                            minWidth: '30rem',
                                                        }}
                                                        step={1}
                                                        min={1}
                                                        max={10}
                                                        value={value}
                                                        onChange={(s) =>
                                                            onChange(s)
                                                        }
                                                    />
                                                </TooltipSelector>
                                            </Fragment>
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                        <div className='row feedback-comment'>
                            {(activeTab === TabType.EMPLOYEE && isAnonymous) ||
                            !user?.image ? (
                                <div className='icon-box'>
                                    <i className='icon-eye-anonymous'></i>
                                </div>
                            ) : (
                                <img
                                    className='rounded'
                                    src={user?.image}
                                    alt='user image miniature'
                                />
                            )}
                            <div className='group-form'>
                                <Controller
                                    name='feedback'
                                    control={control}
                                    rules={{ required: 'Feedback is required' }}
                                    defaultValue={''}
                                    render={({
                                        field: {
                                            onChange,
                                            onBlur,
                                            value,
                                            name,
                                            ref,
                                        },
                                    }) => (
                                        <Fragment>
                                            <Textarea
                                                aria-required='true'
                                                aria-label='Feedback textarea'
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                maxLength={constants.maxLength}
                                                placeholder={`${
                                                    activeTab === TabType.ASK
                                                        ? 'Type your question or search for inspiration...'
                                                        : activeTab ===
                                                          TabType.GIVE
                                                        ? 'Type your feedback...'
                                                        : 'Share your thoughts, feedback or suggestions...'
                                                }`}
                                                rows={3}
                                                value={value}
                                                name={name}
                                                ref={ref}
                                                style={
                                                    activeTab === TabType.ASK
                                                        ? {
                                                              paddingBottom:
                                                                  '30px',
                                                          }
                                                        : {}
                                                }
                                            />
                                        </Fragment>
                                    )}
                                />

                                {activeTab === TabType.ASK && (
                                    <a
                                        role='button'
                                        className='small'
                                        tabIndex={0}
                                        onClick={() =>
                                            setOpenQuestionModal(true)
                                        }
                                        onKeyPress={(ev) =>
                                            ev.key === 'Enter' &&
                                            setOpenQuestionModal(true)
                                        }
                                    >
                                        Search for a question
                                    </a>
                                )}
                                {errors?.feedback?.message && (
                                    <div className='error feedback'>
                                        {errors?.feedback?.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        {isEmployee && (
                            <div className='row'>
                                <p>I want my feedback to be anonymous.</p>
                                <Switch
                                    checked={isAnonymous}
                                    className={`notifications-switch`}
                                    onChange={setIsAnonymous}
                                    style={{ marginLeft: '4rem' }}
                                >
                                    <div className='text'>
                                        {isAnonymous ? 'Yes' : 'No'}
                                    </div>
                                </Switch>
                            </div>
                        )}

                        <div className='row'>
                            <SubmitButton
                                ref={submitRef}
                                loading={form?.loading}
                                success={form?.success}
                                disabled={form?.disabled}
                            >
                                Send
                            </SubmitButton>
                        </div>
                    </form>
                    <SuggestedQuestionModal
                        open={openQuestionModal}
                        onSelect={(q) => {
                            setOpenQuestionModal(false);
                            setFocus(true);
                            if (q) {
                                setValue('topic', q?.topic?.id);
                                setValue('answerType', q?.questionType);
                                setValue('feedback', q?.questionText);

                                trigger(['topic', 'answerType', 'feedback']);
                            }
                        }}
                        topic={{
                            id: watchTopic,
                            name: topics?.find((x) => x.id === watchTopic)
                                ?.title,
                        }}
                    />

                    <ContactModal
                        onAdd={(entities) => {
                            setValue('entities', [
                                ...watchEntities,
                                ...entities,
                            ]);
                            trigger('entities');
                            setOpenContactsModal(false);
                            setFocus(true);
                        }}
                        selected={watchEntities}
                        open={openContactsModal}
                        isEmployee={isEmployee}
                    />

                    <GuestModal
                        open={openGuestModal}
                        selected={watchEntities}
                        email={guestEmail}
                        onAdd={(guest) => {
                            setFocus(true);
                            setOpenGuestModal(false);
                            setGuestEmail(null);
                            if (guest) {
                                setValue('entities', [
                                    ...watchEntities,
                                    {
                                        ...guest,
                                        name: `${guest.firstName} ${guest.lastName}`,
                                        icon: 'icon-person',
                                    },
                                ]);
                            }
                        }}
                    />
                </>
            )}
        </>
    );
}

AskForm.propTypes = {
    activeTab: PropTypes.string,
};
