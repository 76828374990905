import { Fragment, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { TeamSelector } from '../../../../shared/components/TeamSelector';
import { MainContent } from '../../../../shared/layout/MainContent';
import { Page } from '../../../../shared/layout/Page';
import { useSelectedTeam } from '../../../../shared/lib/useSelectedTeam';
import { SurveyCompletionRateWidget } from '../../../../shared/surveys/SurveyCompletionRateWidget';
import './MTPulse.scss';

export function MTPulseCompletionRate() {
    const { teamId } = useParams();

    const { team, setTeam } = useSelectedTeam();
    const [redirect, setRedirect] = useState(null);
    const [loading, setLoading] = useState(true);

    function renderRedirect() {
        return (
            redirect && (
                <Fragment>
                    <Navigate
                        to={`/my-team/feedback/pulse`}
                        state={{ teamId: team.value }}
                    />
                    ;
                </Fragment>
            )
        );
    }

    useEffect(() => {
        async function fetchData() {
            if (teamId) {
                setTeam(teamId);
            }

            setLoading(false);
        }

        fetchData();
    }, [teamId]);

    return (
        <Fragment>
            {renderRedirect()}
            <div className='pulse-completion-rate'>
                <Page
                    loading={loading}
                    header={
                        <div className='title-margin space-between'>
                            <h1>Surveys</h1>
                            <TeamSelector />
                        </div>
                    }
                >
                    {!!team && (
                        <Fragment>
                            <MainContent
                                title={`Survey insights`}
                                line={true}
                                rightBox={
                                    <i
                                        className='icon-progress-active'
                                        onClick={() => setRedirect(true)}
                                    ></i>
                                }
                            >
                                <Fragment>
                                    <SurveyCompletionRateWidget
                                        filterable={true}
                                        team={team}
                                    />
                                </Fragment>
                            </MainContent>
                        </Fragment>
                    )}
                </Page>
            </div>
        </Fragment>
    );
}
