import { useEffect, useState } from 'react';
import { getUserLastPPRs } from '../../../../../services/assessService';
import { Page } from '../../../../shared/layout/Page';
import { useSession } from '../../../../shared/lib/useSession';
import { PPRBox } from './components/PPRBox';
import './MeAssess.scss';

export function MeAssess() {
    const [pprs, setPPRs] = useState(null);

    const session = useSession();

    useEffect(() => {
        async function fetchData() {
            let pprs = await getUserLastPPRs(session?.userId);

            setPPRs(pprs);
        }

        fetchData();
    }, []);

    return (
        <Page
            title='Assessment'
            loading={!pprs}
            className='me-assess'
            testId='me-assess'
        >
            {pprs?.map((ppr) => (
                <PPRBox key={ppr.id} ppr={ppr} userId={session?.userId} />
            ))}
        </Page>
    );
}
