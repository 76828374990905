import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import {
    getTeam,
    updateTeam,
    createTeam,
    getUserTeamLeadTeam,
    getTeams,
} from '../../../../services/teamService';
import { getUsers } from '../../../../services/userService';
import { Modal } from '../../../shared/components/Modal';
import { SubmitButton } from '../../../shared/components/SubmitButton';
import { AvatarImage } from '../../../shared/inputs/AvatarImage';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper';
import { TeamMembersHandler, UserSelector } from './TeamMembersHandler';
import { AppActionTypes, AppContext } from '../../../../contexts/AppContext';
import './Team.scss';

export function Team() {
    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [redirect, setRedirect] = useState(null);

    const [team, setTeam] = useState(null);

    const [users, setUsers] = useState(null);

    const [teamMembers, setTeamMembers] = useState([]);

    const [teamLeads, setTeamLeads] = useState([]);

    const [isTeamMember, setIsTeamMember] = useState(null);

    const [triggerOpen, dispatchTrigger] = useState(null);

    const [imageFile, setImageFile] = useState(null);

    const [form, setForm] = useState(null);

    const [openModal, setOpenModal] = useState(null);

    const [confirmation, setConfirmation] = useState(false);

    const submitRef = useRef(null);

    const [count, setCount] = useState(0);

    const { dispatch } = useContext(AppContext);

    const throwError = useAsyncError();

    useEffect(() => {
        async function getTeamAsync() {
            try {
                let t = await getTeam(id);

                setTeamMembers(t.teamMembers);

                setTeamLeads(t.teamLeads);

                reset(t);

                setTeam(t);
            } catch (error) {
                throwError(error);
            }
        }

        if (id) {
            getTeamAsync();
        }
    }, [id]);

    useEffect(() => {
        async function getUsersAsync() {
            try {
                let u = await getUsers();
                setUsers(u);
                setLoading(false);
            } catch (error) {
                throwError(error);
            }
        }

        getUsersAsync();
    }, []);

    function handleOnAdd(users) {
        if (users?.length) {
            if (isTeamMember) {
                setTeamMembers([...teamMembers, ...users]);
            } else {
                setTeamLeads([...teamLeads, ...users]);
            }
        }
        dispatchTrigger(null);
    }

    function handleImageChange(file, toBlob, fileName) {
        setTeam({ ...team, image: file });

        setImageFile({ base64Image: toBlob, ImageName: fileName });
    }

    async function onSubmit(data) {
        if (!teamLeads?.length && !confirmation) {
            setOpenModal(true);
        } else {
            setForm({ loading: true, disabled: true });
            let request = {
                ...data,
                teamMembers: [...teamMembers.map((x) => x.id)],
                teamLeads: [...teamLeads.map((x) => x.id)],
                ...imageFile,
            };

            try {
                id ? await updateTeam(id, request) : await createTeam(request);

                setForm({ ...form, loading: false, success: true });

                let [tl, t] = await Promise.all([
                    getUserTeamLeadTeam(),
                    getTeams(),
                ]);

                dispatch({
                    type: AppActionTypes.UPDATE,
                    prop: 'cache',
                    payload: {
                        teams: t,
                        teamLeadTeams: tl,
                    },
                });
            } catch (error) {
                setForm({
                    ...form,
                    loading: false,
                    success: false,
                    disabled: false,
                });
            }

            dispatchTrigger(false);
        }
    }

    useEffect(() => {
        if (form?.success) {
            setTimeout(() => {
                setForm({ success: false, disabled: false });
                setRedirect('/admin/teams');
            }, 1000);
        }
    }, [form]);

    useEffect(() => {
        if (confirmation && submitRef?.current) {
            setOpenModal(false);
            submitRef?.current?.click();
        }
    }, [confirmation]);
    return (
        <Fragment>
            <Page className='team' loading={loading}>
                <MainContent className='team-main-content'>
                    {redirect && <Navigate to={redirect} />}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='group-form'>
                            <label htmlFor='name'>Team name</label>
                            <input
                                id='name'
                                className='max'
                                {...register('title', {
                                    required: 'Required',
                                    maxLength: {
                                        value: 360,
                                        message:
                                            'Team name exceeds 360 characters',
                                    },
                                })}
                                maxLength='360'
                                onChange={(e) =>
                                    setCount(e.target.value.length)
                                }
                                autoFocus
                            />
                            <span
                                className={'active char-count'}
                            >{`${count} / 360`}</span>

                            {errors?.title?.message && (
                                <div className='error'>
                                    {errors?.title?.message}
                                </div>
                            )}
                        </div>
                        <div className='group-form avatar'>
                            <label>Logo</label>
                            <AvatarImage
                                image={team?.image}
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className='group-form'>
                            <label>Team Lead(s)</label>
                            <TeamMembersHandler
                                users={teamLeads}
                                onAdd={() => {
                                    setIsTeamMember(false);
                                    dispatchTrigger(true);
                                }}
                                onRemove={(e, id) => {
                                    setTeamLeads(
                                        teamLeads.filter((x) => x.id !== id)
                                    );
                                    setTeamMembers(
                                        teamMembers.filter((x) => x.id !== id)
                                    );
                                }}
                            />
                        </div>

                        <div className='group-form'>
                            <label>Team Member(s)</label>
                            <TeamMembersHandler
                                users={teamMembers?.filter(
                                    (x) =>
                                        !team?.teamLeads?.some(
                                            (y) => y.id === x.id
                                        )
                                )}
                                onAdd={() => {
                                    setIsTeamMember(true);
                                    dispatchTrigger(true);
                                }}
                                onRemove={(e, id) =>
                                    setTeamMembers(
                                        teamMembers.filter((x) => x.id !== id)
                                    )
                                }
                            />
                        </div>
                        <SubmitButton
                            ref={submitRef}
                            loading={form?.loading}
                            success={form?.success}
                            disabled={form?.disabled}
                        >
                            Save
                        </SubmitButton>
                    </form>
                </MainContent>

                <UserSelector
                    title={
                        isTeamMember ? 'Add Team Member(s)' : 'Add Team Lead(s)'
                    }
                    users={users?.filter(
                        (u) =>
                            !(isTeamMember ? teamMembers : teamLeads)?.some(
                                (t) => t.id === u.id
                            )
                    )}
                    open={triggerOpen}
                    onClose={() => dispatchTrigger(null)}
                    onAdd={(users) => handleOnAdd(users)}
                />

                <Modal
                    open={openModal}
                    className='team-modal'
                    onClose={() => setOpenModal(false)}
                >
                    <div className='text'>
                        <h4 className='title'>
                            Are you sure you want to save this team? <br />
                        </h4>
                        <br />
                        <p>
                            A team lead has NOT yet been selected for this team.
                            If any employee voice is received by this team,
                            no-one will be notified. We would recommend ensuring
                            each team has at least one team lead to ensure vital
                            insight is not missed.
                        </p>
                        <br />
                    </div>
                    <div className='buttons'>
                        <button
                            className='btn highlighted'
                            onClick={() => setConfirmation(true)}
                        >
                            Yes
                        </button>
                        <button
                            className='btn'
                            onClick={() => setOpenModal(null)}
                        >
                            No
                        </button>
                    </div>
                </Modal>
            </Page>
        </Fragment>
    );
}
