import { useState } from 'react';
import { Page } from '../../../../shared/layout/Page';
import { CompletedActionsSection } from './components/CompletedActionsSection';
import { OpenActionsSection } from './components/OpenActionsSection';
import './Actions.scss';

export function Actions() {
    const [type, setType] = useState('open');

    return (
        <Page title='Actions' description='' testId='actions'>
            <div className='actions'>
                <div className='tabs'>
                    <div
                        tabIndex={0}
                        role='button'
                        className={type === 'open' ? 'tab active' : 'tab'}
                        onClick={() => {
                            setType('open');
                        }}
                        onKeyPress={(ev) => {
                            ev.key === 'Enter' && setType('open');
                        }}
                        aria-pressed={type === 'open'}
                    >
                        <h4 role='none' aria-label='Open actions'>
                            Open
                        </h4>
                    </div>
                    <div
                        tabIndex={0}
                        role='button'
                        className={type === 'completed' ? 'tab active' : 'tab'}
                        onClick={() => {
                            setType('completed');
                        }}
                        onKeyPress={(ev) => {
                            ev.key === 'Enter' && setType('completed');
                        }}
                        aria-pressed={type === 'completed'}
                    >
                        <h4 role='none' aria-label='Closed actions'>
                            Closed
                        </h4>
                    </div>
                </div>

                {type === 'open' && <OpenActionsSection />}

                {type === 'completed' && <CompletedActionsSection />}
            </div>
        </Page>
    );
}
