import { Fragment, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Modal } from '../components/Modal';
import { Loading } from '../loadings/Loading';
import { PropTypes } from 'prop-types';
import './GenericModal.scss';

export function GenericModal({
    open,
    success,
    message,
    route,
    loading,
    loadingMessage,
    confirmMessage,
    onConfirm,
    onClose,
    className,
}) {
    const [isConfirmed, setIsConfirmed] = useState(false);

    const [canRedirect, setCanRedirect] = useState(false);

    const [delayClose, setDelayClose] = useState(open);

    const [openTimer, setOpenTimer] = useState(0);

    useEffect(() => {
        if (success && route) {
            setTimeout(() => setCanRedirect(true), 3000);
        }

        if (success && !route) {
            setOpenTimer(
                setTimeout(() => {
                    setDelayClose(false);
                    if (onClose) {
                        setTimeout(() => {
                            onClose(), setIsConfirmed(false);
                        }, 200);
                    }
                }, 1500)
            );
        }
    }, [success, open]);

    useEffect(() => {
        if (isConfirmed && onConfirm) {
            onConfirm();
        }

        if (isConfirmed && route) {
            setCanRedirect(true);
        }
    }, [isConfirmed]);

    useEffect(() => {
        if (open) {
            clearTimeout(openTimer);
            setDelayClose(true);
        }
    }, [open]);

    function isLoading() {
        return (loading && !confirmMessage) || (loading && isConfirmed);
    }

    function renderButtons() {
        if (confirmMessage && !isConfirmed) {
            return (
                <div className='text-center'>
                    <button
                        className='btn highlighted modalBtn'
                        onClick={() => setIsConfirmed(true)}
                    >
                        Yes
                    </button>
                    <button
                        className='btn'
                        onClick={() => {
                            setDelayClose(false);
                            setTimeout(() => onClose(false), 500);
                        }}
                    >
                        No
                    </button>
                </div>
            );
        }
    }

    return (
        <Fragment>
            {canRedirect && <Navigate to={route} />}

            <Modal
                className={`generic-modal ${className ?? ''}`}
                open={delayClose}
                fixed={loading}
                onHide={() => {
                    setDelayClose(false);
                    onClose(false);
                }}
            >
                <Fragment>
                    {isLoading() ? (
                        <Fragment> </Fragment>
                    ) : (
                        <i
                            className={`icon-${
                                success ? 'tick' : 'exclamation'
                            }`}
                        >
                            {' '}
                        </i>
                    )}

                    {isLoading() ? (
                        <div className='modal-loading'>
                            <Loading />
                            {!!loadingMessage && (
                                <div
                                    style={{
                                        fontSize: '1.5rem',
                                        padding: '1.5rem',
                                    }}
                                >
                                    {loadingMessage}
                                </div>
                            )}
                        </div>
                    ) : (
                        <p className='text-center'>
                            {confirmMessage && !isConfirmed
                                ? confirmMessage
                                : message}
                        </p>
                    )}

                    {renderButtons()}
                </Fragment>
            </Modal>
        </Fragment>
    );
}

GenericModal.propTypes = {
    open: PropTypes.bool,
    success: PropTypes.bool,
    message: PropTypes.string,
    route: PropTypes.string,
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
    confirmMessage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    className: PropTypes.string,
};
