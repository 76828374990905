import { get, put, post, remove } from '../components/shared/lib/axios';
import pagHelper from '../components/shared/lib/paginationHelper';

export async function getSurveyOverview(surveyId) {
    return get(`/surveys/${surveyId}/overview`);
}

export async function getSurveyRate(surveyId, questionId, iteration) {
    return get(`/api/surveys/${surveyId}/rate`, {
        iteration,
        questionId,
    });
}

export async function getSurveyQuestionComments(
    surveyId,
    questionId,
    teamId,
    iteration,
    take = null,
    offset = null,
    filterText = null,
    sortables = null,
    distributionFilter = null,
    onlyComments = null,
    questionType = null
) {
    let pagination = pagHelper.createQuestionCommentsPagination(
        take,
        offset,
        filterText,
        sortables,
        distributionFilter,
        questionType
    );

    return get(`/api/surveys/${surveyId}/questions/${questionId}/comments`, {
        teamId,
        iteration,
        ...pagination,
        onlyComments,
    });
}

export async function getSurveyRateByTeam(
    surveyId,
    teamId,
    questionId,
    iteration
) {
    return get(`/api/teams/${teamId}/surveys/${surveyId}/rate`, {
        iteration,
        questionId,
    });
}

export async function getHistoricSurveyRate(surveyId, questionId) {
    return get(`/api/surveys/${surveyId}/historic/rate`, {
        questionId,
    });
}

export async function getHistoricSurveyRateByTeam(
    surveyId,
    teamId,
    questionId
) {
    return get(`/api/teams/${teamId}/surveys/${surveyId}/historic/rate`, {
        questionId,
    });
}

export async function getSurvey(surveyId) {
    return get(`/surveys/${surveyId}`);
}

export async function getUserSurvey(userId, surveyId, iteration) {
    return get(`/users/${userId}/surveys/${surveyId}`, {
        iteration: iteration,
    });
}

export async function getSurveyUserQuestions(
    surveyId,
    questionId,
    teamId,
    iteration
) {
    return get(`surveys/${surveyId}/user-questions`, {
        questionId,
        teamId,
        iteration,
    });
}

export async function getQuestion(questionId) {
    return get(`/question/${questionId}`);
}

export async function getQuestionTypes() {
    return get(`/question/type`);
}

export async function getRecurrenceTypes() {
    return get(`/survey/recurrence-types`);
}

export async function getDayOfWeekTypes() {
    return get(`/survey/day-of-week-types`);
}

export async function getSequenceTypes() {
    return get(`/survey/sequence-types`);
}

export async function getMonthTypes() {
    return get(`/survey/month-types`);
}

export async function getSurveyTypes() {
    return get(`/survey/types`);
}

export async function getScheduledSurveys(from, to, orderBy, status, teamId) {
    return get(`/surveys`, {
        from,
        to,
        orderBy,
        status,
        teamId,
    });
}

export async function getDateFilterTypes() {
    return get(`surveys/types/date-filter`);
}

export async function getSurveyUserQuestion(
    surveyId,
    questionId,
    teamId,
    iteration
) {
    return get(`/api/surveys/${surveyId}/user-questions`, {
        teamId,
        iteration,
        questionId,
    });
}

export async function getSurveyQuestionDistribution(
    surveyId,
    questionId,
    teamId,
    iteration
) {
    return get(
        `/api/surveys/${surveyId}/questions/${questionId}/distribution`,
        {
            teamId,
            iteration,
        }
    );
}

export async function getSurveyQuestionSegments(
    surveyId,
    questionId,
    teamId,
    iteration
) {
    return get(`/api/surveys/${surveyId}/questions/${questionId}/segments`, {
        teamId,
        iteration,
    });
}

export async function answerSurvey(req) {
    return post('survey/answer-survey', req);
}

export async function commentSurvey(req, organisationId) {
    return post('surveys/give-user-reaction', req, {
        organisationOrTeamId: organisationId,
    });
}

export async function createSurvey(body) {
    return post('api/survey', body);
}

export async function updateSurvey(body) {
    return put('api/survey', body);
}

export async function calculateIteration(recurrence, startDate, iteration) {
    return post('surveys/calculate-iteration', recurrence, {
        startDate,
        iteration,
    });
}
export async function closeSurvey(id) {
    return put(`survey/${id}/close`);
}

export async function deleteSurvey(id) {
    return remove(`survey/${id}`);
}

export const SURVEY_STATUS_TYPE = {
    Planned: 0,
    Active: 1,
    Closed: 2,
};
