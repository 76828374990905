import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getTeamConditions } from '../../../../../services/conditionService';
import { getTerms, getTeamGoals } from '../../../../../services/goalService';
import { getPPRTopicsParents } from '../../../../../services/pprTopicService';
import { TeamSelector } from '../../../../shared/components/TeamSelector';
import { PulseSelect } from '../../../../shared/inputs/PulseSelect';
import { MainContent } from '../../../../shared/layout/MainContent';
import { Page } from '../../../../shared/layout/Page';
import { useSelectedTeam } from '../../../../shared/lib/useSelectedTeam';
import { Loading } from '../../../../shared/loadings/Loading';
import { AssessTopicBox } from '../../../company/assess/components/AssessTopicBox';
import { BoxBubble } from './components/BoxBubble';
import './MyTeamAssess.scss';

export function MyTeamAssess() {
    const [loading, setLoading] = useState(true);

    const [widgetLoading, setWidgetLoading] = useState(true);

    const [terms, setTerms] = useState([]);

    const [term, setTerm] = useState([]);

    const { team } = useSelectedTeam();

    const [topics, setTopics] = useState([]);

    const [filter, setFilter] = useState({});

    const [redirect, setRedirect] = useState('');

    const [conditions, setConditions] = useState([]);

    const [goals, setGoals] = useState([]);

    const [filteredGoals, setFilteredGoals] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetch() {
            let [top, t] = await Promise.all([
                getPPRTopicsParents(),
                getTerms(),
            ]);

            setTerms(t?.map((x) => ({ ...x, label: x.key })));
            setTerm(t[0].value);

            setTopics(top);

            setLoading(false);
        }

        fetch();
    }, []);

    useEffect(() => {
        async function fetchByTeam() {
            let [con, g] = await Promise.all([
                getTeamConditions(team?.id),
                getTeamGoals(team?.id),
            ]);

            setConditions(con);

            setFilteredGoals(
                g?.filter((x) => x.term === Number(term) && x.status !== 2)
            );
            setGoals(g);
            setWidgetLoading(false);

            navigate('/my-team/assess', { teamId: team?.id, replace: true });
        }

        if (team?.id) {
            fetchByTeam();
        }
    }, [team?.id]);

    function onScoresClick(topic) {
        let auxFilter = {
            StartDate: moment().add(-30, 'days').toISOString(),
            EndDate: moment().toISOString(),
            PPRTopic: topic,
            Team: team?.id,
        };
        setFilter(auxFilter);
        setRedirect('/my-team/assess/ppr-historic');
    }

    function onActionClick(pprTopic) {
        let auxFilter = {
            EndDate: moment().toISOString(),
            ParentTopicId: pprTopic.id,
            StartDate: moment().add(-24, 'months').toISOString(),
            Team: { id: team?.id, Title: team?.name },
        };

        setFilter(auxFilter);
        setRedirect('/my-team/assess/actions');
    }

    function renderRedirect() {
        return redirect ? (
            <Navigate to={redirect} state={filter} />
        ) : (
            <Fragment> </Fragment>
        );
    }

    function handleTerm(t) {
        setFilteredGoals(
            goals.filter((x) => x.status !== 2 && x.term === Number(t))
        );
        setTerm(t);
    }

    return (
        <Page
            className='my-team-assess'
            header={
                <div className='space-between title-margin'>
                    <h1>Assess</h1>
                    <TeamSelector />
                </div>
            }
            loading={loading}
            testId='team-assess'
        >
            <MainContent>
                <div className='assess-boxes-container'>
                    {topics.map((topic, index) => (
                        <AssessTopicBox
                            key={index}
                            topic={topic}
                            actionClick={onActionClick}
                            scoresClick={onScoresClick}
                        />
                    ))}
                </div>
            </MainContent>
            <MainContent
                className='custom-title'
                titleIcon={
                    <div className='title'>
                        <h2 className='main-content-title'>
                            {`Team's picture of success`}
                        </h2>
                        <i className='pulse360-success-icon '></i>
                    </div>
                }
                rightBox={
                    <PulseSelect
                        className='highlighted'
                        label='Term'
                        value={terms.filter((x) => x.value === term)}
                        options={terms}
                        isLoading={terms.length === 0}
                        onChange={(term) => handleTerm(term.value)}
                        isSearchable={false}
                    />
                }
            >
                {widgetLoading ? (
                    <Loading />
                ) : (
                    <div className='conditions-goals'>
                        <div className='gc-container'>
                            {filteredGoals.map((goal, index) => {
                                let labels = [];
                                labels.push({
                                    label: 'From:',
                                    value: goal.user.name,
                                    className: 'bold',
                                });
                                labels.push({
                                    label: 'Term:',
                                    value: terms.find(
                                        (x) => Number(x.value) === goal.term
                                    ).label,
                                    className: 'bubble',
                                });

                                return (
                                    <BoxBubble
                                        image={goal.user.image}
                                        description={goal.description}
                                        labels={labels}
                                        key={`box-goal-${index}`}
                                    />
                                );
                            })}
                            {filteredGoals.length === 2 && (
                                <div className='empty'> </div>
                            )}
                        </div>
                    </div>
                )}
            </MainContent>
            <MainContent
                className='custom-title'
                titleIcon={
                    <div className='title'>
                        <h2 className='main-content-title'>
                            {`Team's conditions`}
                        </h2>
                        <i className='pulse360-icon-conditions '></i>
                    </div>
                }
            >
                {widgetLoading ? (
                    <Loading />
                ) : (
                    <div className='conditions-goals'>
                        <div className='gc-container'>
                            {conditions.map((condition, index) => {
                                let labels = [];
                                labels.push({
                                    label: 'From:',
                                    value: condition.user.name,
                                    className: 'bold',
                                });

                                return (
                                    <BoxBubble
                                        image={condition.user.image}
                                        description={condition.description}
                                        labels={labels}
                                        key={`box-condition-${index}`}
                                    />
                                );
                            })}
                            {conditions.length === 2 && (
                                <div className='empty'> </div>
                            )}
                        </div>
                    </div>
                )}
            </MainContent>
            {renderRedirect()}
        </Page>
    );
}
