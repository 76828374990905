import { get } from '../components/shared/lib/axios';

const controllerRoute = 'widgets';

export async function getWidgetExtremeFeedback(from, to) {
    return get(`${controllerRoute}/extreme-feedback`, { from, to });
}

export async function getWidgetExtremeFeedbackForUser(userId, from, to) {
    return get(`${controllerRoute}/users/${userId}/extreme-feedback`, {
        from,
        to,
    });
}

export async function getWidgetExtremeFeedbackForTeam(teamId, from, to) {
    return get(`${controllerRoute}/teams/${teamId}/extreme-feedback`, {
        from,
        to,
    });
}

export async function getWidgetExtremeFeedbackByEntity(
    from,
    to,
    limit,
    exceptional
) {
    return get(`${controllerRoute}/extreme-feedback/entities`, {
        from,
        to,
        exceptional,
        limit,
    });
}

export async function getWidgetExtremeFeedbackByEntityForTeam(
    teamId,
    from,
    to,
    limit,
    exceptional
) {
    return get(`${controllerRoute}/teams/${teamId}/extreme-feedback/entities`, {
        from,
        to,
        exceptional,
        limit,
    });
}
