import pagHelper from '../components/shared/lib/paginationHelper';
import { get, post, put, remove } from '../components/shared/lib/axios';

export async function getPulseQuestions() {
    return get('/question?categories=pulse');
}

export async function getFeedbackQuestions() {
    return get('/questions?categories=feedback');
}

export async function getFeedbackQuestionsByTopic(topicId) {
    return get(`/questions/?topicId=${topicId}&categories=1`);
}

export async function getQuestion(questionId) {
    return get(`/question/${questionId}`);
}

export async function getQuestionTypes() {
    return get(`/types/question`);
}

export async function getQuestionCategoriesTypes() {
    return get(`/types/question-categories`);
}

export async function createQuestion(question) {
    return post(`/question`, question);
}

export async function updateQuestion(question) {
    return put(`/question`, question);
}

export async function getQuestionsPaginated(offset, limit, filter, orderBy) {
    let pagination = pagHelper.createQuestionPagination(
        offset,
        limit,
        filter,
        orderBy
    );

    return get(`question/paginated`, pagination);
}

export async function deleteQuestion(questionId) {
    return remove(`/question/${questionId}`);
}
