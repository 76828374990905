import * as _ from 'lodash';
import moment from 'moment';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { getUserSurvey } from '../../../../../../services/surveyService';
import { humanizeDiffToday } from '../../../../../shared/lib/dateTimeHelper';
import { useAsyncError } from '../../../../../shared/lib/helper';
import { BoxItem } from './Components/BoxItem';
import { PulseModal } from './Components/PulseModal';
import { UserPhoto } from './Components/UserPhoto';
import { PropTypes } from 'prop-types';
import '../HubItems.scss';

export function PulseItem({ userId, item }) {
    const [survey, setSurvey] = useState(null);

    const [modal, setModal] = useState(false);

    const [hasNotifications, setHasNotifications] = useState(undefined);

    const throwError = useAsyncError();

    const getSurvey = useCallback(async () => {
        try {
            let s = await getUserSurvey(userId, item.id, item.surveyIteration);

            let comments = _.compact(
                _.flatMap(s.surveyQuestions, ({ reactions }) =>
                    _.last(reactions)
                )
            ).sort((x) => moment(x.date).diff(x.date));

            if (comments && comments.length) {
                setHasNotifications(comments[0].actor.id !== userId);
            }

            setSurvey(s);
        } catch (e) {
            throwError(e);
        }
    }, [item]);

    useEffect(() => {
        if (!modal) {
            getSurvey();
        }
    }, [modal]);

    async function onClose() {
        await getSurvey();
        setModal(false);
    }

    return (
        <Fragment>
            <BoxItem
                className='hub-item'
                reviewed={
                    (item.answered && !hasNotifications) ||
                    ((survey?.isExpired || item.isSurveyClosed) &&
                        !(item.answered || survey?.isResponded))
                }
                header={
                    <Fragment>
                        <UserPhoto
                            giver={item.feedbackGiver}
                            receiver={item.feedbackReceiver}
                            receiverAnonymous={
                                item?.feedbackReceiver?.isAnonymous
                            }
                        />

                        <div className='title-date'>
                            <div aria-describedby='Pulse survey'>
                                Pulse survey
                            </div>
                            <p className='small light'>
                                {survey?.isExpired || item.isSurveyClosed
                                    ? 'Expired'
                                    : humanizeDiffToday(survey?.endDate)}
                            </p>
                        </div>
                    </Fragment>
                }
                action={
                    <Fragment>
                        <p
                            className={`description ${
                                (item.answered || survey?.isResponded) &&
                                hasNotifications
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            {(survey?.isExpired || item.isSurveyClosed) &&
                            !(item.answered || survey?.isResponded)
                                ? 'Unanswered'
                                : !(item.answered || survey?.isResponded)
                                ? ''
                                : (item.answered || survey?.isResponded) &&
                                  hasNotifications
                                ? 'Comments received'
                                : 'Response sent'}
                        </p>
                        <button
                            className={`btn ${
                                (survey?.isExpired || item.isSurveyClosed) &&
                                !(item.answered || survey?.isResponded)
                                    ? 'expired'
                                    : !(item.answered || survey?.isResponded) ||
                                      hasNotifications
                                    ? 'highlighted'
                                    : ''
                            }`}
                            onClick={() => (survey ? setModal(true) : void 0)}
                        >
                            {(survey?.isExpired || item.isSurveyClosed) &&
                            !(item.answered || survey?.isResponded)
                                ? 'Expired'
                                : !(item.answered || survey?.isResponded) ||
                                  hasNotifications
                                ? 'Respond'
                                : 'View'}
                        </button>
                    </Fragment>
                }
            >
                <div className='label-section' role='section'>
                    <div className='group'>
                        <div>
                            <label htmlFor='feedback-giver'>
                                From:&nbsp;
                                <span name='feedback-giver' className='bold'>
                                    {item.feedbackGiver?.name}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div
                        className='group'
                        style={{ marginTop: '1rem', width: '100%' }}
                    >
                        <div>
                            <span className='bold'>{item.text}</span>
                        </div>
                    </div>
                </div>
            </BoxItem>
            {!!survey && (
                <PulseModal
                    open={modal}
                    item={item}
                    survey={survey}
                    userId={userId}
                    onClose={(submitted) =>
                        submitted ? onClose() : setModal(false)
                    }
                    onComment={() => getSurvey()}
                />
            )}
        </Fragment>
    );
}

PulseItem.propTypes = {
    userId: PropTypes.string,
    item: PropTypes.object,
};
