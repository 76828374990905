import * as _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
    commentSurvey,
    answerSurvey,
} from '../../../../../../../services/surveyService';
import { Modal } from '../../../../../../shared/components/Modal';
import { StepBar } from '../../../../../../shared/components/StepBar';
import { SubmitButton } from '../../../../../../shared/components/SubmitButton';
import constants from '../../../../../../shared/constants';
import { StyledSlider } from '../../../../../../shared/forms/StyledSlider';
import { Textarea } from '../../../../../../shared/inputs/Textarea';
import { useAsyncError } from '../../../../../../shared/lib/helper';
import { Comments } from './Comments';
import { UserPhoto } from './UserPhoto';
import { renderLikertAnswer } from '../../../../../../shared/lib/helper';
import { PropTypes } from 'prop-types';
import { humanizeDiffToday } from '../../../../../../shared/lib/dateTimeHelper';
import { useClient } from '../../../../../../shared/lib/useClient';
import './HubItemModal.scss';

export function PulseModal({ onClose, item, survey, userId, open, onComment }) {
    const [current, setCurrent] = useState(undefined);

    const [answers, setAnswers] = useState([]);

    const throwError = useAsyncError();

    const [form, setForm] = useState(null);

    const currentIndex = current
        ? survey?.surveyQuestions?.findIndex(
              (x) => x.userQuestionId === current.userQuestionId
          )
        : 0;

    const {
        register,
        handleSubmit,
        control,
        trigger,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    const client = useClient();

    useEffect(() => {
        setCurrent(survey?.surveyQuestions?.[0]);
    }, []);

    useEffect(() => {
        if (current) {
            reset({
                text:
                    answers && answers[currentIndex]
                        ? answers[currentIndex].text
                        : '',
                value:
                    answers && answers[currentIndex]
                        ? answers[currentIndex].value?.toString()
                        : null,
            });
        }
    }, [current]);

    useEffect(() => {
        if (survey && current) {
            setCurrent(survey?.surveyQuestions[currentIndex]);
        }
    }, [survey]);

    async function onSubmit(data) {
        let answer = {
            text: data.text,
            value:
                data?.value === 'true' || data?.value === 'false'
                    ? data?.value
                    : Number(data?.value) ?? null,
            questionId: current.id,
        };

        let auxAns = [...answers];

        auxAns[currentIndex] = answer;

        if (currentIndex === survey?.surveyQuestions?.length - 1) {
            try {
                setForm({ loading: true });
                await answerSurvey({
                    surveyId: survey.surveyId,
                    userTo: survey.to.id,
                    questions: auxAns,
                });
                setForm({ loading: false, success: true });
                onClose(true);
            } catch (e) {
                setForm({ loading: false, success: false });
                throwError(e);
            }
        } else {
            setAnswers(auxAns);

            setCurrent({ ...survey?.surveyQuestions[currentIndex + 1] });
        }
    }

    async function submitComment(comment) {
        try {
            await commentSurvey({
                itemId: current.userQuestionId,
                comment: comment,
            });
            await onComment();
        } catch (e) {
            throwError(e);
        }
    }

    function handlePrevious(data) {
        let answer = {
            text: data.text,
            value: data?.value,
            questionId: current.userQuestionId,
        };

        let auxAns = [...answers];

        auxAns[currentIndex] = answer;

        setAnswers(auxAns);

        setCurrent({ ...survey?.surveyQuestions[currentIndex - 1] });
    }

    return !(survey && current) ? (
        <Fragment> </Fragment>
    ) : (
        <Modal
            className='hub-item-modal'
            open={open}
            onClose={() => onClose(false)}
        >
            <div className='header'>
                <div className='disclaimer'>
                    <div className='disclaimer-icon'>
                        <i
                            className={`icon icon-eye-${
                                survey.to.isAnonymous ? 'anonymous' : 'named'
                            }`}
                        />
                    </div>
                    <div className='disclaimer-text'>
                        Your response(s) to this survey will
                        {survey.to.isAnonymous
                            ? ' be anonymous'
                            : ' have your name attributed to them'}
                    </div>
                </div>
                <div className='header-photo'>
                    <UserPhoto
                        giver={item?.feedbackGiver}
                        receiver={item?.feedbackReceiver}
                        receiverAnonymous={item?.feedbackReceiver?.isAnonymous}
                    />
                    <div>
                        <div>Pulse survey </div>

                        <p className='small light'>
                            {survey.isExpired || survey.isClosed
                                ? 'Expired'
                                : humanizeDiffToday(survey?.endDate)}
                        </p>
                    </div>
                </div>

                <div className='label-section'>
                    <div className='group-row'>
                        <div>
                            <span>From:</span>
                            <span className='bold'>
                                {item.feedbackGiver?.name}
                            </span>
                        </div>
                    </div>
                    <div className='group-row'>
                        <p>
                            <span className='bold' autoFocus={true}>
                                {survey.title}
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <hr role='none' />
            {!!survey?.surveyQuestions &&
                survey?.surveyQuestions?.length > 1 && (
                    <div className='navigation'>
                        <StepBar
                            tabIndex={0}
                            current={currentIndex}
                            steps={survey?.surveyQuestions?.map((x) => x.text)}
                            notifications={survey?.surveyQuestions
                                .filter(
                                    (x) =>
                                        _.last(x.reactions) &&
                                        _.last(x.reactions).actor.id !== userId
                                )
                                .map((x) =>
                                    survey?.surveyQuestions?.findIndex(
                                        (q) =>
                                            q.userQuestionId ===
                                            x.userQuestionId
                                    )
                                )}
                            next={
                                currentIndex <
                                survey?.surveyQuestions?.length - 1
                            }
                            previous={currentIndex > 0}
                            onNext={async () => {
                                if (await trigger()) {
                                    await onSubmit(getValues());
                                }
                            }}
                            onPrevious={() => handlePrevious(getValues())}
                            onCirclePress={(index) =>
                                survey.isResponded
                                    ? setCurrent({
                                          ...survey?.surveyQuestions[index],
                                      })
                                    : null
                            }
                        />
                    </div>
                )}

            <h4 id='modal-text' role='none'>
                {current.text}
            </h4>

            {!survey.isResponded && !survey.isExpired && !survey.isClosed && (
                <div>
                    <form
                        className='pulse-form answer-survey'
                        onSubmit={handleSubmit(onSubmit)}
                        role='form'
                    >
                        {current.type === constants.QuestionType.Score && (
                            <div
                                className='group'
                                style={{ marginRight: '1.5rem' }}
                                role='region'
                                aria-labelledby='region-heading'
                            >
                                <h4 id='region-heading'>Give your score</h4>

                                <Controller
                                    control={control}
                                    name='value'
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Score is required',
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Fragment key={currentIndex}>
                                            <StyledSlider
                                                role='slider'
                                                {...field}
                                                marks={[
                                                    { value: 1, label: '1' },
                                                    { value: 2, label: '2' },
                                                    { value: 3, label: '3' },
                                                    { value: 4, label: '4' },
                                                    { value: 5, label: '5' },
                                                    { value: 6, label: '6' },
                                                    { value: 7, label: '7' },
                                                    { value: 8, label: '8' },
                                                    { value: 9, label: '9' },
                                                    { value: 10, label: '10' },
                                                ]}
                                                style={{
                                                    marginRight: '1rem',
                                                    marginBottom: '2rem',
                                                    marginLeft: '0.5rem',
                                                }}
                                                step={1}
                                                min={1}
                                                max={10}
                                            />
                                        </Fragment>
                                    )}
                                />

                                <p className='error'>
                                    {errors?.value && errors?.value.message}
                                </p>
                            </div>
                        )}

                        {current.type === constants.QuestionType.YesNo && (
                            <div
                                role='radiogroup'
                                className='group'
                                key={currentIndex}
                            >
                                <div className='pulse360-radio-container'>
                                    <div className='pulse360-radio'>
                                        <input
                                            aria-label='Yes'
                                            aria-labelledby='radioButton-yes'
                                            aria-invalid={
                                                errors?.value &&
                                                errors?.value.message
                                                    ? true
                                                    : false
                                            }
                                            aria-describedby='error'
                                            type='radio'
                                            value={true}
                                            {...register('value', {
                                                required: 'Choose one option.',
                                            })}
                                        />
                                        <label id='radioButton-yes'>Yes</label>
                                    </div>
                                    <div className='pulse360-radio'>
                                        <input
                                            aria-label='No'
                                            aria-labelledby='radioButton-no'
                                            type='radio'
                                            value={false}
                                            {...register('value', {
                                                required: 'Choose one option.',
                                            })}
                                        />
                                        <label id='radioButton-no'>No</label>
                                    </div>
                                </div>

                                <p className='error'>
                                    {errors?.value && errors?.value.message}
                                </p>
                            </div>
                        )}

                        {current.type === constants.QuestionType.Likert && (
                            <div
                                role='radiogroup'
                                className='group likert-radio'
                                key={currentIndex}
                            >
                                <div className='pulse360-radio-container'>
                                    <div className='pulse360-radio'>
                                        <input
                                            aria-label='Strongly Disagree'
                                            aria-labelledby='radioButton-strongly-disagree'
                                            type='radio'
                                            value={-1}
                                            {...register('value', {
                                                required: 'Choose one option.',
                                            })}
                                        />
                                        <label id='radioButton-strongly-disagree'>
                                            Strongly Disagree
                                        </label>
                                    </div>
                                    <div className='pulse360-radio'>
                                        <input
                                            aria-label='Disagree'
                                            aria-labelledby='radioButton-disagree'
                                            type='radio'
                                            value={-2}
                                            {...register('value', {
                                                required: 'Choose one option.',
                                            })}
                                        />
                                        <label id='radioButton-disagree'>
                                            Disagree
                                        </label>
                                    </div>
                                    <div className='pulse360-radio'>
                                        <input
                                            aria-label='Neutral'
                                            aria-labelledby='radioButton-neutral'
                                            type='radio'
                                            value={-3}
                                            {...register('value', {
                                                required: 'Choose one option.',
                                            })}
                                        />
                                        <label id='radioButton-neutral'>
                                            Neutral
                                        </label>
                                    </div>
                                    <div className='pulse360-radio'>
                                        <input
                                            aria-label='Agree'
                                            aria-labelledby='radioButton-agree'
                                            type='radio'
                                            value={-4}
                                            {...register('value', {
                                                required: 'Choose one option.',
                                            })}
                                        />
                                        <label id='radioButton-agree'>
                                            Agree
                                        </label>
                                    </div>
                                    <div className='pulse360-radio'>
                                        <input
                                            aria-label='Strongly Agree'
                                            aria-labelledby='radioButton-strongly-agree'
                                            type='radio'
                                            value={-5}
                                            {...register('value', {
                                                required: 'Choose one option.',
                                            })}
                                        />
                                        <label id='radioButton-strongly-agree'>
                                            Strongly Agree
                                        </label>
                                    </div>
                                </div>

                                <p className='error'>
                                    {errors?.value && errors?.value.message}
                                </p>
                            </div>
                        )}

                        {current.type === constants.QuestionType.Choice && (
                            <div
                                role='radiogroup'
                                className='group choice-radio'
                                key={currentIndex}
                            >
                                {current.choices?.map((c) => (
                                    <div key={c.key} className='pulse360-radio'>
                                        <input
                                            aria-label={`Option ${c.key - 10}`}
                                            aria-labelledby={`radioButton-${
                                                c.key - 10
                                            }`}
                                            type='radio'
                                            value={c.key}
                                            {...register('value', {
                                                required: 'Choose one option.',
                                            })}
                                        />
                                        <label id={`radioButton-${c.key - 10}`}>
                                            {c.value}
                                        </label>
                                    </div>
                                ))}

                                <p className='error'>
                                    {errors?.value && errors?.value.message}
                                </p>
                            </div>
                        )}

                        <div className='group'>
                            <Controller
                                control={control}
                                name='text'
                                rules={{
                                    required: {
                                        value:
                                            client?.surveyConfiguration
                                                ?.isCommentRequired ||
                                            current.type ===
                                                constants.QuestionType
                                                    .Suggestion,
                                        message: 'Answer is required',
                                    },
                                    maxLength: {
                                        value: constants.maxLength,
                                        message: '',
                                    },
                                    validate: (value) => {
                                        const commentOptional =
                                            !client?.surveyConfiguration
                                                ?.isCommentRequired &&
                                            current.type !==
                                                constants.QuestionType
                                                    .Suggestion;
                                        return (
                                            commentOptional ||
                                            !!value.trim() ||
                                            "Answer can't be empty"
                                        );
                                    },
                                }}
                                render={({ field }) => (
                                    <Textarea
                                        {...field}
                                        rows={3}
                                        maxLength={constants.maxLength}
                                        aria-required='true'
                                        aria-invalid={errors?.text}
                                        aria-describedby={errors?.text?.message}
                                        aria-label={
                                            'Add a comment.' + current.text
                                        }
                                        placeholder={`Add a comment ${
                                            !client?.surveyConfiguration
                                                ?.isCommentRequired &&
                                            current.type !==
                                                constants.QuestionType
                                                    .Suggestion
                                                ? '(optional)'
                                                : '(required)'
                                        }`}
                                        role='textbox'
                                        focusOnRender={false}
                                    />
                                )}
                            />
                            <p className='error'>
                                {errors?.text && errors?.text.message}
                            </p>
                        </div>

                        <div
                            className={`submit-button  ${
                                currentIndex > 0 ? 'space-between' : 'right'
                            }`}
                        >
                            {currentIndex > 0 && (
                                <div
                                    aria-label='Previous question'
                                    className='btn icon'
                                    onClick={() => handlePrevious(getValues())}
                                >
                                    <i className='icon icon-arrow-left'> </i>
                                    Previous
                                </div>
                            )}
                            <SubmitButton
                                loading={form?.loading}
                                success={form?.success}
                                className={`${
                                    currentIndex <
                                    survey?.surveyQuestions?.length - 1
                                        ? 'icon'
                                        : ''
                                }`}
                            >
                                {currentIndex ===
                                survey?.surveyQuestions?.length - 1 ? (
                                    <Fragment>Send </Fragment>
                                ) : (
                                    <Fragment>
                                        Next
                                        <i
                                            aria-label='Next question'
                                            className='icon icon-arrow-right'
                                        >
                                            {' '}
                                        </i>
                                    </Fragment>
                                )}
                            </SubmitButton>
                        </div>
                    </form>
                </div>
            )}

            {survey.isResponded && (
                <div>
                    {current.type === constants.QuestionType.Score && (
                        <div
                            className='group'
                            style={{ marginRight: '1.5rem' }}
                            role='region'
                            aria-labelledby='region-heading'
                        >
                            <h4 id='region-heading'>Your given score</h4>
                            <StyledSlider
                                role='slider'
                                style={{
                                    marginRight: '1rem',
                                    marginBottom: '2rem',
                                    cursor: 'not-allowed',
                                }}
                                min={1}
                                max={10}
                                marks={[
                                    { value: 1, label: '1' },
                                    { value: 2, label: '2' },
                                    { value: 3, label: '3' },
                                    { value: 4, label: '4' },
                                    { value: 5, label: '5' },
                                    { value: 6, label: '6' },
                                    { value: 7, label: '7' },
                                    { value: 8, label: '8' },
                                    { value: 9, label: '9' },
                                    { value: 10, label: '10' },
                                ]}
                                value={current.value}
                                disabled={true}
                            />
                        </div>
                    )}

                    {current.type === constants.QuestionType.YesNo && (
                        <div
                            role='comment'
                            aria-label={`Your answer for the question is: ${
                                current.value ? 'Yes' : 'No'
                            }.`}
                            className='pulse360-radio-container'
                        >
                            <h3 aria-label={current.value ? 'Yes' : 'No'}>
                                {current.value ? 'Yes' : 'No'}
                            </h3>
                        </div>
                    )}

                    {current.type === constants.QuestionType.Likert && (
                        <div
                            role='comment'
                            aria-label={`Your answer for the question is: ${renderLikertAnswer(
                                current.value
                            )}.`}
                            className='pulse360-radio-container'
                        >
                            <h3 aria-label={renderLikertAnswer(current.value)}>
                                {renderLikertAnswer(current.value)}
                            </h3>
                        </div>
                    )}

                    {current.type === constants.QuestionType.Choice && (
                        <div
                            role='comment'
                            aria-label={`Your answer for the question is: ${
                                current.choices?.find(
                                    (c) => c.key === current.value
                                )?.value
                            }.`}
                            className='pulse360-radio-container'
                        >
                            <h3
                                aria-label={
                                    current.choices?.find(
                                        (c) => c.key === current.value
                                    )?.value
                                }
                            >
                                {
                                    current.choices?.find(
                                        (c) => c.key === current.value
                                    )?.value
                                }
                            </h3>
                        </div>
                    )}

                    <h4 aria-label='Comment'>Comment:</h4>

                    <p style={{ marginBottom: '2rem' }}>
                        {current.answer === '' ? (
                            <i style={{ color: '#757575' }}>No comment added</i>
                        ) : (
                            current.answer
                        )}
                    </p>
                </div>
            )}

            {!!current.reactions && !!current.reactions.length && (
                <Fragment>
                    <hr />
                    <Comments
                        limited={true}
                        reactions={current.reactions}
                        user={survey.user}
                        onComment={submitComment}
                        isAnonymous={survey.to.isAnonymous}
                    />
                </Fragment>
            )}
        </Modal>
    );
}

PulseModal.propTypes = {
    onClose: PropTypes.func,
    item: PropTypes.object,
    survey: PropTypes.object,
    userId: PropTypes.string,
    open: PropTypes.bool,
    onComment: PropTypes.func,
};
