import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../constants';
import { Loading } from '../../loadings/Loading';
import { LikertDistributionLegend } from './charts/distribution/LikertDistributionChart';
import { QuestionType } from './SurveyBox';
import { useIterationQuestionData } from './useIterationQuestionData';
import { PropTypes } from 'prop-types';
import { QuestionCardRecurring } from './QuestionCardRecurring';
import { QuestionCardOneOff } from './QuestionCardOneOff';
import './components.scss';

export function QuestionCard({ survey, question, teamId, iteration }) {
    const navigate = useNavigate();

    const iterations = useIterationQuestionData(survey, question, teamId);

    return (
        <div
            className='question-bar'
            onClick={() =>
                navigate(
                    teamId
                        ? `/my-team/feedback/pulse/survey-overview/${
                              survey?.id
                          }/team/${teamId}/question/${question?.id}/${
                              iteration ?? 0
                          }`
                        : `/company/feedback/pulse/survey-overview/${
                              survey?.id
                          }/question/${question?.id}/${iteration ?? 0}`
                )
            }
            onKeyPress={(e) =>
                e.key === 'Enter' &&
                navigate(
                    teamId
                        ? `/my-team/feedback/pulse/survey-overview/${
                              survey?.id
                          }/team/${teamId}/question/${question?.id}/${
                              iteration ?? 0
                          }`
                        : `/company/feedback/pulse/survey-overview/${
                              survey?.id
                          }/question/${question?.id}/${iteration ?? 0}`
                )
            }
            tabIndex={0}
        >
            {!iterations ? (
                <Loading />
            ) : (
                <Fragment>
                    <div className='title'>
                        <div>
                            <h4>{question?.questionText}</h4>
                            <QuestionType type={question?.questionType} />
                        </div>
                        <div className='topic-type'>
                            <div className='topic-bubble'>
                                <i className='icon icon-topic'></i>
                                {question.topic.title}
                            </div>
                        </div>
                    </div>

                    {survey?.surveyType === constants.SurveyType.Recurring ? (
                        <QuestionCardRecurring
                            question={question}
                            iterations={iterations}
                            iteration={iteration}
                            survey={survey}
                            teamId={teamId}
                        />
                    ) : (
                        <QuestionCardOneOff
                            question={question}
                            iterations={iterations}
                            iteration={iteration}
                            survey={survey}
                            teamId={teamId}
                        />
                    )}
                </Fragment>
            )}
            {question?.questionType === constants.QuestionType.Likert && (
                <LikertDistributionLegend />
            )}
        </div>
    );
}

QuestionCard.propTypes = {
    survey: PropTypes.object,
    question: PropTypes.object,
    teamId: PropTypes.string,
    iteration: PropTypes.number,
};
