import { Fragment, useEffect, useRef, useState } from 'react';
import { useRecaptcha } from '../../../shared/lib/useRecaptcha';
import { Loading } from '../../../shared/loadings/Loading';
import { HomePageTemplate } from '../../../shared/templates/HomePageTemplate';
import ReactTooltip from 'react-tooltip';
import helper from '../../../shared/lib/helper';
import { validateInvitation } from '../../../../services/authenticationService';

export function ValidateInvitation() {
    const [validationCode, setValidationCode] = useState('');
    const [invitationId, setInvitationId] = useState('');
    const [error, setError] = useState('');
    const [tenantId, setTenantId] = useState('');
    const [loading, setLoading] = useState(true);
    const inputRef = useRef(null);

    const onRecaptcha = useRecaptcha();

    useEffect(() => {
        setTenantId(helper.getParameterByName('tenantId'));
        setInvitationId(helper.getParameterByName('invitationId'));
        setValidationCode(helper.getParameterByName('code'));
        setError(helper.getParameterByName('error'));
        setLoading(false);
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();

        onRecaptcha(() =>
            validateInvitation(validationCode, invitationId, tenantId)
        );
    }

    return (
        <Fragment>
            {loading && <Loading className='login-loading' />}
            <HomePageTemplate className='login'>
                <form onSubmit={handleSubmit}>
                    <div className='header-logo'>
                        <img
                            className='home-page-logo'
                            alt='main logo'
                            src='logo.svg'
                        />
                        <h4>
                            Pulse 360 helps organisations, and their employees,
                            be the best they can be.
                        </h4>
                    </div>
                    <input
                        ref={inputRef}
                        data-tip='tooltip'
                        type='text'
                        placeholder='Validation code'
                        onChange={(e) => setValidationCode(e.target.value)}
                        value={validationCode}
                    />
                    <a
                        className='home-page-login-link'
                        href='mailto:support@pulse360.io'
                    >
                        Problem signing up?
                    </a>
                    <ReactTooltip
                        place='right'
                        effect='solid'
                        type='error'
                        event='whatever'
                    >
                        <span>{error}</span>
                    </ReactTooltip>
                    <button
                        type='submit'
                        className='btn highlighted'
                        disabled={!validationCode}
                    >
                        SIGN UP
                    </button>
                </form>
            </HomePageTemplate>
        </Fragment>
    );
}
