import _uniqueId from 'lodash/uniqueId';
import { useCallback, useRef, useState } from 'react';
import { ReactComponent as Heart } from '../../../assets/images/heart.svg';
import { ReactComponent as Insightful } from '../../../assets/images/insightful.svg';
import { ReactComponent as Like } from '../../../assets/images/like.svg';
import { useWindowEvent } from '../lib/useWindowEvent';
import { PropTypes } from 'prop-types';
import './components.scss';

export function EmojiRating({ ratings, onChange, onClose }) {
    const wrapperRef = useRef(null);
    const [id] = useState(_uniqueId('emoji-'));

    const [open, setOpen] = useState(false);

    useWindowEvent(
        'mousedown',
        useCallback(
            (event) => {
                if (event.target.closest(`#${id}`) || !open) {
                    return;
                } else if (typeof onClose === 'function') {
                    setOpen(false);
                    onClose();
                }
            },
            [open, onClose]
        )
    );

    function getRatingEmoji(value, key) {
        switch (value) {
            case 'like':
                return <Like key={key} />;
            case 'heart':
                return (
                    <Heart
                        key={key}
                        textAnchor='middle'
                        alignmentBaseline='middle'
                    />
                );
            case 'insightful':
                return <Insightful key={key} />;

            default:
                return null;
        }
    }
    return (
        <div className='emoji-rating'>
            <div onClick={() => setOpen(true)} className='open-icon'>
                <Like />
                <h5>React</h5>
            </div>
            <div
                ref={wrapperRef}
                id={id}
                className={`selector ${open ? 'active' : ''}`}
            >
                {ratings
                    ?.sort((x, y) => x.index - y.index)
                    .map((rating) => {
                        return (
                            <div
                                key={`rating-emoji${rating.id}`}
                                className='item'
                                onClick={() => onChange(rating)}
                                title={rating.description}
                            >
                                {getRatingEmoji(
                                    rating.value,
                                    `emoji${rating.id}`
                                )}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}

EmojiRating.propTypes = {
    ratings: PropTypes.array,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
};

export function Emoji({ value, description }) {
    return (
        <div className='emoji-display'>
            {value === 'like' && <Like />}

            {value === 'heart' && <Heart />}

            {value === 'insightful' && <Insightful />}

            <h5>{description}</h5>
        </div>
    );
}

Emoji.propTypes = {
    value: PropTypes.string,
    description: PropTypes.string,
};
