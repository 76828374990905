import { get } from '../components/shared/lib/axios';

var controllerRoute = 'authentication';

function redirect(route) {
    window.location = `${window.location.origin}/${route}`;
}

function redirectWithClear(route) {
    sessionStorage.clear();
    redirect(route);
}

export async function getAuthentication() {
    let route = `${controllerRoute}/`;
    return get(route);
}

export async function getProviders() {
    let route = `${controllerRoute}/providers`;
    return get(route);
}

export function signIn(email, url) {
    let queryString = '';
    if (url) {
        queryString = `&url=${url}`;
    }
    redirectWithClear(
        `api/Authentication/sign-in?email=${email}${queryString}`
    );
}

export function trySignIn() {
    redirectWithClear(
        `api/Authentication/try-sign-in?url=${window.location.pathname}`
    );
}

export function signOut() {
    redirectWithClear(`api/Authentication/sign-out`);
}

export function validateInvitation(code, invitationId, tenantId) {
    redirectWithClear(
        `api/Authentication/validate-invitation?code=${code}&invitationId=${invitationId}&tenantId=${tenantId}`
    );
}
