import { get, post } from '../components/shared/lib/axios';

export async function getActorType() {
    return get(`types/actor-type`);
}

export async function getQuestionType() {
    return get(`types/question-types`);
}

export async function verifyRecaptcha(token) {
    return post(`recaptcha/verify`, { token });
}
