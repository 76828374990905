import moment from 'moment';
import Slider from 'rc-slider';
import { Fragment, useEffect, useRef, useState } from 'react';
import {
    getFeedback,
    feedbackGive,
} from '../../../../../../../services/feedbackService';
import { Modal } from '../../../../../../shared/components/Modal';
import constants from '../../../../../../shared/constants';
import { Textarea } from '../../../../../../shared/inputs/Textarea';
import { useAsyncError } from '../../../../../../shared/lib/helper';
import { Loading } from '../../../../../../shared/loadings/Loading';
import { UserPhoto } from './UserPhoto';
import { PropTypes } from 'prop-types';
import './FeedbackRequestModal.scss';

export function FeedbackRequestModal({
    feedbackId,
    onClosedModal,
    onAnswer,
    open,
}) {
    const [feedback, setFeedback] = useState(null);

    const [score, setScore] = useState(null);

    const [yesNoRadio, setYesNoRadio] = useState(null);

    const [disabledSubmit, setDisabledSubmit] = useState(true);

    const [comment, setComment] = useState('');

    const submitRef = useRef(null);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchData() {
            try {
                var feed = await getFeedback(feedbackId);

                setFeedback(feed);
            } catch (error) {
                throwError(error);
            }
        }
        if (feedback === null) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        if (feedback) {
            let type = feedback.givenFeedback.type;

            setDisabledSubmit(
                (type === 1 && typeof score !== 'number') ||
                    (type === 2 && typeof yesNoRadio !== 'boolean') ||
                    comment.trim().length === 0
            );
        }
    }, [score, yesNoRadio, comment]);

    async function giveFeedback(e) {
        e.preventDefault();

        if (!disabledSubmit) {
            let givenFeedback = {
                ...feedback.givenFeedback,
                value: typeof score === 'number' ? score : yesNoRadio,
                feedback: comment,
            };
            let body = {
                Id: feedback.id,
                Answer: comment,
                IsOrganization: false,
                Value: typeof score === 'number' ? score : yesNoRadio,
                FeedbackGiver: feedback.feedbackGiver,
                FeedbackReceiver: feedback.feedbackReceiver,
            };

            if (submitRef.current) {
                submitRef.current.disabled = true;
            }

            try {
                await feedbackGive(body, feedback.feedbackReceiver);
            } catch (error) {
                throwError(error);
            } finally {
                onClosedModal();
                onAnswer({ ...feedback, givenFeedback: givenFeedback });
            }
        }
    }

    return (
        <Modal
            className='feedback-request-modal'
            open={open}
            onClose={onClosedModal}
            center
        >
            {feedback != null ? (
                <form onSubmit={giveFeedback}>
                    <div className='surveyBoxTop'>
                        <div className='surveyBoxTopImage'>
                            <UserPhoto giver={feedback.feedbackReceiver} />
                        </div>
                        <div className='surveyBoxTopData'>
                            <div className='surveyBoxTopDataName'>
                                {feedback.feedbackReceiver.name}
                            </div>
                            <div className='surveyBoxTopDataCreatedDate'>
                                {moment(feedback.created).format(
                                    'MMM DD [at] HH:mm'
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='surveyBoxQuestion'>
                            <div className='surveyBoxTitle'>
                                <span>{feedback.givenFeedback.question}</span>
                            </div>
                            {feedback.givenFeedback.type == 1 ? (
                                <div>
                                    <div className='surveyBoxTitle'>
                                        <span>Give your score</span>
                                    </div>
                                    <div className='sliderAnswer'>
                                        <Slider
                                            required
                                            min={1}
                                            max={10}
                                            marks={{
                                                1: '1',
                                                2: '2',
                                                3: '3',
                                                4: '4',
                                                5: '5',
                                                6: '6',
                                                7: '7',
                                                8: '8',
                                                9: '9',
                                                10: '10',
                                            }}
                                            value={score}
                                            onChange={(value) =>
                                                setScore(value)
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                feedback.givenFeedback.type == 2 && (
                                    <div className='yesNoAnswers'>
                                        <Fragment>
                                            <div className='pulse360-radio'>
                                                <input
                                                    type='radio'
                                                    value='Yes'
                                                    checked={
                                                        yesNoRadio === null
                                                            ? undefined
                                                            : yesNoRadio
                                                    }
                                                    onChange={(e) =>
                                                        setYesNoRadio(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                                <label>Yes</label>
                                            </div>

                                            <div className='pulse360-radio'>
                                                <input
                                                    type='radio'
                                                    value='No'
                                                    checked={
                                                        yesNoRadio === null
                                                            ? undefined
                                                            : !yesNoRadio
                                                    }
                                                    onChange={(e) =>
                                                        setYesNoRadio(
                                                            !e.target.checked
                                                        )
                                                    }
                                                />
                                                <label>No</label>
                                            </div>
                                        </Fragment>
                                    </div>
                                )
                            )}
                        </div>
                        <div>
                            <div className='surveyBoxComments'>
                                <Textarea
                                    required={true}
                                    rows={5}
                                    placeholder='Write a comment...'
                                    maxLength={constants.maxLength}
                                    onChange={(e) =>
                                        setComment(e.target.value.trim())
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className='surveyBoxButton'>
                        <button
                            ref={submitRef}
                            disabled={disabledSubmit}
                            name='surveysRedirect'
                            className='btn highlighted margin-left-survey'
                        >
                            Respond
                        </button>
                    </div>
                </form>
            ) : (
                <Loading />
            )}
        </Modal>
    );
}

FeedbackRequestModal.propTypes = {
    feedbackId: PropTypes.string,
    onClosedModal: PropTypes.func,
    onAnswer: PropTypes.func,
    open: PropTypes.bool,
};
