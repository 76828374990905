import { get, post, remove, put } from '../components/shared/lib/axios';

export async function searchAction(filter, limit, offset) {
    return post(
        `/api/actions/search?limit=${limit}&offset=${offset + 1}`,
        filter
    );
}

export async function shareAction(actionId, users, teams) {
    return put(`/api/actions/${actionId}/share`, { users, teams });
}

export async function getPriorityTypes() {
    return get(`types/actions/priority`);
}

export async function getAction(actionId) {
    return get(`actions/${actionId}`);
}

export async function getSharedAction(actionId) {
    return get(`shared-action/${actionId}`);
}

export async function getUserActions(userId, filter) {
    let filterQuery =
        filter !== null && filter !== undefined ? `?filter=${filter}` : '';

    return get(`/users/${userId}/actions${filterQuery}`);
}

export async function updateAction(req) {
    return put(`actions`, req);
}

export async function commentAction(req) {
    return put(`actions/comment`, req);
}

export async function reviewAction(actionId) {
    return put(`actions/${actionId}/review`);
}

export async function completeAction(actionId) {
    return put(`actions/${actionId}/complete`);
}

export async function createAction(req) {
    return post(`actions`, req);
}

export async function deleteAction(actionId) {
    return remove(`actions/${actionId}`);
}
