import { Fragment, useEffect, useState, useMemo } from 'react';
import { getPulseQuestions } from '../../../../../services/questionService';
import { Modal } from '../../../../shared/components/Modal';
import { AddButton } from '../../../../shared/inputs/AddButton';
import { SearchInput } from '../../../../shared/inputs/SearchInput';
import { useAsyncError } from '../../../../shared/lib/helper';
import { QuestionType } from '../../../../shared/surveys/components/SurveyBox';
import { PropTypes } from 'prop-types';
import { Table } from '../../../../shared/components/Table';
import { Question } from '../../questions/Question';
import './QuestionSelectModal.scss';

export function QuestionSelectModal({ onChange, show, onClose, questionIds }) {
    const [questions, setQuestions] = useState([]);

    const [display, setDisplay] = useState(null);

    const [loading, setLoading] = useState(true);

    const [selectedQuestions, setSelectedQuestions] = useState([]);

    const [showQuestionForm, setShowQuestionForm] = useState(false);

    const throwError = useAsyncError();

    useEffect(() => {
        async function fetchData() {
            try {
                let lQuestions = await getPulseQuestions();

                setQuestions(
                    lQuestions.sort((a, b) =>
                        a.questionText.toLowerCase() >
                        b.questionText.toLowerCase()
                            ? 1
                            : -1
                    )
                );

                setDisplay(lQuestions);
                setLoading(false);
            } catch (e) {
                throwError(new Error(e));
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        setSelectedQuestions([
            ...questions.filter((x) => questionIds?.some((y) => y === x.id)),
        ]);
    }, [questionIds]);

    function onSearch(titleSearch) {
        if (display) {
            setDisplay(
                questions.filter(
                    (x) =>
                        x.questionText
                            .toUpperCase()
                            .includes(titleSearch?.toUpperCase()) ||
                        x.topic.title
                            .toUpperCase()
                            .includes(titleSearch?.toUpperCase())
                )
            );
        }
    }

    function handleSelectedQuestion(question, checked) {
        if (checked && selectedQuestions.length < 5) {
            setSelectedQuestions([...selectedQuestions, question]);
        } else if (!checked) {
            setSelectedQuestions([
                ...selectedQuestions.filter((x) => x.id !== question.id),
            ]);
        }
    }

    const customSort = useMemo(
        () => (a, b) => {
            var textA = a.original.questionText
                ? a.original.questionText?.toLowerCase()
                : '';
            var textB = b.original.questionText
                ? b.original.questionText.toLowerCase()
                : '';

            return textA > textB ? 1 : -1;
        },
        []
    );

    return (
        <Fragment>
            <Modal
                className='question-select-modal'
                open={show}
                onClose={onClose}
                padding={showQuestionForm}
            >
                <div className='title-searchbox-container'>
                    <span className='main-title'>
                        Select questions (up to 5)
                    </span>
                    <SearchInput
                        onSearch={onSearch}
                        placeholder={'Question or topic'}
                        autoFocus
                    />
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <AddButton
                        onClick={() =>
                            setShowQuestionForm((prevState) => !prevState)
                        }
                    >
                        Create Question
                    </AddButton>
                </div>
                {showQuestionForm && (
                    <Question
                        questionModal
                        setSelectedQuestions={setSelectedQuestions}
                        setQuestions={setQuestions}
                        setDisplay={setDisplay}
                        setShowQuestionForm={setShowQuestionForm}
                    />
                )}

                <Table
                    className='question-select'
                    data={display ? display : []}
                    loading={loading}
                    noDataText='No current pulse questions'
                    onSortedChange={() => {
                        let element = display?.find((x) => x.newest);
                        if (element) {
                            delete element.newest;
                        }
                    }}
                    columns={[
                        {
                            Header: '',
                            accessor: (d) => d,
                            id: 'user',
                            headerClassName: 'buttons',
                            className: 'buttons',
                            width: 55,
                            disableSortBy: true,
                            Cell: (row) => (
                                <Fragment>
                                    <label
                                        className='pulse360-checkbox tick'
                                        style={{ height: '2rem' }}
                                    >
                                        <input
                                            type='checkbox'
                                            checked={
                                                !!selectedQuestions.find(
                                                    (x) => x.id === row.value.id
                                                )
                                            }
                                            onChange={(ev) =>
                                                handleSelectedQuestion(
                                                    row.value,
                                                    ev.target.checked
                                                )
                                            }
                                        />
                                        <span></span>
                                    </label>
                                </Fragment>
                            ),
                        },
                        {
                            Header: 'Question',
                            id: 'questionText',
                            accessor: (d) => d,
                            sortDescFirst: true,
                            sortType: customSort,
                            Cell: (row) => (
                                <div
                                    title={row.value.questionText}
                                    key='textarea'
                                >
                                    {row.value.questionText}
                                </div>
                            ),
                        },
                        {
                            Header: 'Topic',
                            width: 180,
                            id: 'topic.title',
                            accessor: (d) => d,
                            className: 'overflow',
                            sortDescFirst: true,
                            sortType: useMemo(
                                () => (a, b) => {
                                    return a.original.topic.title.toLowerCase() >
                                        b.original.topic.title.toLowerCase()
                                        ? 1
                                        : -1;
                                },
                                []
                            ),
                            Cell: (row) => (
                                <div title={row.value.topic.title}>
                                    {row.value.topic.title}
                                </div>
                            ),
                        },
                        {
                            Header: 'Question type',
                            width: 150,
                            id: 'questionType',
                            accessor: (d) => d,
                            className: 'text-center overflow',
                            sortDescFirst: true,
                            sortType: useMemo(
                                () => (a, b) => {
                                    return a.original.questionType >
                                        b.original.questionType
                                        ? 1
                                        : -1;
                                },
                                []
                            ),
                            Cell: (row) => (
                                <Fragment>
                                    <QuestionType
                                        type={row.value.questionType}
                                    />
                                </Fragment>
                            ),
                        },
                    ]}
                    size={display?.length}
                />

                <div
                    style={{
                        textAlign: 'right',
                        marginTop: '1rem',
                    }}
                >
                    <button
                        type='button'
                        className='btn highlighted'
                        onClick={() => onChange([...selectedQuestions])}
                    >
                        Select
                    </button>
                </div>
            </Modal>
        </Fragment>
    );
}

QuestionSelectModal.propTypes = {
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    show: PropTypes.bool,
    questionIds: PropTypes.array,
};
