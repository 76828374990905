import { get } from '../components/shared/lib/axios';

const controllerRoute = 'widgets';

export async function getWidgetFeedbackAverageScore(from, to) {
    return get(`${controllerRoute}/average-score`, { from, to });
}

export async function getWidgetFeedbackAverageScoreForUser(
    userId,
    from,
    to,
    received
) {
    return get(`${controllerRoute}/users/${userId}/average-score`, {
        from,
        to,
        received,
    });
}

export async function getWidgetFeedbackAverageScoreForTeam(
    teamId,
    from,
    to,
    received
) {
    return get(`${controllerRoute}/teams/${teamId}/average-score`, {
        from,
        to,
        received,
    });
}

export async function getWidgetFeedbackAverageScoreByEntity(
    from,
    to,
    received,
    limit
) {
    return get(`${controllerRoute}/average-score/entities`, {
        from,
        to,
        received,
        limit,
    });
}

export async function getWidgetFeedbackAverageScoreByEntityForTeam(
    teamId,
    from,
    to,
    received,
    limit
) {
    return get(`${controllerRoute}/teams/${teamId}/average-score/entities`, {
        from,
        to,
        received,
        limit,
    });
}
