import { Fragment, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
    PolarRadiusAxis,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    Radar,
    Legend,
} from 'recharts';
import './charts.scss';

export function RadarGraph({ labels, values, targets }) {
    const [data, setData] = useState({});

    useEffect(() => {
        if (labels && values && targets) {
            const data = labels?.map((l, i) => ({
                label: l,
                v: values[i],
                t: targets[i],
                fullMark: 10,
            }));

            setData(data);
        }
    }, [labels, values, targets]);

    return (
        <Fragment>
            <RadarChart cx={175} cy={150} width={300} height={300} data={data}>
                <PolarGrid />
                <PolarAngleAxis dataKey='label' />
                <PolarRadiusAxis angle={90} domain={[0, 10]} />

                <Radar
                    name='Target'
                    dataKey='t'
                    stroke='rgba(143,148,152,1)'
                    fill='rgba(143,148,152,1)'
                    fillOpacity={0.6}
                />
                <Radar
                    name='Value'
                    dataKey='v'
                    stroke='rgba(237,50,65,1)'
                    fill='rgba(237,50,65,1)'
                    fillOpacity={0.6}
                />
                <Legend />
            </RadarChart>
        </Fragment>
    );
}

RadarGraph.propTypes = {
    labels: PropTypes.array,
    values: PropTypes.array,
    targets: PropTypes.array,
};
