import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSurvey } from '../../../../../services/surveyService';
import { HorizontalBarChart } from '../../../../shared/charts/HorizontalBarChart';
import { Aside } from '../../../../shared/components/Aside';
import { SurveyIterationCarousel } from '../../../../shared/components/SurveyIterationCarousel';
import { TeamSelector } from '../../../../shared/components/TeamSelector';
import { MainContent } from '../../../../shared/layout/MainContent';
import { Page } from '../../../../shared/layout/Page';
import {
    formatQuestionType,
    useAsyncError,
} from '../../../../shared/lib/helper';
import { useCache } from '../../../../shared/lib/useCache';
import { useMediaQueries } from '../../../../shared/lib/useMediaQuery';
import { useSelectedTeam } from '../../../../shared/lib/useSelectedTeam';
import { Loading } from '../../../../shared/loadings/Loading';
import { AssociatedQuestions } from '../../../../shared/surveys/components/AssociatedQuestions';
import { SurveyChart } from '../../../../shared/surveys/components/charts/SurveyChart';
import { TrendChart } from '../../../../shared/surveys/components/charts/trend/TrendChart';
import { DistributionChart } from '../../../../shared/surveys/components/charts//distribution/DistributionChart';
import { QuestionInformationCard } from '../../../../shared/surveys/components/QuestionInformationCard';
import { SegmentsScore } from '../../../../shared/surveys/components/SegmentsScore';
import { SurveyInformationCard } from '../../../../shared/surveys/components/SurveyInformationCard';
import { CommentBar } from '../../../../shared/surveys/questions-refactor/components/CommentBar';
import { WGroupIconChart } from '../../feedback/widgets/WGroupIconChart';
import constants from '../../../../shared/constants';
import { DominanceChart } from '../../../../shared/surveys/components/charts/dominance/DominanceChart';
import { useResponseRateChart } from './components/useResponseRateChart';
import './SurveyQuestionOverview.scss';

export function SurveyQuestionOverview() {
    const { id, teamId, questionId, iteration } = useParams();

    const [question, setQuestion] = useState(null);

    const [survey, setSurvey] = useState(null);

    const [loading, setLoading] = useState(true);

    const [selectedIteration, setSelectedIteration] = useState(null);

    const throwError = useAsyncError();

    const { teamLeadTeams } = useCache();

    const [teamOptions, setTeamOptions] = useState(null);

    const { team, setTeam } = useSelectedTeam();

    const [iterations, setIterations] = useState(null);

    const { mediumDown } = useMediaQueries();

    const [distributionFilter, setDistributionFilter] = useState(null);

    const navigate = useNavigate();

    const { chartData, completionRate } = useResponseRateChart(
        survey,
        selectedIteration,
        team
    );
    useEffect(() => {
        async function load() {
            if (!id || !questionId) {
                throwError(new Error('bad request'));
            }

            try {
                let s = await getSurvey(id);

                setSelectedIteration(
                    iteration
                        ? Number(iteration)
                        : typeof s?.lastIteration?.iteration === 'number'
                        ? s?.lastIteration?.iteration
                        : 0
                );

                setQuestion(s.questions.find((x) => x.id === questionId));
                setSurvey(s);
                setLoading(false);
            } catch (e) {
                throwError(new Error('error getting survey'));
            }
        }

        load();
    }, [questionId, id]);

    useEffect(() => {
        if (teamId) {
            setTeam(teamId);
        }
    }, [teamId]);

    useEffect(() => {
        let data = [];
        if (survey && question) {
            if (team?.id) {
                if (survey.surveyType === 1) {
                    data = survey?.iterations?.map((s) => ({
                        iteration: s?.iteration,
                        insight: s?.teamInsights
                            ?.find((x) => x?.team.id === team?.id)
                            ?.questionInsight?.find(
                                (q) => q.questionId === question?.id
                            ),
                    }));
                } else {
                    data = [
                        {
                            ...survey?.teamInsights?.find(
                                (x) => x.team.id === team?.id
                            ),
                            insight: survey?.teamInsights
                                ?.find((x) => x?.team.id === team?.id)
                                ?.questionInsight?.find(
                                    (q) => q.questionId === question?.id
                                ),
                            iteration: 0,
                        },
                    ];
                }
            } else {
                if (survey.surveyType === 1) {
                    data = survey?.iterations?.map((x) => ({
                        ...x,
                        insight: x?.insight?.questionInsight?.find(
                            (y) => y.questionId === question?.id
                        ),
                    }));
                } else {
                    data = [
                        {
                            insight: survey?.insight?.questionInsight?.find(
                                (y) => y.questionId === question?.id
                            ),
                            iteration: 0,
                        },
                    ];
                }
            }

            setIterations(data);
        }
    }, [team, survey, question]);

    useEffect(() => {
        if (survey && teamLeadTeams?.length) {
            let teamInsights =
                survey?.surveyType === 1
                    ? survey?.lastIteration?.teamInsights
                    : survey?.teamInsights;

            var teams = teamLeadTeams.filter((x) =>
                teamInsights?.some((y) => y.team.id === x?.value)
            );

            setTeamOptions(teams);
        }
    }, [survey, teamLeadTeams]);

    return loading ? (
        <Loading />
    ) : (
        <Fragment>
            <Page className='survey-question-overview'>
                {team?.id && <TeamSelector options={teamOptions} />}
                <MainContent>
                    <SurveyInformationCard
                        survey={survey}
                        iteration={selectedIteration}
                    />
                </MainContent>

                <MainContent>
                    <QuestionInformationCard question={question} />
                </MainContent>

                {survey?.surveyType === constants.SurveyType.Recurring &&
                    survey?.iterations?.length > 1 && (
                        <MainContent title={`Survey iteration`}>
                            <SurveyIterationCarousel
                                value={selectedIteration}
                                steps={survey?.iterations?.map((x) => ({
                                    value: x?.iteration,
                                    circleLabel: `#${x.iteration + 1}`,
                                    label: `${moment(x?.startDate).format(
                                        'DD/MM/YY'
                                    )}`,
                                }))}
                                onChange={(iteration) =>
                                    setSelectedIteration(iteration)
                                }
                            />
                        </MainContent>
                    )}

                {question?.questionType !== constants.QuestionType.Likert &&
                    question?.questionType !==
                        constants.QuestionType.Choice && (
                        <MainContent
                            title={`Segment ${formatQuestionType(
                                question?.questionType
                            ).toLowerCase()}`}
                        >
                            <SegmentsScore
                                survey={survey}
                                question={question}
                                iteration={selectedIteration}
                            />
                        </MainContent>
                    )}

                {(question?.questionType === constants.QuestionType.Likert ||
                    question?.questionType === constants.QuestionType.Choice) &&
                    !team && (
                        <MainContent
                            title={`${formatQuestionType(
                                question?.questionType
                            )} dominant response`}
                            tooltip={
                                'This widget illustrates the dominant response of specific teams. Where no single response is dominant, the team will not appear.'
                            }
                            br
                        >
                            <DominanceChart
                                questionId={question?.id}
                                iteration={selectedIteration}
                                surveyId={survey?.id}
                                teamId={team?.id}
                                tooltip
                            />
                        </MainContent>
                    )}

                <MainContent
                    title={`${formatQuestionType(
                        question?.questionType
                    )} distribution`}
                    br
                >
                    <DistributionChart
                        clickable
                        onClick={(selected) => setDistributionFilter(selected)}
                        questionId={question?.id}
                        iteration={selectedIteration}
                        surveyId={survey?.id}
                        teamId={team?.id}
                        legend
                    />
                </MainContent>

                <CommentBar
                    questionType={question?.questionType}
                    surveyId={id}
                    questionId={questionId}
                    teamId={team?.id}
                    distributionFilter={distributionFilter}
                    iteration={selectedIteration}
                />
            </Page>

            {!mediumDown && (
                <Aside className={'survey-overview'}>
                    {question?.questionType !== constants.QuestionType.Likert &&
                        question?.questionType !==
                            constants.QuestionType.Choice && (
                            <MainContent
                                title={`Survey ${formatQuestionType(
                                    question?.questionType
                                ).toLowerCase()}`}
                            >
                                <SurveyChart
                                    current={
                                        iterations?.length
                                            ? iterations?.find(
                                                  (x) =>
                                                      x.iteration ===
                                                      selectedIteration
                                              )
                                            : null
                                    }
                                    before={
                                        iterations?.length > 1
                                            ? iterations?.find(
                                                  (x) =>
                                                      x.iteration ===
                                                      selectedIteration - 1
                                              )
                                            : null
                                    }
                                    question={question}
                                />
                            </MainContent>
                        )}

                    {survey?.surveyType === 1 && (
                        <MainContent title='Trend'>
                            <TrendChart
                                question={question}
                                iterations={[
                                    ...(iterations?.sort(
                                        (x, y) => x?.iteration - y?.iteration
                                    ) ?? []),
                                ]}
                                teamId={team?.id}
                                survey={survey}
                            />
                        </MainContent>
                    )}

                    <MainContent
                        line={true}
                        title={`Response rate${
                            survey?.surveyType ===
                            constants.SurveyType.Recurring
                                ? ` #${selectedIteration + 1}`
                                : ''
                        }`}
                        className='aside-content'
                    >
                        <WGroupIconChart
                            label={'Completion rate'}
                            type={'pulse'}
                            value={completionRate}
                        />

                        <HorizontalBarChart data={chartData} />
                    </MainContent>
                    {survey?.questions?.length > 1 && (
                        <MainContent>
                            <AssociatedQuestions
                                onClick={(questionId) => {
                                    navigate(
                                        team?.id
                                            ? `/my-team/feedback/pulse/survey-overview/${
                                                  survey?.id
                                              }/team/${
                                                  team?.id
                                              }/question/${questionId}/${
                                                  selectedIteration ?? 0
                                              }`
                                            : `/company/feedback/pulse/survey-overview/${
                                                  survey?.id
                                              }/question/${questionId}/${
                                                  selectedIteration ?? 0
                                              }`
                                    );
                                    setDistributionFilter(null);
                                }}
                                questions={survey?.questions?.filter(
                                    (x) => x.id !== question?.id
                                )}
                            />
                        </MainContent>
                    )}
                </Aside>
            )}
        </Fragment>
    );
}
