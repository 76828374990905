import { Fragment, useEffect, useState } from 'react';
import {
    getClientNotifications,
    updateClientNotifications,
} from '../../../../services/clientService';
import { Accordion } from '../../../shared/components/Accordion';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper';
import { NotificationSwitch } from '../../me/UserNotifications';
import { parseNotifications } from './notificationsUtils';
import { getNotificationSettingsType } from '../../../../services/notificationService';
import './Notifications.scss';

export function Notifications() {
    const [clientNotifications, setClientNotifications] = useState(null);

    const [active, setActive] = useState(null);

    const throwError = useAsyncError();

    const updateSetting = async (setting) => {
        try {
            await updateClientNotifications(setting);
        } catch (error) {
            throwError(error);
        }
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                let [set, cNotifications] = await Promise.all([
                    getNotificationSettingsType(),
                    getClientNotifications(),
                ]);

                setClientNotifications(
                    parseNotifications(
                        cNotifications
                            ?.filter(
                                (obj, pos, arr) =>
                                    arr
                                        .map((mapObj) => mapObj.setting.setting)
                                        .indexOf(obj.setting.setting) === pos
                            )
                            .map((x) => ({
                                description: x.description,
                                overridable: x.overridable,
                                setting: x.setting.setting,
                                permissionFlag: x.permissionFlag,
                                enabled: x.setting.enabled,
                            })),
                        set
                    )
                );
            } catch (error) {
                throwError(error);
            }
        };

        fetchNotifications();
    }, []);

    const buildAccordion = (category, label) => {
        return (
            <Fragment>
                <div className='header'>
                    <div></div>
                    <div>Default setting</div>
                    <div>Allow users to turn on/off</div>
                </div>
                {!!category?.notify?.length && (
                    <label>Notify {label ? label : 'users'} when...</label>
                )}
                {category?.notify
                    ?.filter(
                        (obj, pos, arr) =>
                            arr
                                .map((mapObj) => mapObj.setting)
                                .indexOf(obj.setting) === pos
                    )
                    ?.map((notification) => (
                        <NotificationSwitch
                            key={`setting-${notification.setting}`}
                            enabled={notification.enabled}
                            onChange={(s, c) =>
                                updateSetting({ ...s, enabled: c })
                            }
                            notification={notification}
                            overridable={notification.overridable}
                            onOverride={(s, o) =>
                                updateSetting({ ...s, overridable: o })
                            }
                        >
                            {notification.description}
                        </NotificationSwitch>
                    ))}
                {!!category?.remind?.length && (
                    <label>Remind {label ? label : 'users'} when...</label>
                )}
                {category?.remind
                    ?.filter(
                        (obj, pos, arr) =>
                            arr
                                .map((mapObj) => mapObj.setting)
                                .indexOf(obj.setting) === pos
                    )
                    ?.map((notification) => (
                        <NotificationSwitch
                            key={`setting-${notification.setting}`}
                            enabled={notification.enabled}
                            onChange={(s, c) =>
                                updateSetting({ ...s, enabled: c })
                            }
                            notification={notification}
                            overridable={notification.overridable}
                            onOverride={(s, o) =>
                                updateSetting({ ...s, overridable: o })
                            }
                        >
                            {notification.description}
                        </NotificationSwitch>
                    ))}
            </Fragment>
        );
    };

    return (
        <Page
            className='notifications'
            loading={!clientNotifications}
            title={'Notifications settings'}
        >
            <div className='description'>
                <p>
                    Pulse 360 allows administrators the ability to configure a
                    standard notifications profile for all new users and decide
                    if users have the ability to update or personalise these
                    settings (based on their personal preferences). If a setting
                    is allowed to be personalised, users can disable or enable
                    this notification.
                </p>
            </div>
            <MainContent testId='loading'>
                {(!!clientNotifications?.feedback?.notify?.length ||
                    !!clientNotifications?.feedback?.remind?.length) && (
                    <Accordion
                        onClick={() => setActive(0)}
                        expand={active === 0}
                        title={
                            <Fragment>
                                <i className='icon icon-feedback-give'></i>
                                Feedback
                            </Fragment>
                        }
                    >
                        <p>{buildAccordion(clientNotifications?.feedback)}</p>
                    </Accordion>
                )}
                {(!!clientNotifications?.pulse?.notify?.length ||
                    !!clientNotifications?.pulse?.remind?.length) && (
                    <Accordion
                        onClick={() => setActive(1)}
                        expand={active === 1}
                        title={
                            <Fragment>
                                <i className='icon icon-pulse'></i>
                                Pulse Surveys
                            </Fragment>
                        }
                    >
                        <p>{buildAccordion(clientNotifications?.pulse)}</p>
                    </Accordion>
                )}
                {(!!clientNotifications?.assess?.notify?.length ||
                    !!clientNotifications?.assess?.remind?.length) && (
                    <Accordion
                        expand={active === 2}
                        onClick={() => setActive(2)}
                        title={
                            <Fragment>
                                <i className='icon icon-ppr'></i>
                                Assess
                            </Fragment>
                        }
                    >
                        <p>{buildAccordion(clientNotifications?.assess)}</p>
                    </Accordion>
                )}
                {(!!clientNotifications?.team?.notify?.length ||
                    !!clientNotifications?.team?.remind?.length) && (
                    <Accordion
                        expand={active === 3}
                        onClick={() => setActive(3)}
                        title={
                            <Fragment>
                                <i className='icon icon-my-team'></i>
                                Team
                            </Fragment>
                        }
                    >
                        <p>
                            {' '}
                            {buildAccordion(
                                clientNotifications?.team,
                                'team leads'
                            )}
                        </p>
                    </Accordion>
                )}
                {(!!clientNotifications?.company?.notify?.length ||
                    !!clientNotifications?.company?.remind?.length) && (
                    <Accordion
                        expand={active === 4}
                        onClick={() => setActive(4)}
                        title={
                            <Fragment>
                                <i className='icon icon-company'></i>
                                Company
                            </Fragment>
                        }
                    >
                        <p> {buildAccordion(clientNotifications?.company)}</p>
                    </Accordion>
                )}
            </MainContent>
        </Page>
    );
}
