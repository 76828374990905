import { Fragment, useEffect, useState } from 'react';
import { useRagIntervals } from '../../../../shared/lib/useRagIntervals';
import { Widget } from './Widget';
import { PropTypes } from 'prop-types';
import constants from '../../../../shared/constants';
import './components.scss';

export function SwotWidget({ title, dafo, info, className }) {
    const [loading, setLoading] = useState(true);

    const [teams, setTeams] = useState(null);

    const { getColor } = useRagIntervals();

    useEffect(() => {
        if (dafo && dafo.length) {
            setLoading(true);

            let teamsWithScore = dafo
                .filter((x) => typeof x.score === 'number')
                .sort((x, y) => (x.score < y.score ? 1 : -1));

            let teamsWithSentiment = dafo
                .filter((x) => typeof x.sentiment === 'number')
                .sort((x, y) => (x.sentiment < y.sentiment ? 1 : -1));

            let t = {
                highScore: teamsWithScore.length ? teamsWithScore[0] : null,
                lowScore:
                    teamsWithScore.length > 1
                        ? teamsWithScore[teamsWithScore.length - 1]
                        : null,
                highSentiment: teamsWithSentiment.length
                    ? teamsWithSentiment[0]
                    : null,
                lowSentiment:
                    teamsWithSentiment.length > 1
                        ? teamsWithSentiment[teamsWithSentiment.length - 1]
                        : null,
            };

            setTeams(t);

            setLoading(false);
        }
    }, [dafo]);

    return (
        <Widget
            title={title}
            info={info}
            className='swot-widget'
            boxClassName={className ?? ''}
            hideTitle={true}
        >
            {!loading && (
                <Fragment>
                    <label className='centered'>Score</label>
                    <div
                        className='w-square'
                        style={{
                            backgroundColor: getColor(
                                teams.lowScore ? teams.lowScore.score : null,
                                constants.QuestionType.Score
                            ),
                        }}
                    >
                        {teams.lowScore ? <p>{teams.lowScore.name}</p> : '-'}
                    </div>
                    <div
                        className='w-square'
                        style={{
                            backgroundColor: getColor(
                                teams.highScore ? teams.highScore.score : null,
                                constants.QuestionType.Score
                            ),
                        }}
                    >
                        {teams.highScore ? <p>{teams.highScore.name}</p> : '-'}
                    </div>

                    <label className='centered'>Sentiment</label>
                    <div
                        className='w-square'
                        style={{
                            backgroundColor: getColor(
                                teams.lowSentiment
                                    ? teams.lowSentiment.sentiment
                                    : null
                            ),
                        }}
                    >
                        {teams.lowSentiment ? (
                            <p>{teams.lowSentiment.name}</p>
                        ) : (
                            '-'
                        )}
                    </div>
                    <div
                        className='w-square  '
                        style={{
                            backgroundColor: getColor(
                                teams.highSentiment
                                    ? teams.highSentiment.sentiment
                                    : null
                            ),
                        }}
                    >
                        {teams.highSentiment ? (
                            <p>{teams.highSentiment.name} </p>
                        ) : (
                            '-'
                        )}
                    </div>

                    <label></label>
                    <label className='centered'>Low</label>
                    <label className='centered'>High</label>
                </Fragment>
            )}
        </Widget>
    );
}

SwotWidget.propTypes = {
    title: PropTypes.string,
    dafo: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    info: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]),
    className: PropTypes.string,
};
