import { Fragment, useState } from 'react';
import { useCSV } from '../../lib/useCSV';
import { SURVEY_STATUS_TYPE } from '../../../../services/surveyService';
import {
    humanizeDiffToday,
    humanizeRecurrence,
} from '../../lib/dateTimeHelper';
import { useClient } from '../../lib/useClient';
import { Loading } from '../../loadings/Loading';
import { ExportPdfModal } from '../../modal/ExportPdfModal';
import { OptionMenu, SurveyActor } from './SurveyBox';
import { PropTypes } from 'prop-types';
import { useSelectedTeam } from '../../lib/useSelectedTeam';
import moment from 'moment';
import './components.scss';

export function SurveyInformationCard({ survey, iteration }) {
    const [exportPdfModal, setExportPdfModal] = useState(false);

    const client = useClient();

    const { exportSurveyComments, exportTeamSurveyComments } = useCSV();

    const { team: selectedTeam } = useSelectedTeam();

    const exportPdfOption = {
        label: (
            <>
                <i className='icon icon-export-pdf'></i>
                <label>Export PDF</label>
            </>
        ),
        onClick: () => setExportPdfModal(true),
    };

    const exportCsvOption = {
        label: (
            <>
                <i className='icon icon-export-csv'></i>
                <label>Export CSV</label>
            </>
        ),
        onClick: async () => {
            selectedTeam?.id
                ? await exportTeamSurveyComments(
                      survey?.id,
                      selectedTeam?.id,
                      survey?.title
                  )
                : await exportSurveyComments(survey?.id, survey?.title);
        },
    };

    const statusLabel = Object.keys(SURVEY_STATUS_TYPE).find(
        (k) => SURVEY_STATUS_TYPE[k] === survey?.status
    );
    return !survey ? (
        <Loading />
    ) : (
        <Fragment>
            <div className='information-bar'>
                <div className='title'>
                    <label
                        className={`status small ${statusLabel.toLowerCase()}`}
                    >
                        {statusLabel}
                    </label>
                    <h4>{survey.title}</h4>
                    <i
                        className={`icon-${
                            survey.surveyType === 0 ? 'one-off' : 'recurring'
                        }`}
                        title={
                            survey.surveyType === 0 ? 'One Off' : 'Recurring'
                        }
                    ></i>
                    {survey.isAnonymous && (
                        <i
                            role='img'
                            aria-label='Anonymous'
                            className='icon-eye-anonymous'
                            title='Anonymous'
                        />
                    )}
                    <OptionMenu
                        position='bottom'
                        options={[exportPdfOption, exportCsvOption]}
                    />
                </div>
                <div>
                    <label>Sent to:</label>
                    {survey?.sentTo ? (
                        <>
                            {survey?.sentTo?.slice(0, 3).map((actor) => (
                                <SurveyActor
                                    actor={actor}
                                    key={`survey-actor-${actor?.id}`}
                                />
                            ))}
                            {survey?.sentTo?.length > 3 && (
                                <SurveyActor
                                    actor={{
                                        name: `+ ${
                                            survey?.sentTo.length - 3
                                        }...`,
                                    }}
                                    title={survey?.sentTo
                                        .slice(3, survey?.sentTo?.length)
                                        .map((x) => x.name)
                                        .join(',')}
                                />
                            )}
                        </>
                    ) : (
                        <SurveyActor
                            actor={{
                                name: client?.companyName,
                                image: client?.logo,
                            }}
                        />
                    )}
                </div>
                <div className='date'>
                    <label>Start date:</label>
                    {humanizeDiffToday(survey?.releaseDate)}
                </div>

                {survey?.surveyType === 1 && (
                    <Fragment>
                        <div className='date'>
                            <label>Recurrence:</label>
                            {humanizeRecurrence(survey?.recurrence)}
                        </div>
                        <div className='date'>
                            <label>Last sent on:</label>
                            {humanizeDiffToday(
                                survey?.lastIteration?.startDate
                            )}
                            <h4>{`#${
                                survey?.lastIteration?.iteration + 1
                            }`}</h4>
                        </div>
                    </Fragment>
                )}
            </div>

            <ExportPdfModal
                open={exportPdfModal}
                questions={survey?.questions}
                iterations={
                    typeof iteration !== 'number' && survey?.iterations?.length
                        ? [
                              ...survey.iterations.map((x) => ({
                                  value: x?.iteration,
                                  label: `Iteration ${
                                      x?.iteration + 1
                                  } - ${moment(x?.startDate).format(
                                      'DD/MM/YY'
                                  )} to ${moment(x?.endDate).format(
                                      'DD/MM/YY'
                                  )}`,
                              })),
                          ]?.sort((x, y) => x?.value - y?.value)
                        : null
                }
                survey={survey}
                teamId={selectedTeam?.id}
                iteration={iteration}
                onClose={() => {
                    setExportPdfModal(false);
                }}
            />
        </Fragment>
    );
}

SurveyInformationCard.propTypes = {
    survey: PropTypes.object,
    iteration: PropTypes.number,
};
