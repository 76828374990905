import {
    ScoreChart,
    SentimentChart,
    TotalChart,
} from '../../../../shared/charts/RadialChartIcon';
import { Widget } from './Widget';
import { PropTypes } from 'prop-types';
import './components.scss';

export function OrganisationalInsightsWidget({
    title,
    score,
    sentiment,
    volume,
    info,
}) {
    return (
        <Widget
            info={info}
            hideTitle={title}
            title={title}
            className='organisational-insights-widget'
            boxClassName='o-insights-w responsive'
        >
            <div className='organisational-body'>
                <div className='chart-content'>
                    <TotalChart total={volume} showText={true} />

                    <label>Volume</label>
                </div>
                <div className='chart-content'>
                    <ScoreChart score={score} showText={true} />
                    <label>Score</label>
                </div>

                <div className='chart-content'>
                    <SentimentChart sentiment={sentiment} showText={true} />
                    <label>Sentiment</label>
                </div>
            </div>
        </Widget>
    );
}

OrganisationalInsightsWidget.propTypes = {
    title: PropTypes.string,
    score: PropTypes.number,
    sentiment: PropTypes.number,
    volume: PropTypes.number,
    info: PropTypes.object,
};
