import { Fragment, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { PropTypes } from 'prop-types';
import './components.scss';

export function Aside({ children, className, fixed }) {
    const [allow, setAllow] = useState(false);

    useEffect(() => {
        if (document.querySelector('main')) {
            document.getElementById('layout')?.classList?.add('with-aside');
            setAllow(true);
        }

        return () => {
            document.getElementById('layout')?.classList?.remove('with-aside');
        };
    }, []);

    if (allow) {
        return createPortal(
            <aside className={`${className ?? ''}`}>
                <div className={`aside-container ${fixed ? 'fixed' : ''}`}>
                    {children}
                </div>
            </aside>,
            document.getElementById('layout') ||
                document.getElementsByClassName('layout')
        );
    } else {
        return <Fragment></Fragment>;
    }
}

Aside.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    className: PropTypes.string,
    fixed: PropTypes.bool,
};
