import { Navigate, useParams } from 'react-router-dom';

export function PulseNavigate() {

    const { teamId, feedbackId } = useParams();

    return (
        <Navigate
            to={`/my-team/feedback/organisation/${teamId}`}
            state={{ itemId: feedbackId }}
    />
    )
}