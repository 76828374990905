import { Loading } from '../loadings/Loading';
import { useContext } from 'react';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import './Snackbar.scss';

export function Snackbar() {
    const { state } = useContext(SnackbarContext);

    return (
        <div
            className={`snackbar-container ${
                state?.success != null
                    ? state?.success
                        ? 'success'
                        : 'error'
                    : ''
            }`}
        >
            {state?.isLoading && (
                <div className='snackbar-loading'>
                    <Loading />
                </div>
            )}
            <div className='snackbar-label'>{state?.message}</div>
        </div>
    );
}
