import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoles } from '../../../../services/roleService';
import {
    deleteUser,
    getUsersPaginated,
} from '../../../../services/userService';
import { InformationTooltip } from '../../../shared/components/InformationTooltip';
import { Tabs } from '../../../shared/components/Tabs';
import { AddButton } from '../../../shared/inputs/AddButton';
import { IconButton } from '../../../shared/inputs/IconButton';
import { IconButtonModal } from '../../../shared/inputs/IconButtonModal';
import { SearchInput } from '../../../shared/inputs/SearchInput';
import { MainContent } from '../../../shared/layout/MainContent';
import { Page } from '../../../shared/layout/Page';
import { useAsyncError } from '../../../shared/lib/helper';
import { ResponsiveTable } from '../../../shared/utils/ResponsiveTable';
import './Users.scss';

export function Users() {
    const [filter, setFilter] = useState('');

    const [deleted, setDeleted] = useState(null);

    const [roles, setRoles] = useState(null);

    const [selectedRole, setSelectedRole] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const [firstLoad, setFirstLoad] = useState(true);

    const navigate = useNavigate();

    const throwError = useAsyncError();

    async function handleDelete(userId) {
        try {
            await deleteUser(userId);
            setDeleted(true);
        } catch (e) {
            setDeleted(false);
        }
        setRefresh(true);
        setFilter('');
    }

    useEffect(() => {
        async function fetchRoles() {
            try {
                let r = await getRoles();

                setRoles(r.filter((x) => x.permissions.length > 1));

                setFirstLoad(false);
            } catch (e) {
                throwError(e);
            }
        }

        fetchRoles();
    }, []);

    useEffect(() => {
        if (!firstLoad) {
            setRefresh(true);
        }
    }, [selectedRole]);

    return (
        <Page title='Users' className='users'>
            <MainContent testId='users'>
                <div>
                    <div className='top'>
                        <AddButton
                            onClick={() => navigate('/admin/users/invite-user')}
                            autoFocus
                        >
                            Invite User
                        </AddButton>
                        <SearchInput
                            onSearch={(text) => setFilter(text)}
                            placeholder={'User or Email'}
                        />
                    </div>
                    <div className='header'>
                        <Tabs
                            className='navy'
                            options={roles
                                ?.sort(
                                    (x, y) =>
                                        y?.permissions?.length -
                                        x?.permissions?.length
                                )
                                ?.map((x) => ({
                                    label: (
                                        <Fragment>
                                            <h4
                                                role='button'
                                                key={`h4-${x.id}`}
                                                aria-label={`${x.name} filter`}
                                            >
                                                {x.name}
                                            </h4>
                                            <InformationTooltip
                                                key={`info-${x.id}`}
                                                text={x.description}
                                            />
                                        </Fragment>
                                    ),
                                    value: x.id,
                                }))}
                            onChange={(active) => setSelectedRole(active)}
                        />
                    </div>
                    <div>
                        <ResponsiveTable
                            className='users'
                            noDataText='No current users'
                            onFetchData={getUsersPaginated}
                            limit={20}
                            innerScroll={false}
                            defaultSorted={{ id: 'name', desc: false }}
                            filter={filter}
                            customFilters={{ roleId: selectedRole }}
                            refresh={refresh}
                            onRefresh={() => setRefresh(false)}
                            columns={[
                                {
                                    Header: '',
                                    accessor: 'image',
                                    headerClassName: 'image',
                                    className: 'image',
                                    width: 50,
                                    disableSortBy: true,
                                    Cell: (row) => (
                                        <img
                                            className='image'
                                            src={row.value}
                                            alt='Image'
                                        />
                                    ),
                                },
                                {
                                    id: 'name',
                                    Header: 'User',
                                    sortDescFirst: true,
                                    accessor: (d) =>
                                        `${d.firstName} ${d.lastName}`,
                                },

                                {
                                    Header: 'Team / Department',
                                    accessor: 'teams',
                                    disableSortBy: true,
                                    Cell: (row) => (
                                        <p
                                            title={row.value
                                                .map((t) => t.name)
                                                .join(', ')}
                                        >
                                            {row.value
                                                .map((t) => t.name)
                                                .join(', ')}
                                        </p>
                                    ),
                                },
                                {
                                    Header: 'Job Title',
                                    accessor: 'jobTitle',
                                    sortDescFirst: true,
                                },
                                {
                                    Header: 'Sign up date',
                                    accessor: 'created',
                                    headerClassName: 'text-center',
                                    className: 'text-center',
                                    width: 200,
                                    sortDescFirst: true,
                                    Cell: (row) => (
                                        <p
                                            style={{ textAlign: 'center' }}
                                            title={moment(row?.value).format(
                                                'DD/MM/YYYY'
                                            )}
                                        >
                                            {moment(row?.value).format(
                                                'DD/MM/YYYY'
                                            )}
                                        </p>
                                    ),
                                },
                                {
                                    Header: 'total-here',
                                    accessor: 'id',
                                    headerClassName: 'buttons',
                                    className: 'buttons',
                                    disableSortBy: true,
                                    width: 120,
                                    Cell: (row) => (
                                        <p style={{ textAlign: 'center' }}>
                                            <IconButton
                                                type='edit'
                                                route={`/admin/users/user/${row.value}`}
                                            />
                                            <IconButtonModal
                                                type='delete'
                                                success={deleted}
                                                message={
                                                    deleted
                                                        ? 'User deleted successfully.'
                                                        : 'Ops! Something went wrong, please try again later.'
                                                }
                                                confirmMessage={
                                                    <Fragment>
                                                        Are you sure you want to
                                                        delete this user?
                                                        <br />
                                                        <strong>
                                                            The user will no
                                                            longer be able to
                                                            access Pulse 360 and
                                                            will be removed from
                                                            all relevant teams.
                                                            <br />
                                                            However, their
                                                            historical feedback
                                                            and survey responses
                                                            will still remain in
                                                            the system.
                                                        </strong>
                                                    </Fragment>
                                                }
                                                onConfirm={() =>
                                                    handleDelete(row.value)
                                                }
                                                onClose={() => setDeleted(null)}
                                            />
                                        </p>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </div>
            </MainContent>
        </Page>
    );
}
