import { get } from '../components/shared/lib/axios';

const controllerRoute = 'widgets';

export async function getWidgetCompanyTopTopics(from, to, limit) {
    return get(`${controllerRoute}/top-topics`, { from, to, limit });
}

export async function getWidgetTeamTopTopics(
    teamId,
    from,
    to,
    limit,
    received
) {
    return get(`${controllerRoute}/teams/${teamId}/top-topics`, {
        from,
        to,
        limit,
        received,
    });
}
