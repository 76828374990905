import { useCallback, useEffect } from 'react';
import { verifyRecaptcha } from '../../../services/utilService';
import { useAsyncError } from './helper';

export function useRecaptcha() {
    const SITE_KEY = '6LeGvTQbAAAAABB72MZpeuLiiq34zNZx61WbQkwh';

    const throwError = useAsyncError();

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        };

        // load the script by passing the URL
        loadScriptByURL(
            'recaptcha-key',
            `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`
        );
    }, []);

    const onSubmit = useCallback((callback) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute(SITE_KEY, { action: 'submit' })
                .then(async (token) => {
                    let isValid = await verifyRecaptcha(token);

                    if (isValid) {
                        callback();
                    } else {
                        throwError(new Error('wrong recaptcha'));
                    }
                });
        });
    }, []);

    return onSubmit;
}
