import pagHelper from '../components/shared/lib/paginationHelper';
import { get, post, put, remove } from '../components/shared/lib/axios';

var controllerRoute = 'users';

export async function getUser(userId) {
    return get(`${controllerRoute}/${userId}`);
}

export async function getUsers() {
    return get(`${controllerRoute}`);
}

export async function getUserProfile() {
    return get(`${controllerRoute}/profile`);
}

export async function getUsersPaginated(offset, limit, filter, orderBy, role) {
    let pagination = pagHelper.createUserPagination(
        offset,
        limit,
        filter,
        orderBy
    );

    return get(`${controllerRoute}/paginated`, { ...pagination, ...role });
}

export async function getUserNotifications() {
    return get(`${controllerRoute}/notifications`);
}

export async function updateNotification(setting) {
    return put(`${controllerRoute}/notifications`, setting);
}

export async function updateUserTeamNotification(setting) {
    return put(`${controllerRoute}/teams/notifications`, setting);
}

export async function updateUser(user) {
    return put(`${controllerRoute}`, user);
}

export async function updateUserImage(userImage) {
    return put(`${controllerRoute}/image`, userImage);
}

export async function invite(inviteRequest) {
    return post(`${controllerRoute}/invite`, inviteRequest);
}

export async function deleteUser(userId) {
    return remove(`${controllerRoute}/${userId}`);
}
